import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import ImgCrop from 'antd-img-crop';
import { withTranslation } from 'react-i18next';
import { Row, Col, Avatar, Typography, Button, Space, Form, Upload, Input, Select, Skeleton, Spin, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { indexCountry } from '../../../../../redux/actions/master/country/countryAction';
import { checkMobile } from '../../../../../redux/actions/check/checkAction';
import { listCity, unmountListCity } from '../../../../../redux/actions/master/city/cityAction';
const { Text } = Typography;
const { Option } = Select;

export class index extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      metaCity: {
        page: 1,
        perpage: 10,
        search: ''
      }
    }
    this.onSearchCity = debounce(this.onSearchCity.bind(this), 800)
  }
  
  componentDidMount() {
    const { actionGetCountry } = this.props
    
    return actionGetCountry()
  }

  onSearchCity = value => {
    const { metaCity } = this.state;
    const { actionListCity } = this.props;

    metaCity.page = 1
    metaCity.perpage = 10
    metaCity.search = value

    return actionListCity(metaCity)
  }

  onFocusCity = () => {
    const { metaCity } = this.state;
    const { actionListCity } = this.props;
    return actionListCity(metaCity)
  }

  handleCity = (value, option) => {
    const optionSelected = Number(option.key)
    this.setState({
      cityId: optionSelected
    })
  }

  onCheckMobile = (e) => {
    const { actionCheckMobile, formRef, callingCode, getContact, t } = this.props
    
    formRef.current.setFieldsValue({
      mobilePhone: e.replace(/^0+/, '')
    })

    e = `${callingCode}${e.replace(/^0+/, '')}`

    if(e !== `${getContact.data.callingCode}${getContact.data.mobilePhone}`){
      return actionCheckMobile(e, response => {
        if(response){
          formRef.current.setFieldsValue({
            mobilePhone: null
          })
          message.error(t('jobDetail.contact.numberError'))
        }
      })
    }
  }

  onChangeCode = () => {
    const { formRef } = this.props

    formRef.current.setFieldsValue({
      mobilePhone: null
    })
  }
  render() {
    const { 
      getContact, 
      getCountry, 
      getCity, 
      handleUpload,
      handleCallingCode,
      handleCurrentCity,
      valuesContact,
      currentCityName,
      onPreviewAvatar,
      t
    } = this.props
    
    if(getCountry.loading || getContact.loading){
      return <Skeleton active avatar />
    }

    const prefixSelector = (
      <Form.Item 
        name="callingCodeId" 
        noStyle
        initialValue={
          valuesContact && valuesContact.callingCodeId ? 
            valuesContact.textCode
          : getContact.data.callingCodeId ?
            `(+${getContact.data.callingCode}) ${getContact.data.countryName}`
          :
            ""
        }
      >
        <Select onChange={(value, option) => {handleCallingCode(value, option); this.onChangeCode()}} style={{ width: 150 }} showSearch>
          {
            getCountry?.data?.map(item =>
              <Option text={item.callingCode} key={item.id} value={`(+${item.callingCode}) ${item.name}`}>{`(+${item.callingCode}) ${item.name}`}</Option>
            )
          }
        </Select>
      </Form.Item>
    )
    
    return (
      <React.Fragment>
        <Row className="mb-32">
          <Col span={4}>
            <Space className="width-100 text-align-center" direction="vertical">
              {
                getContact.data.profilePicUrl ?
                  <Avatar size={80} src={getContact.data.profilePicUrl} />
                : onPreviewAvatar ?
                  <Avatar size={80} src={onPreviewAvatar} />
                :
                  <React.Fragment>
                    <Avatar size={80} icon={<UserOutlined />} />
                    <ImgCrop shape="round" rotate={true}>
                      <Upload {...handleUpload()} multiple={false} showUploadList={false}>
                        <Button>
                          <Space>
                            <Text className="fs-14" type="danger">*</Text>{t('jobDetail.contact.upload')}
                          </Space>
                        </Button>
                      </Upload>
                    </ImgCrop>
                  </React.Fragment>
              }
            </Space>
          </Col>
          <Col className="pl-8 mt-4" span={20}>
            <Space direction="vertical" size={24}>
              <Text className="fs-15 dark-color">{getContact.data.fullName}</Text>
              <Text className="fs-15 dark-color">{getContact.data.email}</Text>
            </Space>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Space size={4}>
              <Text className="fs-12" type="danger">*</Text>
              <Text className="fs-12" type="secondary">{t('jobDetail.contact.mobileNumber')}</Text>
            </Space>
            <Form.Item 
              className="mb-16"
              name="mobilePhone"
              validateFirst
              rules={[
                { required: true, message: t('jobDetail.contact.requiredMobile') },
                { pattern: /^[0-9]*$/, message: t('jobDetail.contact.patternMobile') },
                { min: 7, max: 13, message: t('jobDetail.contact.maxMobile') },
              ]}
              initialValue={
                valuesContact && valuesContact.mobilePhone ? 
                  valuesContact.mobilePhone
                : getContact.data.mobilePhone ?
                  getContact.data.mobilePhone
                :
                  ""
              }
            >
              <Input onBlur={(e) => this.onCheckMobile(e.target.value)} addonBefore={prefixSelector} placeholder="Type your primary mobile number" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
          <Space size={4}>
            <Text className="fs-12" type="danger">*</Text>
            <Text className="fs-12" type="secondary">{t('jobDetail.contact.currentLocation')}</Text>
          </Space>
          <Form.Item 
            name="currentCityId"
            rules={[
              { required: true, message: t('jobDetail.contact.requiredLocation') }
            ]}
            initialValue={
              valuesContact && valuesContact.currentCityId ? 
                currentCityName
              : getContact.data.currentCityId ?
                `${getContact.data.currentCityName}, ${getContact.data.currentCountryName}`
              :
                null
            }
          >
            <Select 
              className="width-100" 
              showSearch
              onFocus={this.onFocusCity}
              onSearch={this.onSearchCity}
              onChange={(value, option) => {this.handleCity(value, option); handleCurrentCity(value, option)}}
              placeholder={t('jobDetail.contact.placeholderCurrent')}
              notFoundContent={getCity.loading ? <Spin size="small" /> : null}
            >
              {
                getCity?.data?.map(item => 
                  <Option key={item.id} value={`${item.name}, ${item.countryName}`}>{`${item.name}, ${item.countryName}`}</Option>
                  )
              }
            </Select>
          </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountListCity } = this.props
    return unmountListCity()
  }
}

const mapStateToProps = (state) => ({
  getCountry: state.countryReducer,
  getCity: state.cityReducer
})

const mapDispatchToProps = {
  actionGetCountry: indexCountry,
  actionCheckMobile: checkMobile,
  actionListCity: listCity,
  unmountListCity: unmountListCity,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(index))
