import React from 'react';
import FormPekerjaan from './pekerjaan';
import FormWiraswasta from './wiraswasta';
import FormStudi from './studi';

export default function Form5(props) {
  const { form4 } = props
  return (
    <React.Fragment>
      {
        form4.status === "1" ?
          <FormPekerjaan {...props} />
        : form4.status === "2" ?
          <FormWiraswasta {...props} />
        : form4.status === "3" ?
          <FormStudi {...props} />
        : null
      }
    </React.Fragment>
  )
}
