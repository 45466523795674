import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';
import { Row, Col, Card, Space, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import CButton from '../../../../../../components/Button';

const { Text } = Typography;

export class CandidateXpertPaymentExpired extends Component {
  render() {
    const { location: { state: { xpertname, xpertTransactionNumber, eventTransactionDetail, totalTransaction } }, t } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>Xpert - {t('xpert.title9')}</title>
        </Helmet>
        <Row className="mt-16 pr-16 pl-16">
          <Col lg={{ span: 18, offset: 3 }} xxl={{ span: 12, offset: 6 }}>
            <Card style={{ backgroundColor: 'transparent', border: '1px solid #e0e0e0', paddingLeft: 32, paddingRight: 32 }}>
              <Space className="width-100 mb-32 text-align-center" direction="vertical">
                <Text className="danger-color fs-16"><ExclamationCircleFilled /> {t('xpert.title10')}</Text>
                <Text className="danger-color fs-14">{t('xpert.title11')}</Text>
              </Space>
              <Row className="mt-16">
                <Col span={24}>
                  <Space direction="vertical" size={16}>
                    <Space direction="vertical" size={0}>
                      <Text className="dark-color fs-14">{t('xpert.eventName')}</Text>
                      <Text className="info-color fs-18 font-weight-bold">{xpertname}</Text>
                    </Space>

                    <Space direction="vertical" size={0}>
                      <Text className="dark-color fs-14">{t('xpert.eventDate')}</Text>
                      <Text className="dark-color fs-18 font-weight-bold">PT Jobshub Indonesia</Text>
                    </Space>

                    <Space direction="vertical" size={0}>
                      <Text className="dark-color fs-14">{t('xpert.text6')}</Text>
                      <Text className="dark-color fs-18 font-weight-bold">{xpertTransactionNumber}</Text>
                    </Space>

                    <Space direction="vertical" size={0}>
                      <Text className="dark-color fs-14">{t('xpert.ticketName')}</Text>
                      <Text className="dark-color fs-18 font-weight-bold">{eventTransactionDetail.map(item => item.name)}</Text>
                    </Space>
                    
                    <Space direction="vertical" size={0}>
                      <Text className="dark-color fs-14">{t('xpert.payment')}</Text>
                      <Text className="dark-color fs-18 font-weight-bold">{`IDR ${totalTransaction.toLocaleString()}`}</Text>
                    </Space>
                  </Space>

                </Col>
              </Row>
            </Card>
            <Row className="mt-32" gutter={32}>
              <Col span={24}>
                <Link to={'/candidate/my-orders'}>
                  <CButton className="box-shadow text-align-center width-100" type="primary" size="large" style={{ border: '#4272b9', background: '#4273b9' }} title={t('xpert.text8')}/>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default withTranslation()(CandidateXpertPaymentExpired)