import React from 'react'
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Row, Col, Grid, Space, Button, Typography, Card } from 'antd';
import Background from '../../assets/img/general/bg.png';
import { ArrowRightOutlined } from '@ant-design/icons';

const { useBreakpoint } = Grid;
const { Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export function cta(props) {
  const { xs, xxl } = GetBreakPoint()
  const { t } = props
  return (
    <React.Fragment>
      {/* <Row style={{ width: '100%' }}>
        <Col style={{ backgroundImage: `url(${Background})`, width: '100%', backgroundSize: 'cover' }}>
          <Row style={{ paddingTop: 32, paddingBottom: 32 }}>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
              <Row justify="space-around" align="middle">
                <Col span={18}>
                  <Space direction="vertical" size={-8}>
                    <Text className="dark-color" style={{ fontSize: 28, fontWeight: 'bold' }}>Daftar dan bergabunglah bersama jobshub</Text>
                    <Text className="dark-color" style={{ fontSize: 28, fontWeight: 'bold' }}>untuk meningkatkan jenjang karir</Text>
                  </Space>
                </Col>
                <Col className="width-100" span={6}>
                  <Link to="/register">
                    <Button type="primary" style={{ border: '#32ab6d', background: '#32ab6d', borderRadius: 32, width: 180, height: 40 }}>Daftar akun <ArrowRightOutlined /></Button>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row> */}
      <Row style={{ width: '100%', backgroundColor: '#fff', paddingBottom: xs ? 16 : 32 }}>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Card bordered={false} style={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover', width: '100%', borderRadius: 16, boxShadow: '0px 4px 8px rgb(70 103 152 / 1)', backgroundColor: '#4273b9' }}>
            <Row style={{ padding: xs ? 4 : 32 }} justify="space-around" align="middle">
              <Col xs={24} lg={18}>
                {
                  xs ?
                    <Text className="white-color" style={{ fontSize: 16, fontWeight: 'bold' }}>{t('jobDomestic.button1')}</Text>
                  :
                    <Space direction="vertical" size={-8}>
                      <Text className="white-color" style={{ fontSize: 28, fontWeight: 'bold' }}>{t('jobDomestic.button1')}</Text>
                      {/* <Text className="white-color" style={{ fontSize: 28, fontWeight: 'bold' }}>untuk meningkatkan jenjang karir</Text> */}
                    </Space>
                }
              </Col>
              <Col className="width-100" xs={24} lg={6}>
                <Link to="/register">
                  {
                    xxl ?
                      <Button type="primary" style={{ border: '#32ab6d', background: '#32ab6d', borderRadius: 32, width: '80%', height: 60, boxShadow: '0px 4px 6px rgb(70 103 152 / 1)', fontSize: 23, fontWeight: 500 }}>{t('jobDomestic.button2')} <ArrowRightOutlined /></Button>
                    : xs ?
                      <Button type="primary" style={{ border: '#32ab6d', background: '#32ab6d', borderRadius: 32, width: '100%', height: 40, boxShadow: '0px 4px 6px rgb(70 103 152 / 1)', marginTop: 16, fontSize: 16, fontWeight: 500 }}>{t('jobDomestic.button2')} <ArrowRightOutlined /></Button>
                    :
                      <Button type="primary" style={{ border: '#32ab6d', background: '#32ab6d', borderRadius: 32, width: '90%', height: 50, boxShadow: '0px 4px 6px rgb(70 103 152 / 1)', fontSize: 20, fontWeight: 500 }}>{t('jobDomestic.button2')} <ArrowRightOutlined /></Button>
                  }
                </Link>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withTranslation()(cta)