import React from 'react';
import { Row, Col, Space, Typography, Form, Input, Radio, Checkbox, Grid } from 'antd';
import CButton from '../../../../components/Button';
const { Text } = Typography
const { useBreakpoint } = Grid;
function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function Form3(props) {
  const { xs } = GetBreakPoint();
  const { formRef, form3, f3, handleRadioStartJob, handleCheckboxLainnya, handlePrev, onFinish } = props
  return (
    <React.Fragment>
      <Row className="text-align-center mb-16 mt-16">
        <Col span={24}>
          <Text className="primary-color fs-17">Lamanya Waktu Mencari Pekerjaan</Text>
        </Col>
      </Row>

      <Form 
        ref={formRef}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          f301: f3?.f301 ? f3.f301 : form3.startJob,
          f302: f3?.f302 ? f3.f302.toString() : null,
          f303: f3?.f303 ? f3.f303.toString() : null,
          f402: f3?.f402 ? f3.f402 : null,
          f403: f3?.f403 ? f3.f403 : null,
          f404: f3?.f404 ? f3.f404 : null,
          f405: f3?.f405 ? f3.f405 : null,
          f407: f3?.f407 ? f3.f407 : null,
          f408: f3?.f408 ? f3.f408 : null,
          f409: f3?.f409 ? f3.f409 : null,
          f410: f3?.f410 ? f3.f410 : null,
          f411: f3?.f411 ? f3.f411 : null,
          f412: f3?.f412 ? f3.f412 : null,
          f413: f3?.f413 ? f3.f413 : null,
          f414: f3?.f414 ? f3.f414 : null,
          f415: f3?.f415 ? f3.f415 : null,
          f416: f3?.f416 ? f3.f416 : null,
          f6: f3?.f6 ? f3.f6.toString() : null,
          f7: f3?.f7 ? f3.f7.toString() : null,
          f7a: f3?.f7a ? f3.f7a.toString() : null,
        }}
      >
        <Row className="mb-16">
          <Col span={24}>
            <Form.Item 
              className="mb-8"
              name="f301"
              label="Kapan mulai mencari pekerjaan?"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <Radio.Group onChange={handleRadioStartJob}>
                <Space direction={xs?'vertical':'horizontal'}>
                  <Radio key="1" value="1">Sebelum lulus</Radio>
                  <Radio key="2" value="2">Setelah lulus</Radio>
                  <Radio key="3" value="3">Saya tidak mencari kerja</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            
          </Col>
          {
            form3.startJob !== "3" ?
              <React.Fragment>
                <Col span={24}>
                  {
                    form3.startJob === "1" ?
                      <Form.Item 
                        name="f302"
                        label="Berapa bulan sebelum kelulusan"
                        rules={[
                          { required: form3.startJob === 1, message: 'This field is mandatory!' },
                          { pattern: /^[0-9]*$/, message: 'Number only' },
                          { max: 2, message: '2 characters only' },
                        ]}
                      >
                        <Input placeholder="Misalnya: 16" suffix="bulan" />
                      </Form.Item>
                    : form3.startJob === "2" ?
                        <Form.Item 
                          name="f303"
                          label="Berapa bulan setelah kelulusan"
                          rules={[
                            { required: form3.startJob === 2, message: 'This field is mandatory!' },
                            { pattern: /^[0-9]*$/, message: 'Number only' },
                            { max: 2, message: '2 characters only' },
                          ]}
                        >
                          <Input placeholder="Misalnya: 16" suffix="bulan" />
                        </Form.Item>
                    : null
                  }
                </Col>

                <Row className="width-100">
                  <Col span={24}>
                    <Space className="mb-8">
                      <Text>Bagaimana cara mencari pekerjaan? (jawaban bisa lebih dari 1)</Text>
                    </Space>

                    <Form.Item 
                      className="margin-0"
                      name="f402"
                      valuePropName="checked"
                    >
                      <Checkbox>Melamar ke perusahaan tanpa mengetahui lowongan yang ada</Checkbox>
                    </Form.Item>

                    <Form.Item 
                      className="margin-0"
                      name="f403"
                      valuePropName="checked"
                    >
                      <Checkbox>Pergi ke bursa/pameran kerja</Checkbox>
                    </Form.Item>

                    <Form.Item 
                      className="margin-0"
                      name="f404"
                      valuePropName="checked"
                    >
                      <Checkbox>Mencari lewat internet/iklan online/milis</Checkbox>
                    </Form.Item>

                    <Form.Item 
                      className="margin-0"
                      name="f405"
                      valuePropName="checked"
                    >
                      <Checkbox>Dihubungi oleh perusahaan</Checkbox>
                    </Form.Item>

                    <Form.Item 
                      className="margin-0"
                      name="f407"
                      valuePropName="checked"
                    >
                      <Checkbox>Menghubungi agen tenaga kerja komersial/swasta</Checkbox>
                    </Form.Item>

                    <Form.Item 
                      className="margin-0"
                      name="f408"
                      valuePropName="checked"
                    >
                      <Checkbox>Memeroleh informasi dari pusat/kantor pengembangan karir fakultas/universitas</Checkbox>
                    </Form.Item>

                    <Form.Item 
                      className="margin-0"
                      name="f409"
                      valuePropName="checked"
                    >
                      <Checkbox>Menghubungi kantor kemahasiswaan/hubungan alumni</Checkbox>
                    </Form.Item>

                    <Form.Item 
                      className="margin-0"
                      name="f410"
                      valuePropName="checked"
                    >
                      <Checkbox>Membangun jejaring (network) sejak masih kuliah</Checkbox>
                    </Form.Item>

                    <Form.Item 
                      className="margin-0"
                      name="f411"
                      valuePropName="checked"
                    >
                      <Checkbox>Melalui relasi (misalnya dosen, orang tua, saudara, teman, dll.)</Checkbox>
                    </Form.Item>

                    <Form.Item 
                      className="margin-0"
                      name="f412"
                      valuePropName="checked"
                    >
                      <Checkbox>Membangun bisnis sendiri</Checkbox>
                    </Form.Item>

                    <Form.Item 
                      className="margin-0"
                      name="f413"
                      valuePropName="checked"
                    >
                      <Checkbox>Melalui penempatan kerja atau magang</Checkbox>
                    </Form.Item>

                    <Form.Item 
                      className="margin-0"
                      name="f414"
                      valuePropName="checked"
                    >
                      <Checkbox>Bekerja di tempat yang sama dengan tempat kerja semasa kuliah</Checkbox>
                    </Form.Item>

                    <Form.Item 
                      className="margin-0"
                      name="f415"
                      valuePropName="checked"
                    >
                      <Checkbox onChange={handleCheckboxLainnya}>Lainnya:</Checkbox>
                    </Form.Item>
                    
                    {
                      form3.lainnya ?
                        <Form.Item 
                          name="f416"
                          validateFirst
                          rules={[
                            { required: form3.lainnya, message: 'This field is mandatory!' },
                            { min: 3, message: 'At least 3 characters' },
                            { max: 50, message: '50 characters only' },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      : null
                    }

                    <Form.Item 
                      className="mt-16"
                      name="f6"
                      label="Jumlah lamaran yang dikirim ke perusahaan/instansi/institusi sebelum mendapatkan pekerjaan pertama:"
                      rules={[
                        { pattern: /^[0-9]*$/, message: 'Number only' },
                        { max: 3, message: '3 characters only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item 
                      name="f7"
                      label="Jumlah lamaran yang mendapat respons/feedback dari perusahaan/instansi/institusi:"
                      rules={[
                        { pattern: /^[0-9]*$/, message: 'Number only' },
                        { max: 3, message: '3 characters only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item 
                      name="f7a"
                      label="Jumlah perusahaan/instansi/institusi yang mengundang untuk melakukan wawancara:"
                      rules={[
                        { pattern: /^[0-9]*$/, message: 'Number only' },
                        { max: 3, message: '3 characters only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </React.Fragment>
            : null
          }
        </Row>
        <Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <CButton className="width-100" type="primary" ghost action={handlePrev} title="Sebelumnya"/>
            </Col>
            <Col span={12}>
              <CButton className="width-100" htmlType="submit" type="primary" title="Selanjutnya"/>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </React.Fragment>
  )
}
