export const eventType = [
  {
    "value": "SPEAKER_SESSION",
    "name": "Speaker Session"
  },
  {
    "value": "CONFERENCES",
    "name": "Conferences"
  },
  {
    "value": "NETWORKING_SESSION",
    "name": "Networking Session"
  },
  {
    "value": "SEMINAR",
    "name": "Seminar"
  },
  {
    "value": "WEBINARS",
    "name": "Webinars"
  },
  {
    "value": "AWARD_AND_COMPETITIONS",
    "name": "Awards and competitions"
  },
  {
    "value": "EXHIBITIONS",
    "name": "Exhibitions"
  }
]