import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CButton from '../../components/Button';
import { Row, Col, Grid, Space, Typography, Form, Select, Card, Image, AutoComplete, Button } from 'antd';
import EmptyLogo from '../../assets/img/EmptyLogo.png';
import { ArrowRightOutlined } from '@ant-design/icons';

const { useBreakpoint } = Grid;
const { Text } = Typography;
const { Option, OptGroup } = Select;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

function GetTranslation() {
  const t = useTranslation()
  return t;
}

export default function job(props) {
  const { getDataLanding, getJobDestination, formRef, handleDestination, onFinish, history, listJobPosition, onSearchJobPosition } = props
  const { xs, md } = GetBreakPoint()
  const { t } = GetTranslation()

  const onClickApply = (val) => {
    localStorage.setItem('link', val);
    localStorage.setItem('visible', true);
    history.push('/login-or-register')
  }
  
  return (
    <React.Fragment>
      <Row style={ md ? { paddingTop: 64, backgroundColor: '#fff' } : { paddingTop: 20, backgroundColor: '#fff' } }>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          {
            md ?
              <Space className="width-100 mb-64" direction="vertical" size={-8}>
                <Text className="dark-color" style={{ fontSize: 28, fontWeight: 'bold', lineHeight: 1 }}>{t('landingPage.titleFilter')}</Text>
                <Text className="dark-color" style={{ fontSize: 28, fontWeight: 'bold' }}>{t('landingPage.titleFilter2')}</Text>
              </Space>
            :
              <Space className="width-100 mb-16 text-align-center" direction="vertical">
                <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'bold', lineHeight: 1 }}>{t('landingPage.titleFilter')} {t('landingPage.titleFilter2')}</Text>
              </Space>
          }
          <Form ref={formRef} onFinish={onFinish} layout="vertical">
            <Row gutter={16}>
              <Col xs={24} md={10} style={{ marginBottom: -16 }}>
                <Form.Item name="search" label={ md ? t('landingPage.jobPosition') : null }>
                  <AutoComplete
                    className="width-100 border-radius-6"
                    onSearch={onSearchJobPosition}
                    // notFoundContent={loading ? <Spin size="small" /> : null}
                    filterOption={false}
                    placeholder={ xs ? t('landingPage.jobPosition') : null }
                    size="large"
                  >
                    {
                      listJobPosition?.data?.map((item, i) => (
                        <AutoComplete.Option key={i} value={item.title}>
                          {item.title}
                        </AutoComplete.Option>
                      ))
                    }
                  </AutoComplete>
                </Form.Item>
              </Col>
              <Col xs={24} md={10} style={{ marginBottom: -16 }}>
                <Form.Item name="location" label={ md ? t('landingPage.jobLocation') : null }>
                  <Select 
                    className="border-radius-6"
                    allowClear 
                    showSearch
                    onChange={handleDestination}
                    placeholder={ xs ? t('landingPage.jobLocation') : null }
                    size="large"
                  >
                    {
                      getJobDestination?.data?.map((item, i) => 
                        <OptGroup key={i} label={item.name}>
                          {
                            item?.listDestination?.map((res) => 
                              <Option key={res.id} value={res.name}>{res.name}</Option>
                            )
                          }
                        </OptGroup>
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={4}>
                <Form.Item style={ md ? { marginTop: 30 } : null }>
                  <CButton type="primary" htmlType="submit" title={t('landingPage.buttonFilter')} size="large" className="width-100" />
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Row className={ md ? "mb-64" : null } gutter={[16, 16]} justify="space-around" align="middle">
            {
              getDataLanding?.data?.listJob.map((item, i) =>
                <Col className="featured-jobs text-align-center" key={i} xs={24} lg={8}>
                  <Card 
                    className="border-radius-6 box-shadow width-100" 
                    bordered={false} 
                    style={{ height: 330 }}
                  >
                    <Space className="width-100" direction="vertical" size={8}>
                      {/* <Link to={`/company/${item.companyId}/${item.companyType}`}> */}
                      <Link to={`/job/detail/${item.slug}`}>
                        <Image
                          className="cursor-pointer"
                          src={item.companyLogoUrl ? item.companyLogoUrl : EmptyLogo}
                          preview={false}
                          width={'50%'}
                          height={120}
                          style={{ objectFit: 'contain' }}
                        />
                      </Link>
                      <Space className="width-100" direction="vertical" size={4}>
                        <Link to={`/job/detail/${item.slug}`}>
                          <Text className="title-job fs-18 dark-color" strong>{item.jobTitle}</Text>
                        </Link>
                        <Space direction="vertical" size={-4}>
                          <Link to={`/company/${item.companyId}/${item.companyType}`}>
                            <Text className="company-job fs-17 dark-color" strong>{item.companyName}</Text>
                          </Link>
                          <Text className="fs-16 dark-color">{item.jobDestinationParentName ? `${item.jobDestinationName}, ${item.jobDestinationParentName}` : item.jobDestinationName}</Text>
                        </Space>
                        <Text className="fs-16 primary-color">
                          {
                            item.isDisplaySalary ?
                              item.maxSalary ? 
                                `${item.salary ? item.salary.toLocaleString() : null} - ${item.maxSalary ? item.maxSalary.toLocaleString() : null} ${item.currencyCode}` 
                              : 
                                `${item.salary ? item.salary.toLocaleString() : null} ${item.currencyCode}`
                            :
                              t('landingPage.salary')
                          }
                        </Text>
                        <Text className="dark-color fs-15">{item.agencyName ? `Recruited by ${item.agencyName}` : null}</Text>
                        <Space className="width-100" direction="vertical">
                          <CButton type="primary" htmlType="submit" title={t('landingPage.buttonApply')} className="width-100" action={() => onClickApply(`/job/detail/${item.slug}`)} />
                        </Space>
                      </Space>
                    </Space>
                  </Card>
                </Col>
              )
            }
            <Col className="width-100 mb-16 text-align-center" xs={24} lg={8}>
              <Link className="width-100 text-align-center" to="/job">
                <Button className="box-shadow text-align-center" type="primary" style={{ border: '#4273b9', background: '#4273b9', borderRadius: 32, fontSize: 20, fontWeight: 500, width: '90%', height: 50, boxShadow: '0px 4px 6px rgb(70 103 152 / 1)' }}>{t('landingPage.buttonBrowse')} <ArrowRightOutlined /></Button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}
