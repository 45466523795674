const LOAD_DATA_MASTER_FIELD_OF_STUDY         = 'LOAD_DATA_MASTER_FIELD_OF_STUDY'
const LOAD_DATA_MASTER_FIELD_OF_STUDY_SUCCESS = 'LOAD_DATA_MASTER_FIELD_OF_STUDY_SUCCESS'
const LOAD_DATA_MASTER_FIELD_OF_STUDY_FAILED  = 'LOAD_DATA_MASTER_FIELD_OF_STUDY_FAILED'
const UNMOUNT_DATA_MASTER_FIELD_OF_STUDY      = 'UNMOUNT_DATA_MASTER_FIELD_OF_STUDY'

const initialState = {
  loading: true,
  data: null,
  message: null,
  meta: null
}

const fieldOfStudyReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DATA_MASTER_FIELD_OF_STUDY:
      return {
        ...state,
        loading: true,
        data: null,
        meta: null
      }
    case LOAD_DATA_MASTER_FIELD_OF_STUDY_SUCCESS:
      return {
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
        meta: action.payload.meta
      }
    case LOAD_DATA_MASTER_FIELD_OF_STUDY_FAILED:
      return {
        ...state, 
        loading: false,
        data: null,
        message: 'FAILED',
        meta: null
      }
    case UNMOUNT_DATA_MASTER_FIELD_OF_STUDY:
      return { 
        ...state, 
        loading: true,
        data: null,
        meta: null
      }
    default:
      return state
  }
}
export default fieldOfStudyReducer