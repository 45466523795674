import React, { Component } from 'react'
import { Card , Typography, Row, Col, Space, Breadcrumb} from 'antd';
import { withTranslation } from 'react-i18next';
import CButton from '../../../../../../../components/Button';

const { Text } = Typography;

export class S2Info extends Component {
  render() {
    const { handleNext , t} = this.props

    return (
      <React.Fragment>
        <Row className='mt-16'>
          <Col lg={{ span: 20, offset: 2 }} xxl={{ span: 18, offset: 3 }}>
            <Breadcrumb className="mb-16" >
              <Breadcrumb.Item>Career Pathway</Breadcrumb.Item>
              <Breadcrumb.Item>Area Karir</Breadcrumb.Item>
            </Breadcrumb>
            <Card className="card-body-info-assessment mt-16 mb-16 border-radius-8"> 
              <Space direction='vertical' size={16}>
                <Space className="text-align-center width-100" direction="vertical" size={0}>
                  <Text className='dark-color' style={{ fontSize: 16, fontWeight: '600' }} strong>Section 2</Text>
                  {/* <Text style={{ fontSize: 20, fontWeight: 'normal', color: "#32AB6D" }} strong>{t('pathway.structure')} & {t('pathway.written')}</Text> */}
                  <Text style={{ fontSize: 20, fontWeight: 'normal', color: "#32AB6D" }} strong>Structure & Written Expression</Text>
                </Space>
                <Space direction='vertical' size={16}>
                  <Text className='fs-15' style={{ fontStyle: 'italic' }}>
                  The Structure and Written Expression section is designed to measure your ability to recognize language that is appropriate for standard written English and it's consists of two parts and 30 questions. There are two types of questions in this section, with special directions for each type.
                  </Text>
                  <Text className='fs-15'>
                  Bagian Struktur dan Ekspresi Tertulis dirancang untuk mengukur kemampuan Anda mengenali tata bahasa yang sesuai dalam bahasa Inggris tertulis standar dan terdiri dari dua bagian dengan 30 pertanyaan. Ada dua jenis pertanyaan di bagian ini, dengan petunjuk khusus untuk setiap jenisnya.
                  </Text>
                </Space>
                <Row className='mt-32'>
                  <Col xs={{ span: 24, offset: 9 }} lg={{ span: 20, offset: 11 }} xxl={{ span: 18, offset: 10 }}>
                    <CButton type="primary" size="large" block action={() => handleNext(2,1)} title={t('pathway.start')}/>
                  </Col>
                </Row>
              </Space>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}


export default withTranslation()(S2Info)