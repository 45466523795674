import { API } from '../../../../config'
import { errorHandler } from '../../auth/errorAction'

export const unmountIndexDocumentType = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_DOCUMENT_TYPE'})
}

export const indexDocumentType = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_DOCUMENT_TYPE' })
  return API.GET('/document_type/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_DOCUMENT_TYPE_SUCCESS', 
      payload: {
        data: response.data
      }
    })
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_DOCUMENT_TYPE_FAILED' }), 
    ))
  })
}