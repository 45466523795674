import React from 'react'
import Countdown from "react-countdown";
import { Card, Progress, Breadcrumb, Typography } from 'antd';
import { withTranslation } from 'react-i18next';

const { Text } = Typography;

export function Header(props) {
  const { result, countDown, renderedCountdown, handleTimesUp, t } = props
  return (
    <React.Fragment>
      <Breadcrumb className="mb-16" >
        <Breadcrumb.Item>Career Pathway</Breadcrumb.Item>
        <Breadcrumb.Item>Area Karir</Breadcrumb.Item>
      </Breadcrumb>
      <Card className="mt-16 mb-16 border-radius-8" bodyStyle={{ padding: 16 }}>
        <Countdown
          date={countDown.date + countDown.delay}
          renderer={renderedCountdown}
          onStart={(delta) => {
            //Save the end date
            if (localStorage.getItem("end_date") == null)
              localStorage.setItem(
                "end_date",
                JSON.stringify(countDown.date + countDown.delay)
              );
          }}
          onComplete={() => {
            localStorage.removeItem("end_date")
            handleTimesUp()
          }}
        />
        <Progress percent={result?.length+3} showInfo={false} strokeWidth={16} strokeColor="#32AB6D" />
        <Text className='dark-color' style={{ fontSize: 15, fontWeight: 'normal' }}>{result?.length}/65 {t('pathway.question')}</Text>
      </Card>
    </React.Fragment>
  )
}

export default withTranslation()(Header)
