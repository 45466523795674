import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography, Space, Image, Grid, Carousel, Card } from 'antd'
import C1 from '../../assets/img/employer/c1.png';
import C2 from '../../assets/img/employer/c2.png';
import C3 from '../../assets/img/employer/c3.png';
import './Employer.css';

const { useBreakpoint } = Grid;
const { Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

function GetTranslation() {
  const t = useTranslation()
  return t;
}

export default function slider() {
  const { md, xxl } = GetBreakPoint()
  const { t } = GetTranslation()
  
  const contentStyle = {
    color: '#fff',
    height: 500
  }

  return (
    <React.Fragment>
      <Row style={md ? { paddingTop: 32, backgroundColor: '#ffffff' } : { paddingTop: 10, paddingBottom: 10, backgroundColor: '#ffffff' }}>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Row>
            <Col span={24} className="text-align-center" >
              <Space direction="vertical" size={xxl ? 24 : md ? 16 : 4} style={{ width: xxl ? '70%' : '100%' }}>
                <Text style={{ lineHeight: 1.4 }}>
                  <Text style={{ fontSize: xxl ? 36 : md ? 28 : 24, fontWeight: 'bold', color: '#32ab6d' }}>{t('pageEmployer.slider.title')} </Text>
                  <Text style={{ fontSize: xxl ? 36 : md ? 28 : 24, fontWeight: 'bold', color: '#4273b9' }}>{t('pageEmployer.slider.title2')} </Text>
                </Text>
                <Text style={{ fontSize: xxl ? 22 : 18, fontWeight: 'normal', color: '#535353' }}>{t('pageEmployer.slider.subTitle')}</Text>
              </Space>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Carousel className="employer-carousel" autoplay dotPosition={ md ? "left" : "bottom" } effect='fade'>
                <div>
                  <Row justify="space-around" align="middle" style={contentStyle} gutter={32}>
                    <Col xs={24} md={2}></Col>
                    <Col xs={24} md={8}>
                      <Card className="card-slider" bordered={false} style={{ borderRadius: 16, boxShadow: '0px 4px 8px #cccccc', backgroundColor: '#32ab6d' }}>
                        <Row justify="space-around" align="middle">
                          <Col xs={24} lg={18}>
                            <Space direction="vertical" size={8}>
                              <Text className="white-color" style={{ fontSize: xxl ? 24 : 20, fontWeight: 'bold' }}>{t('pageEmployer.slider.titleContent')}</Text>
                              <Text className="white-color" style={{ fontSize: xxl ? 20 : 18, fontWeight: 'normal', lineHeight: 1 }}>{t('pageEmployer.slider.content')}</Text>
                            </Space>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col xs={24} md={14} style={{ width: '100%' }}>
                      <Image
                        width={'100%'}
                        height={md ? 500 : 240}
                        src={C1}
                        preview={false}
                        style={md ? { padding: 60, objectFit: 'contain' } : { height: 240, paddingBottom: 30, paddingTop: 20, objectFit: 'contain' }}
                      />
                    </Col>
                  </Row>
                </div>

                <div>
                  <Row justify="space-around" align="middle" style={contentStyle} gutter={32}>
                    <Col xs={24} md={2}></Col>
                    <Col xs={24} md={8}>
                      <Card className="card-slider" bordered={false} style={{ borderRadius: 16, boxShadow: '0px 4px 8px #cccccc', backgroundColor: '#32ab6d' }}>
                        <Row justify="space-around" align="middle">
                          <Col xs={24} lg={18}>
                            <Space direction="vertical" size={8}>
                              <Text className="white-color" style={{ fontSize: xxl ? 24 : 20, fontWeight: 'bold' }}>{t('pageEmployer.slider.titleContent2')}</Text>
                              <Text className="white-color" style={{ fontSize: xxl ? 20 : 18, fontWeight: 'normal', lineHeight: 1 }}>{t('pageEmployer.slider.content2')}</Text>
                            </Space>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col xs={24} md={14} style={{ width: '100%' }}>
                      <Image
                        width={'100%'}
                        height={md ? 500 : 240}
                        src={C2}
                        preview={false}
                        style={md ? { padding: 60, objectFit: 'contain' } : { height: 240, paddingBottom: 30, paddingTop: 20, objectFit: 'contain' }}
                      />
                    </Col>
                  </Row>
                </div>

                <div>
                  <Row justify="space-around" align="middle" style={contentStyle} gutter={32}>
                    <Col xs={24} md={2}></Col>
                    <Col xs={24} md={8}>
                      <Card className="card-slider" bordered={false} style={{ borderRadius: 16, boxShadow: '0px 4px 8px #cccccc', backgroundColor: '#32ab6d' }}>
                        <Row justify="space-around" align="middle">
                          <Col xs={24} lg={18}>
                            <Space direction="vertical" size={8}>
                              <Text className="white-color" style={{ fontSize: xxl ? 24 : 20, fontWeight: 'bold' }}>{t('pageEmployer.slider.titleContent3')}</Text>
                              <Text className="white-color" style={{ fontSize: xxl ? 20 : 18, fontWeight: 'normal', lineHeight: 1 }}>{t('pageEmployer.slider.content3')}</Text>
                            </Space>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col xs={24} md={14} style={{ width: '100%' }}>
                      <Image
                        width={'100%'}
                        height={md ? 500 : 240}
                        src={C3}
                        preview={false}
                        style={md ? { padding: 60, objectFit: 'contain' } : { height: 240, paddingBottom: 30, paddingTop: 20, objectFit: 'contain' }}
                      />
                    </Col>
                  </Row>
                </div>
              </Carousel>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}
