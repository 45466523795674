import React, { Component } from 'react'
import ServicePlusListComponent from '../../../components/ServicePlus/List'

export default class ServicePlusList extends Component {
  render() {
    return (
      <React.Fragment>
        <ServicePlusListComponent />
      </React.Fragment>
    )
  }
}