import React, { Component } from 'react'
import { Card , Typography, Row, Col, Space, Breadcrumb } from 'antd';
import { withTranslation } from 'react-i18next';
import CButton from '../../../../../../../components/Button';

const { Text } = Typography;

export class S1Info extends Component {
  render() {
    const { handleNext, t } = this.props

    return (
      <React.Fragment>
        <Row className='mt-16'>
          <Col lg={{ span: 20, offset: 2 }} xxl={{ span: 18, offset: 3 }}>
            <Breadcrumb className="mb-16" >
              <Breadcrumb.Item>Career Pathway</Breadcrumb.Item>
              <Breadcrumb.Item>Area Karir</Breadcrumb.Item>
            </Breadcrumb>
            <Card className="card-body-info-assessment mt-16 mb-16 border-radius-8"> 
              <Space direction='vertical' size={16}>
                <Space className="text-align-center width-100" direction="vertical" size={0}>
                  <Text className='dark-color' style={{ fontSize: 16, fontWeight: '600' }} strong>Section 1</Text>
                  {/* <Text style={{ fontSize: 20, fontWeight: 'normal', color: "#32AB6D" }} strong>{t('pathway.listening')}</Text> */}
                  <Text style={{ fontSize: 20, fontWeight: 'normal', color: "#32AB6D" }} strong>Listening</Text>
                </Space>
                <Space direction='vertical' size={16}>
                  <Text className='fs-15' style={{ fontStyle: 'italic' }}>
                    For listening section, please listen to the audio before answering the question! In this section of the test, you will hear dialogues, and you will be tested on your ability to understand them. You will hear each dialogue, and then answer questions after each is finished. The questions ask about the main idea, supporting details, and the way the speakers use language. Answer each question based on what is stated or implied by the speakers.
                  </Text>
                  <Text className='fs-15'>
                    Dengarkan audio yang terletak di atas setiap pertanyaan sebelum menjawab pertanyaan di bawah ini! Di bagian tes ini, Anda akan mendengar dialog, dan kemampuan Anda akan diuji untuk memahaminya. Anda akan mendengar setiap dialog, dan kemudian menjawab pertanyaan setelah masing-masing selesai. Pertanyaan tersebut menanyakan tentang gagasan utama, detail pendukung, dan cara penutur menggunakan bahasa. Jawablah setiap pertanyaan berdasarkan apa yang dinyatakan atau tersirat oleh pembicara.
                  </Text>
                </Space>
                <Row className='mt-32'>
                  <Col xs={{ span: 24, offset: 9 }} lg={{ span: 20, offset: 11 }} xxl={{ span: 18, offset: 10 }}>
                    <CButton type="primary" size="large" block action={() => handleNext(1,1)} title={t('pathway.start')}/>
                  </Col>
                </Row>
              </Space>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default withTranslation()(S1Info)