import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Card, Typography, Space, Progress } from 'antd';
import { CloseCircleTwoTone } from '@ant-design/icons';

const { Text } = Typography;

export class Completeness extends Component {
  render() {
    const { t, data: { profileCompletness: { percentage, listDetail } } } = this.props
    
    return (
      <React.Fragment>
        {
          percentage === 100 ?
            <Card className="border-radius-6 box-shadow mt-16">
              <Space className="width-100 fs-17" direction="vertical">
                <Text className="primary-color">{t('profile.completeness.title')}</Text>
                <Progress 
                  strokeColor={{
                    '0%': '#1877f2',
                    '100%': '#32ab6d',
                  }} 
                  percent={percentage} 
                  status="active"
                  style={{ marginLeft: 6 }}
                />
              </Space>
            </Card>
          :
            <Card
              className="border-radius-6 box-shadow"
              title={
                <Space className="width-100" direction="vertical">
                  <Text className="primary-color">{t('profile.completeness.title')}</Text>
                  <Progress 
                    strokeColor={{
                      '0%': '#1877f2',
                      '100%': '#32ab6d',
                    }} 
                    percent={percentage} 
                    // status="active"
                  />
              </Space>
              }
              headStyle={{ color: '#32ab6d', fontSize: 17 }}
            >
              <Space direction="vertical">
                {
                  percentage < 100 ?
                    <Text className="dark-color">{t('profile.completeness.subTitle')}</Text>
                  : null
                }
                {
                  listDetail.filter(item => item.isCheck === false).map((item, i) => 
                    <Space key={i} size={8}>
                      <CloseCircleTwoTone twoToneColor="#f58181" /><Text className="dark-color">
                        {
                          item.name === 'Job Preference' ?
                            t('profile.jobPreferences.title')
                          : item.name === 'Photo' ?
                            t('profile.header.formImage.title')
                          : item.name === 'Current Location' ?
                            t('profile.header.formLocation.title')
                          : item.name === 'Personal Information' ?
                            t('profile.personalInfo.title')
                          : item.name === 'Address' ?
                            t('profile.address.title')
                          : item.name === 'Work Experience' ?
                            t('profile.workExperience.title')
                          : item.name === 'Education' ?
                            t('profile.education.title')
                          : item.name === 'Training and Certification' ?
                            t('profile.training.title')
                          : item.name === 'About' ?
                            t('profile.aboutMe.title')
                          : item.name === 'Skills' ?
                            t('profile.skills.title')
                          : item.name === 'Portfolios' ?
                            t('profile.portfolio.title')
                          : item.name === 'Achievements' ?
                            t('profile.achievment.title')
                          : item.name === 'Social Media' ?
                            t('profile.header.formSocialMedia.title')
                          : item.name === 'Documents' ?
                            t('profile.document.title')
                          : item.name === 'Language' ?
                            t('profile.language.title')
                          : item.name === 'Uniform' ?
                            t('profile.uniform.title')
                          : null
                        }
                      </Text>
                    </Space>
                  )
                }
              </Space>
            </Card>
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Completeness))
