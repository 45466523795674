import React, { Component } from 'react';
import * as Containers from '../containers';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import Cookie from 'js-cookie';
import { Result } from 'antd';
import { Loading } from '../components';
import { convertMenu } from '../utils/menuSelection';
import { PrivateRoute, AuthRoute } from './route'
import { checkAuth } from '../redux/actions/auth/authAction';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import TagManager from 'react-gtm-module'
import { Helmet } from "react-helmet";

if(process.env.REACT_APP_ENV === "production"){
  const tagManagerArgs = {
    gtmId: 'GTM-5TPJGMG'
  }
  TagManager.initialize(tagManagerArgs)
  ReactGA.initialize("UA-158765154-2");
}

export class AppRoute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _menu: null,
      isDisconnected: false,
      channelRegister: '',
      channelJobDetail: '',
      nameReferal: ''
    }
  }

  componentDidMount() {
    const { actionCheckAuth } = this.props;
    if(window.location.pathname === '/candidate/applications/interview'){
      localStorage.setItem('path-interview', window.location.pathname);
    }
    if(window.location.pathname.split('/').slice(0,3)[2] === 'register'){
      this.setState({ channelRegister: window.location.pathname.split('/').slice(0,3)[1] })
    }
    if(!Cookie.get('user')){
      if(window.location.pathname.split('/')[2] === 'job' && window.location.pathname.split('/')[3] === 'detail'){
        this.setState({ channelJobDetail: window.location.pathname.split('/').slice(0,3)[1] })
      }
    }
    if(window.location.pathname.split('/').slice(0,3)[1] === 'ref'){
      this.setState({ nameReferal: window.location.pathname.split('/').slice(0,3)[2] })
    }
    localStorage.removeItem('imageCandidate')
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
    return actionCheckAuth()
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    const { permission, loading, userType } = nextProps
    !loading && convertMenu(permission, userType, (response) => {
      this.setState({ _menu: response })
    })
  }

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const webPing = setInterval(
        () => {
          fetch('/google.com', {
            mode: 'no-cors',
            })
          .then(() => {
            this.setState({ isDisconnected: false }, () => {
              return clearInterval(webPing)
            });
          }).catch(() => this.setState({ isDisconnected: true }) )
        }, 2000);
      return;
    }
    return this.setState({ isDisconnected: true });
  }

  render() {
    const { loading } = this.props;
    const { _menu, isDisconnected, channelRegister, channelJobDetail, nameReferal } = this.state;
    
    if(loading){ 
      return <Loading />
    }

    if(process.env.REACT_APP_ENV === "production"){
      <Helmet>
        <meta name="facebook-domain-verification" content="htx5ke5a0d44ityyczoad6o6advikg" />
        {/* Note: Verification will fail if the meta-tag code is outside of the <head> section or in a section loaded dynamically by JavaScript. */}
        {/* TASK 2: GOOGLE SEARCH CONSOLE
        Copy the meta tag below, and paste it into your site's home page. It should go in the <head> section, before the first <body> section. */}
        <meta name="google-site-verification" content="QGlYN5P5-tYs66MsO1SGzit84IF2WOvTXZUV4JpBWsE" />
      </Helmet>
    }
    
    return (
      <Router>
        <Switch>
          <AuthRoute exact path='/' component={Containers.Home} passProps={this.props} private={false} />
          <AuthRoute exact path='/job' component={Containers.Job} passProps={this.props} private={false} />
          <AuthRoute exact path='/job/detail/:slug' component={Containers.JobDetail} passProps={this.props} private={false} />
          <AuthRoute exact path={`/${channelJobDetail}/job/detail/:slug`} component={Containers.JobDetail} passProps={this.props} private={false} />
          <AuthRoute exact path='/login' component={Containers.Login} passProps={this.props} private={false} />
          <AuthRoute exact path='/register' component={Containers.Register} passProps={this.props} private={false} />
          <AuthRoute exact path={`/${channelRegister}/register`} component={Containers.ChannelRegister} passProps={this.props} private={false} />
          <AuthRoute exact path={`/ref/${nameReferal}/register`} component={Containers.RefRegister} passProps={this.props} private={false} />
          <AuthRoute exact path='/register/confirm-email' component={Containers.ConfirmEmail} passProps={this.props} private={false} />
          <AuthRoute exact path='/company/:id/:companyType' component={Containers.Company} passProps={this.props} private={false} />
          <AuthRoute exact path='/forgot-password' component={Containers.ForgotPassword} passProps={this.props} private={false} />
          <AuthRoute exact path='/forgot-password/verify' component={Containers.Verify} passProps={this.props} private={false} />
          <AuthRoute exact path='/forgot-password/change-password' component={Containers.ChangePassword} passProps={this.props} private={false} />
          <AuthRoute exact path='/privacy-policy' component={Containers.PrivacyPolicy} passProps={this.props} private={false} />
          <AuthRoute exact path='/terms-and-conditions' component={Containers.TermsAndConditions} passProps={this.props} private={false} />
          <AuthRoute exact path='/login-or-register' component={Containers.Bridging} passProps={this.props} private={false} />
          <AuthRoute exact path='/domestic' component={Containers.Domestic} passProps={this.props} private={false} />
          <AuthRoute exact path='/international' component={Containers.International} passProps={this.props} private={false} />
          <AuthRoute exact path='/about-us' component={Containers.AboutUs} passProps={this.props} private={false} />
          <AuthRoute exact path='/faq' component={Containers.Faq} passProps={this.props} private={false} />
          <AuthRoute exact path='/employer' component={Containers.Employer} passProps={this.props} private={false} />
          <AuthRoute exact path='/employer/registration' component={Containers.EmployerRegistration} passProps={this.props} private={false} />
          <AuthRoute exact path='/employer/registration/success' component={Containers.EmployerRegistrationSuccess} passProps={this.props} private={false} />
          <AuthRoute exact path='/career-pathway' component={Containers.Pathway} passProps={this.props} private={false} />
          {/* <AuthRoute exact path='/xpert' component={Containers.Xpert} passProps={this.props} private={false} /> */}
          <AuthRoute exact path='/xpert/list' component={Containers.XpertList} passProps={this.props} private={false} />
          <AuthRoute exact path='/xpert/detail/:categorySlug/:slug' component={Containers.XpertDetail} passProps={this.props} private={false} />
          <AuthRoute exact path='/service-plus/list' component={Containers.ServicePlusList} passProps={this.props} private={false} />
          <AuthRoute exact path='/service-plus/detail/:categorySlug/:slug' component={Containers.ServicePlusDetail} passProps={this.props} private={false} />
          {/* <AuthRoute exact path='/event/detail/:slug' component={Containers.EventDetail} passProps={this.props} private={false} /> */}
          {
            _menu?.map((response, i) => {
              return <PrivateRoute {...response} key={i} exact component={Containers[response.component]} passProps={this.props} />
            })
          }
          { 
            isDisconnected && (
              <React.Fragment>
                <Result status="500" title="Lost Connection" subTitle="Please check your internet connection" />
              </React.Fragment>
            )
          } 
        </Switch>
      </Router>
    )
  }
}
const mapStateToProps = (state) => ({
  loading     : state.authReducer.loading,
  authed      : state.authReducer.authed,
  permission  : state.authReducer.permission,
  userType    : state.authReducer.userType,
  isRegister  : state.authReducer.isRegister
})
const mapDispatchToProps = {
  actionCheckAuth: checkAuth
}
export default connect(mapStateToProps, mapDispatchToProps)(AppRoute)