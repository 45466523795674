import React from 'react'
import { withTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Row, Col, Typography, Space, Divider } from 'antd';
import { paymentOption } from '../../../../utils/constant/paymentOption';
const { Text } = Typography


export function paymentConfirm(props) {
  const { getOrderDetail, t, showModalPayment } = props
  return (
    <React.Fragment>
      <Row className="my-order-card mb-32" style={{ backgroundColor: 'white', border: '1px solid #e0e0e0' }}> 
        <Col span={20} offset={2} style={{ marginTop: 20 }}>
          <Text className="fs-18 dark-color font-weight-bold">{t('myOrders.paymentConfirm1.detail')}</Text>
          <Row className="mt-16" gutter={16}>
            <Col span={16}>
              <Space direction="vertical" size={0}>
                <Text className="fs-14 light-color">{t('myOrders.paymentConfirm1.name')}</Text>
                {
                  getOrderDetail.data.ticketList.map((item, i) => 
                    <Text key={i} className="fs-14 dark-color font-weight-bold">{item.name}</Text>
                  )
                }
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text className="fs-14 light-color">{t('myOrders.waitingPayment.subTotal')}</Text>
              </Space>
            </Col>
              {
                getOrderDetail.data.ticketList.map((item, i) => 
                <React.Fragment key={i}>
                  <Col span={16}>
                    <Space>
                      <Text className="fs-14 light-color">x {item.qty}</Text>
                      <Text className="fs-14 light-color">
                      {
                        `IDR ${item?.price?.toLocaleString()}`
                      }
                      </Text>
                    </Space>
                  </Col>
                  <Col span={8}>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-14 light-color">
                      {
                        `IDR ${item?.totalPrice?.toLocaleString()}`
                      }
                      </Text>
                    </Space>
                  </Col>
                </React.Fragment>
                )
              }
              {
                getOrderDetail?.data.xpertType === 'SERVICE' ? 
                  <Col span={24}>
                    <Space direction="vertical" size={0} style={{ fontStyle: 'italic'}}>
                      {
                        
                          getOrderDetail?.data?.paymentOption !== 'ONE_TIME_PAYMENT' ? 
                            <Text className="fs-14 light-color">{t('myOrders.waitingPayment.Fee')}</Text>
                          : null 
                      }
                    </Space>
                  </Col>
                : null
              }
          </Row>
          <Divider />
                <Text className="fs-18 dark-color font-weight-bold">{t('myOrders.paymentConfirm1.information')}</Text>
                <Row className="mt-16 mb-16" gutter={[0,8]}>
                  {
                    getOrderDetail?.data.xpertType === 'SERVICE' ? 
                      <Col xs={24} lg={12}>
                        <Space direction="vertical" size={0}>
                          <Text className="fs-14 light-color">{t('myOrders.waitingPayment.schema')}</Text>
                          <Text className="fs-14 dark-color font-weight-bold">{paymentOption?.find(item => item?.value === getOrderDetail?.data?.paymentOption)?.name}<InfoCircleOutlined style={{ color: '#4272b9' }} onClick={showModalPayment} /></Text>
                        </Space>
                      </Col>
                      : null
                  }
                  {
                    getOrderDetail.data.totalTransaction > 0 ?
                      <React.Fragment>
                        <Col xs={24} lg={12}>
                          <Space direction="vertical" size={0}>
                            <Text className="light-color fs-14">{t('myOrders.paymentConfirm1.method')}</Text>
                            <Text className="dark-color fs-14 font-weight-bold">Bank Transfer</Text>
                          </Space>
                        </Col>
                        <Col xs={24} lg={12}>
                          <Space direction="vertical" size={0}>
                            <Text className="fs-14 light-color">{t('myOrders.paymentConfirm1.total')}</Text>
                            <Text className="fs-14 dark-color font-weight-bold">
                              {
                                `IDR ${getOrderDetail?.data?.totalTransaction?.toLocaleString()}`
                              }
                            </Text>
                          </Space>
                        </Col>
                        <Col xs={24} lg={12}>
                          <Space direction="vertical" size={0}>
                            <Text className="light-color fs-14">{t('myOrders.paymentConfirm1.namebank')}</Text>
                            <Text className="dark-color fs-14 font-weight-bold">
                              {
                                getOrderDetail.data.xpertType === 'SERVICE' ?
                                  getOrderDetail.data.receiverBankName
                                : getOrderDetail.data.senderBankName
                              }
                            </Text>
                          </Space>
                        </Col>
                            <Col xs={24} lg={12}>
                              <Space direction="vertical" size={0}>
                                <Text className="fs-14 light-color">{t('myOrders.waitingConfirm.norek')}</Text>
                                <Text className="fs-14 dark-color font-weight-bold">
                                  {
                                    getOrderDetail?.data?.xpertType === 'SERVICE' ?
                                      getOrderDetail.data.receiverBankAccountNumber
                                    : getOrderDetail.data.senderBankAccountNumber
                                  }
                                </Text>
                              </Space>
                            </Col>
                        <Col xs={24} lg={12}>
                          <Space direction="vertical" size={0}>
                            <Text className="fs-14 light-color">{t('myOrders.waitingPayment.account')}</Text>
                            <Text className="fs-14 dark-color font-weight-bold">
                              {
                                getOrderDetail?.data?.xpertType === 'SERVICE' ?
                                  getOrderDetail?.data?.receiverBankAccountName
                                : getOrderDetail?.data?.senderBankAccountName
                              }
                            </Text>
                          </Space>
                        </Col>
                      </React.Fragment>
                    :
                      null
                  }
                </Row>
          <Text className="fs-18 dark-color font-weight-bold" >{t('myOrders.paymentConfirm1.detailTransaction')}</Text>
            <Row className="mt-16 mb-32" gutter={[0,8]}>
              <Col span={24}>
                <Space direction="vertical" size={0}>
                  <Text className="fs-14 light-color">{t('myOrders.noOrder')}</Text>
                  <Text className="fs-14 dark-color font-weight-bold">{getOrderDetail.data.xpertTransactionNumber}</Text>
                </Space>
              </Col>
              <Col span={24}>
                <Space direction="vertical" size={0}>
                  <Text className="light-color fs-14">{t('myOrders.paymentConfirm1.date')}</Text>
                  <Text className="dark-color fs-14 font-weight-bold">{getOrderDetail.data.orderDate}</Text>
                </Space>
              </Col>
              <Col span={12}>
                <Space direction="vertical" size={0}>
                  <Text className="light-color fs-14">{t('myOrders.paymentConfirm1.date2')}</Text>
                  <Text className="dark-color fs-14 font-weight-bold">{getOrderDetail.data.expiredDate}</Text>
                </Space>
              </Col>
            </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withTranslation()(paymentConfirm)