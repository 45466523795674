import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import Cookie from 'js-cookie';
import { Loading } from '../../../components';
import { Row, Col, Space, Grid } from 'antd';
import { indexProfile, unmountIndexProfile } from '../../../redux/actions/profile/profileAction'
import Header from './Header';
import JobPreferences from './JobPreferences';
import AboutMe from './AboutMe';
import PersonalInfo from './PersonalInfo';
import Address from './Address';
import WorkExperience from './WorkExperience';
import Education from './Education';
import Training from './Training';
import Document from './Document';
import Completeness from './Completeness';
import Skill from './Skill';
import Language from './Language'
import Portfolio from './Portfolio';
import Achievement from './Achievement';
import Uniform from './Uniform';

const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export const ProfileComponent = ({data, actionGetProfile, nameVisible, activeElement, setActiveElement}) => {
  const { xs, lg } = GetBreakPoint()

  return(
    <Row className="mb-32">
      <Col span={20} offset={2}>
        <Row gutter={32}>
          <Col sm={24} lg={16}>
            <Space className="width-100" direction="vertical" size={32} style={{marginBottom: 15}}>
              {
                xs ?
                  <Completeness data={data} />
                : null
              }
              {
                !xs && !lg ?
                  <Completeness data={data} />
                : null
              }
              <JobPreferences data={data} actionGetProfile={actionGetProfile} visible={nameVisible === 'Job Preference' ? true : false} />
              <AboutMe data={data} actionGetProfile={actionGetProfile} />
              <PersonalInfo data={data} actionGetProfile={actionGetProfile} />
              <Document data={data} actionGetProfile={actionGetProfile} />
              {
                Cookie.get('email')?.split("@")[1] === 'student.binawan.ac.id' ?
                  <Address data={data} actionGetProfile={actionGetProfile} />
                  : null
              }
              <WorkExperience data={data} actionGetProfile={actionGetProfile} />
              <Education data={data} actionGetProfile={actionGetProfile} />
              <Training data={data} actionGetProfile={actionGetProfile} />
            </Space>
          </Col>
          <Col className="width-100" sm={24} lg={8}>
            <Space className="width-100" direction="vertical" size={32}>
              {
                lg ?
                  <Completeness data={data} />
                : null
              }
              <Skill data={data} actionGetProfile={actionGetProfile} />
              <Language data={data} actionGetProfile={actionGetProfile} />
              <Portfolio data={data} actionGetProfile={actionGetProfile} />
              <Achievement data={data} actionGetProfile={actionGetProfile} />
              {
                Cookie.get('email')?.split("@")[1] === 'student.binawan.ac.id' ?
                  <Uniform data={data} actionGetProfile={actionGetProfile} />
                  : null
              }
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}


export class Profile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      nameVisible: null,
      activeElement: null // Added to track active element
    }
  }

  componentDidMount() {
    const { actionGetProfile } = this.props
    Cookie.remove('activeElement')
    return actionGetProfile()
  }

  componentDidUpdate() {
    window.scrollTo(0, Number(Cookie.get('activeElement')));
  }

  setActiveElement = (elementName) => {
    this.setState({ activeElement: elementName })
    Cookie.set('activeElement', elementName)
  }

  render() {
    const { nameVisible, activeElement } = this.state
    const { getProfile: { data, loading }, actionGetProfile } = this.props

    if (process.env.REACT_APP_ENV === "production") {
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Profile');
    }

    if (loading) {
      return <Loading />
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Profile</title>
        </Helmet>
        <Header data={data} actionGetProfile={actionGetProfile} />
        <ProfileComponent 
          data={data} 
          actionGetProfile={actionGetProfile} 
          nameVisible={nameVisible} 
          activeElement={activeElement} 
          setActiveElement={this.setActiveElement} // Passing the setActiveElement function
        />
      </React.Fragment>
    )
  }

  componentWillUnmount() {
    const { unmountIndexProfile } = this.props
    return unmountIndexProfile()
  }
}

const mapStateToProps = (state) => ({
  getProfile: state.profileReducer
})

const mapDispatchToProps = {
  actionGetProfile: indexProfile,
  unmountIndexProfile: unmountIndexProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)