import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import EmptyNotification from '../../assets/img/EmptyNotification.svg';
import { List, Badge, Image, Row, Col, Typography, message } from 'antd';
const { Text } = Typography;

export default class Notification extends Component {
  handleRedirect = (data) => {
    const { actionReadNotification } = this.props
    const params = {
      id: data.id
    }

    if(data.status === "NEW" && data.type !== "INTERVIEW"){
      return actionReadNotification(params, (response) => {
        if(response.code === '1000'){
          if(data.type === "REJECTED"){
            return window.location = '/candidate/applications/rejected' 
          }else{
            return window.location = data.path
          }
        }else{
          message.error(response.message)
        }
      })
    }else{
      if(data.type === "REJECTED"){
        return window.location = '/candidate/applications/rejected'
      }else{
        return window.location = data.path
      }
    }
  }

  handleInfiniteOnLoad = () => {
    ('scroll');
  };
  
  render() {
    const { listNotification: { data } } = this.props;

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {
            data?.length > 0 ?
              <React.Fragment>
                <Col span={24}>
                  <div className="demo-infinite-notifcation">
                    <InfiniteScroll hasMore={false} loadMore={this.handleInfiniteOnLoad}>
                      <List
                        dataSource={data}
                        renderItem={item => (
                          <List.Item 
                            actions={[item.status === "NEW" ? <Badge color="green" /> : null]}
                            style={{ cursor: 'pointer' }}
                          >
                            <List.Item.Meta
                              onClick={() => this.handleRedirect(item)}
                              title={<Text style={{ fontWeight: item.status === "NEW" ? 'bolder' : 'normal' }}>{item.title}</Text>}
                              description={item.createdDate}
                            />
                          </List.Item>
                        )}
                      />
                    </InfiniteScroll>
                  </div>
                </Col>
              </React.Fragment>
            :
              <React.Fragment>
                <Col span={24} style={{ textAlign: 'center', paddingTop: 32 }}>
                  <Image src={EmptyNotification} preview={false} />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Text className="dark-color">You have no notifications</Text>
                </Col>
              </React.Fragment>
          }
        </Row>
      </React.Fragment>
    )
  }
}
