import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Typography, Row, Col, Modal, Space, Button, Form, Input, Select, List, Divider, Grid, message } from 'antd';
import { LinkedinFilled, InstagramFilled, FacebookFilled, TwitterOutlined, MediumOutlined, PlusCircleOutlined, DeleteTwoTone } from '@ant-design/icons';
import { updateSocial, deleteSocial } from '../../../../../redux/actions/profile/headerAction';
import CButton from '../../../../../components/Button';
import Cookie from "js-cookie";

const { Text } = Typography;
const { Option } = Select;
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}
export const SocialMediaComponent = (props) => {
  const { t, data, showModal } = props
  const { xs } = GetBreakPoint()

  return(
    <React.Fragment>
      {
        xs ?
          <Space className="social-media" direction='vertical'>
            <Text className="fs-12 dark-color">{t('profile.header.formSocialMedia.label')}</Text>
            <Space>
              {
                data.listSocialMedia.map((item, i) => 
                  <a key={i} href={`https://${item.url}`} target="_blank" rel="noreferrer">
                    <Button 
                      type="primary" 
                      shape="circle" 
                      icon={ 
                        item.name === 'linkedin' ? 
                          <LinkedinFilled /> 
                        : item.name === 'facebook' ?
                          <FacebookFilled /> 
                        : item.name === 'twitter' ?
                          <TwitterOutlined /> 
                        : item.name === 'instagram' ?
                          <InstagramFilled /> 
                        : 
                          <MediumOutlined />
                      }
                    />
                  </a>
                )
              }
              <PlusCircleOutlined className="dark-color cursor-pointer" onClick={showModal} style={{ fontSize: 30, marginTop: 4 }} />
            </Space>
          </Space>
        :
          <Space className="social-media">
            <Text className="fs-12 dark-color">{t('profile.header.formSocialMedia.label')}</Text>
            <Divider style={{ border: '1px solid #5f6163', height: 20 }} type="vertical" />
            {
              data.listSocialMedia.map((item, i) => 
                <a key={i} href={`https://${item.url}`} target="_blank" rel="noreferrer">
                  <Button 
                    type="primary" 
                    shape="circle" 
                    icon={ 
                      item.name === 'linkedin' ? 
                        <LinkedinFilled /> 
                      : item.name === 'facebook' ?
                        <FacebookFilled /> 
                      : item.name === 'twitter' ?
                        <TwitterOutlined /> 
                      : item.name === 'instagram' ?
                        <InstagramFilled /> 
                      : 
                        <MediumOutlined />
                    }
                  />
                </a>
              )
            }
            <PlusCircleOutlined className="dark-color cursor-pointer" onClick={showModal} style={{ fontSize: 30, marginTop: 4 }} />
          </Space>
      }
    </React.Fragment>
  )
}

export class SocialMedia extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
       submitLoading: false,
       deleteLoading: false,
       visible: false,
       name: null,
       socialMedia: null,
       url: null
    }
  }
  
  showModal = () => {
    this.setState({
      visible: true,
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      name: null,
      socialMedia: null,
      url: null
    })
  };

  onChangeSelect = (e) => {
    const socialMedia =
      e === 'linkedin' ? 
        'www.linkedin.com/in/'
      : e === 'facebook' ? 
        'www.facebook.com/'
      : e === 'twitter' ? 
        'www.twitter.com/'
      : e === 'instagram' ? 
        'www.instagram.com/'
      :
        'medium.com'

    this.setState({
      name: e,
      socialMedia: socialMedia
    })
  }

  onChangeInput = (e) => {
    this.setState({
      url: e
    })
  }

  handleDelete = (id) => {
    const { actionDeleteSocial, actionGetProfile } = this.props
    
    return this.setState({ deleteLoading: true }, () => {
      return actionDeleteSocial(id, response => {
        if(response.code === '1000'){
          return this.setState({ deleteLoading: false }, () => {
            message.success(response.message)
            Cookie.remove('activeElement')
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ deleteLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  onFinish = async (values) => {
    const { socialMedia } = this.state
    const { actionUpdateSocial, actionGetProfile } = this.props

    values.url = values.name === 'medium' ? `${values.url}.${socialMedia}` : `${socialMedia}${values.url}`
    
    return this.setState({ submitLoading: true }, () => {
      return actionUpdateSocial(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            Cookie.remove('activeElement')
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const { submitLoading, deleteLoading, visible, name, socialMedia, url } = this.state
    const { t, data } = this.props
    
    const initialProps = {
      showModal: this.showModal
    }

    return (
      <React.Fragment>
        <SocialMediaComponent {...this.props} {...initialProps} />
        <Modal 
          title={t('profile.header.formSocialMedia.title')} 
          visible={visible} 
          onCancel={this.handleCancel}
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          width={800}
          destroyOnClose
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
            initialValues={{
              firstName: data.firstName,
              lastName: data.lastName
            }}
          >
            <Row gutter={8}>
              <Col xs={24} lg={5}>
                <Form.Item name="name">
                  <Select 
                    className="width-100 border-radius-6"
                    size="small" 
                    placeholder={t('profile.header.formSocialMedia.placeholderName')}
                    onChange={(e) => this.onChangeSelect(e)}
                  >
                    <Option key="1" value="linkedin"><LinkedinFilled /> Linkedin</Option>
                    <Option key="2" value="facebook"><FacebookFilled /> Facebook</Option>
                    <Option key="3" value="twitter"><TwitterOutlined /> Twitter</Option>
                    <Option key="4" value="instagram"><InstagramFilled /> Instagram</Option>
                    <Option key="5" value="medium"><MediumOutlined /> Medium</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={15}>
                <Form.Item 
                  name="url"
                  validateFirst
                  rules={[
                    { pattern: /^\S*$/, message: t('notSpace') },
                    { max: 50, message: t('max',{number:50}) }
                  ]}
                >
                  <Input addonBefore={name !== 'medium' ? socialMedia : null} addonAfter={name === 'medium' ? '.medium.com' : null} size="small" placeholder={t('profile.header.formSocialMedia.placeholderUrl')} onChange={(e) => this.onChangeInput(e.target.value)} disabled={!name} />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <CButton className="width-100 mt-4" htmlType="submit" type="primary" size="small" disabled={!name || !url} loading={submitLoading} title={t('profile.header.formSocialMedia.buttonAdd')}/>
              </Col>
            </Row>
            <Row className="mb-16">
              <Col xs={24} lg={20}>
                <List
                  size="small"
                  bordered={false}
                  dataSource={data.listSocialMedia}
                  loading={deleteLoading}
                  renderItem={(item, index) => (
                    <List.Item
                      className="dark-color"
                      actions={[<DeleteTwoTone className="cursor-pointer" twoToneColor="#ff4646" onClick={() => this.handleDelete(item.id)} />]}
                    >
                      {`${index+1}. ${item.name}: ${item.url}`}
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  actionUpdateSocial: updateSocial,
  actionDeleteSocial: deleteSocial
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SocialMedia))