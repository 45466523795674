import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Cookie from "js-cookie";
import i18next from 'i18next';
import { Layout, Menu, Grid, Drawer, Typography, Space, Tag, Modal, Image, message } from 'antd';
import { ExclamationCircleOutlined, GlobalOutlined } from '@ant-design/icons';
import { checkAuth } from '../../../redux/actions/auth/authAction';
import JobshubLogo from '../../../assets/img/logo/JobshubLogo.svg';
import Ina from '../../../assets/img/flag/ina.png';
import Eng from '../../../assets/img/flag/eng.png';
const { useBreakpoint } = Grid;
const { Text } = Typography
const { Sider } = Layout;
const { confirm } = Modal;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export const index = (props) => {
  const { t, sidebar, visible, onClose, getData, index, actionAddScore, actionAddScoreMiniEnglish } = props
  const { md } = GetBreakPoint()
  const { pathname } = window.location
  const currentLanguage = Cookie.get('i18next')

  const Icon = ({ type, ...rest }) => {
    const icons = require(`@ant-design/icons`);
    const Component = icons[type];
    return <Component {...rest} />
  };

  const showConfirm = (path) => {
    confirm({
      title: t('pathway.msgErrTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('pathway.msgErrText'),
      onOk() {
        if(Cookie.get("result")){
          const values = {
            score: JSON.parse(Cookie.get("result"))
              .map((item) => item.val)
              .reduce((a, b) => a + b, 0)
              .toString(),
          };
          return actionAddScore(
            values,
            (response) => {
              if (response.code === "1000") {
                localStorage.removeItem('isTest')
                props.history.push(path)
              } else {
                message.error(response.message);
              }
            },
            (err) => {
              message.error(err)
            }
          );
        }else{
          localStorage.removeItem('isTest')
          props.history.push(path)
        }
      }
    });
  };

  const showConfirmMiniEnglish = (path) => {
    confirm({
      title: t('pathway.msgErrTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('pathway.msgErrText'),
      onOk() {
        if(Cookie.get("result")){
          const values = {
            score: JSON.parse(Cookie.get("result"))
              .map((item) => item.val)
              .reduce((a, b) => a + b, 0)
              .toString(),
          };
          return actionAddScoreMiniEnglish(
            values,
            (response) => {
              if (response.code === "1000") {
                localStorage.removeItem('isTestMiniEnglish')
                localStorage.removeItem("questionListening")
                localStorage.removeItem("questionStructure")
                localStorage.removeItem("questionWrittenExp")
                localStorage.removeItem("questionReadingCompre")
                props.history.push(path)
              } else {
                message.error(response.message);
              }
            },
            (err) => {
              message.error(err)
            }
          );
        }else{
          localStorage.removeItem('isTestMiniEnglish')
          localStorage.removeItem("questionListening")
          localStorage.removeItem("questionStructure")
          localStorage.removeItem("questionWrittenExp")
          localStorage.removeItem("questionReadingCompre")
          props.history.push(path)
        }
      }
    });
  };

  

  return (
    <React.Fragment>
      {
        md ?
          <Sider width={200} className={ !sidebar ? "sidebar" : null }>
            <Menu
              className="dark-color height-100 width-auto"
              defaultSelectedKeys={[`${index}`]}
              mode="inline"
              selectedKeys={window.location.pathname}
            >
              {
                getData.permission.map((response) => {
                  return (
                    response.show === true ?
                      localStorage.getItem('isTest')  || localStorage.getItem('isTestMiniEnglish') ?
                        <Menu.Item 
                          key={response.path} 
                          icon={<Icon type={response.icon} />} 
                          onClick={
                            localStorage.getItem('isTest') ?
                            () => showConfirm(response.path)
                            : 
                            () => showConfirmMiniEnglish(response.path)
                          }
                        >
                          <Space>
                            <Text className="dark-color">{response.title}</Text>
                            {
                              response?.tag ?
                                <Tag className='border-radius-20' color="#4273B9">{response.tag}</Tag>
                              : null
                            }
                          </Space>
                        </Menu.Item>
                        :
                        <Menu.Item 
                          key={response.path} 
                          icon={<Icon type={response.icon} />} 
                          onClick={
                            localStorage.getItem('isTest') ?
                            () => showConfirm(response.path)
                            : 
                            () => showConfirmMiniEnglish(response.path)
                          }
                        >
                          <Space>
                            <Text className="dark-color">{response.title}</Text>
                            {
                              response?.tag ?
                                <Tag className='border-radius-20' color="#4273B9">{response.tag}</Tag>
                              : null
                            }
                          </Space>
                        </Menu.Item>
                        ?
                        <Menu.Item key={response.path} icon={<Icon type={response.icon} />}>
                          <Link to={response.path}>
                            <Space>
                              <Text className="dark-color">{response.title}</Text>
                              {
                                response?.tag ?
                                  <Tag className='border-radius-20' color="#4273B9">{response.tag}</Tag>
                                : null
                              }
                            </Space>
                          </Link>
                        </Menu.Item>
                    : null
                    : null
                  )
                })
              }
            </Menu>
          </Sider>
        :
          <Drawer
            bodyStyle={{ padding: 0 }}
            placement="left"
            // closable={false}
            onClose={onClose}
            visible={visible}
          >
            <Link to="/">
              <Image
                width={140}
                src={JobshubLogo}
                preview={false}
                style={{ paddingTop: 20, marginLeft: 28, marginBottom: 4 }}
              />
            </Link>
            <Menu
              className="dark-color width-auto"
              selectedKeys={[pathname]}
              mode="inline"
            >
              {
                getData.permission.map((response) => {
                  return (
                    response.show === true ?
                      localStorage.getItem('isTest') || localStorage.getItem('isTestMiniEnglish')  ?
                        <Menu.Item 
                          key={response.index} 
                          icon={<Icon type={response.icon} />} 
                          onClick={
                            localStorage.getItem('isTest')  ?
                            () => showConfirm(response.path) :
                            () => showConfirmMiniEnglish(response.path)
                          }
                        >
                          <Space>
                            <Text className="dark-color">{response.title}</Text>
                            {
                              response?.tag ?
                                <Tag className='border-radius-20' color="#4273B9">{response.tag}</Tag>
                              : null
                            }
                          </Space>
                        </Menu.Item>
                      :
                        <Menu.Item key={response.index} icon={<Icon type={response.icon} />}>
                          <Link to={response.path}>
                            <Space>
                              <Text className="dark-color">{response.title}</Text>
                              {
                                response?.tag ?
                                  <Tag className='border-radius-20' color="#4273B9">{response.tag}</Tag>
                                : null
                              }
                            </Space>
                          </Link>
                        </Menu.Item>
                    :
                    null
                  )
                })
              }
            </Menu>
            <Menu
              defaultOpenKeys={['lang']}
              defaultSelectedKeys={[currentLanguage]}
              mode="inline"
            >
              <Menu.SubMenu key="lang" icon={<GlobalOutlined />} title="Language">
                <Menu.Item key="in" onClick={() => {i18next.changeLanguage('in')}}>
                  <Image
                    height={16}
                    src={Ina}
                    preview={false}
                    style={{ marginTop: 2, borderRadius: 12, boxShadow: '1px 1px 8px #cccccc', objectFit: 'contain' }}
                  />
                  <Text style={{ marginLeft: 8 }}>
                    INA
                  </Text>
                </Menu.Item>
                <Menu.Item key="en" onClick={() => {i18next.changeLanguage('en')}}>
                  <Image
                    height={16}
                    src={Eng}
                    preview={false}
                    style={{ marginTop: 2, borderRadius: 12, boxShadow: '1px 1px 8px #cccccc', objectFit: 'contain' }}
                  />
                  <Text style={{ marginLeft: 8 }}>
                    ENG
                  </Text>
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
          </Drawer>
      }
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  getData: state.authReducer
})

const mapDispatchToProps = {
  auth: checkAuth
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(index))