import React from 'react'
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Row, Col, Grid, Space, Typography, Image } from 'antd';
import Biografi from '../../assets/img/candidate/biografi.png';
import CButton from '../../components/Button';

const { Text } = Typography;

const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

function GetHistory() {
  const history = useHistory()
  return history;
}

export function content(props) {
  const { xs, xxl } = GetBreakPoint()
  const history = GetHistory()
  const { t } = props
 

  const onClickButton = (val) => {
    localStorage.setItem('pathway', val);
    history.push('/login-or-register')
  }

  return (
    <React.Fragment>
      <Row>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          {
            xxl ?
              <React.Fragment>
                <Space className="text-align-center width-100 mt-32" direction="vertical">
                  <Text className="dark-color" style={{ fontSize: 32, fontWeight: 'normal', lineHeight: 1.2 }}>{t('pathway.title')}</Text>
                </Space>
                <Space className="width-100 mt-16" direction="vertical">
                  <Text className="info-color" style={{ fontSize: 26, fontWeight: 'normal' }}>{t('pathway.title1')}</Text>
                  <Space direction="vertical" size={0}>
                    <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'normal' }}>{t('pathway.title6')}</Text>
                  </Space>
                </Space>
              </React.Fragment>
            : xs ?
              <React.Fragment>
                <Space className="width-100 mt-16" direction="vertical">
                  <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'normal', lineHeight: 1.2 }}>{t('pathway.title')}</Text>
                </Space>
                <Space className="width-100 mt-16" direction="vertical">
                  <Text className="info-color" style={{ fontSize: 18, fontWeight: 'normal' }}>{t('pathway.title1')}</Text>
                  <Space direction="vertical" size={0}>
                    <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal' }}>{t('pathway.title6')}</Text>
                  </Space>
                </Space>
              </React.Fragment>
            :
              <React.Fragment>
                <Space className="text-align-center width-100 mt-16" direction="vertical">
                  <Text className="dark-color" style={{ fontSize: 28, fontWeight: 'normal', lineHeight: 1.2 }}>{t('pathway.title')}</Text>
                </Space>
                <Space className="width-100 mt-16" direction="vertical">
                  <Text className="info-color" style={{ fontSize: 22, fontWeight: 'normal' }}>{t('pathway.title1')}</Text>
                  <Space direction="vertical" size={0}>
                    <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal' }}>{t('pathway.title6')}</Text>
                  </Space>
                </Space>
              </React.Fragment>
          }

          <Row className="mb-16 mt-16" justify="space-around" align="middle">
            <Col className="mb-16" xs={24} md={8}>
              <Image
                width={'100%'}
                height={250}
                src={Biografi}
                preview={false}
                style={{ objectFit: 'contain' }}
              />
            </Col>
            <Col xs={24} md={16}>
                <Space direction="vertical" size={16}>
                    <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal' }}>
                      {t('pathway.title7')}
                    </Text>
                  </Space>
            </Col>
          </Row>

          <Row className="mb-32">
            <Col className="text-align-center" span={24}>
              <CButton className={ xs ? "mt-16" : ""} type="primary" size="large" action={() => onClickButton('/candidate/career-pathway')} title={t('pathway.title8')}/>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withTranslation()(content)