import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Row, Col, Typography, Space, Form, Input, DatePicker, Select, AutoComplete, Spin, Skeleton, Switch } from 'antd';
import { listInstitution } from '../../../../../redux/actions/master/institution/institutionAction';
import { indexEducationLevel, unmountIndexEducationLevel } from '../../../../../redux/actions/master/educationLevel/educationLevelAction';
import { listFieldOfStudy } from '../../../../../redux/actions/master/fieldOfStudy/fieldOfStudyAction';
const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

export class index extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      metaInstitution: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaFieldOfStudy: {
        page: 1,
        perpage: 10,
        search: ''
      },
      isBinawan: false,
      educationCurrent: false,
      startYear: null,
      endYear: null,
      isYear: false,
    }
    this.onSearchInstitution = debounce(this.onSearchInstitution.bind(this), 800)
    this.onSearchFieldOfStudy = debounce(this.onSearchFieldOfStudy.bind(this), 800)
  }
  
  componentDidMount() {
    const { actionGetEducationLevel, valuesEducation, getLastEducation } = this.props
    this.setState({ 
      startYear: valuesEducation && valuesEducation.educationStartYear ? valuesEducation.educationStartYear : getLastEducation?.data?.educationStartYear,
      endYear: valuesEducation && valuesEducation.educationEndYear ? valuesEducation.educationEndYear : getLastEducation?.data?.educationEndYear,
      educationCurrent: valuesEducation && valuesEducation.educationCurrent ? valuesEducation.educationCurrent : getLastEducation?.data?.educationCurrent,
      isBinawan: valuesEducation && valuesEducation.educationName === 'Universitas Binawan' ? true : !valuesEducation && getLastEducation?.data?.educationName === 'Universitas Binawan' ? true : false,
    })
    return actionGetEducationLevel()
  }

  onSearchInstitution = value => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;

    metaInstitution.page = 1
    metaInstitution.perpage = 10
    metaInstitution.search = value

    return actionListInstitution(metaInstitution)
  }

  onFocusInstitution = () => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;
    return actionListInstitution(metaInstitution)
  }

  onChangeInstitution = (e) => {
    this.setState({ isBinawan: e === 'Universitas Binawan' ? true : false })
  }

  onSearchFieldOfStudy = value => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;

    metaFieldOfStudy.page = 1
    metaFieldOfStudy.perpage = 10
    metaFieldOfStudy.search = value

    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  onFocusFieldOfStudy = () => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;
    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  handleChecked = (e) => {
    const { formRef } = this.props
    this.setState({
      educationCurrent: e
    })
    formRef.current.setFieldsValue({
      educationCurrent: e
    })
    if(e){
      formRef.current.setFieldsValue({
        educationEndYear: null
      })
    }
  }

  onChangeStartYear = (date, dateString) => {
    const { endYear } = this.state
    
    this.setState({
      startYear: dateString
    })

    if(endYear){
      if (endYear < dateString) {
        this.setState({
          isYear: true
        })
      } else {
        this.setState({
          isYear: false
        })
      }

    }
  }

  onChangeEndYear = (date, dateString) => {
    const { startYear } = this.state

    this.setState({
      endYear: dateString
    })

    if (dateString < startYear) {
      this.setState({
        isYear: true
      })
    } else {
      this.setState({
        isYear: false
      })
    }
  }

  render() {
    const { 
      getInstitution, 
      getEducationLevel, 
      getFieldOfStudy, 
      onChangeEducationLevel,
      getLastEducation,
      valuesEducation,
      educationLevelName,
      t
    } = this.props
    
    const { educationCurrent, isBinawan, isYear } = this.state

    if(getLastEducation.loading || getEducationLevel.loading){
      return <Skeleton active />
    }

    return (
      <React.Fragment>
        <Row className="mb-min-8" gutter={8}>
          <Col span={12}>
            <Space size={4}>
              <Text className="fs-12" type="danger">*</Text>
              <Text className="fs-12" type="secondary">{t('jobDetail.education.school')}</Text>
            </Space>
            <Form.Item 
              name="educationName"
              validateFirst
              rules={[
                { required: true, message: t('jobDetail.education.requiredSchool') },
                // { pattern: /^[0-9a-zA-Z ]*$/, message: 'Special characters are not allowed' },
                { min: 5, message:  t('jobDetail.education.minSchool') },
                { max: 100, message:  t('jobDetail.education.maxSchool') },
              ]}
              initialValue={
                valuesEducation && valuesEducation.educationName ?
                  valuesEducation.educationName
                : getLastEducation.data.educationName ?
                  getLastEducation.data.educationName
                :
                  ""
              }
            >
              <AutoComplete
                className="width-100"
                onFocus={this.onFocusInstitution}
                onSearch={this.onSearchInstitution}
                onChange={this.onChangeInstitution}
                notFoundContent={getInstitution.loading ? <Spin size="small" /> : null}
                filterOption={false}
                size="small"
                placeholder={t('jobDetail.education.placeholderSchool')}
              >
                {
                  getInstitution?.data?.map((res) => (
                    <Option key={res.id} value={res.name}>
                      {res.name}
                    </Option>
                  ))
                }
              </AutoComplete>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Space size={4}>
              <Text className="fs-12" type="danger">*</Text>
              <Text className="fs-12" type="secondary">{t('jobDetail.education.educationLevel')}</Text>
            </Space>
            <Form.Item 
              name="educationLevelId"
              rules={[
                { required: true, message: t('jobDetail.education.requiredEducation') }
              ]}
              initialValue={
                valuesEducation && valuesEducation.educationLevelId ?
                  educationLevelName
                : getLastEducation.data.educationLevelName ?
                  getLastEducation.data.educationLevelName
                :
                  ""
              }
            >
              <Select 
                className="width-100 border-radius-6"
                size="small" 
                onChange={onChangeEducationLevel}
              >
                {
                  getEducationLevel?.data?.map(item => 
                    <Option key={item.id} value={item.name}>{item.name}</Option>
                    )
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-min-8" gutter={8}>
          <Col span={12}>
            <Space size={4}>
              {
                isBinawan ? 
                  <Text className="fs-12" type="danger">*</Text>
                : null
              }
              <Text className="fs-12" type="secondary">{t('jobDetail.education.fieldOfStudy')}</Text>
            </Space>
            <Form.Item 
              name="educationFieldOfStudy"
              validateFirst
              rules={[
                { required: isBinawan, message:  t('jobDetail.education.requiredField') },
                { min: 5, message:  t('jobDetail.education.minField') },
                { max: 100, message:  t('jobDetail.education.maxField') },
              ]}
              initialValue={
                valuesEducation && valuesEducation.educationFieldOfStudy ?
                  valuesEducation.educationFieldOfStudy
                : getLastEducation.data.educationFieldOfStudy ?
                  getLastEducation.data.educationFieldOfStudy
                :
                  ""
              }
            >
              <AutoComplete
                className="width-100"
                onFocus={this.onFocusFieldOfStudy}
                onSearch={this.onSearchFieldOfStudy}
                notFoundContent={getFieldOfStudy.loading ? <Spin size="small" /> : null}
                filterOption={false}
                size="small"
                placeholder={t('register.fieldOfStudy')}
              >
                {
                  getFieldOfStudy?.data?.map((res) => (
                    <Option key={res.id} value={res.name}>
                      {res.name}
                    </Option>
                  ))
                }
              </AutoComplete>
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-min-8" gutter={8}>
          <Col span={12}>
            <Space size={4}>
              {
                isBinawan ? 
                  <Text className="fs-12" type="danger">*</Text>
                : null
              }
              <Text className="fs-12" type="secondary">{ t('jobDetail.education.startyear')}</Text>
            </Space>
            <Form.Item 
              name="educationStartYear"
              rules={[
                { required: isBinawan, message: t('jobDetail.education.requiredField') },
              ]}
              initialValue={
                valuesEducation && valuesEducation.educationStartYear ?
                  moment(valuesEducation.educationStartYear)
                : getLastEducation.data.educationStartYear ?
                  moment(getLastEducation.data.educationStartYear)
                :
                  ""
              }
            >
              <DatePicker inputReadOnly className="width-100" size="small" picker="year" onChange={this.onChangeStartYear} disabledDate={ d => !d || d.isBefore(moment().subtract(30, 'years').format('YYYY').toString()) || d.isAfter(moment().add(1, 'years').format('YYYY').toString()) } />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="educationCurrent" 
              initialValue={educationCurrent}
              style={{ marginTop: 20 }}
            >
              <Text className="fs-12" type="secondary">{t('jobDetail.education.present')}</Text>
              <Switch style={{ marginLeft: 10 }} onChange={this.handleChecked} checked={educationCurrent} size="small" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Space size={4}>
              {
                isBinawan && !educationCurrent ? 
                  <Text className="fs-12" type="danger">*</Text>
                : null
              }
              <Text className="fs-12" type="secondary">{t('jobDetail.education.endyear')}</Text>
            </Space>
            <Form.Item 
              name="educationEndYear"
              validateStatus={isYear ? "error" : null}
              help={isYear ? t('jobDetail.education.check') : null}
              rules={[
                { required: isBinawan && !educationCurrent, message: t('jobDetail.education.requiredField') },
              ]}
              initialValue={
                valuesEducation && valuesEducation.educationEndYear ?
                  moment(valuesEducation.educationEndYear)
                : getLastEducation.data.educationEndYear ?
                  moment(getLastEducation.data.educationEndYear)
                :
                  ""
              }
            >
              {/* <DatePicker inputReadOnly className="width-100" size="small" picker="year" onChange={onChangeEndYear} disabledDate={ d => !d || d.isBefore(moment().subtract(30, 'years').format('YYYY').toString()) || d.isAfter(moment().add(1, 'years').format('YYYY').toString()) } /> */}
              {
                educationCurrent ?  
                  <Text className="fs-12" type="secondary">{ t('jobDetail.education.present')}</Text>
                :
                  <DatePicker className="width-100" size="small" picker="year" onChange={this.onChangeEndYear} disabledDate={ d => !d || d.isBefore(moment().subtract(30, 'years').format('YYYY').toString()) } />
              }
            </Form.Item>
          </Col>
          <Col span={12}>
            <Space size={4}>
              {
                isBinawan ? 
                  <Text className="fs-12" type="danger">*</Text>
                : null
              }
              <Text className="fs-12" type="secondary">{ t('jobDetail.education.score')}</Text>
            </Space>
            <Form.Item 
              name="educationGpa"
              rules={[
                { required: isBinawan, message:  t('jobDetail.education.requiredField') },
                { pattern: /^[0-9a-zA-Z.]*$/, message:  t('jobDetail.education.patternScore') },
                { max: 4, message:  t('jobDetail.education.maxScore') },
              ]}
              initialValue={
                valuesEducation && valuesEducation.educationGpa ?
                  valuesEducation.educationGpa
                : getLastEducation.data.educationGpa ?
                  getLastEducation.data.educationGpa
                :
                  ""
              }
            >
              <Input size="small" placeholder="e.g.: 3.50" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Space size={4}>
              {
                isBinawan ? 
                  <Text className="fs-12" type="danger">*</Text>
                : null
              }
              <Text className="fs-12" type="secondary">{t('jobDetail.education.website')}</Text>
            </Space>
            <Form.Item 
              name="educationWebsite"
              validateFirst
              rules={[
                { required: isBinawan, message:  t('jobDetail.education.requiredField') },
                { max: 50, message:  t('jobDetail.education.maxWebsite') },
              ]}
              initialValue={
                valuesEducation && valuesEducation.educationWebsite ?
                  valuesEducation.educationWebsite
                : getLastEducation.data.educationWebsite ?
                  getLastEducation.data.educationWebsite
                :
                  ""
              }
            >
              <Input size="small" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Text className="fs-12" type="secondary">{t('jobDetail.education.educationExperience')}</Text>
            <Form.Item 
              name="educationExperience"
              rules={[
                { max: 200, message: t('jobDetail.education.maxEducation') }
              ]}
              initialValue={
                valuesEducation && valuesEducation.educationExperience ?
                  valuesEducation.educationExperience
                : getLastEducation.data.educationExperience ?
                  getLastEducation.data.educationExperience
                :
                  ""
              }
            >
              <TextArea rows={3} />
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  getInstitution: state.institutionReducer,
  getEducationLevel: state.educationLevelReducer,
  getFieldOfStudy: state.fieldOfStudyReducer
})

const mapDispatchToProps = {
  actionListInstitution: listInstitution,
  actionGetEducationLevel: indexEducationLevel,
  unmountIndexEducationLevel: unmountIndexEducationLevel,
  actionListFieldOfStudy: listFieldOfStudy,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(index))