const LOAD_HIRED           = 'LOAD_HIRED'
const LOAD_HIRED_SUCCESS   = 'LOAD_HIRED_SUCCESS'
const LOAD_HIRED_FAILED    = 'LOAD_HIRED_FAILED'
const UNMOUNT_HIRED        = 'UNMOUNT_HIRED'
const initialState = {
  loading: true,
  data: null,
  message: null,
}
const hiredReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_HIRED:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_HIRED_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_HIRED_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_HIRED:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}
export default hiredReducer;