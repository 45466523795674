const LOAD_REGISTER              = 'LOAD_REGISTER'
const LOAD_REGISTER_SUCCESS      = 'LOAD_REGISTER_SUCCESS'
const LOAD_REGISTER_FAILED       = 'LOAD_REGISTER_FAILED'

const initialState = {
  loading: true,
  authed: false,
  userData: null,
  role: null,
  permission: [],
}

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_REGISTER:
      return {
        ...state,
        loading: true,
      }
    case LOAD_REGISTER_SUCCESS:
      return {
        ...state, 
        loading: false,
        userData: action.payload.userData,
      }
    case LOAD_REGISTER_FAILED:
      return {
        ...state, 
        loading: false,
      }
    default:
      return state
  }
}
export default registerReducer