import jwt from 'jsonwebtoken'
import Cookie from "js-cookie"
import { API } from '../../../config'
import { errorHandler } from './errorAction'
import { CANDIDATE } from '../../../permissions'

export const checkAuth = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_AUTH' })
  const getToken = Cookie.get('user')
  if(getToken) {
    const userData = jwt.decode(getToken)
    const permission = getPermission(userData.role)
    if(getToken !== null) {
      return dispatch({ 
        type: 'LOAD_AUTH_SUCCESS', 
        payload: { 
          user: userData,
          role: userData.role,
          permission: permission
        } 
      })
    }
  }else {
    return dispatch({ type: 'LOAD_AUTH_FAILED' })
  }
}

export const checkToken = (successCB, failedCB) => async (dispatch) => {
  const params = {
    'token': Cookie.get('user')
  }
  API.POST_NO_WITH_BEARER('/auth/check', params).then((response) => {
    if(response.code === "1000"){
      return successCB && successCB(response)
    }else if(response.code === "2222"){
      dispatch(removedata(successCB))
    }else{
      dispatch({ type: 'LOAD_AUTH_FAILED' })
    }
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const setLogin = (value, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_AUTH' })
  API.POST('/auth/login', value).then((response) => {
    const token = response.token
    const refreshToken = response.refreshToken
    const userData = jwt.decode(token)
    const permission = getPermission(response.role)
    Cookie.set('user', token)
    Cookie.set('email', userData.userEmail)
    Cookie.set('userRefreshTkn', refreshToken)
    dispatch({ type: 'LOAD_AUTH_SUCCESS', payload: { 
      userData: userData,
      role: userData.role,
      permission: permission,
      isRegister: false
    } 
  })
    return successCB && successCB(response)
  })
  .catch((err) => {
    dispatch({ type: 'LOAD_AUTH_FAILED' })
    return failedCB && failedCB(err.error || err.message)
  })
}

export const setLogout = (successCB, failedCB) => async dispatch => {
  const getToken = Cookie.get('user');
  const params = {
    token: getToken
  }
  return API.POST_WITH_PARAMS('/auth/logout', params).then(() => {
    return dispatch(removedata(() => {
      dispatch({ type: 'SET_LOGOUT_SUCCESS'})
      return successCB && successCB();
    }))
  }).catch((err) => {
    return (
      failedCB && failedCB(),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'SET_LOGOUT_FAILED'})
      ))
    )
  })
}

export const getPermission = (type) => {
  switch (type) {
    case 'ROLE_CANDIDATE':
      return CANDIDATE
    default:
      return null
  }
}

export const removedata = (cb) => async (dispatch) => {
  await Cookie.remove('user')
  await Cookie.remove('email')
  await Cookie.remove('userRefreshTkn')
  await Cookie.remove('isSection')
  await Cookie.remove('isPart')
  await Cookie.remove('result')
  await localStorage.removeItem('link')
  await localStorage.removeItem('visible')
  await localStorage.removeItem('end_date')
  await localStorage.removeItem('industriesSelected')
  await localStorage.removeItem('positionSelected')
  await localStorage.removeItem('destinationSelected')
  await localStorage.removeItem('path-interview')
  await localStorage.removeItem('pathway')
  await localStorage.removeItem('imageCandidate')
  await localStorage.removeItem('email')
  await localStorage.removeItem('password')
  await localStorage.removeItem('emailForgotPass')
  await localStorage.removeItem('imageCandidate')
  await localStorage.removeItem('isTest')
  await localStorage.removeItem('isTestMiniEnglish')
  await localStorage.removeItem("questionListening")
  await localStorage.removeItem("questionStructure")
  await localStorage.removeItem("questionWrittenExp")
  await localStorage.removeItem("questionReadingCompre")
  return cb()
}