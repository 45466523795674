import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Info from './Info';
import Q1_5 from './Q1-5';
import Q6_10 from './Q6-10';
import Q11_15 from './Q11-15';

export class Section1 extends Component {
  render() {
    const { isSection, isPart } = this.props

    return (
      <React.Fragment>
        {/* Section 1 - INFO */}
        {isSection === 1 && isPart === 0 ? <Info {...this.props} /> : null}
        {/* Section 1 Q 1 - 5 */}
        {isSection === 1 && isPart === 1 ? <Q1_5 {...this.props} /> : null}
        {/* Section 1 Q 6 - 10 */}
        {isSection === 1 && isPart === 2 ? <Q6_10 {...this.props} /> : null}
        {/* Section 1 Q 11 - 15 */}
        {isSection === 1 && isPart === 3 ? <Q11_15 {...this.props} /> : null}
      </React.Fragment>
    )
  }
}

export default withTranslation()(Section1)