const LOAD_MENU_APPLICATIONS           = 'LOAD_MENU_APPLICATIONS'
const LOAD_MENU_APPLICATIONS_SUCCESS   = 'LOAD_MENU_APPLICATIONS_SUCCESS'
const LOAD_MENU_APPLICATIONS_FAILED    = 'LOAD_MENU_APPLICATIONS_FAILED'
const UNMOUNT_MENU_APPLICATIONS        = 'UNMOUNT_MENU_APPLICATIONS'
const initialState = {
  loading: true,
  data: null,
  message: null,
}
const menuApplicationsReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_MENU_APPLICATIONS:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_MENU_APPLICATIONS_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_MENU_APPLICATIONS_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_MENU_APPLICATIONS:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}
export default menuApplicationsReducer;