import { API } from '../../../../config'
import { errorHandler } from '../../auth/errorAction'

export const unmountlistJobPosition = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_JOB_POSITION'})
}

export const listJobPosition = (params) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_JOB_POSITION' })
  return API.GET('/job-position/list', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_JOB_POSITION_SUCCESS', 
      payload: {
        data: response.data,
        meta: response.meta
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_JOB_POSITION_FAILED' }), 
    ))
  })
}

export const listJobPositionByJobIndustry = (value) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_JOB_POSITION' })
  const params = { 
    jobIndustry: value 
  }
  return API.GET('/job-position/getByJobIndustry', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_JOB_POSITION_SUCCESS', 
      payload: {
        data: response.data,
        meta: response.meta
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_JOB_POSITION_FAILED' }), 
    ))
  })
}