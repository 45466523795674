import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography, Space, Grid, Image } from 'antd'
import Applied from '../../assets/img/employer/_applied.png';
import Qualified from '../../assets/img/employer/_qualified.png';
import Interview from '../../assets/img/employer/_interview.png';
import Offered from '../../assets/img/employer/_offered.png';
import Hired from '../../assets/img/employer/_hired.png';

const { useBreakpoint } = Grid;
const { Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

function GetTranslation() {
  const t = useTranslation()
  return t;
}

export default function register() {
  const { md, xxl } = GetBreakPoint()
  const { t } = GetTranslation()

  return (
    <React.Fragment>
      <Row style={md ? { paddingBottom: 32, backgroundColor: '#ffffff' } : { paddingTop: 16, paddingBottom: 32, backgroundColor: '#ffffff' }}>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Row className={ md ? "text-align-center mb-32" : "text-align-center mb-16" } justify="space-around" align="middle">
            <Col span={24}>
              <Space direction="vertical" size={xxl ? 32 : md ? 16 : 4} style={{ width: xxl ? '70%' : '100%' }}>
                <Text style={{ lineHeight: 1.4 }}>
                  <Text style={{ fontSize: xxl ? 36 : md ? 28 : 24, fontWeight: 'bold', color: '#32ab6d' }}>{t('pageEmployer.pipeline.title')} </Text>
                  <Text style={{ fontSize: xxl ? 36 : md ? 28 : 24, fontWeight: 'bold', color: '#4273b9' }}>{t('pageEmployer.pipeline.title2')} {t('pageEmployer.pipeline.title3')}</Text>
                </Text>
              </Space>
            </Col>
          </Row>
          <Row gutter={md ? [0, 32] : [0, 8]}>
            <Col span={4}>
              <Image
                width={md ? '30%' : '80%'}
                src={Applied}
                preview={false}
              />
            </Col>
            <Col span={20}>
              <Space direction="vertical">
                <Text style={{ fontSize: 22, fontWeight: 'bold', color: '#32ab6d' }}>Applied</Text>
                <Text style={{ fontSize: 18, fontWeight: 'normal', color: '#535353' }}>{t('pageEmployer.pipeline.applied')}</Text>
              </Space>
            </Col>
            <Col span={4}>
              <Image
                width={md ? '30%' : '80%'}
                src={Qualified}
                preview={false}
              />
            </Col>
            {/* <Col span={20}>
              <Space direction="vertical">
                <Text style={{ fontSize: 22, fontWeight: 'bold', color: '#32ab6d' }}>Shortlisted</Text>
                <Text style={{ fontSize: 18, fontWeight: 'normal', color: '#535353' }}>{t('pageEmployer.pipeline.shortlisted')}</Text>
              </Space>
            </Col>
            <Col span={4}>
              <Image
                width={md ? '30%' : '80%'}
                src={Qualified}
                preview={false}
              />
            </Col> */}
            {/* <Col span={20}> 
              <Space direction="vertical">
                <Text style={{ fontSize: 22, fontWeight: 'bold', color: '#32ab6d' }}>Assessment</Text>
                <Text style={{ fontSize: 18, fontWeight: 'normal', color: '#535353' }}>{t('pageEmployer.pipeline.assessment')}</Text>
              </Space>
            </Col>
            <Col span={4}>
              <Image
                width={md ? '30%' : '80%'}
                src={Qualified}
                preview={false}
              />
            </Col> */}
            <Col span={20}>
              <Space direction="vertical">
                <Text style={{ fontSize: 22, fontWeight: 'bold', color: '#32ab6d' }}>Qualified</Text>
                <Text style={{ fontSize: 18, fontWeight: 'normal', color: '#535353' }}>{t('pageEmployer.pipeline.qualified')}</Text>
              </Space>
            </Col>
            <Col span={4}>
              <Image
                width={md ? '30%' : '80%'}
                src={Interview}
                preview={false}
              />
            </Col>
            <Col span={20}>
              <Space direction="vertical">
                <Text style={{ fontSize: 22, fontWeight: 'bold', color: '#32ab6d' }}>Interview</Text>
                <Text style={{ fontSize: 18, fontWeight: 'normal', color: '#535353' }}>{t('pageEmployer.pipeline.interview')}</Text>
              </Space>
            </Col>
            <Col span={4}>
              <Image
                width={md ? '30%' : '80%'}
                src={Offered}
                preview={false}
              />
            </Col>
            <Col span={20}>
              <Space direction="vertical">
                <Text style={{ fontSize: 22, fontWeight: 'bold', color: '#32ab6d' }}>Offered</Text>
                <Text style={{ fontSize: 18, fontWeight: 'normal', color: '#535353' }}>{t('pageEmployer.pipeline.offered')}</Text>
              </Space>
            </Col>
            <Col span={4}>
              <Image
                width={md ? '30%' : '80%'}
                src={Hired}
                preview={false}
              />
            </Col>
            <Col span={20}>
              <Space direction="vertical">
                <Text style={{ fontSize: 22, fontWeight: 'bold', color: '#32ab6d' }}>Hired</Text>
                <Text style={{ fontSize: 18, fontWeight: 'normal', color: '#535353' }}>{t('pageEmployer.pipeline.hired')}</Text>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}
