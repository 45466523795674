import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Form, Input, Row, Col, Card, Typography, Select, Checkbox, Tooltip , AutoComplete, Spin, DatePicker} from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import './nakes.css';
import CButton from '../../../components/Button';

const { Option } = Select;
const { Text } = Typography;

export default function ChannelRegisterNakes(props) {
  const { 
    visible,
    agree,
    formRef,
    submitLoading,
    onFinishNakes,
    onCheckEmail,
    onFocusPassword,
    onChangeCode,
    onCheckMobile,
    onChangeCheck,
    onFocusInstitution,
    onSearchInstitution,
    onChangeEducationLevel,
    onFocusFieldOfStudy,
    onSearchFieldOfStudy,
    // handleDestination,
    getCountry,
    getInstitution,
    getEducationLevel,
    getFieldOfStudy,
    // getJobDestination,
    t
  } = props

  const prefixSelector = (
    <Form.Item name="code" noStyle>
      <Select className="prefix-selector" onChange={onChangeCode} showSearch>
        {
          getCountry?.data?.map(item =>
            <Option text={item.callingCode} key={item.id} value={`(+${item.callingCode}) ${item.name}`}>{`(+${item.callingCode}) ${item.name}`}</Option>
            )
          }
      </Select>
    </Form.Item>
  )

  return (
    <Row className="display-flex" align="middle" justify="center">
      <Col>
        <Card className="card-register" bodyStyle={{ padding: 0, display: 'flex' }}>
          <Form 
            ref={formRef}
            initialValues={{ 
              code: '(+62) Indonesia'
            }}
            onFinish={onFinishNakes}
          >
            <Row gutter={8}>
              <Col className="fs-28" xs={24} sm={24} md={24}>
                <Text type="secondary">{t('register.title')}</Text>
              </Col>

              <Col className="fs-13 mb-16" xs={24} sm={24} md={24}>
                <Text type="secondary">{t('register.subTitle')}</Text>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item 
                  className="mb-16"
                  name="firstName" 
                  validateFirst
                  rules={[
                    { required: true, message: 'First name is required!' },
                    { pattern: /^[a-zA-Z ]*$/, message: 'Can not use special characters and numbers.' },
                    { min: 3, message: 'First Name must be at least 3 characters long.' },
                    { max: 25, message: 'First Name must not be more than 25 characters long.' },
                  ]}
                >
                  <Input placeholder="Nama Depan" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item 
                  className="mb-16"
                  name="lastName" 
                  validateFirst
                  rules={[
                    { required: true, message: 'Last name is required!' },
                    { pattern: /^[a-zA-Z ]*$/, message: 'Can not use special characters and numbers.' },
                    { min: 3, message: 'Last Name must be at least 3 characters long.' },
                    { max: 25, message: 'Last Name must not be more than 25 characters long.' },
                  ]}
                >
                  <Input placeholder="Nama Belakang" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24}>
                <Form.Item 
                  className="mb-16"
                  name="email"
                  validateFirst
                  rules={[
                    { required: true, message: 'Email is required!' },
                    { type: 'email', message: 'Your email address is invalid!' },
                  ]}
                >
                  <Input type="email" onBlur={(e) => onCheckEmail(e.target.value)} placeholder="Alamat email yang valid" />
                </Form.Item>
              </Col>
              
              <Col xs={24} lg={12}>
                <Form.Item 
                  name="birthdate"
                  rules={[
                      { required: true, message: 'Date of Birth is required!' },
                    ]}
                >
                  <DatePicker inputReadOnly className="width-100" format="DD/MM/YYYY" placeholder='Tanggal lahir' defaultPickerValue={moment('2000-01-01')} />
                {/* <DatePicker inputReadOnly className="width-100" size="small" format="DD/MM/YYYY" onChange={onChangeBirthdate} defaultPickerValue={getProfile.data.birthdate ? null : moment('2003-04-15')} disabled={getProfile.data.birthdate} /> */}
                </Form.Item>
              </Col>
              
              <Col xs={24} lg={12}>
                <Form.Item 
                  className="mb-16"
                  name="gender"
                  rules={[
                    { required: true, message: 'Gender is required!' },
                  ]}
                >
                  <Select 
                    className="width-100 border-radius-6"
                    placeholder="Jenis kelamin"
                    // size="small" 
                  >
                    <Option key="1" value="MALE">Male</Option>
                    <Option key="2" value="FEMALE">Female</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item 
                    className="mb-16"
                    name="educationName"
                    validateFirst
                    rules={[
                    { required: true, message: t('required') },
                    { min: 5, message: t('min',{number:5}) },
                    { max: 100, message: t('max',{number:100}) },
                    ]}
                >
                    <AutoComplete
                    className="width-100"
                    onFocus={onFocusInstitution}
                    onSearch={onSearchInstitution}
                    notFoundContent={getInstitution.loading ? <Spin size="small" /> : null}
                    filterOption={false}
                    placeholder={t('register.school')}
                    >
                    {
                        getInstitution?.data?.map((item, i) => (
                        <AutoComplete.Option key={i} value={item.name}>
                            {item.name}
                        </AutoComplete.Option>
                        ))
                    }
                    </AutoComplete>
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item 
                  className="mb-16"
                  name="educationLevelId"
                  rules={[
                    { required: true, message: t('required')},
                  ]}
                >
                  <Select 
                    className="width-100 border-radius-6"
                    onChange={onChangeEducationLevel}
                    placeholder={t('register.educationLevel')}
                  >
                    {
                      getEducationLevel?.data?.map(item => 
                        <Option key={item.id} value={item.id}>{item.name}</Option>
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24} >
                <Form.Item 
                  className="mb-16"
                  name="fieldOfStudy"
                  validateFirst
                  rules={[
                    { required: true, message: t('required') },
                    { min: 5, message: t('min',{number:5}) },
                    { max: 100, message: t('max',{number:100}) },
                  ]}
                >
                  <AutoComplete
                    className="width-100"
                    onFocus={onFocusFieldOfStudy}
                    onSearch={onSearchFieldOfStudy}
                    notFoundContent={getFieldOfStudy.loading ? <Spin size="small" /> : null}
                    filterOption={false}
                    placeholder={t('register.fieldOfStudy')}
                  >
                    {
                      getFieldOfStudy?.data?.map((item, i) => (
                        <AutoComplete.Option key={i} value={item.name}>
                          {item.name}
                        </AutoComplete.Option>
                      ))
                    }
                  </AutoComplete>
                </Form.Item>
              </Col>

              {/* <Col span={24}>
                <Form.Item 
                  className="mb-16"
                  name="destination"
                  validateFirst
                  rules={[
                    { required: true, message: t('required') },
                    { 
                      validator: (rule, value, callback) => {
                        if (value) {
                          if (value.length > 5) {
                            callback(t('profile.jobPreferences.form.max5Destination'));
                          } else if (value.length <= 5) {
                            callback();
                          }
                        }
                        return;
                      }
                    },
                  ]}
                >
                  <Select 
                    className="width-100 border-radius-6"
                    mode="multiple"
                    allowClear 
                    showSearch
                    onChange={handleDestination}
                    placeholder={t('register.destination')}
                  >
                    {
                      getJobDestination?.data?.map((item, i) => 
                        <OptGroup key={i} label={item.name}>
                          {
                            item?.listDestination?.map((res) => 
                              <Option key={res.id} value={res.name}>{res.name}</Option>
                            )
                          }
                        </OptGroup>
                      )
                    }
                  </Select>
                </Form.Item>
              </Col> */}
              
              
              <Col xs={24} sm={24} md={12}>
                <Form.Item 
                  className="mb-16"
                  name="password"
                  validateFirst
                  hasFeedback
                  rules={[
                    { required: true, message: 'Password is required!' },
                    { pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, message: 'Password does not meet requirements' },
                    { min: 8, message: 'Password must be at least 8 characters long.' },
                  ]}
                >
                  <Input.Password onFocus={() => onFocusPassword(true)} onBlur={() => onFocusPassword(false)} placeholder="Kata sandi" />
                </Form.Item>
              </Col>
              
              <Col xs={22} sm={22} md={11}>
                <Form.Item 
                  className="mb-16"
                  name="confirmPassword" 
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    { required: true, message: 'Confirm Password is required!' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Passwords do not match!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Konfirmasi kata sandi" />
                </Form.Item>
              </Col>

              <Col className="pt-4 dark-color pl-0" xs={2} sm={2} md={1}>
                <Tooltip visible={visible} placement="right" title="Password with at least 8 characters, 1 CAPITAL and 1 number">
                  <InfoCircleTwoTone onMouseLeave={() => onFocusPassword(false)} onMouseEnter={() => onFocusPassword(true)} twoToneColor="#0076de" />
                </Tooltip>
              </Col>
              
              <Col xs={24} sm={24} md={24}>
                <Form.Item 
                  className="mb-16"
                  name="mobile"
                  validateFirst
                  rules={[
                    { required: true, message: 'Phone Number is required!' },
                    { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },
                    { min: 7, max: 13, message: 'Your mobile number is invalid' },
                    
                  ]}
                >
                  <Input onBlur={(e) => onCheckMobile(e.target.value)} addonBefore={prefixSelector} placeholder="Masukkan nomor telepon anda" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Form.Item 
                  className="mb-16"
                  name="agree"
                  rules={[
                    { 
                      validator(rule, value, callback){
                        if(!agree){
                          callback('Please check this box to proceed your account')
                        }else{
                          callback()
                        }
                      }
                    }
                  ]}
                >
                  <Checkbox className="fs-11 dark-color" onChange={onChangeCheck} checked={agree}>
                    By creating an account you agree to the 
                    <Link className="info-color" to="/terms-and-conditions" target="_blank"> terms and conditions</Link> and 
                    <Link className="info-color" to="/privacy-policy" target="_blank"> privacy policy </Link>
                  </Checkbox>
                </Form.Item>
              </Col>
              
              <Col xs={24} sm={24} md={24}>
                <Form.Item className="mb-8">
                  <CButton type="primary" htmlType="submit" className="login-form-button" loading={submitLoading} block title="Create Account"/>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24}>
                <Text className="fs-11" type="secondary">{t('register.subButton')} <Link className="info-color" to="/login">{t('register.login')}</Link> </Text>
            </Col>

            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  )
}
