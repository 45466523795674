export const questionStructure = [
  {
    question:
      "I went to the cafe because I wanted to email my sister on her birthday, but the cafe ____________",
    answers: [
      {
        value: 1,
        answerLabel: "was closed",
      },
      {
        value: 0,
        answerLabel: "is closed",
      },
      {
        value: 0,
        answerLabel: "were closed",
      },
    ],
  },
  {
    question:
      "Over the years, the Taj Mahal has suffered from environmental damage, and there _________________to conserve its beauty.",
    answers: [
      {
        value: 0,
        answerLabel: "are been many governments attempts",
      },
      {
        value: 0,
        answerLabel: "many government attempts have been ",
      },
      {
        value: 1,
        answerLabel: "have been many government attempts",
      },
    ],
  },
  {
    question:
      "__________ J.K. Rowling, Harry Potter book was first adopted as movie in 2001.",
    answers: [
      {
        value: 0,
        answerLabel: "She wrote",
      },
      {
        value: 1,
        answerLabel: "Written by",
      },
      {
        value: 0,
        answerLabel: "Was written by",
      },
    ],
  },
  {
    question:
      "Imagine! this time next year I __________ on the other side of the world.",
    answers: [
      {
        value: 0,
        answerLabel: "live",
      },
      {
        value: 0,
        answerLabel: "will have lived",
      },
      {
        value: 1,
        answerLabel: "will be living",
      },
    ],
  },
  {
    question:
      "Look at that tree. It's really leaning over. I think it ____________________.",
    answers: [
      {
        value: 0,
        answerLabel: "will be falling",
      },
      {
        value: 0,
        answerLabel: "will have fallen",
      },
      {
        value: 1,
        answerLabel: "is going to fall",
      },
    ],
  },
  {
    question: "A: I've never seen anything like this before.\nB: Neither____I",
    answers: [
      {
        value: 0,
        answerLabel: "do",
      },
      {
        value: 1,
        answerLabel: "have",
      },
      {
        value: 0,
        answerLabel: "are",
      },
    ],
  },
  {
    question:
      "We'll have __________a bit of Korean by the time you come to Seoul. We're starting classes next week.",
    answers: [
      {
        value: 0,
        answerLabel: "learn",
      },
      {
        value: 1,
        answerLabel: "learnt",
      },
      {
        value: 0,
        answerLabel: "learning",
      },
    ],
  },
  {
    question:
      "Why were there no buses yesterday? Maybe it was the snow or they _____ been on strike.",
    answers: [
      {
        value: 1,
        answerLabel: "might have",
      },
      {
        value: 0,
        answerLabel: "must have",
      },
      {
        value: 0,
        answerLabel: "couldn't have",
      },
    ],
  },
  {
    question: "My car ___ this week, so I'm going to work by bus.",
    answers: [
      {
        value: 1,
        answerLabel: "is being repaired",
      },
      {
        value: 0,
        answerLabel: "is been repair",
      },
      {
        value: 0,
        answerLabel: "is repairing",
      },
    ],
  },
  {
    question:
      "__________ Nuria live in Valencia? - No, she lives in Barcelona.",
    answers: [
      {
        value: 1,
        answerLabel: "does",
      },
      {
        value: 0,
        answerLabel: "do",
      },
      {
        value: 0,
        answerLabel: "did",
      },
    ],
  },
];
