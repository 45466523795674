import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { Row, Col, Typography, Card, Skeleton, Space, Pagination, Empty, Avatar, Modal } from 'antd';
import { listApplications, unmountListApplications, } from '../../../redux/actions/applications/applicationsAction'
import EmptyLogo from '../../../assets/img/EmptyLogo.png';
import CButton from '../../../components/Button';

const { Text } = Typography

export class Development extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      params: {
        page: 1,
        perpage: 10,
        phaseId: '',
        jobStatus: 'DEVELOPMENT'
      },
      submitLoading: false,
      visible: false,
      id: null
    }
  }
  
  componentDidMount() {
    const { params } = this.state
    const { actionListApplications } = this.props

    return actionListApplications(params)
  }
  
  handlePagination = (pageNumber) => {
    const { params: { perpage, jobStatus } } = this.state
    const params = {
      page: pageNumber,
      perpage: perpage,
      jobStatus: jobStatus
    }
    this.setState({ params })
    window.scrollTo(0, 0);
    const { actionListApplications } = this.props
    return actionListApplications(params)
  }

  showModal = (id) => {
    this.setState({
      visible: true,
      id: id
    })
  }


  handleCancel = () => {
    this.setState({
      visible: false,
      id: null
    })
  }


  render() {
    const { getListApplications } = this.props
    const { visible, submitLoading } = this.state
    
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Development');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Development</title>
        </Helmet>
        <Row className="padding-16">
          <Col className="width-100" md={{ span: 20, offset: 2 }}>
            <Row className="mb-32">
              <Col>
                <Text className="dark-color fs-20">Development</Text>
              </Col>
            </Row>
            <Row className="mb-8">
              <Col>
                {
                  getListApplications.loading ?
                    <Skeleton.Input style={{ width: '100%' }} active />
                  :
                    <Text className="dark-color fs-14">Showing {getListApplications?.meta?.total === 0 ? getListApplications?.meta?.total : (getListApplications?.meta?.page*getListApplications?.meta?.perpage)-(getListApplications?.meta?.perpage-1) } - {getListApplications?.meta?.total === 0 ? getListApplications?.meta?.total : getListApplications?.meta?.page === getListApplications?.meta?.pages ? getListApplications?.meta?.total : getListApplications?.meta?.page*getListApplications?.meta?.perpage} of {getListApplications?.meta?.total} Candidates Development</Text>
                }
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={24}>
                <Row className="mb-16">
                  <Col span={24}>
                    {
                      getListApplications.loading ?
                        <Card className="border-radius-3 box-shadow mb-6" style={{ width: '100%' }}>
                          <Skeleton active avatar />
                        </Card>
                      :
                      getListApplications?.data?.length > 0 ?
                        getListApplications?.data?.map((item, i) => 
                          <Card key={i} className="border-radius-3 box-shadow mb-6" style={{ width: '100%' }}>
                            <Row className="width-100" gutter={16}>
                              <Col className="col-img-job" xs={24} sm={4} md={4}>
                                <Avatar
                                  preview={false}
                                  size={100}
                                  src={item.candidateProfilePicUrl ? item.candidateProfilePicUrl : EmptyLogo}
                                />
                              </Col>
                              <Col xs={24} sm={13} md={12}>
                                <Space direction="vertical" size={0}>
                                  <Space direction='vertical'>
                                    <Text strong>{item.candidateFirstName} {item.candidateLastName}</Text>
                                    <Text className="application-date fs-13 dark-color" style={{ marginBottom: 8}}>{item.candidateNumber}</Text>
                                  </Space>
                                  <Space direction='horizontal'>
                                    <Text className="application-date fs-13 dark-color">Training Type :</Text>
                                    <Text className="application-date fs-13 dark-color">{item.trainingType}</Text>
                                  </Space>
                                  <Space direction='horizontal'>
                                    <Text className="application-date fs-13 dark-color">Start Date :</Text>
                                    <Text className="application-date fs-13 dark-color">{item.startDate}</Text>
                                  </Space>
                                  <Space direction='horizontal'>
                                    <Text className="application-date fs-13 dark-color">Target Completion Date :</Text>
                                    <Text className="application-date fs-13 dark-color">{item.targetDate}</Text>
                                  </Space>
                                </Space>

                              </Col>
                              <Col xs={24} sm={7} md={8}>
                                <Space direction="vertical" size={16} className="application-date">
                                  <Space direction='horizontal' align="start">
                                    <Text className="application-date fs-13 dark-color">Location:  </Text>
                                    <Text className="application-date fs-13 dark-color"><div dangerouslySetInnerHTML={{ __html: item.location ? item.location : null }} ></div></Text>
                                  </Space>
                                  <CButton className="application-date mt-8 fs-12" type="primary" style={{ backgroundColor: '#0076de', borderColor: '#0076de' }} action={() => this.showModal(item.id)} title="View Reason"/>
                                  <Modal 
                                    title={
                                      <Space className="text-align-center width-100" direction="vertical">
                                        <Text className="info-color fs-17">Reason</Text>
                                      </Space>
                                    } 
                                    visible={visible} 
                                    onCancel={this.handleCancel}
                                    footer={false}
                                  >
                                    <Space className="width-100" direction="vertical">
                                      <Row className="mb-32" gutter={16}>
                                        <Col xs={18} lg={18}>
                                          <Space align="start" style={{ textAlign: 'justify' }}>
                                            <div dangerouslySetInnerHTML={{ __html: item.reason ? item.reason : null }}></div>
                                          </Space>
                                        </Col>
                                      </Row>
                                      <Space className="float-right">
                                        <CButton className="fs-12" type="primary" style={{ backgroundColor: '#0076de', borderColor: '#0076de' }} loading={submitLoading} action={this.handleCancel} title="Okay"/>
                                      </Space>
                                    </Space>
                                  </Modal>
                                </Space>
                              </Col>
                            </Row>
                          </Card>
                      )
                        :
                          <Card className="border-radius-3 box-shadow mb-6" style={{ width: '100%' }}>
                            <Empty />
                          </Card>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col className="text-align-center" span={24}>
                    <Pagination onChange={(pageNumber,total, range) => this.handlePagination(pageNumber, total, range)} current={getListApplications?.meta?.page} pageSize={getListApplications?.meta?.perpage || 10} total={getListApplications?.meta?.total} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountListApplications } = this.props
    return unmountListApplications()
  }
}

const mapStateToProps = (state) => ({
  getListApplications: state.listApplicationsReducer,
})

const mapDispatchToProps = {
  actionListApplications: listApplications,
  unmountListApplications: unmountListApplications,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Development))