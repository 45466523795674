export const questionWrittenExp = [
  {
    question:
      "<p>According <b><i><u>to</u></i></b> international analyst and writer David Rothkopf, the world <b><i><u>in which</u></i></b> we live is <b><i><u>controls</u></i></b> by what he <b><i><u>terms</u></i></b> the 'Superclass'.</p>",
    answers: [
      {
        value: 0,
        answerLabel: "terms",
      },
      {
        value: 0,
        answerLabel: "to",
      },
      {
        value: 0,
        answerLabel: "in which",
      },
      {
        value: 1,
        answerLabel: "controls",
      },
    ],
  },

  {
    question:
      "<p>The shop <b><i><u>wasn't</u></i></b> easy <b><i><u>finding</u></i></b>. I nearly <b><i><u>miss</u></i></b> it <b><i><u>on</u></i></b> my way back home.</p>",
    answers: [
      {
        value: 0,
        answerLabel: "wasn't",
      },
      {
        value: 1,
        answerLabel: "finding",
      },
      {
        value: 0,
        answerLabel: "on",
      },
      {
        value: 0,
        answerLabel: "miss",
      },
    ],
  },
  {
    question:
      "<p>The good points <b><i><u>includes</u></i></b> the fact that there <b><i><u>are</u></i></b> lots of up-to-date groups you can <b><i><u>listen</u></i></b> to <b><i><u>such as</u></i></b> The Script or One Direction.</p>",
    answers: [
      {
        value: 0,
        answerLabel: "listen",
      },
      {
        value: 1,
        answerLabel: "includes",
      },
      {
        value: 0,
        answerLabel: "such as",
      },
      {
        value: 0,
        answerLabel: "are",
      },
    ],
  },
  {
    question:
      "<p>I <b><i><u>have</u></i></b> really enjoyed <b><i><u>being</u></i></b> able to watch sports which <b><i><u>are</u></i></b> not normally <b><i><u>showing</u></i></b> on television.</p>",
    answers: [
      {
        value: 1,
        answerLabel: "showing",
      },
      {
        value: 0,
        answerLabel: "have",
      },
      {
        value: 0,
        answerLabel: "are",
      },
      {
        value: 0,
        answerLabel: "being",
      },
    ],
  },
];
