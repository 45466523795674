import React from 'react'
import { Button } from 'antd'

export default function ComponentButton(props) {
  const Icon = ({ type, ...rest }) => {
    const icons = require(`@ant-design/icons`);
    const Component = icons[type];
    return <Component {...rest} />
  };
  const { className, htmlType, type, size, action, title, icon, disabled} = props
  return (
    <React.Fragment>
      {
        type === 'primary' ?
          <Button 
            className={`border-radius-6 ${className}`}
            htmlType={htmlType} 
            type="primary"
            size={size}
            onClick={action}
            disabled={disabled}
          >
            {title}
          </Button>
        : type === 'primary-outline' ?
          <Button 
            className={`border-radius-6 ${className}`}
            htmlType={htmlType} 
            type="primary" 
            size={size}
            onClick={action}
            ghost
          >
            {title}
          </Button>
        : type === 'primary-outline-r' ?
          <Button 
            className={`border-radius-6 ${className}`}
            htmlType={htmlType} 
            type="primary" 
            size={size}
            onClick={action}
            ghost
          >
            {title} <Icon type={icon} />
          </Button>
        : type === 'info-r' ?
          <Button 
            className={`border-radius-16 ${className}`}
            htmlType={htmlType} 
            type="primary" 
            size={size}
            onClick={action}
            style={{ border: '#4273b9', background: '#4273b9' }}
          >
            {title} <Icon type={icon} />
          </Button>
        : type === 'info-r-outline' ?
          <Button 
            className={`border-radius-16 ${className}`}
            htmlType={htmlType} 
            type="primary" 
            size={size}
            onClick={action}
            // ghost
            style={{ border: 'solid 1px #4273b9', color: '#4273b9' }} 
          >
            <Icon type={icon} /> {title}
          </Button>
        : type === 'default' ?
          <Button 
            className={`border-radius-6 ${className}`}
            type="default" 
            size={size}
            onClick={action}
            disabled={disabled}
            // ghost
          >
           {title}
          </Button>
        : type === 'link' ?
          <Button 
            className={`border-radius-6 ${className}`}
            type="link" 
            size={size}
            onClick={action}
          >
            <Icon type={icon}/> {title}
          </Button>
        : type === 'link-outline' ?
          <Button 
            className={`border-radius-6 ${className}`}
            type="link" 
            size={size}
            onClick={action}
          >
            {title}
          </Button>
        : type === 'save' ?
          <Button 
            className={`border-radius-6 ${className}`}
            htmlType={htmlType} 
            type="primary" 
            size={size}
            onClick={action}
            style={{ border: '#4273b9', background: '#4273b9', width: '95%' }}
          >
            {title}
          </Button>
        : type === 'primary-icon' ?
          <Button 
            className={`border-radius-6 ${className}`}
            htmlType={htmlType} 
            type="primary"
            size={size}
            onClick={action}
          >
            <Icon type={icon}/> {title}
          </Button>
        : type === 'danger' ?
          <Button 
            className={`border-radius-6 ${className}`}
            htmlType={htmlType} 
            type="primary" 
            size={size}
            onClick={action}
            disabled={disabled}
            style={{ border: '#4273b9', background: '#FF4500' }}
          >
            {title}
          </Button>
        : type === 'danger-r' ?
          <Button 
            className={`border-radius-6 ${className}`}
            // htmlType={htmlType} 
            type="danger" 
            size={size}
            onClick={action}
            style={{ border: '#4273b9',  }}
          >
            <Icon type={icon}/> {title}
          </Button>
        : type === 'dashed' ?
          <Button 
            className={`border-radius-6 ${className}`}
            htmlType={htmlType} 
            type="dashed" 
            size={size}
            onClick={action}
            
          >
            <Icon type={icon}/> {title}
          </Button>
        : type === 'danger-outline' ?
          <Button 
            className={`border-radius-6 ${className}`}
            // htmlType={htmlType} 
            type="link" 
            size={size}
            onClick={action}
            style={{ color:'red',  }}
          >
            {title}
          </Button>
        :
          null
      }
    </React.Fragment>
  )
}
