import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Grid, Space, Typography, Image, Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import Pathway from '../../assets/img/general/pathway.png';

const { useBreakpoint } = Grid;
const { Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

function GetTranslation() {
  const t = useTranslation()
  return t;
}

export default function pathway() {
  const { md } = GetBreakPoint()
  const { t } = GetTranslation()

  return (
    <React.Fragment>
      <Row className="pb-32" style={ md ? { backgroundColor: '#fff', paddingBottom: 64 } : { backgroundColor: '#fff', paddingBottom: 8 }}>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Row gutter={64} justify="space-around" align="middle">
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 10, offset: 0 }}>
              <Image
                className="cursor-pointer option-img"
                src={Pathway}
                preview={false}
                width={'100%'}
              />
            </Col>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 14, offset: 0 }}>
              <Space direction="vertical" size={32}>
                <Space direction='vertical' size={0}>
                  <Text className="dark-color" style={{ fontSize: 28, fontWeight: 'bold', lineHeight: 1.2 }}>Smart Pathway</Text>
                </Space>
                <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'normal', lineHeight: 1.2 }}>{t('landingPage.subTitlePathway')}</Text>
                <Link className="width-100 text-align-center" to="/career-pathway">
                  <Button className="box-shadow text-align-center" type="primary" style={{ border: '#4273b9', background: '#4273b9', borderRadius: 32, height: 50, boxShadow: '0px 4px 6px rgb(70 103 152 / 1)', fontSize: 20, fontWeight: 500 }}>{t('landingPage.buttonPathway')} <ArrowRightOutlined /></Button>
                </Link>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}