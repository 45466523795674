import { API } from '../../../../config'
import { errorHandler } from '../../auth/errorAction'

export const unmountIndexBank = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_BANK'})
}

export const indexBank = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_BANK' })
  return API.GET('/bank/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_BANK_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_BANK_FAILED' }), 
    ))
  })
}
