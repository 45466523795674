import React, { Component } from 'react'
import { Helmet } from "react-helmet";
import XpertComponent from '../../components/Xpert/Home'

export default class Xpert extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Xpert</title>
        </Helmet>
        <XpertComponent />
      </React.Fragment>
    )
  }
}