import React from 'react'
import { Link } from 'react-router-dom'
import Cookie from 'js-cookie';
import { withTranslation } from 'react-i18next';
import { Row, Col, Grid, Button, Card, Avatar, Typography, Space } from 'antd';
import { CalendarOutlined, ClockCircleOutlined, TagOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { xpertType } from '../../../utils/constant/xpertType';

const { Text } = Typography;
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export function Event(props) {
  const { getXpert, t } = props
  const { xxl } = GetBreakPoint()
   
  return (
    <React.Fragment>
      <Row className="mt-32 mb-32">
        <Col md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Row justify="space-around" align="middle">
            <Col span={12}>
              <Text className="dark-color" style={{ fontSize: 22, fontWeight: 'bold', lineHeight: 1.2 }}>{t('xpert.event')}</Text>
            </Col>
            <Col span={12}>
              <Link className="width-100 text-align-center" to={Cookie.get('user') ? '/candidate/xpert/list' : '/xpert/list'}>
                <Button className="box-shadow text-align-center" type="primary" style={{ border: '#4273b9', background: '#4273b9', borderRadius: 32, height: 40, fontSize: 16, fontWeight: 500, float: 'right' }}><ArrowRightOutlined />{t('xpert.title2')}</Button>
              </Link>
            </Col>
          </Row>
          <Row className="mt-32" gutter={[16, 16]}>
            {
              getXpert?.data?.map((item, i) => 
                <Col key={i} span={8}>
                  <Card
                    className="border-radius-6 dark-color box-shadow" 
                    style={{ backgroundColor: '#fff' }}
                    cover={
                      <img
                        alt="example"
                        src={item.imageUrl}
                        height={150}
                        style={{ objectFit: 'cover' }}
                      />
                    }
                    bordered={false}
                  >
                    <Card className="text-align-center" bordered={false} style={{ width: 120, marginTop: -54, marginLeft: -24, backgroundColor: '#32ab6d', borderRadius: '0 12px 0 0' }} bodyStyle={{ padding: 4 }}>
                      <Text className="white-color fs-12">
                        {xpertType.find(res => res.value === item.xpertType)?.name}
                      </Text>
                    </Card>
                    <Space className="mb-8 mt-8" direction="vertical" size={0}>
                      <Text className="info-color" ellipsis={true} style={{ fontSize: xxl ? 20 : 18, fontWeight: 'normal', width: xxl ? 260: 210 }}>{item.title}</Text>
                    </Space>

                    <Row className="mb-8">
                      <Col span={24}>
                        <Space>
                          <Avatar size={60} src={item.organizerLogoUrl} />
                          <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal', lineHeight: 1.2 }}>{item.organizerName}</Text>
                        </Space>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <Space direction="vertical" size={8}>
                          <Space>
                            <CalendarOutlined style={{ fontSize: 14 }} />
                            <Text className="dark-color" style={{ fontSize: 14, fontWeight: 'normal', lineHeight: 1.2 }}>{item.xpertDate}</Text>
                          </Space>
                          <Space>
                            <ClockCircleOutlined style={{ fontSize: 14 }} />
                            <Text className="dark-color" style={{ fontSize: 14, fontWeight: 'normal', lineHeight: 1.2 }}>{item.xpertTime}</Text>
                          </Space>
                          <Space>
                            <TagOutlined style={{ fontSize: 14 }} />
                            <Text className="primary-color" style={{ fontSize: 16, fontWeight: 'bold', lineHeight: 1.2 }}>
                              { 
                                item.priceType === 'RANGE_PRICE' ?
                                  `IDR ${item.price.toLocaleString()} - ${item.maxPrice.toLocaleString()}` 
                                : item.priceType === 'FIX_PRICE' ?
                                  `IDR ${item.price.toLocaleString()}` 
                                : 
                                t('xpert.free')
                              }
                            </Text>
                          </Space>
                        </Space>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              )
            }
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}


export default withTranslation()(Event)