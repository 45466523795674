import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import CButton from '../../components/Button';
import { Link } from 'react-router-dom';
import { Form, Input, Card, Row, Col, Typography, Space, message } from 'antd';
import { forgotPassEmail } from '../../redux/actions/forgotPassword/forgotPasswordAction';
import { checkEmail } from '../../redux/actions/check/checkAction';
import Cookie from 'js-cookie';

const { Text } = Typography

export class ForgotPassword extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      lang: Cookie.get('i18next')
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.i18n.language !== this.state.lang) {
      if(this.formRef.current.getFieldsError()[0]?.errors.length > 0){
        this.updateValidationMessages();
      }
      this.setState({ lang: Cookie.get('i18next') })
    }
  }
  
  updateValidationMessages = () => {
    const { t } = this.props

    this.formRef.current.setFields([
      {
        name: 'email',
        errors: [t('forgotPassword.required')] || [t('forgotPassword.type')]
      },
    ]);
  }

  onFinish =  (values) => {
    const { actionCheckEmail, actionForgotPassEmail } = this.props
    
    return actionCheckEmail(values.email, response => {
      if(response){
        return this.setState({ submitLoading: true }, () => {
          return actionForgotPassEmail(values, response => {
            if(response.code === '1000'){
              return this.setState({ submitLoading: false }, () => {
                localStorage.setItem("emailForgotPass", values.email)
                this.props.history.push('/forgot-password/verify')
              })
            }else{
              return this.setState({ submitLoading: false }, () => message.error(response.message))
            }
          }, (err) => {
            return this.setState({ submitLoading: false }, () => message.error(err.message))
          })
        })
      }else{
        message.error(`We couldn’t find an account associated with ${values.email} Please check your email address`)
      }
    })
  }

  render() {
    const { submitLoading } = this.state
    const { t } = this.props

    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Forgot Password');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Forgot Password</title>
        </Helmet>
        <Row className="display-flex" align="middle" justify="center">
          <Col>
            <Card className="card-forgot-password" bodyStyle={{ padding: 0, display: 'flex' }}>
              <Form 
                ref={this.formRef} 
                name="normal_login" 
                layout="vertical" 
                onFinish={this.onFinish}
              >
                <Row gutter={[20,20]}>
                  <Col className="fs-28" xs={24} sm={24} md={24}>
                    <Text type="secondary">{t('forgotPassword.title')}</Text>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Text type="secondary">Email</Text>
                    <Form.Item 
                      className="mb-16"
                      name="email"
                      rules={[
                        { required: true, message: t('forgotPassword.required') },
                        { type: 'email', message: t('forgotPassword.type') }
                      ]}
                    >
                      <Input 
                        type="email"
                        placeholder={t('forgotPassword.placeholder')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Space className="width-100" direction="vertical">
                      <Form.Item className="mb-0">
                        <CButton type="primary" htmlType="submit" title={t('forgotPassword.reset')} className="width-100" action={() => this.onFinish()} loading={submitLoading}/>
                      </Form.Item>
                      <Form.Item className="mb-0">
                        <Link to="/login">
                          <CButton type="default" htmlType="submit" title={t('forgotPassword.back')} className="width-100"/>
                        </Link>
                      </Form.Item>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  actionCheckEmail: checkEmail,
  actionForgotPassEmail: forgotPassEmail
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword))