import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Card, Typography, Modal, Button, Input, Space, Form, Row, Col, Divider, DatePicker, Select, Radio, message } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { indexCountry, unmountIndexCountry } from '../../../../redux/actions/master/country/countryAction';
import { listProvinceByCountry, unmountlistProvinceByCountry } from '../../../../redux/actions/master/province/provinceAction';
import { listCityByProvince, unmountlistCityByProvince } from '../../../../redux/actions/master/city/cityAction';
import { subDistrictByCity, unmountSubDistrictByCity } from '../../../../redux/actions/master/subDistrict/subDistrictAction';
import { villageBySubDistrict, unmountVillageBySubDistrict } from '../../../../redux/actions/master/village/villageAction';
import { updatePersonalInfo } from '../../../../redux/actions/profile/personalInfoAction';
import moment from 'moment';
import Cookie from 'js-cookie';
import CButton from '../../../../components/Button';

const { Text } = Typography;
const { Option } = Select;

export class PersonalInfo extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      submitLoading: false,
      birthdate: null,
      nationalityId: null,
      countryId: null,
      provinceId: null,
      cityId: null,
      subDistrictId: null,
      villageId: null,
      selectedCountry: null,
      arrFamilyHistory: [],
    }
  }
  
  componentDidMount() {
    const { data: { countryId } } = this.props
    this.setState({
      selectedCountry: countryId
    })
  }


  showModal = () => {
    const { arrFamilyHistory } = this.state
    const { actionGetCountry, actionGetProvince, actionGetCity, actionGetSubDistrict, actionGetVillage, data: { birthdate, countryId, provinceId, cityId, subDistrictId, listFamilyHistory } } = this.props

    this.setState({
      visible: true,
      birthdate: birthdate
    })

    if(listFamilyHistory.length > 0){
      listFamilyHistory.forEach((item) => {
        arrFamilyHistory.push(
          {
            id: item.id, 
            medicalConditionId: item.medicalConditionId, 
            flagStatus: item.flagStatus
          }
        )
      })
      this.setState({ arrFamilyHistory })
    }

    if(subDistrictId){
      return (actionGetCountry(), actionGetProvince(countryId), actionGetCity(provinceId), actionGetSubDistrict(cityId), actionGetVillage(subDistrictId))
    }else if(cityId){
      return (actionGetCountry(), actionGetProvince(countryId), actionGetCity(provinceId), actionGetSubDistrict(cityId))
    }else if(provinceId){
      return (actionGetCountry(), actionGetProvince(countryId), actionGetCity(provinceId))
    }else if(countryId){
      return (actionGetCountry(), actionGetProvince(countryId))
    }

    return actionGetCountry()
  };

  handleCancel = () => {
    const { actionGetCountry, actionGetProvince, actionGetCity, actionGetSubDistrict, actionGetVillage, data: { countryId, provinceId, cityId, subDistrictId } } = this.props
    
    this.setState({
      visible: false,
      selectedCountry: countryId
    })

    if(subDistrictId){
      return (actionGetCountry(), actionGetProvince(countryId), actionGetCity(provinceId), actionGetSubDistrict(cityId), actionGetVillage(subDistrictId))
    }else if(cityId){
      return (actionGetCountry(), actionGetProvince(countryId), actionGetCity(provinceId), actionGetSubDistrict(cityId))
    }else if(provinceId){
      return (actionGetCountry(), actionGetProvince(countryId), actionGetCity(provinceId))
    }else if(countryId){
      return (actionGetCountry(), actionGetProvince(countryId))
    }
  };

  onChangeDate = (date, dateString) => {
    this.setState({
      birthdate: dateString ? dateString : null
    })
  }

  handleNationality = (value, option) => {
    const optionSelected = Number(option.key)
    
    this.setState({
      nationalityId: optionSelected
    })
  }

  handleCountry = (value, option) => {
    const { provinceId } = this.state
    const optionSelected = Number(option.key)
    
    this.setState({
      countryId: optionSelected,
      selectedCountry: optionSelected,
    })

    if(provinceId !== 0){
      this.setState({
        provinceId: null,
        cityId: null,
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        province: null,
        city: null,
        subDistrict: null,
        village: null,
        zipCode: null,
        address: null
      })
    }

    const { actionGetProvince } = this.props
    return actionGetProvince(option.key)
  }

  handleProvince = (value, option) => {
    const { cityId } = this.state
    const optionSelected = Number(option.key)

    this.setState({
      provinceId: optionSelected
    })

    if(cityId !== 0){
      this.setState({
        cityId: null,
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        city: null,
        subDistrict: null,
        village: null,
        zipCode: null,
        address: null
      })
    }

    const { actionGetCity } = this.props
    return actionGetCity(option.key)
  }

  handleCity = (value, option) => {
    const { subDistrictId } = this.state
    const optionSelected = Number(option.key)
    
    this.setState({
      cityId: optionSelected
    })

    if(subDistrictId !== 0){
      this.setState({
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        subDistrict: null,
        village: null,
        zipCode: null,
        address: null
      })
    }

    const { actionGetSubDistrict } = this.props
    return actionGetSubDistrict(option.key)
  }

  handleSubDistrict = (value, option) => {
    const { villageId } = this.state
    const optionSelected = Number(option.key)
    
    this.setState({
      subDistrictId: optionSelected
    })

    if(villageId !== 0){
      this.setState({
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        village: null,
        zipCode: null,
        address: null
      })
    }

    const { actionGetVillage } = this.props
    return actionGetVillage(option.key)
  }

  handleVillage = (value, option) => {
    const optionSelected = Number(option.key)
    
    this.setState({
      villageId: optionSelected
    })

    this.formRef.current.setFieldsValue({
      zipCode: null,
      address: null
    })
  }

  handleRadio = (e, key) => {
    const { arrFamilyHistory } = this.state
    arrFamilyHistory[key].flagStatus = e.target.value
    this.setState({ arrFamilyHistory })
  }

  onFinish = async (values) => {
    const { actionUpdatePersonalInfo, actionGetProfile } = this.props
    const { birthdate, nationalityId, countryId, provinceId, cityId, subDistrictId, villageId, arrFamilyHistory } = this.state
    values.birthdate = birthdate ? birthdate : null
    values.nationalityId = nationalityId
    values.countryId = countryId
    values.provinceId = provinceId
    values.cityId = cityId
    values.subDistrictId = subDistrictId
    values.villageId = villageId
    if(arrFamilyHistory.length > 0){
      values.listFamilyHistory = arrFamilyHistory
      delete values.heartTrouble
      delete values.diabetes
      delete values.convulsion
      delete values.cancer
      delete values.tubercolosis
      delete values.allergies
      delete values.commitedSuicide
    }
    
    return this.setState({ submitLoading: true }, () => {
      return actionUpdatePersonalInfo(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            Cookie.set("activeElement", window.pageYOffset)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  render() {
    const { visible, submitLoading, selectedCountry } = this.state
    const { t, getCountry, getProvince, getCity, getSubDistrict, getVillage, data: { placeOfBirth, birthdate, marital, gender, religion, nationality, bloodType, height, weight, identificationId, countryId, countryName, provinceName, cityName, subDistrictName, villageName, zipCode, address, armReach, isAviation, listFamilyHistory } } = this.props
    const isFilled = placeOfBirth || birthdate || marital || gender || religion || nationality || bloodType || height || weight || identificationId || countryName || provinceName || cityName || subDistrictName || villageName || zipCode || address || isAviation || listFamilyHistory
    
    return (
      <React.Fragment>
        <Card 
          className="border-radius-6 box-shadow" 
          title={t('profile.personalInfo.title')} 
          bordered={false} 
          extra={<Button type="link" icon={<EditFilled />} onClick={this.showModal}>Edit</Button>} 
          headStyle={{ color: '#32ab6d', fontSize: 17 }}
          bodyStyle={isFilled ? {} : { textAlign: 'center', padding: 60 }}
        >
          {
            isFilled ?
              <React.Fragment>
                <Row className="mb-16">
                  <Col span={12}>
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">{t('profile.personalInfo.placeOfBirth')}</Text>
                        <Text className="dark-color" strong>{placeOfBirth ? placeOfBirth : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">{t('profile.personalInfo.gender')}</Text>
                        <Text className="dark-color" strong>{gender ? gender : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">{t('profile.personalInfo.height')}</Text>
                        <Text className="dark-color" strong>{height ? height : '-'}</Text>
                      </Space>
                      {
                        isAviation ?
                          <>
                            <Space direction="vertical" size={0}>
                              <Text className="fs-12" type="secondary">Arm Reach</Text>
                              <Text className="dark-color" strong>{armReach ? armReach : '-'}</Text>
                            </Space>
                            <Space direction="vertical" size={0}>
                              <Text className="fs-12" type="secondary">{t('profile.personalInfo.religion')}</Text>
                              <Text className="dark-color" strong>{religion ? religion : '-'}</Text>
                            </Space>
                            <Space direction="vertical" size={0}>
                              <Text className="fs-12" type="secondary">{t('profile.personalInfo.idCardNumber')}</Text>
                              <Text className="dark-color" strong>{identificationId ? identificationId : '-'}</Text>
                            </Space>
                          </>
                        : 
                          <>
                            <Space direction="vertical" size={0}>
                              <Text className="fs-12" type="secondary">{t('profile.personalInfo.maritalStatus')}</Text>
                              <Text className="dark-color" strong>{marital ? marital : '-'}</Text>
                            </Space>
                            <Space direction="vertical" size={0}>
                              <Text className="fs-12" type="secondary">{t('profile.personalInfo.nationality')}</Text>
                              <Text className="dark-color" strong>{nationality ? nationality : '-'}</Text>
                            </Space>
                          </>
                      }
                    </Space>
                  </Col>
                  <Col span={12}>
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">{t('profile.personalInfo.dateOfBirth')}</Text>
                        <Text className="dark-color" strong>{birthdate ? birthdate : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">{t('profile.personalInfo.bloodType')}</Text>
                        <Text className="dark-color" strong>{bloodType ? bloodType : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">{t('profile.personalInfo.weight')}</Text>
                        <Text className="dark-color" strong>{weight ? weight : '-'}</Text>
                      </Space>
                      {
                        isAviation ?
                          <>
                            <Space direction="vertical" size={0}>
                              <Text className="fs-12" type="secondary">{t('profile.personalInfo.maritalStatus')}</Text>
                              <Text className="dark-color" strong>{marital ? marital : '-'}</Text>
                            </Space>
                            <Space direction="vertical" size={0}>
                              <Text className="fs-12" type="secondary">{t('profile.personalInfo.nationality')}</Text>
                              <Text className="dark-color" strong>{nationality ? nationality : '-'}</Text>
                            </Space>
                          </>
                        :
                          <>
                            <Space direction="vertical" size={0}>
                              <Text className="fs-12" type="secondary">{t('profile.personalInfo.religion')}</Text>
                              <Text className="dark-color" strong>{religion ? religion : '-'}</Text>
                            </Space>
                            <Space direction="vertical" size={0}>
                              <Text className="fs-12" type="secondary">{t('profile.personalInfo.idCardNumber')}</Text>
                              <Text className="dark-color" strong>{identificationId ? identificationId : '-'}</Text>
                            </Space>
                          </>
                      }
                    </Space>

                  </Col>
                </Row>
                {
                  Cookie.get('email')?.split("@")[1] === 'student.binawan.ac.id' ?
                    null
                  :
                    <>
                      <Text type="secondary">{t('profile.personalInfo.idCardAddress')}</Text>
                      <Divider style={{ margin: '8px 0' }} />
                      <Row className="mb-16">
                        <Col span={12}>
                          <Space direction="vertical">
                            <Space direction="vertical" size={0}>
                              <Text className="fs-12" type="secondary">{t('profile.personalInfo.country')}</Text>
                              <Text className="dark-color" strong>{countryName ? countryName : '-'}</Text>
                            </Space>
                            <Space direction="vertical" size={0}>
                              <Text className="fs-12" type="secondary">{t('profile.personalInfo.province')}</Text>
                              <Text className="dark-color" strong>{provinceName ? provinceName : '-'}</Text>
                            </Space>
                            <Space direction="vertical" size={0}>
                              <Text className="fs-12" type="secondary">{t('profile.personalInfo.city')}</Text>
                              <Text className="dark-color" strong>{cityName ? cityName : '-'}</Text>
                            </Space>
                            {
                              countryId === 99 ?
                                <Space direction="vertical" size={0}>
                                  <Text className="fs-12" type="secondary">{t('profile.personalInfo.subDistrict')}</Text>
                                  <Text className="dark-color" strong>{subDistrictName ? subDistrictName : '-'}</Text>
                                </Space>
                              :
                                null
                            }
                          </Space>
                        </Col>
                        <Col span={12}>
                          <Space direction="vertical">
                            {
                              countryId === 99 ?
                                <Space direction="vertical" size={0}>
                                  <Text className="fs-12" type="secondary">{t('profile.personalInfo.village')}</Text>
                                  <Text className="dark-color" strong>{villageName ? villageName : '-'}</Text>
                                </Space>
                              :
                                null
                            }
                            <Space direction="vertical" size={0}>
                              <Text className="fs-12" type="secondary">{t('profile.personalInfo.postalCode')}</Text>
                              <Text className="dark-color" strong>{zipCode ? zipCode : '-'}</Text>
                            </Space>
                            <Space direction="vertical" size={0}>
                              <Text className="fs-12" type="secondary">{t('profile.personalInfo.streetAddress')}</Text>
                              <Text className="dark-color" strong>{address ? address : '-'}</Text>
                            </Space>
                          </Space>
                        </Col>
                      </Row>
                    </>
                }
                {
                  isAviation && listFamilyHistory.length > 0 ?
                    <React.Fragment>
                      <Text type="secondary">Family History</Text>
                      <Divider style={{ margin: '8px 0' }} />
                      <Row className="mb-16">
                        <Col span={12}>
                          <Space direction='vertical' size={0}>
                            <Text className="fs-12" type="secondary" >Heart Trouble</Text>
                            <Text className="dark-color" strong>
                              { 
                                listFamilyHistory[0].flagStatus !== null ?
                                  listFamilyHistory[0].flagStatus ? 'Yes' : 'No' 
                                : '-'
                              }
                            </Text>
                          </Space>
                        </Col>
                        <Col span={12}>
                          <Space direction='vertical' size={0}>
                            <Text className="fs-12" type="secondary">Diabetes</Text>
                            <Text className="dark-color" strong>
                              {
                                listFamilyHistory[1].flagStatus !== null ?
                                  listFamilyHistory[1].flagStatus ? 'Yes' : 'No'
                                : '-'
                              }
                            </Text>
                          </Space>
                        </Col>
                        <Col span={12}>
                          <Space direction='vertical' size={0}>
                            <Text className="fs-12" type="secondary">Convulsion</Text>
                            <Text className="dark-color" strong>
                              {
                                listFamilyHistory[3].flagStatus !== null ?
                                  listFamilyHistory[3].flagStatus ? 'Yes' : 'No'
                                : '-'
                              }
                            </Text>
                          </Space>
                        </Col>
                        <Col span={12}>
                          <Space direction='vertical' size={0}>
                            <Text className="fs-12" type="secondary">Cancer</Text>
                            <Text className="dark-color" strong>
                              {
                                listFamilyHistory[4].flagStatus !== null ?
                                  listFamilyHistory[4].flagStatus ? 'Yes' : 'No'
                                : '-'
                              }
                            </Text>
                          </Space>
                        </Col>
                        <Col span={12}>
                          <Space direction='vertical' size={0}>
                            <Text className="fs-12" type="secondary">Tubercolosis</Text>
                            <Text className="dark-color" strong>
                              {
                                listFamilyHistory[2].flagStatus !== null ?
                                  listFamilyHistory[2].flagStatus ? 'Yes' : 'No'
                                : '-'
                              }
                            </Text>
                          </Space>
                        </Col>
                        <Col span={12}>
                          <Space direction='vertical' size={0}>
                            <Text className="fs-12" type="secondary">Allergies</Text>
                            <Text className="dark-color" strong>
                              {
                                listFamilyHistory[5].flagStatus !== null ?
                                  listFamilyHistory[5].flagStatus ? 'Yes' : 'No'
                                : '-'
                              }
                            </Text>
                          </Space>
                        </Col>
                        <Col span={12}>
                          <Space direction='vertical' size={0}>
                            <Text className="fs-12" type="secondary">Commited Suicide</Text>
                            <Text className="dark-color" strong>
                              {
                                listFamilyHistory[6].flagStatus !== null ?
                                  listFamilyHistory[6].flagStatus ? 'Yes' : 'No'
                                : '-'
                              }
                            </Text>
                          </Space>
                        </Col>
                      </Row>  
                    </React.Fragment>
                  : null
                }
              </React.Fragment>
            :
              <Text className="dark-color">{t('profile.personalInfo.textCard')}</Text>
          }
        </Card>

        <Modal 
          title={t('profile.personalInfo.title')} 
          visible={visible} 
          onCancel={this.handleCancel} 
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          centered
          width={600}
          destroyOnClose
          maskClosable={false}
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
            initialValues={{
              placeOfBirth: placeOfBirth,
              birthdate: birthdate ? moment(birthdate, 'DD/MM/YYYY') : null,
              gender: gender,
              bloodType: bloodType,
              height: height ? height.toString() : null,
              weight: weight ? weight.toString(): null,
              armReach: armReach ? armReach.toString() : null,
              marital: marital,
              religion: religion,
              nationality: nationality,
              identificationId: identificationId,
              country: countryName,
              province: provinceName,
              city: cityName,
              subDistrict: subDistrictName,
              village: villageName,
              zipCode: zipCode,
              address: address,
              heartTrouble: listFamilyHistory[0]?.flagStatus,
              diabetes: listFamilyHistory[1]?.flagStatus,
              convulsion: listFamilyHistory[3]?.flagStatus,
              cancer: listFamilyHistory[4]?.flagStatus,
              tubercolosis: listFamilyHistory[2]?.flagStatus,
              allergies: listFamilyHistory[5]?.flagStatus,
              commitedSuicide: listFamilyHistory[6]?.flagStatus
            }}
          >
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Text className="fs-12" type="secondary">{t('profile.personalInfo.placeOfBirth')}</Text>
                <Form.Item 
                  name="placeOfBirth"
                  validateFirst
                  rules={[
                    { pattern: /^[a-zA-Z ]*$/, message: t('a-zA-Z') },
                    { max: 50, message: t('max',{number:50}) },
                  ]}
                >
                  <Input  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="fs-12" type="secondary">{t('profile.personalInfo.dateOfBirth')}</Text>
                <Form.Item name="birthdate">
                  <DatePicker inputReadOnly className="width-100" format="DD/MM/YYYY" onChange={this.onChangeDate} defaultPickerValue={birthdate ? null : moment('2000-01-01')} />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col xs={12} md={6}>
                <Text className="fs-12" type="secondary" >{t('profile.personalInfo.gender')}</Text>
                <Form.Item name="gender">
                  <Select 
                    className="width-100 border-radius-6"
                  >
                    <Option key="1" value="MALE">{t('profile.personalInfo.male')}</Option>
                    <Option key="2" value="FEMALE">{t('profile.personalInfo.female')}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Text className="fs-12" type="secondary">{t('profile.personalInfo.bloodType')}</Text>
                <Form.Item name="bloodType">
                  <Select 
                    className="width-100 border-radius-6"
                  >
                    <Option key="1" value="A">A</Option>
                    <Option key="2" value="AB">AB</Option>
                    <Option key="3" value="B">B</Option>
                    <Option key="4" value="O">O</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Text className="fs-12" type="secondary">{t('profile.personalInfo.height')}</Text>
                <Form.Item 
                  name="height"
                  validateFirst
                  rules={[
                    { pattern: /^[0-9]*$/, message: t('0-9') },
                    { min: 2, message: t('min',{number:2}) },
                    { max: 3, message: t('max',{number:3}) },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Text className="fs-12" type="secondary">{t('profile.personalInfo.weight')}</Text>
                <Form.Item 
                  name="weight"
                  validateFirst
                  rules={[
                    { pattern: /^[0-9]*$/, message: t('0-9') },
                    { min: 2, message: t('min',{number:2}) },
                    { max: 3, message: t('max',{number:3}) },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            {
              isAviation ?
                <Row className="mb-min-8" gutter={8}>
                  <Col span={8}>
                    <Text className="fs-12" type="secondary">Arm Reach (cm)</Text>
                    <Form.Item 
                      name="armReach"
                      validateFirst
                      rules={[
                        { pattern: /^[0-9]*$/, message: t('0-9') },
                        { min: 2, message: t('min',{number:2}) },
                        { max: 3, message: t('max',{number:3}) },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Text className="fs-12" type="secondary">{t('profile.personalInfo.maritalStatus')}</Text>
                    <Form.Item name="marital">
                      <Select 
                        className="width-100 border-radius-6"
                      >
                        <Option key="1" value="SINGLE">{t('profile.personalInfo.single')}</Option>
                        <Option key="2" value="MARRIED">{t('profile.personalInfo.married')}</Option>
                        <Option key="3" value="DIVORCE">{t('profile.personalInfo.divorce')}</Option>
                        <Option key="4" value="WIDOWED">{t('profile.personalInfo.widowed')}</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Text className="fs-12" type="secondary">{t('profile.personalInfo.religion')}</Text>
                    <Form.Item name="religion">
                      <Select 
                        className="width-100 border-radius-6"
                      >
                        <Option key="1" value="ISLAM">Islam</Option>
                        <Option key="2" value="CATHOLIC">{t('profile.personalInfo.catholic')}</Option>
                        <Option key="3" value="PROTESTAN">Protestan</Option>
                        <Option key="4" value="BUDDHA">Buddha</Option>
                        <Option key="5" value="HINDU">Hindu</Option>
                        <Option key="6" value="OTHERS">{t('profile.personalInfo.others')}</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              :
                <Row className="mb-min-8" gutter={8}>
                  <Col span={12}>
                    <Text className="fs-12" type="secondary">{t('profile.personalInfo.maritalStatus')}</Text>
                    <Form.Item name="marital">
                      <Select 
                        className="width-100 border-radius-6"
                      >
                        <Option key="1" value="SINGLE">{t('profile.personalInfo.single')}</Option>
                        <Option key="2" value="MARRIED">{t('profile.personalInfo.married')}</Option>
                        <Option key="3" value="DIVORCE">{t('profile.personalInfo.divorce')}</Option>
                        <Option key="4" value="WIDOWED">{t('profile.personalInfo.widowed')}</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Text className="fs-12" type="secondary">{t('profile.personalInfo.religion')}</Text>
                    <Form.Item name="religion">
                      <Select 
                        className="width-100 border-radius-6"
                      >
                        <Option key="1" value="ISLAM">Islam</Option>
                        <Option key="2" value="CATHOLIC">{t('profile.personalInfo.catholic')}</Option>
                        <Option key="3" value="PROTESTAN">Protestan</Option>
                        <Option key="4" value="BUDDHA">Buddha</Option>
                        <Option key="5" value="HINDU">Hindu</Option>
                        <Option key="6" value="OTHERS">{t('profile.personalInfo.others')}</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
            }
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Text className="fs-12" type="secondary">{t('profile.personalInfo.nationality')}</Text>
                <Form.Item name="nationality">
                  <Select 
                    className="width-100" 
                    showSearch
                    onChange={this.handleNationality}
                  >
                    {
                      getCountry?.data?.map((item) => 
                        <Option key={item.id} value={item.nationality}>{item.nationality}</Option>
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="fs-12" type="secondary">{t('profile.personalInfo.idCardNumber')}</Text>
                <Form.Item 
                  name="identificationId"
                  validateFirst
                  rules={[
                    { pattern: /^[0-9]*$/, message: t('0-9') },
                    { len: 16, message: t('len',{number:16}) },
                  ]}
                >
                  <Input  />
                </Form.Item>
              </Col>
            </Row>
            {
              Cookie.get('email')?.split("@")[1] === 'student.binawan.ac.id' ?
                null
              :
                <>
                  <Row>
                    <Col span={24}>
                      <Text className="fs-12" type="secondary">{t('profile.personalInfo.idCardAddress')}</Text>
                    </Col>
                  </Row>
                  <Row className="mb-min-8" gutter={8}>
                    <Col span={12}>
                      <Form.Item name="country">
                        <Select 
                          className="width-100" 
                          showSearch
                          onChange={this.handleCountry}
                          placeholder={t('profile.personalInfo.country')}
                        >
                          {
                            getCountry?.data?.map((item) => 
                              <Option key={item.id} value={item.name}>{item.name}</Option>
                              )
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="province">
                        <Select 
                          className="width-100" 
                          showSearch
                          onChange={this.handleProvince}
                          placeholder={t('profile.personalInfo.province')}
                        >
                          {
                            getProvince?.data?.map((item) => 
                              <Option key={item.id} value={item.name}>{item.name}</Option>
                              )
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="mb-min-8" gutter={8}>
                    <Col span={12}>
                      <Form.Item name="city">
                        <Select 
                          className="width-100" 
                          showSearch
                          onChange={this.handleCity}
                          placeholder={t('profile.personalInfo.city')}
                        >
                          {
                            getCity?.data?.map((item) => 
                              <Option key={item.id} value={item.name}>{item.name}</Option>
                              )
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                    {
                      selectedCountry === 99 ?
                        <Col span={12}>
                          <Form.Item name="subDistrict">
                            <Select 
                              className="width-100" 
                              showSearch
                              onChange={this.handleSubDistrict}
                              placeholder={t('profile.personalInfo.subDistrict')}
                            >
                              {
                                getSubDistrict?.data?.map((item) => 
                                  <Option key={item.id} value={item.name}>{item.name}</Option>
                                  )
                              }
                            </Select>
                          </Form.Item>
                        </Col>
                      :
                        <Col span={12}>
                          <Form.Item 
                            name="zipCode"
                            validateFirst
                            rules={[
                              { pattern: /^[0-9]*$/, message: t('0-9') },
                              { max: 10, message: t('max',{number:10}) },
                            ]}
                          >
                            <Input  placeholder={t('profile.personalInfo.postalCode')} />
                          </Form.Item>
                        </Col>
                    }
                  </Row>
                  {
                    selectedCountry === 99 ?
                      <Row className="mb-min-8" gutter={8}>
                        <Col span={12}>
                          <Form.Item name="village">
                            <Select 
                              className="width-100" 
                              showSearch
                              onChange={this.handleVillage}
                              placeholder={t('profile.personalInfo.village')}
                            >
                              {
                                getVillage?.data?.map((item) => 
                                  <Option key={item.id} value={item.name}>{item.name}</Option>
                                  )
                              }
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item 
                            name="zipCode"
                            validateFirst
                            rules={[
                              { pattern: /^[0-9]*$/, message: t('0-9') },
                              { max: 10, message: t('max',{number:10}) },
                            ]}
                          >
                            <Input placeholder={t('profile.personalInfo.postalCode')} />
                          </Form.Item>
                        </Col>
                      </Row>
                    :
                      null
                  }
                  <Row className="mb-min-8">
                    <Col span={24}>
                      <Form.Item 
                        name="address"
                        validateFirst
                        rules={[
                          { max: 75, message: t('max',{number:75}) },
                        ]}
                      >
                        <Input placeholder={t('profile.personalInfo.streetAddress')} />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
            }
            {
              isAviation ?
                <>
                  <Row gutter={8}>
                    <Col className="mb-min-8" xs={24} sm={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Heart Trouble</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="heartTrouble"
                        // rules={[
                        //   { required: true, message: 'This field is mandatory!' }
                        // ]}
                      >
                        <Radio.Group onChange={(e) => this.handleRadio(e, 0)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col className="mb-min-8" xs={24} sm={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Diabetes</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="diabetes"
                        // rules={[
                        //   { required: true, message: 'This field is mandatory!' }
                        // ]}
                        
                      >
                        <Radio.Group onChange={(e) => this.handleRadio(e, 1)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-min-8" xs={24} sm={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Convulsion</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="convulsion"
                        // rules={[
                        //   { required: true, message: 'This field is mandatory!' }
                        // ]}
                      >
                        <Radio.Group onChange={(e) => this.handleRadio(e, 3)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col className="mb-min-8" xs={24} sm={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Cancer</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="cancer"
                        // rules={[
                        //   { required: true, message: 'This field is mandatory!' }
                        // ]}
                      >
                        <Radio.Group onChange={(e) => this.handleRadio(e, 4)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-min-8" xs={24} sm={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Tubercolosis</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="tubercolosis"
                        // rules={[
                        //   { required: true, message: 'This field is mandatory!' }
                        // ]}
                      >
                        <Radio.Group onChange={(e) => this.handleRadio(e, 2)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col className="mb-min-8" xs={24} sm={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Allergies</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="allergies"
                        // rules={[
                        //   { required: true, message: 'This field is mandatory!' }
                        // ]}
                      >
                        <Radio.Group onChange={(e) => this.handleRadio(e, 5)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-min-8" xs={24} sm={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Commited Suicide</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="commitedSuicide"
                        // rules={[
                        //   { required: true, message: 'This field is mandatory!' }
                        // ]}
                      >
                        <Radio.Group onChange={(e) => this.handleRadio(e, 6)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              : null
            }
            <Form.Item>
              <Space className="float-right">
                <CButton type='default' title={t('profile.buttonBack')} action={this.handleCancel}/>
                <CButton type="primary" title={t('profile.buttonSave')} htmlType="submit" loading={submitLoading} />
              </Space>
            </Form.Item>
          </Form>
        </Modal>


      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountIndexCountry, unmountlistProvinceByCountry, unmountlistCityByProvince, unmountSubDistrictByCity, unmountVillageBySubDistrict } = this.props
    return (unmountIndexCountry(), unmountlistProvinceByCountry(), unmountlistCityByProvince(), unmountSubDistrictByCity(), unmountVillageBySubDistrict())
  }
}

const mapStateToProps = (state) => ({
  getCountry: state.countryReducer,
  getProvince: state.provinceReducer,
  getCity: state.cityReducer,
  getSubDistrict: state.subDistrictReducer,
  getVillage: state.villageReducer
})

const mapDispatchToProps = {
  actionGetCountry: indexCountry,
  unmountIndexCountry: unmountIndexCountry,
  actionGetProvince: listProvinceByCountry,
  unmountlistProvinceByCountry: unmountlistProvinceByCountry,
  actionGetCity: listCityByProvince,
  unmountlistCityByProvince: unmountlistCityByProvince,
  actionGetSubDistrict: subDistrictByCity,
  unmountSubDistrictByCity: unmountSubDistrictByCity,
  actionGetVillage: villageBySubDistrict,
  unmountVillageBySubDistrict: unmountVillageBySubDistrict,
  actionUpdatePersonalInfo: updatePersonalInfo
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PersonalInfo))