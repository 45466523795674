import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loading } from '../../../components'
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { indexCountry } from '../../../redux/actions/master/country/countryAction';
import { setEmployerRegister } from '../../../redux/actions/employer/employerAction';
// import { Form, Input, Button, Row, Col, Card, Typography, Select, Checkbox, Divider, Tooltip, message } from 'antd';
import { Form, Input, Row, Col, Card, Typography, Select, Checkbox, message } from 'antd';
// import { GoogleOutlined, FacebookFilled, InfoCircleTwoTone } from '@ant-design/icons';
import './Register.css';
import CButton from '../../../components/Button';

const { Option } = Select;
const { Text } = Typography;


export class EmployerRegistration extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      idCallingCode: 99,
      callingCode: 62,
      agree: false,
      visible: false
    }
  }
  
  componentDidMount() {
    const { actionGetCountry } = this.props

    if(localStorage.getItem('link')){
      localStorage.removeItem('link')
    }
    
    if(localStorage.getItem('visible')){
      localStorage.removeItem('visible')
    }

    return actionGetCountry()
  }

  onFinish = (values) => {
    const { idCallingCode } = this.state
    const { actionSetEmployerRegister } = this.props
    const { callingCode } = this.state

    values.callingCodeId = idCallingCode
    values.picMobilePhone = `${callingCode}${values.picMobilePhone}`
    
    this.setState({ submitLoading: true }, () => {
      return actionSetEmployerRegister(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false }, () => {
            this.props.history.push('/employer/registration/success')
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  onChangeCheck = (e) => {
    this.setState({
      agree: e.target.checked
    })
  }

  onChangeCode = (value, option) => {
    this.setState({
      callingCode: Number(option.text),
      idCallingCode: Number(option.key)
    })
    this.formRef.current.setFieldsValue({
      mobile: null
    })
  }

  onCheckEmail = (e) => {
    const { actionCheckEmail } = this.props
    return actionCheckEmail(e, response => {
      if(response){
        this.formRef.current.setFieldsValue({
          email: null
        })
        message.error('Email already exist')
      }
    })
  }
  
  onCheckMobile = (e) => {
    this.formRef.current.setFieldsValue({
      picMobilePhone: e.replace(/^0+/, '')
    })
  }

  render() {
    const { agree, submitLoading } = this.state
    const { getCountry: { loading, data } } = this.props

    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Employer Registration');
    }
    
    if(loading){
      return <Loading />
    }

    const prefixSelector = (
      <Form.Item name="code" noStyle>
        <Select className="prefix-selector" onChange={this.onChangeCode} showSearch>
          {
            data?.map(item =>
              <Option text={item.callingCode} key={item.id} value={`(+${item.callingCode}) ${item.name}`}>{`(+${item.callingCode}) ${item.name}`}</Option>
            )
          }
        </Select>
      </Form.Item>
    )

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Employer Registration</title>
        </Helmet>
        <Row className="display-flex" align="middle" justify="center">
          <Col>
            <Card className="card-register" bodyStyle={{ padding: 0, display: 'flex' }}>
              <Form 
                ref={this.formRef}
                initialValues={{ 
                  code: '(+62) Indonesia'
                }}
                onFinish={this.onFinish}
                layout="vertical"
              >
                <Row gutter={8}>
                  <Col className="fs-28" xs={24} sm={24} md={24}>
                    <Text type="secondary">Employer Registration</Text>
                  </Col>

                  <Col className="fs-13 mb-16" xs={24} sm={24} md={24}>
                    <Text type="secondary">We will reach you out as soon as possible</Text>
                  </Col>

                  <Col span={24}>
                    <Text className="fs-12" type="secondary">Employer Name</Text>
                    <Form.Item 
                      className="mb-16"
                      name="name" 
                      validateFirst
                      rules={[
                        { required: true, message: 'Employer Name is required!' },
                        { min: 3, message: 'Employer Name must be at least 3 characters long' },
                        { max: 50, message: 'Employer Name is too long. Maximum is 50 characters' }
                      ]}
                    >
                      <Input placeholder="employer name" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Text className="fs-12" type="secondary">Employer Email</Text>
                    <Form.Item 
                      className="mb-16"
                      name="email"
                      validateFirst
                      rules={[
                        { required: true, message: 'Email is required!' },
                        { type: 'email', message: 'Your email address is invalid!' },
                        // Hanya untuk di production jika di staging akan di matikan
                        // { pattern:  /.+@(gmail|yahoo)\.com$/, message: 'Preferred using Gmail or Yahoo' }
                      ]}
                    >
                      <Input type="email" placeholder="valid email address" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Text className="fs-12" type="secondary">Contact Person</Text>
                    <Form.Item 
                      className="mb-16"
                      name="picName" 
                      validateFirst
                      rules={[
                        { required: true, message: 'Contact Person is required!' },
                        { pattern: /^[a-zA-Z ]*$/, message: 'Special characters and numbers are not allowed' },
                        { min: 3, message: 'Contact Person must be at least 3 characters long.' },
                        { max: 50, message: 'Contact person must not be more than 50 characters long' },
                      ]}
                    >
                      <Input placeholder="contact person full name" />
                    </Form.Item>
                  </Col>
                  
                  <Col span={24}>
                    <Text className="fs-12" type="secondary">Contact Person Mobile Number</Text>
                    <Form.Item 
                      className="mb-16"
                      name="picMobilePhone"
                      validateFirst
                      rules={[
                        { required: true, message: 'Contact Person Mobile Number is required!' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },
                        { min: 7, max: 13, message: 'Your mobile number is invalid' }
                      ]}
                    >
                      <Input onBlur={(e) => this.onCheckMobile(e.target.value)} addonBefore={prefixSelector} placeholder="Type your primary phone number" />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <Form.Item 
                      className="mb-16"
                      name="agree"
                      rules={[
                        { 
                          validator(rule, value, callback){
                            if(!agree){
                              callback('Please check this box to proceed your account')
                            }else{
                              callback()
                            }
                          }
                        }
                      ]}
                    >
                      <Checkbox className="fs-11 dark-color" onChange={this.onChangeCheck} checked={agree}>
                        By creating an account you agree to the 
                        <Link className="info-color" to="/terms-and-conditions" target="_blank"> terms and conditions</Link> and 
                        <Link className="info-color" to="/privacy-policy" target="_blank"> privacy policy </Link>
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item className="mb-8">
                      <CButton type="primary" htmlType="submit" className="login-form-button" loading={submitLoading} block title="Submit"/>
                    </Form.Item>
                  </Col>
                  
                  <Col xs={24} sm={24} md={24}>
                    <Text className="fs-11" type="secondary">Already have an account? <a className="info-color" href="https://dashboard.jobshub.id" target="_blank" rel="noreferrer">Go to login</a> </Text>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  getCountry: state.countryReducer
})

const mapDispatchToProps = {
  actionGetCountry: indexCountry,
  actionSetEmployerRegister: setEmployerRegister
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployerRegistration)