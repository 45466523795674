import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Row, Col, Divider, Card, Avatar, Typography, Carousel, Space } from 'antd';
import { Helmet } from "react-helmet";
import Banner from './banner';
import KelasPopuler from './kelasPopuler';
import Slider from './slider';
// import KelasKhusus from './kelasKhusus';
import Event from './event';
import { listXpert, unmountListXpert } from '../../../redux/actions/xpert/xpertAction';
// import { UserOutlined } from '@ant-design/icons';

// const { Text } = Typography;


export class IndexXpert extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      params: {
       page: 1,
       perpage: 3
      }
    }
  }

  componentDidMount() {
    const { params } = this.state
    const { actionGetXpert } = this.props

    return actionGetXpert(params, () => {})
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Xpert</title>
        </Helmet>
        <Banner />
        <KelasPopuler {...this.props} />
        <Slider />
        {/* <KelasKhusus /> */}
        <Event {...this.props} />
        {/* <Row>
          <Col md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
            <Divider />
          </Col>
        </Row>
        <Row className="mb-32">
          <Col md={{ span: 18, offset: 3 }} xxl={{ span: 14, offset: 5 }}>
            <Row gutter={32}>
              <Col className="width-100 text-align-center mb-32" span={24}>
                <Text className="dark-color" style={{ fontSize: 28, fontWeight: 'bold' }}>Berbagi pengalaman dengan Jobshub Xpert</Text>
              </Col>
              
              <Col span={8}>
                <Card
                  className="border-radius-6 dark-color box-shadow" 
                  style={{ backgroundColor: '#fff' }}
                  bordered={false}
                >
                  <Space className="width-100 text-align-center" direction="vertical" size={16}>
                    <Avatar className="text-align-center" size="large" icon={<UserOutlined />} />
                    <Text className="light-color" style={{ fontSize: 16, fontWeight: 'normal', lineHeight: 1.2 }}>Kulit menjadi lebih putih dan bersih, membuat saya semakin percaya diri, terimakasih jobshub xpert</Text>
                    <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'bold', lineHeight: 1.2 }}>Jane Dae</Text>
                  </Space>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  className="border-radius-6 dark-color box-shadow" 
                  style={{ backgroundColor: '#fff' }}
                  bordered={false}
                >
                  <Space className="width-100 text-align-center" direction="vertical" size={16}>
                    <Avatar className="text-align-center" size="large" icon={<UserOutlined />} />
                    <Text className="light-color" style={{ fontSize: 16, fontWeight: 'normal', lineHeight: 1.2 }}>Kulit menjadi lebih putih dan bersih, membuat saya semakin percaya diri, terimakasih jobshub xpert</Text>
                    <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'bold', lineHeight: 1.2 }}>Jane Dae</Text>
                  </Space>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  className="border-radius-6 dark-color box-shadow" 
                  style={{ backgroundColor: '#fff' }}
                  bordered={false}
                >
                  <Space className="width-100 text-align-center" direction="vertical" size={16}>
                    <Avatar className="text-align-center" size="large" icon={<UserOutlined />} />
                    <Text className="light-color" style={{ fontSize: 16, fontWeight: 'normal', lineHeight: 1.2 }}>Kulit menjadi lebih putih dan bersih, membuat saya semakin percaya diri, terimakasih jobshub xpert</Text>
                    <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'bold', lineHeight: 1.2 }}>Jane Dae</Text>
                  </Space>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row> */}
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountListXpert } = this.props
    
    return unmountListXpert()
  }
}

const mapStateToProps = (state) => ({
  getXpert: state.xpertReducer
})

const mapDispatchToProps = {
  actionGetXpert: listXpert,
  unmountListXpert: unmountListXpert,
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexXpert)
