export const questionStructure = [
  {
    question:
      "My family and I are very happy with ________ family. Everyone in the village is happy.",
    answers: [
      {
        value: 1,
        answerLabel: "our",
      },
      {
        value: 0,
        answerLabel: "we",
      },
      {
        value: 0,
        answerLabel: "us",
      },
    ],
  },
  {
    question:
      "If I _____ the problem to my sister, she'll be happy to help me.",
    answers: [
      {
        value: 0,
        answerLabel: "explained",
      },
      {
        value: 0,
        answerLabel: "will explain",
      },
      {
        value: 1,
        answerLabel: "explain",
      },
    ],
  },
  {
    question:
      "Mount Kilimanjaro, in Tanzania, is one ___ the largest volcanoes in the world.",
    answers: [
      {
        value: 0,
        answerLabel: "that",
      },
      {
        value: 1,
        answerLabel: "of",
      },
      {
        value: 0,
        answerLabel: "which",
      },
    ],
  },
  {
    question:
      "I went to the cafe because I wanted to email my sister on her birthday, but the cafe ____________",
    answers: [
      {
        value: 1,
        answerLabel: "was closed",
      },
      {
        value: 0,
        answerLabel: "is closed",
      },
      {
        value: 0,
        answerLabel: "were closed",
      },
    ],
  },
  {
    question:
      "Over the years, the Taj Mahal has suffered from environmental damage, and there _________________to conserve its beauty.",
    answers: [
      {
        value: 0,
        answerLabel: "are been many governments attempts",
      },
      {
        value: 0,
        answerLabel: "many government attempts have been ",
      },
      {
        value: 1,
        answerLabel: "have been many government attempts",
      },
    ],
  },
  {
    question:
      "In a cahsless society, _________________________ enough change for the bus. That's great if you don't like carrying too many coins in your pocket.",
    answers: [
      {
        value: 1,
        answerLabel: "you don't need to worry about having",
      },
      {
        value: 0,
        answerLabel: "you need to worry about have",
      },
      {
        value: 0,
        answerLabel: "you aren't needed worry about having",
      },
    ],
  },
  {
    question:
      "Huang Qinjun is a photographer ___________ and takes photos of people that are standing outside their homes with their household possessions.",
    answers: [
      {
        value: 1,
        answerLabel: "Who travels around China",
      },
      {
        value: 0,
        answerLabel: "Which travels around China",
      },
      {
        value: 0,
        answerLabel: "Whose travels around China",
      },
    ],
  },
  {
    question:
      "In Britain, the average person spends more than £1,000 on new clothes a year, ______ around four percent of their income.",
    answers: [
      {
        value: 0,
        answerLabel: "as if",
      },
      {
        value: 0,
        answerLabel: "although",
      },
      {
        value: 1,
        answerLabel: "which is",
      },
    ],
  },
  {
    question:
      "__________ J.K. Rowling, Harry Potter book was first adopted as movie in 2001.",
    answers: [
      {
        value: 0,
        answerLabel: "She wrote",
      },
      {
        value: 1,
        answerLabel: "Written by",
      },
      {
        value: 0,
        answerLabel: "Was written by",
      },
    ],
  },
  {
    question:
      "Nobuyuki Tsujii was born blind, but ________________ at the age of just two. He began learning the piano two years later, and he gave his first big concert in Tokyo when he was 12 years old.",
    answers: [
      {
        value: 1,
        answerLabel: "he started playing on a toy piano",
      },
      {
        value: 0,
        answerLabel: "he starts playing on a toy piano",
      },
      {
        value: 0,
        answerLabel: "he starting play on a toy piano",
      },
    ],
  },
  {
    question:
      "My parents are coming to stay next month. I hope I __________ decorating the house by then.",
    answers: [
      {
        value: 1,
        answerLabel: "will have finished",
      },
      {
        value: 0,
        answerLabel: "will be finishing",
      },
      {
        value: 0,
        answerLabel: "am finishing",
      },
    ],
  },
  {
    question:
      "Imagine! this time next year I __________ on the other side of the world.",
    answers: [
      {
        value: 0,
        answerLabel: "live",
      },
      {
        value: 0,
        answerLabel: "will have lived",
      },
      {
        value: 1,
        answerLabel: "will be living",
      },
    ],
  },
  {
    question:
      "Look at that tree. It's really leaning over. I think it ____________________.",
    answers: [
      {
        value: 0,
        answerLabel: "will be falling",
      },
      {
        value: 0,
        answerLabel: "will have fallen",
      },
      {
        value: 1,
        answerLabel: "is going to fall",
      },
    ],
  },
  {
    question:
      "_____ they'd only known each other for two months, they got engaged.",
    answers: [
      {
        value: 0,
        answerLabel: "In spite of",
      },
      {
        value: 1,
        answerLabel: "Even though",
      },
      {
        value: 0,
        answerLabel: "Despite",
      },
    ],
  },
  {
    question: "A: I've never seen anything like this before.\nB: Neither____I",
    answers: [
      {
        value: 0,
        answerLabel: "do",
      },
      {
        value: 1,
        answerLabel: "have",
      },
      {
        value: 0,
        answerLabel: "are",
      },
    ],
  },
  {
    question: "We'll be late for the film if we _____.",
    answers: [
      {
        value: 1,
        answerLabel: "don't hurry up",
      },
      {
        value: 1,
        answerLabel: "won't hurry up",
      },
      {
        value: 0,
        answerLabel: "didn't hurry up",
      },
    ],
  },
  {
    question: "What would you say if he _____ you on a date?",
    answers: [
      {
        value: 1,
        answerLabel: "asks",
      },
      {
        value: 0,
        answerLabel: "would ask",
      },
      {
        value: 0,
        answerLabel: "asked",
      },
    ],
  },
  {
    question:
      "If I _____ so much cheese last night, I wouldn't feel terrible now.",
    answers: [
      {
        value: 1,
        answerLabel: "didn't eat",
      },
      {
        value: 0,
        answerLabel: "wouldn't eat",
      },
      {
        value: 0,
        answerLabel: "hadn't eaten",
      },
    ],
  },
  {
    question: "If you hadn't seen the car, it _____ us back there.",
    answers: [
      {
        value: 0,
        answerLabel: "hadn't hit",
      },
      {
        value: 0,
        answerLabel: "would hit",
      },
      {
        value: 1,
        answerLabel: "would have hit",
      },
    ],
  },
  {
    question:
      "We'll have __________a bit of Korean by the time you come to Seoul. We're starting classes next week.",
    answers: [
      {
        value: 0,
        answerLabel: "learn",
      },
      {
        value: 1,
        answerLabel: "learnt",
      },
      {
        value: 0,
        answerLabel: "learning",
      },
    ],
  },
  // {
  //     "question": "Why were there no buses yesterday? Maybe it was the snow or they _____ been on strike.",
  //     "answers": [{
  //             "value": 1,
  //             "answerLabel": "might have"
  //         },{
  //             "value": 0,
  //             "answerLabel": "must have"
  //         },{
  //             "value": 0,
  //             "answerLabel": "couldn't have"
  //         }
  //     ]
  // },
  // {
  //     "question": "You _____ seen her, surely! She was standing right in front of you.",
  //     "answers": [{
  //             "value": 1,
  //             "answerLabel": "must have"
  //         },{
  //             "value": 0,
  //             "answerLabel": "can't have"
  //         },{
  //             "value": 0,
  //             "answerLabel": "might have"
  //         }
  //     ]
  // },
  // {
  //     "question": "Chocolate ___ for over 4,000 years.",
  //     "answers": [{
  //             "value": 1,
  //             "answerLabel": "has been produced"
  //         },{
  //             "value": 0,
  //             "answerLabel": "has been produce"
  //         },{
  //             "value": 0,
  //             "answerLabel": "is been produced"
  //         }
  //     ]
  // },
  // {
  //     "question": "My car ___ this week, so I'm going to work by bus.",
  //     "answers": [{
  //             "value": 1,
  //             "answerLabel": "is being repaired"
  //         },{
  //             "value": 0,
  //             "answerLabel": "is been repair"
  //         },{
  //             "value": 0,
  //             "answerLabel": "is repairing"
  //         }
  //     ]
  // },
  // {
  //     "question": "Amelia Earhart_____born in the USA in July, 1897. She was the first woman to fly across the Atlantic alone.",
  //     "answers": [{
  //             "value": 0,
  //             "answerLabel": "were"
  //         },{
  //             "value": 0,
  //             "answerLabel": "is"
  //         },{
  //             "value": 1,
  //             "answerLabel": "was"
  //         }
  //     ]
  // },
  // {
  //     "question": "He wants to be a nurse ________he loves helping people.",
  //     "answers": [{
  //             "value": 1,
  //             "answerLabel": "like"
  //         },{
  //             "value": 0,
  //             "answerLabel": "as"
  //         }
  //     ]
  // },
  // {
  //     "question": "I was so tired. I slept _______a baby.",
  //     "answers": [{
  //             "value": 1,
  //             "answerLabel": "like"
  //         },{
  //             "value": 0,
  //             "answerLabel": "as"
  //         }
  //     ]
  // },
  // {
  //     "question": "He's only 22 and he's _____ written a best-selling book.",
  //     "answers": [{
  //             "value": 0,
  //             "answerLabel": "still"
  //         },{
  //             "value": 0,
  //             "answerLabel": "yet"
  //         },{
  //             "value": 1,
  //             "answerLabel": "already"
  //         }
  //     ]
  // },
  // {
  //     "question": "I'm sure she's on her way. I've ____ seen her in reception.",
  //     "answers": [{
  //             "value": 1,
  //             "answerLabel": "just"
  //         },{
  //             "value": 0,
  //             "answerLabel": "yet"
  //         },{
  //             "value": 0,
  //             "answerLabel": "still"
  //         }
  //     ]
  // },
  // {
  //     "question": "I'm sure she's on her way. I've ____ seen her in reception.",
  //     "answers": [{
  //             "value": 0,
  //             "answerLabel": "still"
  //         },{
  //             "value": 0,
  //             "answerLabel": "yet"
  //         },{
  //             "value": 1,
  //             "answerLabel": "just"
  //         }
  //     ]
  // },
  // {
  //     "question": "I _____ work at 9 a.m. this morning.",
  //     "answers": [{
  //             "value": 0,
  //             "answerLabel": "am"
  //         },{
  //             "value": 1,
  //             "answerLabel": "started"
  //         },{
  //             "value": 0,
  //             "answerLabel": "was starting"
  //         }
  //     ]
  // },
  // {
  //     "question": "This time last year I _____ at university.",
  //     "answers": [{
  //             "value": 0,
  //             "answerLabel": "studies"
  //         },{
  //             "value": 1,
  //             "answerLabel": "was studying"
  //         },{
  //             "value": 0,
  //             "answerLabel": "studied"
  //         }
  //     ]
  // },
  // {
  //     "question": "__________ Núria live in Valencia? – No, she lives in Barcelona.",
  //     "answers": [{
  //             "value": 1,
  //             "answerLabel": "does"
  //         },{
  //             "value": 0,
  //             "answerLabel": "do"
  //         },{
  //             "value": 0,
  //             "answerLabel": "did"
  //         }
  //     ]
  // },
  // {
  //     "question": "_______ you speak Korean? – Just a little.",
  //     "answers": [{
  //             "value": 1,
  //             "answerLabel": "Can"
  //         },{
  //             "value": 0,
  //             "answerLabel": "Won't"
  //         },{
  //             "value": 0,
  //             "answerLabel": "Would"
  //         }
  //     ]
  // },
  // {
  //     "question": "I enjoy _____ online games.",
  //     "answers": [{
  //             "value": 1,
  //             "answerLabel": "playing"
  //         },{
  //             "value": 0,
  //             "answerLabel": "play"
  //         },{
  //             "value": 0,
  //             "answerLabel": "played"
  //         }
  //     ]
  // },
];
