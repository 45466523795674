import React from 'react'
import { Card, Space, Row, Col, Typography, Image, Grid } from 'antd';
import Visa1 from '../../assets/img/document/visa-1.png';
import Visa2 from '../../assets/img/document/visa-2.png';
import Visa3 from '../../assets/img/document/visa-3.png';
import { withTranslation } from 'react-i18next';

const { Text } = Typography;
const { useBreakpoint } = Grid;

function GetBreakPoint() {
    const screens = useBreakpoint()
    return screens;
}

export function InfoPhotoVisa(props) {
    const { xs } = GetBreakPoint()
    const { t } = props
    return (
        <Card className='border-radius-6' bodyStyle={{ padding: xs ? 8 : 32 }}>
            <Row>
                <Col className='mb-16' span={24}>
                    <Text className='light-color'>{t('profile.document.info.visa.title.normal')}<Text className='dark-color' strong>{t('profile.document.info.visa.title.bold')}</Text></Text>
                </Col>
                <Col span={24}>
                    <Row gutter={[0,16]}>
                        <Col className='text-align-center' xs={24} md={8}>
                            <Space direction="vertical">
                                <Text className='dark-color' strong>{t('profile.document.info.visa.men.title')}</Text>
                                <Image src={Visa1} width="100%" height={200} preview={false} style={{ objectFit: 'contain' }} />
                                <Space className='text-align-left' direction='vertical' size={0}>
                                    <Text className="fs-12" type="secondary">• {t('profile.document.info.visa.men.first')}</Text>
                                    <Text className="fs-12" type="secondary">• {t('profile.document.info.visa.men.second')}</Text>
                                    <Text className="fs-12" type="secondary">• {t('profile.document.info.visa.men.third')}</Text>
                                </Space>
                            </Space>
                        </Col>
                        <Col className='text-align-center' xs={24} md={8}>
                            <Space direction="vertical">
                                <Text className='dark-color' strong>{t('profile.document.info.visa.hijab.title')}</Text>
                                <Image src={Visa2} width="100%" height={200} preview={false} style={{ objectFit: 'contain' }} />
                                <Space className='text-align-left' direction='vertical' size={0}>
                                    <Text className="fs-12" type="secondary">• {t('profile.document.info.visa.hijab.first')}</Text>
                                    <Text className="fs-12" type="secondary">• {t('profile.document.info.visa.hijab.second')}</Text>
                                    <Text className="fs-12" type="secondary">• {t('profile.document.info.visa.hijab.third.normal1')}<Text className='dark-color' strong>{t('profile.document.info.visa.hijab.third.bold')}</Text>{t('profile.document.info.visa.hijab.third.normal2')}</Text>
                                </Space>
                            </Space>
                        </Col>
                        <Col className='text-align-center' xs={24} md={8}>
                            <Space direction="vertical">
                                <Text className='dark-color' strong>{t('profile.document.info.visa.women.title')}</Text>
                                <Image src={Visa3} width="100%" height={200} preview={false} style={{ objectFit: 'contain' }} />
                                <Space className='text-align-left' direction='vertical' size={0}>
                                    <Text className="fs-12" type="secondary">• {t('profile.document.info.visa.men.first')}</Text>
                                    <Text className="fs-12" type="secondary">• {t('profile.document.info.visa.men.second')}</Text>
                                    <Text className="fs-12" type="secondary">• {t('profile.document.info.visa.men.third')}</Text>
                                </Space>
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}

export default withTranslation()(InfoPhotoVisa)
