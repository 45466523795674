import { API } from '../../../../config'
import { errorHandler } from '../../auth/errorAction'

export const unmountIndexJobDestination = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_JOB_DESTINATION'})
}

export const unmountAllJobDestination = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_ALL_JOB_DESTINATION'})
}

export const indexJobDestination = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_JOB_DESTINATION' })
  return API.GET('/job-destination/getDestination').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_JOB_DESTINATION_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_JOB_DESTINATION_FAILED' }), 
    ))
  })
}

export const listJobDestination = (params) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_JOB_DESTINATION' })
  return API.GET('/job-destination/list/sort', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_JOB_DESTINATION_SUCCESS', 
      payload: {
        data: response.data,
        meta: response.meta
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_JOB_DESTINATION_FAILED' }), 
    ))
  })
}

export const allJobDestination = (params) => async (dispatch) => {
  // await dispatch({ type: 'LOAD_DATA_MASTER_ALL_JOB_DESTINATION' })
  return API.GET('/job-destination/list/sort', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_ALL_JOB_DESTINATION_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_ALL_JOB_DESTINATION_FAILED' }), 
    ))
  })
}

export const jobDestinationAll = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_JOB_DESTINATION' })
  return API.GET('/job-destination/getDestination/list').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_JOB_DESTINATION_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_JOB_DESTINATION_FAILED' }), 
    ))
  })
}

export const jobDestinationDomestic = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_JOB_DESTINATION' })
  return API.GET('/job-destination/getListDomestic').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_JOB_DESTINATION_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_JOB_DESTINATION_FAILED' }), 
    ))
  })
}

export const jobDestinationInternational = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_JOB_DESTINATION' })
  return API.GET('/job-destination/getListInternational').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_JOB_DESTINATION_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_JOB_DESTINATION_FAILED' }), 
    ))
  })
}