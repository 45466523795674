import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Row, Col, Typography, Space, Form, Input, DatePicker, Select, Skeleton } from 'antd';
import { indexCountry, unmountIndexCountry } from '../../../../../redux/actions/master/country/countryAction';
import { listProvinceByCountry, unmountlistProvinceByCountry } from '../../../../../redux/actions/master/province/provinceAction';
import { listCityByProvince, unmountlistCityByProvince } from '../../../../../redux/actions/master/city/cityAction';
import { subDistrictByCity, unmountSubDistrictByCity } from '../../../../../redux/actions/master/subDistrict/subDistrictAction';
import { villageBySubDistrict, unmountVillageBySubDistrict } from '../../../../../redux/actions/master/village/villageAction';
const { Text } = Typography;
const { Option } = Select;

export class index extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      selectedCountry: null
    }
  }
  async componentDidMount() {
    const { actionGetCountry, actionGetProvince, actionGetCity, actionGetSubDistrict, actionGetVillage, getPersonalInfo: { data } } = this.props

    await this.setState({
      selectedCountry: data?.countryId
    })

    if(data?.subDistrictId){
      return (actionGetCountry(), actionGetProvince(data?.countryId), actionGetCity(data?.provinceId), actionGetSubDistrict(data?.cityId), actionGetVillage(data?.subDistrictId))
    }else if(data?.cityId){
      return (actionGetCountry(), actionGetProvince(data?.countryId), actionGetCity(data?.provinceId), actionGetSubDistrict(data?.cityId))
    }else if(data?.provinceId){
      return (actionGetCountry(), actionGetProvince(data?.countryId), actionGetCity(data?.provinceId))
    }else if(data?.countryId){
      return (actionGetCountry(), actionGetProvince(data?.countryId))
    }

    return actionGetCountry()
  }
  render() {
    const { selectedCountry } = this.state
    const { 
      getCountry, 
      getProvince, 
      getCity, 
      getSubDistrict, 
      getVillage, 
      getPersonalInfo,
      onChangeDate,
      handleNationality,
      handleCountry,
      handleProvince,
      handleCity,
      handleSubDistrict,
      handleVillage,
      valuesPersonal,
      t
    } = this.props
    
    const _handleCountry = (value, option) => {
      const optionSelected = Number(option.key)
      this.setState({
        selectedCountry: optionSelected,
      })
      const { actionGetProvince } = this.props
      return actionGetProvince(option.key)
    }

    const _handleProvince = (value, option) => {
      const { actionGetCity } = this.props
      return actionGetCity(option.key)
    }

    const _handleCity = (value, option) => {
      const { actionGetSubDistrict } = this.props
      return actionGetSubDistrict(option.key)
    }

    const _handleSubDistrict = (value, option) => {
      const { actionGetVillage } = this.props
      return actionGetVillage(option.key)
    }

    if(getCountry.loading || getPersonalInfo.loading){
      return <Skeleton active />
    }
    
    return (
      <React.Fragment>
        <Row className="mb-min-8" gutter={8}>
          <Col span={12}>
            <Space size={4}>
              <Text className="fs-12" type="danger">*</Text>
              <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.date')}</Text>
            </Space>
            <Form.Item 
              name="birthdate"
              rules={[
                { required: true, message: t('jobDetail.personalInfo.requiredDate') },
              ]}
              initialValue={
                valuesPersonal && valuesPersonal?.birthdate ?
                  moment(valuesPersonal.birthdate, 'DD/MM/YYYY')
                : getPersonalInfo.data.birthdate ?
                  moment(getPersonalInfo.data.birthdate, 'DD/MM/YYYY')
                :
                  ""
              }
            >
              <DatePicker inputReadOnly className="width-100" size="small" format="DD/MM/YYYY" onChange={onChangeDate} defaultPickerValue={getPersonalInfo.data.birthdate ? moment(getPersonalInfo.data.birthdate) : moment('2000-01-01')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Space size={4}>
              <Text className="fs-12" type="danger">*</Text>
              <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.gender')}</Text>
            </Space>
            <Form.Item 
              name="gender"
              rules={[
                { required: true, message:  t('jobDetail.personalInfo.requiredGender') },
              ]}
              initialValue={
                valuesPersonal && valuesPersonal.gender ?
                  valuesPersonal.gender
                : getPersonalInfo.data.gender ?
                  getPersonalInfo.data.gender
                :
                  ""
              }
            >
              <Select 
                className="width-100 border-radius-6"
                size="small" 
              >
                <Option key="1" value="MALE">{t('jobDetail.personalInfo.male')}</Option>
                <Option key="2" value="FEMALE">{t('jobDetail.personalInfo.female')}</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-min-8" gutter={8}>
          <Col span={12}>
            <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.place')}</Text>
            <Form.Item 
              name="placeOfBirth"
              validateFirst
              rules={[
                { pattern: /^[a-zA-Z ]*$/, message: t('jobDetail.personalInfo.requiredPlace') },
                { max: 50, message: t('jobDetail.personalInfo.maxPlace') },
              ]}
              initialValue={
                valuesPersonal && valuesPersonal.placeOfBirth ?
                  valuesPersonal.placeOfBirth
                : getPersonalInfo.data.placeOfBirth ?
                  getPersonalInfo.data.placeOfBirth
                :
                  ""
              }
            >
              <Input size="small" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.nationality')}</Text>
            <Form.Item 
              name="nationality"
              initialValue={
                valuesPersonal && valuesPersonal.nationality ?
                  valuesPersonal.nationality
                : getPersonalInfo.data.nationality ?
                  getPersonalInfo.data.nationality
                :
                  'Indonesian'
              }
            >
              <Select 
                className="width-100" 
                size="small" 
                showSearch
                onChange={handleNationality}
              >
                {
                  getCountry?.data?.map(item => 
                    <Option key={item.id} value={item.nationality}>{item.nationality}</Option>
                  )
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
          
        <Row className="mb-min-8" gutter={8}>
          <Col span={12}>
            <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.maritalStatus')}</Text>
            <Form.Item 
              name="marital"
              initialValue={
                valuesPersonal && valuesPersonal.marital ?
                  valuesPersonal.marital
                : getPersonalInfo.data.marital ?
                  getPersonalInfo.data.marital
                :
                  ""
              }
            >
              <Select 
                className="width-100 border-radius-6"
                size="small" 
              >
                <Option key="1" value="SINGLE">{t('jobDetail.personalInfo.single')}</Option>
                <Option key="2" value="MARRIED">{t('jobDetail.personalInfo.married')}</Option>
                <Option key="3" value="DIVORCE">{t('jobDetail.personalInfo.divorce')}</Option>
                <Option key="4" value="WIDOWED">{t('jobDetail.personalInfo.widowed')}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.bloodType')}</Text>
            <Form.Item 
              name="bloodType"
              initialValue={
                valuesPersonal && valuesPersonal.bloodType ?
                  valuesPersonal.bloodType
                : getPersonalInfo.data.bloodType ?
                  getPersonalInfo.data.bloodType
                :
                  ""
              }
            >
              <Select 
                className="width-100 border-radius-6"
                size="small" 
              >
                <Option key="1" value="A">A</Option>
                <Option key="2" value="AB">AB</Option>
                <Option key="3" value="B">B</Option>
                <Option key="4" value="O">O</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-min-8" gutter={8}>
          <Col span={12}>
            <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.height')}</Text>
            <Form.Item 
              name="height"
              validateFirst
              rules={[
                { pattern: /^[0-9]*$/, message: '' },
                { min: 2, message: t('jobDetail.personalInfo.minHeight') },
                { max: 3, message: t('jobDetail.personalInfo.maxHeight') },
              ]}
              initialValue={
                valuesPersonal && valuesPersonal.height ?
                  valuesPersonal.height
                : getPersonalInfo.data.height ?
                  getPersonalInfo.data.height.toString()
                :
                  ""
              }
            >
              <Input size="small" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.weight')}</Text>
            <Form.Item 
              name="weight"
              validateFirst
              rules={[
                { pattern: /^[0-9]*$/, message: t('jobDetail.personalInfo.requiredWeight') },
                { min: 2, message: t('jobDetail.personalInfo.minWeight') },
                { max: 3, message: t('jobDetail.personalInfo.maxHeight') },
              ]}
              initialValue={
                valuesPersonal && valuesPersonal.weight ?
                  valuesPersonal.weight
                : getPersonalInfo.data.weight ?
                  getPersonalInfo.data.weight.toString()
                :
                  ""
              }
            >
              <Input size="small" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-min-8" gutter={8}>
          <Col span={12}>
            <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.ktp')}</Text>
            <Form.Item 
              name="identificationId"
              validateFirst
              rules={[
                { pattern: /^[0-9]*$/, message: t('jobDetail.personalInfo.requiredWeight') },
                { len: 16, message: t('jobDetail.personalInfo.maxKtp') },
              ]}
              initialValue={
                valuesPersonal && valuesPersonal.identificationId ?
                  valuesPersonal.identificationId
                : getPersonalInfo.data.identificationId ?
                  getPersonalInfo.data.identificationId
                :
                  ""
              }
            >
              <Input size="small" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.religion')}</Text>
            <Form.Item 
              name="religion"
              initialValue={
                valuesPersonal && valuesPersonal.religion ?
                  valuesPersonal.religion
                : getPersonalInfo.data.religion ?
                  getPersonalInfo.data.religion
                :
                  ""
              }
            >
              <Select 
                className="width-100 border-radius-6"
                size="small" 
              >
                <Option key="1" value="ISLAM">Islam</Option>
                <Option key="2" value="CATHOLIC">Catholic</Option>
                <Option key="3" value="PROTESTAN">Protestan</Option>
                <Option key="4" value="BUDDHA">Buddha</Option>
                <Option key="5" value="HINDU">Hindu</Option>
                <Option key="6" value="OTHERS">Others</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.ktpAddress')}</Text>
          </Col>
        </Row>
        <Row className="mb-min-8" gutter={8}>
          <Col span={12}>
            <Form.Item 
              name="country"
              initialValue={
                valuesPersonal && valuesPersonal.country ?
                  valuesPersonal.country
                : getPersonalInfo.data.countryName ?
                  getPersonalInfo.data.countryName
                :
                  null
              }
            >
              <Select 
                className="width-100" 
                size="small" 
                showSearch
                onChange={(value, option) => {handleCountry(value, option); _handleCountry(value, option)}}
                placeholder={t('jobDetail.personalInfo.country')}
              >
                {
                  getCountry?.data?.map(item => 
                    <Option key={item.id} value={item.name}>{item.name}</Option>
                    )
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              name="province"
              initialValue={
                valuesPersonal && valuesPersonal.provinve ?
                  valuesPersonal.provinve
                : getPersonalInfo.data.provinceName ?
                  getPersonalInfo.data.provinceName
                :
                  null
              }
            >
              <Select 
                className="width-100" 
                size="small" 
                showSearch
                onChange={(value, option) => {handleProvince(value, option); _handleProvince(value, option)}}
                placeholder={t('jobDetail.personalInfo.province')}
              >
                {
                  getProvince?.data?.map(item => 
                    <Option key={item.id} value={item.name}>{item.name}</Option>
                    )
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-min-8" gutter={8}>
          <Col span={12}>
            <Form.Item 
              name="city"
              initialValue={
                valuesPersonal && valuesPersonal.city ?
                  valuesPersonal.city
                : getPersonalInfo.data.cityName ?
                  getPersonalInfo.data.cityName
                :
                  null
              }
            >
              <Select 
                className="width-100" 
                size="small" 
                showSearch
                onChange={(value, option) => {handleCity(value, option); _handleCity(value, option)}}
                placeholder={t('jobDetail.personalInfo.city')}
              >
                {
                  getCity?.data?.map(item => 
                    <Option key={item.id} value={item.name}>{item.name}</Option>
                    )
                }
              </Select>
            </Form.Item>
          </Col>
          {
            selectedCountry === 99 ?
              <Col span={12}>
                <Form.Item 
                  name="subDistrict"
                  initialValue={
                    valuesPersonal && valuesPersonal.subDistrict ?
                      valuesPersonal.subDistrict
                    : getPersonalInfo.data.subDistrictName ?
                      getPersonalInfo.data.subDistrictName
                    :
                      null
                  }
                >
                  <Select 
                    className="width-100" 
                    size="small" 
                    showSearch
                    onChange={(value, option) => {handleSubDistrict(value, option); _handleSubDistrict(value, option)}}
                    placeholder={t('jobDetail.personalInfo.subdistrict')}
                  >
                    {
                      getSubDistrict?.data?.map(item => 
                        <Option key={item.id} value={item.name}>{item.name}</Option>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
            :
              <Col span={12}>
                <Form.Item 
                  name="zipCode"
                  validateFirst
                  rules={[
                    { pattern: /^[0-9]*$/, message: t('jobDetail.personalInfo.minWeight') },
                    { max: 10, message: t('jobDetail.personalInfo.maxZipcode') },
                  ]}
                  initialValue={
                    valuesPersonal && valuesPersonal.zipCode ?
                      valuesPersonal.zipCode
                    : getPersonalInfo.data.zipCode ?
                      getPersonalInfo.data.zipCode
                    :
                      null
                  }
                >
                  <Input size="small" placeholder={t('jobDetail.personalInfo.zipcode')} />
                </Form.Item>
              </Col>
          }
        </Row>
        {
          selectedCountry === 99 ?
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Form.Item 
                  name="village"
                  initialValue={
                    valuesPersonal && valuesPersonal.village ?
                      valuesPersonal.village
                    : getPersonalInfo.data.villageName ?
                      getPersonalInfo.data.villageName
                    :
                      null
                  }
                >
                  <Select 
                    className="width-100" 
                    size="small" 
                    showSearch
                    onChange={handleVillage}
                    placeholder={t('jobDetail.personalInfo.village')}
                  >
                    {
                      getVillage?.data?.map(item => 
                        <Option key={item.id} value={item.name}>{item.name}</Option>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item 
                  name="zipCode"
                  validateFirst
                  rules={[
                    { pattern: /^[0-9]*$/, message: t('jobDetail.personalInfo.minWeight') },
                    { max: 10, message: t('jobDetail.personalInfo.maxZipcode') },
                  ]}
                  initialValue={
                    valuesPersonal && valuesPersonal.zipCode ?
                      valuesPersonal.zipCode
                    : getPersonalInfo.data.zipCode ?
                      getPersonalInfo.data.zipCode
                    :
                      null
                  }
                >
                  <Input size="small" placeholder={t('jobDetail.personalInfo.zipcode')} />
                </Form.Item>
              </Col>
            </Row>
          :
            null
        }
        <Row className="mb-min-8">
          <Col span={24}>
            <Form.Item 
              name="address"
              validateFirst
              rules={[
                { max: 75, message: t('jobDetail.personalInfo.maxAddress') },
              ]}
              initialValue={
                valuesPersonal && valuesPersonal.address ?
                  valuesPersonal.address
                : getPersonalInfo.data.address ?
                  getPersonalInfo.data.address
                :
                  null
              }
            >
              <Input size="small" placeholder={t('jobDetail.personalInfo.address')} />
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  // componentWillUnmount(){
  //   const { unmountIndexCountry, unmountlistProvinceByCountry, unmountlistCityByProvince, unmountSubDistrictByCity, unmountVillageBySubDistrict } = this.props
  //   return (unmountIndexCountry(), unmountlistProvinceByCountry(), unmountlistCityByProvince(), unmountSubDistrictByCity(), unmountVillageBySubDistrict())
  // }
}

const mapStateToProps = (state) => ({
  getCountry: state.countryReducer,
  getProvince: state.provinceReducer,
  getCity: state.cityReducer,
  getSubDistrict: state.subDistrictReducer,
  getVillage: state.villageReducer
})

const mapDispatchToProps = {
  actionGetCountry: indexCountry,
  unmountIndexCountry: unmountIndexCountry,
  actionGetProvince: listProvinceByCountry,
  unmountlistProvinceByCountry: unmountlistProvinceByCountry,
  actionGetCity: listCityByProvince,
  unmountlistCityByProvince: unmountlistCityByProvince,
  actionGetSubDistrict: subDistrictByCity,
  unmountSubDistrictByCity: unmountSubDistrictByCity,
  actionGetVillage: villageBySubDistrict,
  unmountVillageBySubDistrict: unmountVillageBySubDistrict,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(index))