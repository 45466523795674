import { API } from '../../../config'
import { errorHandler } from '../auth/errorAction'

export const unmountListXpert = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_XPERT'})
}

export const unmountDetailXpert = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_XPERT'})
}

export const unmountListTicketByXpert = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_TICKET'})
}

export const unmountDetailTicket = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_TICKET'})
}

export const unmountDetailOrder = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_ORDER'})
}

export const unmountListOrder = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_ORDER'})
}

export const listXpert = (params, successCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_XPERT' })
  
  return API.GET('/xpert/list', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_XPERT_SUCCESS', 
      payload: {
        data: response.data,
        meta: response.meta
    }})
    return successCB(params)
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_XPERT_FAILED' }), 
    ))
  })
}

export const detailXpert = (categorySlug, slug) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DETAIL_XPERT' })
  return API.GET(`/xpert/detail/${categorySlug}/${slug}`).then((response) => {
    dispatch({
      type: 'LOAD_DETAIL_XPERT_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DETAIL_XPERT_FAILED' }), 
    ))
  })
}

export const listTicketByXpert = (id) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_TICKET' })
  const params = {
    'id': id
  }
  return API.GET('/xpert/getListTicket', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_TICKET_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_TICKET_FAILED' }), 
    ))
  })
}

export const detailTicket = (id, successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DETAIL_TICKET' })
  const params = {
    'id': id
  }
  return API.GET('/xpert/getTicketDetail', params).then((response) => {
    dispatch({
      type: 'LOAD_DETAIL_TICKET_SUCCESS', 
      payload: {
        data: response.data
    }})
    return successCB && successCB(response)
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DETAIL_TICKET_FAILED' }), 
    ))
  })
}

export const buyTicket = (value, successCB, failedCB) => async dispatch => {
  API.POST('/xpert/booking', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const uploadPayment = (value, successCB, failedCB) => async dispatch => {
  API.POST_FORM_DATA('/xpert/uploadPayment', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const cancelPayment = (value, successCB, failedCB) => async dispatch => {
  API.POST('/xpert/cancel', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const listOrder = (params, successCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_ORDER' })
  
  return API.GET('/xpert/myOrder', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_ORDER_SUCCESS', 
      payload: {
        data: response.data,
        meta: response.meta
    }})
    return successCB(params)
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_ORDER_FAILED' }), 
    ))
  })
}

export const detailOrder = (id) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DETAIL_ORDER' })
  const params = {
    'id': id
  }
  return API.GET('/xpert/getDetailOrder', params).then((response) => {
    dispatch({
      type: 'LOAD_DETAIL_ORDER_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DETAIL_ORDER_FAILED' }), 
    ))
  })}