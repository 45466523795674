import React, { Component } from 'react'
import { Card , Typography, Row, Col, Space, Breadcrumb} from 'antd';
import { withTranslation } from 'react-i18next';
import CButton from '../../../../../../../components/Button';

const { Text } = Typography;

export class S2InfoPart2 extends Component {
  render() {
    const { handleNext, t } = this.props

    return (
      <React.Fragment>
        <Row className='mt-16'>
          <Col lg={{ span: 20, offset: 2 }} xxl={{ span: 18, offset: 3 }}>
            <Breadcrumb className="mb-16" >
              <Breadcrumb.Item>Career Pathway</Breadcrumb.Item>
              <Breadcrumb.Item>Area Karir</Breadcrumb.Item>
            </Breadcrumb>
            <Card className="card-body-info-assessment mt-16 mb-16 border-radius-8"> 
              <Space direction='vertical' size={16}>
                <Space className="text-align-center width-100" direction="vertical" size={0}>
                  <Text className='dark-color' style={{ fontSize: 16, fontWeight: '600' }} strong>Section 2 - Part 2</Text>
                  {/* <Text style={{ fontSize: 20, fontWeight: 'normal', color: "#32AB6D" }} strong>{t('pathway.written')}</Text> */}
                  <Text style={{ fontSize: 20, fontWeight: 'normal', color: "#32AB6D" }} strong>Written Expression</Text>
                </Space>
                <Text className='fs-15' style={{ fontStyle: 'italic', color: '#616466' }}>
                  In questions 36 - 45, each sentece has four underlined words or phrase. Choose the one <Text className='fs-15 text-underline' strong style={{ fontStyle: 'italic', color: '#616466' }}>underlined</Text> word or phrase that must be changed for the sentece to be correct. In other words you need to find a mistake.
                </Text>
                <Text className='fs-15' style={{ fontStyle: 'italic', color: '#616466' }}>
                  "Pada pertanyaan 36-45, setiap kalimat memiliki empat kata atau frasa yang <Text className='fs-15 text-underline' style={{ fontStyle: 'italic', color: '#616466' }}>digarisbawahi</Text>. Pilih salah satu kata atau frasa yang digarisbawahi tersebut yang harus dirubah agar membuat kalimat tersebut menjadi tepat. Dengan kata lain, Anda diharuskan untuk menemukan sebuah kesalahan."
                </Text>
                <Text className='fs-15' style={{ fontStyle: 'italic', color: '#616466' }}>Example: Guppies are sometimes <Text className='fs-15 text-underline' strong style={{ fontStyle: 'italic', color: '#616466' }}>call</Text> rainbow <Text className='fs-15 text-underline' style={{ fontStyle: 'italic', color: '#616466' }}>fish due</Text> to the <Text className='fs-15 text-underline' style={{ fontStyle: 'italic', color: '#616466' }}>bright</Text> colors of the males.</Text>
                <Text className='fs-15' style={{ fontStyle: 'italic', color: '#616466'}}>The sentece should read: "Guppies are sometimes <Text className='fs-15 text-underline' strong style={{ fontStyle: 'italic', color: '#616466' }}>called</Text> rainbow fish due to the bright colors of the males. "Therefore, you should choose <Text className='fs-15 text-underline' strong style={{ fontStyle: 'italic', color: '#616466' }}>call</Text>.</Text>
                <Row className='mt-32'>
                  <Col xs={{ span: 24, offset: 9 }} lg={{ span: 20, offset: 11 }} xxl={{ span: 18, offset: 10 }}>
                    <CButton type="primary" size="large" block action={() => handleNext(2,7)} title={t('pathway.start')}/>
                  </Col>
                </Row>
              </Space>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}


export default withTranslation()(S2InfoPart2)
