export const validatePost = (obj) => {
  for(var key in obj) {
    var value = obj[key];
    if(!value){
      delete obj[key]
    }
    if(typeof value === "object"){
      for(var _key in value){
        var _value = value[_key]
        if(!_value){
          delete obj[key][_key]
        }
      }
    }
  }
  return obj;
}