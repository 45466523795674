import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Cookie from 'js-cookie';
import { withTranslation } from 'react-i18next';
import { Loading } from '../../../../../components';
import { Row, Col, Card, Space, Typography, Button, Input, Layout, Avatar, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
// import Barcode from '../../../../../assets/img/candidate/barcode.png';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { listTicketByXpert, unmountListTicketByXpert } from '../../../../../redux/actions/xpert/xpertAction';
import './../ChooseTicket.css';
import moment from 'moment';
import 'moment/locale/id';
import { eventType } from '../../../../../utils/constant/eventType';
import { courseLevel } from '../../../../../utils/constant/courseLevel';
import { trainingType } from '../../../../../utils/constant/trainingType';
import { serviceType } from '../../../../../utils/constant/serviceType';
import CButton from '../../../../../components/Button';

const { Text } = Typography;
const { Footer } = Layout;

export class CandidateXpertChooseTicket extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      getXpertDetail: null,
      qty: 0,
      selectedTicket: 0
    }
  }

  componentDidMount() {
    const { actionGetTicket, location: { state: { getXpertDetail } } } = this.props

    localStorage.removeItem("end_date")
    localStorage.removeItem('xpertTicketId')
    
    this.setState({ getXpertDetail })
    window.scrollTo(0, 0)
    return actionGetTicket(getXpertDetail.id || this.state.getXpertDetail.id)
  }

  handlePlusButton = (id) => {
    const { qty } = this.state
    this.setState({ qty: qty + 1, selectedTicket: id })
  }

  handleMinusButton = () => {
    const { qty } = this.state
    this.setState({ qty: qty - 1, selectedTicket: 0 })
  }

  handleNextButton = () => {
    const { t } = this.props
    const { getXpertDetail, selectedTicket } = this.state
    if(selectedTicket){
      this.props.history.push({
        pathname: `/candidate/service-plus/payment-method`,
        state: {
          getXpertDetail: getXpertDetail,
          xpertTicketId: selectedTicket
        }
      })
    }else{
      message.error(t('xpert.chooseTicket.msgError'));
    }
  }

  render() {
    const { getXpertDetail, qty, selectedTicket } = this.state
    const { getTicketByXpert, t } = this.props
    
    if(getTicketByXpert.loading){
      return <Loading />
    }
    
    const ticketName = getTicketByXpert.data.filter(item => item.id === selectedTicket).pop()?.name
    const ticketPrice = getTicketByXpert.data.filter(item => item.id === selectedTicket).pop()?.discountedPrice || getTicketByXpert.data.filter(item => item.id === selectedTicket).pop()?.price

    return (
      <React.Fragment>
        <Row className="mt-16">
          <Col span={22} offset={1}>
            <Card className="border-radius-8 mb-16">
              <Link to={`/candidate/service-plus/detail/${getXpertDetail.categorySlug}/${getXpertDetail.slug}`}>
                <Space className="cursor-pointer mb-16">
                  <ArrowLeftOutlined className="info-color fs-18" />
                  <Text className="info-color fs-14">{t('xpert.back')}</Text>
                </Space>
              </Link>
              <Card 
                className="border-radius-8 box-shadow-light"
                title={
                  <Space className="width-100 text-align-center" direction="vertical" size={0}>
                    <Text className="white-color fs-16">{t('xpert.categoryTicket')}</Text>
                    <Text className="white-color fs-18">{getXpertDetail.title}</Text>
                  </Space>
                }
                headStyle={{ borderRadius: '8px 8px 0 0', backgroundColor: '#32AB6D' }}
              >
                
                <Row>
                  <Col className='mb-16' span={24}>
                    <Space className="text-align-justify" direction="vertical" size={0}>
                      <Text className="dark-color fs-16">{t('xpert.organized')}</Text>
                      <Space>
                        <Avatar shape="square" size={40} src={getXpertDetail.logoUrl} />
                        <Text className="dark-color fs-15" strong>{getXpertDetail.name}</Text>
                      </Space>
                    </Space>
                  </Col>
                  <Col className='mb-16' span={24}>
                    <Space className="text-align-justify" direction="vertical" size={0}>
                      <Text className="dark-color fs-16">{t('xpert.date')}</Text>
                      <Space direction="vertical" size={0}>
                        <Text className="light-color fs-15">{getXpertDetail.xpertDate}</Text>
                        {/* <Text className="light-color fs-12">{getXpertDetail.xpertTime}</Text> */}
                      </Space>
                    </Space>
                  </Col>
                  <Col span={24}>
                    {
                      getXpertDetail.xpertType === 'EVENT' ?
                        <Space className="text-align-justify" direction="vertical" size={0}>
                          <Text className="dark-color fs-16">{t('xpert.type')}</Text>
                          <Text className="dark-color fs-15">{getXpertDetail.eventType ? eventType.find(item => item.value === getXpertDetail.eventType)?.name : 'N/A'}</Text>
                        </Space>
                      : getXpertDetail.xpertType === 'COURSE' ?
                        <Space className="text-align-justify" direction="vertical" size={0}>
                          <Text className="dark-color fs-16">{t('xpert.level')}</Text>
                          <Text className="dark-color fs-15">{getXpertDetail.courseLevel ? courseLevel.find(item => item.value === getXpertDetail.courseLevel)?.name : 'N/A'}</Text>
                        </Space>
                      : getXpertDetail.xpertType === 'TRAINING' ?
                        <Space className="text-align-justify" direction="vertical" size={0}>
                          <Text className="dark-color fs-16">{t('xpert.level')}</Text>
                          <Text className="dark-color fs-15">{getXpertDetail.trainingType ? trainingType.find(item => item.value === getXpertDetail.trainingType)?.name : 'N/A'}</Text>
                        </Space>
                      : getXpertDetail.xpertType === 'SERVICE' ?
                        <Space className="text-align-justify" direction="vertical" size={0}>
                          <Text className="dark-color fs-16">{t('xpert.chooseTicket.serviceType')}</Text>
                          <Text className="light-color fs-15">
                            {
                              Cookie.get('i18next') === 'en' ?
                                serviceType.find(res => res.value === getXpertDetail.serviceType)?.nameEn
                              : 
                                serviceType.find(res => res.value === getXpertDetail.serviceType)?.nameIn
                            }
                          </Text>
                        </Space>
                      : null
                    }
                  </Col>
                </Row>
              </Card>

              <Row className="text-align-center mt-16 mb-16">
                <Col span={24}>
                  {/* <Text className="danger-color text-align-center fs-14"><ExclamationCircleFilled />{t('xpert.title3')}</Text> */}
                </Col>
              </Row>

              {
                getTicketByXpert.data.map((item, i) => 
                  <Row className="mb-32" key={i} justify="space-around" align="middle">
                    <Col span={24} style={{ opacity: getXpertDetail.isAvailable ? 1 : 0.5 }}>
                      {/* <div className="outer">
                        <div className="inner">
                          <i className="top right"></i> */}
                          <div className="content mb-16">
                            <div style={{ backgroundColor: '#4273b9', paddingTop: 16, paddingBottom: 16, paddingLeft: 16 }}>
                              <Text className="white-color title-ticket">{item.name}</Text>
                            </div>
                            <div style={{ backgroundColor: '#f5f5f5' }}>
                              <Row justify="space-around" align="middle" style={{ height: 140 }}>
                                <Col span={24}>
                                  <Space className="width-100" direction="vertical" size={16} style={{ padding: 20 }}>
                                    {
                                      item.note ?
                                        <Text className="dark-color fs-14">{item.note}</Text>
                                      :
                                        null
                                    }
                                    <Row justify="space-around" align="middle">
                                      <Col span={24}>
                                        <Space direction="vertical" size={0}>
                                          <Text className="info-color">{`Berakhir tanggal ${moment(item.date, 'DDMMYYYY').format('LL')}`}</Text>
                                          {/* <Text className="info-color">{`Jam ${moment(item.time, 'HHmmss').format('LT')}`}</Text> */}
                                        </Space>
                                      </Col>
                                      <Col span={24}>
                                        <Space direction='vertical' size={0}>
                                          {
                                            item.quota && item.quota <= 10 ?
                                              <Text className="info-color fs-14">{item.quota} {t('xpert.remaining')}</Text>
                                            : null
                                          }
                                          {/* <Text className="dark-color fs-20 float-right font-weight-bold">{ item.price ? `IDR ${item.price.toLocaleString()}` : t('xpert.free') }</Text> */}
                                          {
                                            item.discountedPrice ?
                                              <>
                                                <Text type="secondary" strong delete>{`IDR ${item.price.toLocaleString()}`}</Text>
                                                <Text strong>{`IDR ${item.discountedPrice.toLocaleString()}`}</Text>
                                              </>
                                            : item.price ?
                                              <Text strong>{`IDR ${item.price.toLocaleString()}`}</Text>
                                            :
                                              <Text strong>FREE</Text>
                                          }
                                        </Space>
                                      </Col>
                                    </Row>
                                  </Space>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          {/* <i className="bottom right"></i>
                        </div>
                      </div> */}
                    </Col>
                    {/* <Col span={3} style={{ opacity: getXpertDetail.isAvailable ? 1 : 0.5 }}>
                      <div className="outer">
                        <div className="inner">
                          <i className="top"></i>
                          <div className="content" style={{ backgroundColor: '#f5f5f5', border: '4px #ffffff', borderLeftStyle: 'dotted' }}>
                            <Row className="text-align-center" justify="space-around" align="middle" style={{ height: 200 }}>
                              <Col span={24}>
                                <Image
                                  src={Barcode}
                                  preview={false}
                                  width={'45%'}
                                  style={{ paddingTop: 5 }}
                                />
                              </Col>
                            </Row>
                          </div>
                          <i className="bottom left"></i>
                        </div>
                      </div>
                    </Col> */}
                    <Col className="text-align-center" span={24}>
                      <Space className="text-align-center">
                        {
                          getXpertDetail.isAvailable ? 
                            <>
                              <Button shape="circle" onClick={() => this.handleMinusButton(item.id)} disabled={qty === 0 || selectedTicket !== item.id} icon={<MinusOutlined />} />
                              <Input className="text-align-center" style={{ width: '75%' }} value={selectedTicket === item.id ? qty : 0} readOnly />
                              <Button shape="circle" onClick={() => this.handlePlusButton(item.id)} disabled={qty === 1} icon={<PlusOutlined />} />
                            </>
                          :
                            <Text className="fs-18 text-align-center" type="danger">{t('xpert.saleEnded')}</Text>
                        }
                      </Space>
                    </Col>
                  </Row>
                )
              }
            </Card>

          </Col>
        </Row>

        <Footer style={{ backgroundColor: '#e3eaf5', position: 'fixed', zIndex: 1, width: '100%', height: 160, bottom: 0, left: 0, paddingLeft: 16, paddingRight: 16 }}>
          <Row justify="space-around" align="middle">
            <Col className='mb-16' span={24}>
              <Space direction="vertical" size={0}>
                <Text className="fs-16 dark-color font-weight-bold">{getXpertDetail.title}</Text>
                <Text className="fs-15 dark-color font-weight-normal">{selectedTicket ? ticketName : t('xpert.title4')}</Text>
              </Space>
            </Col>
            <Col span={20}>
              <Space direction="vertical" size={0}>
                <Text className="fs-15 dark-color font-weight-normal">Sub-Total:</Text>
                <Text className="fs-18 dark-color font-weight-bold">
                  { 
                    selectedTicket ? 
                      ticketPrice ? 
                        `IDR ${ticketPrice.toLocaleString()}` 
                      : 
                      t('xpert.free')
                    : 
                      '-' 
                  }
                </Text>
              </Space>
            </Col>
            <Col span={4}>
              <CButton className="box-shadow-light text-align-center border-radius-8 float-right" type="primary" size="large" action={this.handleNextButton} style={{ border: '#4273b9', background: '#4273b9' }} title={t('xpert.continue')}/>
            </Col>
          </Row>
        </Footer>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountListTicketByXpert } = this.props
    return unmountListTicketByXpert()
  }
}

const mapStateToProps = (state) => ({
  getTicketByXpert: state.ticketReducer
})

const mapDispatchToProps = {
  actionGetTicket: listTicketByXpert,
  unmountListTicketByXpert: unmountListTicketByXpert,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CandidateXpertChooseTicket))
