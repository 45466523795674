import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { withTranslation } from 'react-i18next';
import { Card, Typography, Modal, Form, Input, Space, Row, Col, Checkbox, DatePicker, Select, Timeline, Badge, Grid, Spin, message } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { listCity, unmountListCity } from '../../../../redux/actions/master/city/cityAction';
import { indexBuseinessSector, unmountIndexBuseinessSector } from '../../../../redux/actions/master/businessSector/businessSectorAction';
import { addWorkExperience, updateWorkExperience, deleteWorkExperience } from '../../../../redux/actions/profile/workExperienceAction';
import './WorkExperience.css';
import moment from 'moment';
import Cookie from "js-cookie";
import CButton from '../../../../components/Button';

const { useBreakpoint } = Grid;

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}
export const WorkExperienceForm = ({t, data, visible, submitLoading, deleteLoading, current, getCity, getBusinessSector, showModal, handleCancel, onSearchCity, onFocusCity, handleCity, onChangeStartDate, onChangeEndDate, handleChecked, formRef, onFinish, isDate, id, dataEdit, handleDelete}) => {
  const { md } = GetBreakPoint()
  
  const disabledDate = (current) => {
    let month = moment()
    return !current || current.isAfter(month);
  }

  return (
    <React.Fragment>
      <Card 
        className="border-radius-6 box-shadow" 
        title={t('profile.workExperience.title')} 
        bordered={false} 
        headStyle={{ color: '#32ab6d', fontSize: 17 }}
        bodyStyle={data.listWorkExperience.length > 0 ? {} : { textAlign: 'center', padding: 60 }}
        actions={[
          <Text className="primary-color" onClick={() => showModal(null)}>{t('profile.workExperience.buttonAdd')}</Text>
        ]}
      >
        {
          data.listWorkExperience.length > 0 ?
            <Timeline>
                {
                  data.listWorkExperience.map((item, i) => (
                    md ?
                      <Timeline.Item key={i} color="#0076de">
                        <Row gutter={8}>
                          <Col span={8}>
                            <Text className="text-year">
                              {`${item.startDate ? moment(item.startDate).format('MMM YYYY') : 'N/A'} - ${item.current ? 'Present' : item.endDate ? moment(item.endDate).format('MMM YYYY') : 'N/A'}`}
                            </Text>
                          </Col>
                          <Col className="pr-8" span={12}>
                            <Space direction="vertical" size={0}>
                              <Space>
                                <Text className="text-title">{item.title}</Text>
                                {
                                  item.employmentType ? 
                                    <React.Fragment>
                                      <Badge className="dot" color="#848484" />
                                      <Text className="text-type">
                                        {
                                          item.employmentType === 'FULL_TIME' ? 
                                            t('profile.workExperience.fullTime') 
                                          : item.employmentType === 'PART_TIME' ?
                                            t('profile.workExperience.partTime')
                                          : item.employmentType === 'FREELANCE' ?
                                            t('profile.workExperience.freelance')
                                          : item.employmentType === 'INTERNSHIP' ?
                                            t('profile.workExperience.internship')
                                          : item.employmentType === 'VOLUNTEER' ?
                                            t('profile.workExperience.volunteer')
                                          :
                                            null
                                        }
                                      </Text>
                                    </React.Fragment>
                                  :
                                    null
                                }
                              </Space>
                              <Text className="text-loc">{item.cityName ? `${item.company}, ${item.cityName}` : item.company}</Text>
                              <Text className="dark-color">{item.jobDescription}</Text>
                            </Space>
                          </Col>
                          <Col span={4}>
                            <Text className="primary-color float-right cursor-pointer" onClick={() => (showModal(item.id))}><EditFilled /> Edit</Text>
                          </Col>
                        </Row>
                      </Timeline.Item>
                    :
                      <Timeline.Item key={i} color="#0076de">
                        <Row>
                          <Col span={20}>
                            <Text className="text-year">
                              {`${item.startDate ? moment(item.startDate).format('MMM YYYY') : 'N/A'} - ${item.current ? 'Present' : item.endDate ? moment(item.endDate).format('MMM YYYY') : 'N/A'}`}
                            </Text>
                          </Col>
                          <Col span={4}>
                            <Text className="primary-color cursor-pointer" onClick={() => (showModal(item.id))}><EditFilled /> Edit</Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-8" span={24}>
                            <Space direction="vertical" size={0}>
                              <Space>
                                <Text className="text-title">{item.title}</Text>
                                {
                                  item.employmentType ? 
                                    <React.Fragment>
                                      <Badge className="dot" color="#848484" />
                                      <Text className="text-type">
                                        {
                                          item.employmentType === 'FULL_TIME' ? 
                                            t('profile.workExperience.fullTime') 
                                          : item.employmentType === 'PART_TIME' ?
                                            t('profile.workExperience.partTime')
                                          : item.employmentType === 'FREELANCE' ?
                                            t('profile.workExperience.freelance')
                                          : item.employmentType === 'INTERNSHIP' ?
                                            t('profile.workExperience.internship')
                                          : item.employmentType === 'VOLUNTEER' ?
                                            t('profile.workExperience.volunteer')
                                          :
                                            null
                                        }
                                      </Text>
                                    </React.Fragment>
                                  :
                                    null
                                }
                              </Space>
                              <Text className="text-loc">{item.cityName ? `${item.company}, ${item.cityName}` : item.company}</Text>
                              <Text className="dark-color">{item.jobDescription}</Text>
                            </Space>
                          </Col>
                        </Row>
                      </Timeline.Item>
                  ))
                }
            </Timeline>
          :
            <Text className="dark-color">{t('profile.workExperience.textCard')}</Text>
        }
      </Card>

      <Modal 
        title={t('profile.workExperience.title')} 
        visible={visible} 
        onCancel={handleCancel} 
        footer={false}
        bodyStyle={{ paddingBottom: 4 }}
        centered
        width={600}
        destroyOnClose
        maskClosable={false}
      >
        <Form 
          ref={formRef}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            title: id ? dataEdit?.title : null,
            businessSectorId: id ? dataEdit?.businessSectorId : null,
            department: id ? dataEdit?.department : null,
            companyEmail: id ? dataEdit?.companyEmail : null,
            website: id ? dataEdit?.website : null,
            company: id ? dataEdit?.company : null,
            employmentType: id ? dataEdit?.employmentType : null,
            city: id && dataEdit?.cityName  ? `${dataEdit?.cityName}, ${dataEdit?.countryName}` : null,
            startDate: id && dataEdit?.startDate ? moment(dataEdit?.startDate) : null,
            endDate:  id && dataEdit?.endDate ? moment(dataEdit?.endDate) : null,
            jobDescription: id ? dataEdit?.jobDescription : null
          }}
        >
          {
            md ?
              <React.Fragment>
                <Row className="mb-min-8" gutter={8}>
                  <Col className="width-100" span={12}>
                    <Space size={4}>
                      <Text className="fs-12" type="danger">*</Text>
                      <Text className="fs-12" type="secondary">{t('profile.workExperience.form.title')}</Text>
                    </Space>
                    <Form.Item 
                      name="title"
                      validateFirst
                      rules={[
                        { required: true,  message: t('required') },
                        { min: 5, message: t('min',{number:5}) },
                        { max: 50, message: t('max',{number:50}) },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col className="width-100" span={12}>
                    <Space size={4}>
                      <Text className="fs-12" type="danger">*</Text>
                      <Text className="fs-12" type="secondary">{t('profile.workExperience.form.company')}</Text>
                    </Space>
                    <Form.Item 
                      name="company"
                      validateFirst
                      rules={[
                        { required: true,  message: t('required') },
                        { min: 3, message: t('min',{number:3}) },
                        { max: 50, message: t('max',{number:50}) },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-min-8" gutter={8}>
                  <Col span={12}>
                    <Text className="fs-12" type="secondary">{t('profile.workExperience.form.employmentType')}</Text>
                    <Form.Item name="employmentType">
                      <Select 
                        className="width-100 border-radius-6"
                      >
                        <Option key="1" value="FULL_TIME">{t('profile.workExperience.fullTime')}</Option>
                        <Option key="2" value="PART_TIME">{t('profile.workExperience.partTime')}</Option>
                        <Option key="3" value="FREELANCE">{t('profile.workExperience.freelance')}</Option>
                        <Option key="4" value="INTERNSHIP">{t('profile.workExperience.internship')}</Option>
                        <Option key="5" value="VOLUNTEER">{t('profile.workExperience.volunteer')}</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Text className="fs-12" type="secondary">{t('profile.workExperience.form.location')}</Text>
                    <Form.Item name="city">
                      <Select 
                        className="width-100" 
                        showSearch
                        onFocus={onFocusCity}
                        onSearch={onSearchCity}
                        onChange={handleCity}
                        placeholder={t('profile.workExperience.form.placeholderLocation')}
                        notFoundContent={getCity.loading ? <Spin size="small" /> : null}
                      >
                        {
                          getCity?.data?.map(item => 
                            <Option key={item.id} value={`${item.name}, ${item.countryName}`}>{`${item.name}, ${item.countryName}`}</Option>
                            )
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-min-8" gutter={8}>
                  <Col span={12}>
                    <Text className="fs-12" type="secondary">{t('profile.workExperience.form.businessSector')}</Text>
                    <Form.Item name="businessSectorId">
                       <Select
                        loading={getBusinessSector?.loading}
                        notFoundContent={getBusinessSector.loading ? <Spin size="small"/> : null}
                      >
                        {
                          getBusinessSector?.data && getBusinessSector?.data?.map(item => (
                            <Select.Option className='work-exp' key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Text className="fs-12" type="secondary">{t('profile.workExperience.form.department')}</Text>
                    <Form.Item 
                      name="department"
                      validateFirst
                      rules={[
                        { min: 3, message: t('min',{number:3}) },
                        { max: 50, message: t('max',{number:50}) },
                      ]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-min-8" gutter={8}>
                  <Col span={12}>
                    <Text className="fs-12" type="secondary">{t('profile.workExperience.form.companysEmail')}</Text>
                    <Form.Item 
                      name="companyEmail"
                      validateFirst
                      rules={[
                        { max: 50, message: t('max',{number:50}) },
                        // { pattern:  /.+@(gmail)\.com$/, message: 'Preferred using Gmail' }
                        // { pattern:  /.+@(gmail)\.com$/, message: 'Invalid format' }
                      ]}
                      >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Text className="fs-12" type="secondary">{t('profile.workExperience.form.companysWebsite')}</Text>
                    <Form.Item 
                      name="website"
                      validateFirst
                      rules={[
                        { max: 50, message: t('max',{number:50}) },
                        // { pattern:  /.+@(gmail)\.com$/, message: 'Invalid format' }
                      ]}
                      >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col className="width-100" md={6} sm={12}>
                    <Space size={4}>
                      <Text className="fs-12" type="danger">*</Text>
                      <Text className="fs-12" type="secondary">{t('profile.workExperience.form.startDate')}</Text>
                    </Space>
                  </Col>
                  <Col className="width-100" md={6} sm={12}>
                    <Space size={4}>
                      {
                        !current ?
                          <Text className="fs-12" type="danger">*</Text>
                        :
                          null
                      }
                      <Text className="fs-12" type="secondary">{t('profile.workExperience.form.endDate')}</Text>
                    </Space>
                  </Col>
                </Row>
                <Row className="mb-min-8" gutter={8}>
                  <Col className="width-100" md={6} sm={12}>
                    <Form.Item 
                      name="startDate"
                      rules={[
                        { required: true,  message: t('required') }
                      ]}
                    >
                      <DatePicker inputReadOnly className="width-100" picker="month" placeholder="YYYY-MM" onChange={onChangeStartDate} disabledDate={disabledDate} />
                    </Form.Item>
                  </Col>
                  <Col className="width-100" md={6} sm={12}>
                      {
                        current ?
                          <Text className="fs-12" type="secondary">{t('profile.workExperience.form.present')}</Text>
                        :
                          <Form.Item 
                            name="endDate"
                            rules={[
                              { required: !current,  message: t('required') }
                            ]}
                          >
                            <DatePicker inputReadOnly className="width-100" picker="month" placeholder="YYYY-MM" onChange={onChangeEndDate} />
                          </Form.Item>
                      }
                  </Col>
                  <Col className="width-100" md={12}>
                    <Form.Item name="current">
                      <Checkbox className="fs-12 dark-color" onChange={handleChecked} checked={current}>{t('profile.workExperience.form.checkbox')}</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </React.Fragment>
            :
              <React.Fragment>
                <Row className="mb-min-8">
                  <Col className="width-100" span={24}>
                    <Space size={4}>
                      <Text className="fs-12" type="danger">*</Text>
                      <Text className="fs-12" type="secondary">{t('profile.workExperience.form.title')}</Text>
                    </Space>
                    <Form.Item 
                      name="title"
                      validateFirst
                      rules={[
                        { required: true,  message: t('required') },
                        { min: 5, message: t('min',{number:5}) },
                        { max: 50, message: t('max',{number:50}) },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-min-8">
                  <Col className="width-100" span={24}>
                    <Space size={4}>
                      <Text className="fs-12" type="danger">*</Text>
                      <Text className="fs-12" type="secondary">{t('profile.workExperience.form.company')}</Text>
                    </Space>
                    <Form.Item 
                      name="company"
                      validateFirst
                      rules={[
                        { required: true,  message: t('required') },
                        { max: 50, message: t('max',{number:50}) },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-min-8">
                  <Col span={24}>
                    <Text className="fs-12" type="secondary">{t('profile.workExperience.form.employmentType')}</Text>
                    <Form.Item name="employmentType">
                      <Select 
                        className="width-100 border-radius-6"
                      >
                        <Option key="1" value="FULL_TIME">{t('profile.workExperience.fullTime')}</Option>
                        <Option key="2" value="PART_TIME">{t('profile.workExperience.partTime')}</Option>
                        <Option key="3" value="FREELANCE">{t('profile.workExperience.freelance')}</Option>
                        <Option key="4" value="INTERNSHIP">{t('profile.workExperience.internship')}</Option>
                        <Option key="5" value="VOLUNTEER">{t('profile.workExperience.volunteer')}</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-min-12" gutter={8}>
                  <Col span={24}>
                    <Text className="fs-12" type="secondary">{t('profile.workExperience.form.businessSector')}</Text>
                    <Form.Item name="businessSectorId">
                       <Select
                        loading={getBusinessSector?.loading}
                        notFoundContent={getBusinessSector.loading ? <Spin size="small"/> : null}
                      >
                        {
                          getBusinessSector?.data && getBusinessSector?.data?.map(item => (
                            <Select.Option className='work-exp' key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-min-12" gutter={8}>
                  <Col span={24}>
                    <Text className="fs-12" type="secondary">{t('profile.workExperience.form.department')}</Text>
                    <Form.Item name="department">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-min-12" gutter={8}>
                  <Col span={24}>
                    <Text className="fs-12" type="secondary">{t('profile.workExperience.form.companysEmail')}</Text>
                    <Form.Item name="companyEmail" >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-min-12" gutter={8}>
                  <Col span={24}>
                    <Text className="fs-12" type="secondary">{t('profile.workExperience.form.companysWebsite')}</Text>
                    <Form.Item name="website" > 
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-min-8">
                  <Col span={24}>
                    <Text className="fs-12" type="secondary">{t('profile.workExperience.form.location')}</Text>
                    <Form.Item name="city">
                      <Select 
                        className="width-100" 
                        showSearch
                        onFocus={onFocusCity}
                        onSearch={onSearchCity}
                        onChange={handleCity}
                        placeholder={t('profile.workExperience.form.placeholderLocation')}
                        notFoundContent={getCity.loading ? <Spin size="small"/> : null}
                      >
                        {
                          getCity?.data?.map(item => 
                            <Option key={item.id} value={`${item.name}, ${item.countryName}`}>{`${item.name}, ${item.countryName}`}</Option>
                            )
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-min-8" gutter={8}>
                  <Col className="width-100" span={12}>
                    <Space size={4}>
                      <Text className="fs-12" type="danger">*</Text>
                      <Text className="fs-12" type="secondary">{t('profile.workExperience.form.startDate')}</Text>
                    </Space>
                    <Form.Item 
                      name="startDate"
                      rules={[
                        { required: true,  message: t('required') }
                      ]}
                    >
                      <DatePicker inputReadOnly className="width-100" picker="month" placeholder="YYYY-MM" onChange={onChangeStartDate} disabledDate={disabledDate} />
                    </Form.Item>
                  </Col>
                  <Col className="width-100" span={12}>
                    <Space size={4}>
                      {
                        !current ?
                          <Text className="fs-12" type="danger">*</Text>
                        :
                          null
                      }
                      <Text className="fs-12" type="secondary">{t('profile.workExperience.form.endDate')}</Text>
                    </Space>
                    <Form.Item 
                      name="endDate"
                      rules={[
                        { required: !current,  message: t('required') }
                      ]}
                    >
                      {
                        current ?
                          <Text className="fs-12" type="secondary">{t('profile.workExperience.form.present')}</Text>
                        :
                          <DatePicker inputReadOnly className="width-100" picker="month" placeholder="YYYY-MM" onChange={onChangeEndDate} />
                      }
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-min-8">
                  <Col span={24}>
                    <Form.Item name="current">
                      <Checkbox className="fs-12 dark-color" onChange={handleChecked} checked={current}>{t('profile.workExperience.form.checkbox')}</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </React.Fragment>
          }
          <Row>
            <Col span={24}>
              <Text className="fs-12" type="secondary">{t('profile.workExperience.form.jobDescription')}</Text>
              <Form.Item 
                name="jobDescription"
                validateFirst
                rules={[
                  { max: 200, message: t('max',{number:200}) },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            {
              id ?
                <React.Fragment>
                  <CButton type='danger-outline' className="float-left" title={t('profile.buttonDelete')} action={handleDelete} loading={deleteLoading} danger/>
                  <CButton type="primary" className="float-right" title={t('profile.buttonSave')} htmlType="submit" loading={submitLoading} />
                </React.Fragment>
              :
                <Space className="float-right">
                  <CButton type='default' title={t('profile.buttonBack')} action={handleCancel}/>
                  <CButton type="primary" title={t('profile.buttonSave')} htmlType="submit" loading={submitLoading} />
                </Space>
            }
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export class WorkExperience extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      submitLoading: false,
      deleteLoading: false,
      cityId: null,
      startDate: null,
      businesSectorId: null,
      endDate: null,
      current: false,
      isDate: false,
      id: null,
      dataEdit: null,
      metaCity: {
        page: 1,
        perpage: 10,
        search: ''
      }
    }
    this.onSearchCity = debounce(this.onSearchCity.bind(this), 800)
  }
  
  showModal = (id) => {
    const { actionGetBusinessSector, data } = this.props
    const dataEdit = data.listWorkExperience.filter(item => item.id === id).pop()

    this.setState({
      visible: true,
      id: id,
      cityId: id ? dataEdit?.cityId : null,
      businessSectorId: id ? dataEdit?.businessSectorId : null,
      startDate: id ? dataEdit?.startDate : null,
      endDate: id ? dataEdit?.endDate : null,
      current: id ? dataEdit?.current : false,
      dataEdit: dataEdit
    })

    return actionGetBusinessSector()
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      current: false,
      isDate: false
    })
  };

  onSearchCity = value => {
    const { metaCity } = this.state;
    const { actionListCity } = this.props;

    metaCity.page = 1
    metaCity.perpage = 10
    metaCity.search = value

    return actionListCity(metaCity)
  }

  onFocusCity = () => {
    const { metaCity } = this.state;
    const { actionListCity } = this.props;
    return actionListCity(metaCity)
  }

  handleCity = (value, option) => {
    const optionSelected = Number(option.key)
    this.setState({
      cityId: optionSelected
    })
  }

  onChangeStartDate = (date, dateString) => {
    const { t } = this.props
    const { endDate } = this.state

    this.setState({
      startDate: dateString
    })
    
    if(endDate){
      if(endDate < dateString){
        message.error(t('validationEndDate'), 5)
        this.formRef.current.setFieldsValue({
          endDate: null
        })
        this.setState({
          isDate: true
        })
      }else{
        this.setState({
          isDate: false
        })
      }
    }
  }

  onChangeEndDate = (date, dateString) => {
    const { t } = this.props
    const { startDate } = this.state

    this.setState({
      endDate: dateString
    })

    if(dateString < startDate){
      message.error(t('validationEndDate'), 5)
      this.formRef.current.setFieldsValue({
        endDate: null
      })
      this.setState({
        isDate: true
      })
    }else{
      this.setState({
        isDate: false
      })
    }
  }

  handleChecked = (e) => {
    this.setState({
      current: e.target.checked
    })
    
    if(e.target.checked){
      this.setState({
        endDate: null
      })

      this.formRef.current.setFieldsValue({
        endDate: null
      })
    }
  }

  handleDelete = () => {
    const { actionDeleteWorkExperience, actionGetProfile } = this.props
    const { id } = this.state
    
    return this.setState({ deleteLoading: true }, () => {
      return actionDeleteWorkExperience(id, response => {
        if(response.code === '1000'){
          return this.setState({ deleteLoading: false, visible: false }, () => {
            message.success(response.message)
            Cookie.set("activeElement", window.pageYOffset)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ deleteLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  onFinish = async (values) => {
    const { t, actionAddWorkExperience, actionUpdateWorkExperience, actionGetProfile } = this.props
    const { cityId, startDate, endDate, current, id, isDate } = this.state

    values.cityId = cityId
    values.startDate = startDate
    values.endDate = endDate
    values.current = current
    values.id = id
    
    if(isDate && !current){
      message.error(t('validationEndDate'))
    }else{
      if(id){
        return this.setState({ submitLoading: true }, () => {
          return actionUpdateWorkExperience(values, response => {
            if(response.code === '1000'){
              return this.setState({ submitLoading: false, visible: false }, () => {
                message.success(response.message)
                Cookie.set("activeElement", window.pageYOffset)
                return actionGetProfile()
              })
            }else{
              message.error(response.message)
            }
          }, (err) => {
            return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
          })
        })
      }else{
        return this.setState({ submitLoading: true }, () => {
          return actionAddWorkExperience(values, response => {
            if(response.code === '1000'){
              return this.setState({ submitLoading: false, visible: false }, () => {
                message.success(response.message)
                Cookie.set("activeElement", window.pageYOffset)
                return actionGetProfile()
              })
            }else{
              message.error(response.message)
            }
          }, (err) => {
            return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
          })
        })
      }
    }
  }

  render() {
    const { visible, submitLoading, deleteLoading, current, isDate, id, dataEdit } = this.state
    const { t, getCity, getBusinessSector, data } = this.props
    
    return (
      <React.Fragment>
        <WorkExperienceForm 
          t={t}
          data={data}
          visible={visible} 
          current={current}
          getCity={getCity}
          getBusinessSector={getBusinessSector}
          showModal={(id) => this.showModal(id)}
          handleCancel={this.handleCancel}
          onSearchCity={this.onSearchCity}
          onFocusCity={this.onFocusCity}
          handleCity={this.handleCity}
          onChangeStartDate={this.onChangeStartDate}
          onChangeEndDate={this.onChangeEndDate}
          handleChecked={this.handleChecked}
          formRef={this.formRef}
          onFinish={this.onFinish}
          isDate={isDate}
          submitLoading={submitLoading}
          deleteLoading={deleteLoading}
          id={id}
          dataEdit={dataEdit}
          handleDelete={this.handleDelete}
        />
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountListCity } = this.props
    const { unmountIndexBuseinessSector } = this.props
    return [unmountListCity(), unmountIndexBuseinessSector()]
  }
}

const mapStateToProps = (state) => ({
  getCity: state.cityReducer,
  getBusinessSector: state.businessSectorReducer
})

const mapDispatchToProps = {
  actionGetBusinessSector: indexBuseinessSector,
  actionListCity: listCity,
  unmountListCity: unmountListCity,
  actionAddWorkExperience: addWorkExperience,
  actionUpdateWorkExperience: updateWorkExperience,
  actionDeleteWorkExperience: deleteWorkExperience,
  unmountIndexBuseinessSector: unmountIndexBuseinessSector
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(WorkExperience))