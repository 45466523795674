import React from 'react';
import moment from 'moment';
import { Form, Space, Select, Typography, Row, Col, Input, DatePicker, Spin, Checkbox } from 'antd';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input

export default function WorkExperience(props) {
  const { 
    t,
    state: {
      disabledWork,
      workLocation,
      experienceCurrent,
      allValues
    },
    onChangeWorkTitle,
    onFocusCity,
    onSearchCity,
    onChangeWorkCity,
    onChangeBusinessSector,
    onChangeExperienceStartDate,
    onChangeExperienceEndDate,
    onChangeExperienceChecked,
    getCity,
    getBusinessSector,
    getProfile
  } = props

  const dataWorkExperience = getProfile?.data?.listWorkExperience[getProfile.data.listWorkExperience.length-1]

  return (
    <React.Fragment>
      <Row className="mb-min-8" gutter={16}>
        <Col className="width-100" span={12}>
          <Space size={4}>
            <Text className="fs-12" type="secondary">{t('profileUpdate.jobPosition')}</Text>
          </Space>
          <Form.Item 
            name="workTitle"
            validateFirst
            rules={[
              { min: 5, message: t('min',{number:5}) },
              { max: 50, message: t('max',{number:50}) },
            ]}
            initialValue={allValues?.workTitle || dataWorkExperience?.title}
          >
            <Input size="small" onChange={onChangeWorkTitle} disabled={dataWorkExperience?.title} />
          </Form.Item>
        </Col>
        <Col className="width-100" span={12}>
          <Space size={4}>
            <Text className="fs-12" type="secondary">{t('profileUpdate.company')}</Text>
          </Space>
          <Form.Item 
            name="workCompany"
            validateFirst
            rules={[
              { max: 50, message: t('max',{number:50}) },
            ]}
            initialValue={allValues?.workCompany || dataWorkExperience?.company}
          >
            <Input size="small" disabled={disabledWork || dataWorkExperience?.company} />
          </Form.Item>
        </Col>
      </Row>
      <Row className="mb-min-8" gutter={16}>
        <Col span={12}>
          <Text className="fs-12" type="secondary">{t('profileUpdate.jobType')}</Text>
          <Form.Item name="workEmploymentType" initialValue={allValues?.workEmploymentType || dataWorkExperience?.employmentType}>
            <Select 
              className="width-100 border-radius-6"
              size="small"
              disabled={disabledWork || dataWorkExperience?.employmentType}
            >
              <Option key="1" value="FULL_TIME">{t('profile.workExperience.fullTime')}</Option>
              <Option key="2" value="PART_TIME">{t('profile.workExperience.partTime')}</Option>
              <Option key="3" value="FREELANCE">{t('profile.workExperience.freelance')}</Option>
              <Option key="4" value="INTERNSHIP">{t('profile.workExperience.internship')}</Option>
              {/* <Option key="5" value="VOLUNTEER">{t('profile.workExperience.volunteer')}</Option> */}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Text className="fs-12" type="secondary">{t('profileUpdate.jobLocation')}</Text>
          <Form.Item name="workCityId" initialValue={workLocation ? workLocation : null}
          >
            <Select 
              className="width-100" 
              size="small" 
              showSearch
              onFocus={onFocusCity}
              onSearch={onSearchCity}
              onChange={onChangeWorkCity}
              placeholder={t('profile.workExperience.form.placeholderLocation')}
              notFoundContent={getCity.loading ? <Spin size="small" /> : null}
              disabled={disabledWork || dataWorkExperience?.cityName}
            >
              {
                getCity?.data?.map(item => 
                  <Option key={item.id} value={`${item.name}, ${item.countryName}`}>{`${item.name}, ${item.countryName}`}</Option>
                  )
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row className="mb-min-8" gutter={16}>
        <Col span={12}>
          <Text className="fs-12" type="secondary">{t('profileUpdate.business/Sector')}</Text>
          <Form.Item name="workBusinessSectorId" initialValue={allValues?.workBusinessSectorId || dataWorkExperience?.businessSectorId}>
            <Select
              size="small"
              loading={getBusinessSector?.loading}
              notFoundContent={getBusinessSector.loading ? <Spin size="small" /> : null}
              onChange={onChangeBusinessSector}
              disabled={disabledWork || dataWorkExperience?.businessSectorId}
            >
              {
                getBusinessSector?.data && getBusinessSector?.data?.map(item => (
                  <Select.Option className='work-exp' key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Text className="fs-12" type="secondary">{t('profileUpdate.department')}</Text>
          <Form.Item 
            name="workDepartment"
            validateFirst
            rules={[
              { min: 3, message: t('min',{number:3}) },
              { max: 50, message: t('max',{number:50}) },
            ]}
            initialValue={allValues?.workDepartment || dataWorkExperience?.department}
          >
            <Input size="small" disabled={disabledWork || dataWorkExperience?.department} />
          </Form.Item>
        </Col>
      </Row>
      <Row className="mb-min-8" gutter={16}>
        <Col span={12}>
          <Text className="fs-12" type="secondary">{t('profileUpdate.companyEmail')}</Text>
          <Form.Item 
            name="workCompanyEmail"
            validateFirst
            rules={[
              { max: 50, message: t('max',{number:50}) },
              // { pattern:  /.+@(gmail)\.com$/, message: 'Preferred using Gmail' }
            ]}
            initialValue={allValues?.workCompanyEmail || dataWorkExperience?.companyEmail}
          >
            <Input size="small" disabled={disabledWork || dataWorkExperience?.companyEmail} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Text className="fs-12" type="secondary">{t('profileUpdate.companyWebsite')}</Text>
          <Form.Item 
            name="workWebsite"
            validateFirst
            rules={[
              { max: 50, message: t('max',{number:50}) },
            ]}
            initialValue={allValues?.workWebsite || dataWorkExperience?.website}
          >
            <Input size="small" disabled={disabledWork || dataWorkExperience?.website} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="width-100" md={6} sm={12}>
          <Space size={4}>
            <Text className="fs-12" type="secondary">{t('profileUpdate.startDate')}</Text>
          </Space>
        </Col>
        <Col className="width-100" md={6} sm={12}>
          <Space size={4}>
            {
              !experienceCurrent ?
                <Text className="fs-12" type="danger"></Text>
              :
                null
            }
            <Text className="fs-12" type="secondary">{t('profileUpdate.endDate')}</Text>
          </Space>
        </Col>
      </Row>
      <Row className="mb-min-8" gutter={16}>
        <Col className="width-100" md={6} sm={12}>
          <Form.Item 
            name="workStartDate"
            initialValue={allValues?.workStartDate ? moment(allValues?.workStartDate, 'YYYY-MM') : dataWorkExperience?.startDate ? moment(dataWorkExperience?.startDate, 'YYYY-MM') : null}
          >
            <DatePicker inputReadOnly className="width-100" picker="month" size="small" placeholder="YYYY-MM" onChange={onChangeExperienceStartDate} disabled={disabledWork || dataWorkExperience?.startDate} />
          </Form.Item>
        </Col>
        <Col className="width-100" md={6} sm={12}>
          {
            experienceCurrent ?
              <Text className="fs-12" type="secondary">{t('profile.workExperience.form.present')}</Text>
            :
              <Form.Item 
                name="workEndDate" 
                initialValue={allValues?.workEndDate ? moment(allValues?.workEndDate, 'YYYY-MM') : dataWorkExperience?.endDate ? moment(dataWorkExperience?.endDate, 'YYYY-MM') : null}
              >
                <DatePicker inputReadOnly className="width-100" picker="month" size="small" placeholder="YYYY-MM" onChange={onChangeExperienceEndDate} disabled={disabledWork || dataWorkExperience?.endDate} />
              </Form.Item>
          }
        </Col>
        <Col className="width-100" md={12}>
          <Form.Item name="workCurrent">
            <Checkbox className="fs-12 dark-color" onChange={onChangeExperienceChecked} checked={experienceCurrent} disabled={disabledWork || dataWorkExperience?.startDate ||  dataWorkExperience?.endDate}>{t('profile.workExperience.form.checkbox')}</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Text className="fs-12" type="secondary">{t('profile.workExperience.form.jobDescription')}</Text>
          <Form.Item 
            name="workDescription"
            validateFirst
            rules={[
              { max: 200, message: t('max',{number:200}) },
            ]}
            initialValue={allValues?.workDescription || dataWorkExperience?.jobDescription}
          >
            <TextArea rows={3} disabled={disabledWork || dataWorkExperience?.jobDescription} />
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  )
}