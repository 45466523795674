import React from 'react';
import { path } from '../../../../config/index'
import Cookie from 'js-cookie';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Image, Typography, Row, Col, Space, Breadcrumb, Tag, Carousel, Card, Collapse , Menu, Dropdown, Button, message} from 'antd';
import CButton from '../../../Button';
import Icon1 from '../../../../assets/img/xpert/1.png';
import Icon2 from '../../../../assets/img/xpert/2.png';
import Icon3 from '../../../../assets/img/xpert/3.png';
import Icon4 from '../../../../assets/img/xpert/4.png';
import Icon5 from '../../../../assets/img/xpert/5.png';
import Icon6 from '../../../../assets/img/xpert/6.png';
// import Logo from '../../../assets/img/EmptyLogo.png';
import { xpertType } from '../../../../utils/constant/xpertType';
// import { eventType } from '../../../../utils/constant/eventType';
import { courseLevel } from '../../../../utils/constant/courseLevel';
import { trainingType } from '../../../../utils/constant/trainingType';
import { serviceType } from '../../../../utils/constant/serviceType';
import { ShareAltOutlined, FacebookOutlined, TwitterOutlined, WhatsAppOutlined, LinkOutlined } from '@ant-design/icons';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";

const { Text } = Typography;
const { Panel } = Collapse;
export function XpertDetailMobile(props) {
  const { getXpertDetail: { data }, t } = props
  
  const tags = data.tags.split(', ')

  const onClickBuy = () => {
    if(Cookie.get('user')){
      props.history.push({
        pathname: '/candidate/xpert/choose-ticket',
        state: { getXpertDetail: data }
      })
    }else{
      props.history.push('/login-or-register')
    }
  }

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url)
    message.success(t('copiedLink'));
  }

  const menu = (url) => (
    <Menu>
      <Menu.Item>
        <FacebookShareButton 
          url={url}
          // quote={"Jobshub - Job Detail"}
          // hashtag="#jobshub"
        >
          <Space>
            <FacebookOutlined />
            <Text>Facebook</Text>
          </Space>
        </FacebookShareButton>
      </Menu.Item>
      {/* <Menu.Item><InstagramOutlined /> Instagram</Menu.Item> */}
      <Menu.Item>
        <WhatsappShareButton 
          url={url}
          title=""
          separator=""
        >
          <Space>
            <WhatsAppOutlined />
            <Text>Whatsapp</Text>
          </Space>
        </WhatsappShareButton>
      </Menu.Item>
      <Menu.Item>
        <TwitterShareButton 
          url={url}
          // title=""
          // via=""
          // hashtags=""
          // related=""
        >
          <Space>
            <TwitterOutlined />
            <Text>Twitter</Text>
          </Space>
        </TwitterShareButton>
      </Menu.Item>
      <Menu.Item onClick={() => handleCopy(url)}><LinkOutlined /> {`${t('jobDetail.copyLink')}`}</Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      <Row className="width-100 mb-16">
        <Col span={22} offset={1}>
          <Breadcrumb className="mb-16 mt-16">
            <Breadcrumb.Item>
              <Link to={Cookie.get('user') ? '/candidate/xpert/list' : '/xpert/list'}>Xpert</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{xpertType.find(res => res.value === data.xpertType)?.name}</Breadcrumb.Item>
            <Breadcrumb.Item>{data.categoryName}</Breadcrumb.Item>
          </Breadcrumb>
          <Card 
            className="box-shadow mb-8" 
            cover={
              data?.listImage?.length > 1 ?
                <Carousel autoplay>
                  {
                    data.listImage.map((item, i) => 
                      <div key={i}>
                        <Image
                          src={item.fileUrl}
                          preview={false}
                          height={200}
                          width={'100%'}
                          style={{ objectFit: 'cover' }}
                        />
                      </div>
                    )
                  }
                </Carousel>
              :
                <Image
                  src={data?.listImage?.filter(item => item.isCover === true).pop().fileUrl}
                  preview={false}
                  height={200}
                  width={'100%'}
                  style={{ objectFit: 'cover' }}
                />
            }
            style={{ width: '100%' }}
            bodyStyle={{ padding: 0 }}
          >
            <Row className="mb-16 pr-16 pl-16 text-align-center" justify="space-around" align="middle">
              <Col span={24}>
              <Space className="width-100" direction="vertical" size={8}>
                  {/* <Text className="float-right fs-18" style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}> */}
                  {/* {
                    data.discountedPrice || data.maxDiscountedPrice ?
                      <>
                        <Text className="fs-16 dark-color" delete strong>
                          { 
                            data.maxPrice ?
                              `IDR ${data?.price?.toLocaleString()} - ${data?.maxPrice?.toLocaleString()}` 
                            : data.price ?
                              `IDR ${data?.price?.toLocaleString()}` 
                            : 
                              null
                          }
                        </Text> 
                        <Text className="fs-16 dark-color" >
                          { 
                            data.maxDiscountedPrice ?
                              `IDR ${data?.discountedPrice?.toLocaleString()} - ${data?.maxDiscountedPrice?.toLocaleString()}` 
                            : data.discountedPrice ?
                              `IDR ${data?.discountedPrice?.toLocaleString()}` 
                            : 
                              null
                          }
                        </Text>
                      </>
                  :
                    <Text className="primary-color fs-18" strong>
                      { 
                        data.maxPrice ?
                          `IDR ${data?.price?.toLocaleString()} - ${data?.maxPrice?.toLocaleString()}` 
                        : data.price ?
                          `IDR ${data?.price?.toLocaleString()}` 
                        : data.price === 0 ?
                          'FREE'
                        : null
                      }
                    </Text>
                  } */}
                  <Space direction="vertical" size={0}>
                    {
                      data.isStartFrom ?
                        <Text className="dark-color fs-14">Start from</Text>
                      : null
                    }
                    <Text className="primary-color fs-16 font-weight-bold">
                      { 
                        data.discountedPrice > 0 ?
                          `IDR ${data?.discountedPrice?.toLocaleString()}` 
                        : data.price > 0 ?
                          `IDR ${data?.price?.toLocaleString()}` 
                        : data.price === 0 ?
                          `IDR ${data?.price}`
                        :
                          '-'
                      }
                    </Text>
                  </Space>
                  {
                    data.isAvailable ?
                      <CButton type="save" action={() => onClickBuy()} title={t('xpert.buy')}/>
                    :
                      <CButton type="default" ghost disabled title={t('xpert.ticket')} style={{width: '95%'}}/>
                      // <Button type="primary" ghost disabled style={{ borderRadius: 6 }}>{t('xpert.ticket')}</Button>
                  }
                  <Dropdown overlay={() => menu(`${path}/xpert/detail/${data.categorySlug}/${data.slug}`)} trigger={['click']} placement="bottomLeft">
                    {/* <CButton className='fs-15' type='link' icon="ShareAltOutlined" title={`${t('jobDetail.share')}`}/> */}
                    <Button className='fs-15' type="link"><ShareAltOutlined className='fs-16' /> {`${t('jobDetail.share')}`}</Button>
                  </Dropdown>
                </Space>
              </Col>
            </Row>
            <Row className="mb-32 width-100">
              <Col span={24} style={{ backgroundColor: '#e6ecf6'}}>
                <Row className="mt-16 mb-16 pr-32 pl-32">
                  <Col span={24}>
                      <Row>
                        {
                          data.xpertType === 'EVENT' ?
                            <>
                              <Col span={24}>
                                <Space size={-32}>
                                  <Image
                                    src={Icon1}
                                    preview={false}
                                    width={'50%'}
                                  />
                                  <Space className="text-align-justify" direction="vertical" size={0}>
                                    <Text className="dark-color font-weight-bold fs-16">{t('xpert.date')}</Text>
                                    <Text className="dark-color fs-12">{data.xpertDate}</Text>
                                    <Text className="dark-color fs-12">{data.xpertTime}</Text>
                                  </Space>
                                </Space>
                              </Col>

                              <Col span={24}>
                                <Space size={-32}>
                                  <Image
                                    src={Icon2}
                                    preview={false}
                                    width={'50%'}
                                  />
                                  <Space className="text-align-justify" direction="vertical" size={0}>
                                    <Text className="dark-color fs-16 font-weight-bold">{t('xpert.organizer')}</Text>
                                    <Text className="light-color fs-12">{data.name}</Text>
                                  </Space>
                                </Space>
                              </Col>

                              <Col span={24}>
                                <Space size={-32}>
                                  <Image
                                    src={Icon3}
                                    preview={false}
                                    width={'50%'}
                                  />
                                  <Space className="text-align-justify" direction="vertical" size={0}>
                                    <Text className="dark-color fs-16 font-weight-bold">{t('xpert.type')}</Text>
                                    <Text className="light-color fs-12">{data.xpertType ? serviceType.find(item => item.value === data.xpertType)?.name : null}</Text>
                                  </Space>
                                </Space>
                              </Col>
                            </>
                          : 
                          data.xpertType === 'COURSE' ?
                            <>
                              <Col span={24}>
                                <Space size={-32}>
                                  <Image
                                    src={Icon1}
                                    preview={false}
                                    width={'50%'}
                                  />
                                  <Space className="text-align-justify" direction="vertical" size={0}>
                                    <Text className="dark-color font-weight-bold fs-16">{t('xpert.date')}</Text>
                                    <Text className="dark-color fs-12">{data.xpertDate}</Text>
                                    <Text className="dark-color fs-12">{data.xpertTime}</Text>
                                  </Space>
                                </Space>
                              </Col>

                              <Col span={24}>
                                <Space size={-16}>
                                  <Image
                                    src={Icon4}
                                    preview={false}
                                    width={'50%'}
                                  />
                                  <Space className="text-align-justify" direction="vertical" size={0}>
                                    <Text className="dark-color fs-16 font-weight-bold">{t('xpert.instructor')}</Text>
                                    <Text className="light-color fs-12">{data.name}</Text>
                                  </Space>
                                </Space>
                              </Col>

                              <Col span={24}>
                                <Space size={-64}>
                                  <Image
                                    src={Icon5}
                                    preview={false}
                                    width={'50%'}
                                  />
                                  <Space className="text-align-justify" direction="vertical" size={0}>
                                    <Text className="dark-color fs-16 font-weight-bold">{t('xpert.level')}</Text>
                                    <Text className="light-color fs-12">{data.courseLevel ? courseLevel.find(item => item.value === data.courseLevel)?.name : null}</Text>
                                  </Space>
                                </Space>
                              </Col>
                            </>
                          : data.xpertType === 'TRAINING' ?
                            <>
                              <Col span={24}>
                                <Space size={-32}>
                                  <Image
                                    src={Icon1}
                                    preview={false}
                                    width={'50%'}
                                  />
                                  <Space className="text-align-justify" direction="vertical" size={0}>
                                    <Text className="dark-color font-weight-bold fs-16">{t('xpert.date')}</Text>
                                    <Text className="dark-color fs-12">{data.xpertDate}</Text>
                                    <Text className="dark-color fs-12">{data.xpertTime}</Text>
                                  </Space>
                                </Space>
                              </Col>

                              <Col span={24}>
                                <Space size={-16}>
                                  <Image
                                    src={Icon6}
                                    preview={false}
                                    width={'50%'}
                                  />
                                  <Space className="text-align-justify" direction="vertical" size={0}>
                                    <Text className="dark-color fs-16 font-weight-bold">{t('xpert.issuing')}</Text>
                                    <Text className="light-color fs-12">{data.name}</Text>
                                  </Space>
                                </Space>
                              </Col>

                              <Col span={24}>
                                <Space size={-32}>
                                  <Image
                                    src={Icon3}
                                    preview={false}
                                    width={'50%'}
                                  />
                                  <Space className="text-align-justify" direction="vertical" size={0}>
                                    <Text className="dark-color fs-16 font-weight-bold">{t('xpert.type')}</Text>
                                    <Text className="light-color fs-12">{data.trainingType ? trainingType.find(item => item.value === data.trainingType)?.name : null}</Text>
                                  </Space>
                                </Space>
                              </Col>
                            </>
                          : 
                            data.xpertType === 'SERVICE' ?
                            <>
                              <Col span={24}>
                                <Space size={-32}>
                                  <Image
                                    src={Icon1}
                                    preview={false}
                                    width={'50%'}
                                  />
                                  <Space className="text-align-justify" direction="vertical" size={0}>
                                    <Text className="dark-color font-weight-bold fs-16">{t('xpert.dateDetail')}</Text>
                                    <Text className="dark-color fs-12">{data.xpertDate}</Text>
                                  </Space>
                                </Space>
                              </Col>
                              <Col span={24}>
                                <Space size={-32}>
                                  <Image
                                    src={Icon2} 
                                    preview={false}
                                    width={'50%'}
                                  />
                                  <Space className="text-align-justify" direction="vertical" size={0}>
                                    <Text className="dark-color fs-16 font-weight-bold">{t('xpert.organizer')}</Text>
                                    <Text className="light-color fs-12">{data.name}</Text>
                                  </Space>
                                </Space>
                              </Col>

                              <Col span={24}>
                                <Space size={-32}>
                                  <Image
                                    src={Icon3}
                                    preview={false}
                                    width={'50%'}
                                  />
                                  <Space className="text-align-justify" direction="vertical" size={0}>
                                    <Text className="dark-color fs-16 font-weight-bold">{t('xpert.type')}</Text>
                                    <Text className="light-color fs-12">
                                      {
                                        Cookie.get('i18next') === 'en' ?
                                          serviceType.find(res => res.value === data.serviceType)?.nameEn
                                        : 
                                          serviceType.find(res => res.value === data.serviceType)?.nameIn
                                      }
                                    </Text>
                                  </Space>
                                </Space>
                              </Col>
                            </> 
                          : null
                        }
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Space className="pr-32 pl-32 mb-32 width-100" direction="vertical">
              {
                data.xpertType === 'COURSE' ?
                  <React.Fragment>
                    <Space direction="vertical">
                      <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'bold' }}>{t('xpert.title')}</Text>
                        <ul style={{ marginLeft: -20 }}>
                          {
                            data.listDetail.map((item, i) => 
                              <li key={i}>{item.name}</li>
                            )
                          }
                        </ul>
                    </Space>
                    {
                      data?.listSyllabus?.length > 0 ?
                        <Space className="width-100 mb-16" direction="vertical">
                          <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'bold' }}>{t('xpert.syllabus')}</Text>
                            <Collapse className="width-100" defaultActiveKey={[0]} style={{ backgroundColor: '#e6ecf6' }}>
                              {
                                data.listSyllabus.map((item, i) => 
                                  <Panel header={item.title} key={i}>
                                    <div className="dark-color fs-14" dangerouslySetInnerHTML={{ __html: item.detail }}></div>
                                  </Panel>
                                )
                              }
                            </Collapse>
                        </Space>
                      : null
                    }
                    <Space direction="vertical">
                      <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'bold' }}>{t('xpert.recommended')}</Text>
                        <ul style={{ marginLeft: -20 }}>
                          {
                            data.listCourseFor.map((item, i) => 
                              <li key={i}>{item.name}</li>
                            )
                          }
                        </ul>
                    </Space>
                  </React.Fragment>
                : data.xpertType === 'TRAINING' ?
                  <React.Fragment>
                    <Space direction="vertical">
                      <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'bold' }}>{t('xpert.outcomes')}</Text>
                        <ul style={{ marginLeft: -20 }}>
                          {
                            data.listOutcome.map((item, i) => 
                              <li key={i}>{item.name}</li>
                            )
                          }
                        </ul>
                    </Space>
                    {
                      data?.listTrainingSyllabus?.length > 0 ?
                        <Space className="width-100 mb-16" direction="vertical">
                          <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'bold' }}>syllabus</Text>
                            <Collapse className="width-100" defaultActiveKey={[0]} style={{ backgroundColor: '#e6ecf6' }}>
                              {
                                data.listTrainingSyllabus.map((item, i) => 
                                  <Panel header={item.title} key={i}>
                                    <div className="dark-color fs-14" dangerouslySetInnerHTML={{ __html: item.detail }}></div>
                                  </Panel>
                                )
                              }
                            </Collapse>
                        </Space>
                      : null
                    }
                    <Space direction="vertical">
                      <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'bold' }}>{t('xpert.benefit')}</Text>
                        <ul style={{ marginLeft: -20 }}>
                          {
                            data.listBenefit.map((item, i) => 
                              <li key={i}>{item.name}</li>
                            )
                          }
                        </ul>
                    </Space>
                    <Space direction="vertical">
                      <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'bold' }}>{t('xpert.recommended')}</Text>
                        <ul style={{ marginLeft: -20 }}>
                          {
                            data.listTrainingFor.map((item, i) => 
                              <li key={i}>{item.name}</li>
                            )
                          }
                        </ul>
                    </Space>
                  </React.Fragment>
                : null
              }
              <Space direction="vertical">
                <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'bold' }}>{t('xpert.description')}</Text>
                {
                  data.xpertDetail ?
                    <div className="dark-color fs-14" dangerouslySetInnerHTML={{ __html: data.xpertDetail }}></div>
                  :
                    <Text className="dark-color fs-14">-</Text>
                }
              </Space>
              {
                data.tags ?
                  data.tags > 2 ?
                    <Space className="mb-16" direction="vertical">
                      <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'bold' }}>{t('xpert.skill')}</Text>
                      <Space size={4}>
                        {
                          tags.map((item, i) => 
                            <Tag className="dark-color" key={i} color="#e6ecf6" style={{ fontSize: 12, padding: '4px 16px', borderRadius: 12 }}>{item}</Tag>
                          )
                        }
                      </Space>
                    </Space>
                  :
                    <Space className="mb-16" direction="vertical">
                      <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'bold' }}>{t('xpert.skill')}</Text>
                      <Space direction="vertical" size={4}>
                        { 
                          tags.map((item, i) => 
                            <Tag className="dark-color" key={i} color="#e6ecf6" style={{ fontSize: 12, padding: '4px 16px', borderRadius: 12 , marginTop: '10px'}}>{item}</Tag>
                          )
                        }
                      </Space>
                    </Space>
                :
                  null
              }
              <Space className="width-100" direction="vertical">
                <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'bold' }}>
                  {
                    data.xpertType === 'EVENT' ?
                    t('xpert.organizer')
                    : data.xpertType === 'COURSE' ?
                    t('xpert.instructor')
                    : data.xpertType === 'TRAINING' ?
                    t('xpert.issuing')
                    : data.xpertType === 'SERVICE' ?
                    t('xpert.organizer')
                    : null
                  }
                </Text>
                <Row className="pr-16 pl-16" justify="space-around" align="middle">
                  <Col className="text-align-center mb-8" span={24}>
                    <Space direction='vertical'>
                      <Image
                        width={'50%'}
                        src={data.logoUrl}
                        preview={false}
                        style={{ objectFit: 'contain' }}
                      />
                      <Text className="info-color fs-16">{data.name}</Text>
                      {/* <Text className="dark-color fs-14">{data.about}</Text> */}
                    </Space>
                  </Col>
                </Row>
                  {
                    data.xpertType === 'TRAINING' ?
                      <React.Fragment>
                        <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'bold' }}>{t('xpert.trainer')}</Text>
                        <Row className="pr-16 pl-16" justify="space-around" align="middle">
                          <Col span={24}>
                            <Text className="info-color fs-16">{data.trainerName ? data.trainerName : 'N/A'}</Text>
                          </Col>
                        </Row>
                      </React.Fragment>
                    : null
                  }
              </Space>
            </Space>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withTranslation()(XpertDetailMobile)