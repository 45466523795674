import React from 'react';
import { Row, Col, Typography, Form, Input, InputNumber, Select } from 'antd';
import CButton from '../../../../../components/Button';
const { Text } = Typography;
const { Option } = Select;

export default function FormWiraswasta(props) {
  const { formRef, f5, getProvince, getCity, handleProvince, handlePrev, onFinish } = props
  return (
    <React.Fragment>
      <Row className="text-align-center mb-16 mt-16">
        <Col span={24}>
          <Text className="primary-color fs-17">Wiraswasta</Text>
        </Col>
      </Row>

      <Form 
        ref={formRef}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          companyName: f5?.companyName ? f5.companyName : null,
          provinceId: f5?.provinceId ? f5.provinceId : null,
          positionName: f5?.positionName ? f5.positionName : null,
          cityId: f5?.cityId ? f5.cityId : null,
          f1301: f5?.f1301 ? f5.f1301 : null,
          gradeLocation: f5?.gradeLocation ? f5.gradeLocation : null,
          f1302: f5?.f1302 ? f5.f1302 : null,
          f1303: f5?.f1303 ? f5.f1303 : null,
        }}
      >
        <Row gutter={16} justify="space-between" align="bottom">
          <Col xs={24} lg={12}>
            <Form.Item 
              name="companyName"
              label="Nama Perusahaan"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
                { pattern: /^[0-9a-zA-z -]*$/, message: 'Special characters not allowed' },
                { min: 3, message: 'At least 3 characters' },
                { max: 50, message: '50 characters only' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item 
              name="provinceId" 
              label="Lokasi Propinsi Perusahaan"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <Select 
                className="width-100" 
                onChange={handleProvince}
                placeholder="Pilih Propinsi"
              >
                {
                  getProvince.data.map((item) => 
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                    )
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} justify="space-between" align="bottom">
          <Col xs={24} lg={12}>
            <Form.Item 
              name="positionName"
              label="Apa posisi/jabatan kamu saat ini?"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <Select 
                className="width-100" 
                placeholder="Pilih Jawaban"
              >
                <Option key="1" value="Founder">Founder</Option>
                <Option key="2" value="Co-Founder">Co-Founder</Option>
                <Option key="3" value="Staff">Staff</Option>
                <Option key="4" value="Freelance/Kerja Lepas">Freelance/Kerja Lepas</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item 
              name="cityId" 
              label="Lokasi Kota Perusahaan"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <Select 
                className="width-100" 
                placeholder="Pilih Kabupaten/Kota"
              >
                {
                  getCity?.data?.map((item) => 
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                    )
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} justify="space-between" align="bottom">
          <Col xs={24} lg={12}>
          <Form.Item 
              name="f1301"
              label="Berapa pendapatan setiap bulan dari Pekerjaan Utama? (jika tidak ada, isi dengan 0)"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
                { pattern: /^[0-9]*$/, message: 'Number only' },
              ]}
            >
              <InputNumber className="width-100" maxLength={10} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item 
              name="gradeLocation" 
              label="Tingkat tempat bekerja"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <Select 
                className="width-100" 
                placeholder="Pilih Jawaban"
              >
                <Option key="1" value="LOCAL">Lokal/Wilayah/Wiraswasta tidak berbadan hukum</Option>
                <Option key="2" value="NATIONAL">Nasional/Wiraswasta berbadan hukum</Option>
                <Option key="3" value="MULTINATIONAL">Multinasional/Internasional</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        
        <Row gutter={16} justify="space-between" align="bottom">
          <Col xs={24} lg={12}>
            <Form.Item 
              name="f1302" 
              label="Berapa pendapatan setiap bulan dari Lembur dan Tips? (jika tidak ada, isi dengan 0)"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <InputNumber className="width-100" maxLength={10} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item 
              name="f1303" 
              label="Berapa pendapatan setiap bulan dari Pekerjaan Lainnya? (jika tidak ada, isi dengan 0)"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <InputNumber className="width-100" maxLength={10} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <CButton className="width-100" type="primary" ghost action={handlePrev} title="Sebelumnya"/>
            </Col>
            <Col span={12}>
              <CButton className="width-100" htmlType="submit" type="primary" title="Selanjutnya"/>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </React.Fragment>
  )
}
