import React from 'react';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import { Grid } from 'antd';
import XpertListDesktop from './Desktop';
import XpertListMobile from './Mobile';
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function XpertListCondition(props) {
  const { lg } = GetBreakPoint()

  if(process.env.REACT_APP_ENV === "production"){
    ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Xpert - List');
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Xpert - List</title>
      </Helmet>
      {
        lg ?
          <XpertListDesktop {...props} />
        :
          <XpertListMobile {...props} />
      }
    </React.Fragment>
  )
}
