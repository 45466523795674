import React, { Component } from 'react'
import { Card , Typography, Row, Col, Space, Breadcrumb } from 'antd';
import { withTranslation } from 'react-i18next';
import CButton from '../../../../../../../components/Button';

const { Text } = Typography;

export class S3Info extends Component {
  render() {
    const { handleNext, t } = this.props
    return (
      <React.Fragment>
        <Row className='mt-16'>
          <Col lg={{ span: 20, offset: 2 }} xxl={{ span: 18, offset: 3 }}>
            <Breadcrumb className="mb-16" >
              <Breadcrumb.Item>Career Pathway</Breadcrumb.Item>
              <Breadcrumb.Item>Area Karir</Breadcrumb.Item>
            </Breadcrumb>
            <Card className="card-body-info-assessment mt-16 mb-16 border-radius-8"> 
              <Space direction='vertical' size={16}>
                <Space className="text-align-center width-100" direction="vertical" size={0}>
                  <Text className='dark-color' style={{ fontSize: 16, fontWeight: '600' }} strong>Section 3</Text>
                  {/* <Text style={{ fontSize: 20, fontWeight: 'normal', color: "#32AB6D" }} strong>{t('pathway.readingcompre')}</Text> */}
                  <Text style={{ fontSize: 20, fontWeight: 'normal', color: "#32AB6D" }} strong>Reading Comprehension</Text>
                </Space>
                <Space direction='vertical' size={16}>
                  <Text className='fs-15' style={{ fontStyle: 'italic' }}>
                    Directions: In the Reading Comprehension section, you will read a text of several passages. Text is followed by several questions about it.
                  </Text>
                  <Text className='fs-15'>
                    Petunjuk: Di bagian Membaca, Anda akan membaca teks yang terdiri dari beberapa bagian. Teks diikuti oleh beberapa pertanyaan tentangnya.
                  </Text>
                </Space>
                <Row className='mt-32'>
                  <Col xs={{ span: 24, offset: 9 }} lg={{ span: 20, offset: 11 }} xxl={{ span: 18, offset: 10 }}>
                    <CButton type="primary" size="large" block action={() => handleNext(3,1)} title={t('pathway.start')}/>
                  </Col>
                </Row>
              </Space>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
export default withTranslation()(S3Info)