import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loading } from '../../components'
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { setRegister, setRegisterAviation, setRegisterNakes } from '../../redux/actions/register/registerAction';
import { checkEmail, checkMobile } from '../../redux/actions/check/checkAction';
import { indexEducationLevel, unmountIndexEducationLevel } from '../../redux/actions/master/educationLevel/educationLevelAction';
import { listInstitution, unmountIndexInstitution } from '../../redux/actions/master/institution/institutionAction';
import { listFieldOfStudy, unmountIndexFieldOfStudy } from '../../redux/actions/master/fieldOfStudy/fieldOfStudyAction';
import { indexJobDestination, unmountIndexJobDestination } from '../../redux/actions/master/jobDestination/jobDestinationAction';
import { indexCountry } from '../../redux/actions/master/country/countryAction';
import { listProvinceByCountry, unmountlistProvinceByCountry } from '../../redux/actions/master/province/provinceAction';
import { listCityByProvince, unmountlistCityByProvince } from '../../redux/actions/master/city/cityAction';
import { message } from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import ChannelRegisterGeneral from './General';
import ChannelRegisterAviation from './Aviation';
import ChannelRegisterRef from './Ref';
import ChannelRegisterNakes from './Nakes';

export class Register extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      idCallingCode: 99,
      callingCode: 62,
      agree: false,
      visible: false,
      nationalityId: "",
      countryId: "",
      provinceId: "",
      cityId: "",
      destinationSelected: [],

      metaInstitution: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaFieldOfStudy: {
        page: 1,
        perpage: 10,
        search: '',
        educationLevelId: ''
      },
      listFamilyHistory: [],
      listDocument: []
    }
  }
  
  componentDidMount() {
    const { actionGetCountry , actionGetEducationLevel, actionGetJobDestination } = this.props

    if(localStorage.getItem('link')){
      localStorage.removeItem('link')
    }
    
    if(localStorage.getItem('visible')){
      localStorage.removeItem('visible')
    }

    actionGetCountry()
    actionGetEducationLevel()
    actionGetJobDestination()
  }

  onFinish = (values) => {
    const { idCallingCode } = this.state
    const { actionSetRegister } = this.props
    const { callingCode } = this.state
    const channelRegister = window.location.pathname.split("/")[1]

    values.callingCodeId = idCallingCode
    values.mobile = `${callingCode}${values.mobile}`
    values.channel = channelRegister
    
    return this.setState({ submitLoading: true }, () => {
      return actionSetRegister(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false }, () => {
            localStorage.setItem("email", values.email)
            localStorage.setItem("password", values.password)

            this.props.history.push('/register/confirm-email')
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  onFinishAviation = (values) => {
    const { idCallingCode, callingCode, nationalityId, countryId, provinceId, cityId, listFamilyHistory, listDocument } = this.state
    const { actionSetRegisterAviation } = this.props

    values.birthdate = moment(values.birthdate).format('DD/MM/YYYY')
    values.nationalityId = nationalityId
    values.countryId = countryId
    values.provinceId = provinceId
    values.cityId = cityId
    values.callingCodeId = idCallingCode
    values.mobile = `${callingCode}${values.mobile}`
    values.listFamilyHistory = listFamilyHistory
    values.listDocument = listDocument

    return this.setState({ submitLoading: true }, () => {
      return actionSetRegisterAviation(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false }, () => {
            localStorage.setItem("email", values.email)
            localStorage.setItem("password", values.password)

            this.props.history.push('/register/confirm-email')
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  onFinishRef = (values) => {
    const { idCallingCode, callingCode, destinationSelected } = this.state
    const { actionSetRegister } = this.props
    const channelRegister = window.location.pathname.split("/")[1]
    const newDestinationSelected = destinationSelected.map(item => ({ jobDestinationId: item }))

    values.callingCodeId = idCallingCode
    values.mobile = `${callingCode}${values.mobile}`
    values.listJobDestination = newDestinationSelected
    values.channel = channelRegister
    
    return this.setState({ submitLoading: true }, () => {
      return actionSetRegister(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false }, () => {
            localStorage.setItem("email", values.email)
            localStorage.setItem("password", values.password)

            this.props.history.push('/register/confirm-email')
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  onFinishNakes = (values) => {
    const { idCallingCode } = this.state
    const { actionSetRegisterNakes } = this.props
    const { callingCode } = this.state
    const channelRegister = window.location.pathname.split("/")[1]

    values.callingCodeId = idCallingCode
    values.mobile = `${callingCode}${values.mobile}`
    values.birthdate = moment(values.birthdate).format('DD/MM/YYYY')
    values.channel = channelRegister
    
    return this.setState({ submitLoading: true }, () => {
      return actionSetRegisterNakes(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false }, () => {
            localStorage.setItem("email", values.email)
            localStorage.setItem("password", values.password)

            this.props.history.push('/register/confirm-email')
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  onChangeCheck = (e) => {
    this.setState({
      agree: e.target.checked
    })
  }

  onChangeCode = (value, option) => {
    this.setState({
      callingCode: Number(option.text),
      idCallingCode: Number(option.key)
    })
    this.formRef.current.setFieldsValue({
      mobile: null
    })
  }

  onCheckEmail = (e) => {
    const { actionCheckEmail } = this.props
    return actionCheckEmail(e, response => {
      if(response){
        this.formRef.current.setFieldsValue({
          email: null
        })
        message.error('Email already exist')
      }
    })
  }

  onCheckMobile = (e) => {
    const { callingCode } = this.state
    const { actionCheckMobile } = this.props

    this.formRef.current.setFieldsValue({
      mobile: e.replace(/^0+/, '')
    })

    e = `${callingCode}${e.replace(/^0+/, '')}`
    
    return actionCheckMobile(e, response => {
      if(response){
        this.formRef.current.setFieldsValue({
          mobile: null
        })
        message.error('Phone Number already exist')
      }
    })
  }

  onFocusPassword = (value) => {
    this.setState({
      visible: value
    })
  }

  onSearchInstitution = value => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;

    metaInstitution.page = 1
    metaInstitution.perpage = 10
    metaInstitution.search = value

    return actionListInstitution(metaInstitution)
  }

  onFocusInstitution = () => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;
    return actionListInstitution(metaInstitution)
  }

  onSearchFieldOfStudy = value => {
    const { metaFieldOfStudy, educationLevelId } = this.state;
    const { actionListFieldOfStudy } = this.props;

    metaFieldOfStudy.page = 1
    metaFieldOfStudy.perpage = 10
    metaFieldOfStudy.search = value
    metaFieldOfStudy.educationLevelId = educationLevelId

    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  onFocusFieldOfStudy = () => {
    const { metaFieldOfStudy, educationLevelId } = this.state;
    const { actionListFieldOfStudy } = this.props;

    metaFieldOfStudy.page = 1
    metaFieldOfStudy.perpage = 10
    metaFieldOfStudy.educationLevelId = educationLevelId

    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  onChangeEducationLevel = (value) => {
    this.setState({
      educationLevelId: value
    })
    
    this.formRef.current.setFieldsValue({
      fieldOfStudy: ''
    })
  }

  handleNationality = (value, option) => {
    const optionSelected = Number(option.key)

    this.setState({
      nationalityId: optionSelected,
    })
  }

  handleCountry = (value, option) => {
    const { provinceId } = this.state
    const optionSelected = Number(option.key)

    this.setState({
      countryId: optionSelected,
      selectedCountry: optionSelected,
    })

    if (provinceId !== 0) {
      this.setState({
        provinceId: null,
        cityId: null
      })

      this.formRef.current.setFieldsValue({
        provinceId: null,
        cityId: null
      })
    }
    const { actionGetProvince } = this.props
    return actionGetProvince(option.key)

  }

  handleProvince = (value, option) => {
    const { cityId } = this.state
    const optionSelected = Number(option.key)

    this.setState({
      provinceId: optionSelected
    })

    if (cityId !== 0) {
      this.setState({
        cityId: null,
      })

      this.formRef.current.setFieldsValue({
        cityId: null,
      })
    }

    const { actionGetCity } = this.props
    return actionGetCity(option.key)
  }

  handleCity = (value, option) => {
    const optionSelected = Number(option.key)

    this.setState({
      cityId: optionSelected
    })
  }

  handleDestination = (value, option) => {
    const optionSelected = option.map(item => Number(item.key))

    this.setState({
      destinationSelected: optionSelected
    })
  }
  
  handleUpload = (key) => {
    const { listDocument } = this.state
    return {
      showUploadList: true,
      withCredentials: true,
      accept: `${key === 4 || key === 19 ? ".png,.jpg,.jpeg,.pdf" : ".png,.jpg,.jpeg"}`,
      beforeUpload: file => {
        const validateSize = file.size >= (key === 4 || key === 19 ? 3000000 : 1000000);
        if (validateSize) {
          message.error(key === 4 || key === 19 ? 'Max file size is 3 MB!' : 'Max file size is 1 MB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        const index = listDocument.findIndex(item => item.documentTypeId === key)
        if(index !== -1){
          listDocument[index].file = file
        }else{
          listDocument.push({
            documentTypeId: key,
            file: file
          })
        }
        reader.onload = (e) => {
          this.setState({
            listDocument
          })
        }
        return false;
      },
    }
  }

  handleRemove = (file) => {
    const { listDocument } = this.state
    const arrListDocument = listDocument.filter(item => item.file.uid !== file.uid);
    this.setState({ listDocument: arrListDocument })
    message.success(`${file.name} file removed successfully`);
  }

  handleRadio = (e, key) => {
    const { listFamilyHistory } = this.state
    listFamilyHistory[key] = e.target.value
    this.setState({ listFamilyHistory })
  }

  render() {
    const { agree, submitLoading, visible, listDocument } = this.state
    const { getCountry } = this.props

    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Register');
    }
    
    if(getCountry.loading){
      return <Loading />
    }
    
    const initialProps = {
      visible: visible,
      agree: agree,
      submitLoading: submitLoading,
      listDocument: listDocument,
      formRef: this.formRef,
      onFinish: this.onFinish,
      onFinishAviation: this.onFinishAviation,
      onFinishNakes: this.onFinishNakes,
      onFinishRef: this.onFinishRef,
      onCheckEmail: this.onCheckEmail,
      onFocusPassword: this.onFocusPassword,
      onChangeCode: this.onChangeCode,
      onCheckMobile: this.onCheckMobile,
      onChangeCheck: this.onChangeCheck,
      handleNationality: this.handleNationality,
      handleCountry: this.handleCountry,
      handleProvince: this.handleProvince,
      handleCity: this.handleCity,
      onFocusInstitution: this.onFocusInstitution,
      onSearchInstitution: this.onSearchInstitution,
      onChangeEducationLevel: this.onChangeEducationLevel,
      onFocusFieldOfStudy: this.onFocusFieldOfStudy,
      onSearchFieldOfStudy: this.onSearchFieldOfStudy,
      handleRadio: this.handleRadio,
      handleUpload: this.handleUpload,
      handleRemove: this.handleRemove,
      handleDestination: this.handleDestination
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Register</title>
        </Helmet>
        {
          window.location.pathname === '/bicca/register' ?
            <ChannelRegisterAviation {...initialProps} {...this.props} />
          : window.location.pathname === '/jobfair2510/register' ?
            <ChannelRegisterRef {...initialProps} {...this.props}/>
          : window.location.pathname === '/konvensi-nakes/register' ?
            <ChannelRegisterNakes {...initialProps} {...this.props}/>
          :
            <ChannelRegisterGeneral {...initialProps} {...this.props} />
        }
        
        {/* <Row className="display-none">
          <Col span={12}>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Sign In With Google"
              onSuccess={this.responseGoogle}
              onFailure={this.responseGoogle}
              cookiePolicy={'single_host_origin'}
              className="google-login social-button"
            />
          </Col>
          <Col span={12}>
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              fields="name,email,picture"
              callback={this.responseFacebook}
              className="facebook-login social-button"
            />
          </Col>
        </Row> */}
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountIndexInstitution, unmountIndexEducationLevel,  unmountIndexFieldOfStudy, unmountIndexJobDestination , unmountlistCityByProvince, unmountlistProvinceByCountry} = this.props
    return (unmountIndexInstitution(), unmountIndexEducationLevel(),  unmountIndexFieldOfStudy(), unmountIndexJobDestination(), unmountlistCityByProvince(), unmountlistProvinceByCountry())
  }
}

const mapStateToProps = (state) => ({
  getCountry: state.countryReducer,
  getEducationLevel: state.educationLevelReducer,
  getInstitution: state.institutionReducer,
  getFieldOfStudy: state.fieldOfStudyReducer,
  getJobDestination: state.jobDestinationReducer,
  getProvince: state.provinceReducer,
  getCity: state.cityReducer,
  getSubDistrict: state.subDistrictReducer,
})

const mapDispatchToProps = {
  actionGetCountry: indexCountry,
  actionCheckEmail: checkEmail,
  actionCheckMobile: checkMobile,
  actionGetEducationLevel: indexEducationLevel,
  unmountIndexEducationLevel: unmountIndexEducationLevel,
  actionListInstitution: listInstitution,
  unmountIndexInstitution: unmountIndexInstitution,
  actionListFieldOfStudy: listFieldOfStudy,
  unmountIndexFieldOfStudy: unmountIndexFieldOfStudy,
  actionGetJobDestination: indexJobDestination,
  unmountIndexJobDestination: unmountIndexJobDestination,
  actionGetProvince: listProvinceByCountry,
  unmountlistProvinceByCountry: unmountlistProvinceByCountry,
  actionGetCity: listCityByProvince,
  unmountlistCityByProvince: unmountlistCityByProvince,
  actionSetRegister: setRegister,
  actionSetRegisterAviation: setRegisterAviation,
  actionSetRegisterNakes: setRegisterNakes,
  // actionSetRegisterRef: setRegisterRef
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Register))