import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography, Space, Image, Grid } from 'antd'
import Hand from '../../assets/img/employer/hand.png';

const { useBreakpoint } = Grid;
const { Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

function GetTranslation() {
  const t = useTranslation()
  return t;
}

export default function banner(props) {
  const { md } = GetBreakPoint()
  const { t } = GetTranslation()
   
  return (
    <React.Fragment>
      {
        md ?
          <Row style={{ paddingTop: 32, paddingBottom: 32, backgroundColor: '#ffffff' }}>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
              <Row justify="space-around" align="middle">
                <Col xs={24} md={12}>
                  <Space direction="vertical" size={32}>
                    <Space direction="vertical" size={-16}>
                      <Text style={{ fontSize: 34, fontWeight: 'bold', color: '#727272' }}>{t('pageEmployer.banner.title')}</Text>
                      <Text style={{ fontSize: 40, fontWeight: 'bold', color: '#4273b9' }}>{t('pageEmployer.banner.title2')}</Text>
                    </Space>
                    <Space direction="vertical" size={-8}>
                      <Text style={{ fontSize: 22, fontWeight: 'bold', color: '#535353' }}>{t('pageEmployer.banner.subTitle')}</Text>
                      <Text>
                        <Text style={{ fontSize: 30, fontWeight: 'bold', color: '#32ab6d' }}>{t('pageEmployer.banner.subTitle2')} </Text>
                        <Text style={{ fontSize: 18, fontWeight: 'normal', color: '#535353' }}>{t('pageEmployer.banner.subTitle3')}</Text>
                      </Text>
                    </Space>
                  </Space>
                </Col>
                <Col xs={24} md={12}>
                  <Image
                    width={'100%'}
                    src={Hand}
                    preview={false}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        :
          <Row style={{ paddingTop: 16, paddingBottom: 32, backgroundColor: '#f6fbfc' }}>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
              <Row justify="space-around" align="middle" gutter={[0, 32]}>
                <Col xs={24} md={16}>
                  <Space direction="vertical" size={16}>
                    <Space direction="vertical" size={-16}>
                      <Text style={{ fontSize: 21, fontWeight: 'bold', color: '#727272' }}>SOLUSI TEPAT</Text>
                      <Text style={{ fontSize: 28, fontWeight: 'bold', color: '#4273b9' }}>MENCARI KANDIDAT</Text>
                    </Space>
                    <Space direction="vertical" size={-8}>
                      <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#535353' }}>BERGABUNGLAH SEKARANG!</Text>
                      <Text>
                        <Text style={{ fontSize: 24, fontWeight: 'bold', color: '#32ab6d' }}>GRATIS </Text>
                        <Text style={{ fontSize: 14, fontWeight: 'normal', color: '#535353' }}>POSTING IKLAN LOWONGAN KERJA</Text>
                      </Text>
                    </Space>
                  </Space>
                </Col>
                <Col xs={24} md={8}>
                  <Image
                    width={'100%'}
                    src={Hand}
                    preview={false}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
      }
    </React.Fragment>
  )
}
