import React, { Component } from "react";
import { Row, Col, Card, Space, Typography, Radio, Image } from "antd";
import Header from "../Header";
import { withTranslation } from "react-i18next";
import CButton from '../../../../../../../components/Button';

const { Text } = Typography;

export class S3Q57_61 extends Component {
  render() {
    const {
      questionReadingCompre,
      handleSelect,
      handleNext,
      result,
      t,
      handleBack,
    } = this.props;
    const arrQuestion = questionReadingCompre[4];
    return (
      <React.Fragment>
        <Row className="mt-16">
          <Col lg={{ span: 20, offset: 2 }} xxl={{ span: 18, offset: 3 }}>
            <Header {...this.props} />
            <Card className="card-body-question mt-16 mb-16 border-radius-8">
              <Row className="mt-16">
                <Col span={24}>
                  <Space className="width-100" direction="vertical" size={16}>
                    <Col span={24}>
                      <Space
                        className="text-align-center width-100"
                        direction="vertical"
                        size={0}
                      >
                        <Text
                          style={{
                            fontSize: 20,
                            fontWeight: "normal",
                            fontStyle: "italic",
                          }}
                          strong
                        >
                          {/* {t("pathway.readingcompre")} */}
                          Reading Comprehension
                        </Text>
                        <Text
                          className="dark-color"
                          style={{
                            fontSize: 16,
                            fontWeight: "600",
                            fontStyle: "italic",
                          }}
                          strong
                        >
                          Section 3
                        </Text>
                      </Space>
                    </Col>
                    <Col span={24}>
                      <Card style={{ backgroundColor: "#F0F2F5" }}>
                        <Col span={24}>
                          <Space direction="vertical" size={0}>
                            <Text
                              tyle={{
                                fontSize: 15,
                                fontWeight: "normal",
                                fontStyle: "italic",
                              }}
                            >
                              Directions: In this section, you will read a text
                              from an article to answer question number 57 - 61.
                              Choose the correct option to answer the question.
                            </Text>
                            <Text
                              style={{
                                fontSize: 15,
                                fontWeight: "normal",
                                color: "#FF0000",
                              }}
                            >
                              Petunjuk: Pada bagian ini, Anda akan membaca teks
                              dari sebuah artikel untuk menjawab soal nomor 57 -
                              61. Pilih opsi yang tepat untuk menjawab
                              pertanyaan tersebut
                            </Text>
                          </Space>
                        </Col>
                      </Card>
                    </Col>
                    <Col className="text-align-center" span={24}>
                      <Image
                        alt="example"
                        src={arrQuestion.problemsImgs}
                        width={"100%"}
                        preview={false}
                      />
                    </Col>

                    {arrQuestion.questions.map((item, i) => (
                      <Col key={i} span={24}>
                        <Space className="width-100" align="start">
                          <Text>{i + 56 + 1}.</Text>
                          <Space className="width-100" direction="vertical">
                            <Space
                              className="width-100"
                              direction="vertical"
                              size={0}
                            >
                              <Text
                                className="noselect dark-color"
                                style={{ fontSize: 15, fontWeight: "normal" }}
                              >
                                {item.question}
                              </Text>
                            </Space>
                            <Radio.Group
                              className="width-100"
                              value={
                                result?.find((item) => item.no === i + 56 + 1)
                                  ?.selected
                              }
                              onChange={(e) => handleSelect(e, i + 56 + 1)}
                            >
                              <Space className="width-100" direction="vertical">
                                {item.answers.map((res, iAnswer) => (
                                  <Radio
                                    className="noselect"
                                    key={iAnswer}
                                    result={res.value}
                                    value={iAnswer}
                                    style={{
                                      whiteSpace: "break-spaces",
                                      display: "flex",
                                      alignItems: "center"
                                    }}
                                  >
                                    {res.answerLabel}
                                  </Radio>
                                ))}
                              </Space>
                            </Radio.Group>
                          </Space>
                        </Space>
                      </Col>
                    ))}
                  </Space>
                </Col>
                <Col span={24}>
                  <Space className="float-right">
                    <CButton
                      type="link"
                      size="large"
                      action={() => handleBack(3, 4)}
                      icon="LeftOutlined"
                      title={t("pathway.back")}
                    />
                    <CButton
                      type="primary-icon"
                      size="large"
                      action={() => handleNext(3, 6, result?.length >= 61)}
                      title={t("pathway.next")}
                      icon="RightOutlined"
                    />
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withTranslation()(S3Q57_61);
