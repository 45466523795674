import React from 'react';
import { Row, Col, Typography, Image, Space, Grid } from 'antd';
import { withTranslation } from 'react-i18next';
import jobseeker from '../../assets/img/international/jobseeker.png';
import HandIcon from '../../assets/img/international/HandIcon.png';
import CheckIcon from '../../assets/img/international/CheckIcon.png';
import FreeIcon from '../../assets/img/international/FreeIcon.png';

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export function jobIndustry(props) {
  const { md, sm } = GetBreakPoint()
  const { t } = props

  return (
    <React.Fragment>
      <Row gutter={32} style={ md ? { background:  '#fff', paddingBottom: 32 } : { background: '#fff', paddingTop: 8, paddingBottom: 32 } }>
        <Col xs={24} md={12} className="width-100">
          <Image
            src={jobseeker}
            preview={false}
            width={'100%'}
          />
        </Col>
        {
          md || sm ?
            <React.Fragment>
              <Col span={8} className="width-100">
                <Title className="primary-color" level={2} style={{ width: '70%', fontSize: sm ? 26 : 34 }}>{t('jobInternasional.title1')}</Title>
                <Space direction="vertical" size={16}>
                  <Space direction="vertical" size={0}>
                    <Text className="fs-16 dark-color">{t('jobInternasional.title2')}</Text>
                    <Text className="fs-16 dark-color">{t('jobInternasional.title3')}</Text>
                  </Space>
                  <Text className="fs-16 dark-color">{t('jobInternasional.title4')}</Text>
                  <Space className="width-100" direction="vertical">
                    <Space className="width-100">
                      <Image
                        src={HandIcon}
                        preview={false}
                        width={'100%'}
                      />
                      <Text className="fs-16 dark-color">{t('jobInternasional.title5')}</Text>
                    </Space>
                    <Space className="width-100">
                      <Image
                        src={CheckIcon}
                        preview={false}
                        width={'100%'}
                      />
                      <Text className="fs-16 dark-color">{t('jobInternasional.title6')}</Text>
                    </Space>
                    <Space className="width-100">
                      <Image
                        src={FreeIcon}
                        preview={false}
                        width={'100%'}
                      />
                      <Text className="fs-16 dark-color">{t('jobInternasional.title7')}</Text>
                    </Space>
                  </Space>
                </Space>
              </Col>
              <Col span={4}></Col>
            </React.Fragment>
          :
            <Row className="width-100" style={{ paddingTop: 8 }}>
              <Col span={20} offset={2}>
                <Title className="primary-color" level={2}>{t('jobInternasional.title1')}</Title>
              </Col>
              <Col span={20} offset={2}>
                <Space direction="vertical" size={0}>
                  <Text className="fs-16 dark-color">{t('jobInternasional.title2')}</Text>
                  <Text className="fs-16 dark-color">{t('jobInternasional.title3')}</Text>
                </Space>
              </Col>
              <Col className="mb-16" span={20} offset={2}>
                <Text className="fs-16 dark-color">{t('jobInternasional.title4')}</Text>
              </Col>
              <Col className="text-align-center mb-16" span={20} offset={2}>
                <Space className="width-100" direction="vertical">
                  <Image
                    src={HandIcon}
                    preview={false}
                    width={'20%'}
                  />
                  <Text className="fs-16 dark-color">{t('jobInternasional.title5')}</Text>
                </Space>
              </Col>
              <Col className="text-align-center mb-16" span={20} offset={2}>
                <Space className="width-100" direction="vertical">
                  <Image
                    src={CheckIcon}
                    preview={false}
                    width={'20%'}
                  />
                  <Text className="fs-16 dark-color">{t('jobInternasional.title6')}</Text>
                </Space>
              </Col>
              <Col className="text-align-center" span={20} offset={2}>
                <Space className="width-100" direction="vertical">
                  <Image
                    src={FreeIcon}
                    preview={false}
                    width={'20%'}
                  />
                  <Text className="fs-16 dark-color">{t('jobInternasional.title7')}</Text>
                </Space>
              </Col>
            </Row>
        }
      </Row>
    </React.Fragment>
  )
}

export default withTranslation()(jobIndustry)
