const LOAD_DATA_MASTER_VILLAGE         = 'LOAD_DATA_MASTER_VILLAGE'
const LOAD_DATA_MASTER_VILLAGE_SUCCESS = 'LOAD_DATA_MASTER_VILLAGE_SUCCESS'
const LOAD_DATA_MASTER_VILLAGE_FAILED  = 'LOAD_DATA_MASTER_VILLAGE_FAILED'
const UNMOUNT_DATA_MASTER_VILLAGE      = 'UNMOUNT_DATA_MASTER_VILLAGE'

const initialState = {
  loading: true,
  data: null,
  message: null,
  meta: null
}

const villageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DATA_MASTER_VILLAGE:
      return {
        ...state,
        loading: true,
        data: null,
        meta: null
      }
    case LOAD_DATA_MASTER_VILLAGE_SUCCESS:
      return {
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
        meta: action.payload.meta
      }
    case LOAD_DATA_MASTER_VILLAGE_FAILED:
      return {
        ...state, 
        loading: false,
        data: null,
        message: 'FAILED',
        meta: null
      }
    case UNMOUNT_DATA_MASTER_VILLAGE:
      return { 
        ...state, 
        loading: true,
        data: null,
        meta: null
      }
    default:
      return state
  }
}
export default villageReducer