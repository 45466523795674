import React from 'react'
import { withTranslation } from 'react-i18next';
import { Row, Col, Grid, Space, Typography, Menu, Card, Form, Input, Tooltip, Collapse } from 'antd';
import { UndoOutlined, BulbOutlined, AlertOutlined, FileDoneOutlined, ShoppingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import CButton from '../../../../components/Button';

const { Text } = Typography;
const { TextArea } = Input;
const { Panel } = Collapse;

const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export function resultInterest(props) {
  const { xs } = GetBreakPoint()
  const { t, getProfile, getPathway, handleButtonFeedback, isFeedback, onFinish, submitLoading, handleClickMenu, isActiveProgram } = props

  const pathwayResult = getPathway.data.listPathwayForm[isActiveProgram]

  return (
    <React.Fragment>
      <Row className="mb-16">
        <Space className="text-align-center width-100 mb-32" direction="vertical">
          <Text className="info-color" style={{ fontSize: 24, fontWeight: 'bold' }}>
          {t('pathway.yourPathway')}
          </Text>
        </Space>
        {
          xs ?
            <Collapse className="width-100" defaultActiveKey={['0']} expandIconPosition="right" onChange={handleClickMenu} ghost accordion>
              {
                getPathway.data.listPathwayForm.map((item, i) =>
                  <Panel
                    header={
                      item.program === 'STUDY' ?
                        <Text className="info-color fs-18">
                          <BulbOutlined /> {t('pathway.studyPrograms')}
                        </Text>
                        : item.program === 'NIGHT_CLASS' ?
                          <Text className="info-color fs-18">
                            <AlertOutlined /> {t('pathway.nightClassPrograms')}
                          </Text>
                          : item.program === 'BOOTCAMP' ?
                            <Text className="info-color fs-18">
                              <FileDoneOutlined /> {t('pathway.bootcampIntensivePrograms')}
                            </Text>
                            : item.program === 'INTERNSHIP' ?
                              <Text className="info-color fs-18">
                                <ShoppingOutlined /> {t('pathway.internshipPrograms')}
                              </Text>
                              : item.program === 'READY_WORK' ?
                                <Text className="info-color fs-18">
                                  <AlertOutlined /> {t('pathway.gettingReadyForWork')}
                                </Text>
                                :
                                null
                    }
                    key={i}
                  >
                    <Space direction="vertical" size={4}>
                      <Text className="dark-color" style={{ fontSize: xs ? 16 : 20, fontWeight: 'normal' }}>
                        {
                          pathwayResult?.program === 'STUDY' ?
                            t('pathway.studyPrograms')
                            : pathwayResult?.program === 'NIGHT_CLASS' ?
                              t('pathway.nightClassPrograms')
                              : pathwayResult?.program === 'BOOTCAMP' ?
                                t('pathway.bootcampIntensivePrograms')
                                : pathwayResult?.program === 'INTERNSHIP' ?
                                  t('pathway.internshipPrograms')
                                  : pathwayResult?.program === 'READY_WORK' ?
                                    t('pathway.gettingReadyForWork')
                                    :
                                    null
                        }
                        <Tooltip placement="topLeft" trigger="click" title={t('pathway.titleTooltip')}>
                          <InfoCircleOutlined style={{ fontSize: xs ? 14 : 16 }} />
                        </Tooltip>
                      </Text>
                      <Text className="dark-color" style={{ fontSize: xs ? 14 : 16, fontWeight: 'normal' }}>
                        {pathwayResult?.title}
                      </Text>
                      <Text className="dark-color" style={{ fontSize: xs ? 14 : 16, fontWeight: 'normal' }}>
                        {pathwayResult?.note}
                      </Text>
                      {
                        pathwayResult?.attachmentUrl ?
                          <a key={pathwayResult?.attachmentUrl} href={pathwayResult?.attachmentUrl}>
                            <CButton type="primary" title={t('pathway.downloadAttachment')}/>
                          </a>
                          :
                          null
                      }
                    </Space>
                  </Panel>
                )
              }
            </Collapse>
          :
            <React.Fragment>
              <Col span={8}>
                <Menu
                  defaultSelectedKeys={['0']}
                  mode='inline'
                  style={{ backgroundColor: 'transparent' }}
                >
                  {
                    getPathway.data.listPathwayForm.map((item, i) =>
                      <Menu.Item
                        key={i}
                        icon={
                          item.program === 'STUDY' ?
                            <BulbOutlined />
                          : item.program === 'NIGHT_CLASS' ?
                            <AlertOutlined />
                          : item.program === 'BOOTCAMP' ?
                            <FileDoneOutlined />
                          : item.program === 'INTERNSHIP' ?
                            <ShoppingOutlined />
                          : item.program === 'READY_WORK' ?
                            <AlertOutlined />
                          :
                            null
                        }
                        onClick={handleClickMenu}
                      >
                        {
                          item.program === 'STUDY' ?
                            t('pathway.studyPrograms')
                          : item.program === 'NIGHT_CLASS' ?
                            t('pathway.nightClassPrograms')
                          : item.program === 'BOOTCAMP' ?
                            t('pathway.bootcampIntensivePrograms')
                          : item.program === 'INTERNSHIP' ?
                            t('pathway.internshipPrograms')
                          : item.program === 'READY_WORK' ?
                            t('pathway.gettingReadyForWork')
                          :
                            null
                        }
                      </Menu.Item>
                    )
                  }
                </Menu>
              </Col>
              <Col span={1}></Col>
              <Col span={15}>
                <Space direction="vertical" size={8}>
                  <Space>
                    <Text className="info-color" style={{ fontSize: 20, fontWeight: 'normal' }}>
                      {
                        pathwayResult?.program === 'STUDY' ?
                          t('pathway.studyPrograms')
                          : pathwayResult?.program === 'NIGHT_CLASS' ?
                            t('pathway.nightClassPrograms')
                            : pathwayResult?.program === 'BOOTCAMP' ?
                              t('pathway.bootcampIntensivePrograms')
                              : pathwayResult?.program === 'INTERNSHIP' ?
                                t('pathway.internshipPrograms')
                                : pathwayResult?.program === 'READY_WORK' ?
                                  t('pathway.gettingReadyForWork')
                                  :
                                  null
                      }
                    </Text>
                    <Tooltip placement="topLeft" trigger="click" title={t('pathway.titleTooltip')}>
                      <InfoCircleOutlined className="info-color" style={{ fontSize: 14 }} />
                    </Tooltip>
                  </Space>
                  <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal' }}>
                    {pathwayResult?.title}
                  </Text>
                  <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal' }}>
                    {pathwayResult?.note}
                  </Text>
                  {
                    pathwayResult?.attachmentUrl ?
                      <a key={pathwayResult?.attachmentUrl} href={pathwayResult?.attachmentUrl}>
                        <CButton type="primary" title={t('pathway.downloadAttachment')}/>
                      </a>
                      :
                      null
                  }
                </Space>
              </Col>
            </React.Fragment>
        }
      </Row>

      <Row>
        <Col span={24}>
          <Card className="border-radius-6" style={{ border: '1px solid #d3d3d3' }}>
            <Space className="width-100 mb-16" direction="vertical" size={xs ? 0 : 8}>
              <Text className="info-color" style={{ fontSize: xs ? 16 : 20, fontWeight: xs ? 'bold' : 'normal' }}>
                {t('pathway.thankyou')} {getProfile.data.firstName.charAt(0).toUpperCase() + getProfile.data.firstName.slice(1)}, {t('pathway.title5')}
              </Text>
              {
                !isFeedback ?
                  <Text style={{ fontSize: 16, fontWeight: 'normal' }}>
                    {
                      getPathway.data.feedback ?
                        t('pathway.titleFeedback')
                        :
                        t('pathway.titleFeedback1')
                    }
                  </Text>
                  :
                  null
              }
              <Text style={{ fontSize: 16, fontWeight: 'bold', fontStyle: 'italic' }}>
                {getPathway.data.feedback}
              </Text>
            </Space>
            {
              !isFeedback && getPathway.data.feedback ?
                <Text className="primary-color" onClick={handleButtonFeedback} style={{ fontSize: 16, fontWeight: 'normal', cursor: 'pointer' }}><UndoOutlined /> {t('pathway.re-sendFeedback')}</Text>
                :
                null
            }
            {
              isFeedback ?
                <Form layout="vertical" onFinish={onFinish}>
                  <Form.Item
                    name="feedback"
                    label={t('pathway.feedbackToUs')} 
                    validateFirst
                    initialValue={isFeedback ? getPathway.data.feedback : null}
                    rules={[
                      { required: true, message: 'This field is mandatory!' },
                      { min: 4, message: 'feedback must be 4 characters long' },
                      { max: 500, message: '500 characters only' },
                    ]}
                  >
                    <TextArea rows={3} />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <CButton className="float-right" style={{ width: xs ? '25%' : '10%', backgroundColor: '#4272b9', border: '1px solid #4272b9' }} type="primary" htmlType="submit" loading={submitLoading} title={t('pathway.send')}/>
                  </Form.Item>
                </Form>
                :
                null
            }
            {
              !isFeedback && !getPathway.data.feedback ?
                <CButton action={handleButtonFeedback} type="save" title={t('pathway.sendFeedback')}/>
                :
                null
            }
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withTranslation()(resultInterest)