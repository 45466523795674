import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Loading } from '../../../components';
import Cookie from 'js-cookie';
import { withTranslation } from 'react-i18next';
import { indexJobIndustries, unmountIndexJobIndustries } from '../../../redux/actions/master/jobIndustries/jobIndustriesAction';
import { unmountIndexJobDestination } from '../../../redux/actions/master/jobDestination/jobDestinationAction';
import { checkToken } from '../../../redux/actions/auth/authAction'
import { Row, Col, Card, Image, Typography, Space, Layout, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import Header from '../Header';
import Footer from '../Footer';
import '../JobPreferences.css';

const { Title, Text } = Typography;

export class Industries extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      firstName: Cookie.get('firstName'),
      industriesSelected: localStorage.getItem("industriesSelected") ? localStorage.getItem("industriesSelected").split(',').map(x=>+x) : []
    }
  }
  
  componentDidMount() {
    const { actionCheckToken, actionGetJobIndustries } = this.props

    actionCheckToken((response) => {
      if(response.code === "1000"){
        actionGetJobIndustries()
      }
    })
  }

  onSelectIndustries = (id) => {
    const { t } = this.props
    const { industriesSelected } = this.state
    const filterSelected = industriesSelected.filter(item => item === id)
    const indexOfSelected = industriesSelected.indexOf(id)
    if(filterSelected.length === 0){
      if(industriesSelected.length < 3){
        industriesSelected.push(id)
      }else{
        message.error(t('jobPreferences.industries.msg'))
      }
    }else{
      industriesSelected.splice(indexOfSelected,1)
    }

    this.setState({ industriesSelected })
  }

  render() {
    const { getJobIndustries: { data, loading }, t } = this.props
    const { firstName, industriesSelected } = this.state
    
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Job Preferences - Job Industries');
    }
    
    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Job Preferences - Job Industries</title>
        </Helmet>
        <Layout>
          <Header />
          <Row className="display-inline margin-16 mt-32 bg-color-light">
            <Col className="pb-100 mt-64" xs={24} sm={24} md={{ span: 22, offset: 1 }}>
              <Space className="width-100 mb-32 text-align-center" direction="vertical" size="small">
                <Title className="dark-color font-weight-normal" level={3}>{t('jobPreferences.industries.title')} {firstName}</Title>
                <Text className="dark-color font-weight-normal">{t('jobPreferences.industries.subTitle')}</Text>
                <Text className="dark-color font-weight-normal">{t('jobPreferences.industries.subTitle2')}</Text>
              </Space>
              <Row gutter={[16, 16]}>
                {
                  data?.map((item, i) => 
                    <Col key={i} xs={24} sm={24} md={8}>
                      <Card
                        className={ 
                          industriesSelected.length === 0 ? 
                            "width-100 border-radius-6"
                          :
                            industriesSelected.map((value) => 
                              item.id === value ? "width-100 border-radius-6 card-selected" : "width-100 border-radius-6"
                            )}
                        hoverable
                        bodyStyle={{ padding: 12 }}
                        onClick={() => this.onSelectIndustries(item.id)}
                      >
                        <Row justify="space-around" align="middle">
                          <Col span={22}>
                            <Space size="middle">
                              <Image
                                className="pt-6"
                                width={40}
                                height={40}
                                src={item.iconUrl}
                                preview={false}
                              />
                              <Text className="fs-11">{item.title}</Text>
                            </Space>
                          </Col>
                          <Col span={2}>
                            {
                                industriesSelected.map((value, i) => 
                                  item.id === value ? <CheckOutlined key={i} className="primary-color" /> : null
                                )
                            }
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  )
                }
              </Row>
            </Col>
          </Row>
          <Footer industriesSelected={industriesSelected} />
        </Layout>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountIndexJobIndustries, unmountIndexJobDestination } = this.props
    return (unmountIndexJobIndustries(), unmountIndexJobDestination())
  }
}

const mapStateToProps = (state) => ({
  getJobIndustries: state.jobIndustriesReducer
})

const mapDispatchToProps = {
  actionCheckToken: checkToken,
  actionGetJobIndustries: indexJobIndustries,
  unmountIndexJobIndustries: unmountIndexJobIndustries,
  unmountIndexJobDestination: unmountIndexJobDestination
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Industries))
