import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImgCrop from 'antd-img-crop';
import { Row, Col, Avatar, Image, Space, Upload, Spin, Grid, Button, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './Header.css';
import Email from './Email';
import Name from './Name';
import MobileNumber from './MobileNumber';
import Location from './Location';
import SocialMedia from './SocialMedia';
import { uploadAvatar } from '../../../../redux/actions/profile/headerAction';
import { indexHeader } from '../../../../redux/actions/header/headerAction';
import { CameraOutlined } from '@ant-design/icons';
// import CButton from '../../../../components/Button';

const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export const HeaderComponent = (props) => {
  const { data, actionGetProfile } = props
  const { xs } = GetBreakPoint()
  return(
    <Col xs={24} md={6} lg={8} xxl={7} className="pt-16" style={xs ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : null}>
      <Space direction="vertical" size={2} style={xs ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : null}>
        <Name data={data} actionGetProfile={actionGetProfile} />
        <Email data={data} actionGetProfile={actionGetProfile} />
        <MobileNumber data={data} actionGetProfile={actionGetProfile} />
        <Location data={data} actionGetProfile={actionGetProfile} />
      </Space>
    </Col>
  )
}

export class Header extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false
    }
  }
  
  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:".png,.jpg,.jpeg",
      beforeUpload: file => {
        const validateSize = file.size >= 3000000;
        if (validateSize) {
          message.error('Max file size is 3 MB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const { actionUploadAvatar, actionGetProfile, actionGetHeader } = this.props
          const value = {
            profilePicFile: file
          }
          return this.setState({ submitLoading: true }, () => {
            return actionUploadAvatar(value, response => {
              if(response.code === '1000'){
                return this.setState({ submitLoading: false, visible: false }, () => {
                  message.success(response.message)
                  actionGetHeader()
                  actionGetProfile()
                })
              }else{
                message.error(response.message)
              }
            }, (err) => {
              return this.setState({ submitLoading: false, visible: false }, () => message.error(err.message))
            })
          })
        }
        return false;
      },
    }
  }

  render() {
    const { submitLoading } = this.state
    const { data, actionGetProfile } = this.props

    return (
      <React.Fragment>
        <Row className="mb-16 pt-16 pb-16" style={{ backgroundColor: '#ffffff' }}>
          <Col span={22} offset={1}>
            <Row gutter={32}>
              <Col xs={24} md={6} lg={4} xxl={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {
                  submitLoading ?
                    <Spin />
                  :
                    <React.Fragment>
                      {
                        data.profilePicUrl ?
                          <Image
                            className="img-avatar"
                            preview={{
                              maskClassName: 'img-avatar'
                            }}
                            width={140}
                            src={data.profilePicUrl} 
                          />
                        :
                          <Avatar size={140} icon={<UserOutlined />} />
                      }
                      <ImgCrop shape="round" rotate={true}>
                        <Upload {...this.handleUpload()} multiple={false} showUploadList={false}>
                          <Button type="primary" size="large" shape="circle" style={{ position: 'absolute', marginTop: 20, marginLeft: -40 }}><CameraOutlined/></Button>
                        </Upload>
                      </ImgCrop>
                    </React.Fragment>
                }
              </Col>
              <HeaderComponent {...this.props} />
              <Col className='width-100 text-align-center' xs={24} md={12} lg={12} xxl={12}>
                <SocialMedia data={data} actionGetProfile={actionGetProfile} />
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
  actionUploadAvatar: uploadAvatar,
  actionGetHeader: indexHeader,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)