import { API } from '../../../config'

export const addLanguage = (value, successCB, failedCB) => async dispatch => {
  API.POST('/candidate/language/add', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const deleteLanguage = (value, successCB, failedCB) => async dispatch => {
  const params = { 
    id: value
  }
  API.POST_WITH_PARAMS('/candidate/language/delete', params).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}