import React from 'react';
import { Row, Col, Space, Typography, Form, Input, Radio, Grid } from 'antd';
import CButton from '../../../../components/Button';
const { Text } = Typography
const { useBreakpoint } = Grid;
function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function Form2(props) {
  const { xs } = GetBreakPoint();
  const { formRef, form2, f2, handleRadioActivity, handlePrev, onFinish } = props
  return (
    <React.Fragment>
      <Row className="text-align-center mb-16 mt-16">
        <Col span={24}>
          <Text className="primary-color fs-17">Keaktifan Mencari Pekerjaan</Text>
        </Col>
      </Row>

      <Form 
        ref={formRef}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          f1001: f2?.f1001 ? f2.f1001 : form2.activity,
          f1002: f2?.f1002 ? f2.f1002 : null
        }}
      >
        <Row className="mb-16">
          <Col span={24}>
            <Form.Item 
              name="f1001"
              label="Apakah kamu aktif mencari pekerjaan dalam 4 minggu terakhir?"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <Radio.Group className="width-100" onChange={handleRadioActivity}>
                <Space direction="vertical">
                  <Radio key="1" value="1" style={xs?{ display: 'flex', whiteSpace: 'break-spaces'}:null}>Tidak</Radio>
                  <Radio key="2" value="2" style={xs?{ display: 'flex', whiteSpace: 'break-spaces'}:null}>Tidak, tapi saya sedang menunggu hasil lamaran kerja</Radio>
                  <Radio key="3" value="3" style={xs?{ display: 'flex', whiteSpace: 'break-spaces'}:null}>Ya, saya akan mulai bekerja dalam 2 minggu ke depan</Radio>
                  <Radio key="4" value="4" style={xs?{ display: 'flex', whiteSpace: 'break-spaces'}:null}>Ya, tapi saya belum pasti akan bekerja dalam 2 minggu ke depan</Radio>
                  <Radio key="5" value="5" style={xs?{ display: 'flex', whiteSpace: 'break-spaces'}:null}>Lainnya</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>

            {
              form2.activity === "5" ?
                <Form.Item 
                  name="f1002"
                  label="sebutkan"
                  validateFirst
                  rules={[
                    { required: true, message: 'This field is mandatory!' },
                    { min: 3, message: 'At least 3 characters' },
                    { max: 50, message: '50 characters only' },
                  ]}
                >
                  <Input />
                </Form.Item>
              : null
            }
          </Col>
        </Row>
        <Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <CButton className="width-100" type="primary" ghost action={handlePrev} title="Sebelumnya"/>
            </Col>
            <Col span={12}>
              <CButton className="width-100" htmlType="submit" type="primary" title="Selanjutnya"/>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </React.Fragment>
  )
}
