import React from 'react'
import { Link } from 'react-router-dom'
import Cookie from 'js-cookie';
import { withTranslation } from 'react-i18next';
import { Row, Col, Grid, Space, Typography, Form, AutoComplete, Button } from 'antd';
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';
import Background from '../../../assets/img/xpert/background.png'
import MBackground from '../../../assets/img/domestic/m-bg.png'

const { useBreakpoint } = Grid;
const { Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export function Banner(props) {
  const { xs, lg, xl, xxl } = GetBreakPoint()
  const { t } = props
   
  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          <ParallaxProvider>
            <ParallaxBanner 
              layers={[
                { 
                  image: xs ? MBackground : Background, 
                  amount: xxl ? 0.3 : 0.4,
                  props: {
                    style: {
                      marginBottom: xl ? 40 : 0
                    },
                  }
                }
              ]} 
              style={{ width: '100%', height: xs ? 450 : xxl ? 600 : xl ? 400 : 300 , boxShadow: '1px 4px 8px #e6e6e6' }}
            >
              <Row>
                <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                  {
                    lg ?
                      <Row justify="space-around" align="middle" style={{ height: xs ? 450 : xxl ? 600 : xl ? 400 : 300 }}>
                        <Col md={11} xxl={13}>
                          <Space className="text-align-center mb-32" direction='vertical' size={8}>
                            <Text>
                              <Text className="primary-color" style={{ fontSize: xxl ? 60 : 40, fontWeight: 'bold', lineHeight: 1.2 }}>Jobshub  </Text>
                              <Text className="info-color" style={{ fontSize: xxl ? 60 : 40, fontWeight: 'bold', lineHeight: 1.2 }}>Xpert</Text>
                            </Text>
                            <Text className="dark-color" style={{ fontSize: xxl ? 16 : 14, fontWeight: 'normal', lineHeight: 1.2 }}>{t('xpert.title1')}</Text>
                          </Space>
                          {/* <Form ref={formRef} onFinish={onFinish} layout="vertical"> */}
                          <Form layout="vertical">
                            <Row gutter={16}>
                              <Col xs={24} md={18} style={{ marginBottom: -16 }}>
                                <Form.Item name="search">
                                  <AutoComplete
                                    className="width-100"
                                    // onSearch={onSearchJobPosition}
                                    // notFoundContent={loading ? <Spin size="small" /> : null}
                                    filterOption={false}
                                    size="large"
                                  >
                                    {/* {
                                      listJobPosition?.data?.map((item, i) => (
                                        <AutoComplete.Option key={i} value={item.title}>
                                          {item.title}
                                        </AutoComplete.Option>
                                      ))
                                    } */}
                                  </AutoComplete>
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={6}>
                                <Form.Item>
                                  <Link to={Cookie.get('user') ? '/candidate/xpert/list' : '/xpert/list'}>
                                    {/* <Button htmlType="submit" type="primary" style={{ width: '100%' }} size="large">Search</Button> */}
                                    <Button type="primary" style={{ width: '100%' }} size="large">{t('xpert.button')}</Button>
                                  </Link>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    :
                      null
                  }
                </Col>
              </Row>
            </ParallaxBanner>
          </ParallaxProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withTranslation()(Banner)