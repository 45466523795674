import React from 'react';
import { Row, Col, Space, Typography, Form, Radio } from 'antd';
import CButton from '../../../../components/Button';
const { Text } = Typography

export default function Form4(props) {
  const { formRef, form4, f4, handleRadioStatus, handlePrev, onFinish } = props
  return (
    <React.Fragment>
      <Row className="text-align-center mb-16 mt-16">
        <Col span={24}>
          <Text className="primary-color fs-17">Status Saat Ini</Text>
        </Col>
      </Row>

      <Form 
        ref={formRef}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          status: f4?.status ? f4.status : form4.status
        }}
      >
        <Row className="mb-16">
          <Col span={24}>
            <Form.Item 
              name="status"
              label="Apakah status kamu saat ini"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <Radio.Group onChange={handleRadioStatus}>
                <Space direction="vertical">
                  <Radio key="1" value="1">Bekerja (full time/part time)</Radio>
                  <Radio key="2" value="2">Wiraswasta</Radio>
                  <Radio key="3" value="3">Melanjutkan Pendidikan</Radio>
                  <Radio key="4" value="4">Menikah</Radio>
                  <Radio key="5" value="5">Sibuk dengan keluarga dan anak-anak</Radio>
                  <Radio key="6" value="6">Tidak Kerja tetapi sedang mencari kerja</Radio>
                  <Radio key="7" value="7">Belum memungkinkan bekerja</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <CButton className="width-100" type="primary" ghost action={handlePrev} title="Sebelumnya"/>
            </Col>
            <Col span={12}>
              <CButton className="width-100" htmlType="submit" type="primary" title="Selanjutnya"/>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </React.Fragment>
  )
}
