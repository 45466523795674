import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { withTranslation } from 'react-i18next';
import { Card, Typography, Modal, Select, Space, Form, Row, Col, List, AutoComplete, Spin, message } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import { addSkill, deleteSkill } from '../../../../redux/actions/profile/skillAction';
import { listSkill, unmountListSkill } from '../../../../redux/actions/master/skills/skillsAction';
import Cookie from "js-cookie";
import CButton from '../../../../components/Button';

const { Text } = Typography;
const { Option } = Select;

export class Skill extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
       visible: false,
       submitLoading: false,
       deleteLoading: false,
       metaSkill: {
         page: 1,
         perpage: 10,
         search: ''
       }
    }
    this.onSearchSkill = debounce(this.onSearchSkill.bind(this), 800)
  }

  onSearchSkill = value => {
    const { metaSkill } = this.state;
    const { actionListSkill } = this.props;

    metaSkill.page = 1
    metaSkill.perpage = 10
    metaSkill.search = value

    return actionListSkill(metaSkill)
  }

  onFocusSkill = () => {
    const { metaSkill } = this.state;
    const { actionListSkill } = this.props;

    return actionListSkill(metaSkill)
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false
    })
  };

  handleDelete = (id) => {
    const { actionDeleteSkill, actionGetProfile } = this.props
    
    return this.setState({ deleteLoading: true }, () => {
      return actionDeleteSkill(id, response => {
        if(response.code === '1000'){
          return this.setState({ deleteLoading: false }, () => {
            message.success(response.message)
            Cookie.set("activeElement", window.pageYOffset)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ deleteLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  onFinish = async (values) => {
    const { actionAddSkill, actionGetProfile } = this.props

    return this.setState({ submitLoading: true }, () => {
      return actionAddSkill(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
      })
    })
  }
  
  render() {
    const { visible, submitLoading, deleteLoading } = this.state
    const { t, getSkill, data: { listSkill } } = this.props

    return (
      <React.Fragment>
        <Card
          className="border-radius-6 box-shadow" 
          title={t('profile.skills.title')}
          headStyle={{ color: '#32ab6d', fontSize: 17 }}
          bodyStyle={listSkill.length > 0 ? {} : { textAlign: 'center', padding: 60 }}
          actions={[
            <Text className="primary-color" onClick={this.showModal}>{t('profile.skills.button')}</Text>
          ]}
        >
          {
            listSkill.length > 0 ?
              <Space direction="vertical">
                {
                  listSkill.map((item, i) => (
                    <Space key={i} direction="vertical" size={-8}>
                      <Text className="dark-color">{item.name}</Text>
                      <Text className="primary-color fs-11 font-style-italic">{item.skillLevel}</Text>
                    </Space> 
                  ))
                }
              </Space>
            :
            <Text className="dark-color">{t('profile.skills.textCard')}</Text>
          }
        </Card>

        <Modal 
          title={t('profile.skills.title')} 
          visible={visible} 
          onCancel={this.handleCancel} 
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          centered
          width={700}
          destroyOnClose
          maskClosable={false}
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
            initialValues={{
              skillLevel: 'BASIC'
            }}
          >
            <Row gutter={8}>
              <Col xs={12} lg={12}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">{t('profile.skills.form.skill')}</Text>
                </Space>
              </Col>
              <Col xs={12} lg={8}>
                <Text className="fs-12" type="secondary">{t('profile.skills.form.skillLevel')}</Text>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col xs={12} lg={12}>
                <Form.Item 
                  name="name"
                  validateFirst
                  rules={[
                    { required: true, message: t('required') },
                    { max: 150, message: t('max',{number:150}) },
                  ]}
                >
                  <AutoComplete
                    className="width-100"
                    showSearch
                    onFocus={this.onFocusSkill}
                    onSearch={this.onSearchSkill}
                    placeholder={t('profile.skills.form.placehoderSkill')}
                    notFoundContent={getSkill.loading ? <Spin size="small"/> : null}
                  >
                    {
                      getSkill?.data?.map((res) => (
                        <Option key={res.id} value={res.name}>
                          {res.name}
                        </Option>
                      ))
                    }
                  </AutoComplete>
                </Form.Item>
              </Col>
              <Col xs={12} lg={8}>
                <Form.Item name="skillLevel">
                  <Select 
                    className="width-100 border-radius-6"
                  >
                    <Option key="1" value="BASIC">Basic</Option>
                    <Option key="2" value="INTERMEDIATE">Intermediate</Option>
                    <Option key="3" value="ADVANCE">Advance</Option>
                    <Option key="4" value="EXPERT">Expert</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <CButton type="primary" className="width-100" htmlType="submit" loading={submitLoading} title={t('profile.skills.form.button')}/>
              </Col>
            </Row>
            <Row className="mb-16">
              <Col span={24}>
                <List
                  bordered={false}
                  dataSource={listSkill}
                  loading={deleteLoading}
                  renderItem={(item, i) => (
                    <List.Item
                      key={i}
                      className="dark-color"
                      actions={[<DeleteTwoTone className="cursor-pointer" twoToneColor="#ff4646" onClick={() => this.handleDelete(item.id)} />]}
                    >
                      {`${i+1}. ${item.name}`}
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountListSkill } = this.props
    return unmountListSkill()
  }
}

const mapStateToProps = (state) => ({
  getSkill: state.skillsReducer
})

const mapDispatchToProps = {
  actionListSkill: listSkill,
  unmountListSkill: unmountListSkill,
  actionAddSkill: addSkill,
  actionDeleteSkill: deleteSkill
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Skill))
