import React from 'react'
// import { Row, Col, Grid, Space, Typography, Card, Image } from 'antd';
import { Row, Col, Grid } from 'antd';
// import SmartphoneJobshub from '../../assets/img/general/smartphone-jobshub.png';
// import PlayStore from '../../assets/img/general/play-store.png';
// import AppStore from '../../assets/img/general/app-store.png';

const { useBreakpoint } = Grid;
// const { Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function option() {
  const { md } = GetBreakPoint()

  return (
    <React.Fragment>
      <Row style={ md ? { backgroundColor: '#fff', paddingBottom: 64, paddingTop: 64 } : { backgroundColor: '#fff', paddingBottom: 8, paddingTop: 8 }}>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          {/* <Row>
            <Col span={20} offset={2}>
              <Card bordered={false} style={{ width: '100%', borderRadius: 16, boxShadow: '1px 1px 8px #cccccc', backgroundColor: '#4273b9' }} bodyStyle={{ padding: 0 }}>
                <Row>
                  <Col span={20} offset={2}>
                    <Row justify="space-around" align="middle">
                      <Col span={12}>
                        <Space direction="vertical" size={32}>
                          <Space direction="vertical" size={-4}>
                            <Text className="bg-color-light" style={{ fontSize: 18, fontWeight: 'bold' }}>Nikmati kemudahan mencari pekerjaan</Text>
                            <Text className="bg-color-light" style={{ fontSize: 18, fontWeight: 'bold' }}>berskala global dari genggamanmu</Text>
                          </Space>
                          <Space direction="vertical" size={16}>
                            <Text className="bg-color-light" style={{ fontSize: 14 }}>Download aplikasi jobshub sekarang juga!</Text>
                            <Space>
                              <Image
                                width={'100%'}
                                height={40}
                                src={PlayStore}
                                preview={false}
                                style={{ objectFit: 'contain' }}
                              />
                              <Image
                                width={'100%'}
                                height={40}
                                src={AppStore}
                                preview={false}
                                style={{ objectFit: 'contain' }}
                              />
                            </Space>
                          </Space>
                        </Space>
                      </Col>
                      <Col className="text-align-center" span={12}>
                        <Image
                          width={'80%'}
                          src={SmartphoneJobshub}
                          preview={false}
                          style={{ marginTop: 64, marginBottom: -6, objectFit: 'contain' }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row> */}
        </Col>
      </Row>
    </React.Fragment>
  )
}
