import { API } from '../../../config'
import { errorHandler } from '../auth/errorAction'

export const unmountDataCompany = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_COMPANY'})
}


export const dataCompany = (id, companyType) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_COMPANY' })
  const params = {
    'id': id,
    'companyType': companyType
  }
  return API.GET('/candidate/getCompanyProfile', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_COMPANY_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_COMPANY_FAILED' }), 
    ))
  })
}