import React from 'react';
import { Row, Col, Space, Typography, Form, Slider } from 'antd';
import { FrownOutlined, SmileOutlined } from '@ant-design/icons';
import CButton from '../../../../components/Button';
const { Text } = Typography

export default function Form7B(props) {
  const { formRef, f7b, handlePrev, onFinish } = props
  const formatter = (value) => {
    if(value === 1){
      return 'Sangat Rendah'
    }else if(value === 2){
      return 'Rendah'
    }else if(value === 3){
      return 'Cukup'
    }else if(value === 4){
      return 'Tinggi'
    }else if(value === 5){
      return 'Sangat Tinggi'
    }else{
      return null
    }
  }
  return (
    <React.Fragment>
      <Row className="text-align-center mb-16 mt-16">
        <Col span={24}>
          <Text className="primary-color fs-17">Kompetensi yang Diperlukan dalam Pekerjaan</Text>
        </Col>
      </Row>

      <Form 
        ref={formRef}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          f172b: f7b?.f172b ? f7b.f172b : null,
          f1724b: f7b?.f1724b ? f7b.f1724b : null,
          f176ba: f7b?.f176ba ? f7b.f176ba : null,
          f1728b: f7b?.f1728b ? f7b.f1728b : null,
          f1710b: f7b?.f1710b ? f7b.f1710b : null,
          f1730b: f7b?.f1730b ? f7b.f1730b : null,
          f1718b: f7b?.f1718b ? f7b.f1718b : null,
          f1732b: f7b?.f1732b ? f7b.f1732b : null,
          f1726b: f7b?.f1726b ? f7b.f1726b : null,
          f1734b: f7b?.f1734b ? f7b.f1734b : null,
          f1738ba: f7b?.f1738ba ? f7b.f1738ba : null,
          f1736b: f7b?.f1736b ? f7b.f1736b : null,
          f1754b: f7b?.f1754b ? f7b.f1754b : null,
          f1738b: f7b?.f1738b ? f7b.f1738b : null,
          f174b: f7b?.f174b ? f7b.f174b : null,
          f1740b: f7b?.f1740b ? f7b.f1740b : null,
          f176b: f7b?.f176b ? f7b.f176b : null,
          f1742b: f7b?.f1742b ? f7b.f1742b : null,
          f178b: f7b?.f178b ? f7b.f178b : null,
          f1744b: f7b?.f1744b ? f7b.f1744b : null,
          f1712b: f7b?.f1712b ? f7b.f1712b : null,
          f1746b: f7b?.f1746b ? f7b.f1746b : null,
          f1714b: f7b?.f1714b ? f7b.f1714b : null,
          f1748b: f7b?.f1748b ? f7b.f1748b : null,
          f1716b: f7b?.f1716b ? f7b.f1716b : null,
          f1750b: f7b?.f1750b ? f7b.f1750b : null,
          f1720b: f7b?.f1720b ? f7b.f1720b : null,
          f1752b: f7b?.f1752b ? f7b.f1752b : null,
          f1722b: f7b?.f1722b ? f7b.f1722b : null
        }}
      >
        <Row className="mb-16" gutter={16} justify="space-between" align="bottom">
          {/* A1 */}
          <Col lg={12} xs={24}>
            <Space size={4}>
              <Text type="danger">*</Text>
              <Text>Pengetahuan di bidang atau disiplin ilmu kamu</Text>
            </Space>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item 
                  name="f172b"
                  validateFirst
                  rules={[
                    { required: true, message: 'This field is mandatory!' },
                  ]}
                >
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* A2 */}
          <Col lg={12} xs={24}>
            <Text>Bekerja secara mandiri</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1724b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* B1 */}
          <Col lg={12} xs={24}>
            <Space size={4}>
              <Text type="danger">*</Text>
              <Text>Bahasa Inggris</Text>
            </Space>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item 
                  name="f176ba"
                  validateFirst
                  rules={[
                    { required: true, message: 'This field is mandatory!' },
                  ]}
                >
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* B2 */}
          <Col lg={12} xs={24}>
            <Text>Kemampuan dalam memecahkan masalah</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1728b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* C1 */}
          <Col lg={12} xs={24}>
            <Space size={4}>
              <Text type="danger">*</Text>
              <Text>Ketrampilan komputer</Text>
            </Space>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item 
                  name="f1710b"
                  validateFirst
                  rules={[
                    { required: true, message: 'This field is mandatory!' },
                  ]}
                >
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* C2 */}
          <Col lg={12} xs={24}>
            <Text>Negosiasi</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1730b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* D1 */}
          <Col lg={12} xs={24}>
            <Space size={4}>
              <Text type="danger">*</Text>
              <Text>Kemampuan Berkomunikasi</Text>
            </Space>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item 
                  name="f1718b"
                  validateFirst
                  rules={[
                    { required: true, message: 'This field is mandatory!' },
                  ]}
                >
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* D2 */}
          <Col lg={12} xs={24}>
            <Text>Kemampuan Analisis</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1732b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* E1 */}
          <Col lg={12} xs={24}>
            <Space size={4}>
              <Text type="danger">*</Text>
              <Text>Bekerja dalam tim/bekerjasama dengan orang lain</Text>
            </Space>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item 
                  name="f1726b"
                  validateFirst
                  rules={[
                    { required: true, message: 'This field is mandatory!' },
                  ]}
                >
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* E2 */}
          <Col lg={12} xs={24}>
            <Text>Toleransi</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1734b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* F1 */}
          <Col lg={12} xs={24}>
            <Space size={4}>
              <Text type="danger">*</Text>
              <Text>Integritas</Text>
            </Space>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item 
                  name="f1738ba"
                  validateFirst
                  rules={[
                    { required: true, message: 'This field is mandatory!' },
                  ]}
                >
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* F2 */}
          <Col lg={12} xs={24}>
            <Text>Kemampuan Adaptasi</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1736b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* G1 */}
          <Col lg={12} xs={24}>
            <Space size={4}>
              <Text type="danger">*</Text>
              <Text>Kemampuan untuk terus belajar sepanjang hayat </Text>
            </Space>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item 
                  name="f1754b"
                  validateFirst
                  rules={[
                    { required: true, message: 'This field is mandatory!' },
                  ]}
                >
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* G2 */}
          <Col lg={12} xs={24}>
            <Text>Loyalitas</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1738b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* H1 */}
          <Col lg={12} xs={24}>
            <Text>Pengetahuan di luar bidang atau disiplin ilmu kamu</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f174b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* H2 */}
          <Col lg={12} xs={24}>
            <Text>Bekerja dengan orang yang berbeda budaya maupun latar belakang </Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1740b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* I1 */}
          <Col lg={12} xs={24}>
            <Text>Pengetahuan umum</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f176b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* I2 */}
          <Col lg={12} xs={24}>
            <Text>Kepemimpinan</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1742b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* J1 */}
          <Col lg={12} xs={24}>
            <Text>Keterampilan Internet</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f178b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* J2 */}
          <Col lg={12} xs={24}>
            <Text>Kemampuan dalam memegang tanggungjawab</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1744b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* K1 */}
          <Col lg={12} xs={24}>
            <Text>Berpikir Kritis</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1712b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* K2 */}
          <Col lg={12} xs={24}>
            <Text>Inisiatif</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1746b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* L1 */}
          <Col lg={12} xs={24}>
            <Text>Keterampilan Riset</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1714b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* L2 */}
          <Col lg={12} xs={24}>
            <Text>Manajemen proyek/program</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1748b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* M1 */}
          <Col lg={12} xs={24}>
            <Text>Kemampuan Belajar</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1716b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* M2 */}
          <Col lg={12} xs={24}>
            <Text>Kemampuan untuk memresentasikan ide/produk/laporan </Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1750b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* N1 */}
          <Col lg={12} xs={24}>
            <Text>Bekerja di bawah tekanan </Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1720b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* N2 */}
          <Col lg={12} xs={24}>
            <Text>Kemampuan dalam menulis laporan, memo dan dokumen</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1752b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* O1 */}
          <Col lg={12} xs={24}>
            <Text>Manajemen Waktu</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1722b">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={16}>
           <Col span={12}>
              <CButton className="width-100" type="primary" ghost action={handlePrev} title="Sebelumnya"/>
            </Col>
            <Col span={12}>
              <CButton className="width-100" htmlType="submit" type="primary" title="Selanjutnya"/>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </React.Fragment>
  )
}
