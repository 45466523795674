import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Layout, Grid, Image } from 'antd';
import JobshubLogo from '../../../assets/img/logo/JobshubLogo.svg';
import '../JobPreferences.css';

const { useBreakpoint } = Grid;

const { Header } = Layout;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export const HeaderPreference = ({ onClose }) => {

  const { md } = GetBreakPoint()

  return (
    <React.Fragment>
      {
        md ?
        <Header className="header-job-preference">
          <Link to="/" style={{ paddingTop: 20, paddingLeft: 80 }}>
            <Image
              width={140}
              src={JobshubLogo}
              preview={false}
              
            />
          </Link>
          {/* <Menu onClick={onClose} selectedKeys={[pathname]} mode="horizontal">
            <Menu.Item key="/language">
              <Dropdown overlay={menuLanguage} trigger={['click']}>
                <Link to="#" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                  English <DownOutlined />
                </Link>
              </Dropdown>
            </Menu.Item>
          </Menu> */}
        </Header>
      :
        <Header breakpoint="md" className="header-job-preference">
          <Link to="/" style={{ paddingTop: 20, paddingLeft: 18 }}>
            <Image
              width={140}
              src={JobshubLogo}
              preview={false}
            />
          </Link>
          {/* <Menu onClick={onClose} selectedKeys={[pathname]} mode="horizontal">
            <Menu.Item key="/language">
              <Dropdown overlay={menuLanguage} trigger={['click']}>
                <Link to="#" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                  English <DownOutlined />
                </Link>
              </Dropdown>
            </Menu.Item>
          </Menu> */}
        </Header>
      }
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderPreference)