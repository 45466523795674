import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Info from './Info';
import Q46_47 from './Q46-47';
import Q48_51 from './Q48-51';
import Q52_53 from './Q52-53';
import Q54_56 from './Q54-56';
import Q57_61 from './Q57-61';
import Q62_65 from './Q62-65';

export class Section3 extends Component {
  render() {
    const { isSection, isPart } = this.props

    return (
      <React.Fragment>
        {/* Section 3 INFO */}
        {isSection === 3 && isPart === 0 ? <Info {...this.props} /> : null}
        {/* Section 3 Q 46 - 47 */}
        {isSection === 3 && isPart === 1 ? <Q46_47 {...this.props} /> : null}
        {/* Section 3 Q 48 - 51 */}
        {isSection === 3 && isPart === 2 ? <Q48_51 {...this.props} /> : null}
        {/* Section 3 Q 52 - 53 */}
        {isSection === 3 && isPart === 3 ? <Q52_53 {...this.props} /> : null}
        {/* Section 3 Q 54 - 56 */}
        {isSection === 3 && isPart === 4 ? <Q54_56 {...this.props} /> : null}
        {/* Section 3 Q 57 - 61 */}
        {isSection === 3 && isPart === 5 ? <Q57_61 {...this.props} /> : null}
        {/* Section 3 Q 62 - 65 */}
        {isSection === 3 && isPart === 6 ? <Q62_65 {...this.props} /> : null}
      </React.Fragment>
    )
  }
}
export default withTranslation()(Section3)