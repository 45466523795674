export const courseLevel = [
  {
    "value": "ALL",
    "name": "All"
  },
  {
    "value": "BEGINNER",
    "name": "Beginner"
  },
  {
    "value": "INTERMEDIATE",
    "name": "Intermediate"
  },
  {
    "value": "ADVANCED",
    "name": "Advanced"
  }
]