import { API } from '../../../config'
import { errorHandler } from '../auth/errorAction'

export const indexHeader = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_HEADER' })
  return API.GET('/profile/header').then((response) => {
    dispatch({
      type: 'LOAD_DATA_HEADER_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_HEADER_FAILED' }), 
    ))
  })
}

export const listNotification = (params) => async (dispatch) => {
  await dispatch({ type: 'LOAD_NOTIFICATION' })
  return API.GET('/notification/list', params).then((response) => {
    dispatch({
      type: 'LOAD_NOTIFICATION_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_NOTIFICATION_FAILED' }), 
    ))
  })
}

export const countNotification = (successCB, failedCB) => async dispatch => {
  API.GET(`/notification/count`).then((response) => {
    dispatch({ 
      type: 'LOAD_DATA_COUNT_NOTIFICATION_SUCCESS', 
      payload : { 
        data: response.count 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DATA_COUNT_NOTIFICATION_FAILED' }), 
      ))
    )
  })
}

export const readNotification = (value, successCB, failedCB) => async dispatch => {
  API.POST_WITH_PARAMS('/notification/read', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}