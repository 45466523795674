import React from 'react';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import { Grid } from 'antd';
import XpertDetailDesktop from './Desktop';
import XpertDetailMobile from './Mobile';
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function XpertDetail(props) {
  const { lg } = GetBreakPoint()

  if(process.env.REACT_APP_ENV === "production"){
    ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Xpert - Detail');
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Xpert - Detail</title>
      </Helmet>
      {
        lg ?
          <XpertDetailDesktop {...props} />
        :
          <XpertDetailMobile {...props} />
      }
    </React.Fragment>
  )
}
