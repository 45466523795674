import React from 'react';
import moment from 'moment';
import { Row, Col, Space, Typography, Form, Input, DatePicker, Select, Card, Grid } from 'antd';
import CButton from '../../../../components/Button';
const { Option } = Select;
const { Text } = Typography;
const { useBreakpoint } = Grid;
function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function Form1(props) {
  const { xs } = GetBreakPoint();
  const { formRef, activePage, getProfile, getStudyProgram, f1, onChangeEndYear, onFinish } = props
  return (
    <React.Fragment>
      {
        activePage === 1 ?
          <Card 
            className="mb-16 border-radius-6"
            bordered={false} 
            style={{ backgroundColor: '#f8f8f8', paddingLeft: xs ? 10 : 80, paddingRight: xs ? 10 : 80 }}
          >
            <Row className="text-align-center mb-16">
              <Col span={24}>
                <Text className="dark-color fs-20">Petunjuk Pengisian</Text>
              </Col>
            </Row>

            <Space direction='vertical'>
              <Text className="light-color fs-16">- Silahkan jawab pertanyaan yang telah diberikan sesuai dengan kondisimu saat ini.</Text>
              <Text className="light-color fs-16">- Tidak ada jawaban benar atau salah</Text>
              <Text className="light-color fs-16">- Akan ada doorprize bagi mahasiswa yang beruntung</Text>
            </Space>

            <Row className={xs?"mt-16":"text-align-center mt-16"}>
              <Col span={24}>
                <Text className="dark-color fs-16">Pertanyaan-pertanyaan dibawah digunakan sebagai penilaian dalam penyerapan lulusan di dunia kerja.</Text>
              </Col>
            </Row>
          </Card>
        : null
      }
      <Row className="text-align-center mb-16 mt-16">
        <Col span={24}>
          <Text className="primary-color fs-17">Identitas Diri</Text>
        </Col>
      </Row>

      <Form 
        ref={formRef}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          nimhsmsmh: f1?.nimhsmsmh ? f1.nimhsmsmh : null,
          tahunLulus: 
            f1?.tahunLulus ?
              moment(f1.tahunLulus)
            : getProfile.data.listEducation?.find(item => item.name?.includes('binawan') || item.name?.includes('Binawan') || item.name?.includes('BINAWAN')).endYear ?
              moment(getProfile.data.listEducation?.find(item => item.name?.includes('binawan') || item.name?.includes('Binawan') || item.name?.includes('BINAWAN')).endYear) 
            : null,
          kdpstmsmh: f1?.kdpstmsmh ? f1.kdpstmsmh : null,
          nik: 
            f1?.nik ?
              f1.nik
            : getProfile.data.identificationId ? 
              getProfile.data.identificationId 
            : null,
          npwp: f1?.npwp ? f1.npwp : null
        }}
      >
        <Row className={xs?"mb-16":"mb-16"} gutter={16}>
          <Col className={xs?"mb-16":null} xs={24} lg={12}>
            <Space direction='vertical' size={4}>
              <Text>Kode PT</Text>
              <Text className='font-weight-bold'>031064</Text>
            </Space>
          </Col>
          <Col xs={24} lg={12}>
            <Space direction='vertical' size={4}>
              <Text>Nama</Text>
              <Text className='font-weight-bold'>{getProfile.data.fullName}</Text>
            </Space>
          </Col>
        </Row>
        <Row className="mb-16" gutter={16}>
          <Col className={xs?"mb-16":null} xs={24} lg={12}>
            <Space direction='vertical' size={4}>
              <Text>Nomor HP</Text>
              <Text className='font-weight-bold'>{`+${getProfile.data.callingCode}${getProfile.data.mobilePhone}`}</Text>
            </Space>
          </Col>
          <Col className={xs?"mb-16":null} xs={24} lg={12}>
            <Space direction='vertical' size={4}>
              <Text>Email</Text>
              <Text className='font-weight-bold'>{getProfile.data.email}</Text>
            </Space>
          </Col>
        </Row>
        <Row className={xs?"mb-16":null} gutter={16}>
          <Col className={xs?"mb-16":null} xs={24} lg={12}>
            <Form.Item 
              name="nimhsmsmh"
              label="Nomor Mahasiswa"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
                { pattern: /^[0-9]*$/, message: 'Number only' },
                { min: 3, message: 'At least 3 characters' },
                { max: 25, message: '25 characters only' },
              ]}
            >
              <Input placeholder="Misalnya: 20166444848" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item 
              name="tahunLulus" 
              label="Tahun Lulus"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <DatePicker inputReadOnly className="width-100" picker="year" onChange={onChangeEndYear} placeholder="Misalnya: 2021" disabledDate={ d => !d || d.isBefore(moment().subtract(30, 'years').format('YYYY').toString()) || d.isAfter(moment().add(1, 'years').format('YYYY').toString()) } />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item 
              name="kdpstmsmh"
              label="Kode Prodi"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
                { pattern: /^[0-9]*$/, message: 'Number only' },
              ]}
            >
              <Select
                showSearch
                placeholder="Misalnya: 13211 - Gizi"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  getStudyProgram?.data?.map((item, i) => 
                    <Option key={i} value={item.code}>{`${item.code} - ${item.name}`}</Option>
                  )
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item 
              name="nik"
              label="NIK"
              rules={[
                { required: true, message: 'This field is mandatory!' },
                { pattern: /^[0-9]*$/, message: 'Number only' },
                { len: 16, message: '16 characters only' },
              ]}
            >
              <Input placeholder="Misalnya: 3578264810837877" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item 
              name="npwp"
              label="NPWP"
              rules={[
                { pattern: /^[0-9]*$/, message: 'Number only' },
                { len: 15, message: '15 characters only' },
              ]}
            >
              <Input placeholder="Misalnya: 001484939393364" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <CButton className="width-100" htmlType="submit" type="primary" title="Selanjutnya"/>
        </Form.Item>
      </Form>
    </React.Fragment>
  )
}
