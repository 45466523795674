import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';
import { Loading } from '../../../components';
import { listJobPositionByJobIndustry, unmountlistJobPosition } from '../../../redux/actions/master/jobPosition/jobPositionAction';
import { checkToken } from '../../../redux/actions/auth/authAction';
import { Row, Col, Card, Image, Typography, Space, Layout, message } from 'antd';
import Header from '../Header';
import Footer from '../Footer';
import '../JobPreferences.css';

const { Title, Text } = Typography;

export class Position extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      positionSelected: localStorage.getItem("positionSelected") ? localStorage.getItem("positionSelected").split(',').map(x=>+x) : []
    }
  }

  componentDidMount() {
    const { actionCheckToken, actionGetJobPosition } = this.props
    actionCheckToken((response) => {
      if(response.code === "1000"){
        return actionGetJobPosition(localStorage.getItem("industriesSelected"))
      }
    })
  }

  onSelectPosition = (id) => {
    const { t } = this.props
    const { positionSelected } = this.state
    const filterSelected = positionSelected.filter(item => item === id)
    const indexOfSelected = positionSelected.indexOf(id)
    if(filterSelected.length === 0){
      if(positionSelected.length < 3){
        positionSelected.push(id)
      }else{
        message.error(t('jobPreferences.positions.msg'))
      }
    }else{
      positionSelected.splice(indexOfSelected,1)
    }

    this.setState({ positionSelected })
  }

  render() {
    const { getJobPosition: { data, loading }, t } = this.props
    const { positionSelected } = this.state
    
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Job Preferences - Job Position');
    }

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Job Preferences - Job Position</title>
        </Helmet>
        <Layout>
          <Header />
          <Row className="display-inline margin-16 mt-32">
            <Col className="pb-100 mt-64" xs={24} sm={24} md={{ span: 22, offset: 1 }}>
              <Space className="width-100 mb-32 text-align-center" direction="vertical" size="small">
                <Title className="dark-color font-weight-normal" level={3}>{t('jobPreferences.positions.title')}</Title>
                <Text className="dark-color font-weight-normal">{t('jobPreferences.positions.subTitle')}</Text>
              </Space>
              {
                data?.map((item, i) =>
                  <React.Fragment key={i}>
                    <Row className="mb-16" justify="space-around" align="middle">
                      <Col span={24}>
                      <Space size="middle">
                        <Image
                          className="pt-6"
                          width={40}
                          height={40}
                          src={item.iconUrl}
                          preview={false}
                        />
                        <Text className="fs-13">{item.name}</Text>
                      </Space>
                      </Col>
                    </Row>
                    <Row className="mb-32" gutter={[16, 16]}>
                      {
                        item.listJobPosition.map((res, i) => 
                          <Col key={i} xs={24} sm={24} md={8}>
                            <Card
                              className={ 
                                positionSelected.length === 0 ? 
                                  "width-100 border-radius-6"
                                :
                                positionSelected.map((value) => 
                                    res.id === value ? "width-100 border-radius-6 card-selected" : "width-100 border-radius-6"
                                  )}
                              hoverable
                              bodyStyle={{ padding: 12 }}
                              onClick={() => this.onSelectPosition(res.id)}
                            >
                              <Row justify="space-around" align="middle">
                                <Col className="text-align-center" span={24}>
                                  <Text className="fs-11">{res.title}</Text>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        )
                      }
                    </Row>
                  </React.Fragment>
                )
              }
            </Col>
          </Row>
          <Footer positionSelected={positionSelected} />
        </Layout>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountlistJobPosition } = this.props
    return unmountlistJobPosition()
  }
}

const mapStateToProps = (state) => ({
  getJobPosition: state.jobPositionReducer
})

const mapDispatchToProps = {
  actionCheckToken: checkToken,
  actionGetJobPosition: listJobPositionByJobIndustry,
  unmountlistJobPosition: unmountlistJobPosition,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Position))
