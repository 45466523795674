import React from 'react';
import { Link } from 'react-router-dom';
import Cookie from 'js-cookie';
import InfiniteScroll from 'react-infinite-scroller';
import { withTranslation } from 'react-i18next';
import { Row, Col, Form, List, Input, Image, Typography, Space, Button, Collapse, Checkbox, Drawer, Divider, Switch, Select, Spin, Radio, Modal, Card, Skeleton, Tooltip, Grid, Pagination } from 'antd';
import { SafetyOutlined, EnvironmentOutlined, FilterOutlined, PlusOutlined, CheckOutlined, StarOutlined, StarFilled } from '@ant-design/icons';
import EmptyLogo from '../../../assets/img/EmptyLogo.png';
// import { Row, Col, List, Input, Image, Typography, Space, Button, Collapse, Checkbox, Tooltip, Drawer, Divider, Switch } from 'antd';
// import { SafetyOutlined, EnvironmentOutlined, StarOutlined, FilterOutlined } from '@ant-design/icons';
import '../Job.css';
const { Text } = Typography;
const { Search } = Input;
const { Panel } = Collapse;
const { Option } = Select;
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export function JobListMobile(props) {
  const {
    showFilter,
    onCloseFilter,
    visibleFilter,
    onSearch, 
    callback, 
    getJob,
    handleSort,
    onChangePagination, 
    dataIndustry, 
    handleIndustry, 
    handleDestination,
    dataLocation, 
    handleLocation,
    handleType,
    handleRemote,
    handleLevel,
    handleDate,
    handleClearFilter,
    formRef,
    flagRemote,
    showModalIndustry,
    allJobIndustry,
    onSearchIndustry,
    handleCheckedIndustry,
    visibleIndustry,
    valueIndustry,
    handleOkIndustry,
    handleClearIndustry,
    showModalLocation,
    visibleLocation,
    allJobDestination,
    onSearchLocation,
    handleCheckedLocation,
    handleOkLocation,
    handleClearLocation,
    valueLocation,
    valueSearch,
    handleCancel,
    loadingList,
    hasMore,
    handleInfiniteOnLoad,
    handleFavorite,
    valueDestination,
    t
  } = props
  
  const onClickApply = (val) => {
    localStorage.setItem('link', val);
    localStorage.setItem('visible', true);
    props.history.push('/login-or-register')
  }

  const { sm, xs } = GetBreakPoint()

  const handleClearForm = () => {
    window.scrollTo(0, 0);
    formRef.current.setFieldsValue({
      search: null,
      industry: null,
      destination: null,
      location: null,
      jobType: null,
      level: null,
      postDate: null
    });
  }

  return (
    <React.Fragment>
      <Form ref={formRef}>
        <Row className="padding-16">
          <Col span={24}>
            <Row className="mb-8 width-100">
              <Col className="pr-4" span={3}>
                <Button className="width-100 border-radius-6" onClick={showFilter} type="primary" icon={<FilterOutlined />} ghost />
                <Drawer
                  className="dark-color"
                  title="Filter"
                  placement="bottom"
                  height={'70%'}
                  closable={false}
                  onClose={onCloseFilter}
                  visible={visibleFilter}
                >
                  <Collapse className="border-radius-6 dark-color box-shadow" style={{ backgroundColor: '#fff', border: '1px solid #d9d9d9' }} defaultActiveKey={['1','2','3','4','5','6']} onChange={callback} expandIconPosition="right" ghost>
                    <Panel header={t('jobList.industry')} key="1">
                      <Space className="mb-16" direction="vertical" size={-16}>
                        <Form.Item name="industry" initialValue={valueIndustry}>
                          <Checkbox.Group onChange={(checkedValues) => handleIndustry(checkedValues)}>
                            {
                              dataIndustry?.data?.map((item, i) => 
                                <Checkbox key={i} className="fs-12 dark-color width-100" value={item.id}>{item.title}</Checkbox>
                              )
                            }
                          </Checkbox.Group>
                        </Form.Item>
                        <Text className="add-filter fs-13 cursor-pointer" onClick={showModalIndustry}><PlusOutlined />{t('jobList.addInsutry')}</Text>
                        <Modal
                          visible={visibleIndustry}
                          title={t('jobList.filterByIndustry')}
                          onOk={handleOkIndustry}
                          onCancel={handleCancel}
                          footer={[
                            <Button key="back" onClick={handleClearIndustry}>
                              {t('jobList.reset')}
                            </Button>,
                            <Button key="submit" type="primary" loading={getJob?.loading} onClick={handleOkIndustry}>
                              {t('jobList.submit')}
                            </Button>,
                          ]}
                        >
                          <Search className="mb-8" enterButton={t('jobList.search')} placeholder={t('jobList.searchJob')} allowClear onSearch={onSearchIndustry} style={{ width: '100%' }} />
                          <div className="demo-infinite-container">
                            <Form.Item name="modalIndustry" initialValue={valueIndustry}>
                                {
                                  allJobIndustry.loading ?
                                    <Spin size="small" />
                                  :
                                    <Checkbox.Group className="width-100" onChange={(checkedValues) => handleCheckedIndustry(checkedValues)}>
                                      { 
                                        allJobIndustry?.data?.map((item, i) => 
                                          <Checkbox key={i} className="fs-12 dark-color width-100 mb-8" value={item.id}>{item.title}</Checkbox>
                                        )
                                      }
                                    </Checkbox.Group>
                                }
                            </Form.Item>
                          </div>
                        </Modal>
                      </Space>
                    </Panel>
                    {/* <Divider className="dark-color" /> */}
                    <Panel header={t('jobList.destination')} key="2">
                      <Space direction="vertical" size={-16}>
                        <Form.Item className="mb-min-8" name="destination" initialValue={valueDestination}>
                          <Checkbox.Group onChange={(checkedValues) => handleDestination(checkedValues)}>
                            <Checkbox key="INTERNATIONAL" className="fs-12 dark-color width-100" value="INTERNATIONAL">International</Checkbox>
                            <Checkbox key="DOMESTIC" className="fs-12 dark-color width-100" value="DOMESTIC">Domestic</Checkbox>
                          </Checkbox.Group>
                        </Form.Item>
                      </Space>
                    </Panel>
                    {/* <Divider className="dark-color" /> */}
                    <Panel header={t('jobList.location')} key="3">
                      <Space className="mb-16" direction="vertical" size={-16}>
                        <Form.Item name="location" initialValue={valueLocation}>
                          {
                            dataLocation.loading?
                              <Spin size="small" />
                            :
                                <Checkbox.Group onChange={(checkedValues) => handleLocation(checkedValues)}>
                                  {
                                    dataLocation?.data?.map((item, i) => 
                                      <Checkbox key={i} className="fs-12 dark-color" value={item.id} style={{ width: '100%' }}>{item.name}</Checkbox>
                                    )
                                  }
                                </Checkbox.Group>
                          }
                        </Form.Item>
                        <Text className="add-filter fs-13 cursor-pointer" onClick={showModalLocation}><PlusOutlined />{t('jobList.addLocation')}</Text>
                        <Modal
                          visible={visibleLocation}
                          title={t('jobList.filterLocation')}
                          onOk={handleOkLocation}
                          onCancel={handleCancel}
                          footer={[
                            <Button key="back" onClick={handleClearLocation}>
                              {t('jobList.reset')}
                            </Button>,
                            <Button key="submit" type="primary" loading={getJob?.loading} onClick={handleOkLocation}>
                              {t('jobList.submit')}
                            </Button>,
                          ]}
                        >
                          <Search className="mb-8" placeholder={t('jobList.searchLocation')} enterButton={t('jobList.search')} allowClear onSearch={onSearchLocation} style={{ width: '100%' }} />
                            <div className="demo-infinite-container">
                              <InfiniteScroll
                                initialLoad={false}
                                pageStart={0}
                                loadMore={() => handleInfiniteOnLoad()}
                                hasMore={!loadingList && hasMore}
                                useWindow={false}
                              >
                                <List
                                  dataSource={allJobDestination?.data ? allJobDestination?.data : []}
                                  renderItem={(item, i) => (
                                    <List.Item key={i}>
                                      <Checkbox checked={valueLocation.includes(item.id)} onChange={() => handleCheckedLocation(item.id)} className="fs-12 dark-color width-100" value={item.id}>{item.name}</Checkbox>
                                    </List.Item>
                                  )}
                                  loading={allJobDestination.loading}
                                >
                                </List>
                              </InfiniteScroll>
                            </div>
                        </Modal>
                      </Space>
                    </Panel>
                    {/* <Divider className="dark-color" /> */}
                    <Panel header={t('jobList.jobType')} key="4">
                      <Space direction="vertical" size={0}>
                        <Form.Item className="mb-min-8" name="jobType">
                          <Checkbox.Group onChange={(checkedValues) => handleType(checkedValues)}>
                            <Checkbox key="FULL_TIME" className="fs-12 dark-color width-100" value="FULL_TIME">{t('jobList.fullTime')}</Checkbox>
                            <Checkbox key="PART_TIME" className="fs-12 dark-color width-100" value="PART_TIME">{t('jobList.partTime')}</Checkbox>
                            <Checkbox key="FREELANCE" className="fs-12 dark-color width-100" value="FREELANCE">{t('jobList.freelance')}</Checkbox>
                            <Checkbox key="INTERNSHIP" className="fs-12 dark-color width-100" value="INTERNSHIP">{t('jobList.internship')}</Checkbox>
                            <Checkbox key="VOLUNTEER" className="fs-12 dark-color width-100" value="VOLUNTEER">{t('jobList.volunteer')}</Checkbox>
                            <Checkbox key="TALENT_POOL" className="fs-12 dark-color width-100" value="TALENT_POOL">{t('jobList.talentPool')}</Checkbox>
                          </Checkbox.Group>
                        </Form.Item>
                        <Divider style={{ marginBottom: 8, marginTop: 16 }} />
                        <Space className="fs-12 dark-color width-100">
                          {t('jobList.possible')} <Switch size="small" checked={flagRemote} onChange={(checked) => handleRemote(checked)} />
                        </Space>
                      </Space>
                    </Panel>
                    {/* <Divider className="dark-color" /> */}
                    <Panel header={t('jobList.experienceLevel')} key="5">
                      <Space direction="vertical" size={0}>
                        <Form.Item className="mb-min-8" name="level">
                          <Checkbox.Group onChange={(checkedValues) => handleLevel(checkedValues)}>
                            <Checkbox key="ENTRY_LEVEL" className="fs-12 dark-color width-100" value="ENTRY_LEVEL">{t('jobList.entryLevel')}</Checkbox>
                            <Checkbox key="ASSOCIATE" className="fs-12 dark-color width-100" value="ASSOCIATE">{t('jobList.associate')}</Checkbox>
                            <Checkbox key="MID_SENIOR" className="fs-12 dark-color width-100" value="MID_SENIOR">{t('jobList.midSenior')}</Checkbox>
                            <Checkbox key="DIRECTOR" className="fs-12 dark-color width-100" value="DIRECTOR">{t('jobList.director')}</Checkbox>
                            <Checkbox key="EXECUTIVE" className="fs-12 dark-color width-100" value="EXECUTIVE">{t('jobList.executive')}</Checkbox>
                          </Checkbox.Group>
                        </Form.Item>
                      </Space>
                    </Panel>
                    {/* <Divider className="dark-color" /> */}
                    <Panel header={t('jobList.datePosted')} key="6">
                      <Space className="mb-16" direction="vertical" size={0}>
                        <Form.Item className="mb-min-8" name="postDate">
                          <Radio.Group optionType="button" onChange={(e) => handleDate(e)}>
                            <Space className="width-100" direction="vertical">
                              <Radio.Button key="anytime" className="width-100 text-align-center border-radius-6 dark-color" value="anytime">{t('jobList.anyTime')}</Radio.Button>
                              <Radio.Button key="month" className="width-100 text-align-center border-radius-6 dark-color" value="month">{t('jobList.pastMonth')}</Radio.Button>
                              <Radio.Button key="week" className="width-100 text-align-center border-radius-6 dark-color" value="week">{t('jobList.pastWeek')}</Radio.Button>
                              <Radio.Button key="hours" className="width-100 text-align-center border-radius-6 dark-color" value="hours">{t('jobList.pastHours')}</Radio.Button>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      </Space>
                    </Panel>
                  </Collapse>
                  <Card className="border-radius-6 box-shadow" bordered={false} style={{ marginTop: 8 }}>
                    <Button className="width-100 border-radius-6" type="primary" onClick={() => {handleClearFilter(); handleClearForm()}}>{t('jobList.clearFilter')}</Button>
                  </Card>
                </Drawer>
              </Col>
              <Col className="pl-4" span={21}>
                <Form.Item name="search" initialValue={valueSearch}>
                  <Search 
                    className="width-100 border-radius-6" 
                    placeholder={t('jobList.title')} 
                    allowClear 
                    onSearch={onSearch} 
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="mb-8" sm={12} xs={24}>
                {
                  getJob.loading ?
                    <Skeleton.Input style={{ width: '100%' }} active />
                  :
                    <Text className="dark-color fs-13">{`${t('jobList.showing')} ${getJob?.data?.meta?.total === 0 ? getJob?.data?.meta?.total : (getJob?.data?.meta?.page*getJob?.data?.meta?.perpage)-(getJob?.data?.meta?.perpage-1)}-${getJob?.data?.meta?.page === getJob?.data?.meta?.pages || getJob?.data?.meta?.total === 0 ? getJob?.data?.meta?.total : getJob?.data?.meta?.page*getJob?.data?.meta?.perpage} ${t('jobList.of')} ${getJob?.data?.meta?.total} ${t('jobList.jobMatches')}`}</Text>
                }
              </Col>
              <Col className="width-100" sm={12} xs={24} >
                <Space className="width-100" size={4} style={ sm ? { paddingLeft: 30 } : {}}>
                  <Text className="fs-13 dark-color" >{t('jobList.sortBy')}</Text>
                  <Select defaultValue="postedDate" style={{ width: 180 }} size="small" onChange={handleSort}>
                    {/* <Option value="relevance">Relevance</Option> */}
                    <Option key="1" value="expiryDate">{t('jobList.expiryDate')}</Option>
                    <Option key="2" value="postedDate">{t('jobList.postedDate')}</Option>
                  </Select>
                </Space>
              </Col>
            </Row>
            <Row className="width-100 pt-8 pb-8">
              <Col span={24}>
                {
                  getJob?.loading ?
                    <Card className="border-radius-6 box-shadow mb-8" style={{ width: '100%' }}>
                      <Skeleton active avatar />
                    </Card>
                  :
                    getJob?.data?.data.map((item,i) =>
                    <Link key={i} to={Cookie.get('user') ? `/candidate/job/detail/${item.slug}` : `/job/detail/${item.slug}`}>
                      <Card
                        className="border-radius-6 mb-8 dark-color box-shadow item bg-color-white" 
                        bordered={false}
                      >
                        <Row className="width-100" gutter={16}>
                          <Col className="col-img-job" xs={24} sm={4} md={4}>
                            {/* <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}> */}
                            {/* <Link to={Cookie.get('user') ? `/candidate/job/detail/${item.slug}` : `/job/detail/${item.slug}`}> */}
                              <Image
                                src={item.companyLogoUrl ? item.companyLogoUrl : EmptyLogo}
                                preview={false}
                                width={'100%'}
                                height={100}
                                style={{ objectFit: 'contain' }}
                              />
                            {/* </Link> */}
                          </Col>
                          <Col xs={24} sm={13} md={12}>
                            <Space direction="vertical" size={0}>
                              {/* <Link to={Cookie.get('user') ? `/candidate/job/detail/${item.slug}` : `/job/detail/${item.slug}`}> */}
                                <Text className="title-job dark-color fs-17">{item.title}</Text>
                              {/* </Link> */}
                              {/* <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}> */}
                                <Text className="company-job fs-13 dark-color">{item.companyName} {item.isVerified ? <SafetyOutlined className="primary-color" /> : null}</Text>
                              {/* </Link> */}
                              <Text className="fs-13 dark-color"><EnvironmentOutlined /> {item.jobDestinationParentId ? `${item.jobDestinationName}, ${item.jobDestinationParentName}` : item.jobDestinationName}</Text>
                              <Text className="fs-13 primary-color">
                                {
                                  item.isDisplaySalary ?
                                    item.maxSalary ? 
                                      `${item.salary ? item.salary.toLocaleString() : null} - ${item.maxSalary ? item.maxSalary.toLocaleString() : null} ${item.currencyCode}` 
                                    : 
                                      `${item.salary ? item.salary.toLocaleString() : null} ${item.currencyCode}`
                                  :
                                    null
                                }
                              </Text>
                              <Text className="dark-color fs-13">{item.agencyName ? `${t('jobList.recruited')} ${item.agencyName}` : null}</Text>
                            </Space>
                          </Col>
                          <Col xs={24} sm={7} md={8}>
                            <Space direction="vertical" size={16} className={ xs ? "float-left width-100" : "float-right"}>
                              <Text className="fs-11 dark-color">{`${t('jobList.postedOn')} ${item.postDate}`}</Text>
                              {
                                Cookie.get('user') ?
                                  <Space size={8}>
                                    {
                                      item.jobPostingTypeId === 2 && Cookie.get('email')?.split("@")[1] === 'student.binawan.ac.id' ?
                                        <>
                                          <Tooltip title={item.isFavorite ? t('jobList.alreadyFavorite') : t('jobList.addFavorite') }>
                                            <Button type="primary" className="border-radius-6" icon={item.isFavorite ? <StarFilled /> : <StarOutlined />} onClick={() => handleFavorite(item.id, item.isFavorite)} ghost />
                                          </Tooltip>
                                          {
                                            item.isApply ?
                                              <Text className="float-right fs-14 primary-color"><CheckOutlined />{t('jobList.applied')}</Text>
                                            :
                                              
                                              <Link to={`/candidate/job/detail/${item.slug}`}>
                                                <Button type="primary" className="float-right border-radius-6">{t('jobList.apply')}</Button>
                                              </Link>  
                                          }
                                        </>
                                      : item.jobPostingTypeId === 2 && Cookie.get('email')?.split("@")[1] !== 'student.binawan.ac.id' ?
                                        <Tooltip title={t('jobList.typeWh') }>
                                          <Button type="primary" className="float-right border-radius-6" disabled style={{ borderRadius: 6 }}>{t('jobList.apply')}</Button>
                                        </Tooltip>
                                      :
                                        <>
                                          <Tooltip title={item.isFavorite ? t('jobList.alreadyFavorite') : t('jobList.addFavorite') }>
                                            <Button type="primary" className="border-radius-6" icon={item.isFavorite ? <StarFilled /> : <StarOutlined />} onClick={() => handleFavorite(item.id, item.isFavorite)} ghost />
                                          </Tooltip>
                                          {
                                            item.isApply ?
                                              <Text className="float-right fs-14 primary-color"><CheckOutlined /> {t('jobList.applied')}</Text>
                                            :
                                              <Link to={`/candidate/job/detail/${item.slug}`}>
                                                <Button type="primary" className="float-right border-radius-6">{t('jobList.apply')}</Button>
                                              </Link>
                                          }         
                                        </>
                                    }      
                                  </Space>
                                :
                                  <Button type="primary" className="border-radius-6 width-100" onClick={() => onClickApply(`/job/detail/${item.slug}`)}>{t('jobList.apply')}</Button>
                              }
                            </Space>
                          </Col>
                        </Row>
                      </Card>
                    </Link>

                    )
                }
                {/* <List
                  className="border-radius-6 box-shadow"
                  size="large"
                  pagination={{
                    style: { textAlign: 'center' },
                    pageSize: getJob?.data?.meta?.perpage,
                    total: getJob?.data?.meta?.total,
                    current: getJob?.data?.meta?.page,
                    onChange: (pageNumber) => onChangePagination(pageNumber)
                  }}
                  bordered
                  dataSource={getJob?.data?.data}
                  renderItem={item => (
                    <List.Item key={item.id}>
                      <Row className="width-100" gutter={16}>
                        <Col className="col-img-job" xs={24} sm={4} md={4}>
                          <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}>
                          <Link to={Cookie.get('user') ? `/candidate/job/detail/${item.slug}` : `/job/detail/${item.slug}`}>
                            <Image
                              src={item.companyLogoUrl ? item.companyLogoUrl : EmptyLogo}
                              preview={false}
                              width={'100%'}
                              height={100}
                              style={{ objectFit: 'contain' }}
                            />
                          </Link>
                        </Col>
                        <Col xs={24} sm={13} md={12}>
                          <Space direction="vertical" size={0}>
                            <Link to={Cookie.get('user') ? `/candidate/job/detail/${item.slug}` : `/job/detail/${item.slug}`}>
                              <Text className="title-job dark-color fs-17">{item.title}</Text>
                            </Link>
                            <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}>
                              <Text className="company-job fs-13 dark-color">{item.companyName} {item.isVerified ? <SafetyOutlined className="primary-color" /> : null}</Text>
                            </Link>
                            <Text className="fs-13 dark-color"><EnvironmentOutlined /> {item.jobDestinationParentId ? `${item.jobDestinationName}, ${item.jobDestinationParentName}` : item.jobDestinationName}</Text>
                            <Text className="fs-13 primary-color">
                              {
                                item.isDisplaySalary ?
                                  item.maxSalary ? 
                                    `${item.salary ? item.salary.toLocaleString() : null} - ${item.maxSalary ? item.maxSalary.toLocaleString() : null} ${item.currencyCode}` 
                                  : 
                                    `${item.salary ? item.salary.toLocaleString() : null} ${item.currencyCode}`
                                :
                                  null
                              }
                            </Text>
                            <Text className="dark-color fs-13">{item.agencyName ? `Recruited by ${item.agencyName}` : null}</Text>
                          </Space>
                        </Col>
                        <Col xs={24} sm={7} md={8}>
                          <Space direction="vertical" size={16} className={ xs ? "float-left width-100" : "float-right"}>
                            <Text className="fs-11 dark-color">{`Posted on ${item.postDate}`}</Text>
                            {
                              Cookie.get('user') ?
                                <Space size={8}>
                                  {
                                    item.jobPostingTypeId === 2 && Cookie.get('email')?.split("@")[1] === 'student.binawan.ac.id' ?
                                      <>
                                        <Tooltip title={item.isFavorite ? t('jobList.alreadyFavorite') : t('jobList.addFavorite') }>
                                          <Button type="primary" className="border-radius-6" icon={item.isFavorite ? <StarFilled /> : <StarOutlined />} onClick={() => handleFavorite(item.id, item.isFavorite)} ghost />
                                        </Tooltip>
                                        {
                                          item.isApply ?
                                            <Text className="float-right fs-14 primary-color"><CheckOutlined />{t('jobList.applied')}</Text>
                                          :
                                            
                                            <Link to={`/candidate/job/detail/${item.slug}`}>
                                              <Button type="primary" className="float-right border-radius-6">{t('jobList.apply')}</Button>
                                            </Link>  
                                        }
                                      </>
                                    : item.jobPostingTypeId === 2 && Cookie.get('email')?.split("@")[1] !== 'student.binawan.ac.id' ?
                                      <Tooltip title={t('jobList.typeWh') }>
                                        <Button type="primary" className="float-right border-radius-6" disabled style={{ borderRadius: 6 }}>{t('jobList.apply')}</Button>
                                      </Tooltip>
                                    :
                                      <>
                                        <Tooltip title={item.isFavorite ? t('jobList.alreadyFavorite') : t('jobList.addFavorite') }>
                                          <Button type="primary" className="border-radius-6" icon={item.isFavorite ? <StarFilled /> : <StarOutlined />} onClick={() => handleFavorite(item.id, item.isFavorite)} ghost />
                                        </Tooltip>
                                        {
                                          item.isApply ?
                                            <Text className="float-right fs-14 primary-color"><CheckOutlined />{t('jobList.applied')}</Text>
                                          :
                                            <Link to={`/candidate/job/detail/${item.slug}`}>
                                              <Button type="primary" className="float-right border-radius-6">{t('jobList.apply')}</Button>
                                            </Link>
                                        }         
                                      </>
                                  }      
                                </Space>
                              :
                                <Button type="primary" className="border-radius-6 width-100" onClick={() => onClickApply(`/job/detail/${item.slug}`)}>{t('jobList.apply')}</Button>
                            }
                          </Space>
                        </Col>
                      </Row>
                    </List.Item>
                  )}
                /> */}
              </Col>
              <Col className="text-align-center" span={24}>
                <Pagination 
                  onChange={(pageNumber) => onChangePagination(pageNumber)} 
                  current={getJob?.data?.meta?.page} 
                  pageSize={getJob?.data?.meta?.perpage || 10} 
                  total={getJob?.data?.meta?.total} 
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default withTranslation()(JobListMobile)