import React from 'react';
import { Row, Col, Typography, Form, Radio } from 'antd';
import CButton from '../../../../components/Button';
const { Text } = Typography;

export default function Form9(props) {
  const { formRef, form9, f9, handleKesesuaian, handlePrev, onFinish } = props
  return (
    <React.Fragment>
      <Row className="text-align-center mb-16 mt-16">
        <Col span={24}>
          <Text className="primary-color fs-17">Kesesuaian Pendidikan dengan Pekerjaan</Text>
        </Col>
      </Row>

      <Form 
        ref={formRef}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          f1601: f9?.f1601 ? f9.f1601 : form9.kesesuaian
        }}
      >
        <Row className="mb-16">
          <Col span={24}>
            <Form.Item 
              name="f1601"
              label="Apakah kamu merasa bahwa pekerjaan saat ini sudah sesuai dengan pendidikan yang kamu tempuh?"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <Radio.Group onChange={handleKesesuaian}>
                <Radio key="1" value="1">Ya</Radio>
                <Radio key="2" value="2">Tidak</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <CButton className="width-100" type="primary" ghost action={handlePrev} title="Sebelumnya"/>
            </Col>
            <Col span={12}>
              <CButton className="width-100" htmlType="submit" type="primary" title="Selanjutnya"/>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </React.Fragment>
  )
}
