import { API } from '../../../config'
import { errorHandler } from '../auth/errorAction'

export const unmountDataPathway = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_CAREER_PATHWAY'})
}


export const dataPathway = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_CAREER_PATHWAY' })
  return API.GET('/pathway/detail').then((response) => {
    dispatch({
      type: 'LOAD_DATA_CAREER_PATHWAY_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_CAREER_PATHWAY_FAILED' }), 
    ))
  })
}

export const addPathway = (value, successCB, failedCB) => async dispatch => {
  API.POST('/pathway', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const addFeedback = (value, successCB, failedCB) => async dispatch => {
  API.POST('/pathway/add/feedback', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const updateFeedback = (value, successCB, failedCB) => async dispatch => {
  API.POST('/pathway/update/feedback', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const addScore = (value, successCB, failedCB) => async dispatch => {
  API.POST('/pathway/add/basic_english/score', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const pathwayUpdateProfile = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/pathway/editProfile', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addScoreMiniEnglish = (value, successCB, failedCB) => async dispatch => {
  API.POST('/pathway/add/mini_english/score', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}