import React from 'react';
import { Grid } from 'antd';

import Desktop from './Desktop';
import Mobile from './Mobile';

const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function index(props) {
  const { xs } = GetBreakPoint()

  return (
    <React.Fragment>
      {
        xs ? 
          <Mobile {...props} />
        :
          <Desktop {...props} />
      }
    </React.Fragment>
  )
}
