import { API } from '../../../config'
import { errorHandler } from '../auth/errorAction'


export const unmountDetailEvent = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_EVENT'})
} 

export const detailEvent = (slug, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_EVENT' })
  const params = {
    'slug': slug
  }
  API.GET('/job-event/getBySlug', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_EVENT_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_EVENT_FAILED' }), 
      ))
    )
  })
}



