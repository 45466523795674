const LOAD_LAST_EDUCATION           = 'LOAD_LAST_EDUCATION'
const LOAD_LAST_EDUCATION_SUCCESS   = 'LOAD_LAST_EDUCATION_SUCCESS'
const LOAD_LAST_EDUCATION_FAILED    = 'LOAD_LAST_EDUCATION_FAILED'
const UNMOUNT_LAST_EDUCATION        = 'UNMOUNT_LAST_EDUCATION'
const initialState = {
  loading: true,
  data: null,
  message: null,
}
const lastEducationReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_LAST_EDUCATION:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_LAST_EDUCATION_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_LAST_EDUCATION_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_LAST_EDUCATION:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}
export default lastEducationReducer;