import { API } from '../../../config'
import { errorHandler } from '../auth/errorAction'

export const unmountCandidateNotification = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_CANDIDATE_NOTIFICATION'})
}

export const unmountCandidateTimezone = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_CANDIDATE_TIMEZONE'})
}

export const updatePassword = (value, successCB, failedCB) => async dispatch => {
  API.POST('/chpass', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const updateNotification = (value, successCB, failedCB) => async dispatch => {
  API.POST('/account/job_notification', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const candidateNotification = () => async (dispatch) => {
	await dispatch({ type: 'LOAD_CANDIDATE_NOTIFICATION' })
	return API.GET('/account/getJobNotification').then((response) => {
		dispatch({
			type: 'LOAD_CANDIDATE_NOTIFICATION_SUCCESS', 
			payload: {
				data: response.data
		}})
	}).catch((err) => {
		return dispatch(errorHandler(
			err.error || err.message, 
			dispatch({ type: 'LOAD_CANDIDATE_NOTIFICATION_FAILED' }), 
		))
	})
}

export const candidateTimezone = () => async (dispatch) => {
	await dispatch({ type: 'LOAD_CANDIDATE_TIMEZONE' })
	return API.GET('/account/getTimeZone').then((response) => {
		dispatch({
			type: 'LOAD_CANDIDATE_TIMEZONE_SUCCESS', 
			payload: {
				data: response.data
		}})
	}).catch((err) => {
		return dispatch(errorHandler(
			err.error || err.message, 
			dispatch({ type: 'LOAD_CANDIDATE_TIMEZONE_FAILED' }), 
		))
	})
}

export const updateTimezone = (value, successCB, failedCB) => async dispatch => {
  API.POST_WITH_PARAMS('/account/time_zone', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}