import React from 'react';
import { Row, Col, Space, Typography, Form, Slider } from 'antd';
import { FrownOutlined, SmileOutlined } from '@ant-design/icons';
import CButton from '../../../../components/Button';
const { Text } = Typography

export default function Form7A(props) {
  const { formRef, f7a, handlePrev, onFinish } = props
  const formatter = (value) => {
    if(value === 1){
      return 'Sangat Rendah'
    }else if(value === 2){
      return 'Rendah'
    }else if(value === 3){
      return 'Cukup'
    }else if(value === 4){
      return 'Tinggi'
    }else if(value === 5){
      return 'Sangat Tinggi'
    }else{
      return null
    }
  }
  return (
    <React.Fragment>
      <Row className="text-align-center mb-16 mt-16">
        <Col span={24}>
          <Text className="primary-color fs-17">Kompetensi yang Dikuasai</Text>
        </Col>
      </Row>

      <Form 
        ref={formRef}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          f1701: f7a?.f1701 ? f7a.f1701 : null,
          f1723: f7a?.f1723 ? f7a.f1723 : null,
          f175a: f7a?.f175a ? f7a.f175a : null,
          f1727: f7a?.f1727 ? f7a.f1727 : null,
          f179: f7a?.f179 ? f7a.f179 : null,
          f1729: f7a?.f1729 ? f7a.f1729 : null,
          f1717: f7a?.f1717 ? f7a.f1717 : null,
          f1731: f7a?.f1731 ? f7a.f1731 : null,
          f1725: f7a?.f1725 ? f7a.f1725 : null,
          f1733: f7a?.f1733 ? f7a.f1733 : null,
          f1737a: f7a?.f1737a ? f7a.f1737a : null,
          f1735: f7a?.f1735 ? f7a.f1735 : null,
          f1753: f7a?.f1753 ? f7a.f1753 : null,
          f1737: f7a?.f1737 ? f7a.f1737 : null,
          f173: f7a?.f173 ? f7a.f173 : null,
          f1739: f7a?.f1739 ? f7a.f1739 : null,
          f175: f7a?.f175 ? f7a.f175 : null,
          f1741: f7a?.f1741 ? f7a.f1741 : null,
          f177: f7a?.f177 ? f7a.f177 : null,
          f1743: f7a?.f1743 ? f7a.f1743 : null,
          f1711: f7a?.f1711 ? f7a.f1711 : null,
          f1745: f7a?.f1745 ? f7a.f1745 : null,
          f1713: f7a?.f1713 ? f7a.f1713 : null,
          f1747: f7a?.f1747 ? f7a.f1747 : null,
          f1715: f7a?.f1715 ? f7a.f1715 : null,
          f1749: f7a?.f1749 ? f7a.f1749 : null,
          f1719: f7a?.f1719 ? f7a.f1719 : null,
          f1751: f7a?.f1751 ? f7a.f1751 : null,
          f1721: f7a?.f1721 ? f7a.f1721 : null
        }}
      >
        <Row className="mb-16" gutter={16} justify="space-between" align="bottom">
          {/* A1 */}
          <Col lg={12} xs={24}>
            <Space size={4}>
              <Text type="danger">*</Text>
              <Text>Pengetahuan di bidang atau disiplin ilmu kamu</Text>
            </Space>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item 
                  name="f1701"
                  validateFirst
                  rules={[
                    { required: true, message: 'This field is mandatory!' },
                  ]}
                >
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* A2 */}
          <Col lg={12} xs={24}>
            <Text>Bekerja secara mandiri</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1723">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* B1 */}
          <Col lg={12} xs={24}>
            <Space size={4}>
              <Text type="danger">*</Text>
              <Text>Bahasa Inggris</Text>
            </Space>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item 
                  name="f175a"
                  validateFirst
                  rules={[
                    { required: true, message: 'This field is mandatory!' },
                  ]}
                >
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* B2 */}
          <Col lg={12} xs={24}>
            <Text>Kemampuan dalam memecahkan masalah</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1727">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* C1 */}
          <Col lg={12} xs={24}>
            <Space size={4}>
              <Text type="danger">*</Text>
              <Text>Ketrampilan komputer</Text>
            </Space>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item 
                  name="f179"
                  validateFirst
                  rules={[
                    { required: true, message: 'This field is mandatory!' },
                  ]}
                >
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* C2 */}
          <Col lg={12} xs={24}>
            <Text>Negosiasi</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1729">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* D1 */}
          <Col lg={12} xs={24}>
            <Space size={4}>
              <Text type="danger">*</Text>
              <Text>Kemampuan Berkomunikasi</Text>
            </Space>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item 
                  name="f1717"
                  validateFirst
                  rules={[
                    { required: true, message: 'This field is mandatory!' },
                  ]}
                >
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* D2 */}
          <Col lg={12} xs={24}>
            <Text>Kemampuan Analisis</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1731">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* E1 */}
          <Col lg={12} xs={24}>
            <Space size={4}>
              <Text type="danger">*</Text>
              <Text>Bekerja dalam tim/bekerjasama dengan orang lain</Text>
            </Space>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item 
                  name="f1725"
                  validateFirst
                  rules={[
                    { required: true, message: 'This field is mandatory!' },
                  ]}
                >
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* E2 */}
          <Col lg={12} xs={24}>
            <Text>Toleransi</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1733">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* F1 */}
          <Col lg={12} xs={24}>
            <Space size={4}>
              <Text type="danger">*</Text>
              <Text>Integritas</Text>
            </Space>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item 
                  name="f1737a"
                  validateFirst
                  rules={[
                    { required: true, message: 'This field is mandatory!' },
                  ]}
                >
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* F2 */}
          <Col lg={12} xs={24}>
            <Text>Kemampuan Adaptasi</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1735">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* G1 */}
          <Col lg={12} xs={24}>
            <Space size={4}>
              <Text type="danger">*</Text>
              <Text>Kemampuan untuk terus belajar sepanjang hayat </Text>
            </Space>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item 
                  name="f1753"
                  validateFirst
                  rules={[
                    { required: true, message: 'This field is mandatory!' },
                  ]}
                >
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* G2 */}
          <Col lg={12} xs={24}>
            <Text>Loyalitas</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1737">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* H1 */}
          <Col lg={12} xs={24}>
            <Text>Pengetahuan di luar bidang atau disiplin ilmu kamu</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f173">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* H2 */}
          <Col lg={12} xs={24}>
            <Text>Bekerja dengan orang yang berbeda budaya maupun latar belakang </Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1739">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* I1 */}
          <Col lg={12} xs={24}>
            <Text>Pengetahuan umum</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f175">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* I2 */}
          <Col lg={12} xs={24}>
            <Text>Kepemimpinan</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1741">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* J1 */}
          <Col lg={12} xs={24}>
            <Text>Keterampilan Internet</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f177">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* J2 */}
          <Col lg={12} xs={24}>
            <Text>Kemampuan dalam memegang tanggungjawab</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1743">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* K1 */}
          <Col lg={12} xs={24}>
            <Text>Berpikir Kritis</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1711">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* K2 */}
          <Col lg={12} xs={24}>
            <Text>Inisiatif</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1745">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* L1 */}
          <Col lg={12} xs={24}>
            <Text>Keterampilan Riset</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1713">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* L2 */}
          <Col lg={12} xs={24}>
            <Text>Manajemen proyek/program</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1747">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* M1 */}
          <Col lg={12} xs={24}>
            <Text>Kemampuan Belajar</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1715">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* M2 */}
          <Col lg={12} xs={24}>
            <Text>Kemampuan untuk memresentasikan ide/produk/laporan </Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1749">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* N1 */}
          <Col lg={12} xs={24}>
            <Text>Bekerja di bawah tekanan </Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1719">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* N2 */}
          <Col lg={12} xs={24}>
            <Text>Kemampuan dalam menulis laporan, memo dan dokumen</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1751">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* O1 */}
          <Col lg={12} xs={24}>
            <Text>Manajemen Waktu</Text>
            <Row className="text-align-center" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Rendah</Text>
                  </Space>
                  <FrownOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item name="f1721">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <SmileOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Tinggi</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <CButton className="width-100" type="primary" ghost action={handlePrev} title="Sebelumnya"/>
            </Col>
            <Col span={12}>
              <CButton className="width-100" htmlType="submit" type="primary" title="Selanjutnya"/>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </React.Fragment>
  )
}
