export const paymentOption = [
    {
      "value": "ONE_TIME_PAYMENT",
      "name": "One Time Payment"
    },
    {
      "value": "INSTALLMENT",
      "name": "Installment"
    },
    {
      "value": "LOAN",
      "name": "Loan"
    }
  ]