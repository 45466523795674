import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import Cookie from 'js-cookie';
import { Layout, Menu, Grid, Dropdown, Space, Badge, Avatar, Typography, Button, Drawer, Image, Modal, message } from 'antd';
import JobshubLogo from '../../../assets/img/logo/JobshubLogo.svg';
import Ina from '../../../assets/img/flag/ina.png';
import Eng from '../../../assets/img/flag/eng.png';
import Notification from '../../Notification';
import { MenuOutlined, BellOutlined, UserOutlined, CaretDownFilled, SettingOutlined, PoweroffOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import './Header.css';
const { Header } = Layout;
const { Text } = Typography;
const { confirm } = Modal;
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export const index = (props) => {
  const {
    t,
    getHeader,
    getCountNotification: { data },
    onClickSider,
    showDrawer,
    showNotification,
    onCloseNotification,
    visibleNotification,
    setLogout,
    actionAddScore,
    actionAddScoreMiniEnglish
  } = props
  
  const { md } = GetBreakPoint()
  const { pathname } = window.location
  const currentLanguage = Cookie.get('i18next')

  const onClickSettings = () => {
    props.history.push('/candidate/accountSettings')
  }

  const showConfirm = (path) => {
    confirm({
      title: t('pathway.msgErrTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('pathway.msgErrText'),
      onOk() {
        if(Cookie.get("result")){
          const values = {
            score: JSON.parse(Cookie.get("result"))
              .map((item) => item.val)
              .reduce((a, b) => a + b, 0)
              .toString(),
          };
          return actionAddScore(
            values,
            (response) => {
              if (response.code === "1000") {
                localStorage.removeItem('isTest')
                if(path === 'logout'){
                  setLogout()
                }else{
                  props.history.push(path)
                }
              } else {
                message.error(response.message);
              }
            },
            (err) => {
              message.error(err)
            }
          );
        }else{
          localStorage.removeItem('isTest')
          if(path === 'logout'){
            setLogout()
          }else{
            props.history.push(path)
          }
        }
      }
    });
  };

  const showConfirmMiniEnglish = (path) => {
    confirm({
      title: t('pathway.msgErrTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('pathway.msgErrText'),
      onOk() {
        if(Cookie.get("result")){
          const values = {
            score: JSON.parse(Cookie.get("result"))
              .map((item) => item.val)
              .reduce((a, b) => a + b, 0) / 15 * 100
          };
          return actionAddScoreMiniEnglish(
            values,
            (response) => {
              if (response.code === "1000") {
                localStorage.removeItem('isTestMiniEnglish')
                localStorage.removeItem("questionListening")
                localStorage.removeItem("questionStructure")
                localStorage.removeItem("questionWrittenExp")
                localStorage.removeItem("questionReadingCompre")
                if(path === 'logout'){
                  setLogout()
                }else{
                  props.history.push(path)
                }
              } else {
                message.error(response.message);
              }
            },
            (err) => {
              message.error(err)
            }
          );
        }else{
          localStorage.removeItem('isTestMiniEnglish')
          localStorage.removeItem("questionListening")
          localStorage.removeItem("questionStructure")
          localStorage.removeItem("questionWrittenExp")
          localStorage.removeItem("questionReadingCompre")
          if(path === 'logout'){
            setLogout()
          }else{
            props.history.push(path)
          }
        }
      }
    });
  };

  const menu = (
    <Menu className="padding-16" style={{ width: 300 }}>
      <Menu.Item className="not-menu" key="avatar">
        <Space className="width-100 mb-8" key="avatar">
          {
            getHeader?.data?.profilePicUrl ?
              <Avatar src={getHeader?.data?.profilePicUrl} /> 
            :
              <Avatar style={{ backgroundColor: '#32ab6d' }} icon={<UserOutlined />} /> 
          }
          <Text className="dark-color" ellipsis={true} style={{ width: 200 }}>{getHeader?.data?.fullName}</Text>
        </Space>
      </Menu.Item>
      <Menu.Item className="not-menu" key="/profile">
        {
          (localStorage.getItem('isTest') || localStorage.getItem('isTestMiniEnglish')) ?
            <Button 
              className="border-radius-20 width-100 mb-8" 
              size="small" 
              ghost 
              type="primary" 
              onClick={
                localStorage.getItem('isTest') ?
                  () => showConfirm('/candidate/profile')
                :
                  () => showConfirmMiniEnglish('/candidate/profile')
              }
            >
              {t('header.viewProfile')}
            </Button>
          :
            <Link className="dark-color" to="/candidate/profile">
              <Button type="primary" className="border-radius-20 width-100 mb-8" size="small" ghost>{t('header.viewProfile')}</Button>
            </Link>
        }
      </Menu.Item>
      <Menu.Item 
        className="dark-color" 
        key="/account" 
        onClick={
          localStorage.getItem('isTest')  ? 
            () => showConfirm('/candidate/accountSettings') 
          :
          localStorage.getItem('isTestMiniEnglish')  ? 
            () => showConfirmMiniEnglish('/candidate/accountSettings') : onClickSettings} icon={<SettingOutlined />} 
      >
        {t('header.accountSetting')}
      </Menu.Item>
      <Menu.Item 
        className="dark-color" 
        key="/logout" 
        onClick={
          localStorage.getItem('isTest') ? () => showConfirm('logout') :
          localStorage.getItem('isTestMiniEnglish') ? () => showConfirm('logout') : setLogout
        } icon={<PoweroffOutlined />}
      >
          {t('header.logout')}
      </Menu.Item>
    </Menu>
  )

  const menuLanguage = (
    <Menu style={{ marginTop: -24 }}>
      <Menu.Item key="1" disabled={currentLanguage === 'in'} onClick={() => {i18next.changeLanguage('in')}} style={{ opacity: currentLanguage === 'in' ? 0.5 : 1, cursor: currentLanguage === 'in' ? 'not-allowed' : 'pointer' }}>
        <Space>
          <Image
            width={20}
            src={Ina}
            preview={false}
            style={{ marginTop: 4, borderRadius: 12, boxShadow: '1px 1px 8px #cccccc' }}
          />
          <Text style={{ color: '#4273b9' }}>
            INA
          </Text>
        </Space>
      </Menu.Item>
      <Menu.Item key="2" disabled={currentLanguage === 'en'} onClick={() => {i18next.changeLanguage('en')}} style={{ opacity: currentLanguage === 'en' ? 0.5 : 1, cursor: currentLanguage === 'en' ? 'not-allowed' : 'pointer' }}>
        <Space>
          <Image
            width={20}
            src={Eng}
            preview={false}
            style={{ marginTop: 4, borderRadius: 12, boxShadow: '1px 1px 8px #cccccc' }}
          />
          <Text style={{ color: '#4273b9' }}>
            ENG
          </Text>
        </Space>
      </Menu.Item>
    </Menu>
  )
  
  return (
    <React.Fragment>
      {
        pathname === '/job-preferences/industries' || pathname === '/job-preferences/position' || pathname === '/job-preferences/destination' ?
          null
        :
          md ?
            <Header className="header-inside">
              <Space size={24} >
                <Link to="#" onClick={onClickSider}>
                  <MenuOutlined className="fs-18" />
                </Link>
                {
                  localStorage.getItem('isTest') ||  localStorage.getItem('isTestMiniEnglish') ?
                    <Image
                      className='cursor-pointer'
                      width={140}
                      src={JobshubLogo}
                      preview={false}
                      onClick={
                        localStorage.getItem('isTest') ?
                        () => showConfirm('/') 
                        : 
                        () => showConfirmMiniEnglish('/') 
                      }
                      style={{ paddingTop: 25 }}
                    />
                  :
                    <Link to="/">
                      <Image
                        width={140}
                        src={JobshubLogo}
                        preview={false}
                        style={{ paddingTop: 25 }}
                      />
                    </Link>
                }
              </Space>
              <Space size={16}>
                <Dropdown overlay={menuLanguage} trigger={['click']} placement="bottomCenter">
                  <Space>
                    <Image
                      width={20}
                      src={currentLanguage === 'in' ? Ina : currentLanguage === 'en' ? Eng : null}
                      preview={false}
                      style={{ marginTop: 24, borderRadius: 12, boxShadow: '1px 1px 8px #cccccc' }}
                    />
                    <Text style={{ color: '#4273b9', cursor: 'pointer' }}>
                      {currentLanguage === 'in' ? 'INA' : currentLanguage === 'en' ? 'ENG' : null} <DownOutlined />
                    </Text>
                  </Space>
                </Dropdown>
                <Badge className="dark-color cursor-pointer" size="small" count={data} onClick={showNotification} style={{ fontSize: 10 }}>
                  <BellOutlined className="fs-20" />
                </Badge>
                <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                  <Link to="#" className="ant-dropdown-link dark-color" onClick={e => e.preventDefault()}>
                    {
                      getHeader?.data?.profilePicUrl ?
                        <React.Fragment>
                          <Avatar src={getHeader?.data?.profilePicUrl} /> <CaretDownFilled />
                        </React.Fragment>
                      :
                        <React.Fragment>
                          <Avatar style={{ backgroundColor: '#32ab6d' }} icon={<UserOutlined />} /> <CaretDownFilled />
                        </React.Fragment>
                    }
                  </Link>
                </Dropdown>
              </Space>
            </Header>
          :
            <Header className="header-mobile">
              <Space className="pl-16" size={0}>
                <Link to="#" onClick={ md ? onClickSider : showDrawer }>
                  <MenuOutlined className="fs-18" />
                </Link>
                {
                  localStorage.getItem('isTest') || localStorage.getItem('isTestMiniEnglish')?
                    <Image
                      className='cursor-pointer'
                      width={140}
                      src={JobshubLogo}
                      preview={false}
                      onClick={
                        localStorage.getItem('isTest') ?
                        () => showConfirm('/')
                        :
                        () => showConfirmMiniEnglish('/')
                      }
                      style={{ paddingTop: 25, paddingLeft: 12 }}
                    />
                  :
                    <Link to="/">
                      <Image
                        width={140}
                        src={JobshubLogo}
                        preview={false}
                        style={{ paddingTop: 25, paddingLeft: 12 }}
                      />
                    </Link>
                }
              </Space>
              <Space className="pr-16" size={20}>
                {/* <Dropdown overlay={menuLanguage} trigger={['click']} placement="bottomCenter">
                  <Space>
                    <Image
                      width={20}
                      src={currentLanguage === 'in' ? Ina : currentLanguage === 'en' ? Eng : null}
                      preview={false}
                      style={{ marginTop: 24, borderRadius: 12, boxShadow: '1px 1px 8px #cccccc' }}
                    />
                    <Text style={{ color: '#4273b9', cursor: 'pointer' }}>
                      {currentLanguage === 'in' ? 'INA' : currentLanguage === 'en' ? 'ENG' : null} <DownOutlined />
                    </Text>
                  </Space>
                </Dropdown> */}
                <Badge className="dark-color cursor-pointer" size="small" count={data} onClick={showNotification} style={{ fontSize: 10 }}>
                  <BellOutlined className="fs-20" />
                </Badge>
                <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                  <Link to="#" className="ant-dropdown-link dark-color" onClick={e => e.preventDefault()}>
                    {
                      getHeader?.data?.profilePicUrl ?
                        <React.Fragment>
                          <Avatar src={getHeader?.data?.profilePicUrl} /> <CaretDownFilled />
                        </React.Fragment>
                      :
                        <React.Fragment>
                          <Avatar style={{ backgroundColor: '#32ab6d' }} icon={<UserOutlined />} /> <CaretDownFilled />
                        </React.Fragment>
                    }
                  </Link>
                </Dropdown>
              </Space>
            </Header>

      }
      <Drawer
        title={<Text strong style={{ color: '#32ab6d' }}>{t('header.notification')}</Text>}
        placement="right"
        width={md ? 400 : '75%'}
        onClose={onCloseNotification}
        visible={visibleNotification}
      >
        <Notification {...props} />
      </Drawer>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(index))