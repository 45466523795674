import React, { Component } from "react";
import { Row, Col, Card, Space, Typography, Radio, Image, Modal } from "antd";
import Header from "../Header";
import { withTranslation } from "react-i18next";
import CButton from '../../../../../../../components/Button';

const { Text } = Typography;

export class S3Q62_65 extends Component {

  constructor(props) {
    super(props)

    this.state = {
      visibleSection: false,
    }
  }

  showModalSection = () => {
    this.setState({ visibleSection: true})
  }

  handleCancel = () => {
    this.setState({
      visibleSection: false
    })
  }

  render() {
    const {
      questionReadingCompre,
      handleSelect,
      handleNext,
      result,
      handleBack,
      t,
    } = this.props;
    const { visibleSection } = this.state
    const arrQuestion = questionReadingCompre[5];
    return (
      <React.Fragment>
        <Row className="mt-16">
          <Col lg={{ span: 20, offset: 2 }} xxl={{ span: 18, offset: 3 }}>
            <Header {...this.props} />
            <Card className="card-body-question mt-16 mb-16 border-radius-8">
              <Row className="mt-16">
                <Col span={24}>
                  <Space className="width-100" direction="vertical" size={16}>
                    <Col span={24}>
                      <Space
                        className="text-align-center width-100"
                        direction="vertical"
                        size={0}
                      >
                        <Text
                          style={{
                            fontSize: 20,
                            fontWeight: "normal",
                            fontStyle: "italic",
                          }}
                          strong
                        >
                          {/* {t("pathway.readingcompre")} */}
                          Reading Comprehension
                        </Text>
                        <Text
                          className="dark-color"
                          style={{
                            fontSize: 16,
                            fontWeight: "600",
                            fontStyle: "italic",
                          }}
                          strong
                        >
                          Section 3
                        </Text>
                      </Space>
                    </Col>
                    <Col span={24}>
                      <Card style={{ backgroundColor: "#F0F2F5" }}>
                        <Col span={24}>
                          <Space direction="vertical" size={0}>
                            <Text
                              tyle={{
                                fontSize: 15,
                                fontWeight: "normal",
                                fontStyle: "italic",
                              }}
                            >
                              Directions: In this section, you will read a text
                              from an article to answer question number 62 - 65.
                              Choose the correct option to answer the question.
                            </Text>
                            <Text
                              style={{
                                fontSize: 15,
                                fontWeight: "normal",
                                color: "#FF0000",
                              }}
                            >
                              Petunjuk: Pada bagian ini, Anda akan membaca teks
                              dari sebuah artikel untuk menjawab soal nomor 62 -
                              65. Pilih opsi yang tepat untuk menjawab
                              pertanyaan tersebut
                            </Text>
                          </Space>
                        </Col>
                      </Card>
                    </Col>
                    <Col className="text-align-center" span={24}>
                      <Image
                        alt="example"
                        src={arrQuestion.problemsImgs}
                        width={"100%"}
                        preview={false}
                      />
                    </Col>

                    {arrQuestion.questions.map((item, i) => (
                      <Col key={i} span={24}>
                        <Space className="width-100" align="start">
                          <Text>{i + 61 + 1}.</Text>
                          <Space className="width-100" direction="vertical">
                            <Space
                              className="width-100"
                              direction="vertical"
                              size={0}
                            >
                              <Text
                                className="noselect dark-color"
                                style={{ fontSize: 15, fontWeight: "normal" }}
                              >
                                {item.question}
                              </Text>
                            </Space>
                            <Radio.Group
                              className="width-100"
                              value={
                                result?.find((item) => item.no === i + 61 + 1)
                                  ?.selected
                              }
                              onChange={(e) => handleSelect(e, i + 61 + 1)}
                            >
                              <Space className="width-100" direction="vertical">
                                {item.answers.map((res, iAnswer) => (
                                  <Radio
                                    className="noselect"
                                    key={iAnswer}
                                    result={res.value}
                                    value={iAnswer}
                                    style={{
                                      whiteSpace: "break-spaces",
                                      display: "flex",
                                      alignItems: "center"
                                    }}
                                  >
                                    {res.answerLabel}
                                  </Radio>
                                ))}
                              </Space>
                            </Radio.Group>
                          </Space>
                        </Space>
                      </Col>
                    ))}
                  </Space>
                </Col>
                <Col span={24}>
                  <Space className="float-right">
                    <CButton
                      type="link"
                      size="large"
                      action={() => handleBack(3, 5)}
                      icon="LeftOutlined"
                      title={t("pathway.back")}
                    />
                    <CButton
                      type="primary"
                      size="large"
                      action={result?.length<65 ? () =>  handleNext(3, 7, result?.length >= 65) : this.showModalSection}
                      title={t("pathway.finish")}
                    />
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          width={600}
          title={
            <Space className="text-align-center width-100" direction="vertical" size={0}>
              <Text className="fs-20 primary-color">{t("pathway.lastSession")} </Text>
              <Text className="fs-15 dark-color">English Assesment</Text>
            </Space>
          }
          visible={visibleSection}
          onCancel={this.handleCancel}
          footer={false}
        >
          <Row className='text-align-center'>
            <Col span={24}>
              <Space direction='vertical' size={16}>
                <Space className='font-style-italic text-align-center width-100' direction='vertical' size={0}>
                  <Text style={{ fontSize: 15, fontWeight: 'normal' }}>Are you sure you want to submit this test?</Text>
                </Space>
                <Space className='text-align-center width-100' direction='vertical' size={0}>
                  <Text className='dark-color' strong style={{ fontSize: 15, fontWeight: 'normal' }}>Apakah anda yakin ingin mengumpulkan ujian ini</Text>
                </Space>
              </Space>
              <Row className='mt-32'>
                <Col lg={{ span: 6, offset: 9 }} xs={{ span: 24, offset: 0 }}>
                  <CButton type="primary" size="large" block action={() => {handleNext(3, 7, result?.length >= 65); this.handleCancel()}} title="Oke"/>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withTranslation()(S3Q62_65);
