import React from 'react';
import moment from 'moment';
import { Form, Space, Select, Typography, Row, Col, Input, DatePicker, Spin, AutoComplete, Switch } from 'antd';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

export default function Education(props) {
  const {
    t,
    state: {
      disabledEducation,
      isBinawan,
      educationLevelName,
      educationCurrent,
      allValues
    },
    onSearchInstitution,
    onFocusInstitution,
    onChangeInstitution,
    onChangeEducationLevel,
    getEducationLevel,
    onSearchFieldOfStudy,
    onFocusFieldOfStudy,
    onChangeStartYear,
    onChangeChecked,
    onChangeEndYear,
    getInstitution,
    getFieldOfStudy,
    getProfile
  } = props

  const dataEducation = getProfile?.data?.listEducation[getProfile.data.listEducation.length-1]

  return (
    <React.Fragment>
      <Row className='mb-8' gutter={16}>
        <Col span={12}>
          <Space size={4}>
            {/* <Text className="fs-12" type="danger">*</Text> */}
            <Text className="fs-12" type="secondary">{t('profile.education.form.school')}</Text>
          </Space>
          <Form.Item 
            name="educationName"
            validateFirst
            rules={[
              { required: true, message: t('profileUpdate.careerPathway.alert.education') },
              // { pattern: /^[0-9a-zA-Z ]*$/, message: 'Special characters are not allowed' },
              { min: 5, message: t('profileUpdate.careerPathway.alert.minEducation') },
              { max: 50, message: t('profileUpdate.careerPathway.alert.maxEducation') },
            ]}
            initialValue={allValues?.educationName || dataEducation?.name}
          >
            <AutoComplete
              className="width-100"
              onFocus={onFocusInstitution}
              onSearch={onSearchInstitution}
              onChange={onChangeInstitution}
              notFoundContent={getInstitution.loading ? <Spin size="small" /> : null}
              filterOption={false}
              size="small"
              placeholder="e.g.: Binawan University"
              disabled={dataEducation?.name}
            >
              {
                getInstitution?.data?.map((res) => 
                  <Option key={res.id} value={res.name}>
                    {res.name}
                  </Option>
                )
              }
            </AutoComplete>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Space size={4}>
            {/* <Text className="fs-12" type="danger">*</Text> */}
            <Text className="fs-12" type="secondary">{t('profile.education.form.educationLevel')}</Text>
          </Space>
          <Form.Item 
            name="educationLevelId"
            rules={[
              { required: true, message: t('profileUpdate.careerPathway.alert.educationlevel') }
            ]}
            initialValue={educationLevelName || dataEducation?.educationLevelName}
          >
            <Select 
              className="width-100 border-radius-6"
              size="small" 
              onChange={(value, option) => onChangeEducationLevel(value, option)}
              disabled={disabledEducation || dataEducation?.educationLevelId}
            >
              {
                getEducationLevel?.data?.map(item => 
                  <Option key={item.id} value={item.name}>{item.name}</Option>
                )
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row className='mb-8' gutter={16}>
        <Col span={12} className='width-100 mt-min-16'>
          <Space size={4}>
            {/* {
              isBinawan ? 
                // <Text className="fs-12" type="danger">*</Text>
              : null
            } */}
            <Text className="fs-12" type="secondary">{t('profile.education.form.fieldOfStudy')}</Text>
          </Space>
          <Form.Item 
            name="educationFieldOfStudy"
            validateFirst
            rules={[
              { required: isBinawan, message: t('profileUpdate.careerPathway.alert.fieldOfStudy') },
              { min: 5, message: t('profileUpdate.careerPathway.alert.minField') },
              { max: 100, message: t('profileUpdate.careerPathway.alert.maxField') },
            ]}
            initialValue={allValues?.educationFieldOfStudy || dataEducation?.fieldOfStudy}
          >
            <AutoComplete
              className="width-100"
              onFocus={onFocusFieldOfStudy}
              onSearch={onSearchFieldOfStudy}
              notFoundContent={getFieldOfStudy.loading ? <Spin size="small" /> : null}
              filterOption={false}
              size="small"
              placeholder="e.g.: Nursing"
              disabled={disabledEducation || dataEducation?.fieldOfStudy}
            >
              {
                getFieldOfStudy?.data?.map((res) => (
                  <Option key={res.id} value={res.name}>
                    {res.name}
                  </Option>
                ))
              }
            </AutoComplete>
          </Form.Item>
        </Col>
      </Row>
      <Row className='width-100 mt-min-16' gutter={16}>
        <Col span={12}>
          <Space size={4}>
            {/* {
              isBinawan ? 
                // <Text className="fs-12" type="danger">*</Text>
              : null
            } */}
            <Text className="fs-12" type="secondary">{t('profile.education.form.startYear')}</Text>
          </Space>
          <Form.Item 
            name="educationStartYear"
            rules={[
              { required: isBinawan, message: t('profileUpdate.careerPathway.alert.starYear') },
            ]}
            initialValue={allValues?.educationStartYear ? moment(allValues?.educationStartYear, 'YYYY') : dataEducation?.startYear ? moment(dataEducation?.startYear, 'YYYY-MM') : null}
          >
            <DatePicker 
              className="width-100"
              inputReadOnly 
              size="small" 
              picker="year" 
              onChange={onChangeStartYear} 
              disabledDate={ d => !d || d.isBefore(moment().subtract(30, 'years').format('YYYY').toString()) || d.isAfter(moment().add(1, 'years').format('YYYY').toString()) }
              disabled={disabledEducation || dataEducation?.startYear}
              placeholder={t('profile.education.form.selectYear')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="educationCurrent" 
            initialValue={educationCurrent}
            style={{ marginTop: 20 }}
          >
            <Text className="fs-12 ml-8" type="secondary">{t('profile.education.form.onGoing')}</Text>
            <Switch className='ml-8' onChange={onChangeChecked} checked={educationCurrent} size="small" disabled={disabledEducation || dataEducation?.startYear || dataEducation?.endYear} />
          </Form.Item>
        </Col>
      </Row>
      <Row className="mt-8 mb-min-8" gutter={16}>
        <Col span={12} className='width-100 mt-min-16'>
          <Space size={4}>
            {/* {
              isBinawan && !educationCurrent ? 
                // <Text className="fs-12" type="danger">*</Text>
              : null
            } */}
            <Text className="fs-12" type="secondary">{t('profile.education.form.endYear')}</Text>
          </Space>
          <Form.Item 
            name="educationEndYear"
            // validateStatus={isYear ? "error" : null}
            // help={isYear ? "Your end year can’t be earlier than your start year" : null}
            rules={[
              { required: isBinawan && !educationCurrent, message: t('profileUpdate.careerPathway.alert.endYear') },
            ]}
            initialValue={allValues?.educationEndYear ? moment(allValues?.educationEndYear, 'YYYY') : dataEducation?.endYear ? moment(dataEducation?.endYear, 'YYYY-MM') : null}
          >
            {/* <DatePicker inputReadOnly className="width-100" size="small" picker="year" onChange={onChangeEndYear} disabledDate={ d => !d || d.isBefore(moment().subtract(30, 'years').format('YYYY').toString()) || d.isAfter(moment().add(1, 'years').format('YYYY').toString()) } /> */}
            {
              educationCurrent ?  
                <Text className="fs-12" type="secondary">Present</Text>
              :
                <DatePicker 
                  className="width-100" 
                  size="small" 
                  picker="year" 
                  onChange={onChangeEndYear} 
                  disabledDate={ d => !d || d.isBefore(moment().subtract(30, 'years').format('YYYY').toString()) }
                  disabled={disabledEducation || dataEducation?.endYear}
                  placeholder={t('profile.education.form.selectYear')}
                />
            }
          </Form.Item>
        </Col>
        <Col span={12} className='width-100 mt-min-16'>
          <Space size={4}>
            {/* {
              isBinawan ? 
                // <Text className="fs-12" type="danger">*</Text>
              : null
            } */}
            <Text className="fs-12" type="secondary">{t('profile.education.form.score')}</Text>
          </Space>
          <Form.Item 
            name="educationGpa"
            rules={[
              { required: isBinawan, message: t('profileUpdate.careerPathway.alert.gpa') },
              { pattern: /^[0-9a-zA-Z.]*$/, message: t('profileUpdate.careerPathway.alert.patternGpa') },
              { max: 4, message: t('profileUpdate.careerPathway.alert.maxGpa') },
            ]}
            initialValue={allValues?.educationGpa || dataEducation?.gpa}
          >
            <Input size="small" placeholder="e.g.: 3.50" disabled={disabledEducation || dataEducation?.gpa} />
          </Form.Item>
        </Col>
      </Row>
      <Row className='width-100 mt-min-8'>
        <Col span={24}>
          <Space size={4}>
            {/* {
              isBinawan ? 
                // <Text className="fs-12" type="danger">*</Text>
              : null
            } */}
            <Text className="fs-12" type="secondary">{t('profile.education.form.website')}</Text>
          </Space>
          <Form.Item 
            name="educationWebsite"
            validateFirst
            rules={[
              { required: isBinawan, message: t('profileUpdate.careerPathway.alert.website') },
              { max: 50, message: t('profileUpdate.careerPathway.alert.maxWebsite') },
            ]}
            initialValue={allValues?.educationWebsite || dataEducation?.website}
          >
            <Input size="small" disabled={disabledEducation || dataEducation?.website} />
          </Form.Item>
        </Col>
      </Row>
      <Row className='width-100 mt-min-8'>
        <Col span={24}>
          <Text className="fs-12" type="secondary">{t('profile.education.form.activityAndOrganization')}</Text>
          <Form.Item 
            name="educationExperience"
            rules={[
              { max: 200, message: t('profileUpdate.careerPathway.alert.educationExperience') }
            ]}
            initialValue={allValues?.educationExperience || dataEducation?.experience}
          >
            <TextArea rows={3} disabled={disabledEducation || dataEducation?.experience} />
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  )
}