import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Row, Col, Typography, Card } from 'antd';
import { updatePassword, updateNotification, candidateNotification, unmountCandidateNotification, updateTimezone, candidateTimezone, unmountCandidateTimezone } from '../../../redux/actions/accountSettings/accountSettingsAction';
import { indexTimezone, unmountIndexTimezone } from '../../../redux/actions/master/timezone/timezoneAction';
import ChangePassword from './ChangePassword';
import JobNotification from './JobNotification';
import Timezone from './Timezone';
import { checkToken } from '../../../redux/actions/auth/authAction';

const { Text } = Typography

export class index extends Component {

  render() {
    const { t } = this.props
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Account Settings');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Account Settings</title>
        </Helmet>
        <Row className="padding-16">
          <Col span={20} offset={2}>
            <Row className="mb-32">
              <Col>
                <Text className="dark-color fs-20">{t('changePassword.account')}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Row className="mb-16">
                  <Col span={24}>
                    <Card className="border-radius-3 box-shadow" title={t('changePassword.change')} headStyle={{ color: '#5f6163', fontSize: 17 }} bordered={false}>
                      <ChangePassword {...this.props} />
                    </Card>
                  </Col>
                </Row>
                <Row className="mb-16">
                  <Col span={24}>
                    <Card className="border-radius-3 box-shadow" title={t('jobNotification.title')} headStyle={{ color: '#5f6163', fontSize: 17 }} bordered={false}>
                      <JobNotification {...this.props} />
                    </Card>
                  </Col>
                </Row>
                <Row className="mb-16">
                  <Col span={24}>
                    <Card className="border-radius-3 box-shadow" title={t('timezone.title')} headStyle={{ color: '#5f6163', fontSize: 17 }} bordered={false}>
                      <Timezone {...this.props} />
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  getCandidateNotification: state.candidateNotificationReducer,
  getIndexTimezone: state.timezoneReducer,
  getCandidateTimezone: state.candidateTimezoneReducer
})

const mapDispatchToProps = {
  actionCheckToken: checkToken,
  actionUpdatePassword: updatePassword,
  actionUpdateNotification: updateNotification,
  actionCandidateNotification: candidateNotification,
  unmountCandidateNotification: unmountCandidateNotification,
  actionIndexTimezone: indexTimezone,
  unmountIndexTimezone: unmountIndexTimezone,
  actionUpdateTimezone: updateTimezone,
  actionCandidateTimezone: candidateTimezone,
  unmountCandidateTimezone: unmountCandidateTimezone
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(index))