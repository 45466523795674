import { API } from '../../../config'

export const updateName = (value, successCB, failedCB) => async dispatch => {
  API.POST('/profile/name', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const updateEmail = (value, successCB, failedCB) => async dispatch => {
  API.POST('/profile/email', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const updateEmailActivation = (value, successCB, failedCB) => async dispatch => {
  API.POST('/profile/email/activation', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const updateEmailResend = (value, successCB, failedCB) => async dispatch => {
  API.POST('/profile/email/resend', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const updateMobilePhone = (value, successCB, failedCB) => async dispatch => {
  API.POST('/profile/mobile-phone', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const updateCity = (value, successCB, failedCB) => async dispatch => {
  API.POST('/profile/city', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const updateSocial = (value, successCB, failedCB) => async dispatch => {
  API.POST('/profile/social', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const deleteSocial = (value, successCB, failedCB) => async dispatch => {
  const params = { 
    id: value
  }
  API.POST_WITH_PARAMS('/candidate/social-link/delete', params).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const uploadAvatar = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/profile/avatar', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}