import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Row, Col, Card, Typography, Select, Checkbox, Tooltip } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import './ChannelRegisterGeneral.css';
import CButton from '../../../components/Button';
const { Option } = Select;
const { Text } = Typography;

export default function ChannelRegisterGeneral(props) {
  const { 
    visible,
    agree,
    formRef,
    submitLoading,
    onFinish,
    onCheckEmail,
    onFocusPassword,
    onChangeCode,
    onCheckMobile,
    onChangeCheck,
    data
  } = props
  
  const prefixSelector = (
    <Form.Item name="code" noStyle>
      <Select className="prefix-selector" onChange={onChangeCode} showSearch>
        {
          data?.map(item =>
            <Option text={item.callingCode} key={item.id} value={`(+${item.callingCode}) ${item.name}`}>{`(+${item.callingCode}) ${item.name}`}</Option>
            )
          }
      </Select>
    </Form.Item>
  )

  return (
    <Row className="display-flex" align="middle" justify="center">
      <Col>
        <Card className="card-register" bodyStyle={{ padding: 0, display: 'flex' }}>
          <Form 
            ref={formRef}
            initialValues={{ 
              code: '(+62) Indonesia'
            }}
            onFinish={onFinish}
          >
            <Row gutter={8}>
              <Col className="fs-26 mb-32 text-align-center" xs={24} sm={24} md={24}>
                <Text type="secondary">International Job Recruitment Registration</Text>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item 
                  className="mb-16"
                  name="firstName" 
                  validateFirst
                  rules={[
                    { required: true, message: 'First name is required!' },
                    { pattern: /^[a-zA-Z ]*$/, message: 'Can not use special characters and numbers.' },
                    { min: 3, message: 'First Name must be at least 3 characters long.' },
                    { max: 25, message: 'First Name must not be more than 25 characters long.' },
                  ]}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item 
                  className="mb-16"
                  name="lastName" 
                  validateFirst
                  rules={[
                    { required: true, message: 'Last name is required!' },
                    { pattern: /^[a-zA-Z ]*$/, message: 'Can not use special characters and numbers.' },
                    { min: 3, message: 'Last Name must be at least 3 characters long.' },
                    { max: 25, message: 'Last Name must not be more than 25 characters long.' },
                  ]}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </Col>
              
              <Col xs={24} sm={24} md={24}>
                <Form.Item 
                  className="mb-16"
                  name="email"
                  validateFirst
                  rules={[
                    { required: true, message: 'Email is required!' },
                    { type: 'email', message: 'Your email address is invalid!' },
                    // Hanya untuk di production jika di staging akan di matikan
                    // { pattern:  /.+@(gmail|yahoo)\.com$/, message: 'Preferred using Gmail or Yahoo' }
                  ]}
                >
                  <Input type="email" onBlur={(e) => onCheckEmail(e.target.value)} placeholder="Valid Email Address" />
                </Form.Item>
              </Col>
              
              <Col xs={24} sm={24} md={12}>
                <Form.Item 
                  className="mb-16"
                  name="password"
                  validateFirst
                  hasFeedback
                  rules={[
                    { required: true, message: 'Password is required!' },
                    { pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, message: 'Password does not meet requirements' },
                    { min: 8, message: 'Password must be at least 8 characters long.' },
                  ]}
                >
                  <Input.Password onFocus={() => onFocusPassword(true)} onBlur={() => onFocusPassword(false)} placeholder="Password" />
                </Form.Item>
              </Col>
              
              <Col xs={22} sm={22} md={11}>
                <Form.Item 
                  className="mb-16"
                  name="confirmPassword" 
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    { required: true, message: 'Confirm Password is required!' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Passwords do not match!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirm Password" />
                </Form.Item>
              </Col>

              <Col className="pt-4 dark-color pl-0" xs={2} sm={2} md={1}>
                <Tooltip visible={visible} placement="right" title="Password with at least 8 characters, 1 CAPITAL and 1 number">
                  <InfoCircleTwoTone onMouseLeave={() => onFocusPassword(false)} onMouseEnter={() => onFocusPassword(true)} twoToneColor="#0076de" />
                </Tooltip>
              </Col>
              
              <Col xs={24} sm={24} md={24}>
                <Form.Item 
                  className="mb-16"
                  name="mobile"
                  validateFirst
                  rules={[
                    { required: true, message: 'Phone Number is required!' },
                    { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },
                    { min: 7, max: 13, message: 'Your mobile number is invalid' },
                    // { validator(rule, value, callback){
                    //     if(idCallingCode === 99)
                    //       if(value.charAt(0) === '0' || value.charAt(0) === '8' || value.charAt(0) === ''){
                    //         callback()
                    //       }else{
                    //         callback('Invalid phone number')
                    //       }
                    //   }
                    // }
                  ]}
                >
                  <Input onBlur={(e) => onCheckMobile(e.target.value)} addonBefore={prefixSelector} placeholder="Type your primary phone number" />
                </Form.Item>
              </Col>
              {/* <Col xs={24} sm={24} md={24}>
                <Form.Item 
                  className="mb-16"
                  name="xpertTime"
                >
                  <Select 
                    className="width-100 border-radius-6"
                    placeholder="Select location and date"
                  >
                    <Option key="1" value="Malang Day 1, 23 November 2021">Malang Day 1, 23 November 2021</Option>
                    <Option key="2" value="Malang Day 2, 24 November 2021 (Khusus Alumni & Mahasiswa Poltekkes malang)">Malang Day 2, 24 November 2021 (Khusus Alumni & Mahasiswa Poltekkes malang)</Option>
                    <Option key="3" value="Surabaya Day 1, 26 November 2021">Surabaya Day 1, 26 November 2021</Option>
                    <Option key="4" value="Surabaya Day 2, 27 November 2021 (Khusus Alumni & Mahasiswa UNUSA Surabaya)">Surabaya Day 2, 27 November 2021 (Khusus Alumni & Mahasiswa UNUSA Surabaya)</Option>
                    <Option key="5" value="Jakarta Day 1, 3 Desember 2021">Jakarta Day 1, 3 Desember 2021</Option>
                    <Option key="6" value="Jakarta Day 2, 4 Desember 2021">Jakarta Day 2, 4 Desember 2021</Option>
                    <Option key="7" value="Semarang coming soon!">Semarang coming soon!</Option>
                    <Option key="8" value="Bandung coming soon!">Bandung coming soon!</Option>
                  </Select>
                </Form.Item>
              </Col> */}
              <Col xs={24} sm={24} md={24}>
                <Form.Item 
                  className="mb-16"
                  name="agree"
                  rules={[
                    { 
                      validator(rule, value, callback){
                        if(!agree){
                          callback('Please check this box to proceed your account')
                        }else{
                          callback()
                        }
                      }
                    }
                  ]}
                >
                  <Checkbox className="fs-11 dark-color" onChange={onChangeCheck} checked={agree}>
                    By creating an account you agree to the 
                    <Link className="info-color" to="/terms-and-conditions" target="_blank"> terms and conditions</Link> and 
                    <Link className="info-color" to="/privacy-policy" target="_blank"> privacy policy </Link>
                    {/* and  */}
                    {/* <Link className="info-color" to="#"> end-user license agreement</Link> */}
                  </Checkbox>
                </Form.Item>
              </Col>
              
              <Col xs={24} sm={24} md={24}>
                <Form.Item className="mb-8">
                  <CButton type="primary" htmlType="submit" className="login-form-button" loading={submitLoading} block title="Create Account"/>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  )
}
