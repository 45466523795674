import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Grid, Space, Button, Typography, Image, Modal, Form, Input, Radio, Select } from 'antd';
import CButton from '../../components/Button';
import Women from '../../assets/img/employer/women.png';
import Checked from '../../assets/img/employer/checked.png';

const { useBreakpoint } = Grid;
const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

function GetTranslation() {
  const t = useTranslation()
  return t;
}

export default function question(props) {
  const { lg, xxl } = GetBreakPoint()
  const { t } = GetTranslation()
  const { visible, showModal, handleCancel, formRef, onFinish, detail, onChangeCode, onChangeDetail, getCountry, visibleSuccess } = props
  
  const prefixSelector = (
    <Form.Item name="code" noStyle>
      <Select className="border-radius-3" onChange={onChangeCode} showSearch>
        {
          getCountry?.data?.map(item =>
            <Option text={item.callingCode} key={item.id} value={`(+${item.callingCode}) ${item.name}`}>{`(+${item.callingCode}) ${item.name}`}</Option>
          )
        }
      </Select>
    </Form.Item>
  )
  
  const onCheckMobile = (e) => {
    formRef.current.setFieldsValue({
      mobilePhone: e.replace(/^0+/, '')
    })
  }

  return (
    <React.Fragment>
      <Row style={{ width: '100%', backgroundColor: '#fff', paddingBottom: lg ? 64 : 32 }}>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Row justify="space-around" align="middle" gutter={[0, 32]}>
            <Col xxl={12} xl={16} xs={24}>
              <Space direction="vertical" size={xxl ? 32 : lg ? 16 : 8}>
                <Text>
                  <Text style={{ fontSize: xxl ? 36 : lg ? 28 : 22, fontWeight: 'bold', color: '#32ab6d' }}>{t('pageEmployer.question.title')} </Text>
                  <Text style={{ fontSize: xxl ? 36 : lg ? 28 : 22, fontWeight: 'bold', color: '#4273b9' }}>{t('pageEmployer.question.title2')}</Text>
                </Text>
                <Text className="dark-color" style={{ fontSize: xxl ? 20 : lg ? 18 : 16, fontWeight: 'normal', lineHeight: 1.2 }}>{t('pageEmployer.question.subTitle')}</Text>
                
              </Space>
            </Col>
            <Col xxl={3} xl={1} xs={2}></Col>
            <Col className="text-align-center" xxl={6} xl={6} xs={20}>
              <Image
                width={'80%'}
                src={Women}
                preview={false}
                style={{ marginBottom: -50 }}
              />
              <Button className="box-shadow text-align-center" type="primary" onClick={showModal} style={{ border: '#4273b9', background: '#4273b9', borderRadius: 32, height: 50, boxShadow: '0px 4px 6px rgb(70 103 152 / 1)', width: '100%', fontSize: 22 }}>{t('pageEmployer.question.button')}</Button>
              <Modal 
                visible={visible} 
                onCancel={handleCancel}
                footer={false}
                centered
                bodyStyle={{ padding: 32 }}
                width={800}
                destroyOnClose
              >
                <Row>
                  <Col span={22} offset={1}>
                    <Row className="text-align-center">
                      <Col span={24}>
                        <Text className="dark-color" style={{ fontSize: 24, fontWeight: 'normal', lineHeight: 1 }}>{t('pageEmployer.formRegister.title')}</Text>
                      </Col>
                    </Row>

                    <Form 
                      className="pt-32" 
                      ref={formRef} 
                      onFinish={onFinish} 
                      layout="vertical"
                      initialValues={{ 
                        code: '(+62) Indonesia',
                        isDetail: false
                      }}
                    >
                      <Row gutter={8}>
                        <Col xs={24} md={12} style={{ marginBottom: -8 }}>
                          <Form.Item 
                            className="mb-16"
                            name="name" 
                            label={t('pageEmployer.formRegister.contactPersonName')}
                            validateFirst
                            rules={[
                              { required: true, message: t('required') },
                              { pattern: /^[a-zA-Z ]*$/, message: t('a-zA-Z') },
                              { min: 3, message: t('min',{number:3}) },
                              { max: 50, message: t('max',{number:50}) }
                            ]}
                          >
                            <Input className="border-radius-3" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12} style={{ marginBottom: -8 }}>
                          <Form.Item 
                            className="mb-16"
                            name="email" 
                            label={t('pageEmployer.formRegister.contactPersonEmail')}
                            validateFirst
                            rules={[
                              { required: true, message: t('required') },
                              { type: 'email', message: t('typeEmail') },
                            ]}
                          >
                            <Input className="border-radius-3" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={24} style={{ marginBottom: -8 }}>
                          <Form.Item 
                            className="mb-16"
                            name="mobilePhone"
                            label={t('pageEmployer.formRegister.contactPersonMobileNumber')}
                            validateFirst
                            rules={[
                              { required: true, message: t('required') },
                              { pattern: /^[0-9]*$/, message: t('0-9') },
                              { min: 7, max: 13, message: t('typeMobileNumber') },
                            ]}
                          >
                            <Input className="border-radius-3" onBlur={(e) => onCheckMobile(e.target.value)} addonBefore={prefixSelector} placeholder={t('pageEmployer.formRegister.placeholderMobileNumber')} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={24} style={{ marginBottom: -8 }}>
                          <Form.Item name="isDetail" label={t('pageEmployer.formRegister.details')}>
                            <Radio.Group onChange={onChangeDetail} value={detail}>
                              <Radio value={true}>Yes</Radio>
                              <Radio value={false}>No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        {
                          detail ?
                            <React.Fragment>
                              <Col xs={24} md={12} style={{ marginBottom: -8 }}>
                                <Form.Item 
                                  className="mb-16"
                                  name="role" 
                                  label={t('pageEmployer.formRegister.roles')}
                                  validateFirst
                                  rules={[
                                    { required: true, message: t('required') },
                                    { pattern: /^([^0-9]*)$/, message: t('notNumber') },
                                    { min: 3, message: t('min',{number:3}) },
                                    { max: 100, message: t('max',{number:100}) },
                                  ]}
                                >
                                  <Input className="border-radius-3" />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={12} style={{ marginBottom: -8 }}>
                                <Form.Item 
                                  name="numberHired" 
                                  label={t('pageEmployer.formRegister.numberHired')}
                                  validateFirst
                                  rules={[
                                    { pattern: /^[0-9]*$/, message: t('0-9') },
                                    { max: 4, message: t('max',{number:4}) }
                                  ]}
                                >
                                  <Input className="border-radius-3" />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={12} style={{ marginBottom: -8 }}>
                                <Form.Item name="urgentType" label={t('pageEmployer.formRegister.urgent')}>
                                <Select className="border-radius-3" placeholder={t('pageEmployer.formRegister.placeholerUrgent')}>
                                  <Option value="URGENT">{t('pageEmployer.formRegister.optionUrgent')}</Option>
                                  <Option value="NEXT_MONTH">{t('pageEmployer.formRegister.optionNextMonth')}</Option>
                                  <Option value="TALENT_POOL">{t('pageEmployer.formRegister.optionTalentPool')}</Option>
                                </Select>
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={12} style={{ marginBottom: -8 }}>
                                <Form.Item 
                                  name="note" 
                                  label={t('pageEmployer.formRegister.notes')}
                                  rules={[
                                    { max: 100, message: t('max',{number:100}) }
                                  ]}
                                >
                                  <Input className="border-radius-3" />
                                </Form.Item>
                              </Col>
                            </React.Fragment>
                          :
                            <React.Fragment>
                              <Col xs={24} md={24} style={{ marginBottom: -8 }}>
                                <Form.Item 
                                  className="mb-16"
                                  name="hiringNote" 
                                  label={t('pageEmployer.formRegister.hiringNotes')}
                                  validateFirst
                                  rules={[
                                    { required: true, message: t('required') },
                                    { min: 5, message: t('min',{number:5}) },
                                    { max: 250, message: t('max',{number:250}) },
                                  ]}
                                >
                                  <TextArea rows={3} />
                                </Form.Item>
                              </Col>
                            </React.Fragment>
                        }
                        <Col xs={24} md={12} style={{ marginBottom: -8 }}>
                          <Form.Item 
                            name="companyName" 
                            label={t('pageEmployer.formRegister.companyName')}
                            rules={[
                              { max: 50, message: t('max',{number:50}) }
                            ]}
                          >
                            <Input className="border-radius-3" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12} style={{ marginBottom: -8 }}>
                          <Form.Item 
                            name="companyLocation" 
                            label={t('pageEmployer.formRegister.companyLocation')}
                            rules={[
                              { pattern: /^([^0-9]*)$/, message: t('notNumber') },
                              { max: 50, message: t('max',{number:50}) },
                            ]}
                          >
                            <Input className="border-radius-3" placeholder={t('pageEmployer.formRegister.companyLocation')} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                          <Form.Item style={ lg ? { marginTop: 30 } : null }>
                            <CButton className="width-100" htmlType="submit" type="save" title={t('pageEmployer.formRegister.button')}/>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Modal>

              <Modal 
                visible={visibleSuccess} 
                onCancel={handleCancel}
                footer={false}
                centered
                bodyStyle={{ padding: 40 }}
              >
                <Row className="text-align-center">
                  <Col span={24}>
                      <Image
                        src={Checked}
                        preview={false}
                        width={'50%'}
                      />
                    <Space direction="vertical" size={16}>
                      <Text style={{ fontSize: lg ? 24 : 22, fontWeight: 'normal', color: '#32ab6d' }}>Thank you for your interest!</Text>
                      <Space direction="vertical" size={-4}>
                        <Text style={{ fontSize: lg ? 16 : 14, fontWeight: 'normal', color: '#8b8b8b' }}>Our team will reach you within the days.</Text>
                        <Text style={{ fontSize: lg ? 16 : 14, fontWeight: 'normal', color: '#8b8b8b' }}>We need some time to review your request.</Text>
                      </Space>
                      <Button type="primary" style={{ width: '100%' }} onClick={handleCancel}>Back to homepage</Button>
                    </Space>
                  </Col>
                </Row>
              </Modal>
            </Col>
            <Col xxl={3} xl={1} xs={2}></Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}