import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import moment from 'moment';
import Form1 from './form1';
import Form2 from './form2';
import Form3 from './form3';
import Form4 from './form4';
import Form5 from './Form5';
import Form6 from './form6';
import Form7A from './form7A';
import Form7B from './form7B';
import Form8 from './form8';
import Form9 from './form9';
import Form10 from './form10';
import FormFeedback from './formFeedback';
import { Loading } from '../../../../components';
import { indexProfile, unmountIndexProfile } from '../../../../redux/actions/profile/profileAction';
import { indexStudyProgram, unmountIndexStudyProgram } from '../../../../redux/actions/master/studyProgram/studyProgramAction';
import { listProvinceByCountry, unmountlistProvinceByCountry } from '../../../../redux/actions/master/province/provinceAction';
import { listCityByProvince, unmountlistCityByProvince } from '../../../../redux/actions/master/city/cityAction';
import { listInstitution, unmountIndexInstitution } from '../../../../redux/actions/master/institution/institutionAction';
import { listFieldOfStudy, unmountIndexFieldOfStudy } from '../../../../redux/actions/master/fieldOfStudy/fieldOfStudyAction';
import { addTracerStudy } from '../../../../redux/actions/profile/tracerStudyAction';
import { Row, Col, Card, Image, Progress, message } from 'antd';
import LeftLogo from '../../../../assets/img/candidate/_left-logo.png';
import Logo from '../../../../assets/img/candidate/_logo-univ-binawan.png';
import RightLogo from '../../../../assets/img/candidate/_right-logo.png';

export class TracerStucy extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      activePage: 1,
      form1: {
        endYear: null
      },
      form2: {
        activity: "1"
      },
      form3: {
        startJob: "1",
        lainnya: false
      },
      form4: {
        status: "1"
      },
      form5: {
        lulus: "1",
        jenisInstansi: null
      },
      form6: {
        pembiyayaan: null
      },
      form9: {
        kesesuaian: "1",
      },
      form10: {
        lainnya: false,
      },
      metaInstitution: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaFieldOfStudy: {
        page: 1,
        perpage: 10,
        search: ''
      },
      f1: null,
      f2: null,
      f3: null,
      f4: null,
      f5: null,
      f6: null,
      f7a: null,
      f7b: null,
      f8: null,
      f9: null,
      f10: null
    }
    this.onSearchInstitution = debounce(this.onSearchInstitution.bind(this), 800)
    this.onSearchFieldOfStudy = debounce(this.onSearchFieldOfStudy.bind(this), 800)
  }

  componentDidMount() {
    const { actionGetProfile, actionGetStudyProgram, actionGetProvince } = this.props
    window.scrollTo(0,0)
    return (actionGetProfile(), actionGetStudyProgram(), actionGetProvince(99))
  }

  onChangeEndYear = (date, dateString) => {
    this.setState({
      form1: { endYear: dateString }
    })
  }

  handleRadioActivity = (e) => {
    this.setState({ form2: { activity: e.target.value } })
  }

  handleRadioStartJob = (e) => {
    const { form3 } = this.state
    this.setState({ form3: { startJob: e.target.value, lainnya: form3.lainnya } })
  }

  handleRadioStatus = (e) => {
    this.setState({ form4: { status: e.target.value }, f5: null })
  }

  handleCheckboxLainnya = (e) => {
    const { form3 } = this.state
    this.setState({ form3: { startJob: form3.startJob, lainnya: e.target.checked } })
  }

  handleProvince = (value, option) => {
    // const optionSelected = Number(option.key)
    const { actionGetCity } = this.props
    return actionGetCity(option.key)
  }

  handleJenisInstansi = (e) => {
    this.setState({ form5: { jenisInstansi: e } })
  }

  onSearchInstitution = value => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;

    metaInstitution.page = 1
    metaInstitution.perpage = 10
    metaInstitution.search = value

    return actionListInstitution(metaInstitution)
  }

  onFocusInstitution = () => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;
    return actionListInstitution(metaInstitution)
  }

  onSearchFieldOfStudy = value => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;

    metaFieldOfStudy.page = 1
    metaFieldOfStudy.perpage = 10
    metaFieldOfStudy.search = value

    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  onFocusFieldOfStudy = () => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;
    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  handlePembiyayaan = (e) => {
    this.setState({ form6: { pembiyayaan: e } })
  }

  handleKesesuaian = (e) => {
    this.setState({ form9: { kesesuaian: e.target.value } })
  }

  handleCheckboxLainnyaF10 = (e) => {
    this.setState({ form10: { lainnya: e.target.checked } })
  }

  handlePrev = () => {
    const { activePage, form4: { status }, form9: { kesesuaian } } = this.state
    if(activePage === 6 && Number(status) > 3){
      this.setState({ activePage: activePage - 2 })
    }else if(activePage === 12 && kesesuaian === "1"){
      this.setState({ activePage: activePage - 2 })
    }else{
      this.setState({ activePage: activePage - 1 })
    }
    window.scrollTo(0,0)
  }

  onFinish = (values) => {
    const { activePage, form1: { endYear }, form4: { status }, form9: { kesesuaian } } = this.state
    const { getProfile: { data }, actionAddTracerStudy } = this.props

    if(activePage !== 12){
      if(activePage === 4 && Number(status) > 3){
        this.setState({ activePage: activePage + 2 })
      }else if(activePage === 10 && kesesuaian === "1"){
        this.setState({ activePage: activePage + 2 })
      }else{
        this.setState({ activePage: activePage + 1 })
      }
    }

    if(activePage === 1){
      values.kdptimsmh = '031064'
      values.nmmhsmsmh = data.fullName
      values.telpomsmh = `${data.callingCode}${data.mobilePhone}`
      values.emailmsmh = data.email
      values.tahunLulus = endYear ? endYear : values.tahunLulus._i
      this.setState({ f1: values })
    }

    if(activePage === 2){
      this.setState({ f2: values })
    }

    if(activePage === 3){
      values.f402 = values.f402 ? "1" : null
      values.f403 = values.f403 ? "1" : null
      values.f404 = values.f404 ? "1" : null
      values.f405 = values.f405 ? "1" : null
      values.f407 = values.f407 ? "1" : null
      values.f408 = values.f408 ? "1" : null
      values.f409 = values.f409 ? "1" : null
      values.f410 = values.f410 ? "1" : null
      values.f411 = values.f411 ? "1" : null
      values.f412 = values.f412 ? "1" : null
      values.f413 = values.f413 ? "1" : null
      values.f414 = values.f414 ? "1" : null
      values.f415 = values.f415 ? "1" : null
      this.setState({ f3: values })
    }

    if(activePage === 4){
      values.f8 = values.status === "1" || values.status === "2" ? "1" : values.status === "7" ? "2" : null
      values.f901 = values.status === "3" ? "1" : null
      values.f902 = values.status === "4" ? "1" : null
      values.f903 = values.status === "5" ? "1" : null
      values.f904 = values.status === "6" ? "1" : null
      this.setState({ f4: values })
    }

    if(Number(status) < 4 && activePage === 5){
      if(status === "1"){
        values.f502 = values.f501 === "1" ? values.f502orf503 : null
        values.f503 = values.f501 === "2" ? values.f502orf503 : null
        this.setState({ f5: values, f6: null })
      }
      if(status === "2"){
        this.setState({ f5: values, f6: null })
      }
      if(status === "3"){
        values.nextJoinInstitutionDate = moment(values.nextJoinInstitutionDate).format("YYYY-MM-DD")
        this.setState({ f5: values, f6: null })
      }
    }

    if(activePage === 6){
      this.setState({ f6: values })
    }

    if(activePage === 7){
      this.setState({ f7a: values })
    }

    if(activePage === 8){
      this.setState({ f7b: values })
    }

    if(activePage === 9){
      this.setState({ f8: values })
    }

    if(activePage === 10){
      values.f1601 = values.f1601 === "1" ? values.f1601 : null
      this.setState({ f9: values })
    }

    if(activePage === 11){
      values.f1602 = values.f1602 ? "2" : null
      values.f1603 = values.f1603 ? "3" : null
      values.f1604 = values.f1604 ? "4" : null
      values.f1605 = values.f1605 ? "5" : null
      values.f1606 = values.f1606 ? "6" : null
      values.f1607 = values.f1607 ? "7" : null
      values.f1608 = values.f1608 ? "8" : null
      values.f1609 = values.f1609 ? "9" : null
      values.f1610 = values.f1610 ? "10" : null
      values.f1611 = values.f1611 ? "11" : null
      values.f1612 = values.f1612 ? "12" : null
      values.f1613 = values.f1613 ? "13" : null
      this.setState({ f10: values })
    }

    if(activePage === 12){
      const allValues = {...this.state.f1, ...this.state.f2, ...this.state.f3, ...this.state.f4, ...this.state.f5, ...this.state.f6, ...this.state.f7a, ...this.state.f7b, ...this.state.f8, ...this.state.f9, ...this.state.f10, ...values}

      return this.setState({ submitLoading: true }, () => {
        return actionAddTracerStudy(allValues, response => {
          if(response.code === '1000'){
            setTimeout(() => {
              this.setState({ submitLoading: false })
              this.props.history.push({
                pathname: '/candidate/profile/tracer-study/success',
                state: response.data
              })
            }, 3000)
          }else{
            message.error(response.message)
          }
        }, (err) => {
          return this.setState({ submitLoading: false }, () => message.error(err))
        })
      })
    }
    window.scrollTo(0,0)
  }
  
  render() {
    const { activePage } = this.state
    const { getProfile } = this.props

    const initialProps = {
      formRef: this.formRef,
      onChangeEndYear: this.onChangeEndYear,
      handleRadioActivity: this.handleRadioActivity,
      handleRadioStartJob: this.handleRadioStartJob,
      handleRadioStatus: this.handleRadioStatus,
      handleCheckboxLainnya: this.handleCheckboxLainnya,
      handleProvince: this.handleProvince,
      handleJenisInstansi: this.handleJenisInstansi,
      onFocusInstitution: this.onFocusInstitution,
      onSearchInstitution: this.onSearchInstitution,
      onFocusFieldOfStudy: this.onFocusFieldOfStudy,
      onSearchFieldOfStudy: this.onSearchFieldOfStudy,
      handlePembiyayaan: this.handlePembiyayaan,
      handleKesesuaian: this.handleKesesuaian,
      handleCheckboxLainnyaF10: this.handleCheckboxLainnyaF10,
      handlePrev: this.handlePrev,
      onFinish: this.onFinish
    }

    if(getProfile.loading){
      return <Loading />
    }

    const percentProgress = 
      activePage === 1 ? 8
      : activePage === 2 ? 16
      : activePage === 3 ? 24
      : activePage === 4 ? 32
      : activePage === 5 ? 40
      : activePage === 6 ? 48
      : activePage === 7 ? 56
      : activePage === 8 ? 64
      : activePage === 9 ? 72
      : activePage === 10 ? 80
      : activePage === 11 ? 88
      : 100

    return (
      <React.Fragment>
        <Row className="padding-16">
          <Col md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
            <Card 
              className="mb-16 border-radius-6 box-shadow"
              bordered={false} 
            >
              <Row justify="space-around" align="middle">
                <Col span={10}>
                  <Image
                    src={LeftLogo}
                    preview={false}
                  />
                </Col>
                <Col className="text-align-center" span={4}>
                  <Image
                    src={Logo}
                    width={'60%'}
                    preview={false}
                  />
                </Col>
                <Col span={10}>
                  <Image
                    src={RightLogo}
                    preview={false}
                  />
                </Col>
              </Row>

              <Progress className='mt-16 mb-16' percent={percentProgress} showInfo={false} strokeColor={'#6cca2d'} />
              
              {
                activePage === 1 ?
                  <Form1 {...initialProps} {...this.props} {...this.state} />
                : activePage === 2 ?
                  <Form2 {...initialProps} {...this.props} {...this.state} />
                : activePage === 3 ?
                  <Form3 {...initialProps} {...this.props} {...this.state} />
                : activePage === 4 ?
                  <Form4 {...initialProps} {...this.props} {...this.state} />
                : activePage === 5 ?
                  <Form5 {...initialProps} {...this.props} {...this.state} />
                : activePage === 6 ?
                  <Form6 {...initialProps} {...this.props} {...this.state} />
                : activePage === 7 ?
                  <Form7A {...initialProps} {...this.props} {...this.state} />
                : activePage === 8 ?
                  <Form7B {...initialProps} {...this.props} {...this.state} />
                : activePage === 9 ?
                  <Form8 {...initialProps} {...this.props} {...this.state} />
                : activePage === 10 ?
                  <Form9 {...initialProps} {...this.props} {...this.state} />
                : activePage === 11 ?
                  <Form10 {...initialProps} {...this.props} {...this.state} />
                : activePage === 12 ?
                  <FormFeedback {...initialProps} {...this.props} {...this.state} />
                : null
              }
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountIndexProfile, unmountIndexStudyProgram, unmountlistProvinceByCountry, unmountlistCityByProvince, unmountIndexInstitution, unmountIndexFieldOfStudy } = this.props
    return (unmountIndexProfile(), unmountIndexStudyProgram(), unmountlistProvinceByCountry(), unmountlistCityByProvince(), unmountIndexInstitution(), unmountIndexFieldOfStudy())
  }
}

const mapStateToProps = (state) => ({
  getProfile: state.profileReducer,
  getProvince: state.provinceReducer,
  getCity: state.cityReducer,
  getStudyProgram: state.studyProgramReducer,
  getInstitution: state.institutionReducer,
  getFieldOfStudy: state.fieldOfStudyReducer
})

const mapDispatchToProps = {
  actionGetProfile: indexProfile,
  unmountIndexProfile: unmountIndexProfile,
  actionGetStudyProgram: indexStudyProgram,
  unmountIndexStudyProgram: unmountIndexStudyProgram,
  actionGetProvince: listProvinceByCountry,
  unmountlistProvinceByCountry: unmountlistProvinceByCountry,
  actionGetCity: listCityByProvince,
  unmountlistCityByProvince: unmountlistCityByProvince,
  actionListInstitution: listInstitution,
  unmountIndexInstitution: unmountIndexInstitution,
  actionListFieldOfStudy: listFieldOfStudy,
  unmountIndexFieldOfStudy: unmountIndexFieldOfStudy,
  actionAddTracerStudy: addTracerStudy,

}

export default connect(mapStateToProps, mapDispatchToProps)(TracerStucy)
