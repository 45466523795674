import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Row, Col, Typography, Card, Skeleton, Image, Space, Pagination, Empty, message } from 'antd';
import { listFavorite, unmountFavorite, addOrDeleteFavorite } from '../../../redux/actions/favorite/favoriteAction';
import { SafetyOutlined, EnvironmentOutlined } from '@ant-design/icons';
import EmptyLogo from '../../../assets/img/EmptyLogo.png';
import CButton from '../../../components/Button';

const { Text } = Typography

export class Favorite extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      params: {
        page: 1,
        perpage: 10
      },
      submitLoading: false
    }
  }
  
  componentDidMount() {
    const { params } = this.state
    const { actionListFavorite } = this.props

    return actionListFavorite(params)
  }
  
  handlePagination = (pageNumber) => {
    const { params: { perpage } } = this.state
    const params = {
      page: pageNumber,
      perpage: perpage,
    }
    this.setState({ params })
    window.scrollTo(0, 0);
    const { actionListFavorite } = this.props
    return actionListFavorite(params)
  }

  handleRemove = (jobId) => {
    const { params } = this.state
    const { actionAddOrDeleteFavorite, actionListFavorite, t } = this.props
    
    const values = {
      jobId
    }

    return this.setState({ submitLoading: true }, () => {
      return actionAddOrDeleteFavorite(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false }, () => {
            message.success(t('jobList.jobRemove'))
            return actionListFavorite(params)
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const { getListFavorite, t } = this.props
    
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Favorite Jobs');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - {t('jobList.favoriteJobs')}</title>
        </Helmet>
        <Row className="padding-16">
          <Col className="width-100" md={{ span: 20, offset: 2 }}>
            <Row className="mb-32">
              <Col>
                <Text className="dark-color fs-20">{t('jobList.favoriteJobs')}</Text>
              </Col>
            </Row>
            <Row className="mb-8">
              <Col>
                {
                  getListFavorite.loading ?
                    <Skeleton.Input style={{ width: '100%' }} active />
                  :
                    <Text className="dark-color fs-14">{`${t('jobList.showing')} ${getListFavorite?.meta?.total === 0 ? getListFavorite?.meta?.total : (getListFavorite?.meta?.page*getListFavorite?.meta?.perpage)-(getListFavorite?.meta?.perpage-1) }-${getListFavorite?.meta?.total === 0 ? getListFavorite?.meta?.total : getListFavorite?.meta?.page === getListFavorite?.meta?.pages ? getListFavorite?.meta?.total : getListFavorite?.meta?.page*getListFavorite?.meta?.perpage} ${t('jobList.of')} ${getListFavorite?.meta?.total} ${t('jobList.favoriteJobs')}`}</Text>
                }
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={24}>
                <Row className="mb-16">
                  <Col span={24}>
                    {
                      getListFavorite.loading ?
                        <Card className="border-radius-3 box-shadow mb-6" style={{ width: '100%' }}>
                          <Skeleton active avatar />
                        </Card>
                      :
                        getListFavorite?.data?.length > 0 ?
                          getListFavorite?.data?.map((item, i) => 
                            <Card key={i} className="border-radius-3 box-shadow mb-6" style={{ width: '100%' }}>
                              <Row className="width-100" gutter={16}>
                                <Col className="col-img-job" xs={24} sm={4} md={4}>
                                  {/* <Link to={`/candidate/company/${item.companyId}/${item.companyType}`}> */}
                                  <Link to={`/candidate/job/detail/${item.slug}`}>
                                    <Image
                                      src={item.companyLogoUrl ? item.companyLogoUrl : EmptyLogo}
                                      preview={false}
                                      width={'100%'}
                                      height={100}
                                      style={{ objectFit: 'contain' }}
                                    />
                                  </Link>
                                </Col>
                                <Col xs={24} sm={13} md={12}>
                                  <Space direction="vertical" size={0}>
                                    <Link to={`/candidate/job/detail/${item.slug}`}>
                                      <Text className="info-color fs-17">{item.title}</Text>
                                    </Link>
                                    <Link to={`/candidate/company/${item.companyId}/${item.companyType}`}>
                                      <Text className="company-job fs-13 dark-color">{item.companyName} {item.isVerified ? <SafetyOutlined className="primary-color" /> : null}</Text>
                                    </Link>
                                    <Text className="fs-13 dark-color"><EnvironmentOutlined /> {item.jobDestinationParentName ? `${item.jobDestinationName}, ${item.jobDestinationParentName}` : item.jobDestinationName}</Text>
                                    <CButton className="fs-12 mt-8" type="danger" size="small" action={() => this.handleRemove(item.jobId)} danger title={t('jobList.remove')}/>
                                  </Space>
                                </Col>
                                <Col xs={24} sm={7} md={8}>
                                  <Space direction="vertical" size={16} className="float-right">
                                    <Text className="float-right fs-11 dark-color">{`${t('favorite.postedOn')} ${item.postDate}`}</Text>
                                  </Space>
                                </Col>
                              </Row>
                          </Card>
                      )
                        :
                          <Card className="border-radius-3 box-shadow mb-6" style={{ width: '100%' }}>
                            <Empty />
                          </Card>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col className="text-align-center" span={24}>
                    <Pagination onChange={(pageNumber,total, range) => this.handlePagination(pageNumber, total, range)} current={getListFavorite?.meta?.page} pageSize={getListFavorite?.meta?.perpage || 10} total={getListFavorite?.meta?.total} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountFavorite } = this.props
    return unmountFavorite()
  }
}

const mapStateToProps = (state) => ({
  getListFavorite: state.favoriteReducer
})

const mapDispatchToProps = {
  actionListFavorite: listFavorite,
  unmountFavorite: unmountFavorite,
  actionAddOrDeleteFavorite: addOrDeleteFavorite
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Favorite))