import React, { Component } from 'react'
import { connect } from 'react-redux'
import XpertListCondition from './condition';
import { listXpert, unmountListXpert } from '../../../redux/actions/xpert/xpertAction'
import { indexCategory, unmountIndexCategory } from '../../../redux/actions/master/category/categoryAction'

export class XpertListComponent extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
  
    this.state = {
      params: {
       page: 1,
       perpage: 12,
       search: null,
       xpertType: 'EVENT,COURSE,TRAINING',
       category: null,
       priceType: null,
       postDate: null
      },
      valueSearch: null,
      visibleFilter: false
    }
  }

  componentDidMount() {
    const { params } = this.state
    const { actionGetXpert, actionIndexCategory } = this.props
    
    window.scrollTo(0, 0);
    
    return actionGetXpert(params, (res) => {
      actionIndexCategory()
    })
  }

  onSearch = (value) => {
    const { params } = this.state
    const { actionGetXpert } = this.props

    params.search = value
    params.page = 1
    
    return actionGetXpert(params, (res) => {
      this.setState({ params: res, valueSearch: value })
    })
  }

  handleType = (checkedValues) => {
    const { params } = this.state
    const { actionGetXpert } = this.props

    const strType = checkedValues.join()
    params.xpertType = strType !== '' ? strType : 'EVENT,COURSE,TRAINING'
    params.page = 1

    return actionGetXpert(params, (res) => {
      this.setState({ params: res })
    })
  }

  handleCategory = (checkedValues) => {
    const { params } = this.state
    const { actionGetXpert } = this.props

    const strType = checkedValues.join()
    params.category = strType
    params.page = 1

    return actionGetXpert(params, (res) => {
      this.setState({ params: res })
    })
  }

  handlePriceType = (e) => {
    const { params } = this.state
    const { actionGetXpert } = this.props

    params.priceType = e.target.value
    params.page = 1

    return actionGetXpert(params, (res) => {
      this.setState({ params: res })
    })
  }

  handleDate = (e) => {
    const { params } = this.state
    const { actionGetXpert } = this.props

    params.postDate = e.target.value
    params.page = 1


    return actionGetXpert(params, (res) => {
      this.setState({ params: res })
    })
  }

  handlePagination = (pageNumber) => {
    const { params } = this.state
    const { actionGetXpert } = this.props
    
    params.page = pageNumber

    window.scrollTo(0, 0);
    return actionGetXpert(params, (res) => {
      this.setState({ params: res })
    })
  }

  handleClearFilter = () => {
    const { params } = this.state
    const { actionGetXpert } = this.props

    this.setState({ valueSearch: null })
    
    params.page = 1
    params.search = null
    params.xpertType = 'EVENT,COURSE,TRAINING'
    params.category = null
    params.priceType = null
    params.postDate = null

    return actionGetXpert(params, (res) => {
      this.setState({ params: res })
    })
  }

  showFilter = () => {
    this.setState({
      visibleFilter: true
    })
  }
  
  onCloseFilter = () => {
    this.setState({
      visibleFilter: false
    })
  }

  render() {
    const { valueSearch, visibleFilter } = this.state

    const initialProps = {
      onSearch: this.onSearch,
      handleType: (checkedValues) => this.handleType(checkedValues),
      handleCategory: (checkedValues) => this.handleCategory(checkedValues),
      handleDate: (e) => this.handleDate(e),
      handlePriceType: (e) => this.handlePriceType(e),
      valueSearch: valueSearch,
      formRef: this.formRef,
      handleClearFilter: this.handleClearFilter,
      visibleFilter: visibleFilter,
      showFilter: this.showFilter,
      handlePagination: this.handlePagination,
      onCloseFilter: this.onCloseFilter
    }
    return (
      <XpertListCondition {...initialProps} {...this.props} />
    )
  }
  componentWillUnmount(){
    const { unmountListXpert, unmountIndexCategory } = this.props
    
    return (unmountListXpert(), unmountIndexCategory())
  }
}

const mapStateToProps = (state) => ({
  getCategory: state.categoryReducer,
  getXpert: state.xpertReducer
})

const mapDispatchToProps = {
  actionGetXpert: listXpert,
  unmountListXpert: unmountListXpert,
  actionIndexCategory: indexCategory,
  unmountIndexCategory: unmountIndexCategory
}

export default connect(mapStateToProps, mapDispatchToProps)(XpertListComponent)
