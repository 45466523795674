import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Info from './Info';
import Part1 from './Part1';

export class Section1 extends Component {
  render() {
    const { isSection, isPart } = this.props

    return (
      <React.Fragment>
        {/* Section 1 - INFO */}
        {isSection === 1 && isPart === 0 ? <Info {...this.props} /> : null}
        {/* Section 1 Q 1 - 5 */}
        {isSection === 1 && isPart === 1 ? <Part1 {...this.props} /> : null}
      </React.Fragment>
    )
  }
}

export default withTranslation()(Section1)