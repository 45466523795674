import React from 'react'
import { Row, Col, Image, Grid, Space, Typography } from 'antd';
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';
import Background from '../../assets/img/domestic/background.png'
import MBackground from '../../assets/img/domestic/m-bg.png'
import Women from '../../assets/img/domestic/women.png';
import Logo from '../../assets/img/logo/JobshubLogo.svg';
import { withTranslation } from 'react-i18next';

const { useBreakpoint } = Grid;
const { Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export function banner(props) {
  const { xs, md, xxl, sm } = GetBreakPoint()
  const { t } = props
   
  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          <ParallaxProvider>
            <ParallaxBanner layers={[{ image: xs ? MBackground : Background, amount: 0.3 }]} style={{ width: '100%', height: xs ? 450 : xxl ? 600 : 480 , boxShadow: '1px 4px 8px #e6e6e6' }}>
              <Row>
                <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }} sm={{ span: 22, offset: 2 }}>
                  {
                    md  ?
                      <Row justify="space-around" align="middle">
                        <Col xs={24} md={12}>
                          {
                            xxl || sm ?
                              <Space size={16} direction="vertical">
                                <Text className="dark-color" style={{ fontSize: sm ? 26 : 34, fontWeight: 'normal', lineHeight: 1.2 }}>{t('jobDomestic.titleBanner')}</Text>
                                <Image
                                  width={'60%'}
                                  src={Logo}
                                  preview={false}
                                />
                              </Space>
                            :
                              <Space direction="vertical">
                                <Text className="dark-color" style={{ fontSize: 28, fontWeight: 'normal', lineHeight: 1.2 }}>{t('jobDomestic.titleBanner')}</Text>
                                <Image
                                  width={'50%'}
                                  src={Logo}
                                  preview={false}
                                />
                              </Space>
                          }
                        </Col>
                        <Col xs={24} md={12}>
                          <Image
                            width={'100%'}
                            src={Women}
                            preview={false}
                            style={xxl ? { marginTop: 40, marginLeft: 120 } : { marginTop: 20, marginLeft: 40 }}
                          />
                        </Col>
                      </Row>
                    :
                      <Row justify="space-around" align="middle">
                        <Col xs={24} md={12}>
                          <Image
                            width={'100%'}
                            src={Women}
                            preview={false}
                            style={{ marginTop: 20, marginBottom: 20 }}
                          />
                        </Col>
                        <Col xs={24} md={12}>
                          <Space className="width-100 text-align-center" direction="vertical">
                            <Text className="dark-color" style={{ fontSize: 24, fontWeight: 'normal', lineHeight: 1.2 }}>{t('jobDomestic.titleBanner')}</Text>
                            <Image
                              width={'50%'}
                              src={Logo}
                              preview={false}
                            />
                          </Space>
                        </Col>
                      </Row>
                  }
                </Col>
              </Row>
            </ParallaxBanner>
          </ParallaxProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withTranslation()(banner)
