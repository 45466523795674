import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookie from 'js-cookie';
import { Layout, Image } from 'antd';
import Header from './Header';
import Footer from './Footer';
import { checkToken } from '../../redux/actions/auth/authAction'
import WhatsappButtonIcon from '../../assets/img/whatsapp-button-icon.png'
const { Content } = Layout;

export class MainAuth extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      pathname: null,
      visible: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    const { actionCheckToken } = this.props
    const getToken = Cookie.get('user')
    
    window.scrollTo(0, 0);

    if(getToken){
      actionCheckToken(() => {
        window.location.reload()
      })
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ pathname: this.props.location.pathname })
    }
  }

  showDrawer = () => {
    this.setState({
      visible: true
    })
  }

  onClose = () => {
    this.setState({
      visible: false
    })
  }

  render() {
    const { children, path } = this.props
    const { pathname, visible } = this.state
    
    return (
      <React.Fragment>
        <Layout style={ path === '/login-or-register' || path === '/login' || path === '/register' || path === '/employer/registration' || path === '/employer/registration/success' || path === '/xpert'  ? { minHeight: '100vh', overflow: 'hidden', background: '#fff' } : { position: 'relative', minHeight: '100vh' } }>
          <Header 
            pathname={pathname}
            visible={visible}
            showDrawer={this.showDrawer}
            onClose={this.onClose}
          />
          <Content style={{ maxWidth: '100%', overflowX: 'hidden', overflowY: 'clip' }}>
            { children }
          </Content>
          <div style={{ objectFit: 'contain', bottom: 30, right: 30, position: 'fixed', zIndex: 1000 }}>
            <a href="https://api.whatsapp.com/send?phone=6285179708195&text=Halo, saat ini saya sedang mengakses website Jobshub dan saya butuh bantuan" target="_blank" rel="noreferrer">
              <Image
                src={WhatsappButtonIcon}
                preview={false}
                width={60}
                height={60}
              />
            </a>
          </div>
          <Footer style={{ position: 'absolute', bottom: 0, width: '100%', height: '2.5rem' }} />
        </Layout>
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  actionCheckToken: checkToken
}

export default connect(mapStateToProps, mapDispatchToProps)(MainAuth)
