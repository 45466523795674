import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Form, Input, Card, Row, Col, Typography, Space, Tooltip, message } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { forgotPass } from '../../../redux/actions/forgotPassword/forgotPasswordAction';
import Cookie from 'js-cookie';
import CButton from '../../../components/Button';
;
const { Text } = Typography

export class ChangePassword extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      visible: false,
      lang: Cookie.get('i18next')
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.i18n.language !== this.state.lang) {
      if(this.formRef.current.getFieldsError()[0]?.errors.length > 0){
        this.updateValidationMessages();
      }
      this.setState({ lang: Cookie.get('i18next') })
    }
  }
  
  updateValidationMessages = () => {
    const { t } = this.props

    this.formRef.current.setFields([
      {
        name: 'newPassword',
        errors: [t('changePassword.requiredPassword')] || [t('changePassword.patternPassword')] || [t('changePassword.minPassword')]
      },
      {
        name: 'retypeNewPassword',
        errors: [t('required') ]
      }
    ]);
  }
  
  onFocusPassword = (value) => {
    this.setState({
      visible: value
    })
  }

  onFinish =  (values) => {
    const { actionForgotPass } = this.props

    values.username = localStorage.getItem('emailForgotPass')

    return this.setState({ submitLoading: true }, () => {
      actionForgotPass(values, response => {
        if(response.code === '1000'){
          message.success('Password updated successfully', () => {
            this.setState({ submitLoading: false })
            localStorage.removeItem('emailForgotPass')
            this.props.history.push('/login')
          })
        }else{
          message.error(response.message)
          this.setState({ submitLoading: false })
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const { submitLoading, visible } = this.state
    const { t } = this.props

    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Forgot Password - Change Password');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Forgot Password - Change Password</title>
        </Helmet>
        <Row className="display-flex" align="middle" justify="center">
          <Col>
            <Card className="card-forgot-password" bodyStyle={{ padding: 0, display: 'flex' }}>
              <Form 
                layout="vertical" 
                onFinish={this.onFinish}
              >
                <Row gutter={[20,20]}>
                  <Col span={24}>
                    <Space className="width-100" direction="vertical" size="small">
                      <Text className="fs-28" type="secondary">{t('changePassword.title')}</Text>
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Form.Item 
                      className="mb-16"
                      label={t('changePassword.newPassword')}
                      name="newPassword"
                      validateFirst
                      hasFeedback
                      rules={[
                        { required: true, message: t('changePassword.requiredPassword') },
                        { pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, message: t('changePassword.patternPassword') },
                        { min: 8, message: t('changePassword.minPassword') },
                      ]}
                    >
                      <Input.Password onFocus={() => this.onFocusPassword(true)} onBlur={() => this.onFocusPassword(false)} />
                    </Form.Item>
                  </Col>
                  
                  <Col span={22}>
                    <Form.Item 
                      className="width-100 mb-16"
                      label={t('changePassword.confirmPassword')}
                      name="retypeNewPassword" 
                      dependencies={['newPassword']}
                      hasFeedback
                      rules={[
                        { required: true, message: t('changePassword.requiredConfirm') },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('changePassword.passwordError')));
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>

                  <Col className="dark-color" span={2} style={{ paddingTop: 36, marginLeft: -8 }}>
                    <Tooltip visible={visible} placement="right" title={t('changePassword.tooltip')}>
                      <InfoCircleTwoTone className="fs-16" onMouseLeave={() => this.onFocusPassword(false)} onMouseEnter={() => this.onFocusPassword(true)} twoToneColor="#0076de" />
                    </Tooltip>
                  </Col>

                  <Col span={24}>
                    <Space className="width-100" direction="vertical">
                      <Form.Item className="mb-0">
                        <CButton type="primary" htmlType="submit" block loading={submitLoading} title={t('verify.submit')}/>
                      </Form.Item>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  actionForgotPass: forgotPass
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChangePassword))