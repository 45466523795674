import React from 'react';
import { Row, Col, Typography, Form, Input, InputNumber, Select, Radio } from 'antd';
import CButton from '../../../../../components/Button';
const { Text } = Typography
const { Option } = Select;

export default function FormPekerjaan(props) {
  const { formRef, form5, f5, getProvince, getCity, handleProvince, handleJenisInstansi, handlePrev, onFinish } = props
  return (
    <React.Fragment>
      <Row className="text-align-center mb-16 mt-16">
        <Col span={24}>
          <Text className="primary-color fs-17">Pekerjaan Saat Ini</Text>
        </Col>
      </Row>

      <Form 
        ref={formRef}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          f501: f5?.f501 ? f5.f501 : form5.lulus,
          companyName: f5?.companyName ? f5.companyName : null,
          f502orf503: f5?.f502 || f5?.f503 ? f5?.f502 || f5?.f503 : null,
          provinceId: f5?.provinceId ? f5.provinceId : null,
          f1301: f5?.f1301 ? f5.f1301 : null,
          cityId: f5?.cityId ? f5.cityId : null,
          f1302: f5?.f1302 ? f5.f1302 : null,
          f1101: f5?.f1101 ? f5.f1101 : null,
          f1102: f5?.f1102 ? f5.f1102 : null,
          f1303: f5?.f1303 ? f5.f1303 : null,
          gradeLocation: f5?.gradeLocation ? f5.gradeLocation : null
        }}
      >
        <Row gutter={16} justify="space-between" align="bottom">
          <Col xs={24} lg={12}>
            <Form.Item 
              name="f501"
              label="Apakah sudah mendapatkan pekerjaan sebelum lulus? (1 - 6 bulan sebelum kelulusan)"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <Radio.Group>
                <Radio key="1" value="1">Ya</Radio>
                <Radio key="2" value="2">Tidak</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item 
              name="companyName"
              label="Nama Perusahaan/Kantor Tempat kamu Bekerja:"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
                { pattern: /^[0-9a-zA-z -]*$/, message: 'Special characters not allowed' },
                { min: 3, message: 'At least 3 characters' },
                { max: 50, message: '50 characters only' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          
        </Row>

        <Row gutter={16} justify="space-between" align="bottom">
          <Col xs={24} lg={12}>
            <Form.Item 
              name="f502orf503"
              label="Dalam berapa bulan kamu mendapatkan pekerjaan?"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
                { pattern: /^[0-9]*$/, message: 'Number only' },
                { min: 1, message: 'At least 1 characters' },
                { max: 3, message: '3 characters only' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item 
              name="provinceId"
              label="Dimana Propinsi tempat kamu bekerja?"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <Select 
                className="width-100" 
                onChange={handleProvince}
                placeholder="Pilih Propinsi"
              >
                {
                  getProvince.data.map((item) => 
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                    )
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} justify="space-between" align="bottom">
          <Col xs={24} lg={12}>
            <Form.Item 
              name="f1301"
              label="Berapa pendapatan setiap bulan dari Pekerjaan Utama? (jika tidak ada, isi dengan 0)"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
                { pattern: /^[0-9]*$/, message: 'Number only' },
              ]}
            >
              <InputNumber className="width-100" maxLength={10} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item 
              name="cityId"
              label="Dimana Kota tempat kamu bekerja?"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <Select 
                className="width-100" 
                placeholder="Pilih Kabupaten/Kota"
              >
                {
                  getCity?.data?.map((item) => 
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                    )
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row className="mb-16" gutter={16} justify="space-between" align="bottom">
          <Col xs={24} lg={12}>
            <Form.Item 
              name="f1302" 
              label="Berapa pendapatan setiap bulan dari Lembur dan Tips? (jika tidak ada, isi dengan 0)"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <InputNumber className="width-100" maxLength={10} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item 
              name="f1101" 
              label="Jenis Perusahaan/Instansi/Institusi Tempat Bekerja"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <Select 
                className="width-100" 
                placeholder="Pilih Jawaban"
                onChange={handleJenisInstansi}
              >
                <Option key="1" value="1">Instansi pemerintah</Option>
                <Option key="6" value="6">BUMN/BUMD</Option>
                <Option key="7" value="7">Institusi/Organisasi Multilateral</Option>
                <Option key="2" value="2">Organisasi non-profit/Lembaga Swadaya Masyarakat</Option>
                <Option key="3" value="3">Perusahaan swasta</Option>
                <Option key="4" value="4">Wiraswasta/perusahaan sendiri</Option>
                <Option key="5" value="5">Lainnya</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {
          form5.jenisInstansi === "7" ?
            <Row gutter={16} style={{ marginTop: -32 }}>
              <Col xs={24} lg={12}></Col>
              <Col xs={24} lg={12}>
                <Form.Item 
                  name="f1102" 
                  label="sebutkan"
                  validateFirst
                  rules={[
                    { required: true, message: 'This field is mandatory!' },
                  ]}
                >
                  <Input className="width-100" />
                </Form.Item>
              </Col>
            </Row>
          : null
        }

        <Row className="mb-16" gutter={16} justify="space-between" align="bottom">
          <Col xs={24} lg={12}>
            <Form.Item 
              name="f1303" 
              label="Berapa pendapatan setiap bulan dari Pekerjaan Lainnya? (jika tidak ada, isi dengan 0)"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <InputNumber className="width-100" maxLength={10} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item 
              name="gradeLocation" 
              label="Tingkat tempat bekerja"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <Select 
                className="width-100" 
                placeholder="Pilih Jawaban"
              >
                <Option key="1" value="1">Lokal/Wilayah/Wiraswasta tidak berbadan hukum</Option>
                <Option key="2" value="2">Nasional/Wiraswasta berbadan hukum</Option>
                <Option key="3" value="3">Multinasional/Internasional</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <CButton className="width-100" type="primary" ghost action={handlePrev} title="Sebelumnya"/>
            </Col>
            <Col span={12}>
              <CButton className="width-100" htmlType="submit" type="primary" title="Selanjutnya"/>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </React.Fragment>
  )
}
