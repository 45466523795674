import { API } from '../../../config'

export const addDocument = (value, successCB, failedCB) => async dispatch => {
  API.POST_FORM_DATA('/candidate/document/add', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const updateDocument = (value, successCB, failedCB) => async dispatch => {
  API.POST_FORM_DATA('/candidate/document/update', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const deleteDocument = (value, successCB, failedCB) => async dispatch => {
  const params = { 
    id: value
  }
  API.POST_WITH_PARAMS('/candidate/document/delete', params).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}