import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Card, Typography, Modal, Input, Space, Form, Row, Col, DatePicker, List, Select, AutoComplete, Spin, message, Switch } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { listInstitution, unmountIndexInstitution } from '../../../../redux/actions/master/institution/institutionAction';
import { indexEducationLevel, unmountIndexEducationLevel } from '../../../../redux/actions/master/educationLevel/educationLevelAction';
import { listFieldOfStudy, unmountIndexFieldOfStudy } from '../../../../redux/actions/master/fieldOfStudy/fieldOfStudyAction';
import { addEducation, updateEducation, deleteEducation } from '../../../../redux/actions/profile/educationAction';
import Cookie from "js-cookie";
import CButton from '../../../../components/Button';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

export class Education extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      submitLoading:false,
      deleteLoading: false,
      metaInstitution: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaFieldOfStudy: {
        page: 1,
        perpage: 10,
        search: '',
        educationLevelId: ''
      },
      educationLevelId: null,
      isYear: false,
      startYear: null,
      endYear: null,
      website: null,
      id: null,
      dataEdit: null,
      isBinawan: false,
    }
    this.onSearchInstitution = debounce(this.onSearchInstitution.bind(this), 800)
    this.onSearchFieldOfStudy = debounce(this.onSearchFieldOfStudy.bind(this), 800)
  }

  onSearchInstitution = value => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;

    metaInstitution.page = 1
    metaInstitution.perpage = 10
    metaInstitution.search = value

    return actionListInstitution(metaInstitution)
  }

  onFocusInstitution = () => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;
    return actionListInstitution(metaInstitution)
  }

  onChangeInstitution = (e) => {
    this.setState({ isBinawan: e === 'Universitas Binawan' ? true : false })
  }

  onSearchFieldOfStudy = value => {
    const { metaFieldOfStudy, educationLevelId } = this.state;
    const { actionListFieldOfStudy } = this.props;

    metaFieldOfStudy.page = 1
    metaFieldOfStudy.perpage = 10
    metaFieldOfStudy.search = value
    metaFieldOfStudy.educationLevelId = educationLevelId

    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  onFocusFieldOfStudy = () => {
    const { metaFieldOfStudy, educationLevelId } = this.state;
    const { actionListFieldOfStudy } = this.props;

    metaFieldOfStudy.page = 1
    metaFieldOfStudy.perpage = 10
    metaFieldOfStudy.educationLevelId = educationLevelId

    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  showModal = (id) => {
    const { actionGetEducationLevel, data } = this.props
    const dataEdit = data.listEducation.filter(item => item.id === id).pop()
    this.setState({
      visible: true,
      id: id,
      educationLevelId: id ? dataEdit?.educationLevelId : null,
      startYear: id ? dataEdit?.startYear : null,
      endYear: id ? dataEdit?.endYear : null,
      website: id ? dataEdit?.website : null,
      current: id ? dataEdit?.current : false,
      isBinawan: id ? dataEdit?.name === 'Universitas Binawan' ? true : false : false,
      dataEdit: dataEdit
    })

    actionGetEducationLevel()
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      isYear: false,
      current: false,
      metaFieldOfStudy: {
        page: 1,
        perpage: 10,
        search: '',
        educationLevelId: ''
      }
    })
  };

  onChangeEducationLevel = (value) => {
    this.setState({
      educationLevelId: value
    })
    this.formRef.current.setFieldsValue({
      fieldOfStudy: null
    })
  }

  onChangeStartYear = (date, dateString) => {
    const { endYear } = this.state

    this.setState({
      startYear: dateString
    })
    
    if(endYear){
      if(endYear < dateString){
        this.setState({
          isYear: true
        })
      }else{
        this.setState({
          isYear: false
        })
      }
    }
  }

  handleChecked = (e) => {
    this.setState({
      current: e
    })
    if(e){
      this.setState({
        endYear: null
      })
      this.formRef.current.setFieldsValue({
        endYear: null
      })
    }
  }

  onChangeEndYear = (date, dateString) => {
    const { startYear } = this.state

    this.setState({
      endYear: dateString
    })

    if(dateString < startYear){
      this.setState({
        isYear: true
      })
    }else{
      this.setState({
        isYear: false
      })
    }
  }

  handleDelete = () => {
    const { actionDeleteEducation, actionGetProfile } = this.props
    const { id } = this.state
    
    return this.setState({ deleteLoading: true }, () => {
      return actionDeleteEducation(id, response => {
        if(response.code === '1000'){
          return this.setState({ deleteLoading: false, visible: false }, () => {
            message.success(response.message)
            Cookie.set("activeElement", window.pageYOffset)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ deleteLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  onFinish = async (values) => {
    const { t, actionAddEducation, actionUpdateEducation, actionGetProfile } = this.props
    const { educationLevelId, startYear, endYear, id, isYear, current } = this.state

    values.educationLevelId = educationLevelId
    values.startYear = startYear
    values.endYear = endYear
    values.id = id
    values.current = current
    
    if(isYear){
      message.error(t('validationEndDate'))
    }else{
      if(id){
        return this.setState({ submitLoading: true }, () => {
          return actionUpdateEducation(values, response => {
            if(response.code === '1000'){
              return this.setState({ submitLoading: false, visible: false }, () => {
                message.success(response.message)
                Cookie.set("activeElement", window.pageYOffset)
                return actionGetProfile()
              })
            }else{
              message.error(response.message)
            }
          }, (err) => {
            return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
          })
        })
      }else{
        return this.setState({ submitLoading: true }, () => {
          return actionAddEducation(values, response => {
            if(response.code === '1000'){
              return this.setState({ submitLoading: false, visible: false }, () => {
                message.success(response.message)
                Cookie.set("activeElement", window.pageYOffset)
                return actionGetProfile()
              })
            }else{
              message.error(response.message)
            }
          }, (err) => {
            return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
          })
        })
      }
    }
  }

  disabledDatePassport = (current) => {
    const { startYear } = this.state
    return current && current < moment(startYear)
  }


  
  render() {
    const { visible, submitLoading, deleteLoading, id, dataEdit, current, isBinawan, startYear } = this.state
    const { t, getInstitution, getEducationLevel, getFieldOfStudy, data: { listEducation, isTracerStudy } } = this.props

    return (
      <React.Fragment>
        <Card 
          className="border-radius-6 box-shadow" 
          title={t('profile.education.title')} 
          bordered={false}
          headStyle={{ color: '#32ab6d', fontSize: 17 }}
          bodyStyle={listEducation.length > 0 ? {} : { textAlign: 'center', padding: 60 }}
          extra={
            listEducation?.find(item => item?.name?.includes('binawan') || item?.name?.includes('Binawan') || item?.name?.includes('BINAWAN')) ? 
              isTracerStudy ?
                <Space>
                  <Text className="font-style-italic">{t('profile.education.titleTracerStudy')}</Text>
                  {/* <Link to='/candidate/profile/tracer-study'>
                    <Text className="primary-color float-right cursor-pointer"><EditFilled /> Edit</Text>
                  </Link> */}
                </Space>
              :
                <Link to='/candidate/profile/tracer-study'>
                  <CButton type="primary" ghost title={t('profile.education.buttonAddTracerStudy')}/>
                </Link> 
              : null
          }
          actions={[
            <Text className="primary-color" onClick={() => this.showModal(null)}>{t('profile.education.buttonAdd')}</Text>
          ]}
        >
          {
            listEducation.length > 0 ?
              <List
                dataSource={listEducation}
                renderItem={(item, i) => (
                  <List.Item key={i}>
                    <Row className="width-100">
                      <Col span={20}>
                        <Space direction="vertical" size={0}>
                          <Text className="primary-color">{item.name}</Text>
                          {
                            item.educationLevelName && item.fieldOfStudy ?
                              <Text className="light-color">{`${item.educationLevelName}, ${item.fieldOfStudy}`}</Text>
                            : item.educationLevelName ?
                              <Text className="light-color">{item.educationLevelName}</Text>
                            : item.fieldOfStudy ?
                              <Text className="light-color">{item.fieldOfStudy}</Text>
                            :
                              null
                          }


                          {
                            item.gpa ?
                              <Text className="light-color">{`GPA ${item.gpa}`}</Text>
                            :
                              null
                          }
                          {
                            item.startYear || item.endYear ?
                              <Text className="light-color">
                                {
                                  item.startYear && item.current ?
                                    `${item.startYear} - Present`
                                  : item.startYear && item.endYear ?
                                    `${item.startYear} - ${item.endYear}`
                                  : item.startYear || item.endYear
                                }
                              </Text>
                            :
                              null
                          }
                          {
                            item.website ? 
                              <Text className='light-color'>{item.website ? item.website : 'N/A'}</Text>
                            : 
                              null
                          }
                          {
                            item.experience ?
                              <Text className="light-color">{item.experience ? item.experience : 'N/A'}</Text>
                            :
                              null
                          }
                        </Space>
                      </Col>
                      <Col span={4}>
                        <Text className="primary-color float-right cursor-pointer" onClick={() => this.showModal(item.id)}><EditFilled /> Edit</Text>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            :
              <Text className="light-color">{t('profile.education.textCard')}</Text>
          }
        </Card>

        <Modal 
          title={t('profile.education.title')} 
          visible={visible} 
          onCancel={this.handleCancel} 
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          centered
          width={800}
          destroyOnClose
          maskClosable={false}
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
            initialValues={{
              name: id ? dataEdit?.name : undefined,
              educationLevelId: id ? dataEdit?.educationLevelName : null,
              fieldOfStudy: id ? dataEdit?.fieldOfStudy : undefined,
              gpa: id ? dataEdit?.gpa : null,
              startYear: id && dataEdit?.startYear ? moment(dataEdit?.startYear) : null,
              endYear: id && dataEdit?.endYear ? moment(dataEdit?.endYear) : null,
              website: id ? dataEdit?.website : null,
              experience: id ? dataEdit?.experience : null,
            }}
          >
            <Row className="mb-min-8" gutter={8}>
              <Col xs={24} lg={12}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">{t('profile.education.form.school')}</Text>
                </Space>
                <Form.Item 
                  name="name"
                  validateFirst
                  rules={[
                    { required: true, message: t('required') },
                    { min: 5, message: t('min',{number:5}) },
                    { max: 100, message: t('max',{number:100}) },
                  ]}
                > 
                  <AutoComplete
                    className="width-100"
                    onFocus={this.onFocusInstitution}
                    onSearch={this.onSearchInstitution}
                    onChange={this.onChangeInstitution}
                    notFoundContent={getInstitution.loading ? <Spin size="small" /> : null}
                    filterOption={false}
                    placeholder={t('profile.education.form.placeholderSchool')}
                  >
                    {
                      getInstitution?.data?.map((item, i) => (
                        <AutoComplete.Option key={i} value={item.name}>
                          {item.name}
                        </AutoComplete.Option>
                      ))
                    }
                  </AutoComplete>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">{t('profile.education.form.educationLevel')}</Text>
                </Space>
                <Form.Item 
                  name="educationLevelId"
                  rules={[
                    { required: true, message: t('required') }
                  ]}
                >
                  <Select 
                    className="width-100 border-radius-6"
                    onChange={this.onChangeEducationLevel}
                  >
                    {
                      getEducationLevel?.data?.map(item => 
                        <Option key={item.id} value={item.id}>{item.name}</Option>
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col xs={24} lg={12}>
                <Space size={4}>
                  {
                    isBinawan ? 
                      <Text className="fs-12" type="danger">*</Text>
                    : null
                  }
                  <Text className="fs-12" type="secondary">{t('profile.education.form.fieldOfStudy')}</Text>
                </Space>
                <Form.Item 
                  name="fieldOfStudy"
                  validateFirst
                  rules={[
                    { required: isBinawan, message: t('required') },
                    { min: 5, message: t('min',{number:5}) },
                    { max: 100, message: t('max',{number:100}) },
                  ]}
                >
                  <AutoComplete
                    className="width-100"
                    onFocus={this.onFocusFieldOfStudy}
                    onSearch={this.onSearchFieldOfStudy}
                    notFoundContent={getFieldOfStudy.loading ? <Spin size="small" /> : null}
                    filterOption={false}
                    placeholder={t('profile.education.form.placeholderfieldOfStudy')}
                  >
                    {
                      getFieldOfStudy?.data?.map((item, i) => (
                        <AutoComplete.Option key={i} value={item.name}>
                          {item.name}
                        </AutoComplete.Option>
                      ))
                    }
                  </AutoComplete>
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col xs={24} lg={12}>
                <Space size={4}>
                  {
                    isBinawan ? 
                      <Text className="fs-12" type="danger">*</Text>
                    : null
                  }
                  <Text className="fs-12" type="secondary">{t('profile.education.form.startYear')}</Text>
                </Space>
                <Form.Item 
                  name="startYear"
                  rules={[
                    { required: isBinawan, message: t('required') },
                  ]}
                >
                  <DatePicker inputReadOnly className="width-100" picker="year" placeholder={t('profile.education.form.selectYear')} onChange={this.onChangeStartYear} disabledDate={ d => !d || d.isBefore(moment().subtract(30, 'years').format('YYYY').toString()) || d.isAfter(moment().add(1, 'years').format('YYYY').toString()) } />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item name="current" 
                  style={{ marginTop: 20 }}
                >
                  <Text className="fs-12" type="secondary">{t('profile.education.form.onGoing')}</Text>
                  <Switch style={{ marginLeft: 10 }} onChange={this.handleChecked} checked={current}/>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Space size={4}>
                  {
                    isBinawan && !current ? 
                      <Text className="fs-12" type="danger">*</Text>
                    : null
                  }
                  <Text className="fs-12" type="secondary">{t('profile.education.form.endYear')}</Text>
                </Space>
                <Col>
                  {
                    current ?
                      <Text className="fs-12" type="secondary">{t('profile.education.form.onGoing')}</Text>
                    :
                      <Form.Item 
                        name="endYear"
                        // validateStatus={isYear && !current ? "error" : null}
                        // help={isYear && !current ? t('validationEndDate') : null}
                        rules={[
                          { required: isBinawan && !current, message: <Text style={{fontSize: '8', color: 'red'}}>{t('required')}</Text>} ,
                        ]}
                      >
                        {
                          current ?
                            <Text className="fs-12" type="secondary">{t('profile.education.form.onGoing')}</Text>
                          :
                            <DatePicker 
                              className="width-100" 
                              picker="year" 
                              placeholder={t('profile.education.form.selectYear')} 
                              onChange={this.onChangeEndYear} 
                              // disabledDate={ d => !d || d.isBefore(moment().subtract(30, 'years').format('YYYY').toString()) } 
                              disabledDate={startYear ? this.disabledDatePassport : null}
                            />
                        }
                      </Form.Item>
                  }
                </Col>
              </Col>
               <Col xs={24} lg={12}>
                <Space size={4}>
                  {
                    isBinawan ? 
                      <Text className="fs-12" type="danger">*</Text>
                    : null
                  }
                  <Text className="fs-12" type="secondary">{t('profile.education.form.score')}</Text>
                </Space>
                <Form.Item 
                  name="gpa"
                  rules={[
                    { required: isBinawan, message: t('required') },
                    { pattern: /^[0-9a-zA-Z.]*$/, message: t('onlyDot') },
                    { max: 4, message: t('max',{number:4}) },
                  ]}
                >
                  <Input placeholder={t('profile.education.form.placeholderscore')} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Space size={4}>
                  {
                    isBinawan ? 
                      <Text className="fs-12" type="danger">*</Text>
                    : null
                  }
                  <Text className="fs-12" type="secondary">{t('profile.education.form.website')}</Text>
                </Space>
                <Form.Item 
                  name="website"
                  validateFirst
                    rules={[
                      { required: isBinawan, message: t('required') },
                      { max: 50, message: t('max',{number:50}) },
                    ]}
                >
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Text className="fs-12" type="secondary">{t('profile.education.form.activityAndOrganization')}</Text>
                <Form.Item 
                  name="experience"
                  rules={[
                    { max: 200, message: t('max',{number:200}) }
                  ]}
                >
                  <TextArea rows={3} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              {
                id ?
                  <React.Fragment>
                    <CButton type='danger-outline' className="float-left" title={t('profile.buttonDelete')} action={this.handleDelete} loading={deleteLoading} danger/>
                    <CButton type="primary" className="float-right" title={t('profile.buttonSave')} htmlType="submit" loading={submitLoading} />
                  </React.Fragment>
                :
                  <Space className="float-right">
                    <CButton type='default' title={t('profile.buttonBack')} action={this.handleCancel}/>
                    <CButton type="primary" title={t('profile.buttonSave')} htmlType="submit" loading={submitLoading} />
                  </Space>
              }
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountIndexEducationLevel, unmountIndexInstitution, unmountIndexFieldOfStudy } = this.props
    return (unmountIndexEducationLevel(), unmountIndexInstitution(), unmountIndexFieldOfStudy())
  }
}

const mapStateToProps = (state) => ({
  getInstitution: state.institutionReducer,
  getEducationLevel: state.educationLevelReducer,
  getFieldOfStudy: state.fieldOfStudyReducer
})

const mapDispatchToProps = {
  actionListInstitution: listInstitution,
  unmountIndexInstitution: unmountIndexInstitution,
  actionGetEducationLevel: indexEducationLevel,
  unmountIndexEducationLevel: unmountIndexEducationLevel,
  actionListFieldOfStudy: listFieldOfStudy,
  unmountIndexFieldOfStudy: unmountIndexFieldOfStudy,
  actionAddEducation: addEducation,
  actionUpdateEducation: updateEducation,
  actionDeleteEducation: deleteEducation
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Education))