import React from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga'
import { Helmet } from "react-helmet";
import { Card, Row, Col, Typography, Space, Breadcrumb, Grid } from 'antd'

const { useBreakpoint } = Grid;
const { Text } = Typography

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function candidatePrivacyPolicy() {
  window.scrollTo(0, 0);
  
  if(process.env.REACT_APP_ENV === "production"){
    ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Privacy Policy');
  }

  const { xs } = GetBreakPoint()

  return (
    <React.Fragment>
      <Helmet>
        <title>Jobshub - Privacy Policy</title>
      </Helmet>
      <Row className="mb-16">
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Breadcrumb style={{ marginTop: xs ? 16 : 32, marginBottom: 8 }}>
            <Breadcrumb.Item>
              <Link className="dark-color" to="/">Home page</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="dark-color">Privacy policy</Breadcrumb.Item>
          </Breadcrumb>
          <Card style={{ boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.1)', paddingRight: xs ? 0 : 16, paddingLeft: xs ? 0 : 16 }}>
            <Row>
              <Col className={ xs ? "fs-24 text-align-center mb-8" : "fs-28 text-align-center mb-16" } span={24}>
                <Text type="secondary">Privacy Policy</Text>
              </Col>
              <Space align="start">
                <Text type="secondary">1.</Text>
                <Text type="secondary">
                  Once you are registered in our system, you are agreed that any information about you and your uploaded documents to be reviewed and analyzed by us and / or by other parties related to the job that you are applying for.
                </Text>
              </Space>
              <Space align="start">
                <Text type="secondary">2.</Text>
                <Text type="secondary">
                  Please always be careful with any information you entered, the information will be displayed as is, and not edited by our system.
                </Text>
              </Space>
              <Space align="start">
                <Text type="secondary">3.</Text>
                <Text type="secondary">
                  Your account in the system is protected by a password. This means that only you have access to your account and only you can change all information entered through your account.
                </Text>
              </Space>
              <Space align="start">
                <Text type="secondary">4.</Text>
                <Text type="secondary">
                  We recommend you always keeping your password a secret. We will never ask for your password via telephone or email and will not give your password to third parties.

                </Text>
              </Space>
              <Space align="start">
                <Text type="secondary">5.</Text>
                <Text type="secondary">
                  Once you finished using this system, don't forget to always log out. This is to ensure that no other party accesses your account, especially if the computer is shared or when you use the computer in a public place such as a library or cafe / internet cafe.
                </Text>
              </Space>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}