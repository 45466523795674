import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Info from './Info';
// import InfoPart1 from './Info1';
import Q3_5 from './Q3-5';
// import InfoPart2 from './Info2';
import Q6_7 from './Q6-7';

export class Section2 extends Component {
  render() {
    const { isSection, isPart } = this.props

    return (
      <React.Fragment>
        {/* Section 2 INFO */}
        {isSection === 2 && isPart === 0 ? <Info {...this.props} /> : null}
        {/* Section 2 Q INFO Part 1 */}
        {/* {isSection === 2 && isPart === 1 ? <InfoPart1 {...this.props} /> : null} */}
        {/* Section 2 Q 3 - 5 */}
        {isSection === 2 && isPart === 2 ? <Q3_5 {...this.props} /> : null}

        {/* Section 2 INFO Part 2 */}
        {/* {isSection === 2 && isPart === 3 ? <InfoPart2 {...this.props} /> : null} */}
        {/* Section 2 Q 6 - 7 */}
        {isSection === 2 && isPart === 4 ? <Q6_7 {...this.props} /> : null}
      </React.Fragment>
    )
  }
}


export default withTranslation()(Section2)