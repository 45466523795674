import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Row, Col, Typography, Space, Image, Grid } from 'antd';
import JobshubLogo from '../../../assets/img/logo/JobshubLogo.svg';
import MapsIcon from '../../../assets/img/MapsIcon.png';
import InstagramIcon from '../../../assets/img/InstagramIcon.png';
import FacebookIcon from '../../../assets/img/FacebookIcon.png';
import { MailOutlined } from '@ant-design/icons';
const { useBreakpoint } = Grid;
const { Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function FooterPrivate() {
  const { pathname } = window.location
  const { xxl, xl, lg, md, sm, xs } = GetBreakPoint()
  return (
    <React.Fragment>
      {
        pathname === '/job-preferences/industries' || pathname === '/job-preferences/position' || pathname === '/job-preferences/destination' ?
          null
        :
          <React.Fragment>
            <Row 
              style={ 
                (pathname === '/candidate/service-plus/choose-ticket' || pathname === '/candidate/xpert/choose-ticket') && (md || lg || xl || xxl) ?
                  { paddingBottom: 40, paddingTop: 80, background: '#e6e6e6', minHeight: '38vh' }
                : (pathname === '/candidate/service-plus/choose-ticket' || pathname === '/candidate/xpert/choose-ticket') && (xs || sm) ?
                  { paddingBottom: 24, paddingTop: 24, background: '#e6e6e6', minHeight: '65vh' } 
                : md ? 
                  { paddingBottom: 40, paddingTop: 80, background: '#e6e6e6' } 
                : 
                  { paddingBottom: 24, paddingTop: 24, background: '#e6e6e6' } 
              }
            >
              <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                <Row gutter={8}>
                  <Col className={!lg ? 'text-align-center' : null} xs={24} lg={9}>
                    <Space direction="vertical" size={0}>
                      <Link to="/">
                        <Image
                          width={120}
                          src={JobshubLogo}
                          preview={false}
                        />
                      </Link>
                      <Space className="mb-16" direction="vertical" size={0}>
                        <Text className="dark-color">Gedung Perkantoran Binawan Lobby 3, Lantai 2</Text>
                        <Text className="dark-color">Jl. Raya Kalibata - Dewi Sartika, No 25 - 30</Text>
                        <Text className="dark-color">DKI Jakarta - 13630</Text>
                      </Space>
                      <Space className="mb-16" size={16}>
                        <a href="https://goo.gl/maps/Gb5UThs8NV3PY6C98" target="_blank" rel="noreferrer">
                          <Image
                            width={'100%'}
                            src={MapsIcon}
                            preview={false}
                          />
                        </a>
                        <a href="https://www.instagram.com/jobshub_id" target="_blank" rel="noreferrer">
                          <Image
                            width={'100%'}
                            src={InstagramIcon}
                            preview={false}
                          />
                        </a>
                        <a href="https://www.facebook.com/Jobshubdotid/" target="_blank" rel="noreferrer">
                          <Image
                            width={'100%'}
                            src={FacebookIcon}
                            preview={false}
                          />
                        </a>
                      </Space>
                    </Space>
                  </Col>
                  {
                    md ?
                      <React.Fragment>
                        <Col xs={12} lg={5}>
                          <Space direction="vertical" size={16}>
                            <Text className="fs-16 primary-color">Product & Services</Text>
                            <Space direction="vertical" size={4}>
                              <Link className="dark-color" to="/candidate/job">Find a job</Link>
                            </Space>
                          </Space>
                        </Col>

                        <Col xs={12} lg={5}>
                          <Space direction="vertical" size={16}>
                            <Text className="fs-16 primary-color">Other</Text>
                              <Space direction="vertical" size={4}>
                                <Link className="dark-color" to="/candidate/about-us">About Us</Link>
                                <Link className="dark-color" to="/candidate/terms-and-conditions">Terms and Conditions</Link>
                                <Link className="dark-color" to="/candidate/privacy-policy">Privacy Policy</Link>
                                <Link className="dark-color" to="/candidate/faq">FAQ</Link>
                              </Space>
                          </Space>
                        </Col>

                        <Col xs={12} lg={5}>
                          <Space direction="vertical" size={16}>
                            <Text className="fs-16 primary-color">Information</Text>
                            <Space direction="vertical" size={4}>
                              <Space direction="vertical" size={-4}>
                                <Text className="dark-color">For technical support issues,</Text>
                                <Text className="dark-color">e-mail us at:</Text>
                              </Space>
                              <a href="mailto:support@jobshub.id?subject=What is your problem?"><MailOutlined /> support@jobshub.id</a>
                            </Space>
                          </Space>
                        </Col>
                      </React.Fragment>
                    :
                      <React.Fragment>
                        <Col xs={1}></Col>
                        <Col xs={11}>
                          <Space direction="vertical" size={6}>
                            <Text className="fs-16 primary-color">Product & Services</Text>
                            <Space direction="vertical" size={4}>
                              <Link className="dark-color" to="/candidate/job">Find a job</Link>
                            </Space>
                          </Space>
                        </Col>

                        <Col xs={11}>
                          <Space direction="vertical" size={6}>
                            <Text className="fs-16 primary-color">Other</Text>
                            <Space direction="vertical" size={4}>
                              <Link className="dark-color" to="/candidate/about-us">About Us</Link>
                              <Link className="dark-color" to="/candidate/terms-and-conditions">Terms and Conditions</Link>
                              <Link className="dark-color" to="/candidate/privacy-policy">Privacy Policy</Link>
                              <Link className="dark-color" to="/candidate/faq">FAQ</Link>
                            </Space>
                          </Space>
                        </Col>
                        <Col xs={1}></Col>

                        <Col xs={1}></Col>
                        <Col xs={22}>
                          <Space direction="vertical" size={6}>
                            <Text className="fs-16 primary-color">Information</Text>
                            <Space direction="vertical" size={4}>
                              <Space direction="vertical" size={-4}>
                                <Text className="dark-color">For technical support issues, e-mail us at:</Text>
                              </Space>
                              <a href="mailto:support@jobshub.id?subject=What is your problem?"><MailOutlined /> support@jobshub.id</a>
                            </Space>
                          </Space>
                        </Col>
                        <Col xs={1}></Col>

                      </React.Fragment>
                  }
                </Row>
              </Col>
            </Row>
            <Row style={{ height: 40 }}>
              <Col span={6} style={{ background: '#4173b9' }}></Col>
              <Col span={12} style={{ background: '#32ab6d', textAlign: 'center', paddingTop: 10 }}>
                <Text style={{ color: '#ffffff', fontSize: md ? 14 : 10 }}>© {moment().format('YYYY')} Jobshub. All Rights Reserved.</Text>
              </Col>
              <Col span={6} style={{ background: '#32ab6d' }}></Col>
            </Row>
          </React.Fragment>
      }
    </React.Fragment>
  )
}
