import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Cookie from 'js-cookie';
import { DeleteOutlined } from '@ant-design/icons';
import { Card, Typography, Modal, Select, Space, Form, Row, Col, Input, Divider, message } from 'antd';
import { addLanguage, deleteLanguage } from '../../../../redux/actions/profile/languageAction';
import { indexLanguage, unmountIndexLanguage } from '../../../../redux/actions/master/language/languageAction';
import CButton from '../../../../components/Button';

const { Text } = Typography;
const { Option } = Select;

export class Language extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
      visibleLanguage1: false,
      visibleLanguage2: false,
      isNative: false,
      submitLoading: false
    }
  }
  
  showModal = () => {
    const { actionGetLanguage } = this.props
    this.setState({
      visible: true,
    })
    return actionGetLanguage()
  };

  showModalLanguage1 = () => {
    this.setState({ visibleLanguage1: true, visible: false, isNative: true })
  }

  showModalLanguage2 = () => {
    this.setState({ visibleLanguage2: true, visible: false })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  };

  handleCancelLanguage1 = () => {
    this.setState({ visibleLanguage1: false, visible: true, isNative: false })
  }

  handleCancelLanguage2 = () => {
    this.setState({ visibleLanguage2: false, visible: true })
  }

  handleDelete = (id) => {
    const { actionDeleteLanguage, actionGetProfile } = this.props

    return actionDeleteLanguage(id, response => {
      if (response.code === '1000') {
        message.success(response.message)
        Cookie.set("activeElement", window.pageYOffset)
        return actionGetProfile()
      } else {
        message.error(response.message)
      }
    }, (err) => {
      return message.error(err)
    })
  }

  onFinish = async (values) => {
    const { actionAddLanguage, actionGetProfile } = this.props
    const { isNative } = this.state
    values.isNative = isNative
    return this.setState({ submitLoading: true }, () => {
      return actionAddLanguage(values, response => {
        if (response.code === '1000') {
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            Cookie.set("activeElement", window.pageYOffset)
            return actionGetProfile()
          })
        } else {
          this.setState({ submitLoading: false, visible: false })
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  render() {
    const { visible, submitLoading, visibleLanguage1, visibleLanguage2 } = this.state
    const { t, data: { listLanguage }, getLanguage } = this.props

    return (
      <React.Fragment>
        <Card
          className="border-radius-6 box-shadow"
          title={t('profile.language.title')}
          headStyle={{ color: '#32ab6d', fontSize: 17 }}
          bodyStyle={listLanguage.length > 0 ? {} : { textAlign: 'center', padding: 60 }}
          actions={[
            <Text className="primary-color" onClick={this.showModal}>{t('profile.language.button')}</Text>
          ]}
        >
          {
            listLanguage.length > 0 ?
              <Row>
                <Col span={24}>
                  {
                    listLanguage.map((item, i) =>
                      item.isNative ?
                        <Text key={i}>• {t('profile.language.native')} ({item.name})</Text>
                        : null
                    )
                  }
                </Col>
                <Col span={24}>
                  {
                    listLanguage.map((item, i) =>
                      !item.isNative ?
                        <Space key={i} direction='vertical' size={0}>
                          <Text>• {t('profile.language.other')} ({item.name})</Text>
                          <Space className='ml-12' direction='vertical' size={0}>
                            <Text className="dark-color">{t('profile.language.underStanding')}</Text>
                            <Text className="primary-color font-style-italic">{t('profile.language.listening')} -
                              {
                                item.listeningLevelId ?
                                  <>
                                    <Text className="primary-color" strong> {item.listeningLevelCode}</Text> <Text className="primary-color">{item.listeningLevelName}</Text>
                                  </>
                                  :
                                  ' N/A'
                              }
                            </Text>
                            <Text className="primary-color font-style-italic">{t('profile.language.reading')} -
                              {
                                item.readingLevelId ?
                                  <>
                                    <Text className="primary-color" strong> {item.readingLevelCode}</Text> <Text className="primary-color">{item.readingLevelName}</Text>
                                  </>
                                  :
                                  ' N/A'
                              }
                            </Text>
                          </Space>
                          <Space className='ml-12' direction='vertical' size={0}>
                            <Text className="dark-color">{t('profile.language.speaking')}</Text>
                            <Text className="primary-color font-style-italic">{t('profile.language.spokenInteraction')} -
                              {
                                item.spokenInterLevelId ?
                                  <>
                                    <Text className="primary-color" strong> {item.spokenInterLevelCode}</Text> <Text className="primary-color">{item.spokenInterLevelName}</Text>
                                  </>
                                  :
                                  ' N/A'
                              }
                            </Text>
                            <Text className="primary-color font-style-italic">{t('profile.language.spokenIntroduction')} -
                              {
                                item.spokenInterLevelId ?
                                  <>
                                    <Text className="primary-color" strong> {item.spokenInterLevelCode}</Text> <Text className="primary-color">{item.spokenInterLevelName}</Text>
                                  </>
                                  :
                                  ' N/A'
                              }
                            </Text>
                          </Space>
                          <Space className='ml-12' direction='vertical' size={0}>
                            <Text className="dark-color">{t('profile.language.writing')}</Text>
                            <Text className="primary-color font-style-italic">{t('profile.language.writing')} -
                              {
                                item.writingLevelId ?
                                  <>
                                    <Text className="primary-color" strong> {item.writingLevelCode}</Text> <Text className="primary-color">{item.writingLevelName}</Text>
                                  </>
                                  :
                                  ' N/A'
                              }
                            </Text>
                          </Space>
                        </Space>
                        : null
                    )
                  }
                </Col>
              </Row>
              :
              <Text className="light-color">{t('profile.language.textCard')}</Text>
          }
        </Card>

        <Modal
          title={t('profile.language.title')}
          visible={visible}
          onCancel={this.handleCancel}
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          centered
          width={500}
          destroyOnClose
          maskClosable={false}
        >
          <Row className='mb-16' gutter={8}>
            <Col className='mb-8' span={24}>
              <Text>{t('profile.language.textLanguage')}</Text>
            </Col>
            {
              listLanguage.length > 0 ?
                listLanguage.map((item, i) =>
                  item.isNative ?
                    <Col key={i} span={24}>
                      <Space className='width-100' direction='vertical' size={0}>
                        <Text strong>{t('profile.language.native')}</Text>
                        <Row className='width-100'>
                          <Col span={20}>
                            <Text className='primary-color'>{item.name}</Text>
                          </Col>
                          <Col className='float-right' span={4}>
                            <DeleteOutlined className='float-right danger-color cursor-pointer' onClick={() => this.handleDelete(item.id)} />
                          </Col>
                        </Row>
                      </Space>
                    </Col>
                    : null
                )
                : null
            }
            {
              listLanguage.filter(item => item.isNative === true).length < 1 ?
                <Col span={24}>
                  <CButton type='dashed' className="width-100" title={t('profile.language.button1')} block action={this.showModalLanguage1} icon="PlusOutlined"/>
                </Col>
                : null
            }
            <Col span={24}>
              <Divider className='mt-16 mb-16' />
            </Col>
            {
              listLanguage.length > 0 ?
                <Col className='mb-16' span={24}>
                  <Space className='width-100' direction='vertical' size={0}>
                    <Text strong>{t('profile.language.other')}</Text>
                    {
                      listLanguage.map((item, i) =>
                        !item.isNative ?
                          <Row key={i} className='width-100 mb-8'>
                            <Col span={23}>
                              <Space className='width-100' direction='vertical' size={0}>
                                <Text className='primary-color'>{i}. {item.name}</Text>
                                <Row className='width-100'>
                                  <Col className='mb-0' span={24}>
                                    <Text className='light-color'>{t('profile.language.underStanding')}</Text>
                                  </Col>
                                  <Col span={12}>
                                    <Space className='width-100' direction='vertical' size={0}>
                                      <Text strong>{t('profile.language.listening')}</Text>
                                      {
                                        item.listeningLevelId ?
                                          <Text>{item.listeningLevelCode} - {item.listeningLevelName}</Text>
                                          :
                                          'N/A'
                                      }
                                    </Space>
                                  </Col>
                                  <Col span={12}>
                                    <Space className='width-100' direction='vertical' size={0}>
                                      <Text strong>{t('profile.language.reading')}</Text>
                                      {
                                        item.readingLevelId ?
                                          <Text>{item.readingLevelCode} - {item.readingLevelName}</Text>
                                          :
                                          'N/A'
                                      }
                                    </Space>
                                  </Col>

                                  <Col className='mb-0' span={24}>
                                    <Text className='light-color'>{t('profile.language.speaking')}</Text>
                                  </Col>
                                  <Col span={12}>
                                    <Space className='width-100' direction='vertical' size={0}>
                                      <Text strong>{t('profile.language.spokenInteraction')}</Text>
                                      {
                                        item.spokenInterLevelId ?
                                          <Text>{item.spokenInterLevelCode} - {item.spokenInterLevelName}</Text>
                                          :
                                          'N/A'
                                      }
                                    </Space>
                                  </Col>
                                  <Col span={12}>
                                    <Space className='width-100' direction='vertical' size={0}>
                                      <Text strong>{t('profile.language.spokenIntroduction')}</Text>
                                      {
                                        item.spokenIntroLevelId ?
                                          <Text>{item.spokenIntroLevelCode} - {item.spokenIntroLevelName}</Text>
                                          :
                                          'N/A'
                                      }
                                    </Space>
                                  </Col>

                                  <Col className='mb-0' span={24}>
                                    <Text className='light-color'>{t('profile.language.writing')}</Text>
                                  </Col>
                                  <Col span={12}>
                                    <Space className='width-100' direction='vertical' size={0}>
                                      <Text strong>{t('profile.language.writing')}</Text>
                                      {
                                        item.writingLevelId ?
                                          <Text>{item.writingLevelCode} - {item.writingLevelName}</Text>
                                          :
                                          'N/A'
                                      }
                                    </Space>
                                  </Col>
                                </Row>
                              </Space>
                            </Col>
                            <Col className='float-right' span={1}>
                              <DeleteOutlined className='float-right danger-color cursor-pointer' onClick={() => this.handleDelete(item.id)} />
                            </Col>
                          </Row>
                          : null
                      )
                    }
                  </Space>
                </Col>
                : null
            }
            <Col span={24}>
              <CButton type="dashed" className="width-100" block action={this.showModalLanguage2} icon="PlusOutlined" title={t('profile.language.button2')}/>
            </Col>
          </Row>
        </Modal>

        <Modal
          title={t('profile.language.button1')}
          visible={visibleLanguage1}
          onCancel={this.handleCancelLanguage1}
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          centered
          width={500}
          destroyOnClose
        >
          <Form
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
          >
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label={t('profile.language.native')}
                  validateFirst
                  rules={[
                    { required: true, message: t('profile.language.validasi') },
                    { min: 3, message: t('profile.language.validasi2') },
                    { max: 50, message: t('profile.language.validasi3') },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Form.Item>
                  <Space>
                    <CButton type="default" action={this.handleCancelLanguage1} title={t('profile.language.cancel')}/>
                    <CButton className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} title={t('profile.language.add')}/>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          title={t('profile.language.other')}
          visible={visibleLanguage2}
          onCancel={this.handleCancelLanguage2}
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          centered
          width={1000}
          destroyOnClose
        >
          <Form
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
          >
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item
                  className='mb-8'
                  name="name"
                  label={t('profile.language.other')}
                  validateFirst
                  rules={[
                    { required: true, message: t('profile.language.validasi') },
                    { min: 3, message: t('profile.language.validasi2') },
                    { max: 50, message: t('profile.language.validasi3') },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text strong>{t('profile.language.underStanding')}</Text>
              </Col>
              <Col span={12}>
                <Form.Item
                  className='mb-8'
                  name="listeningLevelId"
                  label={t('profile.language.listening')}
                >
                  <Select
                    placeholder={t('profile.language.select')}
                    className="width-100 border-radius-6"
                  >
                    {
                      getLanguage?.data?.map(item =>
                        item.section === 'LISTENING' ?
                          <Option key={item.id} className="language">{item.code} - {item.name} - {Cookie.get('i18next') === 'in' ? item.descriptionIdn : item.descriptionEng}</Option>
                          : null
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className='mb-8'
                  name="readingLevelId"
                  label={t('profile.language.reading')}
                >
                  <Select
                    placeholder={t('profile.language.select')}
                    className="width-100 border-radius-6"
                  >
                    {
                      getLanguage?.data?.map(item =>
                        item.section === 'READING' ?
                          <Option key={item.id} className="language">{item.code} - {item.name} - {Cookie.get('i18next') === 'in' ? item.descriptionIdn : item.descriptionEng}</Option>
                          : null
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text strong>{t('profile.language.speaking')}</Text>
              </Col>
              <Col span={12}>
                <Form.Item
                  className='mb-8'
                  name="spokenInterLevelId"
                  label={t('profile.language.spokenInteraction')}
                >
                  <Select
                    placeholder={t('profile.language.select')}
                    className="width-100 border-radius-6"
                  >
                    {
                      getLanguage?.data?.map(item =>
                        item.section === 'SPOKEN_INTERACTION' ?
                          <Option key={item.id} className="language">{item.code} - {item.name} - {Cookie.get('i18next') === 'in' ? item.descriptionIdn : item.descriptionEng}</Option>
                          : null
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className='mb-8'
                  name="spokenIntroLevelId"
                  label={t('profile.language.spokenIntroduction')}
                >
                  <Select
                    placeholder={t('profile.language.select')}
                    className="width-100 border-radius-6"
                  >
                    {
                      getLanguage?.data?.map(item =>
                        item.section === 'SPOKEN_PRODUCTION' ?
                          <Option key={item.id} className="language">{item.code} - {item.name} - {Cookie.get('i18next') === 'in' ? item.descriptionIdn : item.descriptionEng}</Option>
                          : null
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col className='mb-8' span={24} >
                <Text strong>{t('profile.language.writing')}</Text>
              </Col>
              <Col span={24}>
                <Form.Item
                  className='mb-32'
                  name="writingLevelId"
                >
                  <Select
                    placeholder={t('profile.language.select')}
                    className="width-100 border-radius-6"
                  >
                    {
                      getLanguage?.data?.map(item =>
                        item.section === 'WRITING' ?
                          <Option key={item.id} className="language">{item.code} - {item.name} - {Cookie.get('i18next') === 'in' ? item.descriptionIdn : item.descriptionEng}</Option>
                          : null
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Form.Item>
                  <Space>
                    <CButton type="default" onClick={this.handleCancelLanguage2} title={t('profile.language.cancel')}/>
                    <CButton className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} title={t('profile.language.add')}/>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>

      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountIndexLanguage } = this.props
    return unmountIndexLanguage()
  }
}

const mapStateToProps = (state) => ({
  getLanguage: state.languageReducer
})

const mapDispatchToProps = {
  actionGetLanguage: indexLanguage,
  unmountIndexLanguage: unmountIndexLanguage,
  actionAddLanguage: addLanguage,
  actionDeleteLanguage: deleteLanguage
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Language))