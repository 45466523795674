import { API } from '../../../config'
import { errorHandler } from '../auth/errorAction'

export const unmountIndexProfile = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_PROFILE'})
}

export const indexProfile = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_PROFILE' })
  return API.GET('/profile').then((response) => {
    dispatch({
      type: 'LOAD_DATA_PROFILE_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_PROFILE_FAILED' }), 
    ))
  })
}