import React from 'react'
import { withTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Row, Col, Typography, Space, Divider, Image } from 'antd';
import { paymentOption } from '../../../../utils/constant/paymentOption';
const { Text } = Typography

export function waitingConfirm(props) {
  const { getOrderDetail, t, showModalPayment } = props
  return (
    <React.Fragment>
      <Row className="my-order-card b-32 mb-16" style={{ backgroundColor: 'white', border: '1px solid #e0e0e0' }}>
        <Col span={20} offset={2} style={{ marginTop: 20 }}>
          <Text className="fs-18 dark-color font-weight-bold">{t('myOrders.waitingConfirm.detail')}</Text>
          <Row className="mt-16">
            <Col span={16}>
              <Space direction="vertical" size={0}>
                <Text className="fs-14 light-color">{t('myOrders.waitingConfirm.name')}</Text>
                {
                  getOrderDetail.data.ticketList.map((item, i) => 
                    <Text key={i} className="fs-14 dark-color font-weight-bold">{item.name}</Text>
                  )
                }
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text className="fs-14 light-color">{t('myOrders.waitingPayment.subTotal')}</Text>
              </Space>
            </Col>
            {
                getOrderDetail.data.ticketList.map((item, i) => 
                <React.Fragment key={i}>
                  <Col span={16}>
                    <Space>
                      <Text className="fs-14 light-color">x {item.qty}</Text>
                      <Text className="fs-14 light-color">
                      {
                        `IDR ${item?.price?.toLocaleString()}`
                      }
                      </Text>
                    </Space>
                  </Col>
                  <Col span={8}>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-14 light-color">
                      {
                        `IDR ${item?.totalPrice?.toLocaleString()}`
                      }
                      </Text>
                    </Space>
                  </Col>
                </React.Fragment>
                )
              }
              {
                getOrderDetail?.data.xpertType === 'SERVICE' ? 
                  <Col span={24}>
                    <Space direction="vertical" size={0} style={{ fontStyle: 'italic'}}>
                      {
                        
                          getOrderDetail?.data?.paymentOption !== 'ONE_TIME_PAYMENT' ? 
                            <Text className="fs-14 light-color">{t('myOrders.waitingPayment.Fee')}</Text>
                          : null 
                      }
                    </Space>
                  </Col>
                : null
              }
          </Row>
          <Divider />
          <Text className="fs-18 dark-color font-weight-bold">{t('myOrders.waitingConfirm.information')}</Text>
          <Row className="mt-16 mb-8" gutter={[0,8]}>
            <Col xs={24} lg={12}>
              <Space direction="vertical" size={0}>
                <Text className="fs-14 light-color">{t('myOrders.waitingConfirm.noOrder')}</Text>
                <Text className="fs-14 dark-color font-weight-bold">{getOrderDetail.data.xpertTransactionNumber}</Text>
              </Space>
            </Col>
            <Col xs={24} lg={12}>
              <Space direction="vertical" size={0}>
                <Text className="light-color fs-14">{t('myOrders.waitingConfirm.date')}</Text>
                <Text className="dark-color fs-14 font-weight-bold">{getOrderDetail.data.orderDate}</Text>
              </Space>
            </Col>
            <Col xs={24} lg={12}>
              <Space direction="vertical" size={0}>
                <Text className="fs-14 light-color">{t('myOrders.waitingConfirm.total')}</Text>
                <Text className="fs-14 dark-color font-weight-bold">
                  {
                    `IDR ${getOrderDetail?.data?.totalTransaction?.toLocaleString()}`
                  }
                </Text>
              </Space>
            </Col>
            <Col xs={24} lg={12}>
              <Space direction="vertical" size={0}>
                <Text className="light-color fs-14">{t('myOrders.waitingConfirm.date2')}</Text>
                <Text className="dark-color fs-14 font-weight-bold">{getOrderDetail.data.expiredDate}</Text>
              </Space>
            </Col>
            <Col xs={24} lg={12}>
              <Space direction="vertical" size={0}>
                <Text className="light-color fs-14">{t('myOrders.waitingConfirm.method')}</Text>
                <Text className="dark-color fs-14 font-weight-bold">Bank Transfer</Text>
              </Space>
            </Col>
            <Col xs={24} lg={12}>
              <Space direction="vertical" size={0}>
                <Text className="fs-14 light-color">{t('myOrders.waitingConfirm.norek')}</Text>
                <Text className="fs-14 dark-color font-weight-bold">
                  {
                    getOrderDetail?.data?.xpertType === 'SERVICE' ?
                      getOrderDetail.data.receiverBankAccountNumber
                    : getOrderDetail.data.senderBankAccountNumber
                  }
                </Text>
              </Space>
            </Col>
            {
              getOrderDetail?.data.xpertType === 'SERVICE' ? 
                <Col xs={24} lg={12}>
                  <Space direction="vertical" size={0}>
                    <Text className="fs-14 light-color">{t('myOrders.waitingPayment.schema')}</Text>
                    <Text className="fs-14 dark-color font-weight-bold">{paymentOption?.find(item => item?.value === getOrderDetail?.data?.paymentOption)?.name}<InfoCircleOutlined style={{ color: '#4272b9' }} onClick={showModalPayment} /></Text>
                  </Space>
                </Col>
                : null
            }
            <Col xs={24} lg={12}>
              <Space direction="vertical" size={0}>
                <Text className="fs-14 light-color">{t('myOrders.waitingPayment.account')}</Text>
                <Text className="fs-14 dark-color font-weight-bold">
                  {
                    getOrderDetail?.data?.xpertType === 'SERVICE' ?
                      getOrderDetail?.data?.receiverBankAccountName
                    : getOrderDetail.data.senderBankAccountName
                  }
                </Text>
              </Space>
            </Col>
            <Col xs={24} lg={12}>
              <Space direction="vertical" size={0}>
                <Text className="light-color fs-14">{t('myOrders.waitingConfirm.nameBank')}</Text>
                <Text className="dark-color fs-14 font-weight-bold">
                  {
                    getOrderDetail.data.xpertType === 'SERVICE' ?
                      getOrderDetail.data.receiverBankName
                    : getOrderDetail.data.senderBankName
                  }
                </Text>
              </Space>  
            </Col>
          </Row>
          <Col className='mb-16' xs={24} lg={12}>
            <Space direction="vertical" size={0}>
              <Text className="light-color fs-14">{t('myOrders.waitingConfirm.payment')}</Text>
              <Image
                width={200}
                src={getOrderDetail.data.paymentProofUrl}
                style={{ borderRadius: 0 }}
              />
              <Text className="light-color fs-14">{getOrderDetail.data.paymentProof}</Text>
            </Space>
          </Col>
        </Col>
      </Row>
    </React.Fragment>
  )
}


export default withTranslation()(waitingConfirm)
