import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Row, Col, Typography, Form, Select, Checkbox, Divider, Skeleton, message } from 'antd';
import CButton from '../../../../components/Button';

const { Text } = Typography
const { Option } = Select

export class FormJobNotification extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      isWebNotification: null,
      isEmailNotification: null,
      isDeviceNotification: null
    }
  }
  
  componentDidMount() {
    const { actionCandidateNotification } = this.props

    return actionCandidateNotification()
  }

  onFinish = (values) => {
    const { isWebNotification, isEmailNotification, isDeviceNotification } = this.state
    const { getCandidateNotification } = this.props
    values.isWebNotification = isWebNotification !== null ? isWebNotification : getCandidateNotification?.data?.isWebNotification || false
    values.isEmailNotification = isEmailNotification !== null ? isEmailNotification : getCandidateNotification?.data?.isEmailNotification || false
    values.isDeviceNotification = isDeviceNotification !== null ? isDeviceNotification : getCandidateNotification?.data?.isDeviceNotification || false
    const { actionUpdateNotification, actionCandidateTimezone } = this.props;

    this.setState({ submitLoading: true })
    return actionUpdateNotification(values, (response) => {
      this.setState({ submitLoading: false }, () => {
        if(response.code === '1000'){
          message.success(response.message)
          return actionCandidateTimezone()
        }else{
          message.error(response.message)
        }
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => {
        message.error(err)
      })
    })
  }
  
  handleWebNotification = (e) => {
    this.setState({
      isWebNotification: e.target.checked
    })
  }

  handleEmailNotification = (e) => {
    this.setState({
      isEmailNotification: e.target.checked
    })
  }

  handleDeviceNotification = (e) => {
    this.setState({
      isDeviceNotification: e.target.checked
    })
  }

  render() {
    const { submitLoading, isWebNotification, isEmailNotification, isDeviceNotification } = this.state
    const { getCandidateNotification, t } = this.props
    
    if(getCandidateNotification.loading){
      return <Skeleton active />
    }

    return (
      <React.Fragment>
        <Form 
          ref={this.formRef}
          layout="vertical"
          onFinish={this.onFinish}
        >
          <Row gutter={8}>
            <Col xxl={18} xl={16} lg={14} md={12} sm={24} xs={24}>
              <Text className="fs-13" type="secondary">{t('jobNotification.recommended')}</Text>
            </Col>
            <Col xxl={6} xl={8} lg={10} md={12} sm={24} xs={24}>
              <Form.Item name="jobNotification" initialValue={getCandidateNotification?.data?.jobNotification || "MONTHLY"}>
                <Select
                  className="float-right border-radius-6"
                  optionFilterProp="children"
                  style={{ width: '100%' }}
                  size="small"
                >
                  <Option value="WEEKLY">{t('jobNotification.weekly')}</Option>
                  <Option value="MONTHLY">{t('jobNotification.monthly')}</Option>
                  <Option value="NEVER">{t('jobNotification.never')}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Divider />
            <Col xxl={18} xl={16} lg={14} md={12} sm={24} xs={24}>
              <Text className="fs-13" type="secondary">{t('jobNotification.how')}</Text>
            </Col>
            <Col xxl={6} xl={8} lg={10} md={12} sm={24} xs={24}>
              <Form.Item className="mb-min-8" name="isWebNotification" initialValue={isWebNotification || getCandidateNotification?.data?.isWebNotification}>
                <Checkbox defaultChecked={isWebNotification || getCandidateNotification?.data?.isWebNotification} onChange={this.handleWebNotification}>Jobshub Web</Checkbox>
              </Form.Item>
              <Form.Item className="mb-min-8" name="isEmailNotification" initialValue={isEmailNotification || getCandidateNotification?.data?.isEmailNotification}>
                <Checkbox defaultChecked={isEmailNotification || getCandidateNotification?.data?.isEmailNotification} onChange={this.handleEmailNotification}>Email</Checkbox>
              </Form.Item>
              <Form.Item name="isDeviceNotification" initialValue={isDeviceNotification || getCandidateNotification?.data?.isDeviceNotification}>
                <Checkbox defaultChecked={isDeviceNotification || getCandidateNotification?.data?.isDeviceNotification} onChange={this.handleDeviceNotification}>{t('jobNotification.device')}</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item className="text-align-center mt-8">
            <CButton className="width-160" htmlType="submit" type="primary" loading={submitLoading} title={t('jobNotification.save')}/>
          </Form.Item>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountCandidateNotification } = this.props
    return unmountCandidateNotification()
  }
}

export default withTranslation()(FormJobNotification)
