import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loading } from '../../../components';
import JobDetail from '../../../components/Job/Detail';
import { detailJob, unmountDetailJob } from '../../../redux/actions/job/jobAction';

export class JobDetail_ extends Component {
  sliderRef = React.createRef()
  mediaRef = React.createRef()
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      openLightbox: false,
      slides: []
    }
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.nextMedia = this.nextMedia.bind(this);
    this.previousMedia = this.previousMedia.bind(this);
  }

  componentDidMount() {
    const { actionGetJobDetail, match: { params } } = this.props
    
    window.scrollTo(0, 0)

    return actionGetJobDetail(params.slug)
  }

  next() {
    this.sliderRef.current.next();
  }

  previous() {
    this.sliderRef.current.prev();
  }

  nextMedia() {
    this.mediaRef.current.next();
  }

  previousMedia() {
    this.mediaRef.current.prev();
  }
  
  handleOpenLightbox = (data,i) => {
    const { slides } = this.state
    let iImage = Number(i)
    while (slides.length < data.length) {
      if (data[iImage].fileExtension === 'mp4') {
        slides.push(
          { 
            type: "video",
            width: 1280,
            height: 720,
            sources: [
              {
                src: data[iImage].fileUrl,
                type: "video/mp4"
              }
            ] 
          }
        )
      } else {
        slides.push({ src: data[iImage].fileUrl })
      }
      iImage++
      if(iImage===data.length){
        iImage=0
      }
    }
    this.setState({ openLightbox: true, slides })
  }

  handleCloseLightbox = () => {
    this.setState({ openLightbox: false, slides: [] })
  }

  showModal = () => {
    this.setState({
      visible: true
    })
  }
   
  handleCancel = () => {
    this.setState({
      visible: false
    })
  }
  
  render() {
    const { visible, openLightbox, slides } = this.state
    const { getJobDetail } = this.props
    
    if(getJobDetail.loading){
      return <Loading />
    }

    const initialProps = {
      showModal: this.showModal,
      handleCancel: this.handleCancel,
      next: this.next,
      previous: this.previous,
      nextMedia: this.nextMedia,
      previousMedia: this.previousMedia,
      openLightbox: openLightbox,
      handleOpenLightbox: this.handleOpenLightbox,
      handleCloseLightbox: this.handleCloseLightbox,
      slides: slides,
      sliderRef: this.sliderRef,
      mediaRef: this.mediaRef,
      visible: visible,
      id: this.props.match.params.id
    }

    return (
      <JobDetail {...initialProps} {...this.props} />
    )
  }
  componentWillUnmount(){
    const { unmountDetailJob } = this.props
    return unmountDetailJob()
  }
}

const mapStateToProps = (state) => ({
  getJobDetail: state.jobDetailReducer
})

const mapDispatchToProps = {
  actionGetJobDetail: detailJob,
  unmountDetailJob: unmountDetailJob,
}

export default connect(mapStateToProps, mapDispatchToProps)(JobDetail_)
