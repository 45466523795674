import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Row, Col, Avatar, Typography, Space, Divider, Skeleton, Button } from 'antd';
import { UserOutlined, EditFilled } from '@ant-design/icons';
import Contact from './../contact';
import PersonalInfo from './../personalInfo';
import Education from './../education';
const { Text } = Typography;

export class index extends Component {
  render() {
    const { 
      getContact, 
      getPersonalInfo,
      getLastEducation,
      onPreviewAvatar, 
      currentCityName,
      educationLevelName,
      valuesContact, 
      valuesPersonal,
      valuesEducation,
      editContact,
      editPersonal,
      editEducation,
      handleEditContact,
      handleEditPersonal,
      handleEditEducation,
      t
    } = this.props
    if(getContact.loading || getPersonalInfo.loading || getLastEducation.loading){
      return <Skeleton active avatar />
    }
    return (
      <React.Fragment>
        {
          editContact ?
            <Contact {...this.props} />
          : editPersonal ?
            <PersonalInfo {...this.props} />
          : editEducation ?
            <Education {...this.props} />
          :
            <React.Fragment>
              <Row className="mb-16">
                <Col span={24}>
                  <Text className="fs-16" type="secondary">{t('jobDetail.review1.title')}</Text>
                </Col>
              </Row>
              <Row className="mb-8">
                <Col span={20}>
                  <Text className="fs-14 dark-color">{t('jobDetail.review1.contact')}</Text>
                </Col>
                <Col span={4}>
                  <Button className="float-right" onClick={handleEditContact} type="link" icon={<EditFilled />}>{t('jobDetail.review1.edit')}</Button>
                </Col>
              </Row>
                <React.Fragment>
                  <Row className="mb-8">
                    <Space size={24}>
                      <Avatar 
                        size={80} 
                        src={
                          onPreviewAvatar ? 
                            onPreviewAvatar 
                          : getContact.data.profilePicUrl  ? 
                            getContact.data.profilePicUrl 
                          : 
                            null
                        } 
                        icon={
                          onPreviewAvatar ? 
                            null 
                          : getContact.data.profilePicUrl ? 
                            null 
                          : 
                          <UserOutlined />
                        } 
                      />
                      <Space direction="vertical" size={16}>
                        <Text className="fs-15 dark-color">{getContact.data.fullName}</Text>
                        <Text className="fs-15 dark-color">{getContact.data.email}</Text>
                      </Space>
                    </Space>
                  </Row>
                  <Row className="mb-8">
                    <Col span={24}>
                      <Space direction="vertical">
                        <Space direction="vertical" size={0}>
                          <Text className="fs-12" type="secondary">{t('jobDetail.contact.mobileNumber')}</Text>
                          <Text className="dark-color" strong>
                            { 
                              valuesContact ? 
                                `+${valuesContact.mobilePhone}`
                              : getContact.data.mobilePhone ?
                                `+${getContact.data.callingCode}${getContact.data.mobilePhone}`
                              :
                                '-'
                            }
                          </Text>
                        </Space>
                        <Space direction="vertical" size={0}>
                          <Text className="fs-12" type="secondary">{t('jobDetail.contact.currentLocation')}</Text>
                          <Text className="dark-color" strong>
                            {
                              currentCityName ?
                                currentCityName
                              : getContact.data.currentCityName ?
                                `${getContact.data.currentCityName}, ${getContact.data.currentCountryName}`
                              :
                                null
                            }
                          </Text>
                        </Space>
                      </Space>
                    </Col>
                  </Row>
                </React.Fragment>
              <Divider />
              <Row className="mb-16">
                <Col span={20}>
                  <Text className="fs-16" type="secondary">{t('jobDetail.personalInfo.title')}</Text>
                </Col>
                <Col span={4}>
                  <Button className="float-right" onClick={handleEditPersonal} type="link" icon={<EditFilled />}>{t('jobDetail.review1.edit')}</Button>
                </Col>
              </Row>
              <Row className="mb-16">
                <Col span={12}>
                  <Space direction="vertical">
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.place')}</Text>
                      <Text className="dark-color" strong>
                        {
                          valuesPersonal && valuesPersonal.placeOfBirth ? 
                            valuesPersonal.placeOfBirth 
                          : getPersonalInfo.data.placeOfBirth ?
                            getPersonalInfo.data.placeOfBirth
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.date')}</Text>
                      <Text className="dark-color" strong>
                        {
                          valuesPersonal && valuesPersonal.birthdate ? 
                            valuesPersonal.birthdate 
                          : getPersonalInfo.data.birthdate ?
                            getPersonalInfo.data.birthdate
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.maritalStatus')}</Text>
                      <Text className="dark-color" strong>
                        {
                          valuesPersonal && valuesPersonal.marital ? 
                            valuesPersonal.marital 
                          : getPersonalInfo.data.marital ?
                            getPersonalInfo.data.marital
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.gender')}</Text>
                      <Text className="dark-color" strong>
                        {
                          valuesPersonal && valuesPersonal.gender ? 
                            valuesPersonal.gender 
                          : getPersonalInfo.data.gender ?
                            getPersonalInfo.data.gender
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.religion')}</Text>
                      <Text className="dark-color" strong>
                        {
                          valuesPersonal && valuesPersonal.religion ? 
                            valuesPersonal.religion 
                          : getPersonalInfo.data.religion ?
                            getPersonalInfo.data.religion
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                  </Space>
                </Col>
                <Col span={12}>
                  <Space direction="vertical">
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.nationality')}</Text>
                      <Text className="dark-color" strong>
                        {
                          valuesPersonal && valuesPersonal.nationality ? 
                            valuesPersonal.nationality 
                          : getPersonalInfo.data.nationality ?
                            getPersonalInfo.data.nationality
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.bloodType')}</Text>
                      <Text className="dark-color" strong>
                        {/* {
                          valuesPersonal && valuesPersonal.bloodType ? 
                            valuesPersonal.bloodType 
                          : getPersonalInfo.data.bloodType ?
                            getPersonalInfo.data.bloodType
                          :
                            '-'
                        } */}
                        {
                            (valuesPersonal && valuesPersonal.bloodType) || getPersonalInfo.data.bloodType || '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.height')}</Text>
                      <Text className="dark-color" strong>
                        {
                          valuesPersonal && valuesPersonal.height ? 
                            valuesPersonal.height 
                          : getPersonalInfo.data.height ?
                            getPersonalInfo.data.height
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.weight')}</Text>
                      <Text className="dark-color" strong>
                        {
                          valuesPersonal && valuesPersonal.weight ? 
                            valuesPersonal.weight 
                          : getPersonalInfo.data.weight ?
                            getPersonalInfo.data.weight
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.ktp')}</Text>
                      <Text className="dark-color" strong>
                        {
                          valuesPersonal && valuesPersonal.identificationId ? 
                            valuesPersonal.identificationId 
                          : getPersonalInfo.data.identificationId ?
                            getPersonalInfo.data.identificationId
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                  </Space>
                </Col>
              </Row>
              <Text type="secondary">{t('jobDetail.personalInfo.ktpAddress')}</Text>
              <Row className="mb-16">
                <Col span={12}>
                  <Space direction="vertical">
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.country')}</Text>
                      <Text className="dark-color" strong>
                        {
                          valuesPersonal && valuesPersonal.country ? 
                            valuesPersonal.country 
                          : getPersonalInfo.data.countryName ?
                            getPersonalInfo.data.countryName
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.province')}</Text>
                      <Text className="dark-color" strong>
                        {
                          valuesPersonal && valuesPersonal.province ? 
                            valuesPersonal.province 
                          : getPersonalInfo.data.provinceName ?
                            getPersonalInfo.data.provinceName
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.city')}</Text>
                      <Text className="dark-color" strong>
                        {
                          valuesPersonal && valuesPersonal.city ? 
                            valuesPersonal.city 
                          : getPersonalInfo.data.cityName ?
                            getPersonalInfo.data.cityName
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    {
                      getPersonalInfo.data.CountryId === 99 || (valuesPersonal && valuesPersonal.countryId === 99) ?
                        <Space direction="vertical" size={0}>
                          <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.subdistrict')}</Text>
                          <Text className="dark-color" strong>
                          {
                            valuesPersonal && valuesPersonal.subDistrict ? 
                              valuesPersonal.subDistrict 
                            : getPersonalInfo.data.subDistrict ?
                              getPersonalInfo.data.subDistrict
                            :
                              '-'
                          }
                          </Text>
                        </Space>
                      :
                        null
                    }
                  </Space>
                </Col>
                <Col span={12}>
                  <Space direction="vertical">
                    {
                      getPersonalInfo.data.CountryId === 99 || (valuesPersonal && valuesPersonal.countryId === 99) ?
                        <Space direction="vertical" size={0}>
                          <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.village')}</Text>
                          <Text className="dark-color" strong>
                          {
                            valuesPersonal && valuesPersonal.village ? 
                              valuesPersonal.village 
                            : getPersonalInfo.data.village ?
                              getPersonalInfo.data.village
                            :
                              '-'
                          }
                          </Text>
                        </Space>
                      :
                        null
                    }
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.zipcode')}</Text>
                      <Text className="dark-color" strong>
                        {
                          valuesPersonal && valuesPersonal.zipCode ? 
                            valuesPersonal.zipCode 
                          : getPersonalInfo.data.zipCode ?
                            getPersonalInfo.data.zipCode
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{t('jobDetail.personalInfo.address')}</Text>
                      <Text className="dark-color" strong>
                        {
                          valuesPersonal && valuesPersonal.address ? 
                            valuesPersonal.address 
                          : getPersonalInfo.data.address ?
                            getPersonalInfo.data.address
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                  </Space>
                </Col>
              </Row>
              <Divider />
              <Row className="mb-16" gutter={8}>
                <Col span={20}>
                  <Text className="fs-16" type="secondary">{t('jobDetail.education.title')}</Text>
                </Col>
                <Col span={4}>
                  <Button className="float-right" onClick={handleEditEducation} type="link" icon={<EditFilled />}>{t('jobDetail.review1.edit')}</Button>
                </Col>
              </Row>
              <Row className="mb-16">
                <Col span={12}>
                  <Space direction="vertical">
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{t('jobDetail.education.school')}</Text>
                      <Text className="dark-color" strong>
                        {
                          valuesEducation && valuesEducation.educationName ? 
                            valuesEducation.educationName 
                          : getLastEducation?.data?.educationName ?
                            getLastEducation?.data?.educationName
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{t('jobDetail.education.fieldOfStudy')}</Text>
                      <Text className="dark-color" strong>
                        {
                          valuesEducation && valuesEducation.educationFieldOfStudy ? 
                            valuesEducation.educationFieldOfStudy 
                          : getLastEducation?.data?.educationFieldOfStudy ?
                            getLastEducation?.data?.educationFieldOfStudy
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{valuesEducation?.educationCurrent ? 'Year' : t('jobDetail.education.startyear')}</Text>
                      <Text className="dark-color" strong>
                        {
                          valuesEducation && valuesEducation.educationStartYear ? 
                            valuesEducation?.educationCurrent ? `${valuesEducation.educationStartYear} - Present` : valuesEducation.educationStartYear 
                          : getLastEducation?.data?.educationStartYear ?
                            getLastEducation?.data?.educationStartYear
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    {
                      valuesEducation?.educationCurrent ?
                        <Space direction="vertical" size={0}>
                          <Text className="fs-12" type="secondary">{t('jobDetail.education.educationExperience')}</Text>
                          <Text className="dark-color" strong>
                            {
                              valuesEducation && valuesEducation.educationExperience ? 
                                valuesEducation.educationExperience 
                              : getLastEducation?.data?.educationExperience ?
                                getLastEducation?.data?.educationExperience
                              :
                                '-'
                            }
                          </Text>
                        </Space>
                      :
                        <Space direction="vertical" size={0}>
                          <Text className="fs-12" type="secondary">{t('jobDetail.education.website')}</Text>
                          <Text className="dark-color" strong>
                            {
                              valuesEducation && valuesEducation.educationWebsite ? 
                                valuesEducation.educationWebsite 
                              : getLastEducation?.data?.educationWebsite ?
                                getLastEducation?.data?.educationWebsite
                              :
                                '-'
                            }
                          </Text>
                        </Space>
                    }
                  </Space>
                </Col>
                <Col span={12}>
                  <Space direction="vertical">
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{t('jobDetail.education.educationLevel')}</Text>
                      <Text className="dark-color" strong>
                        {
                          valuesEducation && valuesEducation.educationLevelId ? 
                            educationLevelName || getLastEducation?.data?.educationLevelName
                          : getLastEducation?.data?.educationLevelName ?
                            getLastEducation?.data?.educationLevelName
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">{t('jobDetail.education.score')}</Text>
                      <Text className="dark-color" strong>
                        {
                          valuesEducation && valuesEducation.educationGpa ? 
                            valuesEducation.educationGpa 
                          : getLastEducation?.data?.educationGpa ?
                            getLastEducation?.data?.educationGpa
                          :
                            '-'
                        }
                      </Text>
                    </Space>
                    {
                      valuesEducation?.educationCurrent ?
                        <Space direction="vertical" size={0}>
                          <Text className="fs-12" type="secondary">{t('jobDetail.education.website')}</Text>
                          <Text className="dark-color" strong>
                            {
                              valuesEducation && valuesEducation.educationWebsite ? 
                                valuesEducation.educationWebsite 
                              : getLastEducation?.data?.educationWebsite ?
                                getLastEducation?.data?.educationWebsite
                              :
                                '-'
                            }
                          </Text>
                        </Space>
                      : 
                        <>
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12" type="secondary">{t('jobDetail.education.endyear')}</Text>
                            <Text className="dark-color" strong>
                              {
                                valuesEducation && valuesEducation.educationEndYear ? 
                                  valuesEducation.educationEndYear 
                                : getLastEducation?.data?.educationEndYear ?
                                  getLastEducation?.data?.educationEndYear
                                :
                                  '-'
                              }
                            </Text>
                          </Space>
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12" type="secondary">{t('jobDetail.education.educationExperience')}</Text>
                            <Text className="dark-color" strong>
                              {
                                valuesEducation && valuesEducation.educationExperience ? 
                                  valuesEducation.educationExperience 
                                : getLastEducation?.data?.educationExperience ?
                                  getLastEducation?.data?.educationExperience
                                :
                                  '-'
                              }
                            </Text>
                          </Space>
                        </>
                    }
                  </Space>
                </Col>
              </Row>
            </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(index))