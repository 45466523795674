import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Typography, Modal, Form, Input, Space, Row, Col, Select, message } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { indexCountry, unmountIndexCountry } from '../../../../../redux/actions/master/country/countryAction';
import { checkMobile } from '../../../../../redux/actions/check/checkAction';
import { updateMobilePhone } from '../../../../../redux/actions/profile/headerAction';
import Cookie from "js-cookie";
import CButton from '../../../../../components/Button';

const { Option } = Select;
const { Text } = Typography;

export class MobileNumber extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      visible: false,
      idCallingCode: 99,
      callingCode: 62,
    }
  }

  showModal = () => {
    const { actionGetCountry } = this.props
    this.setState({
      visible: true
    })
    return actionGetCountry()
  };

  handleCancel = () => {
    this.setState({
      visible: false
    })
  };

  onChangeCode = (value, option) => {
    this.setState({
      callingCode: option.children[1]
    })
    this.formRef.current.setFieldsValue({
      mobilePhone: null
    })
  }

  onCheckMobile = (e) => {
    const { callingCode } = this.state
    const { t, actionCheckMobile, data } = this.props

    this.formRef.current.setFieldsValue({
      mobilePhone: e.replace(/^0+/, '')
    })

    e = `${callingCode}${e.replace(/^0+/, '')}`
    
    if(e !== `${data.callingCode}${data.mobilePhone}`){
      return actionCheckMobile(e, response => {
        if(response){
          this.formRef.current.setFieldsValue({
            mobilePhone: null
          })
          message.error(t('mobileNumberExist'))
        }
      })
    }
  }

  onFinish = async (values) => {
    const { callingCode } = this.state
    const { actionUpdateMobilePhone, actionGetProfile } = this.props

    values.callingCodeId = values.code
    values.mobilePhone = `${callingCode}${values.mobilePhone}`
    
    this.setState({ submitLoading: true }, () => {
      return actionUpdateMobilePhone(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            Cookie.remove('activeElement')
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const { submitLoading, visible, idCallingCode } = this.state
    const { t, data, getCountry } = this.props
    

    const prefixSelector = (
      <Form.Item name="code" noStyle>
        <Select className="prefix-selector" onChange={this.onChangeCode} loading={getCountry.loading}>
          {
            getCountry.data?.map(item =>
              <Option key={item.id} value={item.id}>+{item.callingCode}</Option>
            )
          }
        </Select>
      </Form.Item>
    )

    return (
      <React.Fragment>
        <Space>
          <Text className="fs-12 dark-color">{`+${data.callingCode} ${data.mobilePhone}`}</Text>
          <EditFilled className="fs-12 dark-color cursor-pointer" onClick={this.showModal} />
        </Space>

        <Modal 
          title={t('profile.header.formNumber.title')} 
          visible={visible} 
          onCancel={this.handleCancel}
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          width={600}
          destroyOnClose
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
            initialValues={{ 
              code: +idCallingCode
            }}
          >
            <Row className="mb-8">
              <Col span={24}>
                <Space size={0} direction="vertical">
                  <Text className="fs-12" type="secondary">{t('profile.header.formNumber.currentNumber')}</Text>
                  <Text className="fs-13 dark-color" type="secondary">{`+${data.callingCode} ${data.mobilePhone}`}</Text>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">{t('profile.header.formNumber.newNumber')}</Text>
                </Space>
                <Form.Item 
                  className="mb-16"
                  name="mobilePhone"
                  validateFirst
                  rules={[
                    { required: true, message: t('required') },
                    { pattern: /^[0-9]*$/, message: t('0-9') },
                    { min: 7, max: 13, message: t('typeMobileNumber') },
                    // { validator(rule, value, callback){
                    //     if(idCallingCode === 99)
                    //       if(value.charAt(0) === '0' || value.charAt(0) === '8' || value.charAt(0) === ''){
                    //         callback()
                    //       }else{
                    //         callback('Invalid phone number')
                    //       }
                    //   }
                    // }
                  ]}
                >
                  <Input onBlur={(e) => this.onCheckMobile(e.target.value)} addonBefore={prefixSelector} placeholder={t('profile.header.formNumber.placeholderNewNumber')} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space className="float-right">
                <CButton type="default" action={this.handleCancel} title={t('profile.buttonBack')}/>
                <CButton htmlType="submit" type="primary" loading={submitLoading} title={t('profile.buttonSave')}/>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountIndexCountry } = this.props
    return unmountIndexCountry()
  }
}

const mapStateToProps = (state) => ({
  getCountry: state.countryReducer
})

const mapDispatchToProps = {
  actionGetCountry: indexCountry,
  actionCheckMobile: checkMobile,
  actionUpdateMobilePhone: updateMobilePhone,
  unmountIndexCountry: unmountIndexCountry
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MobileNumber))