import React from 'react';
import moment from 'moment';
import { Form, Space, Select, Typography, Row, Col, Input, DatePicker, Spin } from 'antd';

const { Text } = Typography;
const { Option } = Select;

export default function PersonalInfo(props) {

  
  
  const { 
    t,
    state: {
      callingCodeId,
      location,
      allValues
    },
    onCheckEmail,
    onCheckMobileNumber,
    onChangeCallingCode,
    onChangeBirthdate,
    onFocusCity,
    onSearchCity,
    onChangeCity,
    getCountry,
    getCity,
    getProfile
  } = props

  const mobileNumber = (
    <Form.Item 
      noStyle
      name="callingCodeId"
      rules={[ {required: true, message: 'Please input your calling code'} ]}
      initialValue={allValues?.callingCodeId || getProfile.data.callingCodeId || callingCodeId}
    >
      <Select 
        showSearch
        optionFilterProp="children"
        onChange={onChangeCallingCode}
        disabled={getProfile.data.mobilePhone}
      >
        {
          getCountry?.data?.map(item => (
            <Select.Option key={item.id} value={item.id} disabled={getProfile.data.mobilePhone}>
              {`+${item.callingCode}`}
            </Select.Option>
          ))
        }
      </Select>
    </Form.Item>
  )

  return (
    <React.Fragment>
      <Row gutter={16}>
        <Col className='mb-min-8' xs={24} md={12}>
          <Space size={2}>
            {/* <Text className="fs-12" type="danger">*</Text> */}
            <Text className="fs-12" type="secondary">{t('profileUpdate.name')}</Text>
          </Space>
          <Form.Item
            name="fullName"
            rules={[
              { required: true, message: t('profileUpdate.careerPathway.alert.name') },
              { pattern: /^[a-zA-Z ]*$/, message: t('profileUpdate.careerPathway.alert.patternName') },
              { min: 3, message: t('profileUpdate.careerPathway.alert.minName') },
              { max: 50, message: t('profileUpdate.careerPathway.alert.maxName') },
            ]}
            initialValue={allValues?.fullName || getProfile.data.fullName}
          >
            <Input size='small' disabled={getProfile.data.fullName} />
          </Form.Item>
        </Col>

        <Col className='mb-min-8' xs={24} md={12}>
          <Space size={2}>
            {/* <Text className="fs-12" type="danger">*</Text> */}
            <Text className="fs-12" type="secondary">{t('profileUpdate.email')}</Text>
          </Space>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: t('profileUpdate.careerPathway.alert.email') },
              { type: 'email', message: t('profileUpdate.careerPathway.alert.typeEmail') },
              // { pattern:  /.+@(gmail|yahoo)\.com$/, message: 'Preferred using Gmail or Yahoo' }
            ]}
            initialValue={allValues?.email || getProfile.data.email}
          >
            <Input size='small' onBlur={(e) => onCheckEmail(e.target.value)} disabled={getProfile.data.email} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className='mb-min-8' xs={24} md={12}>
          <Space size={0}>
            {/* <Text className="fs-12" type="danger">*</Text> */}
            <Text className="fs-12" type="secondary">{t('profileUpdate.noHandphone')}</Text>
          </Space>
          <Form.Item 
            name="mobilePhone"
            validateFirst
            rules={[
              { required: true, message: t('profileUpdate.careerPathway.alert.phoneNumber') },
              { min: 7, message: t('profileUpdate.careerPathway.alert.minMobile') },
              { max: 13, message: t('profileUpdate.careerPathway.alert.maxMobile') },
              { pattern: /^[0-9]*$/, message: t('profileUpdate.careerPathway.alert.patternMobile') },                
            ]}
            initialValue={allValues?.mobilePhone || getProfile.data.mobilePhone}
          >
            <Input size='small' addonBefore={mobileNumber} onBlur={(e) => onCheckMobileNumber(e.target.value)} disabled={getProfile.data.mobilePhone} />
          </Form.Item>
        </Col>

        <Col className='mb-min-8' xs={24} md={12}>
          <Space size={2}>
            {/* <Text className="fs-12" type="danger">*</Text> */}
            <Text Text className="fs-12" type="secondary">{t('profileUpdate.gender')}</Text>
          </Space>
          <Form.Item 
            name="gender"
            rules={[
              { required: true, message: t('profileUpdate.careerPathway.alert.gender') },
            ]}
            initialValue={allValues?.gender || getProfile.data.gender}
          >
            <Select 
              className="width-100 border-radius-6"
              size="small" 
              disabled={getProfile.data.gender}
            >
              <Option key="1" value="MALE">{t('profileUpdate.careerPathway.alert.male')}</Option>
              <Option key="2" value="FEMALE">{t('profileUpdate.careerPathway.alert.Female')}</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className='mb-min-8' xs={24} md={12}>
          <Space size={2}>
            {/* <Text className="fs-12" type="danger">*</Text> */}
            <Text className="fs-12" type="secondary">{t('profile.personalInfo.placeOfBirth')}</Text>
          </Space>
          <Form.Item 
            name="placeOfBirth"
            validateFirst
            rules={[
              { required: true, message: t('profileUpdate.careerPathway.alert.placeOfBirth') },
              { pattern: /^[a-zA-Z ]*$/, message: t('profileUpdate.careerPathway.alert.patternPlace') },
              { max: 50, message: t('profileUpdate.careerPathway.alert.maxPlace') },
            ]}
            initialValue={allValues?.placeOfBirth || getProfile.data.placeOfBirth}
          >
            <Input size="small" disabled={getProfile.data.placeOfBirth} />
          </Form.Item>
        </Col>

        <Col className='mb-min-8' xs={24} md={12}>
          <Space size={2}>
            {/* <Text className="fs-12" type="danger">*</Text> */}
            <Text className="fs-12" type="secondary">{t('profile.personalInfo.dateOfBirth')}</Text>
          </Space>
          <Form.Item 
            name="birthdate"
            rules={[
              { required: true, message: t('profileUpdate.careerPathway.alert.dateOfBirth') },
            ]}
            initialValue={allValues?.birthdate ? moment(allValues?.birthdate, 'DD/MM/YYYY') : getProfile.data.birthdate ? moment(getProfile.data.birthdate, 'DD/MM/YYYY') : null}
          >
            <DatePicker inputReadOnly className="width-100" size="small" format="DD/MM/YYYY" placeholder={t('profile.education.form.selectYear')} onChange={onChangeBirthdate} defaultPickerValue={getProfile.data.birthdate ? null : moment('2003-04-15')} disabled={getProfile.data.birthdate} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className='mb-min-8' xs={24} md={12}>
          <Space size={2}>
            {/* <Text className="fs-12" type="danger">*</Text> */}
            <Text className="fs-12" type="secondary">{t('profile.personalInfo.maritalStatus')}</Text>
          </Space>
          <Form.Item
            name="marital"
            rules={[
              { required: true, message: t('profileUpdate.careerPathway.alert.maritalStatus') },
            ]}
            initialValue={allValues?.marital || getProfile.data.marital}
          >
            <Select 
              className="width-100 border-radius-6"
              size="small" 
              disabled={getProfile.data.marital}
            >
              <Option key="1" value="SINGLE">{t('profile.personalInfo.single')}</Option>
              <Option key="2" value="MARRIED">{t('profile.personalInfo.married')}</Option>
              <Option key="3" value="DIVORCE">{t('profile.personalInfo.divorce')}</Option>
              <Option key="4" value="WIDOWED">{t('profile.personalInfo.widowed')}</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col className='mb-min-8' xs={24} md={12} >
          <Space size={2}>
            {/* <Text className="fs-12" type="danger">*</Text> */}
            <Text className="fs-12" type="secondary">{t('profileUpdate.currentLocation')}</Text>
          </Space>
          <Form.Item 
            name="currentCityId"
            rules={[
              { required: true, message: t('profileUpdate.careerPathway.alert.currentLocation') },
            ]}
            initialValue={location || getProfile.data.currentCityName ? location || `${getProfile.data.currentCityName}, ${getProfile.data.currentCountryName}` : null}
          >
            <Select 
              className="width-100" 
              size="small" 
              showSearch
              onFocus={onFocusCity}
              onSearch={onSearchCity}
              onChange={onChangeCity}
              placeholder={t('profile.header.formLocation.placeholderCity')}
              notFoundContent={getCity.loading ? <Spin size="small" /> : null}
              disabled={getProfile.data.currentCityName}
            >
              {
                getCity?.data?.map(item => 
                  <Option key={item.id} value={`${item.name}, ${item.countryName}`}>{`${item.name}, ${item.countryName}`}</Option>
                )
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  )
}