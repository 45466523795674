import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { exportComponentAsPNG } from 'react-component-export-image';
import { Row, Col, Card, Space, Typography, Grid, Image } from 'antd';
import KartuIlubin from '../../../../../assets/img/candidate/kartu-ilubin.png';
import CButton from '../../../../../components/Button';
const { Text } = Typography;
const { useBreakpoint } = Grid;
function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function TracerStudySuccess(props) {
  const { location: { state: { tracerStudyNo, name } } } = props
  const componentRef = useRef();
  const { xs, xxl } = GetBreakPoint();
  return (
    <React.Fragment>
      <Row className={xs?"mt-16":"mt-32"}>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Card className="border-radius-6 box-shadow mb-16 text-align-center">
            <Row>
              <Col xs={{ span: 24, offset: 0 }} md={{ span: 22, offset: 1 }}>
                <Space className="width-100 mb-16" direction="vertical" size={32}>
                  <Text className="dark-color fs-20">Tracer Study Berhasil Dikirim!</Text>
                  <Text className="dark-color fs-15">Dengan mengisi tracer study, kamu telah membantu Universitas Binawan untuk berkembang menjadi lebih baik</Text>
                </Space>
                  <div name="qwerty" className="box-shadow border-radius-6" ref={componentRef} style={{ width: xs ? '100%' : xxl ? '50%' : '60%', margin: '0 auto' }}>
                    <Image
                      width={'100%'}
                      src={KartuIlubin}
                      preview={false}
                      style={{ display: 'inherit' }}
                    />
                    <Space direction="vertical" size={xs ? 0 : xxl ? 8 : 4} style={{ position: 'absolute', bottom: xs ? 130 : 90, left: xs ? 30 : xxl ? 290 : 190 }}>
                      <Text className={xs ? "fs-15 float-left" : xxl ? "fs-18 float-left" : "fs-16 float-left"} style={{ color: '#d2ad33' }}>{tracerStudyNo}</Text>
                      <Text className={xs ? "fs-15 float-left" : xxl ? "fs-18 float-left" : "fs-16 float-left"} style={{ color: '#d2ad33' }}>{name}</Text>
                    </Space>
                  </div>
                <Row className="mt-32" gutter={[16,8]}>
                  <Col xs={24} lg={12}>
                    <CButton className="float-right" type="primary" action={() => exportComponentAsPNG(componentRef,{fileName:`ILUBIN_${name}`})} style={{ width: xs ? '100%' : xxl ? '80%' : '90%' }} title="Download Kartu ILUBIN"/>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Link to='/candidate/profile'>
                      <CButton className="float-left" type="primary" ghost style={{ width: xs ? '100%' : xxl ? '80%' : '90%' }} title="Halaman Profile"/>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}