import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';
import { setActivation } from '../../../redux/actions/register/registerAction';
import { setResend } from '../../../redux/actions/check/checkAction';
import { Form, Input, Row, Col, Card, Typography, Space, Statistic, message } from 'antd';
import { SafetyOutlined } from '@ant-design/icons';
import './ConfirmEmail.css';
import CButton from '../../../components/Button';

const { Countdown } = Statistic;
const { Title, Text } = Typography;

export class ConfirmEmail extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      isCountdown: true,
      countdown: Date.now() + 60 * 2000
    }
  }

  onFinish = (values) => {
    const { actionSetActivation } = this.props

    values.email = localStorage.getItem('email')
    values.password = localStorage.getItem('password')

    return actionSetActivation(values, response => {
      if(response.code === '1000'){
        this.setState({ submitLoading: false }, () => {
          localStorage.removeItem('email')
          localStorage.removeItem('password')
          this.props.history.push('/job-preferences/industries')
        })
      }else{
        message.error(response.message)
      }
    }, (err) => {
      message.error(err)
    })
  }

  onClickResend = () => {
    const value = {
      email: localStorage.getItem('email')
    }

    const { actionSetResend } = this.props

    actionSetResend(value, response => {
      message.success(response.message)
      this.setState({
        isCountdown: true,
        countdown: Date.now() + 60 * 2000
      })
    }, (err) => {
      message.error(err)
    })
  }

  onFinishCountdown = () => {
    this.setState({
      isCountdown: false
    })
  }

  render() {
    const { isCountdown, countdown } = this.state
    const { t } = this.props

    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Confirm Email');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Confirm Email</title>
        </Helmet>
        <Row className="display-flex pt-32 margin-16" align="middle">
          <Col xs={24} md={{ span: 12, offset: 6 }}>
            <Form onFinish={this.onFinish}>
              <Space className="width-100" align="center" direction="vertical" size="small">
                <Title className="dark-color font-weight-normal mb-0" level={2}>{t('confirmEmail.title')}</Title>
                <Text className="dark-color font-weight-normal">{t('confirmEmail.subTitle',{email:localStorage.getItem('email')})}</Text>
              </Space>
              <Form.Item name="activationCode">
                <Input className="text-align-center mt-32 mb-16" placeholder="_ _ _ _ _ _" size="large" />
              </Form.Item>
              <Card className="card-confirm-email dark-color width-100 text-align-left mb-16">
                <Space direction="vertical">
                  <Space>
                    <SafetyOutlined className="icon-md" />
                    <Text className="fs-13">{t('confirmEmail.cardTitle')}</Text>
                  </Space>
                  <Text className="dark-color fs-13">{t('confirmEmail.cardSubTitle')}</Text>
                </Space>
              </Card>
              <CButton className="width-100 mb-8" htmlType="submit" type="primary" title={t('confirmEmail.button')}/>
              <Space size={4}>
                  <Text className="fs-11 text-align-left" type="secondary">{t('confirmEmail.timer')}</Text>
                  {
                    isCountdown ?
                    <Countdown value={countdown} onFinish={this.onFinishCountdown} format="mm:ss" valueStyle={{ fontSize: 11 }} />
                    :
                    <Text className="fs-12 info-color cursor-pointer" onClick={this.onClickResend}>Send a new one</Text>
                  }
                </Space>
            </Form>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
  actionSetActivation: setActivation,
  actionSetResend: setResend,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail))
