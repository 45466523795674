import React from 'react'
import { Card, Space, Row, Col, Typography, Image, Grid } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import CorrectPasport from '../../assets/img/document/pasport-correct.png';
import IncorrectPasport from '../../assets/img/document/pasport-incorrect.png';
import { withTranslation } from 'react-i18next';

const { Text } = Typography;
const { useBreakpoint } = Grid;

function GetBreakPoint() {
    const screens = useBreakpoint()
    return screens;
}

export function InfoPasport(props) {
    const { xs } = GetBreakPoint()
    const { t } = props
    return (
        <Card className='border-radius-6' bodyStyle={{ padding: xs ? 8 : 32 }}>
            <Row>
                <Col span={24}>
                    <Row align={xs ? "top" : "middle"}>
                        <Col className='text-align-center' xs={12} md={7}>
                            <Space direction="vertical" size={xs ? 8 : 16}>
                                <CloseCircleFilled className='danger-color fs-28' />
                                <Image src={IncorrectPasport} width="100%" height={xs ? 160 : 205} preview={false} style={{ objectFit: 'contain' }} />
                            </Space>
                        </Col>
                        <Col className='text-align-center' xs={12} md={7}>
                            <Space direction="vertical" size={xs ? 8 : 16}>
                                <CheckCircleFilled className='primary-color fs-28' />
                                <Image src={CorrectPasport} width="100%" height={xs ? 170 : 210} preview={false} style={{ objectFit: 'contain' }} />
                            </Space>
                        </Col>
                        <Col xs={24} md={10}>
                            <Space direction="vertical" size={0}>
                                <Text type="secondary">{t('profile.document.info.ktp.title')}</Text>
                                <Text type="secondary">• {t('profile.document.info.ktp.first.normal1')}<Text className='dark-color' strong>{t('profile.document.info.ktp.first.bold')}</Text>{t('profile.document.info.ktp.first.normal2')}</Text>
                                <Text type="secondary">• {t('profile.document.info.ktp.second')}</Text>
                                <Text type="secondary">• {t('profile.document.info.ktp.third')}</Text>
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}
export default withTranslation()(InfoPasport)
