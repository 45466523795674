import React from 'react';
import { Card, Typography, Modal, Form, Input, Space, Row, Col, Checkbox, DatePicker, Select, Grid, Spin, Divider, Tooltip } from 'antd';
import { DownloadOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import './Document.css';
import InfoPhotoVisa from '../../../../components/Document/InfoPhotoVisa';
import InfoKTP from '../../../../components/Document/InfoKtp';
import InfoPasport from '../../../../components/Document/InfoPasport';
import InfoKk from '../../../../components/Document/InfoKk';
import CButton from '../../../../components/Button';

const { useBreakpoint } = Grid;
const { Text } = Typography;
const { TextArea } = Input;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function DocumentForm(props) {
  const { t, data, visible, submitLoading, deleteLoading, current, showModal, handleCancel, onChangeStartDate, onChangeEndDate, handleChecked, formRef, onFinish, id, dataEdit, startDate, handleDelete, getDocumentTypes, uploadDocument, handleDocType, docType } = props
  const { md } = GetBreakPoint()

  const disabledNextMonth = (current) => {
    return current && current > moment();
  }

  const disabledDatePassport = (current) => {
    return current && current < moment(startDate)
  }

  return (
    <React.Fragment>
      <Card 
        className="border-radius-6 box-shadow" 
        title={t('profile.document.title')} 
        bordered={false} 
        headStyle={{ color: '#32ab6d', fontSize: 17 }}
        bodyStyle={data.listDocument.length > 0 ? {} : { textAlign: 'center', padding: 60 }}
        actions={[
          <Text className="primary-color" onClick={() => showModal(null)}>{t('profile.document.buttonAdd')}</Text>
        ]}
      >
        {
          data.listDocument.length > 0 ?
            data.listDocument.map((item, i) => 
              md ?
                <React.Fragment key={i}>
                  <div style={{ marginBottom: 11, display: 'flex', justifyContent: 'space-between' }}>
                    <Space direction='vertical' size={0}>
                      <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                        {item.documentName}
                      </Text>
                      <Text style={{ fontSize: 14 }}>
                        {item.documentNumber}
                      </Text>
                      <Text style={{ fontSize: 14 }}>
                        {
                          item.issueDate && item.expirationDate ?
                            `${moment(item.issueDate, 'MM/YYYY').format('MMM YYYY')} - ${moment(item.expirationDate, 'MM/YYYY').format('MMM YYYY')}`
                          : item.issueDate && item.current ?
                            `${moment(item.issueDate, 'MM/YYYY').format('MMM YYYY')} - No Expiration Date`
                          : item.current ?
                            'No Expiration Date'
                          : item.issueDate ?
                            `${moment(item.issueDate, 'MM/YYYY').format('MMM YYYY')} - N/A`
                          : item.expirationDate ?
                            `N/A - ${moment(item.expirationDate, 'MM/YYYY').format('MMM YYYY')}`
                          : null
                        }
                      </Text>
                    </Space>
                    <Space>
                      <a href={item.documentUrl} target="_blank" rel='noreferrer'>
                        <Tooltip title="Download Document">
                          <DownloadOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} />
                        </Tooltip>
                      </a>
                      <Tooltip title="Edit">
                        <EditOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} onClick={() => showModal(item.id)} />
                      </Tooltip>
                    </Space>
                  </div>
                  <Divider />
                </React.Fragment>
              :
                <React.Fragment key={i}>
                  <div style={{ marginBottom: 11, display: 'flex', justifyContent: 'space-between' }}>
                    <Space direction='vertical' size={0}>
                      <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                        {item.documentName}
                      </Text>
                      <Text style={{ fontSize: 14 }}>
                        {item.documentNumber}
                      </Text>
                      <Text style={{ fontSize: 14 }}>
                        {
                          item.issueDate && item.expirationDate ?
                            `${moment(item.issueDate, 'MM/YYYY').format('MMM YYYY')} - ${moment(item.expirationDate, 'MM/YYYY').format('MMM YYYY')}`
                          : item.issueDate && item.current ?
                            `${moment(item.issueDate, 'MM/YYYY').format('MMM YYYY')} - No Expiration Date`
                          : null
                        }
                      </Text>
                    </Space>
                    <Space>
                      <a href={item.documentUrl} target="_blank" rel='noreferrer'>
                        <Tooltip title="Download Document">
                            <DownloadOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} />
                        </Tooltip>
                      </a>
                      <Tooltip title="Edit">
                        <EditOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} onClick={() => showModal(item.id)} />
                      </Tooltip>
                    </Space>
                  </div>
                  <Divider />
                </React.Fragment>
            )
          :
            <Text className="dark-color">{t('profile.document.textCard')}</Text>
        }
      </Card>

      <Modal 
        title={t('profile.document.title')} 
        visible={visible} 
        onCancel={handleCancel} 
        footer={false}
        bodyStyle={{ paddingBottom: 4 }}
        centered
        width={1000}
        destroyOnClose
        maskClosable={false}
      >
        <Form 
          ref={formRef}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            documentTypeId: id ? dataEdit?.documentTypeId : null,
            documentNumber: id ? dataEdit?.documentNumber : null,
            issueDate: id && dataEdit?.issueDate ? moment(dataEdit?.issueDate, 'MM/YYYY') : null,
            expirationDate: id && dataEdit?.expirationDate ? moment(dataEdit?.expirationDate, 'MM/YYYY') : null,
            documentFile: id ? dataEdit?.documentUrl : null,
            remark: id ? dataEdit?.remark : null
          }}
        >
          {
            md ?
              <React.Fragment>
                <Row gutter={16}>
                  <Col className="width-100 mt-min-8" span={12}>
                    <Text className="fs-12" type="danger">* </Text>
                    <Text className="fs-12" type="secondary">{t('profile.document.documentType')}</Text>
                    <Form.Item 
                      name="documentTypeId"
                      validateFirst
                      rules={[
                        { required: true,  message: t('required') }
                      ]}
                    >
                      <Select
                        loading={getDocumentTypes?.loading}
                        notFoundContent={getDocumentTypes.loading ? <Spin size="small" /> : null}
                        onChange={handleDocType}
                        disabled={dataEdit}
                      >
                        {
                          getDocumentTypes?.data && getDocumentTypes?.data?.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="width-100 mt-min-8" span={12}>
                    {
                      docType !== 29 ?
                        <>
                          { docType === 4 || docType === 8 || docType === 1 ? <Text className="fs-12" type="danger">* </Text> : null }
                          <Text className="fs-12" type="secondary">
                            {
                              docType === 4 ? 
                                t('profile.document.info.ktp.name') 
                              : docType === 8 ? 
                                t('profile.document.info.paspor.name') 
                              : docType === 1 ?
                                t('profile.document.info.kk.name')
                              : t('profile.document.documentNumber')}
                          </Text>
                          <Form.Item 
                            name="documentNumber"
                            validateFirst
                            rules={
                              docType === 4 || docType === 1 ?
                                [
                                  { required: docType === 4 || docType === 1 , message: t('required') },
                                  { pattern: /^[0-9]*$/, message: 'Numbers Only' },
                                  { len: 16, message: t('len',{number:16}) }
                                ]
                                : docType === 8 ? 
                                  [
                                    { required: docType === 8 , message: t('required') },
                                    { min: 5, message: t('min',{number:5}) },
                                    { max: 20, message: t('max',{number:20}) }
                                  ]
                                :
                                  [
                                    { max: 50, message: t('max',{number:50}) }
                                  ]
                            }
                          >
                            <Input  />
                          </Form.Item>
                        </>
                      : null
                    }
                  </Col>
                  {
                    docType === 29 || docType === 4 || docType === 8 || docType === 1 ?
                      null
                    :
                      <Col className="width-100 mt-min-8" span={24}>
                        <Form.Item name="current">
                          <Checkbox className="fs-12" onChange={handleChecked} checked={current}>{t('profile.document.checkbox')}</Checkbox>
                        </Form.Item>
                      </Col>
                  }
                  {
                    docType !== 29 ?
                      <Col className="width-100 mt-min-8" span={12}>
                        { docType === 8 || docType === 4 || docType === 1 ? <Text className="fs-12" type="danger">* </Text> : null }
                        <Text className="fs-12" type="secondary">{t('profile.document.issueDate')}</Text>
                        <Form.Item 
                          name="issueDate"
                          rules={[
                            { required: docType === 8 || docType === 4 || docType === 1 , message: t('required') }
                          ]}
                        >
                          <DatePicker 
                            picker="month" 
                            placeholder="YYYY-MM" 
                            style={{ width: '100%' }}
                            onChange={onChangeStartDate} 
                            disabledDate={ docType === 8 || docType === 4 || docType === 1 ? disabledNextMonth : null }
                          />
                        </Form.Item>
                      </Col>
                    : null
                  }
                  {
                    docType === 29 || docType === 4 || docType === 1 ?
                      null
                    :
                      <Col span={12} className="width-100 mt-min-8">
                        { docType === 4 || docType === 8 ? <Text className="fs-12" type="danger">* </Text> : null }
                        <Text className="fs-12" type="secondary">{t('profile.document.expirationDate')}</Text>
                        <Form.Item 
                          name="expirationDate"
                          // validateStatus={isDate && !current ? "error" : null}
                          // help={isDate && !current ? t('validationExpDate') : null}
                          rules={[
                            { required: docType === 8 , message: t('required') }
                          ]}
                        >
                          {
                            current ?
                            <Text className="fs-12" type="secondary">{t('profile.document.noExp')}</Text>
                            :
                            <DatePicker 
                              picker="month" 
                              placeholder="YYYY-MM" 
                              style={{ width: '100%' }}
                              onChange={onChangeEndDate} 
                              disabledDate={startDate ? disabledDatePassport : null}
                            />
                          }
                        </Form.Item>
                      </Col>
                  }
                  {
                    docType === 29 ?
                      <Col className='mb-32' span={24}>
                        <InfoPhotoVisa />
                      </Col>
                    : null
                  }
                  {
                    docType === 4 ?
                      <Col className='mb-32' span={24}>
                        <InfoKTP />
                      </Col>
                    : null
                  }
                  {
                    docType === 8 ?
                      <Col className='mb-32' span={24}>
                        <InfoPasport />
                      </Col>
                    : null
                  }
                  {
                    docType === 1 ?
                      <Col className='mb-32' span={24}>
                        <InfoKk />
                      </Col>
                    : null
                  }
                  <Col className="width-100 mt-min-8" span={24}>
                    <Form.Item 
                      name="documentFile"
                      rules={[
                        { required: true, message: t('required') },
                      ]}
                    >
                      {uploadDocument}
                    </Form.Item>
                  </Col>
                </Row>
              </React.Fragment>
            :
              <React.Fragment>
                <Row gutter={16}>
                  <Col className="width-100 mt-min-8" span={24}>
                    <Text className="fs-12" type="danger">* </Text>
                    <Text className="fs-12" type="secondary">{t('profile.document.documentType')}</Text>
                    <Form.Item 
                      name="documentTypeId"
                      validateFirst
                      rules={[
                        { required: true,  message: t('required') }
                      ]}
                    >
                      <Select
                        loading={getDocumentTypes?.loading}
                        notFoundContent={getDocumentTypes.loading ? <Spin size="small" /> : null}
                        onChange={handleDocType}
                        disabled={dataEdit}
                      >
                        {
                          getDocumentTypes?.data && getDocumentTypes?.data?.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="width-100 mt-min-8" span={24}>
                    {
                      docType !== 29 ?
                        <>
                          { docType === 4 || docType === 8 || docType === 1 ? <Text className="fs-12" type="danger">* </Text> : null }
                          <Text className="fs-12" type="secondary">
                            {
                              docType === 4 ? 
                                'Nomor Induk Kependudukan (NIK)' 
                              : docType === 8 ? 
                                'Nomor Paspor' 
                              : docType === 1 ?
                                'Nomor Kartu Keluarga'
                              : t('profile.document.documentNumber')}
                          </Text>
                          <Form.Item 
                            name="documentNumber"
                            validateFirst
                            rules={
                              docType === 4 || docType === 1 ?
                                [
                                  { required: docType === 4 || docType === 8 , message: t('required') },
                                  { pattern: /^[0-9]*$/, message: 'Numbers Only' },
                                  { len: 16, message: t('len',{number:16}) }
                                ]
                                : docType === 8 ? 
                                  [
                                    { required: docType === 4 || docType === 8 , message: t('required') },
                                    { min: 5, message: t('min',{number:5}) },
                                    { max: 20, message: t('max',{number:20}) }
                                  ]
                                :
                                  [
                                    { max: 50, message: t('max',{number:50}) }
                                  ]
                            }
                          >
                            <Input />
                          </Form.Item>
                        </>
                      : null
                    }
                  </Col>
                  {
                    docType === 29 || docType === 4 || docType === 8 || docType === 1 ?
                      null
                    :
                      <Col className="width-100 mt-min-8" span={24}>
                        <Form.Item name="current">
                          <Checkbox className="fs-12" onChange={handleChecked} checked={current}>{t('profile.document.checkbox')}</Checkbox>
                        </Form.Item>
                      </Col>
                  }
                  {
                    docType !== 29 ?
                      <Col className="width-100 mt-min-8" span={24}>
                        { docType === 8 || docType === 4 || docType === 1 ? <Text className="fs-12" type="danger">* </Text> : null }
                        <Text className="fs-12" type="secondary">{t('profile.document.issueDate')}</Text>
                        <Form.Item 
                          name="issueDate"
                          rules={[
                            { required: docType === 8 || docType === 4 || docType === 1 , message: t('required') }
                          ]}
                        >
                          <DatePicker 
                            size="small"
                            picker="month" 
                            placeholder="YYYY-MM" 
                            style={{ width: '100%' }}
                            onChange={onChangeStartDate} 
                            disabledDate={ docType === 8 || docType === 4 || docType === 1 ? disabledNextMonth : null }
                          />
                        </Form.Item>
                      </Col>
                    : null
                  }
                  {
                    docType === 29 || docType === 4 || docType === 1 ?
                      null
                    :
                      <Col className="width-100 mt-min-8" span={24}>
                        { docType === 4 || docType === 8 ? <Text className="fs-12" type="danger">* </Text> : null }
                        <Text className="fs-12" type="secondary">{t('profile.document.expirationDate')}</Text>
                        <Form.Item 
                          name="expirationDate"
                          // validateStatus={isDate && !current ? "error" : null}
                          // help={isDate && !current ? t('validationExpDate') : null}
                          rules={[
                            { required: docType === 8 , message: t('required') }
                          ]}
                        >
                          {
                            current ?
                            <Text className="fs-12" type="secondary">{t('profile.document.noExp')}</Text>
                            :
                            <DatePicker 
                              size="small"
                              picker="month" 
                              placeholder="YYYY-MM" 
                              style={{ width: '100%' }}
                              onChange={onChangeEndDate} 
                            />
                          }
                        </Form.Item>
                      </Col>
                  }
                  {
                    docType === 29 ?
                      <Col className='mb-32' span={24}>
                        <InfoPhotoVisa />
                      </Col>
                    : null
                  }
                  {
                    docType === 4 ?
                      <Col className='mb-32' span={24}>
                        <InfoKTP />
                      </Col>
                    : null
                  }
                  {
                    docType === 8 ?
                      <Col className='mb-32' span={24}>
                        <InfoPasport />
                      </Col>
                    : null
                  }
                  {
                    docType === 1 ?
                      <Col className='mb-32' span={24}>
                        <InfoKk />
                      </Col>
                    : null
                  }
                  <Col className="width-100 mt-min-8" span={24}>
                    <Form.Item 
                      name="documentFile"
                      rules={[
                        { required: true, message: t('required') },
                      ]}
                    >
                      {uploadDocument}
                    </Form.Item>
                  </Col>
                </Row>
              </React.Fragment>
          }
          <Row>
            {
              docType === 29 || docType === 4 || docType === 8 || docType === 1 ?
                null
              :
                <Col className="width-100 mt-min-8" span={24}>
                  <Text className="fs-12" type="secondary">{t('profile.document.remarks')}</Text>
                  <Form.Item 
                    name="remark"
                    validateFirst
                    rules={[
                      { max: 200, message: t('max',{number:200}) },
                    ]}
                  >
                    <TextArea rows={3} />
                  </Form.Item>
                </Col>
            }
          </Row>
          <Form.Item>
            {
              id ?
                <React.Fragment>
                  <CButton type='danger-outline' className="float-left" title={t('profile.buttonDelete')} action={handleDelete} loading={deleteLoading} danger/>
                  <CButton type="primary" className="float-right" title={t('profile.buttonSave')} htmlType="submit" loading={submitLoading} />
                </React.Fragment>
              :
                <Space className="float-right">
                  <CButton type='default' title={t('profile.buttonBack')} action={handleCancel}/>
                  <CButton type="primary" title={t('profile.buttonSave')} htmlType="submit" loading={submitLoading} />
                </Space>
            }
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

