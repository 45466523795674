import { API } from '../../../config'
import { errorHandler } from '../auth/errorAction'

export const unmountIndexProfile = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_LANDING'})
}

export const dataLandingPage = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_LANDING' })
  return API.GET('/landing').then((response) => {
    dispatch({
      type: 'LOAD_DATA_LANDING_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_LANDING_FAILED' }), 
    ))
  })
}

export const dataLandingPageDomestic = () => async (dispatch) => {
  const params = {
    flagDomestic: true
  }

  await dispatch({ type: 'LOAD_DATA_LANDING' })
  return API.GET('/landing', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_LANDING_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_LANDING_FAILED' }), 
    ))
  })
}

export const dataLandingPageInternational = () => async (dispatch) => {
  const params = {
    flagDomestic: false
  }

  await dispatch({ type: 'LOAD_DATA_LANDING' })
  return API.GET('/landing', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_LANDING_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_LANDING_FAILED' }), 
    ))
  })
}