import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import CompanyComponent from '../../../components/Company';
import { message } from 'antd';
import { dataCompany, unmountDataCompany } from '../../../redux/actions/company/companyAction';
import { listJobCompany, unmountListJob } from '../../../redux/actions/job/jobAction';
import { addOrDeleteFavorite } from '../../../redux/actions/favorite/favoriteAction';

export class CandidateCompany extends Component {
  mediaRef = React.createRef()
  constructor(props) {
    super(props)
    
    this.state = {
      submitLoading: false,
      paramsJob: {
        page: 1,
        perpage: 5,
        search: null
      },
      openLightbox: false,
      slides: []
    }
    this.nextMedia = this.nextMedia.bind(this);
    this.previousMedia = this.previousMedia.bind(this);
  }
  
  componentDidMount() {
    const { paramsJob: { page, perpage, search } } = this.state
    const { actionGetCompany, actionGetJob, match: { params } } = this.props

    const paramsJob = {
      page: page,
      perpage: perpage,
      search: search
    }

    if(params.companyType === 'EMPLOYERS'){
      paramsJob.employersId = Number(params.id)
    }

    if(params.companyType === 'CLIENT'){
      paramsJob.clientId = Number(params.id)
    }

    window.scrollTo(0, 0);
    this.setState({ paramsJob })

    return (actionGetCompany(params.id, params.companyType), actionGetJob(paramsJob))
  }

  onSearch = (value) => {
    const { paramsJob: { page, perpage } } = this.state
    const { actionGetJob, match: { params } } = this.props

    const paramsJob = {
      page: page,
      perpage: perpage,
      search: value
    }
    if(params.companyType === 'EMPLOYERS'){
      paramsJob.employersId = Number(params.id)
    }
    if(params.companyType === 'CLIENT'){
      paramsJob.clientId = Number(params.id)
    }

    this.setState({ paramsJob })
    return actionGetJob(paramsJob)
  }

  onChangePagination = (pageNumber) => {
    const { paramsJob: { perpage, search } } = this.state
    const { actionGetJob, match: { params } } = this.props

    const paramsJob = {
      page: pageNumber,
      perpage: perpage,
      search: search
    }
    if(params.companyType === 'EMPLOYERS'){
      paramsJob.employersId = Number(params.id)
    }
    if(params.companyType === 'CLIENT'){
      paramsJob.clientId = Number(params.id)
    }

    this.setState({ paramsJob })
    return actionGetJob(paramsJob)
  }

  handleFavorite = (id, isFavorite) => {
    const { paramsJob: { page, perpage, search } } = this.state
    const { actionAddOrDeleteFavorite, actionGetJob, match: { params }, t } = this.props

    const paramsJob = {
      page: page,
      perpage: perpage,
      search: search
    }
    if(params.companyType === 'EMPLOYERS'){
      paramsJob.employersId = Number(params.id)
    }
    if(params.companyType === 'CLIENT'){
      paramsJob.clientId = Number(params.id)
    }
    
    const values = {
      jobId: id
    }

    return this.setState({ submitLoading: true }, () => {
      return actionAddOrDeleteFavorite(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false }, () => {
            message.success(isFavorite ? t('jobList.jobRemove') : t('jobList.jobFavorite') )
            return actionGetJob(paramsJob)
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  nextMedia() {
    this.mediaRef.current.next()
  }

  previousMedia() {
    this.mediaRef.current.prev()
  }

  handleOpenLightbox = (data, i) => {
    const { slides } = this.state
    let iImage = Number(i)
    while (slides.length < data.length) {
      if (data[iImage].fileExtension === 'mp4') {
        slides.push(
          {
            type: "video",
            width: 1280,
            height: 720,
            sources: [
              {
                src: data[iImage].fileUrl,
                type: "video/mp4"
              }
            ]
          }
        )
      } else {
        slides.push({ src: data[iImage].fileUrl })
      }
      iImage++
      if (iImage === data.length) {
        iImage = 0
      }
    }
    this.setState({ openLightbox: true, slides })
  }

  handleCloseLightbox = () => {
    this.setState({ openLightbox: false, slides: [] })
  }

  render() {
    const { openLightbox, slides } = this.state
    const initialProps = {
      onSearch: this.onSearch,
      onChangePagination: this.onChangePagination,
      handleFavorite: this.handleFavorite,
      mediaRef: this.mediaRef,
      nextMedia: this.nextMedia,
      previousMedia: this.previousMedia,
      openLightbox: openLightbox,
      handleOpenLightbox: this.handleOpenLightbox,
      handleCloseLightbox: this.handleCloseLightbox,
      slides: slides
    }
    return (
      <CompanyComponent {...initialProps} {...this.props} />
    )
  }
  componentWillUnmount(){
    const { unmountDataCompany, unmountListJob } = this.props
    return (unmountDataCompany(), unmountListJob())
  }
}

const mapStateToProps = (state) => ({
  getCompany: state.companyReducer,
  getJob: state.jobReducer
})

const mapDispatchToProps = {
  actionGetCompany: dataCompany,
  unmountDataCompany: unmountDataCompany,
  actionGetJob: listJobCompany,
  unmountListJob: unmountListJob,
  actionAddOrDeleteFavorite: addOrDeleteFavorite
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CandidateCompany))