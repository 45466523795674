import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography, Space, Image, Grid } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import JobshubLogo from '../../../assets/img/logo/JobshubLogo.svg';
import MapsIcon from '../../../assets/img/MapsIcon.png';
import InstagramIcon from '../../../assets/img/InstagramIcon.png';
import FacebookIcon from '../../../assets/img/FacebookIcon.png';
const { Text } = Typography;
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function FooterAuth() {
  const { pathname } = window.location
  const { md, lg } = GetBreakPoint()
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {
        pathname === '/register' || pathname === '/login' || pathname === '/register/confirm-email' || pathname === '/forgot-password' || pathname === '/forgot-password/verify' || pathname === '/forgot-password/change-password' || pathname === '/privacy-policy' || pathname === '/terms-and-conditions' || pathname === '/login-or-register' || pathname === '/employer/registration' || pathname === '/employer/registration/success' || pathname === '/bicca/register' || pathname === '/jobfair2510/register' || pathname === '/konvensi-nakes/register' ?
          null
        :
          <React.Fragment>
            <Row style={{ paddingBottom: md ? 40 : 24, paddingTop: md ? 80 : 24, backgroundColor: '#f1f3f6' }}>
              <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                <Row gutter={8}>
                  <Col className={!lg ? 'text-align-center' : null} xs={24} lg={9}>
                    <Space direction="vertical" size={0}>
                      <Link to="/">
                        <Image
                          width={120}
                          src={JobshubLogo}
                          preview={false}
                        />
                      </Link>
                      <Space className="mb-16" direction="vertical" size={0}>
                        <Text className="dark-color">Gedung Perkantoran Binawan Lobby 3, Lantai 2</Text>
                        <Text className="dark-color">Jl. Raya Kalibata - Dewi Sartika, No 25 - 30</Text>
                        <Text className="dark-color">DKI Jakarta - 13630</Text>
                      </Space>
                      <Space className="mb-16" size={16}>
                        <a href="https://goo.gl/maps/Gb5UThs8NV3PY6C98" target="_blank" rel="noreferrer">
                          <Image
                            width={'100%'}
                            src={MapsIcon}
                            preview={false}
                          />
                        </a>
                        <a href={"https://www.instagram.com/jobshub_id"} target="_blank" rel="noreferrer">
                          <Image
                            width={'100%'}
                            src={InstagramIcon}
                            preview={false}
                          />
                        </a>
                        <a href="https://www.facebook.com/Jobshubdotid/" target="_blank" rel="noreferrer">
                          <Image
                            width={'100%'}
                            src={FacebookIcon}
                            preview={false}
                          />
                        </a>
                      </Space>
                    </Space>
                  </Col>
                  {
                    md ?
                      <React.Fragment>
                        <Col xs={12} lg={5}>
                          <Space direction="vertical" size={16}>
                            <Text className="fs-16 primary-color">{t('footer.productServices')}</Text>
                            <Space direction="vertical" size={4}>
                              <Link className="dark-color" to="/job">{t('footer.findJob')}</Link>
                              <Link className="dark-color" to="/employer">{t('footer.hireNow')}</Link>
                            </Space>
                          </Space>
                        </Col>

                        <Col xs={12} lg={5}>
                          <Space direction="vertical" size={16}>
                            <Text className="fs-16 primary-color">{t('footer.other')}</Text>
                            {
                              pathname === '/employer' ?
                                <Space direction="vertical" size={4}>
                                  <Link className="dark-color" to="/privacy-policy">{t('footer.privacyPolicy')}</Link>
                                </Space>
                              :
                                <Space direction="vertical" size={4}>
                                  <Link className="dark-color" to="/about-us">{t('footer.aboutUs')}</Link>
                                  <Link className="dark-color" to="/terms-and-conditions">{t('footer.termsAndCondition')}</Link>
                                  <Link className="dark-color" to="/privacy-policy">{t('footer.privacyPolicy')}</Link>
                                  <Link className="dark-color" to="/faq">{t('footer.faq')}</Link>
                                </Space>
                            }
                          </Space>
                        </Col>

                        <Col xs={12} lg={5}>
                          <Space direction="vertical" size={16}>
                            <Text className="fs-16 primary-color">{t('footer.information')}</Text>
                            <Space direction="vertical" size={4}>
                              <Space direction="vertical" size={-4}>
                                <Text className="dark-color">{t('footer.for')}</Text>
                                <Text className="dark-color">{t('footer.email')}</Text>
                              </Space>
                              <a href="mailto:support@jobshub.id?subject=What is your problem?"><MailOutlined /> support@jobshub.id</a>
                            </Space>
                          </Space>
                        </Col>
                      </React.Fragment>
                    :
                      <React.Fragment>
                        <Col xs={1}></Col>
                        <Col xs={11}>
                          <Space direction="vertical" size={6}>
                            <Text className="fs-16 primary-color">{t('footer.productServices')}</Text>
                            <Space direction="vertical" size={4}>
                              <Link className="dark-color" to="/job">{t('footer.findJob')}</Link>
                              <Link className="dark-color" to="/employer">{t('footer.hireNow')}</Link>
                            </Space>
                          </Space>
                        </Col>

                        <Col xs={11}>
                          <Space direction="vertical" size={6}>
                            <Text className="fs-16 primary-color">{t('footer.other')}</Text>
                            <Space direction="vertical" size={4}>
                              <Link className="dark-color" to="/about-us">{t('footer.aboutUs')}</Link>
                              <Link className="dark-color" to="/terms-and-conditions">{t('footer.termsAndCondition')}</Link>
                              <Link className="dark-color" to="/privacy-policy">{t('footer.privacyPolicy')}</Link>
                              <Link className="dark-color" to="/faq">{t('footer.faq')}</Link>
                            </Space>
                          </Space>
                        </Col>
                        <Col xs={1}></Col>

                        <Col xs={1}></Col>
                        <Col xs={22}>
                          <Space direction="vertical" size={6}>
                            <Text className="fs-16 primary-color">{t('footer.information')}</Text>
                            <Space direction="vertical" size={4}>
                              <Space direction="vertical" size={-4}>
                                <Text className="dark-color">{t('footer.for')} {t('footer.email')}</Text>
                              </Space>
                              <a href="mailto:support@jobshub.id?subject=What is your problem?"><MailOutlined /> support@jobshub.id</a>
                            </Space>
                          </Space>
                        </Col>
                        <Col xs={1}></Col>

                      </React.Fragment>
                  }
                </Row>
              </Col>
            </Row>
            <Row style={{ height: 40 }}>
              <Col span={6} style={{ background: '#4173b9' }}></Col>
              <Col span={12} style={{ background: '#32ab6d', textAlign: 'center', paddingTop: 10 }}>
                <Text style={{ color: '#ffffff', fontSize: md ? 14 : 10 }}>© {moment().format('YYYY')} Jobshub. All Rights Reserved.</Text>
              </Col>
              <Col span={6} style={{ background: '#32ab6d' }}></Col>
            </Row>
          </React.Fragment>
      }
    </React.Fragment>
  )
}