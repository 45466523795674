import React from 'react';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import { Grid } from 'antd';
import JobListDesktop from './Desktop';
import JobListMobile from './Mobile';
import './Job.css';
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function JobList(initialProps) {
  const { lg } = GetBreakPoint()
  
  if(process.env.REACT_APP_ENV === "production"){
    ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Job Opening');
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Jobshub - Job Opening</title>
      </Helmet>
      {
        lg ?
          <JobListDesktop {...initialProps} />
        :
          <JobListMobile {...initialProps} />
      }
    </React.Fragment>
  )
}