const LOAD_DATA_MASTER_JOB_INDUSTRIES         = 'LOAD_DATA_MASTER_JOB_INDUSTRIES'
const LOAD_DATA_MASTER_JOB_INDUSTRIES_SUCCESS = 'LOAD_DATA_MASTER_JOB_INDUSTRIES_SUCCESS'
const LOAD_DATA_MASTER_JOB_INDUSTRIES_FAILED  = 'LOAD_DATA_MASTER_JOB_INDUSTRIES_FAILED'
const UNMOUNT_DATA_MASTER_JOB_INDUSTRIES      = 'UNMOUNT_DATA_MASTER_JOB_INDUSTRIES'

const initialState = {
  loading: true,
  meta: null,
  data: null,
  message: null
}

const jobIndustriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DATA_MASTER_JOB_INDUSTRIES:
      return {
        ...state,
        loading: true,
        meta: null,
        data: null
      }
    case LOAD_DATA_MASTER_JOB_INDUSTRIES_SUCCESS:
      return {
        ...state, 
        loading: false,
        meta: action.payload.meta,
        data: action.payload.data,
        message: 'SUCCESS'
      }
    case LOAD_DATA_MASTER_JOB_INDUSTRIES_FAILED:
      return {
        ...state, 
        loading: false,
        meta: null,
        data: null,
        message: 'FAILED'
      }
    case UNMOUNT_DATA_MASTER_JOB_INDUSTRIES:
      return { 
        ...state, 
        loading: true,
        data: null
      }
    default:
      return state
  }
}
export default jobIndustriesReducer