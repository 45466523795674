import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { withTranslation } from 'react-i18next';
import { Typography, Modal, Form, Space, Row, Col, Select, Spin, message } from 'antd';
import { EnvironmentOutlined, EditFilled } from '@ant-design/icons';
import { listCity, unmountListCity } from '../../../../../redux/actions/master/city/cityAction';
import { updateCity } from '../../../../../redux/actions/profile/headerAction';
import Cookie from "js-cookie";
import CButton from '../../../../../components/Button';

const { Text } = Typography;
const { Option } = Select;

export class Location extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      visible: false,
      metaCity: {
        page: 1,
        perpage: 10,
        search: ''
      }
    }
    this.onSearchCity = debounce(this.onSearchCity.bind(this), 800)
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      isActivation: false,
      newEmail: null
    })
  };

  onSearchCity = value => {
    const { metaCity } = this.state;
    const { actionListCity } = this.props;

    metaCity.page = 1
    metaCity.perpage = 10
    metaCity.search = value

    return actionListCity(metaCity)
  }

  onFocusCity = () => {
    const { metaCity } = this.state;
    const { actionListCity } = this.props;
    return actionListCity(metaCity)
  }

  handleCity = (value, option) => {
    const optionSelected = Number(option.key)
    this.setState({
      cityId: optionSelected
    })
  }

  onFinish = async (values) => {
    const { cityId } = this.state

    values.currentCityId = cityId
    
    const { actionUpdateCity, actionGetProfile } = this.props
    
    return this.setState({ submitLoading: true }, () => {
      return actionUpdateCity(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            Cookie.remove('activeElement')
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const { submitLoading, visible } = this.state
    const { t, data, getCity } = this.props
    
    return (
      <React.Fragment>
        {
          data.currentCityName ? 
            <Space>
              <EnvironmentOutlined className="fs-12 dark-color" />
              <Text className="fs-12 dark-color cursor-pointer">{`${data.currentCityName}, ${data.currentCountryName}`}</Text>
              <EditFilled className="fs-12 dark-color cursor-pointer" onClick={this.showModal} />
            </Space>
          :
            <Space>
              <EnvironmentOutlined className="fs-12 dark-color" />
              <Text className="fs-12 dark-color cursor-pointer" underline onClick={this.showModal}>{t('profile.header.formLocation.add')}</Text>
            </Space>
        }

        <Modal 
          title={t('profile.header.formLocation.title')} 
          visible={visible} 
          onCancel={this.handleCancel}
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          width={600}
          destroyOnClose
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
            initialValues={{
              city: data.currentCityName ? `${data.currentCityName}, ${data.currentCountryName}` : null
            }}
          >
            <Row>
              <Col span={24}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">{t('profile.header.formLocation.city')}</Text>
                </Space>
                <Form.Item name="city">
                  <Select 
                    className="width-100" 
                    size="small" 
                    showSearch
                    onFocus={this.onFocusCity}
                    onSearch={this.onSearchCity}
                    onChange={this.handleCity}
                    placeholder={t('profile.header.formLocation.placeholderCity')}
                    notFoundContent={getCity.loading ? <Spin size="small" /> : null}
                  >
                    {
                      getCity?.data?.map(item => 
                        <Option key={item.id} value={`${item.name}, ${item.countryName}`}>{`${item.name}, ${item.countryName}`}</Option>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space className="float-right">
                <CButton type="default" action={this.handleCancel} title={t('profile.buttonBack')}/>
                <CButton htmlType="submit" type="primary" loading={submitLoading} title={t('profile.buttonSave')}/>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountListCity } = this.props
    return unmountListCity()
  }
}

const mapStateToProps = (state) => ({
  getCity: state.cityReducer
})

const mapDispatchToProps = {
  actionListCity: listCity,
  unmountListCity: unmountListCity,
  actionUpdateCity: updateCity
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Location))