import { API } from '../../../config'

export const forgotPassEmail = (value, successCB, failedCB) => async dispatch => {
  API.POST('/forgotPass/email', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const forgotPassCode = (value, successCB, failedCB) => async dispatch => {
  API.POST('/forgotPass/code', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const forgotPassResend = (value, successCB, failedCB) => async dispatch => {
  API.POST_WITH_PARAMS('/forgotPass/resend', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const forgotPass = (value, successCB, failedCB) => async dispatch => {
  API.POST('/forgotPass', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}