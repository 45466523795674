import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Card, Typography, Modal, Select, Space, Form, Row, Col, message } from 'antd';
import { addUniform } from '../../../../redux/actions/profile/uniformAction';
import Cookie from "js-cookie";
import CButton from '../../../../components/Button';

const { Text } = Typography;
const { Option } = Select;

export class Uniform extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      submitLoading: false,
      deleteLoading: false
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false
    })
  };

  onFinish = async (values) => {
    const { actionAddUniform, actionGetProfile } = this.props

    return this.setState({ submitLoading: true }, () => {
      return actionAddUniform(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            Cookie.set("activeElement", window.pageYOffset)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
      })
    })
  }
  
  render() {
    const { visible, submitLoading } = this.state
    const { t, data } = this.props
    return (
      <React.Fragment>
        <Card
          className="border-radius-6 box-shadow" 
          title={t('profile.uniform.title')}
          headStyle={{ color: '#32ab6d', fontSize: 17 }}
          bodyStyle={{ textAlign: 'center' }}
          actions={[
            <Text className="primary-color" onClick={this.showModal}>{t('profile.uniform.button')}</Text>
          ]}
        >
          {
            data.shirt || data.pants || data.shoes ?
              <Space direction="vertical">
                <Space direction="vertical" size={0}>
                  <Text className="fs-12" type="secondary">{t('profile.uniform.shirt')}</Text>
                  <Text className="dark-color" strong>{data.shirt ? data.shirt : '-'}</Text>
                </Space>
                <Space direction="vertical" size={0}>
                  <Text className="fs-12" type="secondary">{t('profile.uniform.pants')}</Text>
                  <Text className="dark-color" strong>{data.pants ? data.pants : '-'}</Text>
                </Space>
                <Space direction="vertical" size={0}>
                  <Text className="fs-12" type="secondary">{t('profile.uniform.shoes')}</Text>
                  <Text className="dark-color" strong>{data.shoes ? data.shoes : '-'}</Text>
                </Space>
              </Space>
            :
              <Text className="dark-color">{t('profile.uniform.textCard')}</Text>
          }
        </Card>

        <Modal 
          title={t('profile.uniform.title')}
          visible={visible} 
          onCancel={this.handleCancel} 
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          centered
          width={600}
          destroyOnClose
          maskClosable={false}
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
            initialValues={{
              shirt: data.shirt,
              pants: data.pants,
              shoes: data.shoes
            }}
          >
            <Row className="mb-min-8">
              <Col span={24}>
                <Text className="fs-12" type="secondary">{t('profile.uniform.shirt')}</Text>
                <Form.Item name="shirt">
                  <Select>
                    <Option value="XS">XS</Option>
                    <Option value="S">S</Option>
                    <Option value="M">M</Option>
                    <Option value="L">L</Option>
                    <Option value="XL">XL</Option>
                    <Option value="XXL">XXL</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8">
              <Col span={24}>
                <Text className="fs-12" type="secondary">{t('profile.uniform.pants')}</Text>
                <Form.Item name="pants">
                  <Select>
                    <Option value="25">25</Option>
                    <Option value="26">26</Option>
                    <Option value="27">27</Option>
                    <Option value="28">28</Option>
                    <Option value="29">29</Option>
                    <Option value="30">30</Option>
                    <Option value="31">31</Option>
                    <Option value="32">32</Option>
                    <Option value="33">33</Option>
                    <Option value="34">34</Option>
                    <Option value="35">35</Option>
                    <Option value="36">36</Option>
                    <Option value="37">37</Option>
                    <Option value="38">38</Option>
                    <Option value="39">39</Option>
                    <Option value="40">40</Option>
                    <Option value="41">41</Option>
                    <Option value="42">42</Option>
                    <Option value="43">43</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col span={24}>
                <Text className="fs-12" type="secondary">{t('profile.uniform.shoes')}</Text>
                <Form.Item name="shoes">
                  <Select>
                    <Option value="34">34</Option>
                    <Option value="34.5">34.5</Option>
                    <Option value="35">35</Option>
                    <Option value="35.5">35.5</Option>
                    <Option value="36">36</Option>
                    <Option value="36.5">36.5</Option>
                    <Option value="37">37</Option>
                    <Option value="37.5">37.5</Option>
                    <Option value="38">38</Option>
                    <Option value="38.5">38.5</Option>
                    <Option value="39">39</Option>
                    <Option value="39.5">39.5</Option>
                    <Option value="40">40</Option>
                    <Option value="40.5">40.5</Option>
                    <Option value="41">41</Option>
                    <Option value="41.5">41.5</Option>
                    <Option value="42">42</Option>
                    <Option value="42.5">42.5</Option>
                    <Option value="43">43</Option>
                    <Option value="43.5">43.5</Option>
                    <Option value="44">44</Option>
                    <Option value="44.5">44.5</Option>
                    <Option value="45">45</Option>
                    <Option value="46">46</Option>
                    <Option value="47">47</Option>
                    <Option value="48">48</Option>
                    <Option value="49">49</Option>
                    <Option value="50">50</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space className="float-right">
                <CButton type="default" action={this.handleCancel} title={t('profile.buttonBack')}/>
                <CButton htmlType="submit" type="primary" loading={submitLoading} title={t('profile.buttonUpdate')}/>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  actionAddUniform: addUniform
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Uniform))