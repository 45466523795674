import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography, Space, Grid, Button } from 'antd'

const { useBreakpoint } = Grid;
const { Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

function GetTranslation() {
  const t = useTranslation()
  return t;
}

export default function register() {
  const { md, xxl } = GetBreakPoint()
  const { t } = GetTranslation()

  return (
    <React.Fragment>
      <Row style={md ? { paddingTop: 32, paddingBottom: 32, backgroundColor: '#ffffff' } : { paddingTop: 16, paddingBottom: 8, backgroundColor: '#ffffff' }}>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Row className="text-align-center" justify="space-around" align="middle">
            <Col span={24}>
              <Space direction="vertical" size={xxl ? 32 : md ? 16 : 4} style={{ width: xxl ? '70%' : '100%' }}>
                <Text style={{ lineHeight: 1.4 }}>
                  <Text style={{ fontSize: xxl ? 36 : md ? 28 : 24, fontWeight: 'bold', color: '#32ab6d' }}>{t('pageEmployer.register.title')} </Text>
                  <Text style={{ fontSize: xxl ? 36 : md ? 28 : 24, fontWeight: 'bold', color: '#4273b9' }}>{t('pageEmployer.register.title2')}</Text>
                </Text>
                <Text style={{ fontSize: xxl ? 22 : 18, fontWeight: 'normal', color: '#535353' }}>{t('pageEmployer.register.subTitle')}</Text>
                {
                  md ?
                    <Link to="/employer/registration">
                      <Button type="primary" shape="round" size="large" style={{  width: xxl ? '30%' : '25%', marginTop: 32, fontSize: 25, fontWeight: 500, height: 60, boxShadow: '0px 4px 6px #cccccc' }}>{t('pageEmployer.register.button')}</Button>
                    </Link>
                  :
                    <Link to="/employer/registration">
                      <Button type="primary" shape="round" size="large" style={{ width: '75%', marginTop: 8, boxShadow: '0px 4px 6px #cccccc' }}>{t('pageEmployer.register.button')}</Button>
                    </Link>
                }
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}
