import React from 'react';
import ReactGA from 'react-ga'
import { Helmet } from "react-helmet";
import { Row, Col, Typography, Space, Image, Divider, Grid, Collapse } from 'antd'
import Img1 from '../../../assets/img/about-us/1.png';
import Img2 from '../../../assets/img/about-us/2.png';
import Img3 from '../../../assets/img/about-us/3.png';
import Img4 from '../../../assets/img/about-us/4.png';
import Img5 from '../../../assets/img/about-us/5.png';

const { useBreakpoint } = Grid;
const { Text } = Typography
const { Panel } = Collapse;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function candidateAboutUs(props) {
  window.scrollTo(0, 0);
  
  if(process.env.REACT_APP_ENV === "production"){
    ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - About Us');
  }

  const { xs } = GetBreakPoint()

  return (
    <React.Fragment>
      <Helmet>
        <title>Jobshub - About Us</title>
      </Helmet>
      <Row style={ xs ? { paddingTop: 8, paddingBottom: 32, backgroundColor: '#fff' } : { paddingTop: 32, paddingBottom: 32, backgroundColor: '#fff' } }>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          {
            xs ?
              <Collapse accordion bordered={false} defaultActiveKey={['1']} style={{ background: '#fff' }}>
                <Panel 
                  header={
                    <Text>
                        <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Mengapa </Text>
                        <Text className="primary-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Jobshub </Text>
                        <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>?</Text>
                    </Text>
                  } 
                  key="1"
                >
                  <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'normal', lineHeight: 1.2 }}>Jobshub membantu kamu untuk memahami dirimu dan memberdayakan potensi yang kamu miliki agar mendapatkan pekerjaan yang sesuai.</Text>
                </Panel>
                <Panel 
                  header={
                    <Text>
                      <Text className="primary-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Jobshub </Text>
                      <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Menampung Ambisi</Text>
                    </Text>
                  } 
                  key="2"
                >
                  <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'normal', lineHeight: 1.2 }}>Jobshub percaya ambisi setiap orang perlu untuk disuarakan. Jobshub membantu mewujudkannya melalui fitur My Pathway yang kami miliki.</Text>
                </Panel>
                <Panel 
                  header={
                    <Text>
                      <Text className="primary-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Jobshub </Text>
                      <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Berjiwa Muda</Text>
                    </Text>
                  } 
                  key="3"
                >
                  <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'normal', lineHeight: 1.2 }}>Jobshub diinisiasi oleh sekelompok anak muda yang memiliki pemikiran positif, kreatif, semangat dan kerja keras.</Text>
                </Panel>
              </Collapse>
            :
              <Row gutter={[64, 32]}>
                <Col xs={24} md={8}>
                  <Space direction="vertical" size={0}>
                    <Text>
                      <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Mengapa </Text>
                      <Text className="primary-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Jobshub </Text>
                      <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>?</Text>
                    </Text>
                    <Divider style={ xs ? { marginTop: 8, marginBottom: 8 } : {}} />
                    <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'normal', lineHeight: 1.2 }}>Jobshub membantu kamu untuk memahami dirimu dan memberdayakan potensi yang kamu miliki agar mendapatkan pekerjaan yang sesuai.</Text>
                  </Space>
                </Col>
                <Col xs={24} md={8}>
                  <Space direction="vertical" size={0}>
                    <Text>
                      <Text className="primary-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Jobshub </Text>
                      <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Menampung Ambisi</Text>
                    </Text>
                    <Divider style={ xs ? { marginTop: 8, marginBottom: 8 } : {}} />
                    <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'normal', lineHeight: 1.2 }}>Jobshub percaya ambisi setiap orang perlu untuk disuarakan. Jobshub membantu mewujudkannya melalui fitur My Pathway yang kami miliki.</Text>
                  </Space>
                </Col>
                <Col xs={24} md={8}>
                  <Space direction="vertical" size={0}>
                    <Text>
                      <Text className="primary-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Jobshub </Text>
                      <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Berjiwa Muda</Text>
                    </Text>
                    <Divider style={ xs ? { marginTop: 8, marginBottom: 8 } : {}} />
                    <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'normal', lineHeight: 1.2 }}>Jobshub diinisiasi oleh sekelompok anak muda yang memiliki pemikiran positif, kreatif, semangat dan kerja keras.</Text>
                  </Space>
                </Col>
              </Row>
          }
        </Col>
      </Row>
      <Row style={{ paddingTop: 32, paddingBottom: 32, backgroundColor: '#32ab6d' }}>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Row>
            <Col span={24}>
              <Space direction="vertical" size={ 32 }>
                <Text style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2, color: '#fff' }}>Jobshub bukan sekadar portal pencari kerja.</Text>
                <Space direction="vertical" size={16}>
                  <Text style={{ fontSize: xs ? 18 : 20, fontWeight: 'normal', lineHeight: 1.2, color: '#fff' }}>Jobshub menawarkan solusi untuk menyelesaikan permasalahan pencari kerja sampai tuntas. Kami mengakomodasi kebutuhan pencari kerja, yang dimulai dari memberikan lowongan kerja hingga memberikan ruang bagi pencari kerja untuk meningkatkan kualitas diri.</Text>
                  <Text style={{ fontSize: xs ? 18 : 20, fontWeight: 'normal', lineHeight: 1.2, color: '#fff' }}>Jobshub berusaha memahami impian karir setiap orang dan menggambarkannya melalui fitur My Pathway yang dimiliki. Jobshub juga memiliki fitur online course, blog, event dan komunitas, guna mendukung potensi anak muda Indonesia untuk meningkatkan keahlian agar memiliki kualitas global dan siap mewujudkan pekerjaan impiannya di dalam dan luar negeri.</Text>
                </Space>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={ xs ? { paddingTop: 8, paddingBottom: 8, backgroundColor: '#fff' } : { paddingTop: 16, paddingBottom: 16, backgroundColor: '#fff' } }>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          {
            xs ?
              <Row justify="space-around" align="middle">
                <Col className="text-align-center" xs={24} md={12}>
                  <Image
                    width={'100%'}
                    src={Img1}
                    preview={false}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <Space direction="vertical" size={8}>
                    <Text>
                      <Text className="primary-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>My </Text>
                      <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Pathway</Text>
                    </Text>
                    <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'normal', lineHeight: 1.2 }}>Kamu dapat melakukan penilaian mandiri untuk mengetahui tahapan apa saja yang perlu kamu lalui untuk meraih karir impianmu. Setelah kamu mengenal dirimu lebih dalam, Jobshub memberikan peluang karir domestik maupun internasional.</Text>
                  </Space>
                </Col>
              </Row>
            :
              <Row gutter={64} justify="space-around" align="middle">
                <Col xs={24} md={12}>
                  <Space direction="vertical" size={32}>
                    <Text>
                      <Text className="primary-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>My </Text>
                      <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Pathway</Text>
                    </Text>
                    <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'normal', lineHeight: 1.2 }}>Kamu dapat melakukan penilaian mandiri untuk mengetahui tahapan apa saja yang perlu kamu lalui untuk meraih karir impianmu. Setelah kamu mengenal dirimu lebih dalam, Jobshub memberikan peluang karir domestik maupun internasional.</Text>
                  </Space>
                </Col>
                <Col className="text-align-center" xs={24} md={12}>
                  <Image
                    width={'50%'}
                    src={Img1}
                    preview={false}
                  />
                </Col>
              </Row>
          }
        </Col>
      </Row>
      <Row style={ xs ? { paddingTop: 8, paddingBottom: 8, backgroundColor: '#fff' } : { paddingTop: 16, paddingBottom: 16, backgroundColor: '#fff' } }>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          {
            xs ?
              <Row justify="space-around" align="middle">
                <Col className="text-align-center" xs={24} md={12}>
                  <Image
                    width={'100%'}
                    src={Img2}
                    preview={false}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <Space direction="vertical" size={8}>
                    <Text>
                      <Text className="primary-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Events</Text>
                      <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Hub</Text>
                    </Text>
                    <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'normal', lineHeight: 1.2 }}>Jobshub bersama para mitra berkomitmen untuk mengadakan kegiatan yang memperkaya pengetahuanmu di bidang yang kamu tekuni.</Text>
                  </Space>
                </Col>
              </Row>
            :
              <Row gutter={64} justify="space-around" align="middle">
                <Col className="text-align-center" xs={24} md={12}>
                  <Image
                    width={'50%'}
                    src={Img2}
                    preview={false}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <Space direction="vertical" size={32}>
                    <Text>
                      <Text className="primary-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Events</Text>
                      <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Hub</Text>
                    </Text>
                    <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'normal', lineHeight: 1.2 }}>Jobshub bersama para mitra berkomitmen untuk mengadakan kegiatan yang memperkaya pengetahuanmu di bidang yang kamu tekuni.</Text>
                  </Space>
                </Col>
              </Row>
          }
        </Col>
      </Row>
      <Row style={ xs ? { paddingTop: 8, paddingBottom: 8, backgroundColor: '#fff' } : { paddingTop: 16, paddingBottom: 16, backgroundColor: '#fff' } }>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          {
            xs ?
              <Row gutter={[64, 32]} justify="space-around" align="middle">
                <Col className="text-align-center" xs={24} md={12}>
                  <Image
                    width={'100%'}
                    src={Img3}
                    preview={false}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <Space direction="vertical" size={8}>
                    <Text>
                      <Text className="primary-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Courses</Text>
                      <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Hub</Text>
                    </Text>
                    <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'normal', lineHeight: 1.2 }}>Jobshub bekerja sama dengan mitra terpercaya untuk menyediakan materi pembelajaran yang variatif agar kamu bisa menambah wawasan, meningkatkan keterampilan diri dan tentunya mendapatkan sertifikat yang kredibel.</Text>
                  </Space>
                </Col>
              </Row>
            :
            <Row gutter={64} justify="space-around" align="middle">
              <Col xs={24} md={12}>
                <Space direction="vertical" size={32}>
                  <Text>
                    <Text className="primary-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Courses</Text>
                    <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Hub</Text>
                  </Text>
                  <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'normal', lineHeight: 1.2 }}>Jobshub bekerja sama dengan mitra terpercaya untuk menyediakan materi pembelajaran yang variatif agar kamu bisa menambah wawasan, meningkatkan keterampilan diri dan tentunya mendapatkan sertifikat yang kredibel.</Text>
                </Space>
              </Col>
              <Col className="text-align-center" xs={24} md={12}>
                <Image
                  width={'50%'}
                  src={Img3}
                  preview={false}
                />
              </Col>
            </Row>
          }
        </Col>
      </Row>
      <Row style={ xs ? { paddingTop: 8, paddingBottom: 8, backgroundColor: '#fff' } : { paddingTop: 16, paddingBottom: 16, backgroundColor: '#fff' } }>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          {
            xs ?
            <Row gutter={[64, 32]} justify="space-around" align="middle">
              <Col className="text-align-center" xs={24} md={12}>
                <Image
                  width={'100%'}
                  src={Img4}
                  preview={false}
                />
              </Col>
              <Col xs={24} md={12}>
                <Space direction="vertical" size={8}>
                  <Text>
                    <Text className="primary-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>People</Text>
                    <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Hub</Text>
                  </Text>
                  <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'normal', lineHeight: 1.2 }}>Manusia membutuhkan grup sosial yang memiliki kesamaan dalam minat, hobi atau bidang karir untuk saling berinteraksi dan memberi dukungan. Jobshub menjawab kebutuhan itu dengan memberikan akses untukmu meningkatkan kualitas diri dan memiliki kesempatan untuk bertemu dengan mentor atau ahli di bidangnya.</Text>
                </Space>
              </Col>
            </Row>
            :
              <Row gutter={64} justify="space-around" align="middle">
                <Col className="text-align-center" xs={24} md={12}>
                  <Image
                    width={'50%'}
                    src={Img4}
                    preview={false}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <Space direction="vertical" size={32}>
                    <Text>
                      <Text className="primary-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>People</Text>
                      <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Hub</Text>
                    </Text>
                    <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'normal', lineHeight: 1.2 }}>Manusia membutuhkan grup sosial yang memiliki kesamaan dalam minat, hobi atau bidang karir untuk saling berinteraksi dan memberi dukungan. Jobshub menjawab kebutuhan itu dengan memberikan akses untukmu meningkatkan kualitas diri dan memiliki kesempatan untuk bertemu dengan mentor atau ahli di bidangnya.</Text>
                  </Space>
                </Col>
              </Row>
          }
        </Col>
      </Row>
      <Row style={ xs ? { paddingTop: 8, paddingBottom: 32, backgroundColor: '#fff' } : { paddingTop: 32, paddingBottom: 32, backgroundColor: '#fff' } }>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          {
            xs ?
              <Row justify="space-around" align="middle">
                <Col className="text-align-center" xs={24} md={12}>
                  <Image
                    width={'100%'}
                    src={Img5}
                    preview={false}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <Space direction="vertical" size={8}>
                    <Text>
                      <Text className="primary-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Articles</Text>
                      <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Hub</Text>
                    </Text>
                    <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'normal', lineHeight: 1.2 }}>Jobshub ingin kamu selalu mendapatkan informasi terkini seputar dunia kerja maupun kiat untuk bisa sukses di bidang yang kamu geluti. Artikel yang kami sajikan mampu memenuhi rasa haus akan ilmu yang kamu rasakan.</Text>
                  </Space>
                </Col>
              </Row>
            :
              <Row gutter={64} justify="space-around" align="middle">
                <Col xs={24} md={12}>
                  <Space direction="vertical" size={32}>
                    <Text>
                      <Text className="primary-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Articles</Text>
                      <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>Hub</Text>
                    </Text>
                    <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'normal', lineHeight: 1.2 }}>Jobshub ingin kamu selalu mendapatkan informasi terkini seputar dunia kerja maupun kiat untuk bisa sukses di bidang yang kamu geluti. Artikel yang kami sajikan mampu memenuhi rasa haus akan ilmu yang kamu rasakan.</Text>
                  </Space>
                </Col>
                <Col className="text-align-center" xs={24} md={12}>
                  <Image
                    width={'50%'}
                    src={Img5}
                    preview={false}
                  />
                </Col>
              </Row>
          }
        </Col>
      </Row>
    </React.Fragment>
  )
}