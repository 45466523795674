import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookie from "js-cookie";
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { message } from 'antd';
import { Loading } from '../../../components';
import { indexProfile, unmountIndexProfile } from '../../../redux/actions/profile/profileAction';
import { dataPathway, unmountDataPathway, addFeedback, updateFeedback } from '../../../redux/actions/careerPathway/careerPathwayAction';
import Banner from './banner';
// import Content from './content';
import NewContent from './NewContent';

export class index extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      isActive: 'biografi',
      isFeedback: false,
      submitLoading: false,
      isActiveProgram: '0',
      visible: false,
      visibleResult: false,
      visibleEnglish: false,
      visibleScore: false,
      visibleMini: false,
      visibleScoreMini: false
    }
  }

  componentDidMount() {
    const { actionGetProfile, actionGetPathway } = this.props
    localStorage.removeItem('isTest')
    localStorage.removeItem('isTestMiniEnglish')
    localStorage.removeItem("questionListening")
    localStorage.removeItem("questionStructure")
    localStorage.removeItem("questionWrittenExp")
    localStorage.removeItem("questionReadingCompre")
    return (actionGetProfile(), actionGetPathway())
  }
  
  showModalResult = () => {
    this.setState({ visibleResult: true })
  }

  showModalScore = () => {
    this.setState({ visibleScore: true })
  }

  showModalScoreMini = () => {
    this.setState({ visibleScoreMini: true })
  }

  handleButton = value => {
    this.setState({ isActive: value })
  }

  handleButtonFeedback = () => {
    this.setState({ isFeedback: true })
  }

  handleClickMenu = e => {
    if(e.key){
      this.setState({ isActiveProgram: e.key })
    }else{
      this.setState({ isActiveProgram: e })
    }
  }

  onFinish = values => {
    const { actionAddFeedback, actionUpdateFeedback, actionGetPathway, getPathway } = this.props
    if(getPathway.data.feedback){
      values.id = getPathway.data.feedbackId
      return this.setState({ submitLoading: true }, () => {
        return actionUpdateFeedback(values, response => {
          if(response.code === '1000'){
            this.setState({ submitLoading: false, isFeedback: false })
            return actionGetPathway()
          }else{
            message.error(response.message)
          }
        }, (err) => {
          return this.setState({ submitLoading: false }, () => message.error(err.message))
        })
      })
    }else{
      return this.setState({ submitLoading: true }, () => {
        return actionAddFeedback(values, response => {
          if(response.code === '1000'){
            this.setState({ submitLoading: false, isFeedback: false })
            return actionGetPathway()
          }else{
            message.error(response.message)
          }
        }, (err) => {
          return this.setState({ submitLoading: false }, () => message.error(err.message))
        })
      })
    }
  }

  showModal = () => {
    this.setState({ visible: true })
  }

  showModalEnglish = () => {
    this.setState({
      visibleEnglish: true
    })
    localStorage.removeItem("end_date")
    Cookie.remove('isSection')
    Cookie.remove('isPart')
    Cookie.remove('result')
  }

  showModalMini = () => {
    this.setState({
      visibleMini: true
    })
    localStorage.removeItem("end_date");
    localStorage.removeItem('questionListening')
    Cookie.remove('isSection')
    Cookie.remove('isPart')
    Cookie.remove('result')
  }


  showModalScore = () => {
    this.setState({ visibleScore: true })
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      visibleEnglish: false,
      visibleResult: false,
      visibleScore: false,
      visibleScoreMini: false,
      visibleMini: false
    })
  }

  render() {
    const { getProfile, getPathway } = this.props
    const { isActive, isFeedback, submitLoading, isActiveProgram, visible, visibleResult, visibleEnglish, visibleScore, visibleMini, visibleScoreMini } = this.state

    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Career Pathway');
    }

    if(getProfile.loading || getPathway.loading){
      return <Loading />
    }

    const initialProps = {
      isActive: isActive,
      isFeedback: isFeedback,
      handleButtonFeedback: this.handleButtonFeedback,
      onFinish: this.onFinish,
      submitLoading: submitLoading,
      handleClickMenu: this.handleClickMenu,
      isActiveProgram: isActiveProgram,
      showModal : this.showModal,
      visible: visible,
      showModalResult : this.showModalResult,
      visibleResult: visibleResult,
      showModalEnglish : this.showModalEnglish,
      visibleEnglish: visibleEnglish,
      handleCancel : this.handleCancel,
      showModalScore: this.showModalScore,
      visibleScore: visibleScore,
      showModalMini: this.showModalMini,
      visibleMini: visibleMini,
      showModalScoreMini : this.showModalScoreMini,
      visibleScoreMini : visibleScoreMini
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Career Pathway</title>
        </Helmet>
        <Banner />
        {/* <Content {...this.props} {...initialProps} /> */}
        <NewContent {...this.props} {...initialProps} />
    </React.Fragment>

    )
  }
  componentWillUnmount(){
    const { unmountIndexProfile, unmountDataPathway } = this.props
    return (unmountIndexProfile(), unmountDataPathway())
  }
}

const mapStateToProps = (state) => ({
  getProfile: state.profileReducer,
  getPathway: state.careerPathwayReducer
})

const mapDispatchToProps = {
  actionGetProfile: indexProfile,
  unmountIndexProfile: unmountIndexProfile,
  actionGetPathway: dataPathway,
  unmountDataPathway: unmountDataPathway,
  actionAddFeedback: addFeedback,
  actionUpdateFeedback: updateFeedback,
}

export default connect(mapStateToProps, mapDispatchToProps)(index)