import React from 'react'
import { Row, Col, Grid, Space, Typography } from 'antd';
import { withTranslation } from 'react-i18next';
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';
import Background from '../../assets/img/international/world.png'
import MBackground from '../../assets/img/international/m-world.png'

const { useBreakpoint } = Grid;
const { Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export function banner(props) {
  const { xs, md, xxl } = GetBreakPoint()
  const { t } = props
   
  return (
    <React.Fragment>
      <Row style={{ width: '100%', backgroundColor: '#fff' }}>
        <Col span={24}>
          <ParallaxProvider>
            <ParallaxBanner layers={[{ image: md ? Background : MBackground, amount: 0.4 }]} style={{ width: '100%', height: xs ? 420 : xxl ? 680 : 500 , boxShadow: '1px 4px 8px #e6e6e6' }}>
              <Row>
                <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                  <Row>
                    <Col className="width-100" span={24}>
                      {/* <Space direction="vertical" size={-8} style={{ paddingTop: 160 }}> */}
                      {
                        xxl ?
                          <Space direction="vertical" style={{ paddingTop: 220 }}>
                            <Text className="dark-color" style={{ fontSize: 34, fontWeight: 'bold', lineHeight: 1.2 }}>{t('jobInternasional.bannerTitle')}</Text>
                            <Text style={{ fontSize: 38, fontWeight: 'bold', lineHeight: 1.2, color: '#4273b9' }}>{t('jobInternasional.bannerTitle1')}</Text>
                            <Text className="dark-color" style={{ fontSize: 26, fontWeight: 'normal', lineHeight: 1.2 }}>{t('jobInternasional.bannerTitle2')}</Text>
                          </Space>
                        : md ?
                          <Space direction="vertical" style={{ paddingTop: 210 }}>
                            <Text className="dark-color" style={{ fontSize: 28, fontWeight: 'normal', lineHeight: 1.2 }}>{t('jobInternasional.bannerTitle')}</Text>
                            <Text style={{ fontSize: 34, fontWeight: 'bold', lineHeight: 1.2, color: '#4273b9' }}>{t('jobInternasional.bannerTitle1')}</Text>
                            <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'normal', lineHeight: 1.2 }}>{t('jobInternasional.bannerTitle2')}</Text>
                          </Space>
                        :
                          <Space direction="vertical" style={{ paddingTop: 20 }}>
                            <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'normal', lineHeight: 1.2 }}>{t('jobInternasional.bannerTitle')}</Text>
                            <Text style={{ fontSize: 24, fontWeight: 'bold', lineHeight: 1.2, color: '#4273b9' }}>{t('jobInternasional.bannerTitle1')}</Text>
                            <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal', lineHeight: 1.2 }}>{t('jobInternasional.bannerTitle2')}</Text>
                          </Space>
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </ParallaxBanner>
          </ParallaxProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withTranslation()(banner)