import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loading } from '../../components'
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { indexCountry } from '../../redux/actions/master/country/countryAction';
import { setRegister } from '../../redux/actions/register/registerAction';
import { checkEmail, checkMobile } from '../../redux/actions/check/checkAction';
// import { Form, Input, Button, Row, Col, Card, Typography, Select, Checkbox, Divider, Tooltip, message } from 'antd';
import { Form, Input, Button, Row, Col, Card, Typography, Select, Checkbox, Tooltip, message } from 'antd';
// import { GoogleOutlined, FacebookFilled, InfoCircleTwoTone } from '@ant-design/icons';
import { InfoCircleTwoTone } from '@ant-design/icons';
import './RefRegister.css';

const { Option } = Select;
const { Text } = Typography;


export class RefRegister extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      idCallingCode: 99,
      callingCode: 62,
      agree: false,
      visible: false
    }
  }
  
  componentDidMount() {
    const { actionGetCountry } = this.props

    if(localStorage.getItem('link')){
      localStorage.removeItem('link')
    }
    
    if(localStorage.getItem('visible')){
      localStorage.removeItem('visible')
    }

    return actionGetCountry()
  }

  onFinish = (values) => {
    const { idCallingCode } = this.state
    const { actionSetRegister } = this.props
    const { callingCode } = this.state

    values.callingCodeId = idCallingCode
    values.mobile = `${callingCode}${values.mobile}`
    values.channel = window.location.pathname.split("/")[2]

    return this.setState({ submitLoading: true }, () => {
      return actionSetRegister(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false }, () => {
            localStorage.setItem("email", values.email)
            localStorage.setItem("password", values.password)

            this.props.history.push('/register/confirm-email')
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }
  
  handleClickSocialLogin = (e, name) => {
    e.preventDefault()
    let target = name === 'google' ? `${name}-login` : 'kep-login-facebook'
    document.getElementsByClassName(target)[0].click()
  }
  
  responseGoogle = (response) => {
    console.log(response);
    // if(response !== undefined) handleSocialMediaLogin('google', response.tokenId, response.profileObj.email, response.profileObj.name)
  }
  
  responseFacebook = (response) => {
    console.log(response);
    // if(response !== undefined) handleSocialMediaLogin('facebook', response.accessToken, response.email, response.name)
  }

  onChangeCheck = (e) => {
    this.setState({
      agree: e.target.checked
    })
  }

  onChangeCode = (value, option) => {
    this.setState({
      callingCode: Number(option.text),
      idCallingCode: Number(option.key)
    })
    this.formRef.current.setFieldsValue({
      mobile: null
    })
  }

  onCheckEmail = (e) => {
    const { actionCheckEmail } = this.props
    return actionCheckEmail(e, response => {
      if(response){
        this.formRef.current.setFieldsValue({
          email: null
        })
        message.error('Email already exist')
      }
    })
  }

  onCheckMobile = (e) => {
    const { callingCode } = this.state
    const { actionCheckMobile } = this.props

    this.formRef.current.setFieldsValue({
      mobile: e.replace(/^0+/, '')
    })

    e = `${callingCode}${e.replace(/^0+/, '')}`
    
    return actionCheckMobile(e, response => {
      if(response){
        this.formRef.current.setFieldsValue({
          mobile: null
        })
        message.error('Phone Number already exist')
      }
    })
  }

  onFocusPassword = (value) => {
    this.setState({
      visible: value
    })
  }
  
  render() {
    const { agree, submitLoading, visible } = this.state
    const { getCountry: { loading, data } } = this.props

    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Register');
    }
    
    if(loading){
      return <Loading />
    }

    const prefixSelector = (
      <Form.Item name="code" noStyle>
        <Select className="prefix-selector" onChange={this.onChangeCode} showSearch>
          {
            data?.map(item =>
              <Option text={item.callingCode} key={item.id} value={`(+${item.callingCode}) ${item.name}`}>{`(+${item.callingCode}) ${item.name}`}</Option>
            )
          }
        </Select>
      </Form.Item>
    )

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Register</title>
        </Helmet>
        <Row className="display-flex" align="middle" justify="center">
          <Col>
            <Card className="card-register" bodyStyle={{ padding: 0, display: 'flex' }}>
              <Form 
                ref={this.formRef}
                initialValues={{ 
                  code: '(+62) Indonesia'
                }}
                onFinish={this.onFinish}
              >
                <Row gutter={8}>
                  <Col className="fs-28" xs={24} sm={24} md={24}>
                    <Text type="secondary">Register</Text>
                  </Col>

                  <Col className="fs-13 mb-16" xs={24} sm={24} md={24}>
                    <Text type="secondary">Give us some of your information to get free access to jobshub.</Text>
                  </Col>

                  <Col xs={24} sm={12}>
                    <Form.Item 
                      className="mb-16"
                      name="firstName" 
                      validateFirst
                      rules={[
                        { required: true, message: 'First name is required!' },
                        { pattern: /^[a-zA-Z ]*$/, message: 'Can not use special characters and numbers.' },
                        { min: 3, message: 'First Name must be at least 3 characters long.' },
                        { max: 25, message: 'First Name must not be more than 25 characters long.' },
                      ]}
                    >
                      <Input placeholder="First Name" />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12}>
                    <Form.Item 
                      className="mb-16"
                      name="lastName" 
                      validateFirst
                      rules={[
                        { required: true, message: 'Last name is required!' },
                        { pattern: /^[a-zA-Z ]*$/, message: 'Can not use special characters and numbers.' },
                        { min: 3, message: 'Last Name must be at least 3 characters long.' },
                        { max: 25, message: 'Last Name must not be more than 25 characters long.' },
                      ]}
                    >
                      <Input placeholder="Last Name" />
                    </Form.Item>
                  </Col>
                  
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item 
                      className="mb-16"
                      name="email"
                      validateFirst
                      rules={[
                        { required: true, message: 'Email is required!' },
                        { type: 'email', message: 'Your email address is invalid!' },
                        // Hanya untuk di production jika di staging akan di matikan
                        // { pattern:  /.+@(gmail|yahoo)\.com$/, message: 'Preferred using Gmail or Yahoo' }
                      ]}
                    >
                      <Input type="email" onBlur={(e) => this.onCheckEmail(e.target.value)} placeholder="Valid Email Address" />
                    </Form.Item>
                  </Col>
                  
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item 
                      className="mb-16"
                      name="password"
                      validateFirst
                      hasFeedback
                      rules={[
                        { required: true, message: 'Password is required!' },
                        { pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, message: 'Password does not meet requirements' },
                        { min: 8, message: 'Password must be at least 8 characters long.' },
                      ]}
                    >
                      <Input.Password onFocus={() => this.onFocusPassword(true)} onBlur={() => this.onFocusPassword(false)} placeholder="Password" />
                    </Form.Item>
                  </Col>
                  
                  <Col xs={22} sm={22} md={11}>
                    <Form.Item 
                      className="mb-16"
                      name="confirmPassword" 
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        { required: true, message: 'Confirm Password is required!' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Passwords do not match!'));
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder="Confirm Password" />
                    </Form.Item>
                  </Col>

                  <Col className="pt-4 txt-color pl-0" xs={2} sm={2} md={1}>
                    <Tooltip visible={visible} placement="right" title="Password with at least 8 characters, 1 CAPITAL and 1 number">
                      <InfoCircleTwoTone onMouseLeave={() => this.onFocusPassword(false)} onMouseEnter={() => this.onFocusPassword(true)} twoToneColor="#0076de" />
                    </Tooltip>
                  </Col>
                  
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item 
                      className="mb-16"
                      name="mobile"
                      validateFirst
                      rules={[
                        { required: true, message: 'Phone Number is required!' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },
                        { min: 7, max: 13, message: 'Your mobile number is invalid' },
                        // { validator(rule, value, callback){
                        //     if(idCallingCode === 99)
                        //       if(value.charAt(0) === '0' || value.charAt(0) === '8' || value.charAt(0) === ''){
                        //         callback()
                        //       }else{
                        //         callback('Invalid phone number')
                        //       }
                        //   }
                        // }
                      ]}
                    >
                      <Input onBlur={(e) => this.onCheckMobile(e.target.value)} addonBefore={prefixSelector} placeholder="Type your primary phone number" />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <Form.Item 
                      className="mb-16"
                      name="agree"
                      rules={[
                        { 
                          validator(rule, value, callback){
                            if(!agree){
                              callback('Please check this box to proceed your account')
                            }else{
                              callback()
                            }
                          }
                        }
                      ]}
                    >
                      <Checkbox className="fs-11 txt-color" onChange={this.onChangeCheck} checked={agree}>
                        By creating an account you agree to the 
                        <Link className="info-color" to="/terms-and-conditions" target="_blank"> terms and conditions</Link> and 
                        <Link className="info-color" to="/privacy-policy" target="_blank"> privacy policy </Link>
                        {/* and  */}
                        {/* <Link className="info-color" to="#"> end-user license agreement</Link> */}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item className="mb-8">
                      <Button type="primary" htmlType="submit" className="login-form-button" loading={submitLoading} block>
                        Create Account
                      </Button>
                    </Form.Item>
                  </Col>
                  
                  <Col xs={24} sm={24} md={24}>
                    <Text className="fs-11" type="secondary">Already have an account? <Link className="info-color" to="/login">Go to login</Link> </Text>
                  </Col>
                  
                  {/* <Col xs={24} sm={24} md={24}>
                    <Divider className="fs-11 txt-color mb-16 mt-8" plain>or create an account with:</Divider>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Form.Item className="mb-8">
                      <Button 
                        className="button-google txt-color" 
                        block 
                        onClick={ (e) => this.handleClickSocialLogin(e, "google") }
                      >
                        <GoogleOutlined /> Google
                      </Button>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Form.Item className="mb-8">
                      <Button 
                        className="button-facebook txt-color" 
                        block 
                        onClick={ (e) => this.handleClickSocialLogin(e, "facebook") }
                      >
                        <FacebookFilled /> Facebook
                      </Button>
                    </Form.Item>
                  </Col> */}
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
        <Row className="display-none">
          <Col span={12}>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Sign In With Google"
              onSuccess={this.responseGoogle}
              onFailure={this.responseGoogle}
              cookiePolicy={'single_host_origin'}
              className="google-login social-button"
            />
          </Col>
          <Col span={12}>
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              fields="name,email,picture"
              callback={this.responseFacebook}
              className="facebook-login social-button"
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  getCountry: state.countryReducer
})

const mapDispatchToProps = {
  actionGetCountry: indexCountry,
  actionCheckEmail: checkEmail,
  actionCheckMobile: checkMobile,
  actionSetRegister: setRegister
}

export default connect(mapStateToProps, mapDispatchToProps)(RefRegister)