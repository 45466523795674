const LOAD_CONTACT           = 'LOAD_CONTACT'
const LOAD_CONTACT_SUCCESS   = 'LOAD_CONTACT_SUCCESS'
const LOAD_CONTACT_FAILED    = 'LOAD_CONTACT_FAILED'
const UNMOUNT_CONTACT        = 'UNMOUNT_CONTACT'
const initialState = {
  loading: true,
  data: null,
  message: null,
}
const contactReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_CONTACT:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_CONTACT_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_CONTACT_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_CONTACT:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}
export default contactReducer;