import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import { Loading } from "../../../../../components";
import { withTranslation } from "react-i18next";
import {
  Row,
  Col,
  Card,
  Space,
  Typography,
  Avatar,
  Checkbox,
  Image,
  Radio,
  message,
} from "antd";
import { ArrowLeftOutlined, CheckCircleFilled } from "@ant-design/icons";
import {
  detailTicket,
  unmountDetailTicket,
  buyTicket,
} from "../../../../../redux/actions/xpert/xpertAction";
import { eventType } from "../../../../../utils/constant/eventType";
import { courseLevel } from "../../../../../utils/constant/courseLevel";
import { trainingType } from "../../../../../utils/constant/trainingType";
import { serviceType } from "../../../../../utils/constant/serviceType";
import { paymentOption } from '../../../../../utils/constant/paymentOption';
import CButton from '../../../../../components/Button';

const { Text } = Typography;
// const { Panel } = Collapse;
const getLocalStorageValue = (s) => localStorage.getItem(s);

export class CandidateXpertPaymentMethod extends Component {
  constructor(props) {
    super(props);

    this.state = {
      getXpertDetail: null,
      qty: 0,
      selectedTicket: 0,
      countDown: {
        date: Date.now(),
        delay: 900000,
      },
      wantedDelay: 900000,
      agree: false,
      submitLoading: false,
      visible: false,
      selectedPaymentOptions: null,
      isSelectedBankAccount: null
    };
  }

  componentDidMount() {
    const { wantedDelay } = this.state;
    const {
      actionGetTicketDetail,
      location: {
        state: { getXpertDetail, xpertTicketId },
      },
    } = this.props;


    const savedDate = getLocalStorageValue("end_date");
    if (savedDate != null && !isNaN(savedDate)) {
      const currentTime = Date.now();
      const delta = parseInt(savedDate, 10) - currentTime;

      //Do you reach the end?
      if (delta > wantedDelay) {
        //Yes we clear uour saved end date
        if (localStorage.getItem("end_date").length > 0)
          localStorage.removeItem("end_date");
      } else {
        //No update the end date with the current date
        this.setState({ countDown: { date: currentTime, delay: delta } });
      }
    }

    this.setState({ getXpertDetail });
    window.scrollTo(0, 0);
    actionGetTicketDetail(xpertTicketId, response => 
      this.setState({
        selectedPaymentOptions: response.data.listScheme.length > 0 ? response.data.listScheme[0].paymentOption : null,
        isDpFree: response.data.listScheme.length > 0 ? response.data.listScheme.find(item => item.paymentOption === 'LOAN')?.listSchemeDetail[0].amount === 0 : null
      })
    );
  }

  renderedCountdown = ({ hours, minutes, seconds, completed }) => {
    // const {
    //   match: { params },
    // } = this.props;

    if (completed) {
      // Render a completed state
      // window.location.href = `/candidate/service-plus/detail/${params.categorySlug}/${params.slug}`;
      window.location.href = `/candidate/service-plus/list`;
    } else {
      // Render a countdown
      return (
        <Text
          className="danger-color"
          style={{ fontSize: 16, fontWeight: "normal" }}
        >
          {localStorage.getItem("minutes") && localStorage.getItem("seconds")
            ? `${localStorage.getItem("minutes")}:${localStorage.getItem(
                "seconds"
              )}`
            : `${zeroPad(minutes)}:${zeroPad(seconds)}`}
        </Text>
      );
    }
  };

  handleAgreement = (e) => {
    this.setState({
      agree: e.target.checked,
    });
  };

  handleBack = () => {
    const {
      location: {
        state: { getXpertDetail },
      },
    } = this.props;

    this.props.history.push({
      pathname: "/candidate/service-plus/choose-ticket",
      state: { getXpertDetail: getXpertDetail },
    });
  };

  handlePaymentOption = (e) => {
    const { isDpFree } = this.state
    this.setState({ selectedPaymentOptions: e.target.value })
    if(e.target.value === 'LOAN' && isDpFree){
      this.setState({ isSelectedBankAccount: null })
    }
  };

  handleBankAccount = (val) => {
    this.setState({ isSelectedBankAccount: val })
  }

  onFinish = () => {
    const { agree, selectedPaymentOptions, isSelectedBankAccount, isDpFree } = this.state;
    const { getTicketDetail, actionBuyTicket, t } = this.props;

    if (agree) {
      if (getTicketDetail.data.isFree) {
        const values = {
          xpertId: getTicketDetail.data.xpertId,
          xpertTicketBookingDetail: [
            {
              xpertTicketId: getTicketDetail.data.id,
              qty: 1,
            },
          ],
        };

        return this.setState({ submitLoading: true }, () => {
          return actionBuyTicket(
            values,
            (response) => {
              if (response.code === "1000") {
                setTimeout(() => {
                  this.props.history.push({
                    pathname: `/candidate/service-plus/payment/success`,
                    state: response.data,
                  });
                  this.setState({ submitLoading: false });
                }, 1500);
              } else {
                message.error(response.message);
              }
            },
            (err) => {
              return this.setState({ submitLoading: false }, () =>
                message.error(err.message)
              );
            }
          );
        });
      } else {
        const values = {
          xpertId: getTicketDetail.data.xpertId,
          bankAccountId: isSelectedBankAccount,
          paymentType: "TRANSFER",
          paymentOption: selectedPaymentOptions,
          xpertTicketBookingDetail: [
            {
              xpertTicketId: getTicketDetail.data.id,
              qty: 1,
            },
          ],
        };

        if((isDpFree && selectedPaymentOptions === 'LOAN') || isSelectedBankAccount){
          return this.setState({ submitLoading: true }, () => {
            return actionBuyTicket(
              values,
              (response) => {
                if (response.code === "1000") {
                  setTimeout(() => {
                    this.props.history.push({
                      pathname: (isDpFree && selectedPaymentOptions === 'LOAN') ? '/candidate/service-plus/payment/success' : `/candidate/service-plus/payment`,
                      state: response.data,
                    });
                    this.setState({ submitLoading: false });
                  }, 1500);
                } else {
                  message.error(response.message);
                }
              },
              (err) => {
                return this.setState({ submitLoading: false }, () =>
                  message.error(err.message)
                );
              }
            );
          });
        }else{
          message.error(t("xpert.msgErrorBankAccount"));
        }
      }
    } else {
      message.error(t("xpert.checkbox"));
    }
  };

  render() {
    const { getTicketDetail, t } = this.props;
    const { getXpertDetail, countDown, submitLoading, isSelectedBankAccount, selectedPaymentOptions, isDpFree } = this.state;

    if (getTicketDetail.loading) {
      return <Loading />;
    }

    return (
      <Row className="mt-16">
        <Col md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Card className="border-radius-8 mb-16">
            <Space className="cursor-pointer mb-16 ml-32 mr-32" onClick={this.handleBack}>
              <ArrowLeftOutlined className="info-color fs-20" />
              <Text className="info-color fs-16">{t("xpert.back")}</Text>
            </Space>
            <Card
              className="border-radius-8 box-shadow-light ml-32 mr-32"
              title={
                <Space className="width-100 text-align-center" direction="vertical" size={0}>
                  <Text className="fs-20" style={{ color: "#FFFFFF" }}>{t("xpert.paymentMethod")}</Text>
                  <Text className="fs-20" style={{ color: "#FFFFFF" }}>{getXpertDetail?.title}</Text>
                </Space>
              }
              headStyle={{ borderRadius: '8px 8px 0 0', backgroundColor: '#32AB6D' }}
            >
              <Row className="text-align-center">
                <Col span={8}>
                  <Space direction="vertical text-align-justify">
                    <Text className="dark-color fs-16">
                      {t("xpert.organized")}
                    </Text>
                    <Space>
                      <Avatar
                        shape="square"
                        size={60}
                        src={getXpertDetail?.logoUrl}
                      />
                      <Text className="info-color fs-16">
                        {getXpertDetail?.name}
                      </Text>
                    </Space>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space direction="vertical text-align-justify">
                    <Text className="dark-color fs-16">
                      {t("xpert.date")}
                    </Text>
                    <Space direction="vertical" size={0}>
                      <Text className="dark-color fs-12">
                        {getXpertDetail?.xpertDate}
                      </Text>
                    </Space>
                  </Space>
                </Col>
                <Col span={8}>
                  {getXpertDetail?.xpertType === "EVENT" ? (
                    <Space
                      className="text-align-justify"
                      direction="vertical"
                    >
                      <Text className="dark-color fs-16">
                        {t("xpert.type")}
                      </Text>
                      <Text className="light-color fs-14">
                        {getXpertDetail?.eventType
                          ? eventType.find(
                              (item) =>
                                item.value === getXpertDetail?.eventType
                            )?.name
                          : "N/A"}
                      </Text>
                    </Space>
                  ) : getXpertDetail?.xpertType === "COURSE" ? (
                    <Space
                      className="text-align-justify"
                      direction="vertical"
                    >
                      <Text className="dark-color fs-16">
                        {t("xpert.level")}
                      </Text>
                      <Text className="light-color fs-14">
                        {getXpertDetail?.courseLevel
                          ? courseLevel.find(
                              (item) =>
                                item.value === getXpertDetail?.courseLevel
                            )?.name
                          : "N/A"}
                      </Text>
                    </Space>
                  ) : getXpertDetail?.xpertType === "TRAINING" ? (
                    <Space
                      className="text-align-justify"
                      direction="vertical"
                    >
                      <Text className="dark-color fs-16">
                        {t("xpert.level")}
                      </Text>
                      <Text className="light-color fs-14">
                        {getXpertDetail?.trainingType
                          ? trainingType.find(
                              (item) =>
                                item.value === getXpertDetail?.trainingType
                            )?.name
                          : "N/A"}
                      </Text>
                    </Space>
                  ) : getXpertDetail?.xpertType === "SERVICE" ? (
                    <Space
                      className="text-align-justify"
                      direction="vertical"
                    >
                      <Text className="dark-color fs-16">
                        {t("xpert.level")}
                      </Text>
                      <Text className="light-color fs-12">{getXpertDetail?.xpertType ? serviceType.find(item => item.value === getXpertDetail?.xpertType)?.name === "Service" ? t('xpert.serviceTag') : null  : null}</Text>
                    </Space>
                  ) : null}
                </Col>
              </Row>
            </Card>

            <Row className="mt-32 mb-32 ml-32 mr-32">
              <Col
                className="text-align-center"
                span={3}
                style={{ backgroundColor: "#fee7e8", padding: 16 }}
              >
                <Countdown
                  date={countDown.date + countDown.delay}
                  renderer={this.renderedCountdown}
                  onStart={(delta) => {
                    //Save the end date
                    if (localStorage.getItem("end_date") == null)
                      localStorage.setItem(
                        "end_date",
                        JSON.stringify(countDown.date + countDown.delay)
                      );
                  }}
                  onComplete={() => {
                    if (localStorage.getItem("end_date") != null)
                      localStorage.removeItem("end_date");
                  }}
                />
              </Col>
              <Col
                span={21}
                style={{ border: "2px solid #fee7e8", padding: 16 }}
              >
                <Text
                  className="danger-color"
                  style={{ fontSize: 16, fontWeight: "normal" }}
                >
                  {t("xpert.label")}
                </Text>
              </Col>
            </Row>
            
            {
              isDpFree && selectedPaymentOptions === 'LOAN' ?
                null
              : 
                <Row className="mb-8 ml-32 mr-32">
                  <Col span={24}>
                    <Row gutter={8}>
                      {
                        getTicketDetail.data.listBankAccount.length > 0 ?
                          getTicketDetail.data.listBankAccount.map((item, i) =>
                            <Col key={i} className="bankAccount" span={8}>
                              <Card className="item border-radius-8 mb-16 width-100" bodyStyle={{ padding: 16 }} onClick={() => this.handleBankAccount(item.bankAccountId)} style={item.bankAccountId === isSelectedBankAccount ? { border: '1px solid #32ab6d' } : null}>
                                  <Space className="width-100" direction="vertical" size={0}>
                                    <Row>
                                      <Col span={20}>
                                        <Image
                                          src={item.bankLogoUrl}
                                          preview={false}
                                          width={100}
                                          height={50}
                                          style={{ objectFit: 'contain' }}
                                        />
                                      </Col>
                                      <Col span={4}>
                                        {
                                          item.bankAccountId === isSelectedBankAccount ?
                                            <CheckCircleFilled className="primary-color float-right fs-20" />
                                          : null
                                        }
                                      </Col>
                                    </Row>
                                    <Text>{item.bankAccountName}</Text>
                                    <Text>{item.bankAccountNumber}</Text>
                                  </Space>
                              </Card>
                            </Col>
                          )
                        : null
                      }
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-14 font-weight-bold dark-color">
                      {t("xpert.bankTransfer")}
                      </Text>
                      <Text>{t("xpert.labelPayment")}</Text>
                    </Space>
                  </Col>
                </Row>
            }

            <Space
              className="ml-32 mr-32 width-100"
              direction="vertical"
              size={32}
            >
              {
                !getTicketDetail.data.isFree ?
                  <React.Fragment>
                    <Row gutter={[0,16]}>
                      <Col span={14}>
                        <Text className="dark-color" strong>{t("xpert.paymentOption")}</Text>
                        {
                          getTicketDetail.data.listScheme.length > 0 ?
                            getTicketDetail.data.listScheme.map((item, i) =>
                              <Space key={i} className="width-100 mb-8 pr-64" direction="vertical">
                                <Radio key={i} value={item.paymentOption} onChange={this.handlePaymentOption} checked={item.paymentOption === selectedPaymentOptions}>
                                  {paymentOption.find(res => res.value === item.paymentOption)?.name}
                                </Radio>
                                {
                                  item.paymentOption === selectedPaymentOptions ?
                                    <Row gutter={[0,8]}> 
                                      <Col span={8} style={{ background: '#F2F2F2', paddingTop: 8, paddingBottom: 8 }}></Col>
                                      <Col span={8} style={{ background: '#F2F2F2', paddingTop: 8, paddingBottom: 8 }}><Text strong>{t("xpert.fee")}</Text></Col>
                                      <Col span={8} style={{ background: '#F2F2F2', paddingTop: 8, paddingBottom: 8 }}><Text strong>{t("xpert.dateOfPurchase")}</Text></Col>
                                      {
                                        item.listSchemeDetail.map((res, i) => 
                                          <React.Fragment key={i}>
                                            <Col span={8} style={{ borderBottom: '1px solid #F2F2F2', paddingBottom: 8 }}>{res.subject}</Col>
                                            <Col span={8} style={{ borderBottom: '1px solid #F2F2F2', paddingBottom: 8 }}>Rp {res.amount.toLocaleString()}</Col>
                                            <Col span={8} style={{ borderBottom: '1px solid #F2F2F2', paddingBottom: 8 }}>{t("xpert.before")} {res.dueDate}</Col>
                                          </React.Fragment>
                                        )
                                      }
                                    </Row>
                                  : null
                                }
                              </Space>
                            )
                          : null
                        }
                      </Col>
                      <Col span={14}></Col>
                    </Row>
                    
                    <Space className="width-100 pr-64" direction="vertical">
                      <Text className="fs-14 dark-color float-right">
                        {t("xpert.label2")}
                      </Text>
                      <Checkbox
                        className="fs-14 dark-color float-right"
                        onChange={this.handleAgreement}
                      >
                        {t("xpert.agree")}
                        <Link
                          className="info-color"
                          to="/candidate/service-plus/terms-and-conditions"
                          target="_blank"
                        >
                          {" "}
                          {t("xpert.terms")}{" "}
                        </Link>
                        {t("xpert.label3")}
                      </Checkbox>
                      <CButton
                        className="box-shadow-light text-align-center float-right"
                        type="primary"
                        size="large"
                        loading={submitLoading}
                        action={this.onFinish}
                        style={{
                          border: "#4273b9",
                          background: "#4273b9",
                          width: 200,
                        }}
                        title={t("xpert.payment3")}
                      />
                    </Space>
                  </React.Fragment>
                : null
              }
            </Space>
          </Card>
        </Col>
      </Row>
    );
  }
  componentWillUnmount(){
    const { unmountDetailTicket } = this.props
    return unmountDetailTicket()
  }
}

const mapStateToProps = (state) => ({
  getTicketDetail: state.ticketDetailReducer,
});

const mapDispatchToProps = {
  actionGetTicketDetail: detailTicket,
  unmountDetailTicket: unmountDetailTicket,
  actionBuyTicket: buyTicket,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CandidateXpertPaymentMethod)
);
