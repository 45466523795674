const LOAD_FAVORITE           = 'LOAD_FAVORITE'
const LOAD_FAVORITE_SUCCESS   = 'LOAD_FAVORITE_SUCCESS'
const LOAD_FAVORITE_FAILED    = 'LOAD_FAVORITE_FAILED'
const UNMOUNT_FAVORITE        = 'UNMOUNT_FAVORITE'
const initialState = {
  loading: true,
  meta: null,
  data: null,
  message: null,
}
const favoriteReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_FAVORITE:
      return {
        ...state,
        loading: true,
        meta: null,
        data: null,
      }
    case LOAD_FAVORITE_SUCCESS:
      return { 
        ...state, 
        loading: false,
        meta: action.payload.data.meta,
        data: action.payload.data.data,
        message: 'SUCCESS',
      }
    case LOAD_FAVORITE_FAILED:
      return { 
        ...state, 
        loading: true,
        meta: null,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_FAVORITE:
      return { 
        ...state, 
        loading: true,
        meta: null,
        data: null,
      }
    default:
      return state
  }
}
export default favoriteReducer;