import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Row, Col, Typography, Space, Form, Input, message } from 'antd';
import CButton from '../../../../components/Button';
import Cookie from 'js-cookie';

const { Text } = Typography
export class FormChangePassword extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.i18n.language !== this.state.lang) {
      if(this.formRef.current.getFieldsError()[0]?.errors.length > 0){
        this.updateValidationMessages();
      }
      this.setState({ lang: Cookie.get('i18next') })
    }
  }
  
  updateValidationMessages = () => {
    const { t } = this.props

    this.formRef.current.setFields([
      {
        name: 'currentPassword',
        errors: [t('changePassword.required')]
      },
      {
        name: 'newPassword',
        errors: [t('changePassword.required')] || [t('changePassword.pattern')] || [t('changePassword.min')]
      },
      {
        name: 'retypeNewPassword',
        errors: [t('changePassword.required')] || [t('changePassword.passwordError')]
      }
    ]);
  }

  onFinish = (values) => {
    const { actionUpdatePassword } = this.props;
    this.setState({ submitLoading: true })
    return actionUpdatePassword(values, (response) => {
      this.setState({ submitLoading: false }, () => {
        if(response.code === '1000'){
          message.success("Password updated")
          this.formRef.current.setFieldsValue({
            currentPassword: null,
            newPassword: null,
            retypeNewPassword: null,
          });
        }else{
          message.error(response.message)
        }
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => {
        message.error(err)
      })
    })
  }

  render() {
    const { submitLoading } = this.state
    const { t } = this.props

    return (
      <React.Fragment>
        <Form 
          ref={this.formRef}
          layout="vertical"
          onFinish={this.onFinish}
        >
          <Row className="mb-min-8">
            <Col span={24}>
              <Space size={4}>
                <Text className="fs-12" type="secondary">{t('changePassword.oldPassword')}</Text>
                <Text className="fs-12" type="danger">*</Text>
              </Space>
              <Form.Item 
                name="currentPassword"
                rules={[
                  { required: true, message: t('changePassword.required') },
                ]}
              >
                <Input.Password visibilityToggle={false} size="small" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mb-min-8">
            <Col span={24}>
              <Space size={4}>
                <Text className="fs-12" type="secondary">{t('changePassword.newPassword')}</Text>
                <Text className="fs-12" type="danger">*</Text>
              </Space>
              <Form.Item
                name="newPassword"
                validateFirst
                rules={[
                  { required: true, message: t('changePassword.required') },
                  { pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, message: t('changePassword.pattern') },
                  { min: 8, message: t('changePassword.min') },
                ]}
              >
                <Input.Password size="small" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mb-min-8" gutter={8}>
            <Col span={24}>
              <Space size={4}>
                <Text className="fs-12" type="secondary">{t('changePassword.retypePassword')}</Text>
                <Text className="fs-12" type="danger">*</Text>
              </Space>
              <Form.Item
                name="retypeNewPassword"
                validateFirst
                dependencies={['newPassword']}
                rules={[
                  { required: true, message:  t('changePassword.required')},
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t('changePassword.passwordError')));
                    },
                  }),
                ]}
              >
                <Input.Password size="small" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="text-align-center mt-8">
            <CButton htmlType="submit" type="primary" loading={submitLoading} className="width-160" title={t('changePassword.save')}/>
            {/* <Button className="border-radius-3" htmlType="submit" type="primary" loading={submitLoading} style={{ width: 160 }}>{t('changePassword.save')}</Button> */}
          </Form.Item>
        </Form>
      </React.Fragment>
    )
  }
}

export default withTranslation()(FormChangePassword)
