import React from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Card, Row, Col, Typography, Space, Breadcrumb, Grid } from 'antd'

const { useBreakpoint } = Grid;
const { Text } = Typography

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function termsAndConditions() {
  window.scrollTo(0, 0);
  
  if(process.env.REACT_APP_ENV === "production"){
    ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Terms and Conditions');
  }

  const { xs } = GetBreakPoint()

  return (
    <React.Fragment>
      <Helmet>
        <title>Jobshub - Terms and Conditions</title>
      </Helmet>
      <Row className="mb-16">
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Breadcrumb style={{ marginTop: xs ? 16 : 32, marginBottom: 8 }}>
            <Breadcrumb.Item>
              <Link className="dark-color" to="/">Home page</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="dark-color">Terms and Conditions</Breadcrumb.Item>
          </Breadcrumb>
          <Card style={{ boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.1)', paddingRight: xs ? 0 : 16, paddingLeft: xs ? 0 : 16 }}>
            <Row>
              <Col className={ xs ? "fs-24 text-align-center mb-8" : "fs-28 text-align-center mb-16" } span={24}>
                <Text type="secondary">Terms and Conditions</Text>
              </Col>
              <Space direction="vertical">
                <Space direction="vertical">
                  <Text type="secondary">These Terms govern:</Text>
                  <Space direction="vertical" size={-4}>
                    <Text type="secondary">• 	The use of this Application, and</Text>
                    <Text type="secondary">• 	Any other related agreement or legal relationship with the owner</Text>
                  </Space>
                  <Text type="secondary">The User must read this document carefully.</Text>
                </Space>

                <Space direction="vertical">
                  <Text type="secondary" strong>Terms of Use</Text>
                  <Text type="secondary">
                    Unless otherwise specified, the terms of use detailed in this section apply generally when using this Application.
                  </Text>
                  <Text type="secondary">
                    Single or additional conditions of use or access may apply in specific scenarios and in such cases are additionally indicated within this document.
                  </Text>
                </Space>

                <Space direction="vertical">
                  <Text type="secondary" strong>Account Registrations</Text>
                  <Text type="secondary">
                    To use the Service Users must register or create a user account, providing all required data or information completely and truthfully. 
                    Failure to do so will cause unavailability of the Service.
                  </Text>
                  <Text type="secondary">
                    Users are responsible for keeping their login credentials confidential and safe. For this reason, Users are also required to choose passwords that meet the highest standards of strength permitted by this Application.
                  </Text>
                  <Text type="secondary">
                    Users agree to be fully responsible for all activities that occur under their username and password by registering.
                    Users are required to immediately and unambiguously inform the Owner via the contact details indicated in this document if they think their personal information, including but not limited to user accounts, access credentials, or personal data, has been violated, unduly disclosed, or stolen.
                  </Text>
                </Space>

                <Space direction="vertical">
                  <Text type="secondary" strong>Account Termination</Text>
                  <Text type="secondary">
                    Users can terminate their account and stop using the Service at any time by directly contacting the Owner at the contact details provided in this document.
                  </Text>
                </Space>

                <Space direction="vertical">
                  <Text type="secondary" strong>Account suspension and deletion</Text>
                  <Text type="secondary">
                    The Owner reserves the right, at its sole discretion, to suspend or delete at any time and without notice, User accounts which it deems inappropriate, offensive, or in violation of these Terms.
                  </Text>
                  <Text type="secondary">
                    The suspension or deletion of User accounts shall not entitle Users to any claims for compensation, damages, or reimbursement.
                  </Text>
                </Space>

                <Space direction="vertical">
                  <Text type="secondary" strong>Content on this Application</Text>
                  <Text type="secondary">
                    Unless where otherwise specified or clearly recognizable, all content available on this Application is owned or provided by the Owner or its licensors.
                  </Text>
                  <Text type="secondary">
                    The Owner undertakes its utmost effort to ensure that the content provided on this Application infringes no applicable legal provisions or third-party rights. However, it may not always be possible to achieve such a result.
                  </Text>
                  <Text type="secondary">
                    In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are kindly asked to preferably report related complaints using the contact details provided in this document.
                  </Text>
                </Space>

                <Space direction="vertical">
                  <Text type="secondary" strong>Rights regarding content on this Application – All rights reserved</Text>
                  <Text type="secondary">
                    The Owner holds and reserves all intellectual property rights for any such content.
                  </Text>
                  <Text type="secondary">
                    In particular, but without limitation, Users may not copy, download, share (beyond the limits set forth below), modify, translate, transform, publish, transmit, sell, sublicense, edit, transfer/assign to third parties, or create derivative works from the content available on this Application, nor allow any third party to do so through the User or their device, even without the User's knowledge.
                  </Text>
                  <Text type="secondary">
                    Where explicitly stated on this Application, the User may download, copy and/or share some content available through this Application for its sole personal and non-commercial use and provided that the copyright attributions and all the other attributions requested by the Owner are correctly implemented.
                  </Text>
                  <Text type="secondary">
                    Any applicable statutory limitation or exception to copyright shall stay unaffected.
                  </Text>
                </Space>
              </Space>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}