export const xpertType = [
  {
    "value": "EVENT",
    "name": "Event",
    "nameEn": "Event",
    "nameIn": "Acara",
    "color": "#32ab6d"
  },
  {
    "value": "COURSE",
    "name": "Course",
    "nameEn": "Course",
    "nameIn": "Kelas Kursus",
    "color": "#4272b9"
  },
  {
    "value": "TRAINING",
    "name": "Training & Certification",
    "nameEn": "Training & Certification",
    "nameIn": "Pelatihan & Sertifikasi",
    "color": "#ed9004"
  },
  {
    "value": "SERVICE",
    "name": "Service",
    "nameEn": "Service",
    "nameIn": "Layanan",
    "color": "#D93939"
  }
]