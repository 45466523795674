import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
// import { Row, Col, Typography, Card, Skeleton, Image, Space, Tag, Pagination, Empty } from 'antd';
import { Row, Col, Typography, Card, Skeleton, Space, Tag, Pagination, Avatar, Tooltip, Empty } from 'antd';
import { listOrder, unmountListOrder } from '../../../redux/actions/xpert/xpertAction'
// import { SafetyOutlined, EnvironmentOutlined } from '@ant-design/icons';
import EmptyLogo from '../../../assets/img/EmptyLogo.png';
import MenuApplications from './Menu';
import { Link } from 'react-router-dom';
import { xpertType } from '../../../utils/constant/xpertType';

const { Text } = Typography;

export class ListOrder extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      params: {
        page: 1,
        perpage: 10,
        status: 'WAITING_FOR_PAYMENT'
      },
      visible: false,
      isReschedule: false,
      submitLoading: false,
    }
  }
  
  componentDidMount() {
    const { params } = this.state
    const { actionListOrder, location } = this.props
    const status = location.status ? location.status : params.status
    const newParams = {...params, status: status}
    window.scrollTo(0, 0);
    return actionListOrder(newParams, (res) => {
      this.setState({ params: res })
    })
  }

  handleMenu = e => {
    const { params } = this.state
    const { actionListOrder } = this.props

    const newParams = {...params, status: e}
    
    return actionListOrder(newParams, (res) => {
      this.setState({ params: res })
    })
  }

  handlePagination = (pageNumber) => {
  }

  render() {
    const { params } = this.state
    const { getListOrder, t } = this.props

    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Xpert My Orders');
    }

    const initialProps = {
      handleMenu: this.handleMenu,
      activeKey: params.status
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Xpert My Orders</title>
        </Helmet>
        <Row className="padding-16">
          <Col className="width-100" lg={{ span: 22, offset: 1 }} xxl={{ span: 18, offset: 3 }}>
            <Row gutter={32}>
              <Col className="mb-16" span={24}>
                <Text className="dark-color fs-20">My Orders</Text>
              </Col>
              <Col xs={24} lg={8}>
                <MenuApplications {...this.props} {...initialProps} />
              </Col>
              <Col xs={24} lg={16}>
                <Row className="width-100 mb-8" gutter={8}>
                  <Col span={24}>
                    {
                      getListOrder?.data ?
                        <Text className="dark-color fs-13">{`Showing ${getListOrder?.meta?.total === 0 ? getListOrder?.meta?.total : (getListOrder?.meta?.page*getListOrder?.meta?.perpage)-(getListOrder?.meta?.perpage-1)}-${getListOrder?.meta?.page === getListOrder?.meta?.pages || getListOrder?.meta?.total === 0 ? getListOrder?.meta?.total : getListOrder?.meta?.page*getListOrder?.meta?.perpage} of ${getListOrder?.meta?.total} Orders`}</Text>
                      :
                        <Skeleton.Input active />
                    }
                  </Col>
                </Row>
                <Row className="mb-16">
                  <Col className="width-100 xpert" span={24}>
                    {
                      getListOrder?.loading ?
                        <Card className="border-radius-3 box-shadow mb-6">
                          <Skeleton active avatar />
                        </Card>
                      :
                        getListOrder?.data?.length > 0 ?
                          getListOrder?.data?.map((item, i) => 
                          <Link key={i} to={`my-orders/detail/${item.id}`}>
                            <Card className="item border-radius-3 box-shadow mb-6">
                              <Row className="width-100" align="middle" gutter={8}>
                                <Col lg={17} xxl={20}>
                                  <Space className='width-100' direction="vertical" size={8}>
                                    <Tooltip title={item.xpertName}>
                                      <Text className="fs-16 info-color" ellipsis style={{ width: '90%' }}>{item.xpertName}</Text>
                                    </Tooltip>
                                    <Row>
                                      <Col xs={24} lg={12}>
                                        <Space direction="vertical text-align-justify">
                                          <Text className="fs-12 light-color">{t('myOrders.organized')} :</Text>
                                          <Space>
                                            <Avatar size={50} shape="square" src={item.logoUrl ? item.logoUrl : EmptyLogo} />
                                            <Text className="info-color fs-14">{item.name}</Text>
                                          </Space>
                                        </Space>
                                      </Col>
                                      <Col xs={24} lg={12}>
                                        <Space direction="vertical text-align-justify">
                                          <Text className="fs-12 light-color">{t('myOrders.noOrder')} :</Text>
                                          <Text className="fs-14 dark-color">{item.xpertTransactionNumber}</Text>
                                        </Space>
                                      </Col>
                                    </Row>
                                  </Space>
                                </Col>
                                <Col className="text-align-center" lg={7} xxl={4}>
                                  <Tag className="pt-8 pb-8 fs-11 font-weight-bold text-align-center width-200" color={ item.xpertType === 'SERVICE' ? "red" : item.xpertType === 'EVENT' ? "green" : item.xpertType === 'COURSE' ? "geekblue" : "gold"}>
                                    {xpertType.find(res => res.value === item.xpertType)?.name}
                                  </Tag>
                                </Col>
                              </Row>
                            </Card>
                          </Link>
                          )
                        :
                          <Card className="border-radius-3 box-shadow mb-6">
                            <Empty />
                          </Card>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col className="text-align-center" span={24}>
                    <Pagination onChange={this.handlePagination} current={getListOrder?.meta?.page} pageSize={getListOrder?.meta?.perpage || 10} total={getListOrder?.meta?.total} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountListOrder } = this.props
    return unmountListOrder()
  }
}

const mapStateToProps = (state) => ({
  getListOrder: state.orderReducer
})

const mapDispatchToProps = {
  actionListOrder: listOrder,
  unmountListOrder: unmountListOrder,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ListOrder))