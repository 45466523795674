export const questionWrittenExp = [
  {
    "question": "<p>Nowdays, the idea that <b><i><u>learn</u></i></b> is only for children is obviously not true. It <b><i><u>seems</u></i></b> to me that as <b><i><u>an</u></i></b> adult you can't rely on what you learnt <b><i><u>in</u></i></b> school to get by any longer.<p>",
    "answers": [{
            "value": 0,
            "answerLabel": "an"
        },{
            "value": 0,
            "answerLabel": "in"
        },{
            "value": 0,
            "answerLabel": "seems"
        },{
            "value": 1,
            "answerLabel": "learn"
        }
    ]
},
{
    "question": "<p>Thongsa Juangsang <b><i><u>was</u></i></b> a rice farmer. <b><i><u>Grew</u></i></b> rice needs a lot of water, and when the rain <b><i><u>didn't</u></i></b> come, her crop died. So, she has introduced new crops, growing food that <b><i><u>needs</u></i></b> less water.</p>",
    "answers": [{
            "value": 1,
            "answerLabel": "grew"
        },{
            "value": 0,
            "answerLabel": "was"
        },{
            "value": 0,
            "answerLabel": "didn't"
        },{
            "value": 0,
            "answerLabel": "needs"
        }
    ]
},
{
    "question": "<p>Switzerland <b><i><u>has</u></i></b> very <b><i><u>strict</u></i></b> anti-noise rules, especially <b><i><u>to</u></i></b> people who <b><i><u>live</u></i></b> in flats.</p>",
    "answers": [{
            "value": 0,
            "answerLabel": "has"
        },{
            "value": 0,
            "answerLabel": "strict"
        },{
            "value": 0,
            "answerLabel": "live"
        },{
            "value": 1,
            "answerLabel": "to"
        }
    ]
},
{
    "question": "<p>Arthur Conan Doyle <b><i><u>was</u></i></b> born in Edinburgh <b><i><u>in</u></i></b> 22nd May 1859. He <b><i><u>studied</u></i></b> medicine at Edinburgh University and as a student he <b><i><u>began</u></i></b> writing short stories.</p>",
    "answers": [{
            "value": 0,
            "answerLabel": "was"
        },{
            "value": 0,
            "answerLabel": "studied"
        },{
            "value": 0,
            "answerLabel": "began"
        },{
            "value": 1,
            "answerLabel": "in"
        }
    ]
},
{
    "question": "<p>According <b><i><u>to</u></i></b> international analyst and writer David Rothkopf, the world <b><i><u>in which</u></i></b> we live is <b><i><u>controls</u></i></b> by what he <b><i><u>terms</u></i></b> the 'Superclass'.</p>",
    "answers": [{
            "value": 0,
            "answerLabel": "terms"
        },{
            "value": 0,
            "answerLabel": "to"
        },{
            "value": 0,
            "answerLabel": "in which"
        },{
            "value": 1,
            "answerLabel": "controls"
        }
    ]
},
{
    "question": "<p>There <b><i><u>is</u></i></b> many people who <b><i><u>feel</u></i></b> they have little influence on the world today. <b><i><u>However</u></i></b>, one way in which people can have a positive effect on society is <b><i><u>by</u></i></b> volunteering.</p>",
    "answers": [{
            "value": 1,
            "answerLabel": "is"
        },{
            "value": 0,
            "answerLabel": "feel"
        },{
            "value": 0,
            "answerLabel": "however"
        },{
            "value": 0,
            "answerLabel": "by"
        }
    ]
},
{
    "question": "<p>The Khao San Road <b><i><u>was</u></i></b> a famous traveller spot even <b><i><u>before</u></i></b> Leonardo di Caprio's character <b><i><u>on</u></i></b> the film The Beach <b><i><u>stayed</u></i></b> there. But it's noisy, not very pretty and not very Thai.</p>",
    "answers": [{
            "value": 0,
            "answerLabel": "before"
        },{
            "value": 0,
            "answerLabel": "stayed"
        },{
            "value": 1,
            "answerLabel": "on"
        },{
            "value": 0,
            "answerLabel": "was"
        }
    ]
},
{
    "question": "<p>Oxford Street <b><i><u>is</u></i></b> one of the busiest streets <b><i><u>in</u></i></b> the world <b><i><u>where</u></i></b> two hundred million shoppers <b><i><u>visited</u></i></b> it every year.</p>",
    "answers": [{
            "value": 0,
            "answerLabel": "where"
        },{
            "value": 0,
            "answerLabel": "in"
        },{
            "value": 1,
            "answerLabel": "visited"
        },{
            "value": 0,
            "answerLabel": "is"
        }
    ]
},
{
    "question": "<p>According to scientists, people <b><i><u>tastes</u></i></b> food differently. It depends <b><i><u>on</u></i></b> the number of taste buds on your tongue. If you <b><i><u>have</u></i></b> a large number of taste buds, things taste <b><i><u>stronger</u></i></b>.</p>",
    "answers": [{
            "value": 0,
            "answerLabel": "have"
        },{
            "value": 0,
            "answerLabel": "on"
        },{
            "value": 1,
            "answerLabel": "tastes"
        },{
            "value": 0,
            "answerLabel": "stronger"
        }
    ]
},
{
    "question": "<p>The shop <b><i><u>wasn't</u></i></b> easy <b><i><u>finding</u></i></b>. I nearly <b><i><u>miss</u></i></b> it <b><i><u>on</u></i></b> my way back home.</p>",
    "answers": [{
            "value": 0,
            "answerLabel": "wasn't"
        },{
            "value": 1,
            "answerLabel": "finding"
        },{
            "value": 0,
            "answerLabel": "on"
        },{
            "value": 0,
            "answerLabel": "miss"
        }
    ]
},
// {
//     "question": "<p>The good points <b><i><u>includes</u></i></b> the fact that there <b><i><u>are</u></i></b> lots of up-to-date groups you can <b><i><u>listen</u></i></b> to <b><i><u>such as</u></i></b> The Script or One Direction.</p>",
//     "answers": [{
//             "value": 0,
//             "answerLabel": "listen"
//         },{
//             "value": 1,
//             "answerLabel": "includes"
//         },{
//             "value": 0,
//             "answerLabel": "such as"
//         },{
//             "value": 0,
//             "answerLabel": "are"
//         }
//     ]
// },
// {
//     "question": "<p>I’ve been <b><i><u>writting</u></i></b> my blog from London <b><i><u>every day</u></i></b> during the Olympics and the pics and this <b><i><u>are</u></i></b> my final post to look back <b><i><u>on</u></i></b> a wonderful couple of months.</p>",
//     "answers": [{
//             "value": 1,
//             "answerLabel": "are"
//         },{
//             "value": 0,
//             "answerLabel": "on"
//         },{
//             "value": 0,
//             "answerLabel": "writing"
//         },{
//             "value": 0,
//             "answerLabel": "every day"
//         }
//     ]
// },
// {
//     "question": "<p>I <b><i><u>have</u></i></b> really enjoyed <b><i><u>being</u></i></b> able to watch sports which <b><i><u>are</u></i></b> not normally <b><i><u>showing</u></i></b> on television.</p>",
//     "answers": [{
//             "value": 1,
//             "answerLabel": "showing"
//         },{
//             "value": 0,
//             "answerLabel": "have"
//         },{
//             "value": 0,
//             "answerLabel": "are"
//         },{
//             "value": 0,
//             "answerLabel": "being"
//         }
//     ]
// },
// {
//     "question": "<p>It <b><i><u>took</u></i></b> seven years of planning and 70,000 volunteers to make everything go well. <b><i><u>Much</u></i></b> people have said that the organisation <b><i><u>was</u></i></b> not as perfect as that of the Beijing Games, but there was a much better atmosphere <b><i><u>which</u></i></b> spread out through the whole city.</p>",
//     "answers": [{
//             "value": 0,
//             "answerLabel": "took"
//         },{
//             "value": 0,
//             "answerLabel": "which"
//         },{
//             "value": 1,
//             "answerLabel": "much"
//         },{
//             "value": 0,
//             "answerLabel": "was"
//         }
//     ]
// },
// {
//     "question": "<p>I still get really nervous before a concert and <b><i><u>have to</u></i></b> drink herbal tea! Even today, after having <b><i><u>done</u></i></b> hundreds of concerts, my hands sweat and my heart <b><i><u>beats</u></i></b> really fast just before going <b><i><u>in</u></i></b> stage.</p>",
//     "answers": [{
//             "value": 0,
//             "answerLabel": "done"
//         },{
//             "value": 0,
//             "answerLabel": "have to"
//         },{
//             "value": 1,
//             "answerLabel": "in"
//         },{
//             "value": 0,
//             "answerLabel": "beats"
//         }
//     ]
// },
];
