import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { Typography, Modal, Space, Image, message } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import CButton from '../../../../components/Button';
import FormEditProfile from './Form';
import DetailEditProfile from './Detail';
import ImgProfileUpdated from '../../../../assets/img/profileUpdate.png';
import { checkEmail, checkMobile } from '../../../../redux/actions/check/checkAction';
import { indexCountry, unmountIndexCountry } from '../../../../redux/actions/master/country/countryAction';
import { listCity, unmountListCity } from '../../../../redux/actions/master/city/cityAction';
import { listInstitution, unmountIndexInstitution } from '../../../../redux/actions/master/institution/institutionAction';
import { indexEducationLevel, unmountIndexEducationLevel } from '../../../../redux/actions/master/educationLevel/educationLevelAction';
import { listFieldOfStudy, unmountIndexFieldOfStudy } from '../../../../redux/actions/master/fieldOfStudy/fieldOfStudyAction';
import { indexBuseinessSector, unmountIndexBuseinessSector } from '../../../../redux/actions/master/businessSector/businessSectorAction';
import { indexDocumentType, unmountIndexDocumentType } from '../../../../redux/actions/master/documentTypes/documentTypesAction';
import { pathwayUpdateProfile } from '../../../../redux/actions/careerPathway/careerPathwayAction';
import { indexHeader } from '../../../../redux/actions/header/headerAction';

const { Text } = Typography;

export class CareerPathwayEditProfile extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props)
  
    this.state = {
      isActive: 'form',
      visible: false,
      visibleSuccess: false,
      profilePicFile: null,
      onPreviewAvatar: null,
      callingCode: "62",
      callingCodeId: 99,
      disabledEducation: true,
      metaCity: {
        page: 1,
        perpage: 10,
        search: ''
      },
      location: null,
      isBinawan: false,
      educationLevelName: null,
      educationLevelId: null,
      educationCurrent: false,
      metaInstitution: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaFieldOfStudy: {
        page: 1,
        perpage: 10,
        search: '',
        educationLevelId: ''
      },
      disabledWork: true,
      workCityId: null,
      workLocation: null,
      businessSectorName: null,
      experienceCurrent: false,
      disabledDoc: [true, true],
      documentTypeName: [],
      documentFile: [],
      documentCurrent: [false, false],
      isDocumentDate: false,
      allValues: {},
      submitLoading: false
    }
    this.onSearchCity = debounce(this.onSearchCity.bind(this), 800)
    this.onSearchInstitution = debounce(this.onSearchInstitution.bind(this), 800)
    this.onSearchFieldOfStudy = debounce(this.onSearchFieldOfStudy.bind(this), 800)
  }

  
  componentDidMount(){
    const { actionGetCountry, actionGetEducationLevel, actionGetBusinessSector, actionGetDocumentTypes, getProfile } = this.props
    if(getProfile.data.listEducation.length > 0){
      this.setState({ 
        disabledEducation: false,
        educationLevelId: getProfile.data.listEducation[getProfile.data.listEducation.length-1].educationLevelId,
        educationCurrent: getProfile.data.listEducation[getProfile.data.listEducation.length-1].current
      })
    }
    if(getProfile.data.listWorkExperience.length > 0){
      this.setState({ 
        disabledWork: false,
        workCityId: getProfile.data.listWorkExperience[getProfile.data.listWorkExperience.length-1].cityId,
        workLocation: getProfile.data.listWorkExperience[getProfile.data.listWorkExperience.length-1].countryName ? `${getProfile.data.listWorkExperience[getProfile.data.listWorkExperience.length-1].countryName}, ${getProfile.data.listWorkExperience[getProfile.data.listWorkExperience.length-1].cityName}` : null,
        businessSectorName: getProfile.data.listWorkExperience[getProfile.data.listWorkExperience.length-1].businessSectorName,
        experienceCurrent: getProfile.data.listWorkExperience[getProfile.data.listWorkExperience.length-1].current
      })
    }
    actionGetCountry()
    actionGetEducationLevel()
    actionGetBusinessSector()
    actionGetDocumentTypes()
  }

  showModal = () => {
    this.setState({ visible: true })
  }

  handleCancel = () => {
    this.setState({ 
      visible: false, 
      allValues: {},
      profilePicFile: null,
      onPreviewAvatar: null,
      // disabledEducation: true,
      // location: null,
      // isBinawan: false,
      // educationLevelName: null,
      // educationLevelId: null,
      // educationCurrent: false,
      // disabledWork: true,
      businessSectorName: null,
      experienceCurrent: false,
      disabledDoc: [true, true],
      documentTypeName: [],
      documentFile: [],
      documentCurrent: [false, false],
      isDocumentDate: false,
      visibleSuccess: false 
    })
  }

  // FUNCTION PERSONAL INFO

  handleUploadImage = () => {
    return {
      showUploadList: false,
      withCredentials: true,
      accept:".png,.jpg,.jpeg",
      beforeUpload: file => {
        const validateSize = file.size >= 3000000;
        if (validateSize) {
          message.error('Max file size is 3 MB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({
            profilePicFile: file,
            onPreviewAvatar: e.target.result,
          })
        }
        return false;
      },
    }
  }

  onCheckEmail = (e) => {
    const { t, actionCheckEmail, getProfile } = this.props
    
    if(getProfile?.data?.email !== e){
      return actionCheckEmail(e, response => {
        if(response){
          this.formRef.current.setFieldsValue({
            email: null
          })
          message.error(t('emailExist'))
        }
      })
    }
  }

  onCheckMobileNumber = (e) => {
    const { callingCode } = this.state
    const { t, actionCheckMobile, getProfile: { data } } = this.props

    this.formRef.current.setFieldsValue({
      mobilePhone: e.replace(/^0+/, '')
    })

    e = `${callingCode}${e.replace(/^0+/, '')}`
    
    if(e !== `${data.callingCode}${data.mobilePhone}`){
      return actionCheckMobile(e, response => {
        if(response){
          this.formRef.current.setFieldsValue({
            mobilePhone: null
          })
          message.error(t('mobileNumberExist'))
        }
      })
    }
  }

  onChangeCallingCode = (_, index) => {
    this.setState({ callingCode: index.children.replace(/^[+]+/, '') })
    this.formRef.current.setFieldsValue({
      mobilePhone: null
    });
  }

  onChangeBirthdate = (date, dateString) => {
    this.setState({
      birthdate: dateString ? dateString : null
    })
  }

  onSearchCity = value => {
    const { metaCity } = this.state;
    const { actionListCity } = this.props;

    metaCity.page = 1
    metaCity.perpage = 10
    metaCity.search = value

    return actionListCity(metaCity)
  }

  onFocusCity = () => {
    const { metaCity } = this.state;
    const { actionListCity } = this.props;
    return actionListCity(metaCity)
  }

  onChangeCity = (value, option) => {
    const optionSelected = Number(option.key)
    this.setState({
      cityId: optionSelected,
      location: value
    })
  }

  // FUNCTION EDUCATION

  onSearchInstitution = value => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;

    metaInstitution.page = 1
    metaInstitution.perpage = 10
    metaInstitution.search = value

    return actionListInstitution(metaInstitution)
  }

  onFocusInstitution = () => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;
    return actionListInstitution(metaInstitution)
  }

  onChangeInstitution = (e) => {
    this.setState({ isBinawan: e === 'Universitas Binawan' ? true : false, disabledEducation: e ? false : true })
  }

  onChangeEducationLevel = (value, option) => {
    const optionSelected = Number(option.key)

    this.setState({
      educationLevelName: value,
      educationLevelId: optionSelected
    })

    this.formRef.current.setFieldsValue({
      educationFieldOfStudy: ''
    })
  }

  onSearchFieldOfStudy = value => {
    const { metaFieldOfStudy, educationLevelId } = this.state;
    const { actionListFieldOfStudy } = this.props;

    metaFieldOfStudy.page = 1
    metaFieldOfStudy.perpage = 10
    metaFieldOfStudy.search = value
    metaFieldOfStudy.educationLevelId = educationLevelId

    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  onFocusFieldOfStudy = () => {
    const { metaFieldOfStudy, educationLevelId } = this.state;
    const { actionListFieldOfStudy} = this.props;
    
    metaFieldOfStudy.page = 1
    metaFieldOfStudy.perpage = 10
    metaFieldOfStudy.educationLevelId = educationLevelId

    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  onChangeStartYear = (date, dateString) => {
    const { endYear } = this.state

    this.setState({
      startYear: dateString
    })
    
    if(endYear){
      if(endYear < dateString){
        this.setState({
          isYear: true
        })
      }else{
        this.setState({
          isYear: false
        })
      }
    }
  }

  onChangeChecked = (e) => {
    this.setState({
      educationCurrent: e
    })
    if(e){
      this.setState({
        endYear: null
      })
      this.formRef.current.setFieldsValue({
        endYear: null
      })
    }
  }

  onChangeEndYear = (date, dateString) => {
    const { startYear } = this.state

    this.setState({
      endYear: dateString
    })

    if(dateString < startYear){
      this.setState({
        isYear: true
      })
    }else{
      this.setState({
        isYear: false
      })
    }
  }

  // FUNCTION WORK EXPERIENCE

  onChangeWorkTitle = (e) => {
    this.setState({ disabledWork: e.target.value !== "" ? false : true })
  }

  onChangeWorkCity = (value, option) => {
    const optionSelected = Number(option.key)
    this.setState({
      workCityId: optionSelected,
      workLocation: value
    })
  }

  onChangeBusinessSector = (val, item) => {
    this.setState({ businessSectorName: item.children })
  }

  onChangeExperienceStartDate = (date, dateString) => {
    const { t } = this.props
    const { endDate } = this.state

    this.setState({
      startDate: dateString
    })
    
    if(endDate){
      if(endDate < dateString){
        message.error(t('validationEndDate'), 5)
        this.formRef.current.setFieldsValue({
          endDate: null
        })
        this.setState({
          isDate: true
        })
      }else{
        this.setState({
          isDate: false
        })
      }
    }
  }

  onChangeExperienceEndDate = (date, dateString) => {
    const { t } = this.props
    const { startDate } = this.state

    this.setState({
      endDate: dateString
    })

    if(dateString < startDate){
      message.error(t('validationEndDate'), 5)
      this.formRef.current.setFieldsValue({
        endDate: null
      })
      this.setState({
        isDate: true
      })
    }else{
      this.setState({
        isDate: false
      })
    }
  }

  onChangeExperienceChecked = (e) => {
    this.setState({
      experienceCurrent: e.target.checked
    })
    if(e.target.checked){
      this.setState({
        endYear: null
      })
      this.formRef.current.setFieldsValue({
        endYear: null
      })
    }
  }

  // FUNCTION DOCUMENT

  onChangeDocumentType = (val, item, key) => {
    const { disabledDoc, documentTypeName } = this.state
    disabledDoc[key] = val ? false : true
    documentTypeName[key] = item.children
    this.setState({ disabledDoc, documentTypeName })
  }

  handleUploadDocument = (key) => {
    const { documentFile } = this.state
    return {
      showUploadList: false,
      withCredentials: true,
      accept:".jpg, .jpeg, .pdf, .png",
      beforeUpload: file => {
        const validateSize = file.size >= 3000000;
        if (validateSize) {
          message.error('Max file size is 3 MB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        documentFile[key] = file
        reader.onload = (e) => {
          this.setState({ 
            documentFile
          })
          message.info('Upload Success!');
        }
        return false;
      },
    }
  }

  handleRemoveDocument = (file) => {
    this.setState({ 
      documentFile: null
    })
    message.success(`${file.name} file removed successfully`);
  }

  onChangeDocChecked = (e, key) => {
    const { documentCurrent } = this.state
    documentCurrent[key] = e.target.checked
    this.setState({ documentCurrent })
    
    if(e.target.checked){
      this.setState({
        docEndDate: null
      })

      this.formRef.current.setFieldsValue({
        docEndDate: null
      })
    }
  }

  onChangeDocStartDate = (date, dateString) => {
    const { t } = this.props
    const { docEndDate } = this.state

    this.setState({
      docStartDate: dateString
    })
    
    if(docEndDate){
      if(docEndDate < dateString){
        message.error(t('validationEndDate'), 5)
        this.formRef.current.setFieldsValue({
          docEndDate: null
        })
        this.setState({
          isDocumentDate: true
        })
      }else{
        this.setState({
          isDocumentDate: false
        })
      }
    }
  }

  onChangeDocEndDate = (date, dateString) => {
    const { t } = this.props
    const { docStartDate } = this.state

    this.setState({
      docEndDate: dateString
    })

    if(dateString < docStartDate){
      message.error(t('validationEndDate'), 5)
      this.formRef.current.setFieldsValue({
        docEndDate: null
      })
      this.setState({
        isDocumentDate: true
      })
    }else{
      this.setState({
        isDocumentDate: false
      })
    }
  }

  handleClick = (type) => {
   this.setState({ statusSubmit: type })
  }

  // GENERAL FUNCTION

  handleBackButton = () => {
    this.setState({ isActive: 'form' })
  }

  onFinish = (values) => {
    const { profilePicFile, birthdate, cityId, educationLevelId, educationCurrent, experienceCurrent, workCityId, documentFile, documentCurrent } = this.state
    const { getProfile } = this.props

    Object.keys(values).forEach((key) => {
      if (values[key] === undefined || values[key] === null) {
        delete values[key];
      }
    });

    if(profilePicFile?.uid || getProfile?.data?.profilePicUrl){
      if(profilePicFile?.uid){
        values.profilePicFile = profilePicFile
      }else{
        values.profilePic = getProfile?.data?.profilePic
      }
      values.birthdate = birthdate || getProfile?.data?.birthdate
      values.currentCityId = cityId || getProfile?.data?.currentCityId
      if(getProfile?.data?.listEducation?.length > 0){
        values.candidateEducationId = getProfile?.data?.listEducation[getProfile?.data?.listEducation?.length-1].id
      }
      values.educationLevelId = educationLevelId
      if(values.educationFieldOfStudy === '' || values.educationFieldOfStudy === null || values.educationFieldOfStudy === undefined){
        delete values.educationFieldOfStudy
      }
      if(educationCurrent !== null){
        values.educationCurrent = educationCurrent
      }
      if(values.educationEndYear){
        values.educationEndYear = values.educationEndYear ? moment(values.educationEndYear).format('YYYY') : ''
      }
      if(values.educationStartYear){
        values.educationStartYear = values.educationStartYear ? moment(values.educationStartYear).format('YYYY') : ''
      }
      if(getProfile?.data?.listWorkExperience?.length > 0){
        values.workExperienceId = getProfile?.data?.listWorkExperience[getProfile?.data?.listWorkExperience?.length-1].id
      }
      if(values.workTitle){
        values.workCurrent = experienceCurrent
      }
      if(values.workCityId){
        values.workCityId = workCityId
      }
      if(values.workStartDate){
        values.workStartDate = values.workStartDate ? moment(values.workStartDate).format('YYYY-MM') : ''
      }
      if(values.workEndDate){
        values.workEndDate = values.workEndDate ? moment(values.workEndDate).format('YYYY-MM'): ''
      }
      if(values?.listDocument[0] !== ''){
        values.listDocument.forEach((item,i) => {
          values.listDocument[i].documentCurrent = documentCurrent[i]
          if(values.listDocument[i].documentFile){
            values.listDocument[i].documentFile = documentFile[i]
          }
        })
      }else{
        delete values.listDocument
      }
      this.setState({ allValues: values, isActive: 'detail' })
    }else{
      message.error('Photo tidak boleh kosong')
    }
  }

  onSubmit = () => {
    const { allValues } = this.state
    const { actionUpdateProfile, actionGetHeader } = this.props
    
    return this.setState({ submitLoading: true }, () => {
      return actionUpdateProfile(allValues, response => {
        if(response.code === '1000'){
          actionGetHeader()
          return this.setState({ submitLoading: false, visible: false, visibleSuccess: true })
        }else{
          message.error(response.message)
          return this.setState({ submitLoading: false })
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }

  handleDone = () => {
    const { actionGetProfile } = this.props
    this.setState({ visibleSuccess: false })
    return actionGetProfile()
  }

  render() {
    const { t } = this.props
    const { visible, isActive, visibleSuccess } = this.state

    const initialProps = {
      formRef: this.formRef,
      state: {...this.state},
      // FUNCTION PERSONAL INFO
      handleUploadImage: this.handleUploadImage,
      onCheckEmail: this.onCheckEmail,
      onCheckMobileNumber: this.onCheckMobileNumber,
      onChangeCallingCode: this.onChangeCallingCode,
      onChangeBirthdate: this.onChangeBirthdate,
      onSearchCity: this.onSearchCity,
      onFocusCity: this.onFocusCity,
      onChangeCity: this.onChangeCity,
      // FUNCTION EDUCATION
      onSearchInstitution: this.onSearchInstitution,
      onFocusInstitution: this.onFocusInstitution,
      onChangeInstitution: this.onChangeInstitution,
      onChangeEducationLevel: this.onChangeEducationLevel,
      onSearchFieldOfStudy: this.onSearchFieldOfStudy,
      onFocusFieldOfStudy: this.onFocusFieldOfStudy,
      onChangeStartYear: this.onChangeStartYear,
      onChangeChecked: this.onChangeChecked,
      onChangeEndYear: this.onChangeEndYear,
      // FUNCTION WORK EXPERIENCE
      onChangeWorkTitle: this.onChangeWorkTitle,
      onChangeWorkCity: this.onChangeWorkCity,
      onChangeBusinessSector: this.onChangeBusinessSector,
      onChangeExperienceStartDate: this.onChangeExperienceStartDate,
      onChangeExperienceEndDate: this.onChangeExperienceEndDate,
      onChangeExperienceChecked: this.onChangeExperienceChecked,
      // FUNCTION DOCUMENT
      onChangeDocumentType: this.onChangeDocumentType,
      handleUploadDocument: this.handleUploadDocument,
      handleRemoveDocument: this.handleRemoveDocument,
      onChangeDocChecked: this.onChangeDocChecked,
      onChangeDocStartDate: this.onChangeDocStartDate,
      onChangeDocEndDate: this.onChangeDocEndDate,
      handleClick: this.handleClick,
      // GENERAL
      handleBackButton: this.handleBackButton,
      onFinish: this.onFinish,
      onSubmit: this.onSubmit
    }

    return (
      <React.Fragment>
        <Text className="primary-color cursor-pointer fs-16" onClick={this.showModal}>{t('pathway.completeProfile')} <RightOutlined /></Text>
        <Modal
          centered
          width={700}
          visible={visible}
          onCancel={this.handleCancel}
          footer={false}
          title={
            <Space className="text-align-center width-100" direction="vertical" size={0}>
              <Text className="fs-15 primary-color">Edit Profile</Text>
            </Space>
          }
          destroyOnClose
        >
          {
            isActive === 'form' ?
              <FormEditProfile {...this.props} {...initialProps} />
            : isActive === 'detail' ? 
              <DetailEditProfile {...this.props} {...initialProps} />
            : null
          }
        </Modal>
        <Modal
          centered
          className="modal-border-rad-6"
          title={false}
          footer={false}
          closable={false}
          visible={visibleSuccess}
          maskClosable={false}
          bodyStyle={{ padding: 32 }}
        >
          <Space className='width-100 text-align-center' direction='vertical' size={32}>
            <Space className='text-align-center' direction='vertical' size={8}>
              <Text className='fs-28' strong>Selamat</Text>
              <Text className='fs-18 dark-color' strong>Profile anda berhasil diperbarui</Text>
            </Space>
            <Image 
              preview={false}
              src={ImgProfileUpdated}
              style={{ objectFit: 'contain' }}
            />
            <Space className='width-100 text-align-center' direction='vertical'>
              <CButton className="btn-save-primary width-50'" type="primary" action={this.handleDone} title="Selesai"/>
              <Link to='/candidate/profile'>
                <CButton className="btn-save-primary" type="link-outline" title="Ke Halaman Profile"/>
              </Link>
            </Space>
          </Space>
        </Modal>
      </React.Fragment>
    )
  }

  componentWillUnmount(){
    const { unmountIndexCountry, unmountListCity, unmountIndexInstitution, unmountIndexEducationLevel, unmountIndexFieldOfStudy, unmountIndexBuseinessSector, unmountIndexDocumentType } = this.props
    return (
      unmountIndexCountry(), 
      unmountListCity(),
      unmountIndexInstitution(),
      unmountIndexEducationLevel(),
      unmountIndexFieldOfStudy(),
      unmountIndexBuseinessSector(),
      unmountIndexDocumentType()
    )
  }
}

const mapStateToProps = (state) => ({
  getCountry: state.countryReducer,
  getCity: state.cityReducer,
  getInstitution: state.institutionReducer,
  getEducationLevel: state.educationLevelReducer,
  getFieldOfStudy: state.fieldOfStudyReducer,
  getBusinessSector: state.businessSectorReducer,
  getDocumentTypes: state.documentTypeReducer
})

const mapDispatchToProps = {
  actionUpdateProfile: pathwayUpdateProfile,
  actionCheckEmail: checkEmail,
  actionCheckMobile: checkMobile,
  actionGetCountry: indexCountry,
  actionListCity: listCity,
  actionListInstitution: listInstitution,
  actionGetEducationLevel: indexEducationLevel,
  actionListFieldOfStudy: listFieldOfStudy,
  actionGetBusinessSector: indexBuseinessSector,
  actionGetDocumentTypes: indexDocumentType,
  actionGetHeader: indexHeader,
  unmountIndexCountry: unmountIndexCountry,
  unmountListCity: unmountListCity,
  unmountIndexInstitution: unmountIndexInstitution,
  unmountIndexEducationLevel: unmountIndexEducationLevel,
  unmountIndexFieldOfStudy: unmountIndexFieldOfStudy,
  unmountIndexBuseinessSector: unmountIndexBuseinessSector,
  unmountIndexDocumentType: unmountIndexDocumentType
}

export default connect(mapStateToProps, mapDispatchToProps)(CareerPathwayEditProfile)