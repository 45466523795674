const LOAD_INTERVIEW           = 'LOAD_INTERVIEW'
const LOAD_INTERVIEW_SUCCESS   = 'LOAD_INTERVIEW_SUCCESS'
const LOAD_INTERVIEW_FAILED    = 'LOAD_INTERVIEW_FAILED'
const UNMOUNT_INTERVIEW        = 'UNMOUNT_INTERVIEW'
const initialState = {
  loading: true,
  data: null,
  message: null,
}
const interviewReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_INTERVIEW:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_INTERVIEW_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_INTERVIEW_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_INTERVIEW:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}
export default interviewReducer;