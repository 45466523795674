import { API } from '../../../config'

export const addEducation = (value, successCB, failedCB) => async dispatch => {
  API.POST('/candidate/education/add', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const updateEducation = (value, successCB, failedCB) => async dispatch => {
  API.POST('/candidate/education/update', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const deleteEducation = (value, successCB, failedCB) => async dispatch => {
  const params = { 
    id: value
  }
  API.POST_WITH_PARAMS('/candidate/education/delete', params).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}