import React from 'react'
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Row, Col, Grid, Space, Typography, Image, Tabs, Modal } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import Bakat from '../../../../assets/img/candidate/bakat.png'
import BasicEnglish from '../../../../assets/img/candidate/basic-english.png'
import MiniEnglish from '../../../../assets/img/candidate/mini-english.png'
import Time from '../../../../assets/img/candidate/time.png'
import Question from '../../../../assets/img/candidate/question.png';
import Score from '../../../../assets/img/InfoScore.png';
import EditProfile from '../EditProfile'
import ResultInterest from '../Modal/resultInterest';
import CButton from '../../../..//components/Button';

const { Text } = Typography;
const { useBreakpoint } = Grid;


function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export function newContent(props) {
  const { xs, xxl } = GetBreakPoint()
  const { t, getProfile, getPathway, visible, visibleResult, showModalResult, handleCancel, visibleEnglish, showModalEnglish, showModalScore, visibleScore, visibleMini, showModalMini, visibleScoreMini, showModalScoreMini } = props

  const isActiveAssessment = getProfile.data.profilePicUrl && getProfile.data.birthdate && getProfile.data.placeOfBirth && getProfile.data.marital && getProfile.data.currentCityName && getProfile.data.listEducation.length > 0

  const handleStartBasic = () => {
    localStorage.setItem('isTest', true)
    props.history.push(`/candidate/career-pathway/question/basic-english`)
  }

  const handleStartMini = () => {
    localStorage.setItem('isTestMiniEnglish', true)
    props.history.push(`/candidate/career-pathway/question/mini-english`)
  }

  return (
    <React.Fragment>
      <Row>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
        {
          xxl ?
            <React.Fragment>
              <Space className="text-align-center width-100 mt-32" direction="vertical">
                <Text className="dark-color" style={{ fontSize: 30, fontWeight: 'normal', lineHeight: 1.2 }}>{t('pathway.title')}</Text>
              </Space>
              <Space className="width-100 mt-16" direction="vertical">
                <Text className="info-color" style={{ fontSize: 24, fontWeight: 'normal' }}>{t('pathway.title1')}</Text>
                <Space direction="vertical" size={0}>
                  <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'normal' }}>{getProfile.data.firstName.charAt(0).toUpperCase() + getProfile.data.firstName.slice(1)}, {t('pathway.title2')}</Text>
                  <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'normal' }}>{t('pathway.title3')}</Text>
                  <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'normal' }}>{t('pathway.title4')}</Text>
                </Space>
              </Space>
            </React.Fragment>
          : xs ?
            <React.Fragment>
              <Space className="width-100 mt-16" direction="vertical">
                <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'normal', lineHeight: 1.2 }}>{t('pathway.title')}</Text>
              </Space>
              <Space className="width-100 mt-16" direction="vertical">
                <Text className="info-color" style={{ fontSize: 18, fontWeight: 'normal' }}>{t('pathway.title1')}</Text>
                <Space direction="vertical" size={0}>
                  <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal' }}>{getProfile.data.firstName.charAt(0).toUpperCase() + getProfile.data.firstName.slice(1)}, {t('pathway.title2')}</Text>
                  <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal' }}>{t('pathway.title3')}</Text>
                  <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal' }}>{t('pathway.title4')}</Text>
                </Space>
              </Space>
            </React.Fragment>
          :
            <React.Fragment>
              <Space className="text-align-center width-100 mt-16" direction="vertical">
                <Text className="dark-color" style={{ fontSize: 28, fontWeight: 'normal', lineHeight: 1.2 }}>{t('pathway.title')}</Text>
              </Space>
              <Space className="width-100 mt-16" direction="vertical">
                <Text className="info-color" style={{ fontSize: 22, fontWeight: 'normal' }}>{t('pathway.title1')}</Text>
                <Space direction="vertical" size={0}>
                  <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal' }}>{getProfile.data.firstName.charAt(0).toUpperCase() + getProfile.data.firstName.slice(1)}, {t('pathway.title2')}</Text>
                  <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal' }}>{t('pathway.title3')}</Text>
                  <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal' }}>{t('pathway.title4')}</Text>
                </Space>
              </Space>
            </React.Fragment>
        }

          <Row className='mt-16 mb-16'>
            <Col span={24}>
              <div className="blue-tabs">
                <Tabs type="card" tabBarStyle={{ borderBottom: '2px solid #D9D9D9' }}>
                  <Tabs.TabPane tab={t('pathway.interest')} key="item-1">
                    <Row className="mt-16" justify="space-around" align="middle">
                      <Col className="mb-16" xs={24} md={8}>
                        <Image
                          width={'100%'}
                          height={250}
                          src={Bakat}
                          preview={false}
                          style={{ objectFit: 'contain' }}
                        />
                      </Col>
                      <Col xs={24} md={16}>
                        <Space direction="vertical" size={16}>
                          <Text className="info-color" style={{ fontSize: 20, fontWeight: '600', lineHeight: 1.2 }}>
                            {t('pathway.interest')}
                          </Text>
                          <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal' }}>
                            {t('pathway.titleInterest')}
                          </Text>
                          {
                            getPathway.data.status === 'WAITING' ?
                              <Space direction='vertical'>
                                <Text className="info-color" style={{ fontSize: 16, fontWeight: 'normal' }}>{t('pathway.titleEducation')}</Text>
                                <Text className="info-color" style={{ fontSize: 16, fontWeight: 'normal' }}>{t('pathway.subTitleEducation')}</Text>
                              </Space>
                            : getPathway.data.status === 'SUBMIT' ?
                              <Text className='primary-color cursor-pointer' onClick={showModalResult} style={{ fontSize: 16, fontWeight: 'normal'}}>{t('pathway.result')} <RightOutlined /></Text>
                            : isActiveAssessment ?
                              // <Button className={xs ? "border-radius-6 mt-16" : "border-radius-6"} type="primary" size="large" disabled={isActiveAssessment ? false : true} onClick={showModal}>{t('pathway.startAssessment')}</Button>
                              <Link to={`/candidate/career-pathway/question`}>
                                <CButton className={xs ? "" : ""} type="primary" size="large" disabled={isActiveAssessment ? false : true} title={t('pathway.startAssessment')}/>
                              </Link> 
                            :
                              <React.Fragment>
                                <Text className="danger-color font-style-italic fs-16">{t('pathway.titleEducation2')}</Text>
                                <EditProfile {...props} />
                              </React.Fragment>
                          }
                        </Space>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="English Assessment" key="item-2">
                    <Row className="mt-16" justify="space-around" align="middle">
                      <Col className="mb-16" xs={24} md={8}>
                        <Image
                          width={'100%'}
                          height={250}
                          src={BasicEnglish}
                          preview={false}
                          style={{ objectFit: 'contain' }}
                        />
                      </Col>
                      <Col xs={24} md={16}>
                        <Space direction="vertical" size={16}>
                          <Text className="info-color" style={{ fontSize: 20, fontWeight: '600', lineHeight: 1.2 }}>
                            {/* {t('pathway.basic')} */}
                            English Assessment
                          </Text>
                          <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal' }}>
                            {t('pathway.titleBasic')}
                          </Text>
                          {
                            getPathway.data.basicEnglishScore ?
                              <Text className='primary-color cursor-pointer' onClick={showModalScore} style={{ fontSize: 16, fontWeight: 'normal'}}>{t('pathway.result')} <RightOutlined /></Text>
                            : isActiveAssessment ?
                              <CButton className={xs ? "mt-16" : ""} type="primary" size="large" disabled={isActiveAssessment ? false : true} action={showModalEnglish} title={t('pathway.startAssessment')}/>
                            :
                              <React.Fragment>
                                <Text className="danger-color font-style-italic fs-16">{t('pathway.titleEducation2')}</Text>
                                <EditProfile {...props} />
                              </React.Fragment>
                          }
                        </Space>
                      </Col>
                    </Row>
                  </Tabs.TabPane>

                  <Tabs.TabPane tab="Mini English Test" key="item-3">
                    <Row className="mt-16" justify="space-around" align="middle">
                      <Col className="mb-16" xs={24} md={8}>
                        <Image
                          width={'100%'}
                          height={250}
                          src={MiniEnglish}
                          preview={false}
                          style={{ objectFit: 'contain' }}
                        />
                      </Col>
                      <Col xs={24} md={16}>
                        <Space direction="vertical" size={16}>
                          <Text className="info-color" style={{ fontSize: 20, fontWeight: '600', lineHeight: 1.2 }}>
                            {/* {t('pathway.titleMiniEnglish')} */}
                            Mini English Test
                          </Text>
                          <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal' }}>
                            {t('pathway.miniEnglish')}
                          </Text>
                          {
                            getPathway.data.miniEnglishScore ?
                              <Text className='primary-color cursor-pointer' onClick={showModalScoreMini} style={{ fontSize: 16, fontWeight: 'normal'}}>{t('pathway.result')} <RightOutlined /></Text>
                            : isActiveAssessment  ?
                              <CButton className={xs ? "mt-16" : ""} type="primary" size="large" disabled={isActiveAssessment ? false : true} action={showModalMini} title={t('pathway.startAssessment')}/>
                            :
                              <React.Fragment>
                                <Text className="danger-color font-style-italic fs-16">{t('pathway.titleEducation2')}</Text>
                                <EditProfile {...props} />
                              </React.Fragment>
                          }
                        </Space>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={false}
        title={
          <Space className="text-align-center width-100" direction="vertical" size={0}>
            <Text className="fs-20 primary-color">{t('pathway.instructions')} </Text>
            <Text className="fs-15 dark-color">
              {/* {t('pathway.interest')} */}
              English Assessment
            </Text>
          </Space>
        }
        bodyStyle={{ padding: 48 }}
      >
        <Row>
          <Col className='mb-32' span={12}>
            <Row>
              <Col className="text-align-center" span={24}>
                <Space direction="vertical">
                  <Image
                    width={'100%'}
                    height={50}
                    src={Question}
                    preview={false}
                    style={{ objectFit: 'contain' }}
                  />
                  <Text className="info-color" style={{ fontSize: 14, fontWeight: 'normal' }}>{t('pathway.question')}</Text>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col className='mb-32' span={12}>
            <Row>
              <Col className="text-align-center" span={24}>
                <Space direction="vertical">
                  <Image
                    width={'100%'}
                    height={50}
                    src={Time}
                    preview={false}
                    style={{ objectFit: 'contain' }}
                  />
                  <Text className="info-color" style={{ fontSize: 14, fontWeight: 'normal' }}>{t('pathway.minutes')}</Text>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col className='mb-32' span={24}>
            <Text style={{ fontSize: 15, fontWeight: 'normal' }}>
              {t('pathway.modalInterest')}
            </Text>
          </Col>
          <Col span={6} offset={9}>
            {/* <Link to={`/candidate/career-pathway/question`}> */}
              <CButton type="primary" size="large" block title={t('pathway.start')}/>
            {/* </Link> */}
          </Col>
        </Row>
      </Modal>
      <Modal
        centered
        title={false}
        footer={false}
        visible={visibleResult}
        onCancel={handleCancel}
        width={1000}
      >
        <ResultInterest {...props} />
      </Modal>
      {/* Modal Basic English Test */}
      <Modal
        visible={visibleEnglish}
        onCancel={handleCancel}
        footer={false}
        title={
          <Space className="text-align-center width-100" direction="vertical" size={0}>
            <Text className="fs-20 primary-color">{t('pathway.instructions')} </Text>
            <Text className="fs-15 dark-color">English Assessment</Text>
          </Space>
        }
        bodyStyle={{ padding: 48 }}
      >
        <Row>
          <Col span={24}>
            <Text type="secondary" style={{ fontSize: 15, fontWeight: 'normal', fontStyle: 'italic' }}>This test consists of 3 sections with a total of </Text>
            <Text style={{ fontSize: 15, fontWeight: 'normal', color: "#32AB6D", fontStyle: 'italic' }}> 65 questions </Text>
            <Text type="secondary" style={{ fontSize: 15, fontWeight: 'normal', fontStyle: 'italic' }}>which must be finished within </Text>
            <Text style={{ fontSize: 15, fontWeight: 'normal', color: "#FF0000", fontStyle: 'italic' }}>60 minutes. </Text>
            <Text type="secondary" style={{ fontSize: 15, fontWeight: 'normal', fontStyle: 'italic' }}>Make sure that you have read and follow the instructions.</Text>
          </Col>
          <Col span={24} style={{paddingTop: 20}}>
            <Text className='dark-color' style={{ fontSize: 14, fontWeight: '600' }}>Tes ini terdiri dari 3 bagian dengan total </Text>
            <Text style={{ fontSize: 14, fontWeight: '600', color: "#32AB6D" }}> 65 pertanyaan </Text>
            <Text className='dark-color' style={{ fontSize: 14, fontWeight: '600' }}>yang harus diselesaikan dalam </Text>
            <Text style={{ fontSize: 14, fontWeight: '600', color: "#FF0000" }}>60 menit. </Text>
            <Text className='dark-color' style={{ fontSize: 14, fontWeight: '600' }}>Pastikan bahwa anda telah membaca dan mengikuti instruksi dengan baik.</Text>
          </Col>
          <Col className='mt-32 mb-32 text-align-center' span={24}>
            <Text style={{ fontSize: 24, fontWeight: '600' }}>Good Luck!!</Text>
          </Col>  
          <Col xs={{ span: 24, offset: 9 }} lg={{ span: 20, offset: 10 }} xxl={{ span: 18, offset: 10 }}>
            <CButton type="primary" size="large" block action={handleStartBasic} title="Oke"/>
          </Col>
        </Row>
      </Modal>
      {/* Modal Score Basic English Test */}
      <Modal
        className="modal-border-rad-24"
        centered
        title={false}
        footer={false}
        closable={false}
        visible={visibleScore}
        onCancel={handleCancel}
        style={{ borderRadius: 12 }}
      >
        <Row style={{ marginTop: -150 }}>
          <Col span={12} offset={6}>
            <Image 
              preview={false}
              src={Score}
              style={{ objectFit: 'contain' }}
            />
          </Col>
        </Row>
        <Space className='width-100 text-align-center' direction='vertical' size={16}>
          <Space className='text-align-center' direction='vertical' size={0}>
            <Text strong style={{ fontSize: 40, color: '#616466'}}>Congratulations!</Text>
            <Text style={{ fontSize: 20, color: '#616466'}}>You get a score</Text>
            <Text>
              <Text strong style={{ fontSize: 80, color: "#FED302" }}>
                {Math.round(getPathway.data.basicEnglishScore)}
              </Text>
              <Text style={{ fontSize: 90, color: "#7C7C7C", verticalAlign: 'super' }}> / </Text>
              <Text strong style={{ fontSize: 80, color: "#7C7C7C" }}>
                {
                  getPathway.data.basicEnglishScore <= 39 ?
                    'A1'
                  : getPathway.data.basicEnglishScore <= 79 ?
                    'A2'
                  : 
                    'B1'
                }
              </Text>
            </Text>
            <Text style={{ fontSize: 20, color: "#616466" }}>
              English Assessment
            </Text>
          </Space>
          <CButton type='primary' size='large' action={handleCancel} title="Finish"/>
        </Space>
      </Modal>
      {/* Modal Mini English Test */}
      <Modal
        visible={visibleMini}
        onCancel={handleCancel}
        footer={false}
        title={
          <Space className="text-align-center width-100" direction="vertical" size={0}>
            <Text className="fs-20 primary-color">{t('pathway.instructions')} </Text>
            <Text className="fs-15 dark-color">Mini English Test</Text>
          </Space>
        }
        bodyStyle={{ padding: 48 }}
      >
        <Row>
          <Col span={24}>
            <Text type="secondary" style={{ fontSize: 15, fontWeight: 'normal', fontStyle: 'italic' }}>This test consists of 3 sections with a total of </Text>
            <Text style={{ fontSize: 15, fontWeight: 'normal', color: "#32AB6D", fontStyle: 'italic' }}> 10 questions </Text>
            <Text type="secondary" style={{ fontSize: 15, fontWeight: 'normal', fontStyle: 'italic' }}>which must be finished within </Text>
            <Text style={{ fontSize: 15, fontWeight: 'normal', color: "#FF0000", fontStyle: 'italic' }}>15 minutes. </Text>
            <Text type="secondary" style={{ fontSize: 15, fontWeight: 'normal', fontStyle: 'italic' }}>Make sure that you have read and follow the instructions.</Text>
          </Col>
          <Col span={24} style={{paddingTop: 20}}>
            <Text className='dark-color' style={{ fontSize: 14, fontWeight: '600' }}>Tes ini terdiri dari 3 bagian dengan total </Text>
            <Text style={{ fontSize: 14, fontWeight: '600', color: "#32AB6D" }}> 10 pertanyaan </Text>
            <Text className='dark-color' style={{ fontSize: 14, fontWeight: '600' }}>yang harus diselesaikan dalam </Text>
            <Text style={{ fontSize: 14, fontWeight: '600', color: "#FF0000" }}>15 menit. </Text>
            <Text className='dark-color' style={{ fontSize: 14, fontWeight: '600' }}>Pastikan bahwa anda telah membaca dan mengikuti instruksi dengan baik.</Text>
          </Col>
          <Col className='mt-32 mb-32 text-align-center' span={24}>
            <Text style={{ fontSize: 24, fontWeight: '600' }}>GoodLuck!!</Text>
          </Col>  
          <Col span={6} offset={9}>
            {/* <Link to={`/candidate/career-pathway/question/mini-english`}> */}
              <CButton type="primary" size="large" block action={handleStartMini} title="Oke"/>
            {/* </Link> */}
          </Col>
        </Row>
      </Modal>
      {/* Modal Score Mini English Test */}
      <Modal
        className="modal-border-rad-24"
        centered
        title={false}
        footer={false}
        closable={false}
        visible={visibleScoreMini}
        onCancel={handleCancel}
        style={{ borderRadius: 12 }}
      >
        <Row style={{ marginTop: -150 }}>
          <Col span={12} offset={6}>
            <Image 
              preview={false}
              src={Score}
              style={{ objectFit: 'contain' }}
            />
          </Col>
        </Row>
        <Space className='width-100 text-align-center' direction='vertical' size={16}>
          <Space className='text-align-center' direction='vertical' size={0}>
            <Text strong style={{ fontSize: 40, color: '#616466'}}>Congratulations!</Text>
            <Text style={{ fontSize: 20, color: '#616466'}}>You get a score</Text>
            <Text>
              <Text strong style={{ fontSize: 80, color: "#FED302" }}>
                {Math.round(getPathway.data.miniEnglishScore)}
              </Text>
              <Text style={{ fontSize: 90, color: "#7C7C7C", verticalAlign: 'super' }}> / </Text>
              <Text strong style={{ fontSize: 80, color: "#7C7C7C" }}>
                {/* {getPathway?.data?.miniEnglishScore}
                {getPathway?.data?.miniEnglishLevel} */}
                {
                  getPathway.data.miniEnglishScore <= 39 ?
                    'A1'
                  : getPathway.data.miniEnglishScore <= 69 ?
                    'A2'
                  : getPathway.data.miniEnglishScore <= 79 ?
                    'B1'
                  : 
                    'B1+'
                }
              </Text>
            </Text>
            <Text style={{ fontSize: 20, color: "#616466" }}>
              Mini English Test
            </Text>
          </Space>
          <CButton type='primary' size='large' action={handleCancel} title="Finish"/>
        </Space>
      </Modal>
    </React.Fragment>
  )
}

export default withTranslation()(newContent)