import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Result, Button } from 'antd';

export default class NotFound extends Component {
  render() {
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Not Found');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Not Found</title>
        </Helmet>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={<Button type="primary" href="/">Back To Home</Button>}
        />
      </React.Fragment>
    )
  }
}