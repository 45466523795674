import AudioNo1 from "../../../assets/audio/Audio-Number-1.mp3";
import AudioNo2 from "../../../assets/audio/Audio-Number-2.mp3";
import AudioNo3 from "../../../assets/audio/Audio-Number-3.mp3";
import AudioNo4 from "../../../assets/audio/Audio-Number-4.mp3";
import AudioNo5 from "../../../assets/audio/Audio-Number-5.mp3";
import AudioNo6 from "../../../assets/audio/Audio-Number-6.mp3";
import AudioNo7 from "../../../assets/audio/Audio-Number-7.mp3";
import AudioNo8 from "../../../assets/audio/Audio-Number-8.mp3";
import AudioNo9 from "../../../assets/audio/Audio-Number-9.mp3";
import AudioNo10 from "../../../assets/audio/Audio-Number-10.mp3";
import AudioNo11 from "../../../assets/audio/Audio-Number-11.m4a";
import AudioNo12 from "../../../assets/audio/Audio-Number-12.mp3";
import AudioNo13 from "../../../assets/audio/Audio-Number-13.mp3";
import AudioNo14 from "../../../assets/audio/Audio-Number-14.mp3";
import AudioNo15 from "../../../assets/audio/Audio-Number-15.mp3";

export const questionListening = [
  {
    title1: "Choose the correct option to answer the question.",
    title2: "Pilih jawaban yang tepat untuk menjawab pertanyaan berikut.",
    question: "Who will Sam be traveling with?",
    audio: AudioNo1,
    answers: [
      {
        value: 0,
        answerLabel: "His family",
      },
      {
        value: 2,
        answerLabel: "On his own",
      },
      {
        value: 0,
        answerLabel: "His friend",
      },
    ],
  },
  {
    title1: "Choose the correct option to answer the question.",
    title2: "Pilih jawaban yang tepat untuk menjawab pertanyaan berikut.",
    question: "Where was the man yesterday?",
    audio: AudioNo2,
    answers: [
      {
        value: 0,
        answerLabel: "at Croatia",
      },
      {
        value: 0,
        answerLabel: "at his house",
      },
      {
        value: 2,
        answerLabel: "at Fabiola's house",
      },
    ],
  },
  {
    title1: "Choose the correct option to answer the question.",
    title2: "Pilih jawaban yang tepat untuk menjawab pertanyaan berikut.",
    question: "Why does the man never wear clothes his partner bought him?",
    audio: AudioNo3,
    answers: [
      {
        value: 0,
        answerLabel: "Because the clothes are ugly",
      },
      {
        value: 0,
        answerLabel: "Because he doesn't like someone else to buy him clothe",
      },
      {
        value: 2,
        answerLabel: "Because he likes black clothes",
      },
    ],
  },
  {
    title1: "Choose the correct option to answer the question.",
    title2: "Pilih jawaban yang tepat untuk menjawab pertanyaan berikut.",
    question: "Why can't the woman call the bus company?",
    audio: AudioNo4,
    answers: [
      {
        value: 0,
        answerLabel: "Because she doesn't have the number",
      },
      {
        value: 0,
        answerLabel: "Because she doesn't want to do it",
      },
      {
        value: 2,
        answerLabel: "Because the man has more time than her",
      },
    ],
  },
  {
    title1: "Choose the correct option to answer the question.",
    title2: "Pilih jawaban yang tepat untuk menjawab pertanyaan berikut.",
    question: "How does Beth feel about the security cameras?",
    audio: AudioNo5,
    answers: [
      {
        value: 0,
        answerLabel: "She likes it because it's anywhere",
      },
      {
        value: 0,
        answerLabel: "She hates it because it makes her like a criminal",
      },
      {
        value: 2,
        answerLabel: "She likes it because it can help the police",
      },
    ],
  },
  {
    title1: "Choose the correct option to answer the question.",
    title2: "Pilih jawaban yang tepat untuk menjawab pertanyaan berikut.",
    question: "Why does the woman order salad?",
    audio: AudioNo6,
    answers: [
      {
        value: 0,
        answerLabel: "Because she needs side dish after eating steak",
      },
      {
        value: 0,
        answerLabel: "Because she heard that the salad is good",
      },
      {
        value: 2,
        answerLabel: "Because the steak is raw",
      },
    ],
  },
  {
    title1: "Choose the correct option to answer the question.",
    title2: "Pilih jawaban yang tepat untuk menjawab pertanyaan berikut.",
    question: "What does the woman suggest?",
    audio: AudioNo7,
    answers: [
      {
        value: 2,
        answerLabel: "he needs to keep warm",
      },
      {
        value: 0,
        answerLabel: "he must go out",
      },
      {
        value: 0,
        answerLabel: "he needs to take medicine every five hours",
      },
    ],
  },
  {
    title1: "Choose the correct option to answer the question.",
    title2: "Pilih jawaban yang tepat untuk menjawab pertanyaan berikut.",
    question: "Do you think the brand love to work with the comedian?",
    audio: AudioNo8,
    answers: [
      {
        value: 0,
        answerLabel: "Maybe, they will try it first",
      },
      {
        value: 2,
        answerLabel: "No, they don't",
      },
      {
        value: 0,
        answerLabel: "Yes, they do",
      },
    ],
  },
  {
    title1: "Choose the correct option to answer the question.",
    title2: "Pilih jawaban yang tepat untuk menjawab pertanyaan berikut.",
    question: "What makes the lectures start 30 minutes earlier?",
    audio: AudioNo9,
    answers: [
      {
        value: 0,
        answerLabel: "to finish lectures earlier every day",
      },
      {
        value: 0,
        answerLabel: "to eliminate lunch time for students",
      },
      {
        value: 2,
        answerLabel: "to have longer lunch time",
      },
    ],
  },
  {
    title1: "Choose the correct option to answer the question.",
    title2: "Pilih jawaban yang tepat untuk menjawab pertanyaan berikut.",
    question: "What made her shift her career into teaching?",
    audio: AudioNo10,
    answers: [
      {
        value: 0,
        answerLabel: "It's her dream being a teacher since she was younger",
      },
      {
        value: 2,
        answerLabel: "She needed money",
      },
      {
        value: 0,
        answerLabel: "She didn't like acting anymore",
      },
    ],
  },
  {
    title1: "Choose the correct option to answer the question.",
    title2: "Pilih jawaban yang tepat untuk menjawab pertanyaan berikut.",
    question: "How many people visit Stonehenge?",
    audio: AudioNo11,
    answers: [
      {
        value: 0,
        answerLabel: "Six thousand every day of the year.",
      },
      {
        value: 0,
        answerLabel: "One million every summer",
      },
      {
        value: 2,
        answerLabel: "Six thousand every summer day",
      },
    ],
  },
  {
    title1: "Choose the correct option to answer the question.",
    title2: "Pilih jawaban yang tepat untuk menjawab pertanyaan berikut.",
    question: "What happened to her?",
    audio: AudioNo12,
    answers: [
      {
        value: 0,
        answerLabel: "She visited Frankfurt for holiday",
      },
      {
        value: 0,
        answerLabel: "She needed to fly back to Rome",
      },
      {
        value: 2,
        answerLabel: "She couldn't come back to Seattle in time",
      },
    ],
  },
  {
    title1: "Choose the correct option to answer the question.",
    title2: "Pilih jawaban yang tepat untuk menjawab pertanyaan berikut.",
    question:
      "Which of these suggestions might be best solution for the boy next door?",
    audio: AudioNo13,
    answers: [
      {
        value: 0,
        answerLabel: "to move his drums kit to another room",
      },
      {
        value: 0,
        answerLabel: "To suggest him playing the drums in certain time",
      },
      {
        value: 2,
        answerLabel:
          "to put rubber disk in his drums in order to reduce the sound",
      },
    ],
  },
  {
    title1: "Choose the correct option to answer the question.",
    title2: "Pilih jawaban yang tepat untuk menjawab pertanyaan berikut.",
    question: "What were they talking about?",
    audio: AudioNo14,
    answers: [
      {
        value: 0,
        answerLabel: "They were talking about painting",
      },
      {
        value: 2,
        answerLabel: "They were talking about sculpture",
      },
      {
        value: 0,
        answerLabel: "They were talking about scenery",
      },
    ],
  },
  {
    title1: "Choose True/False option to answer the question.",
    title2: "Pilih True/False untuk menjawab pertanyaan berikut.",
    question: "They are all agree to go to Luigi's to have Italian food.",
    audio: AudioNo15,
    answers: [
      {
        value: 0,
        answerLabel: "True",
      },
      {
        value: 2,
        answerLabel: "False",
      },
    ],
  },
];
