import React from 'react'
import { Carousel, Image } from 'antd';
import Banner1 from '../../../assets/img/candidate/banner-xpert1.png';
import Banner2 from '../../../assets/img/candidate/banner-xpert2.png';
import Banner3 from '../../../assets/img/candidate/banner-xpert3.png';
// import MBanner1 from '../../../assets/img/candidate/m-banner-xpert1.png';
// import MBanner2 from '../../../assets/img/candidate/m-banner-xpert2.png';
// import MBanner3 from '../../../assets/img/candidate/m-banner-xpert3.png';

export default function Slider() {
  return (
    <React.Fragment>
      <Carousel autoplay>
        <div>
          <Image
            width={'100%'}
            height={300}
            src={Banner1}
            preview={false}
            style={{ objectFit: 'cover' }}
          />
        </div>
        <div>
          <Image
            width={'100%'}
            height={300}
            src={Banner2}
            preview={false}
            style={{ objectFit: 'cover' }}
          />
        </div>
        <div>
          <Image
            width={'100%'}
            height={300}
            src={Banner3}
            preview={false}
            style={{ objectFit: 'cover' }}
          />
        </div>
      </Carousel>
    </React.Fragment>
  )
}