import React from 'react'
import { Link } from 'react-router-dom';
import Cookie from 'js-cookie';
import { withTranslation } from 'react-i18next';
import { Row, Col, Grid, Form, Input, Collapse, Checkbox, Card, Typography, Space, Radio, Skeleton, Empty, Pagination, Image, Badge } from 'antd';
import { CalendarOutlined, TagOutlined } from '@ant-design/icons';
import { xpertType } from '../../../../utils/constant/xpertType';
import CButton from '..//../../../components/Button';
const { Search } = Input;
const { Panel } = Collapse;
const { Text, Paragraph } = Typography;
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export function ServicePlusListDesktop(props) {
  const { 
    getCategory,
    getXpert,
    formRef,
    onSearch,
    // handleType,
    handleCategory,
    handlePriceType,
    handleDate,
    valueSearch,
    handlePagination,
    handleClearFilter, 
    t
  } = props
  
  const { xxl } = GetBreakPoint()

  const handleClearForm = () => {
    window.scrollTo(0, 0);
    formRef.current.setFieldsValue({
      search: null,
      eventType: null,
      category: null,
      priceType: null,
      date: null
    });
  }
  return (
    <React.Fragment>
      <Form className="mb-32" ref={formRef}>
        <Row className="mt-24">
          <Col lg={{ span: Cookie.get('user') ? 22 : 20, offset: Cookie.get('user') ? 1 : 2 }} xxl={{ span: Cookie.get('user') ? 20 : 16, offset: Cookie.get('user') ? 2 : 4 }}>
            <Row gutter={32}>
              <Col lg={6} xl={5}></Col>
              <Col lg={18} xl={19}>
                <Form.Item name="search" initialValue={valueSearch}>
                  <Search
                    placeholder={t('xpert.search')}
                    allowClear
                    enterButton={t('xpert.button')}
                    onSearch={onSearch}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: Cookie.get('user') ? 22 : 20, offset: Cookie.get('user') ? 1 : 2 }} xxl={{ span: Cookie.get('user') ? 20 : 16, offset: Cookie.get('user') ? 2 : 4 }}>
            <Row gutter={32}>
              <Col lg={6} xl={5}>
                <Collapse className="border-radius-6 dark-color box-shadow" style={{ backgroundColor: '#fff', border: '1px solid #d9d9d9' }} defaultActiveKey={['1','2','3','4']} expandIconPosition="right" ghost>
                  {/* <Panel header={t('xpert.type')} key="1">
                    <Form.Item className="margin-0 mt-min-16 mb-min-16" name="eventType">
                      <Checkbox.Group className="checkbox-filter-job" onChange={(checkedValues) => handleType(checkedValues)}>
                        <Checkbox key="EVENT" className="fs-12 dark-color width-100" value="EVENT">{t('xpert.event')}</Checkbox>
                        <Checkbox key="COURSE" className="fs-12 dark-color width-100" value="COURSE">{t('xpert.course')}</Checkbox>
                        <Checkbox key="TRAINING" className="fs-12 dark-color width-100" value="TRAINING">{t('xpert.training')}</Checkbox>
                        <Checkbox key="SERVICE" className="fs-12 dark-color width-100" value="SERVICE">{t('xpert.typeService')}</Checkbox>
                      </Checkbox.Group>
                    </Form.Item>
                  </Panel> */}
                  {/* <Divid er className="dark-color" /> */}
                  <Panel header={t('xpert.topic')} key="2">
                    <Form.Item className="margin-0 mt-min-16 mb-min-16" name="category">
                      <Checkbox.Group onChange={(checkedValues) => handleCategory(checkedValues)}>
                        {
                          getCategory.loading ?
                            <Skeleton active />
                          :
                            getCategory?.data?.map((item, i) => 
                              <Checkbox key={i} className="fs-12 dark-color width-100" value={item.id}>{item.name}</Checkbox>
                            )
                        }
                      </Checkbox.Group>
                    </Form.Item>
                  </Panel>
                  {/* <Divider className="dark-color" /> */}
                  <Panel header={t('xpert.price')} key="3">
                    <Form.Item className="margin-0 mt-min-16 mb-min-16" name="priceType">
                      <Radio.Group className="width-100" optionType="button" onChange={(e) => handlePriceType(e)}>
                        <Space className="width-100 text-align-center" direction="vertical">
                          <Radio.Button key="1" className="width-100 text-align-center border-radius-6 dark-color fs-12" value="PAID">{t('xpert.paid')}</Radio.Button>
                          <Radio.Button key="2" className="width-100 text-align-center border-radius-6 dark-color fs-12" value="FREE">{t('xpert.free')}</Radio.Button>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Panel>
                  {/* <Divider className="dark-color" /> */}
                  <Panel header={t('xpert.sequence')} key="4">
                    <Form.Item className="margin-0 mt-min-16" name="date">
                      <Radio.Group className="width-100" optionType="button" onChange={(e) => handleDate(e)}>
                        <Space className="width-100 text-align-center" direction="vertical">
                          <Radio.Button key="1" className="width-100 text-align-center border-radius-6 dark-color fs-12" value="hours">{t('xpert.today')}</Radio.Button>
                          <Radio.Button key="2" className="width-100 text-align-center border-radius-6 dark-color fs-12" value="week">{t('xpert.week')}</Radio.Button>
                          <Radio.Button key="3" className="width-100 text-align-center border-radius-6 dark-color fs-12" value="month">{t('xpert.month')}</Radio.Button>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Panel>
                </Collapse>
                <Card className="border-radius-6 box-shadow mt-8" bordered={false}>
                  <CButton className="width-100" type="primary" action={() => {handleClearFilter(); handleClearForm()}} title={t('xpert.clear')}/>
                  {/* <Button className="width-100 border-radius-6" type="primary" onClick={() => {handleClearFilter(); handleClearForm()}}>{t('xpert.clear')}</Button> */}
                </Card>
              </Col>
              <Col lg={18} xl={19}>
                {
                  getXpert?.data?.length > 0 ?
                    <>
                      <Row className="mb-32" gutter={[16, 16]}>
                        {
                          getXpert.loading ?
                            <Skeleton active />
                          :
                            getXpert?.data?.map((item, i) => 
                              // item.endDate >= moment() ?
                                <Col className="xpert" key={i} lg={12} xxl={8}>
                                  <Link to={Cookie.get('user') ? `/candidate/service-plus/detail/${item.categorySlug}/${item.slug}` : `/service-plus/detail/${item.categorySlug}/${item.slug}`}>
                                    {
                                      item.isDiscount ?
                                        <Badge.Ribbon text="Discount" color="volcano">
                                          <Card
                                            className="border-radius-6 dark-color box-shadow item bg-color-white" 
                                            cover={
                                              <img
                                                className="box-shadow"
                                                alt="example"
                                                src={item.imageUrl}
                                                height={150}
                                                style={{ objectFit: 'cover' }}
                                              />
                                            }
                                            bordered={false}
                                          >
                                            <Card className="text-align-center" bordered={false} style={{ width: 150, marginTop: -54, marginLeft: -24, backgroundColor: xpertType.find(res => res.value === item.xpertType)?.color, borderRadius: '0 12px 0 0' }} bodyStyle={{ padding: 4 }}>
                                              {/* <Text className="white-color fs-12">{xpertType.find(res => res.value === item.xpertType)?.name === "Service" ? t('xpert.serviceTag') : null}</Text> */}
                                              <Text className="white-color fs-12">
                                                {
                                                  Cookie.get('i18next') === 'en' ?
                                                    xpertType.find(res => res.value === item.xpertType)?.nameEn
                                                  : 
                                                    xpertType.find(res => res.value === item.xpertType)?.nameIn
                                                }
                                              </Text>
                                            </Card>
                                            <Space className="mt-8 mb-min-16" direction="vertical" size={0}>
                                              {/* <Text className="info-color" ellipsis={true} style={{ fontSize: xxl ? 18 : 16, width: 320, height: 60 }} strong>{item.title}</Text> */}
                                              <Paragraph className="info-color" ellipsis={{ rows: 2 }} style={{ fontSize: xxl ? 18 : 16, width: 265, height: 58, lineHeight: 1.4 }} strong>{item.title}</Paragraph>
                                            </Space>

                                            <Space className='width-100'>
                                              <Image
                                                width={40}
                                                height={40}
                                                src={item.logoUrl}
                                                preview={false}
                                                style={{ objectFit: 'cover' }}
                                              />
                                              <Text className="dark-color fs-15" strong>{item.name}</Text>
                                            </Space>

                                            <Row className='mt-8'>
                                              <Col span={24}>
                                                <Space direction="vertical" size={8}>
                                                  <Space>
                                                    <CalendarOutlined style={{ fontSize: 14 }} />
                                                    <Text className="dark-color fs-12">{item.xpertDate}</Text>
                                                  </Space>
                                                  <Space style={item.isStartFrom ? null : { marginBottom: 24 }}>
                                                    <TagOutlined className="fs-16" />
                                                    <Space direction="vertical" size={0}>
                                                      {
                                                        item.isStartFrom ?
                                                          <Text className="dark-color fs-14">Start from</Text>
                                                        : null
                                                      }
                                                      <Text className="primary-color fs-16 font-weight-bold">
                                                        { 
                                                          item.discountedPrice > 0 ?
                                                            `IDR ${item?.discountedPrice?.toLocaleString()}` 
                                                          : item.price > 0 ?
                                                            `IDR ${item?.price?.toLocaleString()}` 
                                                          : item.price === 0 ?
                                                            `IDR ${item?.price}`
                                                          :
                                                            t('xpert.ticket')
                                                        }
                                                      </Text>
                                                    </Space>
                                                  </Space>
                                                </Space>
                                              </Col>
                                            </Row>
                                          </Card>
                                        </Badge.Ribbon>
                                      : 
                                        <Card
                                          className="border-radius-6 dark-color box-shadow item bg-color-white" 
                                          cover={
                                            <img
                                              className="box-shadow"
                                              alt="example"
                                              src={item.imageUrl}
                                              height={150}
                                              style={{ objectFit: 'cover' }}
                                            />
                                          }
                                          bordered={false}
                                        >
                                          <Card className="text-align-center" bordered={false} style={{ width: 150, marginTop: -54, marginLeft: -24, backgroundColor: xpertType.find(res => res.value === item.xpertType)?.color, borderRadius: '0 12px 0 0' }} bodyStyle={{ padding: 4 }}>
                                            {/* <Text className="white-color fs-12">{xpertType.find(res => res.value === item.xpertType)?.name === "Service" ? t('xpert.serviceTag') : null}</Text> */}
                                            <Text className="white-color fs-12">
                                              {
                                                Cookie.get('i18next') === 'en' ?
                                                  xpertType.find(res => res.value === item.xpertType)?.nameEn
                                                : 
                                                  xpertType.find(res => res.value === item.xpertType)?.nameIn
                                              }
                                            </Text>
                                          </Card>
                                          <Space className="mt-8 mb-min-16" direction="vertical" size={0}>
                                            {/* <Text className="info-color" ellipsis={true} style={{ fontSize: xxl ? 18 : 16, width: 320, height: 60 }} strong>{item.title}</Text> */}
                                            <Paragraph className="info-color" ellipsis={{ rows: 2 }} style={{ fontSize: xxl ? 18 : 16, width: 265, height: 58, lineHeight: 1.4 }} strong>{item.title}</Paragraph>
                                          </Space>

                                          <Space className='width-100'>
                                            <Image
                                              width={40}
                                              height={40}
                                              src={item.logoUrl}
                                              preview={false}
                                              style={{ objectFit: 'cover' }}
                                            />
                                            <Text className="dark-color fs-15" strong>{item.name}</Text>
                                          </Space>

                                          <Row className='mt-8'>
                                            <Col span={24}>
                                              <Space direction="vertical" size={8}>
                                                <Space>
                                                  <CalendarOutlined style={{ fontSize: 14 }} />
                                                  <Text className="dark-color fs-12">{item.xpertDate}</Text>
                                                </Space>
                                                <Space style={item.isStartFrom ? null : { marginBottom: 24 }}>
                                                  <TagOutlined className="fs-16" />
                                                  <Space direction="vertical" size={0}>
                                                    {
                                                      item.isStartFrom ?
                                                        <Text className="dark-color fs-14">Start from</Text>
                                                      : null
                                                    }
                                                    <Text className="primary-color fs-16 font-weight-bold">
                                                      { 
                                                        item.discountedPrice > 0 ?
                                                          `IDR ${item?.discountedPrice?.toLocaleString()}` 
                                                        : item.price > 0 ?
                                                          `IDR ${item?.price?.toLocaleString()}` 
                                                        : item.price === 0 ?
                                                          `IDR ${item?.price}`
                                                        :
                                                          t('xpert.ticket')
                                                      }
                                                    </Text>
                                                  </Space>
                                                </Space>
                                              </Space>
                                            </Col>
                                          </Row>
                                        </Card>
                                    }
                                  </Link>
                                </Col>
                              // :
                              //   null
                            )
                        }
                      </Row>
                      <Row>
                        <Col className="text-align-center" span={24}>
                          <Pagination onChange={handlePagination} current={getXpert?.meta?.page} pageSize={getXpert?.meta?.perpage || 12} total={getXpert?.meta?.total} />
                        </Col>
                      </Row>
                    </>
                  :
                    <Card className="border-radius-3 box-shadow mb-6">
                      <Empty />
                    </Card>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default withTranslation()(ServicePlusListDesktop)
