import React, { Component } from 'react'
import XpertListComponent from '../../../../components/Xpert/List'

export default class XpertList extends Component {
  render() {
    return (
      <React.Fragment>
        <XpertListComponent />
      </React.Fragment>
    )
  }
}