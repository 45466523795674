import React from 'react';
import { Link } from 'react-router-dom';
import Cookie from 'js-cookie';
import InfiniteScroll from 'react-infinite-scroller';
import { withTranslation } from 'react-i18next';
import EmptyLogo from '../../../assets/img/EmptyLogo.png';
import { Row, Col, Form, List, Input, Image, Typography, Space, Collapse, Checkbox, Radio, Divider, Switch, Select, Spin, Modal, Card, Skeleton, Tooltip, Button } from 'antd';
import { SafetyOutlined, EnvironmentOutlined, PlusOutlined, CheckOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import '../Job.css';
import CButton from '../../Button';

const { Option } = Select;
const { Search } = Input;
const { Title, Text } = Typography;
const { Panel } = Collapse;


export function JobListDesktop(props) {
  const {
    onSearch, 
    callback, 
    getJob,
    handleSort,
    onChangePagination, 
    dataIndustry, 
    handleIndustry, 
    handleDestination,
    dataLocation, 
    handleLocation,
    handleType,
    handleRemote,
    handleLevel,
    handleDate,
    handleClearFilter,
    formRef,
    flagRemote,
    showModalIndustry,
    allJobIndustry,
    onSearchIndustry,
    handleCheckedIndustry,
    visibleIndustry,
    valueIndustry,
    handleOkIndustry,
    handleClearIndustry,
    showModalLocation,
    visibleLocation,
    allJobDestination,
    onSearchLocation,
    handleCheckedLocation,
    handleOkLocation,
    handleClearLocation,
    valueLocation,
    valueSearch,
    handleCancel,
    loadingList,
    hasMore,
    handleInfiniteOnLoad,
    handleFavorite,
    valueDestination,
    t
  } = props

  const onClickApply = (val) => {
    localStorage.setItem('link', val);
    localStorage.setItem('visible', true);
    props.history.push('/login-or-register')
  }

  const handleClearForm = () => {
    window.scrollTo(0, 0);
    formRef.current.setFieldsValue({
      search: null,
      industry: null,
      destination: null,
      location: null,
      jobType: null,
      level: null,
      postDate: null
    });
  }

  return (
    <React.Fragment>
      <Form ref={formRef}>
        <Row className="padding-16">
          <Col md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
            <Row className="mb-16" gutter={8}>
              <Col md={8} lg={6} xl={5}></Col>
              <Col md={16} lg={18} xl={19}>
                <Form.Item name="search" initialValue={valueSearch}>
                  <Search
                    placeholder={t('jobList.title')} 
                    allowClear
                    enterButton={t('jobList.search')}
                    onSearch={onSearch}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="width-100" gutter={8}>
              <Col md={8} lg={6} xl={5}>
                <Title className="font-weight-normal dark-color" level={5}>Filter</Title>
              </Col>
              
              <Col md={8} lg={9} xl={9}>
                {
                  getJob.loading ?
                    <Skeleton.Input style={{ width: '100%' }} active />
                  :
                    <Text className="dark-color fs-13">{`${t('jobList.showing')} ${getJob?.data?.meta?.total === 0 ? getJob?.data?.meta?.total : (getJob?.data?.meta?.page*getJob?.data?.meta?.perpage)-(getJob?.data?.meta?.perpage-1)}-${getJob?.data?.meta?.page === getJob?.data?.meta?.pages || getJob?.data?.meta?.total === 0 ? getJob?.data?.meta?.total : getJob?.data?.meta?.page*getJob?.data?.meta?.perpage} ${t('jobList.of')} ${getJob?.data?.meta?.total} ${t('jobList.jobMatches')}`}</Text>
                }
              </Col>
              <Col md={8} lg={9} xl={10}>
                <Space className="float-right">
                  <Text className="fs-13 dark-color">{t('jobList.sortBy')} </Text>
                  <Select defaultValue="postedDate" style={{ width: 140 }} size="small" onChange={handleSort}>
                    {/* <Option value="relevance">Relevance</Option> */}
                    <Option key="expiryDate" value="expiryDate">{t('jobList.expiryDate')}</Option>
                    <Option key="postedDate" value="postedDate">{t('jobList.postedDate')}</Option>
                  </Select>
                </Space>
              </Col>
            </Row>
            <Row className="pt-8 pb-8" gutter={8}>
              <Col md={8} lg={6} xl={5}>
                {
                  dataIndustry?.loading || dataLocation?.loading ?
                    <Card className="border-radius-6 box-shadow mb-8" style={{ width: '100%' }}>
                      <Skeleton active />
                    </Card>
                  :
                    <Collapse className="border-radius-6 dark-color box-shadow" style={{ backgroundColor: '#fff', border: '1px solid #d9d9d9' }} defaultActiveKey={['1','2','3','4','5','6']} onChange={callback} expandIconPosition="right" ghost>
                      <Panel header={t('jobList.industry')} key="1">
                        <Space className="mb-16" direction="vertical" size={-16}>
                          <Form.Item name="industry" initialValue={valueIndustry}>
                            <Checkbox.Group onChange={(checkedValues) => handleIndustry(checkedValues)}>
                              {
                                dataIndustry?.data?.map((item, i) => 
                                  <Checkbox key={i} className="checkbox-filter-job fs-12 dark-color width-100" value={item.id}>{item.title}</Checkbox>
                                )
                              }
                            </Checkbox.Group>
                          </Form.Item>
                          <Text className="add-filter fs-13 cursor-pointer" onClick={showModalIndustry}><PlusOutlined />{t('jobList.addInsutry')}</Text>
                          <Modal
                            visible={visibleIndustry}
                            title={t('jobList.filterByIndustry')}
                            onOk={handleOkIndustry}
                            onCancel={handleCancel}
                            footer={[
                              // <Button key="back" onClick={handleClearIndustry}>
                              //   {t('jobList.reset')}
                              // </Button>,
                              <CButton type='default' title={t('jobList.reset')} action={handleClearIndustry}/>,
                              <CButton type='primary' title={t('jobList.submit')} loading={getJob?.loading} action={handleOkIndustry}/>
                              // <Button key="submit" type="primary" loading={getJob?.loading} onClick={handleOkIndustry}>
                              //   {t('jobList.submit')}
                              // </Button>,
                            ]}
                          >
                            <Search className="mb-8" enterButton={t('jobList.search')} placeholder={t('jobList.searchJob')} allowClear onSearch={onSearchIndustry} style={{ width: '100%' }} />
                            <div className="demo-infinite-container">
                              <Form.Item name="modalIndustry" initialValue={valueIndustry}>
                                  {
                                    allJobIndustry.loading ?
                                      <Spin size="small" />
                                    :
                                      <Checkbox.Group className="width-100" onChange={(checkedValues) => handleCheckedIndustry(checkedValues)}>
                                        { 
                                          allJobIndustry?.data?.map((item, i) => 
                                            <Checkbox key={i} className="fs-12 dark-color width-100 mb-8" value={item.id}>{item.title}</Checkbox>
                                          )
                                        }
                                      </Checkbox.Group>
                                  }
                              </Form.Item>
                            </div>
                          </Modal>
                        </Space>
                      </Panel>
                      {/* <Divider className="dark-color" /> */}
                      <Panel header={t('jobList.destination')} key="2">
                        <Space direction="vertical" size={-16}>
                          <Form.Item className="mb-min-8" name="destination" initialValue={valueDestination}>
                            <Checkbox.Group onChange={(checkedValues) => handleDestination(checkedValues)}>
                              <Checkbox key="INTERNATIONAL" className="fs-12 dark-color width-100" value="INTERNATIONAL">{t('jobList.international')}</Checkbox>
                              <Checkbox key="DOMESTIC" className="fs-12 dark-color width-100" value="DOMESTIC">{t('jobList.domestic')}</Checkbox>
                            </Checkbox.Group>
                          </Form.Item>
                        </Space>
                      </Panel>
                      {/* <Divider className="dark-color" /> */}
                      <Panel header={t('jobList.location')} key="3">
                        <Space className="mb-16" direction="vertical" size={-16}>
                          <Form.Item name="location" initialValue={valueLocation}>
                            {
                              dataLocation.loading?
                                <Spin size="small" />
                              :
                                  <Checkbox.Group onChange={(checkedValues) => handleLocation(checkedValues)}>
                                    {
                                      dataLocation?.data?.map((item, i) => 
                                        <Checkbox key={i} className="checkbox-filter-job fs-12 dark-color" value={item.id} style={{ width: '100%' }}>{item.name}</Checkbox>
                                      )
                                    }
                                  </Checkbox.Group>
                            }
                          </Form.Item>
                          <Text className="add-filter fs-13 cursor-pointer" onClick={showModalLocation}><PlusOutlined />{t('jobList.addLocation')}</Text>
                          <Modal
                            visible={visibleLocation}
                            title={t('jobList.filterLocation')}
                            onOk={handleOkLocation}
                            onCancel={handleCancel}
                            footer={[
                              // <Button key="back" onClick={handleClearLocation}>
                              //   {t('jobList.reset')}
                              // </Button>,
                              // <Button key="submit" type="primary" loading={getJob?.loading} onClick={handleOkLocation}>
                              //   {t('jobList.submit')}
                              // </Button>,
                              <CButton type='default' title={t('jobList.reset')} action={handleClearLocation}/>,
                              <CButton type='primary' title={t('jobList.submit')} loading={getJob?.loading} action={handleOkLocation}/>
                            ]}
                          >
                            <Search className="mb-8" placeholder={t('jobList.searchLocation')} enterButton={t('jobList.search')} allowClear onSearch={onSearchLocation} style={{ width: '100%' }} />
                              <div className="demo-infinite-container">
                                <InfiniteScroll
                                  initialLoad={false}
                                  pageStart={0}
                                  loadMore={() => handleInfiniteOnLoad()}
                                  hasMore={!loadingList && hasMore}
                                  useWindow={false}
                                >
                                  <List
                                    dataSource={allJobDestination?.data ? allJobDestination?.data : []}
                                    renderItem={(item, i) => (
                                      <List.Item key={i}>
                                        <Checkbox checked={valueLocation.includes(item.id)} onChange={() => handleCheckedLocation(item.id)} className="fs-12 dark-color width-100" value={item.id}>{item.name}</Checkbox>
                                      </List.Item>
                                    )}
                                    loading={allJobDestination.loading}
                                  >
                                  </List>
                                </InfiniteScroll>
                              </div>
                          </Modal>
                        </Space>
                      </Panel>
                      {/* <Divider className="dark-color" /> */}
                      <Panel header={t('jobList.jobType')} key="4">
                        <Space direction="vertical" size={0}>
                          <Form.Item className="mb-min-8" name="jobType">
                            <Checkbox.Group onChange={(checkedValues) => handleType(checkedValues)}>
                              <Checkbox key="FULL_TIME" className="fs-12 dark-color width-100" value="FULL_TIME">{t('jobList.fullTime')}</Checkbox>
                              <Checkbox key="PART_TIME" className="fs-12 dark-color width-100" value="PART_TIME">{t('jobList.partTime')}</Checkbox>
                              <Checkbox key="FREELANCE" className="fs-12 dark-color width-100" value="FREELANCE">{t('jobList.freelance')}</Checkbox>
                              <Checkbox key="INTERNSHIP" className="fs-12 dark-color width-100" value="INTERNSHIP">{t('jobList.internship')}</Checkbox>
                              <Checkbox key="VOLUNTEER" className="fs-12 dark-color width-100" value="VOLUNTEER">{t('jobList.volunteer')}</Checkbox>
                              <Checkbox key="TALENT_POOL" className="fs-12 dark-color width-100" value="TALENT_POOL">{t('jobList.talentPool')}</Checkbox>
                            </Checkbox.Group>
                          </Form.Item>
                          <Divider style={{ marginBottom: 8, marginTop: 16 }} />
                          <Space className="fs-12 dark-color width-100">
                            {t('jobList.possible')} <Switch size="small" checked={flagRemote} onChange={(checked) => handleRemote(checked)} />
                          </Space>
                        </Space>
                      </Panel>
                      {/* <Divider className="dark-color" /> */}
                      <Panel header={t('jobList.experienceLevel')} key="5">
                        <Space direction="vertical" size={0}>
                          <Form.Item className="mb-min-8" name="level">
                            <Checkbox.Group onChange={(checkedValues) => handleLevel(checkedValues)}>
                              <Checkbox key="ENTRY_LEVEL" className="fs-12 dark-color width-100" value="ENTRY_LEVEL">{t('jobList.entryLevel')}</Checkbox>
                              <Checkbox key="ASSOCIATE" className="fs-12 dark-color width-100" value="ASSOCIATE">{t('jobList.associate')}</Checkbox>
                              <Checkbox key="MID_SENIOR" className="fs-12 dark-color width-100" value="MID_SENIOR">{t('jobList.midSenior')}</Checkbox>
                              <Checkbox key="DIRECTOR" className="fs-12 dark-color width-100" value="DIRECTOR">{t('jobList.director')}</Checkbox>
                              <Checkbox key="EXECUTIVE" className="fs-12 dark-color width-100" value="EXECUTIVE">{t('jobList.executive')}</Checkbox>
                            </Checkbox.Group>
                          </Form.Item>
                        </Space>
                      </Panel>
                      {/* <Divider className="dark-color" /> */}
                      <Panel header={t('jobList.datePosted')} key="6">
                        <Space className="mb-16" direction="vertical" size={0}>
                          <Form.Item className="mb-min-8" name="postDate">
                            <Radio.Group optionType="button" onChange={(e) => handleDate(e)}>
                              <Space className="width-100" direction="vertical">
                                <Radio.Button key="anytime" className="width-100 text-align-center border-radius-6 dark-color" value="anytime">{t('jobList.anyTime')}</Radio.Button>
                                <Radio.Button key="month" className="width-100 -text-aligncenter border-radius-6 dark-color" value="month">{t('jobList.pastMonth')}</Radio.Button>
                                <Radio.Button key="week" className="width-100 text-align-center border-radius-6 dark-color" value="week">{t('jobList.pastWeek')}</Radio.Button>
                                <Radio.Button key="hours" className="width-100 text-align-center border-radius-6 dark-color" value="hours">{t('jobList.pastHours')}</Radio.Button>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                        </Space>
                      </Panel>
                    </Collapse>
                }
                <Card className="border-radius-6 box-shadow" bordered={false} style={{ marginTop: 8 }}>
                  {/* <Button className="width-100 border-radius-6" type="primary" onClick={() => {handleClearFilter(); handleClearForm()}}>{t('jobList.clearFilter')}</Button> */}
                  <CButton type="primary" title={t('jobList.clearFilter')} className="width-100" action={() => {handleClearFilter(); handleClearForm()}} />
                </Card>
              </Col>
              <Col md={16} lg={18} xl={19}>
                {
                  getJob?.loading ?
                    <Card className="border-radius-6 box-shadow mb-8" style={{ width: '100%' }}>
                      <Skeleton active avatar />
                    </Card>
                  :
                    <List
                      className="border-radius-6 box-shadow"
                      size="large"
                      pagination={{
                        style: { textAlign: 'center' },
                        pageSize: getJob?.data?.meta?.perpage,
                        total: getJob?.data?.meta?.total,
                        current: getJob?.data?.meta?.page,
                        onChange: (pageNumber) => onChangePagination(pageNumber)
                      }}
                      bordered
                      dataSource={getJob?.data?.data}
                      renderItem={item => (
                        <List.Item key={item.id}>
                          <Row className="width-100" gutter={16}>
                            <Col className="col-img-job" xxl={3} xl={4} lg={4}>
                              {/* <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}> */}
                              <Link to={Cookie.get('user') ? `/candidate/job/detail/${item.slug}` : `/job/detail/${item.slug}`}>
                                <Image
                                  src={item.companyLogoUrl ? item.companyLogoUrl : EmptyLogo}
                                  preview={false}
                                  width={'100%'}
                                  height={100}
                                  style={{ objectFit: 'contain' }}
                                />
                              </Link>
                            </Col>
                            <Col xxl={14} xl={13} lg={8}>
                              <Space direction="vertical" size={0}>
                                <Link to={Cookie.get('user') ? `/candidate/job/detail/${item.slug}` : `/job/detail/${item.slug}`}>
                                  <Text className="title-job dark-color fs-17">{item.title}</Text>
                                </Link>
                                {/* <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}> */}
                                  <Text className="company-job fs-13 dark-color">{item.companyName} {item.isVerified ? <SafetyOutlined className="primary-color" /> : null}</Text>
                                {/* </Link> */}
                                <Text className="fs-13 dark-color"><EnvironmentOutlined /> {item.jobDestinationParentId ? `${item.jobDestinationName}, ${item.jobDestinationParentName}` : item.jobDestinationName}</Text>
                                <Text className="fs-13 primary-color">
                                  {
                                    item.isDisplaySalary ?
                                      item.maxSalary ? 
                                        `${item.salary ? item.salary.toLocaleString() : null} - ${item.maxSalary ? item.maxSalary.toLocaleString() : null} ${item.currencyCode}` 
                                      : 
                                        `${item.salary ? item.salary.toLocaleString() : null} ${item.currencyCode}`
                                    :
                                      'Salary not disclosed'
                                  }
                                </Text>
                                <Text className="dark-color fs-13">{item.agencyName ? `${t('jobList.recruited')} ${item.agencyName}` : null}</Text>
                              </Space>
                            </Col>
                            <Col lg={12} xl={7} >
                              <Space className="float-right" direction="vertical" size={16}>
                                <Text className="float-right fs-11 dark-color">{`${t('jobList.postedOn')} ${item.postDate}`}</Text>
                                {
                                  Cookie.get('user') ?
                                    <Space className="float-right" size={8}>
                                      {
                                        item.jobPostingTypeId === 2 && Cookie.get('email')?.split("@")[1] === 'student.binawan.ac.id' ?
                                          <>
                                            <Tooltip title={item.isFavorite ? t('jobList.alreadyFavorite') : t('jobList.addFavorite')  }>
                                              {/* <CButton type="link" icon={item.isFavorite ? 'StarFilled' : 'StarOutlined'} action={() => handleFavorite(item.id, item.isFavorite)} ghost /> */}
                                              <Button type="primary" className="border-radius-6" icon={item.isFavorite ? <StarFilled /> : <StarOutlined />} onClick={() => handleFavorite(item.id, item.isFavorite)} ghost />
                                            </Tooltip>
                                            {
                                              item.isApply ?
                                                <Text className="float-right fs-14 primary-color"><CheckOutlined />{t('jobList.applied')}</Text>
                                              :
                                                
                                                <Link to={`/candidate/job/detail/${item.slug}`}>
                                                  {/* <CButton type="primary" title={t('jobList.apply')}/> */}
                                                  <Button type="primary" className="float-right border-radius-6">{t('jobList.apply')}</Button>
                                                </Link>  
                                            }
                                          </>
                                        : item.jobPostingTypeId === 2 && Cookie.get('email')?.split("@")[1] !== 'student.binawan.ac.id' ?
                                          <Tooltip title={t('jobList.typeWh') }>
                                            <CButton type="primary" className="float-right" disabled title={t('jobList.apply')}/>
                                          </Tooltip>
                                        :
                                          <>
                                            <Tooltip title={item.isFavorite ? t('jobList.alreadyFavorite') : t('jobList.addFavorite') }>
                                              {/* <CButton type="icon" icon={item.isFavorite ? "StarFilled" : "StarOutlined" } action={() => handleFavorite(item.id, item.isFavorite)} /> */}
                                              <Button type="primary" className="border-radius-6" icon={item.isFavorite ? <StarFilled /> : <StarOutlined />} onClick={() => handleFavorite(item.id, item.isFavorite)} ghost />
                                            </Tooltip>
                                            {
                                              item.isApply ?
                                                <Text className="float-right fs-14 primary-color"><CheckOutlined /> {t('jobList.applied')}</Text>
                                              :
                                                <Link to={`/candidate/job/detail/${item.slug}`}>
                                                  <CButton type="primary" className="float-right" title={t('jobList.apply')}/>
                                                </Link>
                                            }         
                                          </>
                                      }
                                    </Space>
                                  :
                                      <CButton type="primary" className="float-right" action={() => onClickApply(`/job/detail/${item.slug}`)} title={t('jobList.apply')}/>
                                    // <Button type="primary" className="float-right border-radius-6" onClick={() => onClickApply(`/job/detail/${item.slug}`)}>{t('jobList.apply')}</Button>
                                }
                              </Space>
                            </Col>
                          </Row>
                        </List.Item>
                      )}
                    />
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}


export default withTranslation()(JobListDesktop)