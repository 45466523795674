import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import { Row, Col, Card, Space, Typography, Image, message } from 'antd';
import BankMandiri from '../../../../../assets/img/candidate/bank-mandiri.png';
import moment from 'moment';
import 'moment/locale/id';
import CButton from '../../../../../components/Button';

const { Text } = Typography;

export class CandidateXpertPayment extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      orderDateString: null,
      expiredDateString: null,
      totalTransaction: 0,
      xpertTransactionNumber: null
    }
  }
  componentDidMount(){
    const { location: { state } } = this.props
    this.setState({ 
      orderDateString: state.orderDateString,
      expiredDateString: state.expiredDateString,
      totalTransaction: state.totalTransaction,
      xpertTransactionNumber: state.xpertTransactionNumber
    })
    window.scrollTo(0, 0)
    localStorage.removeItem("end_date")
    if(state.status === 'REJECTED'){
      this.props.history.push({
        pathname: '/candidate/xpert/payment/expired',
        state: state
      })
    }
  }

  handleCopy = () => {
    navigator.clipboard.writeText('1030007950815')
    message.success('copied to clipboard');
  }

  handleUpload = () => {
    const { location: { state } } = this.props

    if(state.status === 'REJECTED'){
      this.props.history.push({
        pathname: '/candidate/xpert/payment/expired',
        state: state
      })
    }else{
      this.props.history.push({
        pathname: '/candidate/xpert/payment/upload',
        state: state
      })
    }
  }

  render() {
    const { orderDateString, expiredDateString, totalTransaction, xpertTransactionNumber } = this.state
    const { t } = this.props

    return (
      <React.Fragment>
        <Row className="mt-16">
          <Col md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
            <Card className="border-radius-8 mb-16">
              <Space className="width-100 mb-32" direction="vertical" size={16}>
                <Text className="info-color fs-18">{t('xpert.thankyou')}</Text>
                <Text className="dark-color fs-15">{t('xpert.text')} <Text className="font-weight-bold">{t('xpert.text2')}</Text> {t('xpert.text3')}</Text>
                <Row>
                  <Col className="text-align-center" span={24} style={{ backgroundColor: '#fee7e8', padding: 16 }}>
                    <Text className="danger-color" style={{ fontSize: 16, fontWeight: 'normal' }}>{`Mohon untuk melakukan pembayaran sebelum pukul ${moment(expiredDateString, 'DDMMYYYY HHmmss').format('LT')} pada hari ${moment(expiredDateString, 'DDMMYYYY HHmmss').format('LL')}`}</Text>
                  </Col>
                </Row>
              </Space>

              <Card style={{ backgroundColor: 'transparent', border: '1px solid #e0e0e0', paddingLeft: 32, paddingRight: 32 }}>
                <Image
                  width={150}
                  src={BankMandiri}
                  preview={false}
                  style={{ objectFit: 'containts' }}
                />
                <Row className="mt-16">
                  <Col span={12}>
                    <Space direction="vertical" size={32}>
                      <Space direction="vertical" size={0}>
                        <Text className="dark-color fs-14">{t('xpert.norek')}</Text>
                        <Space size={16}>
                          <Text className="dark-color fs-18 font-weight-bold">103-00-0795081-5</Text>
                          <Text className="info-color fs-15 cursor-pointer" onClick={this.handleCopy}>{t('xpert.copy')}</Text>
                        </Space>
                      </Space>

                      <Space direction="vertical" size={0}>
                        <Text className="dark-color fs-14">{t('xpert.account')}</Text>
                        <Text className="dark-color fs-18 font-weight-bold">PT Jobshub Indonesia</Text>
                      </Space>
                      
                      <Space direction="vertical" size={0}>
                        <Text className="dark-color fs-14">{t('xpert.date2')}</Text>
                        <Text className="dark-color fs-18 font-weight-bold">{`${moment(orderDateString, 'DDMMYYYY HHmmss').format('LL')} | ${moment(orderDateString, 'DDMMYYYY HHmmss').format('LT')}`}</Text>
                      </Space>
                    </Space>
                  </Col>
                  <Col span={12}>
                    <Space direction="vertical" size={32}>
                      <Space direction="vertical" size={0}>
                        <Text className="dark-color fs-14">{t('xpert.text5')}</Text>
                        <Text className="dark-color fs-18 font-weight-bold">{`IDR ${totalTransaction.toLocaleString()}`}</Text>
                      </Space>

                      <Space direction="vertical" size={0}>
                        <Text className="dark-color fs-14">{t('xpert.text6')}</Text>
                        <Text className="dark-color fs-18 font-weight-bold">{xpertTransactionNumber}</Text>
                      </Space>
                      
                      <Space direction="vertical" size={0}>
                        <Text className="dark-color fs-14">{t('xpert.text7')}</Text>
                        <Text className="dark-color fs-18 font-weight-bold">{`${moment(expiredDateString, 'DDMMYYYY HHmmss').format('LL')} | ${moment(expiredDateString, 'DDMMYYYY HHmmss').format('LT')}`}</Text>
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </Card>

              <Row className="mt-32" gutter={32}>
                <Col span={12}>
                  <Link to={'/candidate/my-orders'}>
                    <CButton className="box-shadow text-align-center width-100" type="default" size="large" style={{ border: '2px solid #4272b9', color: '#4272b9', background: 'transparent' }} title={t('xpert.text8')}/>
                  </Link>
                </Col>
                <Col span={12}>
                  <CButton className="box-shadow text-align-center width-100" type="primary" size="large" action={this.handleUpload} style={{ border: '#4272b9', background: '#4273b9' }} title={t('xpert.text9')}/>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CandidateXpertPayment))
