import Question8V1 from "../../../assets/img/reading/8-10-v1.png";
import Question8V2 from "../../../assets/img/reading/8-10-v2.png";

export const questionReadingCompre = [
  {
    problemsImgs: Question8V1,
    questions: [
      {
        question: "What's the opposite of man-made disasters?",
        answers: [
          {
            value: 0,
            answerLabel: "Unnatural disasters",
          },
          {
            value: 2,
            answerLabel: "Natural disasters",
          },
          {
            value: 0,
            answerLabel: "Human-caused disasters",
          },
        ],
      },
      {
        question:
          "Why did the United Nations start The World Food Programme in 1961",
        answers: [
          {
            value: 0,
            answerLabel: "to sell food around the world",
          },
          {
            value: 2,
            answerLabel: "to help those who need food assistance",
          },
          {
            value: 0,
            answerLabel: "to provide food in festival",
          },
        ],
      },
      {
        question: `What does "provide" mean?`,
        answers: [
          {
            value: 0,
            answerLabel: "abandon",
          },
          {
            value: 0,
            answerLabel: "ignore",
          },
          {
            value: 0,
            answerLabel: "prevent",
          },
          {
            value: 2,
            answerLabel: "give",
          },
        ],
      },
    ],
  },
  {
    problemsImgs: Question8V2,
    questions: [
      {
        question: "Why did Matt Cuss start 30-day challenges?",
        answers: [
          {
            value: 0,
            answerLabel: "Because he wanted to climb mountains around the world",
          },
          {
            value: 2,
            answerLabel: "Because he felt like his life was monotonous",
          },
          {
            value: 0,
            answerLabel: "Because he liked adventure",
          },
        ],
      },
      {
        question:
          "What moral value can we learn from Matt's story?",
        answers: [
          {
            value: 2,
            answerLabel: "We need to create good habits day by day",
          },
          {
            value: 0,
            answerLabel: "We should give up with our life",
          },
          {
            value: 0,
            answerLabel: "We shouldn't challenge ourselves and get out of our comfort zone",
          },
        ],
      },
      {
        question: `What does "proceed" in the second paragraph mean?`,
        answers: [
          {
            value: 2,
            answerLabel: "continue",
          },
          {
            value: 0,
            answerLabel: "stop",
          },
          {
            value: 0,
            answerLabel: "successful",
          }
        ],
      },
    ],
  },
];
