import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Card, Typography, Modal, Input, Space, Form, Row, Col, DatePicker, List, Checkbox, message } from 'antd';
import { EditFilled, SwapRightOutlined } from '@ant-design/icons';
import { addTraining, updateTraining, deleteTraining } from '../../../../redux/actions/profile/trainingAction';
import Cookie from "js-cookie";
import CButton from '../../../../components/Button';

const { Text } = Typography;

export class Training extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      submitLoading: false,
      deleteLoading: false,
      isDate: false,
      issueDate: null,
      expDate: null,
      isExp: false,
      id: null,
      dataEdit: null
    }
  }
  
  showModal = (id) => {
    const { data } = this.props
    const dataEdit = data.listTraining.filter(item => item.id === id).pop()
    
    this.setState({
      visible: true,
      id: id,
      issueDate: id ? dataEdit?.issueDate : null,
      expDate: id ? dataEdit?.expDate : null,
      isExp: id ? dataEdit?.isExp : false,
      dataEdit: dataEdit
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      isDate: false,
      isExp: false
    })
  };

  onChangeIssueDate = (date, dateString) => {
    const { t } = this.props
    const { expDate } = this.state

    this.setState({
      issueDate: dateString
    })

    if(expDate){
      if(expDate < dateString){
        message.error(t('validationEndDate'), 5)
        this.formRef.current.setFieldsValue({
          expDate: null
        })
        this.setState({
          isDate: true
        })
      }else{
        this.setState({
          isDate: false
        })
      }
    }
  }

  onChangeExpDate = (date, dateString) => {
    const { t } = this.props
    const { issueDate, isExp } = this.state

    if (!isExp) {
      this.setState({
        expDate: dateString
      })

      if(dateString < issueDate){
        message.error(t('validationEndDate'), 5)
        this.formRef.current.setFieldsValue({
          expDate: null
        })
        this.setState({
          isDate: true
        })
      }else{
        this.setState({
          isDate: false
        })
      }
    }
  }

  handleChecked = (e) => {
    this.setState({
      isExp: e.target.checked
    })
    
    if(e.target.checked){
      this.setState({
        expDate: null
      })

      this.formRef.current.setFieldsValue({
        expDate: null
      })
    }
  }

  handleDelete = () => {
    const { actionDeleteTraining, actionGetProfile } = this.props
    const { id } = this.state
    
    return this.setState({ deleteLoading: true }, () => {
      return actionDeleteTraining(id, response => {
        if(response.code === '1000'){
          return this.setState({ deleteLoading: false, visible: false }, () => {
            message.success(response.message)
            Cookie.set("activeElement", window.pageYOffset)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ deleteLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  onFinish = async (values) => {
    const { t, actionAddTraining, actionUpdateTraining, actionGetProfile } = this.props
    const { issueDate, expDate, isExp, id, isDate } = this.state

    values.issueDate = issueDate
    values.expDate = expDate
    values.isExp = isExp
    values.credentialUrl = values.credentialUrl ? values.credentialUrl.slice(0,8) === 'https://' || values.credentialUrl.slice(0,7) === 'http://' ? values.credentialUrl : `http://${values.credentialUrl}` : null
    values.id = id
    
    if(isDate && !isExp){
      message.error(t('validationEndDate'))
    }else{
      if(id){
        return this.setState({ submitLoading: true }, () => {
          return actionUpdateTraining(values, response => {
            if(response.code === '1000'){
              return this.setState({ submitLoading: false, visible: false }, () => {
                message.success(response.message)
                Cookie.set("activeElement", window.pageYOffset)
                return actionGetProfile()
              })
            }else{
              message.error(response.message)
            }
          }, (err) => {
            return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
          })
        })
      }else{
        return this.setState({ submitLoading: true }, () => {
          return actionAddTraining(values, response => {
            if(response.code === '1000'){
              return this.setState({ submitLoading: false, visible: false }, () => {
                message.success(response.message)
                Cookie.set("activeElement", window.pageYOffset)
                return actionGetProfile()
              })
            }else{
              message.error(response.message)
            }
          }, (err) => {
            return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
          })
        })
      }
    }
  }

  disabledDate = (current) => {
    let month = moment()
    return !current || current.isAfter(month);
  }

  render() {
    const { visible, submitLoading, deleteLoading, isExp, id, dataEdit } = this.state
    const { t, data: { listTraining } } = this.props

    
    
    return (
      <React.Fragment>
        <Card 
          className="border-radius-6 box-shadow" 
          title={t('profile.training.title')} 
          bordered={false}
          headStyle={{ color: '#32ab6d', fontSize: 17 }}
          bodyStyle={listTraining.length > 0 ? {} : { textAlign: 'center', padding: 60 }}
          actions={[
            <Text className="primary-color" onClick={() => this.showModal(null)}>{t('profile.training.buttonAdd')}</Text>
          ]}
        >
          {
            listTraining.length > 0 ?
              <List
                dataSource={listTraining}
                renderItem={(item, i) => (
                  <List.Item key={i}>
                    <Row className="width-100">
                      <Col span={20}>
                        <Space direction="vertical" size={0}>
                          <Text className="primary-color">{item.name}</Text>
                          <Text className="dark-color">
                            { 
                              item.issueDate && item.expDate ?
                                `${moment(item.issueDate).format('MMM YYYY')} - ${moment(item.expDate).format('MMM YYYY')}`
                              : item.issueDate && item.isExp ?
                                `${moment(item.issueDate).format('MMM YYYY')} - No Expiration Date`
                              : item.issueDate ?
                                moment(item.issueDate).format('MMM YYYY')
                              : item.expDate ?
                                moment(item.expDate).format('MMM YYYY')
                              : item.isExp ?
                                'No Expiration Date'
                              :
                                null
                            }
                          </Text>
                          <Text className="dark-color">{item.credentialId}</Text>
                          {
                            item.credentialUrl ?
                              <a className="info-color" href={item.credentialUrl} target="_blank" rel="noreferrer">go to link <SwapRightOutlined /></a>
                            :
                              null
                          }
                        </Space>
                      </Col>
                      <Col span={4}>
                        <Text className="primary-color float-right cursor-pointer" onClick={() => this.showModal(item.id)}><EditFilled /> Edit</Text>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            :
              <Text className="dark-color">{t('profile.training.textCard')}</Text>
          }
        </Card>

        <Modal 
          title={t('profile.training.title')} 
          visible={visible} 
          onCancel={this.handleCancel} 
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          centered
          width={600}
          destroyOnClose
          maskClosable={false}
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
            initialValues={{
              name: id ? dataEdit?.name : null,
              organization: id ? dataEdit?.organization : null,
              issueDate: id && dataEdit?.issueDate ? moment(dataEdit?.issueDate) : null,
              expDate: id && dataEdit?.expDate ? moment(dataEdit?.expDate) : null,
              credentialId: id ? dataEdit?.credentialId : null,
              credentialUrl: id ? dataEdit?.credentialUrl : null
            }}
          >
            <Row className="mb-min-8">
              <Col span={24}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">{t('profile.training.form.name')}</Text>
                </Space>
                <Form.Item 
                  name="name"
                  validateFirst
                  rules={[
                    { required: true, message: t('required') },
                    { min: 5, message: t('min',{number:5}) },
                    { max: 50, message: t('max',{number:50}) },
                  ]}
                >
                  <Input  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8">
              <Col span={24}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">{t('profile.training.form.organization')}</Text>
                </Space>
                <Form.Item 
                  name="organization"
                  validateFirst
                  rules={[
                    { required: true, message: t('required') },
                    { min: 5, message: t('min',{number:5}) },
                    { max: 50, message: t('max',{number:50}) },
                  ]}
                >
                  <Input  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Text className="fs-12" type="secondary">{t('profile.training.form.issueDate')}</Text>
                <Form.Item 
                  name="issueDate"
                >
                  <DatePicker inputReadOnly className="width-100" picker="month" placeholder="YYYY-MM" onChange={this.onChangeIssueDate} disabledDate={this.disabledDate} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="fs-12" type="secondary">{t('profile.training.form.expirationDate')}</Text>
                <Form.Item 
                  name="expDate"
                  // validateStatus={isDate && !isExp ? "error" : null}
                  // help={isDate && !isExp ? t('validationExpDate') : null}
                >
                  {
                    isExp ?
                      <Text className="fs-12" type="secondary">{t('profile.training.form.noExp')}</Text>
                    :
                      <DatePicker inputReadOnly className="width-100" picker="month" placeholder="YYYY-MM" onChange={this.onChangeExpDate}  />
                  }
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-16">
              <Col span={24}>
                <Form.Item name="isExp">
                  <Checkbox className="fs-12 dark-color" onChange={this.handleChecked} checked={isExp}>{t('profile.training.form.checkbox')}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-16">
              <Col span={24}>
                <Text className="fs-12" type="secondary">{t('profile.training.form.credentialId')}</Text>
                <Form.Item 
                  name="credentialId"
                  rules={[
                    { max: 50, message: t('max',{number:50}) }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-16">
              <Col span={24}>
                <Text className="fs-12" type="secondary">{t('profile.training.form.credentialUrl')}</Text>
                <Form.Item 
                  name="credentialUrl"
                  rules={[
                    { max: 100, message: t('max',{number:100}) }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              {
                id ?
                  <React.Fragment>
                    <CButton type='danger-outline' className="float-left" title={t('profile.buttonDelete')} action={this.handleDelete} loading={deleteLoading} danger/>
                    <CButton type="primary" className="float-right" title={t('profile.buttonSave')} htmlType="submit" loading={submitLoading} />
                  </React.Fragment>
                :
                  <Space className="float-right">
                    <CButton type='default' title={t('profile.buttonBack')} action={this.handleCancel}/>
                    <CButton type="primary" title={t('profile.buttonSave')} htmlType="submit" loading={submitLoading} />
                  </Space>
              }
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  actionAddTraining: addTraining,
  actionUpdateTraining: updateTraining,
  actionDeleteTraining: deleteTraining
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Training))
