import React from 'react'
import { MainAuth, MainPrivate } from '../components'
import { Route, Redirect } from 'react-router-dom'
export const PrivateRoute = ({ component: Component, passProps, ...res }) => {
  return (
    <React.Fragment>
      <Route 
        {...res} 
        render = {
          (props) => 
          passProps.authed ? <MainPrivate {...res} {...props}> <Component {...res} {...props} /> </MainPrivate> 
          : 
          <Redirect to="/" /> 
        } 
      />
    </React.Fragment>
  )
}
export const AuthRoute = ({ component: Component, passProps, ...res }) => {
  return (
    <Route 
      {...res}
      render = {
        (props) => 
        !passProps.authed && localStorage.getItem('path-interview') ?
          <React.Fragment>
            <MainAuth {...res} {...props}> <Component {...res} {...props} /> </MainAuth>
            <Redirect to="/login"/>
          </React.Fragment>
        : 
        !passProps.authed ? 
          <MainAuth {...res} {...props}> <Component {...res} {...props} /> </MainAuth>
        : 
          passProps.isRegister ?
            // <Redirect to="/job-preferences/industries"/>
            <Redirect to="/candidate/career-pathway"/>
          : localStorage.getItem('link') ?
            <Redirect to={`/candidate${localStorage.getItem('link')}`}/>
          : localStorage.getItem('path-interview') ?
            <Redirect to={localStorage.getItem('path-interview')}/>
          : localStorage.getItem('pathway') ?
            <Redirect to={localStorage.getItem('pathway')}/>
          // : window.location.pathname.slice(0, 12) === '/job/detail/' ?
          //   <Redirect to={`/job/detail/${window.location.pathname.split("/")[3]}`}/>
          :
            <Redirect to="/candidate/career-pathway"/>
       }
    />
  )
}
