import React from 'react'
import { Row, Col, Space, Typography, Image, Card, Breadcrumb, Progress, Form, Input, Radio, Select, Spin } from 'antd';
import Question from '../../../../../../assets/img/candidate/question.png';
import Time from '../../../../../../assets/img/candidate/time.png';
import { withTranslation } from 'react-i18next';
import CButton from '../../../../../../components/Button';

const { Text } = Typography;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

export function index(props) {
  const { formRef, percent, total, placement, getJobIndustries, getJobPosition, getJobDestination, onFinish, onChangePlacement, onChangeIndustry, onFocusPosition, onSearchPosition, onChangePosition, onFocusDestination, onSearchDestination, onChangeDestination, onChangeReason, t } = props

  
  return (
    <React.Fragment>
      <Row className="mt-32">
          <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
            <Breadcrumb className="mb-8">
              <Breadcrumb.Item>Career Pathway</Breadcrumb.Item>
              <Breadcrumb.Item>Area Karir</Breadcrumb.Item>
            </Breadcrumb>
            <Card className="mb-16 border-radius-6 box-shadow" bordered={false}>
              <Row>
                <Col span={12}>
                  <Row >
                    <Col span={8}></Col>
                    <Col className="text-align-center" span={10}>
                      <Space direction="vertical">
                        <Image
                          width={'100%'}
                          height={100}
                          src={Question}
                          preview={false}
                          style={{ objectFit: 'contain' }}
                        />
                        <Text className="info-color" style={{ fontSize: 18, fontWeight: 'normal' }}>{t('startAssesment.question')}</Text>
                      </Space>
                    </Col>
                    <Col span={6}></Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row >
                    <Col span={6}></Col>
                    <Col className="text-align-center" span={10}>
                      <Space direction="vertical">
                        <Image
                          width={'100%'}
                          height={100}
                          src={Time}
                          preview={false}
                          style={{ objectFit: 'contain' }}
                        />
                        <Text className="info-color" style={{ fontSize: 18, fontWeight: 'normal' }}>{t('startAssesment.estimated')}</Text>
                      </Space>
                    </Col>
                    <Col span={8}></Col>
                  </Row>
                </Col>
              </Row>
              <Card className="border-radius-6 mt-32 mb-16" bordered={false} style={{ backgroundColor: '#f8f8f8' }} bodyStyle={{ padding: '30px 50px 30px 50px' }}>
                <Space className="width-100" direction="vertical" size={16}>
                  <Row className="text-align-center">
                    <Col span={24}>
                      <Text className="dark-color" style={{ fontSize: 20, fontWeight: 'normal' }}>{t('startAssesment.instructions')}</Text>
                    </Col>
                  </Row>
                  <Space className="width-100" direction="vertical" size={-4}>
                    <Text className="light-color" style={{ fontSize: 16, fontWeight: 'normal' }}>- {t('startAssesment.instructions1')}</Text>
                    <Text className="light-color" style={{ fontSize: 16, fontWeight: 'normal' }}>- {t('startAssesment.instructions2')}</Text>
                  </Space>
                  <Space className="text-align-center width-100" direction="vertical" size={-4}>
                    <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal' }}>{t('startAssesment.instructions3')}</Text>
                    <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal' }}>{t('startAssesment.instructions4')}</Text>
                  </Space>
                </Space>
              </Card>

              <Progress 
                strokeColor={{
                  '0%': '#4272b9',
                  '100%': '#32ab6d',
                }} 
                percent={percent} 
                showInfo={false} 
              />
              <Text className="dark-color" style={{ fontSize: 14, fontWeight: 'normal' }}>{`${total}/5`} {t('startAssesment.headerQuestion')}</Text>
              
              <Form
                className="mt-16"
                layout="vertical"
                onFinish={onFinish}
                ref={formRef}
              >
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item 
                      name="placementType"
                      label={t('startAssesment.form1')}
                      rules={[{ required: true, message: t('required') }]}
                      initialValue="INTERNATIONAL"
                    >
                      <Radio.Group onChange={onChangePlacement}>
                        <Radio value="INTERNATIONAL">{t('startAssesment.international')}</Radio>
                        <Radio value="DOMESTIC">{t('startAssesment.domestic')}</Radio>
                        <Radio value="BOTH">{t('startAssesment.both')}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="listJobIndustry"
                      label={t('startAssesment.form2')}
                      validateFirst
                      rules={[
                        { required: true, message: t('required') },
                        { 
                          validator: (rule, value, callback) => {
                            if (value) {
                              if (value.length > 3) {
                                callback("Maximum 3 options selected");
                              } else if (value.length <= 3) {
                                callback();
                              }
                            }
                            return;
                          }
                        }
                      ]}
                    >
                      <Select 
                        mode="multiple"
                        allowClear 
                        showSearch
                        onChange={onChangeIndustry}
                        placeholder={t('startAssesment.placeholder2')}
                      >
                        {
                          getJobIndustries?.data?.map((item) => 
                            <Option key={item.id} value={item.title}>{item.title}</Option>
                          )
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item 
                      name="listJobPosition" 
                      label={t('startAssesment.form3')}
                      validateFirst
                      rules={[
                        { required: true, message: t('required') },
                        { 
                          validator: (rule, value, callback) => {
                            if (value) {
                              if (value.length > 3) {
                                callback("Maximum 3 options selected");
                              } else if (value.length <= 3) {
                                callback();
                              }
                            }
                            return;
                          }
                        }
                      ]}
                    >
                      <Select
                        mode="tags"
                        allowClear 
                        onFocus={onFocusPosition}
                        onSearch={onSearchPosition}
                        onChange={onChangePosition}
                        notFoundContent={getJobPosition?.loading ? <Spin size="small" /> : null}
                        filterOption={false}
                        placeholder={t('startAssesment.placeholder3')}
                      >
                        {
                          getJobPosition?.data?.map((res) => (
                            <Option key={res.id} value={res.title}>
                              {res.title}
                            </Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item 
                      name="listJobDestination" 
                      label={t('startAssesment.form4')}
                      validateFirst
                      rules={[
                        { required: true, message: t('required') },
                        { 
                          validator: (rule, value, callback) => {
                            if (value) {
                              if (value.length > 3) {
                                callback("Maximum 3 options selected");
                              } else if (value.length <= 3) {
                                callback();
                              }
                            }
                            return;
                          }
                        }
                      ]}
                    >
                      <Select 
                        mode="multiple"
                        allowClear 
                        showSearch
                        onFocus={onFocusDestination}
                        onSearch={onSearchDestination}
                        onChange={onChangeDestination}
                        notFoundContent={getJobDestination?.loading ? <Spin size="small" /> : null}
                        placeholder={t('startAssesment.placeholder4')}
                      >
                        {
                          placement === 'BOTH' ?
                            getJobDestination?.data?.map((item, i) => 
                              <OptGroup key={i} label={item.name}>
                                {
                                  item?.listDestination?.map((res) => 
                                    <Option key={res.id} value={res.name}>{res.name}</Option>
                                  )
                                }
                              </OptGroup>
                            )
                          :
                            getJobDestination?.data?.map(item => 
                              // <Option key={item.id} value={`${item.name}, ${item.countryName}`}>{`${item.name}, ${item.countryName}`}</Option>
                              <Option key={item.id} value={item.name}>{item.name}</Option>
                              )
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item 
                      name="reason" 
                      label={t('startAssesment.form5')}
                      rules={[
                        { max: 500, message: t('max',{number:500}) },
                      ]}
                    >
                      <TextArea rows={3} onChange={onChangeReason} placeholder={t('startAssesment.placeholder5')}/>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      <CButton className="width-100" type="primary" htmlType="submit" title={t('startAssesment.submit')}/>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
    </React.Fragment>
  )
}

export default withTranslation()(index)

