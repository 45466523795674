const DEVELOPMENT           = 'DEVELOPMENT'
const DEVELOPMENT_SUCCESS   = 'DEVELOPMENT_SUCCESS'
const DEVELOPMENT_FAILED    = 'DEVELOPMENT_FAILED'
const UNMOUNT_DEVELOPMENT        = 'UNMOUNT_DEVELOPMENT'
const initialState = {
  loading: true,
  meta: null,
  data: null,
  message: null,
}
const developmentReducer = (state = initialState, action) => {
  switch(action.type){
    case DEVELOPMENT:
      return {
        ...state,
        loading: true,
        meta: null,
        data: null,
      }
    case DEVELOPMENT_SUCCESS:
      return { 
        ...state, 
        loading: false,
        meta: action.payload.data.meta,
        data: action.payload.data.data,
        message: 'SUCCESS',
      }
    case DEVELOPMENT_FAILED:
      return { 
        ...state, 
        loading: true,
        meta: null,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_DEVELOPMENT:
      return { 
        ...state, 
        loading: true,
        meta: null,
        data: null,
      }
    default:
      return state
  }
}
export default developmentReducer;