const LOAD_DETAIL_JOB           = 'LOAD_DETAIL_JOB'
const LOAD_DETAIL_JOB_SUCCESS   = 'LOAD_DETAIL_JOB_SUCCESS'
const LOAD_DETAIL_JOB_FAILED    = 'LOAD_DETAIL_JOB_FAILED'
const UNMOUNT_DETAIL_JOB        = 'UNMOUNT_DETAIL_JOB'
const initialState = {
  loading: true,
  data: null,
  message: null,
}
const jobDetailReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_DETAIL_JOB:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_DETAIL_JOB_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_DETAIL_JOB_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_DETAIL_JOB:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}
export default jobDetailReducer;