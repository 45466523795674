import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { Grid, Row, Col, Typography, Space, Image } from 'antd'
import LoginLogo from '../../assets/img/login.png';
import RegisterLogo from '../../assets/img/register.png';

const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

const { Title, Text } = Typography

export default function Bridging(props) {
  if(process.env.REACT_APP_ENV === "production"){
    ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Login or Register');
  }

  const { xs, md, lg } = GetBreakPoint()

  return (
    <React.Fragment>
      <Helmet>
        <title>Jobshub - Login or Register</title>
      </Helmet>
      <Row style={{ paddingTop: 32, background: '#fff' }}>
        <Col className="width-100" xs={{ span: 24, offset: 0 }} md={{ span: 18, offset: 3 }}>
          <Row className="mb-32">
            <Col span={24}>
              <Space className="text-align-center width-100" direction="vertical" size={0}>
                <Title className="dark-color" level={2}>You’re on your way</Title>
                <Text className="fs-20 dark-color">Login or Register to continue access pages</Text>
              </Space>
            </Col>
          </Row>
          {
            xs ?
              <React.Fragment>
                <Link to="/login">
                  <Row className="bridge width-100 text-align-center">
                    <Col className="text-align-center" span={24}>
                      <Image
                        src={LoginLogo}
                        preview={false}
                        height={300}
                        width={300}
                        style={{ objectFit: 'contain' }}
                      />
                    </Col>
                    <Col span={24} style={{ marginTop: -45 }}>
                      <Text className="fs-16 dark-color">Login</Text>
                    </Col>
                  </Row>
                </Link>
                <Link to="/register">
                  <Row className="bridge width-100 text-align-center">
                    <Col className="text-align-center" span={24}>
                      <Image
                        src={RegisterLogo}
                        preview={false}
                        height={300}
                        width={300}
                        style={{ objectFit: 'contain', }}
                      />
                    </Col>
                    <Col span={24} style={{ marginTop: -45 }}>
                      <Text className="fs-16 dark-color">Register</Text>
                    </Col>
                  </Row>
                </Link>
              </React.Fragment>
            :
              <React.Fragment>
                <Row className="width-100 text-align-center" gutter={md ? 32 : 8}>
                  <Col className="bridge text-align-center" span={12}>
                    <Link to="/login">
                      <Row>
                        <Col span={24}>
                          <Image
                            src={LoginLogo}
                            preview={false}
                            height={lg ? 400 : 300}
                            width={'100%'}
                            style={{ objectFit: 'contain' }}
                          />
                        </Col>
                        <Col className="text-align-center" span={24} style={{ marginTop: lg ? -60 : -40 }}>
                          <Text className="fs-16 dark-color">Login</Text>
                        </Col>
                      </Row>
                    </Link>
                  </Col>
                  <Col className="bridge text-align-center" span={12}>
                    <Link to="/register">
                      <Row>
                        <Col className="width-100" span={24}>
                          <Image
                            src={RegisterLogo}
                            preview={false}
                            height={lg ? 400 : 300}
                            width={'100%'}
                            style={{ objectFit: 'contain', }}
                          />
                        </Col>
                        <Col className="width-100" span={24} style={{ marginTop: lg ? -60 : -40 }}>
                          <Text className="fs-16 dark-color">Register</Text>
                        </Col>
                      </Row>
                    </Link>
                  </Col>
                </Row>
              </React.Fragment>
          }
        </Col>
      </Row>
    </React.Fragment>
  )
}