import React from 'react'
import { withTranslation } from 'react-i18next';
import { Row, Col, Typography, Space, Divider } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { paymentOption } from '../../../../utils/constant/paymentOption';
import CButton from '../../../../components/Button';
const { Text } = Typography


export function waitingPayment(props) {
  const { getOrderDetail, handleCopy, handleUpload, handleCancel, submitLoading, t, showModalPayment } = props
  return (
    <React.Fragment>
      <Row className="my-order-card mb-32" style={{ backgroundColor: 'white', border: '1px solid #e0e0e0' }}>
        <Col span={20} offset={2} style={{ marginTop: 20}}>
          <Text className="fs-18 dark-color font-weight-bold">{t('myOrders.waitingPayment.detail')}</Text>
          <Row className="mt-16" >
            <Col span={16}>
              <Space direction="vertical" size={0}>
                <Text className="fs-14 light-color">{t('myOrders.waitingPayment.name')}</Text>
                {
                  getOrderDetail?.data?.ticketList?.map((item, i) => 
                    <Text key={i} className="info-color fs-14 font-weight-bold">{item.name}</Text>
                  )
                }
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text className="fs-14 light-color">{t('myOrders.waitingPayment.subTotal')}</Text>
              </Space>
            </Col>
            {
                getOrderDetail?.data?.ticketList?.map((item, i) => 
                <React.Fragment key={i}>
                  <Col span={16}>
                    <Space>
                      <Text className="fs-14 light-color">x {item.qty}</Text>
                      <Text className="fs-14 light-color">
                      {
                        `IDR ${item?.price?.toLocaleString()}`
                      }
                      </Text>
                    </Space>
                  </Col>
                  <Col span={8}>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-14 light-color">
                      {
                        `IDR ${item?.totalPrice?.toLocaleString()}`
                      }
                      </Text>
                    </Space>
                  </Col>
                </React.Fragment>
                )
              }
              {
                getOrderDetail?.data.xpertType === 'SERVICE' ? 
                  <Col span={24}>
                    <Space direction="vertical" size={0} style={{ fontStyle: 'italic'}}>
                      {
                        
                          getOrderDetail?.data?.paymentOption !== 'ONE_TIME_PAYMENT' ? 
                            <Text className="fs-14 light-color">{t('myOrders.waitingPayment.Fee')}</Text>
                          : null 
                      }
                    </Space>
                  </Col>
                : null
              }
          </Row>
          <Divider />
          <Text className="fs-18 dark-color font-weight-bold">{t('myOrders.waitingPayment.information')}</Text>
          <Row className="mt-16 mb-32" gutter={[0,8]}>
            <Col xs={24} lg={12}>
              <Space direction="vertical" size={0}>
                <Text className="fs-14 light-color">{t('myOrders.waitingPayment.noOrder')}</Text>
                <Text className="fs-14 dark-color font-weight-bold">{getOrderDetail?.data?.xpertTransactionNumber}</Text>
              </Space>
            </Col>
            <Col xs={24} lg={12}>
              <Space direction="vertical" size={0}>
                <Text className="fs-14 light-color">{t('myOrders.waitingPayment.total')}</Text>
                <Text className="fs-14 dark-color font-weight-bold">
                  {
                    `IDR ${getOrderDetail?.data?.totalTransaction?.toLocaleString()}`
                  }
                </Text>
              </Space>
            </Col>
            <Col xs={24} lg={12}>
              <Space direction="vertical" size={0}>
                <Text className="light-color fs-14">{t('myOrders.waitingPayment.method')}</Text>
                <Text className="dark-color fs-14 font-weight-bold">Bank Transfer</Text>
              </Space>
            </Col>
            <Col xs={24} lg={12}>
              <Space direction="vertical" size={0}>
                <Text className="light-color fs-14">{t('myOrders.waitingPayment.norek')}</Text>
                <Space size={8} >
                  <Text className="fs-14 dark-color font-weight-bold">
                    { 
                      getOrderDetail?.data.xpertType === 'SERVICE' ?
                        getOrderDetail?.data?.receiverBankAccountNumber
                      : '103-00-0795081-5'
                    }
                  </Text>
                  <Text className="info-color fs-14 cursor-pointer" style={{ fontWeight: 500 }} onClick={handleCopy}>SALIN</Text>
                </Space>
              </Space>
            </Col>
            {
              getOrderDetail?.data.xpertType === 'SERVICE' ? 
                <Col xs={24} lg={12}>
                  <Space direction="vertical" size={0}>
                    <Text className="fs-14 light-color">{t('myOrders.waitingPayment.schema')}</Text>
                    <Text className="fs-14 dark-color font-weight-bold">{paymentOption?.find(item => item?.value === getOrderDetail?.data?.paymentOption)?.name}<InfoCircleOutlined style={{ color: '#4272b9' }} onClick={showModalPayment} /></Text>
                  </Space>
                </Col>
                : null
            }
            <Col xs={24} lg={12}>
              <Space direction="vertical" size={0}>
                <Text className="light-color fs-14">{t('myOrders.waitingPayment.date')}</Text>
                <Text className="dark-color fs-14 font-weight-bold">{getOrderDetail?.data?.orderDate}</Text>
              </Space>
            </Col>
            <Col xs={24} lg={12}>
              <Space direction="vertical" size={0}>
                <Text className="fs-14 light-color">{t('myOrders.waitingPayment.account')}</Text>
                <Text className="fs-14 dark-color font-weight-bold">
                  {
                    getOrderDetail?.data?.xpertType === 'SERVICE' ?
                      getOrderDetail?.data?.receiverBankAccountName
                    : 'PT Jobshub Indonesia'
                  }
                </Text>
              </Space>
            </Col>
            <Col xs={24} lg={12}>
              <Space direction="vertical" size={0}>
                <Text className="light-color fs-14">{t('myOrders.waitingPayment.date2')}</Text>
                <Text className="dark-color fs-14 font-weight-bold">{getOrderDetail?.data?.expiredDate}</Text>
              </Space>
            </Col>
          </Row>
          <Row className="text-align-center mb-16" gutter={[0, 8]}>
            <Col span={24}>
              <CButton className="box-shadow text-align-center" type="primary-icon" action={handleUpload} style={{ border: '#4273b9', background: '#4273b9', width: 300, height: 40, fontSize: 16, fontWeight: 500}} title={t('myOrders.waitingPayment.upload')} icon="UploadOutlined"/>
            </Col>
            <Col span={24}>
              <CButton className="box-shadow text-align-center" type="danger-r" action={handleCancel} danger loading={submitLoading} style={{ height: 40, fontSize: 16, fontWeight: 500}} title={t('myOrders.waitingPayment.cancel')} icon="CloseCircleOutlined"/>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withTranslation()(waitingPayment)
