import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Cookie from 'js-cookie';
import Countdown, { zeroPad } from "react-countdown";
import { Loading } from "../../../../../components";
import { withTranslation } from "react-i18next";
import {
  Row,
  Col,
  Card,
  Space,
  Typography,
  Avatar,
  Checkbox,
  Image,
  message,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  detailTicket,
  unmountDetailTicket,
  buyTicket,
} from "../../../../../redux/actions/xpert/xpertAction";
import BankTransfer from "../../../../../assets/img/candidate/bank-transfer_.png";
import VirtualAccount from "../../../../../assets/img/candidate/virtual-account.png";
import OnTheSpot from "../../../../../assets/img/candidate/on-the-spot.png";
import CreditCard from "../../../../../assets/img/candidate/credit-card.png";
import { eventType } from "../../../../../utils/constant/eventType";
import { courseLevel } from "../../../../../utils/constant/courseLevel";
import { trainingType } from "../../../../../utils/constant/trainingType";
import { serviceType } from "../../../../../utils/constant/serviceType";
import CButton from '../../../../../components/Button';
const { Text } = Typography;
// const { Panel } = Collapse;
const getLocalStorageValue = (s) => localStorage.getItem(s);

export class CandidateXpertPaymentMethod extends Component {
  constructor(props) {
    super(props);

    this.state = {
      getXpertDetail: null,
      qty: 0,
      selectedTicket: 0,
      countDown: {
        date: Date.now(),
        delay: 900000,
      },
      wantedDelay: 900000,
      agree: false,
      submitLoading: false,
      visible: false,
      selectedPaymentOptions: null,
      isSelectedBankAccount: null
    };
  }

  componentDidMount() {
    const { wantedDelay } = this.state;
    const {
      actionGetTicketDetail,
      location: {
        state: { getXpertDetail, xpertTicketId },
      },
    } = this.props;

    const savedDate = getLocalStorageValue("end_date");
    if (savedDate != null && !isNaN(savedDate)) {
      const currentTime = Date.now();
      const delta = parseInt(savedDate, 10) - currentTime;

      //Do you reach the end?
      if (delta > wantedDelay) {
        //Yes we clear uour saved end date
        if (localStorage.getItem("end_date").length > 0)
          localStorage.removeItem("end_date");
      } else {
        //No update the end date with the current date
        this.setState({ countDown: { date: currentTime, delay: delta } });
      }
    }

    this.setState({ getXpertDetail });
    window.scrollTo(0, 0);
    actionGetTicketDetail(xpertTicketId);
  }

  renderedCountdown = ({ hours, minutes, seconds, completed }) => {
    // const {
    //   match: { params },
    // } = this.props;

    if (completed) {
      // Render a completed state
      // window.location.href = `/candidate/xpert/detail/${params.categorySlug}/${params.slug}`;
      window.location.href = `/candidate/xpert/list`;
    } else {
      // Render a countdown
      return (
        <Text
          className="danger-color"
          style={{ fontSize: 16, fontWeight: "normal" }}
        >
          {localStorage.getItem("minutes") && localStorage.getItem("seconds")
            ? `${localStorage.getItem("minutes")}:${localStorage.getItem(
                "seconds"
              )}`
            : `${zeroPad(minutes)}:${zeroPad(seconds)}`}
        </Text>
      );
    }
  };

  handleAgreement = (e) => {
    this.setState({
      agree: e.target.checked,
    });
  };

  handleBack = () => {
    const {
      location: {
        state: { getXpertDetail },
      },
    } = this.props;

    this.props.history.push({
      pathname: "/candidate/xpert/choose-ticket",
      state: { getXpertDetail: getXpertDetail },
    });
  };

  handlePaymentOption = (e) => {
    const { isDpFree } = this.state
    this.setState({ selectedPaymentOptions: e.target.value })
    if(e.target.value === 'LOAN' && isDpFree){
      this.setState({ isSelectedBankAccount: null })
    }
  };

  handleBankAccount = (val) => {
    this.setState({ isSelectedBankAccount: val })
  }

  onFinish = () => {
    const { agree } = this.state
    const { getTicketDetail, actionBuyTicket, t } = this.props

    if(agree){
      if(getTicketDetail.data.isFree){
        const values = {
          xpertId: getTicketDetail.data.xpertId,
          paymentType: null,
          xpertTicketBookingDetail: [
            {
              xpertTicketId: getTicketDetail.data.id,
              qty: 1
            }
          ]
        }
        
        return this.setState({ submitLoading: true }, () => {
          return actionBuyTicket(values, response => {
            if(response.code === '1000'){
              setTimeout(() => {
                this.props.history.push({
                  pathname: `/candidate/xpert/payment/success`,
                  state: response.data
                })
                this.setState({ submitLoading: false })
              }, 1500)
            }else{
              message.error(response.message)
            }
          }, (err) => {
            return this.setState({ submitLoading: false }, () => message.error(err.message))
          })
        })
      }else{
        const values = {
          xpertId: getTicketDetail.data.xpertId,
          paymentType: 'TRANSFER',
          xpertTicketBookingDetail: [
            {
              xpertTicketId: getTicketDetail.data.id,
              qty: 1
            }
          ]
        }

        return this.setState({ submitLoading: true }, () => {
          return actionBuyTicket(values, response => {
            if(response.code === '1000'){
              setTimeout(() => {
                this.props.history.push({
                  pathname: `/candidate/xpert/payment`,
                  state: response.data
                })
                this.setState({ submitLoading: false })
              }, 1500)
            }else{
              message.error(response.message)
            }
          }, (err) => {
            return this.setState({ submitLoading: false }, () => message.error(err.message))
          })
        })
      }
      
    }else{
      message.error(t('xpert.checkbox'));
    }
  }

  render() {
    const { getTicketDetail, t } = this.props;
    const { getXpertDetail, countDown, submitLoading } = this.state;

    if (getTicketDetail.loading) {
      return <Loading />;
    }

    return (
      <Row className="mt-16">
        <Col span={22} offset={1}>
          <Card className="border-radius-8 mb-16">
            <Link to={`/candidate/xpert/detail/${getXpertDetail?.categorySlug}/${getXpertDetail?.slug}`}>
              <Space className="cursor-pointer mb-16">
                <ArrowLeftOutlined className="info-color fs-18" />
                <Text className="info-color fs-14">{t('xpert.back')}</Text>
              </Space>
            </Link>
            <Card 
              className="border-radius-8 box-shadow-light"
              title={
                <Space className="width-100 text-align-center" direction="vertical" size={0}>
                  <Text className="white-color fs-16">{t("xpert.paymentMethod")}</Text>
                  <Text className="white-color fs-18">{getXpertDetail?.title}</Text>
                </Space>
              }
              headStyle={{ borderRadius: '8px 8px 0 0', backgroundColor: '#32AB6D' }}
            >
              
              <Row>
                <Col className='mb-16' span={24}>
                  <Space className="text-align-justify" direction="vertical" size={0}>
                    <Text className="dark-color fs-16">{t('xpert.organized')}</Text>
                    <Space>
                      <Avatar shape="square" size={40} src={getXpertDetail?.logoUrl} />
                      <Text className="dark-color fs-15" strong>{getXpertDetail?.name}</Text>
                    </Space>
                  </Space>
                </Col>
                <Col className='mb-16' span={24}>
                  <Space className="text-align-justify" direction="vertical" size={0}>
                    <Text className="dark-color fs-16">{t('xpert.date')}</Text>
                    <Space direction="vertical" size={0}>
                      <Text className="light-color fs-15">{getXpertDetail?.xpertDate}</Text>
                      {/* <Text className="light-color fs-12">{getXpertDetail?.xpertTime}</Text> */}
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  {
                    getXpertDetail?.xpertType === 'EVENT' ?
                      <Space className="text-align-justify" direction="vertical" size={0}>
                        <Text className="dark-color fs-16">{t('xpert.type')}</Text>
                        <Text className="dark-color fs-15">{getXpertDetail?.eventType ? eventType.find(item => item.value === getXpertDetail?.eventType)?.name : 'N/A'}</Text>
                      </Space>
                    : getXpertDetail?.xpertType === 'COURSE' ?
                      <Space className="text-align-justify" direction="vertical" size={0}>
                        <Text className="dark-color fs-16">{t('xpert.level')}</Text>
                        <Text className="dark-color fs-15">{getXpertDetail?.courseLevel ? courseLevel.find(item => item.value === getXpertDetail?.courseLevel)?.name : 'N/A'}</Text>
                      </Space>
                    : getXpertDetail?.xpertType === 'TRAINING' ?
                      <Space className="text-align-justify" direction="vertical" size={0}>
                        <Text className="dark-color fs-16">{t('xpert.level')}</Text>
                        <Text className="dark-color fs-15">{getXpertDetail?.trainingType ? trainingType.find(item => item.value === getXpertDetail?.trainingType)?.name : 'N/A'}</Text>
                      </Space>
                    : getXpertDetail?.xpertType === 'SERVICE' ?
                      <Space className="text-align-justify" direction="vertical" size={0}>
                        <Text className="dark-color fs-16">{t('xpert.chooseTicket.serviceType')}</Text>
                        <Text className="light-color fs-15">
                          {
                            Cookie.get('i18next') === 'en' ?
                              serviceType.find(res => res.value === getXpertDetail?.serviceType)?.nameEn
                            : 
                              serviceType.find(res => res.value === getXpertDetail?.serviceType)?.nameIn
                          }
                        </Text>
                      </Space>
                    : null
                  }
                </Col>
              </Row>
            </Card>

            <Row className="mt-16 mb-16">
              <Col
                className="text-align-center"
                span={24}
                style={{ backgroundColor: "#fee7e8", padding: 16 }}
              >
                <Countdown
                  date={countDown.date + countDown.delay}
                  renderer={this.renderedCountdown}
                  onStart={(delta) => {
                    //Save the end date
                    if (localStorage.getItem("end_date") == null)
                      localStorage.setItem(
                        "end_date",
                        JSON.stringify(countDown.date + countDown.delay)
                      );
                  }}
                  onComplete={() => {
                    if (localStorage.getItem("end_date") != null)
                      localStorage.removeItem("end_date");
                  }}
                />
              </Col>
              <Col
                span={24}
                style={{ border: "2px solid #fee7e8", padding: 16 }}
              >
                <Text
                  className="danger-color"
                  style={{ fontSize: 14, fontWeight: "normal" }}
                >
                  {t("xpert.label")}
                </Text>
              </Col>
            </Row>

            {!getTicketDetail.data.isFree ? (
              <React.Fragment>
                <Row className="mt-16" gutter={[0,8]}>
                  <Col span={24}>
                    <Card
                      className="bg-transparent text-align-center"
                      style={{ border: "2px solid #4272b9", borderRadius: 6 }}
                      bodyStyle={{ padding: 8 }}
                    >
                      <Space>
                        <Image
                          className="mt-4"
                          width={40}
                          height={30}
                          src={BankTransfer}
                          preview={false}
                          style={{ objectFit: "containt" }}
                        />
                        <Text className="fs-16 info-color">
                          Bank Transfer
                        </Text>
                      </Space>
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card
                      className="bg-transparent text-align-center"
                      style={{
                        border: "1px solid #626262",
                        borderRadius: 6,
                        cursor: "not-allowed",
                      }}
                      bodyStyle={{ padding: 8 }}
                    >
                      <Space>
                        <Image
                          className="mt-4"
                          width={40}
                          height={30}
                          src={VirtualAccount}
                          preview={false}
                          style={{ objectFit: "containt" }}
                        />
                        <Text className="fs-16 dark-color">
                          {t("xpert.credit")}
                        </Text>
                      </Space>
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card
                      className="bg-transparent text-align-center"
                      style={{
                        border: "1px solid #626262",
                        borderRadius: 6,
                        cursor: "not-allowed",
                      }}
                      bodyStyle={{ padding: 8 }}
                    >
                      <Space>
                        <Image
                          className="mt-4"
                          width={40}
                          height={30}
                          src={OnTheSpot}
                          preview={false}
                          style={{ objectFit: "containt" }}
                        />
                        <Text className="fs-16 dark-color">
                          {t("xpert.onthespot")}
                        </Text>
                      </Space>
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card
                      className="bg-transparent text-align-center"
                      style={{
                        border: "1px solid #626262",
                        borderRadius: 6,
                        cursor: "not-allowed",
                      }}
                      bodyStyle={{ padding: 8 }}
                    >
                      <Space>
                        <Image
                          className="mt-4"
                          width={40}
                          height={30}
                          src={CreditCard}
                          preview={false}
                          style={{ objectFit: "containt" }}
                        />
                        <Text className="fs-16 dark-color">
                          {t("xpert.credit")}
                        </Text>
                      </Space>
                    </Card>
                  </Col>
                </Row>
              </React.Fragment>
            ) : null}

            <Space
              className={
                !getTicketDetail.data.isFree
                  ? "mt-16 mb-8 width-100"
                  : "mb-8 width-100"
              }
              direction="vertical"
              size={16}
            >
              {!getTicketDetail.data.isFree ? (
                <Space direction="vertical" size={0}>
                  <Text className="fs-14 font-weight-bold dark-color">
                    Bank Transfer
                  </Text>
                  <Text className="fs-14 dark-color">
                    {t("xpert.service")}
                  </Text>
                </Space>
              ) : null}

              <Space className="width-100" direction="vertical" size={0}>
                <Text className="fs-18 dark-color">
                  {t("xpert.paymentTicket")}
                </Text>
                <Text className="fs-14 dark-color">
                  {getTicketDetail.data.name}
                </Text>
                <Text>
                  <Text className="fs-14 font-weight-bold dark-color float-left">
                    {t("xpert.payment")}
                  </Text>
                  {/* <Text className="fs-18 font-weight-bold dark-color float-right">
                    {getTicketDetail.data.price
                      ? `IDR ${getTicketDetail.data.price.toLocaleString()}`
                      : "FREE"}
                  </Text> */}
                  <Text className="fs-18 font-weight-bold dark-color float-right">
                    { 
                      getTicketDetail.data.discountedPrice > 0 ?
                        `IDR ${getTicketDetail?.data?.discountedPrice?.toLocaleString()}` 
                      : getTicketDetail.data.price > 0 ?
                        `IDR ${getTicketDetail?.data?.price?.toLocaleString()}` 
                      : getTicketDetail.data.price === 0 ?
                        `IDR ${getTicketDetail?.data?.price}`
                      :
                        '-'
                    }
                  </Text>
                </Text>
              </Space>

              <Space className="width-100" direction="vertical">
                <Text className="fs-12 dark-color">
                  {t("xpert.label2")}
                </Text>
                {/* <Checkbox className="fs-11 dark-color" onChange={this.onChangeCheck} checked={agree}> */}
                <Checkbox
                  className="fs-12 dark-color"
                  onChange={this.handleAgreement}
                >
                  {t("xpert.agree")}
                  <Link
                    className="info-color"
                    to="/candidate/xpert/terms-and-conditions"
                    target="_blank"
                  >
                    {" "}
                    {t("xpert.terms")}{" "}
                  </Link>
                  {t("xpert.label3")}
                </Checkbox>
                <CButton
                  className="box-shadow-light text-align-center float-right"
                  type="primary"
                  size="large"
                  loading={submitLoading}
                  action={this.onFinish}
                  style={{
                    border: "#4273b9",
                    background: "#4273b9"
                  }}
                  block
                  title={getTicketDetail.data.price
                    ? t("xpert.payment3")
                    : t("xpert.payment3")}
                />
              </Space>
            </Space>
          </Card>
        </Col>
      </Row>
    );
  }
  componentWillUnmount(){
    const { unmountDetailTicket } = this.props
    return unmountDetailTicket()
  }
}

const mapStateToProps = (state) => ({
  getTicketDetail: state.ticketDetailReducer,
});

const mapDispatchToProps = {
  actionGetTicketDetail: detailTicket,
  unmountDetailTicket: unmountDetailTicket,
  actionBuyTicket: buyTicket,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CandidateXpertPaymentMethod)
);
