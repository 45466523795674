import React, { Component } from 'react'
import { Card , Typography, Row, Col, Space, Breadcrumb} from 'antd';
import { withTranslation } from 'react-i18next';
import CButton from '../../../../../../../components/Button';

const { Text } = Typography;


export class S2InfoPart1 extends Component {
  render() {
    const { handleNext, t } = this.props

    return (
      <React.Fragment>
        <Row className='mt-16'>
          <Col lg={{ span: 20, offset: 2 }} xxl={{ span: 18, offset: 3 }}>
            <Breadcrumb className="mb-16" >
              <Breadcrumb.Item>Career Pathway</Breadcrumb.Item>
              <Breadcrumb.Item>Area Karir</Breadcrumb.Item>
            </Breadcrumb>
            <Card className="card-body-info-assessment mt-16 mb-16 border-radius-8"> 
              <Space direction='vertical' size={16}>
                <Space className="text-align-center width-100" direction="vertical" size={0}>
                  <Text className='dark-color' style={{ fontSize: 16, fontWeight: '600' }} strong>Section 2 - Part 1</Text>
                  {/* <Text style={{ fontSize: 20, fontWeight: 'normal', color: "#32AB6D" }} strong>{t('pathway.structure')}</Text> */}
                  <Text style={{ fontSize: 20, fontWeight: 'normal', color: "#32AB6D" }} strong>Structure</Text>
                </Space>
                <Space direction='vertical' size={16}>
                  <Text className='fs-15' style={{ fontStyle: 'italic', color: '#616466' }}>
                    Directions: Questions 16–35 are incomplete sentences. Beneath each sentence you will see four words or phrases, marked. Choose the one word or phrase that best completes the sentence.
                  </Text>
                  <Text className='fs-15' style={{ fontStyle: 'italic', color: '#616466'}}>
                    Petunjuk: Pertanyaan 16–35 adalah kalimat yang tidak lengkap. Di bawah setiap kalimat Anda akan melihat empat kata atau frasa. Pilih satu kata atau frasa yang paling tepat untuk melengkapi kalimat.
                  </Text>
                </Space>
                <Space direction='vertical' size={16}>
                  <Text className='fs-15' style={{ fontStyle: 'italic', color: '#616466' }}>
                    Example:
                  </Text>
                  <Text className='fs-15' style={{ fontStyle: 'italic', color: '#616466'}}>
                    Geysers have often been compared to valcanoes_________both emit hot liquids from below Earth's surface
                  </Text>
                </Space>
                <Space direction='vertical' size={14}>
                  <Text className='fs-15' style={{ fontStyle: 'italic', color: '#616466' }}>
                    (A) despite
                  </Text>
                  <Text className='fs-15' style={{ fontStyle: 'italic', color: '#616466'}}>
                    (B) because
                  </Text>
                  <Text className='fs-15' style={{ fontStyle: 'italic', color: '#616466'}}>
                    (C) in regard to
                  </Text>
                  <Text className='fs-15' style={{ fontStyle: 'italic', color: '#616466'}}>
                    (D) as a result of
                  </Text>
                </Space>
                <Space direction='vertical' size={16}>
                  <Text className='fs-15' style={{ fontStyle: 'italic', color: '#616466' }}>
                    The sentece should read: "Geysers have often been compared to volcanoes because both emit"
                  </Text>
                  <Text className='fs-15' style={{ fontStyle: 'italic', color: '#616466'}}>
                  hot liquids from below Earth's surface."Therefore, you should choose answer (B) because."
                  </Text>
                </Space>
                <Row className='mt-32'>
                  <Col xs={{ span: 24, offset: 9 }} lg={{ span: 20, offset: 11 }} xxl={{ span: 18, offset: 10 }}>
                    <CButton type="primary" size="large" block action={() => handleNext(2,2)} title={t('pathway.start')}/>
                  </Col>
                </Row>
              </Space>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}


export default withTranslation()(S2InfoPart1)
