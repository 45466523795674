import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';
import { Row, Col, Space, Typography, Image, Card } from 'antd';
import WaitingForConfirmation from '../../../../../../assets/img/candidate/waiting-for-confirmation.png';
import CButton from '../../../../../../components/Button';

const { Text } = Typography;

export class CandidateXpertPaymentWaiting extends Component {
  render() {

    const { t } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>Xpert - {t('xpert.title5')}</title>
        </Helmet>
        <Row className="mt-16 pr-16 pl-16">
          <Col lg={{ span: 14, offset: 5 }} xxl={{ span: 8, offset: 8 }}>
            <Card className="border-radius-8 mb-16">
              <Space className="width-100 mb-32 text-align-center" direction="vertical">
                <Text className="info-color fs-16">{t('xpert.title6')}</Text>
                <Space direction="vertical" size={-4}>
                  <Text className="dark-color fs-14">{t('xpert.title7')}</Text>
                  <Text className="dark-color fs-14">{t('xpert.title8')}</Text>
                </Space>
                <Image
                  src={WaitingForConfirmation}
                  preview={false}
                  height={300}
                  width={'100%'}
                  style={{ objectFit: 'contain' }}
                />
              </Space>
              <Row gutter={32}>
                <Col span={24}>
                  <Link to={'/candidate/my-orders'}>
                    <CButton className="box-shadow text-align-center width-100" type="primary" size="large" style={{ border: '#4272b9', background: '#4273b9' }} title={t('xpert.text8')}/>
                  </Link>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default withTranslation()(CandidateXpertPaymentWaiting)
