import { API } from '../../../config'

export const setEmployerRegister = (value, successCB, failedCB) => async dispatch => {
  API.POST('/employers/register', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const setCandidateRecomendation = (value, successCB, failedCB) => async dispatch => {
  API.POST('/candidate-recommendation/register', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}