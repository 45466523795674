import { API } from '../../../../config'
import { errorHandler } from '../../auth/errorAction'

export const unmountIndexInstitution = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_INSTITUTION'})
}

export const indexInstitution = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_INSTITUTION' })
  return API.GET('/institution/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_INSTITUTION_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_INSTITUTION_FAILED' }), 
    ))
  })
}

export const listInstitution = (value) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_INSTITUTION' })
  return API.GET('/institution/list', value).then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_INSTITUTION_SUCCESS', 
      payload: {
        data: response.data,
        meta: response.meta
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_INSTITUTION_FAILED' }), 
    ))
  })
}