import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import Banner from './banner';
import Register from './register';
import Slider from './slider';
import Pipeline from './pipeline';
import Question from './question';
import { indexCountry } from '../../redux/actions/master/country/countryAction';
import { setCandidateRecomendation } from '../../redux/actions/employer/employerAction';
import { message } from 'antd';

export class Employer extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      idCallingCode: 99,
      callingCode: 62,
      visibleSuccess: false,
    }
  }
  
  componentDidMount() {
    const { actionGetCountry } = this.props

    window.scrollTo(0, 0);

    return actionGetCountry()
  }

  showModal = () => {
    this.setState({
      visible: true
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      detail: false,
      visibleSuccess: false
    })
  };

  onChangeCode = (value, option) => {
    this.setState({
      callingCode: Number(option.text),
      idCallingCode: Number(option.key)
    })
    this.formRef.current.setFieldsValue({
      mobilePhone: null
    })
  }

  onChangeDetail = e => {
    if(e.target.value){
      this.formRef.current.setFieldsValue({
        hiringNote: null
      })
    }else{
      this.formRef.current.setFieldsValue({
        role: null,
        numberHired: null,
        urgentType: null,
        note: null
      })
    }

    this.setState({
      detail: e.target.value
    })
  };
  
  onFinish = async (values) => {
    const { idCallingCode } = this.state
    const { actionSetCandidateRecomendation } = this.props
    const { callingCode } = this.state

    values.callingCodeId = idCallingCode
    values.mobilePhone = `${callingCode}${values.mobilePhone}`

    this.setState({ submitLoading: true }, () => {
      return actionSetCandidateRecomendation(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false }, () => {
            this.setState({
              visible: false,
              visibleSuccess: true
            })
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const { visible,  detail, callingCode, idCallingCode, visibleSuccess } = this.state
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Employer');
    }

    const initialProps = {
      visible: visible,
      showModal: this.showModal,
      handleCancel: this.handleCancel,
      formRef: this.formRef,
      onFinish: this.onFinish,
      detail: detail,
      onChangeCode: this.onChangeCode,
      callingCode: callingCode,
      idCallingCode: idCallingCode,
      onChangeDetail: this.onChangeDetail,
      visibleSuccess: visibleSuccess
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Employer</title>
        </Helmet>
        <Banner />
        <Register />
        <Slider />
        <Pipeline />
        <Question {...this.props} {...initialProps} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  getCountry: state.countryReducer
})

const mapDispatchToProps = {
  actionGetCountry: indexCountry,
  actionSetCandidateRecomendation: setCandidateRecomendation
}

export default connect(mapStateToProps, mapDispatchToProps)(Employer)