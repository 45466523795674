import React, { Component } from "react";
import { connect } from "react-redux";
import Cookie from "js-cookie";
import { zeroPad } from "react-countdown";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Score from "../../../../../assets/img/InfoScore.png";
import CButton from '../../../../../components/Button';
import { questionListening } from '../../../../../utils/question/basicEnglish/listening';
import { questionStructure } from '../../../../../utils/question/basicEnglish/structure';
import { questionWrittenExp } from '../../../../../utils/question/basicEnglish/writtenExp';
import { questionReadingCompre } from '../../../../../utils/question/basicEnglish/readingCompre';
// import { shuffleArray } from '../../../../../utils/shuffleArray'
import {
  Modal,
  Row,
  Col,
  Image,
  Space,
  Typography,
  message,
} from "antd";
import { addScore } from "../../../../../redux/actions/careerPathway/careerPathwayAction";
import { ClockCircleOutlined } from '@ant-design/icons';
import { withTranslation } from "react-i18next";

const { Text } = Typography;

const getLocalStorageValue = (s) => localStorage.getItem(s);

export class QuestionBasicEnglish extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSection: 1,
      isPart: 0,
      result: [],
      submitLoading: false,
      visibleScore: false,
      countDown: {
        date: Date.now(),
        delay: 1200000,
      },
      wantedDelay: 1200000
    };
  }

  componentDidMount() {
    const { isSection, isPart, result, wantedDelay } = this.state;

    this.setState({
      isSection: Cookie.get("isSection")
        ? Number(Cookie.get("isSection"))
        : isSection,
      isPart: Cookie.get("isPart") ? Number(Cookie.get("isPart")) : isPart,
      result: Cookie.get("result") ? JSON.parse(Cookie.get("result")) : result,
    });
    window.scrollTo(0, 0);
    const savedDate = getLocalStorageValue("end_date");
    if (savedDate != null && !isNaN(savedDate)) {
      const currentTime = Date.now();
      const delta = parseInt(savedDate, 10) - currentTime;

      //Do you reach the end?
      if (delta > wantedDelay) {
        //Yes we clear uour saved end date
        if (localStorage.getItem("end_date").length > 0)
          localStorage.removeItem("end_date");
        } else {
          //No update the end date with the current date
          this.setState({ countDown: { date: currentTime, delay: delta } });
      }
    }
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', this.onBackButtonEvent);
  }

  onBackButtonEvent = (e) => {
    const { t, actionAddScore } = this.props
    const { result } = this.state
    e.preventDefault();

    if (!this.isBackButtonClicked) {
      if (window.confirm(t('pathway.msgErr'))) {
        this.isBackButtonClicked = true;
        // Your custom logic to page transition, like react-router-dom history.push()
        if(result.length > 0){
          const values = {
            score: Math.round(result.map((item) => item.val).reduce((a, b) => a + b, 0)),
          };
          return actionAddScore(
            values,
            (response) => {
              if (response.code === "1000") {
                localStorage.removeItem('isTest')
                this.props.history.push('/candidate/career-pathway')
              } else {
                message.error(response.message);
              }
            },
            (err) => {
              message.error(err)
            }
          );
        }else{
          localStorage.removeItem('isTest')
          this.props.history.push('/candidate/career-pathway')
        }
      }
      else {
        window.history.pushState(null, null, window.location.pathname);
        this.isBackButtonClicked = false;
      }
    }
  }

  componentWillUnmount(){
    window.removeEventListener('popstate', this.onBackButtonEvent);
  }

  renderedCountdown = ({ hours, minutes, seconds, completed }) => {
    const { t } = this.props
    return (
      <Text className="fs-14 primary-color float-right ml-8"><ClockCircleOutlined /> {t('pathway.runningTime')} {localStorage.getItem("minutes") && localStorage.getItem("seconds")
      ? `${localStorage.getItem("minutes")}:${localStorage.getItem(
          "seconds"
        )}`
      : `${zeroPad(minutes)}:${zeroPad(seconds)}`}</Text>
    );
  };

  handleSelect = (e, key) => {
    const { result } = this.state;
    if (result.find((item) => item.no === key)) {
      result.find((item) => item.no === key).val = e.target.result;
      result.find((item) => item.no === key).selected = e.target.value;
    } else {
      result.push({
        no: key,
        val: e.target.result,
        selected: e.target.value,
      });
    }
    Cookie.set("result", result);
    this.setState({ result });
  };

  handleNext = (section, part, isFilled) => {
    const { actionAddScore } = this.props;
    const { result, wantedDelay, isSection, isPart } = this.state;
    window.scrollTo(0, 0);
    if((section === 1 && part === 1) || (section === 2 && part === 2) || (section === 2 && part === 7) || (section === 3 && part === 1)){
      const savedDate = getLocalStorageValue("end_date");
      if (savedDate != null && !isNaN(savedDate)) {
        const currentTime = Date.now();
        const delta = parseInt(savedDate, 10) - currentTime;

        //Do you reach the end?
        if (delta > wantedDelay) {
          //Yes we clear uour saved end date
          if (localStorage.getItem("end_date").length > 0)
            localStorage.removeItem("end_date");
          } else {
            //No update the end date with the current date
            this.setState({ countDown: { date: currentTime, delay: delta } });
        }
      }
      this.setState({
        countDown: {
          date: Date.now(),
          delay: ((section === 2 && part === 2) || (section === 2 && part === 7)) ? 600000 : 1200000,
        },
        wantedDelay: ((section === 2 && part === 2) || (section === 2 && part === 7)) ? 600000 : 1200000,
      })
    }
    if (
      isFilled ||
      (isSection === 1 && isPart === 0) ||
      (isSection === 2 && (isPart === 0 || isPart === 1 || isPart === 6)) ||
      (isSection === 3 && isPart === 0)
    ) {
      if (section === 3 && part === 7) {
        return this.setState({ submitLoading: true }, () => {
          const values = {
            score: Math.round(result.map((item) => item.val).reduce((a, b) => a + b, 0)),
          };
          return actionAddScore(
            values,
            (response) => {
              if (response.code === "1000") {
                localStorage.removeItem('isTest')
                localStorage.removeItem("questionListening")
                localStorage.removeItem("questionStructure")
                localStorage.removeItem("questionWrittenExp")
                localStorage.removeItem("questionReadingCompre")
                Cookie.remove("isSection");
                Cookie.remove("isPart");
                Cookie.remove("result");
                this.setState({ submitLoading: false, visibleScore: true });
              } else {
                message.error(response.message);
              }
            },
            (err) => {
              return this.setState({ submitLoading: false }, () =>
                message.error(err)
              );
            }
          );
        });
      } else {
        this.setState({
          isSection: section,
          isPart: part,
        });
        Cookie.set("isSection", section);
        Cookie.set("isPart", part);
      }
    } else {
      message.error("Masih ada soal yang belum terisi!");
    }
  };

  handleBack = (section, part) => {
    window.scrollTo(0, 0);
    this.setState({
      isSection: section,
      isPart: part
    })
    Cookie.set("isSection", section);
    Cookie.set("isPart", part);
  }

  handleDone = () => {
    this.props.history.push("/candidate/career-pathway");
    Cookie.remove("isSection");
    Cookie.remove("isPart");
    Cookie.remove("result");
    localStorage.removeItem("end_date");
    localStorage.removeItem('isTest');
  };

  handleTimesUp = () => {
    const { actionAddScore } = this.props;
    const { isSection, isPart, result } = this.state;
    window.scrollTo(0, 0);
    const colQuestion = isSection === 1 ? 15 : (isSection === 2 && isPart < 6) ? 35 : (isSection === 2 && isPart > 6) ? 45 : 65
    for (let i = 1; i <= colQuestion; i++) {
      if (!result.find((item) => item.no === i)) {
        result.push({
          no: i,
          val: 0,
        });
      }
      this.setState({ result });
    }

    if(isSection === 1){
      this.setState({
        isSection: 2,
        isPart: 0
      })
      Cookie.set("isSection", 2);
      Cookie.set("isPart", 0);
      Cookie.set("result", result);
    }
    if(isSection === 2 && isPart < 6){
      this.setState({
        isSection: 2,
        isPart: 6
      })
      Cookie.set("isSection", 2);
      Cookie.set("isPart", 2);
      Cookie.set("result", result);
    }
    if(isSection === 2 && isPart > 6){
      this.setState({
        isSection: 3,
        isPart: 0
      })
      Cookie.set("isSection", 3);
      Cookie.set("isPart", 0);
      Cookie.set("result", result);
    }
    if(isSection === 3){
      return this.setState({ submitLoading: true }, () => {
        const values = {
          score: Math.round(result.map((item) => item.val).reduce((a, b) => a + b, 0)),
        };
        return actionAddScore(
          values,
          (response) => {
            if (response.code === "1000") {
              this.setState({ submitLoading: false, visibleScore: true });
            } else {
              message.error(response.message);
            }
          },
          (err) => {
            return this.setState({ submitLoading: false }, () =>
              message.error(err)
            );
          }
        );
      });
    }
  }

  render() {
    const { result, visibleScore } = this.state;
    // const { t } = this.props
    const initialProps = {
      questionListening: questionListening,
      questionStructure: questionStructure,
      questionWrittenExp: questionWrittenExp,
      questionReadingCompre: questionReadingCompre,
      renderedCountdown: this.renderedCountdown,
      handleSelect: this.handleSelect,
      handleNext: this.handleNext,
      handleTimesUp: this.handleTimesUp,
      handleBack: this.handleBack
    };

    return (
      <React.Fragment>
        <Row className="width-100">
          <Col lg={{ span: 24, offset: 0 }} xs={{ span: 22, offset: 1 }}>
            {/* Section 1 */}
            <Section1 {...this.state} {...initialProps} />

            {/* Section 2 */}
            <Section2 {...this.state} {...initialProps} />
            
            {/* Section 3 */}
            <Section3 {...this.state} {...initialProps} />
          </Col>
        </Row>
        <Modal
          className="modal-border-rad-24"
          centered
          title={false}
          footer={false}
          closable={false}
          visible={visibleScore}
          style={{ borderRadius: 12 }}
        >
          <Row style={{ marginTop: -150 }}>
            <Col span={12} offset={6}>
              <Image
                preview={false}
                src={Score}
                style={{ objectFit: "contain" }}
              />
            </Col>
          </Row>
          <Space
            className="width-100 text-align-center"
            direction="vertical"
            size={16}
          >
            <Space className="text-align-center" direction="vertical" size={0}>
              <Text strong style={{ fontSize: 40, color: '#616466'}}>Congratulations!</Text>
              <Text style={{ fontSize: 20, color: '#616466'}}>You get a score</Text>
              <Text>
                <Text strong style={{ fontSize: 80, color: "#FED302" }}>
                  {result.map((item) => item.val).reduce((a, b) => a + b, 0)}
                </Text>
                <Text style={{ fontSize: 90, color: "#7C7C7C", verticalAlign: 'super' }}> / </Text>
                <Text strong style={{ fontSize: 80, color: "#7C7C7C" }}>
                  {
                    result.map((item) => item.val).reduce((a, b) => a + b, 0) >= 80 ?
                      'B1+'
                    : result.map((item) => item.val).reduce((a, b) => a + b, 0) >= 79 ?
                      'B1'
                    : result.map((item) => item.val).reduce((a, b) => a + b, 0) >= 69 ?
                      'A2'
                    : 
                      'A1'
                  }
                </Text>
              </Text>
              <Text style={{ fontSize: 20, color: "#616466" }}>
                  English Assesment
              </Text>
            </Space>
            <CButton type="primary" size="large" action={this.handleDone} title="Finish"/>
          </Space>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  actionAddScore: addScore,
};

export default withTranslation()(connect(mapStateToProps,mapDispatchToProps)(QuestionBasicEnglish));
