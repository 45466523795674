import { API } from '../../../config'

export const setJobPreferences = (value, successCB, failedCB) => async dispatch => {
  API.POST('/job-preference', value).then((response) => {
    dispatch({ type: 'LOAD_JOB_PREFERENCES_SUCCESS', payload: { 
      data: response
    }
    })
    return successCB && successCB(response)
  })
  .catch((err) => {
    dispatch({ type: 'LOAD_JOB_PREFERENCES_FAILED' })
    return failedCB && failedCB(err.error || err.message)
  })
}