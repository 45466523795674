import { API } from '../../../../config'
import { errorHandler } from '../../auth/errorAction'

export const unmountIndexCategory = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_CATEGORY'})
}

export const indexCategory = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_CATEGORY' })
  return API.GET('/category/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_CATEGORY_SUCCESS', 
      payload: {
        data: response.data,
        meta: response.meta
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_CATEGORY_FAILED' }), 
    ))
  })
}