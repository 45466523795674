import React from 'react';
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import { Helmet } from "react-helmet";
import { Row, Col, Typography, Space, Image, Collapse, Grid } from 'antd'
import QuestionMark from '../../../assets/img/question-mark.png';

const { useBreakpoint } = Grid;
const { Text } = Typography;
const { Panel } = Collapse;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function candidateFaq() {
  window.scrollTo(0, 0);
  
  if(process.env.REACT_APP_ENV === "production"){
    ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - FAQ');
  }

  const { md, xxl } = GetBreakPoint()

  return (
    <React.Fragment>
      <Helmet>
        <title>Jobshub - FAQ</title>
      </Helmet>
      {
        xxl ?
          <Row style={{ paddingTop: 32, paddingBottom: 32, backgroundColor: '#f6fbfc' }}>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
              <Row justify="space-around" align="middle">
                <Col xs={24} md={16}>
                  <Text style={{ fontSize: 46, fontWeight: 'bold', color: '#4273b9' }}>Frequently Asked Questions</Text>
                </Col>
                <Col xs={24} md={8}>
                  <Image
                    width={'100%'}
                    src={QuestionMark}
                    preview={false}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        : md ?
          <Row style={{ paddingTop: 32, paddingBottom: 32, backgroundColor: '#f6fbfc' }}>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
              <Row justify="space-around" align="middle">
                <Col xs={24} md={16}>
                  <Text style={{ fontSize: 36, fontWeight: 'bold', color: '#4273b9' }}>Frequently Asked Questions</Text>
                </Col>
                <Col xs={24} md={8}>
                  <Image
                    width={'70%'}
                    src={QuestionMark}
                    preview={false}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        :
          <Row style={{ paddingTop: 16, paddingBottom: 32, backgroundColor: '#f6fbfc' }}>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
              <Row justify="space-around" align="middle">
                <Col className="text-align-center" xs={24} md={8}>
                  <Image
                    width={'70%'}
                    src={QuestionMark}
                    preview={false}
                  />
                </Col>
                <Col className="text-align-center" xs={24} md={16}>
                  <Text style={{ fontSize: 20, fontWeight: 'bold', color: '#4273b9' }}>Frequently Asked Questions</Text>
                </Col>
              </Row>
            </Col>
          </Row>
      }
      <Row style={ xxl ? { paddingTop: 60 } : { paddingTop: 10, paddingBottom: 10 } }>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Row>
            <Col span={24}>
              <Space className="width-100" direction="vertical" size={0}>
                <Collapse className="collapse-container-custom" defaultActiveKey={['1']} expandIconPosition="right" accordion>
                  <Panel className="collapse-panel-custom" header="Bagaimana melihat semua lowongan pekerjaan yang ada di Jobshub?" key="1">
                    <Space direction="vertical">
                      <Text className="dark-color">1. Alangkah lebih baik jika kamu melakukan registrasi sampai verifikasi email terlebih dahulu</Text>
                      <Text className="dark-color">2. Setelah itu, kamu dapat mengetahui lowongan kerja dengan klik <Link className="info-color" to="/candidate/job">link ini</Link></Text>
                    </Space>
                  </Panel>
                  <Panel className="collapse-panel-custom" header="Bagaimana cara mengirim lamaran ke lowongan yang saya minati?" key="2">
                    <Space direction="vertical">
                      <Text className="dark-color">1. Lengkapi data profil agar perusahaan bisa mempertimbangkanmu sebagai kandidat yang baik</Text>
                      <Text className="dark-color">2. Klik button Apply pada lowongan</Text>
                      <Text className="dark-color">3. Review data yang kamu isi sebelum mengirimkan data ke perusahaan</Text>
                      <Text className="dark-color">4. Klik Submit dan lamaranmu telah sukses terkirim</Text>
                    </Space>
                  </Panel>
                  <Panel className="collapse-panel-custom" header="Kenapa password dinyatakan error?" key="3">
                    <Space direction="vertical">
                      <Text className="dark-color">Pastikan password yang kamu buat sudah sesuai dengan kriteria:</Text>
                      <Text className="dark-color">- Minimal 8 karakter</Text>
                      <Text className="dark-color">- Minimal 1 huruf besar atau kapital</Text>
                      <Text className="dark-color">- Minimal 1 angka</Text>
                    </Space>
                  </Panel>
                  <Panel className="collapse-panel-custom" header="Kenapa tidak mendapatkan email verifikasi?" key="4">
                    <Space direction="vertical">
                      <Text className="dark-color">1. Mohon dicek kembali, apakah email yang kamu isi sudah benar</Text>
                      <Text className="dark-color">2. Jika sudah yakin bahwa alamat email benar, klik tombol ‘Send a new one’ pada halaman Confirm your email</Text>
                      <Text className="dark-color">3. Cek pada spam</Text>
                      <Text className="dark-color">4. Apabila sudah melakukan 3 Langkah diatas namun masih belum mendapatkan email verifikasi, mohon hubungi kami pada email: support@jobshub.id</Text>
                    </Space>
                  </Panel>
                  <Panel className="collapse-panel-custom" header="Bagaimana aku tahu status lamaran pekerjaan yang aku kirimkan?" key="5">
                    <Space direction="vertical">
                      <Text className="dark-color">1. Login terlebih dahulu pada sistem</Text>
                      <Text className="dark-color">2. Cek status lamaranmu pada menu Applications</Text>
                      <Text className="dark-color">3. Kamu akan mendapatkan update pada setiap perubahan status melalui notifikasi</Text>
                    </Space>
                  </Panel>
                  <Panel className="collapse-panel-custom" header="Bagaimana aku tahu status lamaran pekerjaan yang aku kirimkan?" key="6">
                    <Space direction="vertical">
                      <Text className="dark-color">Sayangnya untuk saat ini fitur tersebut belum tersedia. Tetapi jangan khawatir, apabila profilmu telah lengkap, maka kemungkinan untuk dihubungi perusahaan lebih tinggi.</Text>
                    </Space>
                  </Panel>
                  <Panel className="collapse-panel-custom" header="Apa itu favorite jobs?" key="7">
                    <Space direction="vertical">
                      <Text className="dark-color">1. Favorite jobs berguna sebagai tempat menyimpan job yang menarik buatmu</Text>
                      <Text className="dark-color">2. Kamu bisa klik icon bergambar bintang untuk menyimpan job</Text>
                      <Text className="dark-color">3. Apabila ingin mengirimkan lamaran pekerjaan, kamu bisa klik button Apply pada halaman Favorite Jobs</Text>
                    </Space>
                  </Panel>
                  <Panel className="collapse-panel-custom" header="Saya ingin melakukan pengubahan password. Bagaimana caranya?" key="8">
                    <Space direction="vertical">
                      <Text className="dark-color">1. Login terlebih dahulu pada sistem</Text>
                      <Text className="dark-color">2. Klik sub-menu Account Setting yang ada pada profile menu kamu</Text>
                      <Text className="dark-color">3. Ubah password pada section Change Password</Text>
                    </Space>
                  </Panel>
                </Collapse>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}