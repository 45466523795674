import { API } from '../../../config'
import { errorHandler } from '../auth/errorAction'

export const unmountSteps = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_STEPS'})
}

export const unmountContact = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_CONTACT'})
}

export const unmountPersonalInfo = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_PERSONAL_INFO'})
}

export const unmountLastEducation = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_LAST_EDUCATION'})
}

export const steps = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_STEPS' })
  return API.GET('/job/getApplySteps').then((response) => {
    dispatch({
      type: 'LOAD_STEPS_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_STEPS_FAILED' }), 
    ))
  })
}

export const contact = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_CONTACT' })
  return API.GET('/job/getApplyContact').then((response) => {
    dispatch({
      type: 'LOAD_CONTACT_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_CONTACT_FAILED' }), 
    ))
  })
}

export const personalInfo = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_PERSONAL_INFO' })
  return API.GET('/job/getApplyPersonal').then((response) => {
    dispatch({
      type: 'LOAD_PERSONAL_INFO_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_PERSONAL_INFO_FAILED' }), 
    ))
  })
}

export const lastEducation = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_LAST_EDUCATION' })
  return API.GET('/job/getApplyEducation').then((response) => {
    dispatch({
      type: 'LOAD_LAST_EDUCATION_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_LAST_EDUCATION_FAILED' }), 
    ))
  })
}

export const applyJob = (value, successCB, failedCB) => async dispatch => {
  API.POST_FORM_DATA('/job/apply', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}