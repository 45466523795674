export const xpertStatus = [
  {
    "value": "WAITING_FOR_PAYMENT",
    "name": "Menunggu Pembayaran",
    "color": "#ffb017"
  },
  {
    "value": "WAITING_FOR_CONFIRM",
    "name": "Menunggu Konfirmasi",
    "color": "#4272b9"
  },
  {
    "value": "PAYMENT_CONFIRM",
    "name": "Pembayaran Terkonfirmasi",
    "color": "#32ab6d"
  },
  {
    "value": "REJECTED",
    "name": "Ditolak",
    "color": "#f53f54"
  },
  {
    "value": "CANCELED",
    "name": "Dibatalkan",
    "color": "#5b51ff"
  },
  {
    "value": "EXPIRED",
    "name": "Kedaluwarsa",
    "color": "#979797"
  }
]