import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { message } from 'antd';
import { withTranslation } from 'react-i18next';
import { indexJobIndustries, unmountIndexJobIndustries } from '../../../../redux/actions/master/jobIndustries/jobIndustriesAction';
import { listJobPosition, unmountlistJobPosition } from '../../../../redux/actions/master/jobPosition/jobPositionAction';
// import { listCity, unmountListCity } from '../../../../redux/actions/master/city/cityAction';
import { jobDestinationInternational, jobDestinationDomestic, jobDestinationAll } from '../../../../redux/actions/master/jobDestination/jobDestinationAction';
import { addPathway } from '../../../../redux/actions/careerPathway/careerPathwayAction';
import QuestionForm from './Mode'
import { Loading } from '../../../../components';
import Cookie from 'js-cookie';

export class index extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: this.props.visible,
      submitLoading: false,
      placement: 'INTERNATIONAL',
      industriesSelected: [],
      positionSelected: [],
      destinationSelected: [],
      metaPosition: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaDestination: {
        page: 1,
        perpage: 10,
        search: ''
      },
      percent: 20,
      total: 1,
      listJobIndustry: [],
      listJobDestination: [],
      industryFilled: false,
      positionFilled: false,
      destinationFilled: false,
      reasonFilled: false,
      lang: Cookie.get('i18next'),
      validationMessages: this.getValidationMessages(Cookie.get('i18next'))
    }
    this.onSearchPosition = debounce(this.onSearchPosition.bind(this), 800)
    this.onSearchDestination = debounce(this.onSearchDestination.bind(this), 800)
  }

  getValidationMessages = (lang) => {
    const { t } = this.props;
    return {
      placementType: t('required'),
      listJobIndustry: t('required'),
      listJobPosition: t('required'),
      listJobDestination: t('required'),
      reason: t('max', { number: 500 })
    }
  }

  componentDidMount() {
    const { actionGetJobIndustries } = this.props

    window.scrollTo(0, 0);

    // return (actionGetCountry(), actionGetJobIndustries(), actionGetJobPosition(strJobIndustry), actionGetJobDestination())
    return (actionGetJobIndustries())
  }

  componentDidUpdate(prevProps, prevState) {
    const { i18n } = this.props;
    if (i18n.language !== this.state.lang) {
      const newValidationMessages = this.getValidationMessages(i18n.language);
      this.setState({ 
        lang: i18n.language,
        validationMessages: newValidationMessages
      }, this.updateValidationMessages);
    }
  }
  
  updateValidationMessages = () => {
    const { validationMessages } = this.state;

    this.formRef.current.setFields([
      {
        name: 'placementType',
        errors: [validationMessages.placementType]
      },
      {
        name: 'listJobIndustry',
        errors: [validationMessages.listJobIndustry]
      },
      {
        name: 'listJobPosition',
        errors: [validationMessages.listJobPosition]
      },
      {
        name: 'listJobDestination',
        errors: [validationMessages.listJobDestination]
      },
      {
        name: 'reason',
        errors: [validationMessages.reason]
      },
    ]);
  }

  onChangePlacement = e => {
    this.setState({ placement: e.target.value })
  }

  onChangeIndustry = (e, option) => {
    const { percent, total, industryFilled } = this.state

    const optionSelected = option.map(item => Number(item.key)) 

    this.setState({ listJobIndustry: optionSelected })

    if(e.length > 0){
      if(industryFilled === false) {
        this.setState({ percent: percent + 20, total: total + 1, industryFilled: true })
      }
    }else{
      this.setState({ percent: percent - 20, total: total - 1, industryFilled: false })
    }
  }

  onSearchPosition = value => {
    const { metaPosition } = this.state;
    const { actionListPosition } = this.props;

    metaPosition.page = 1
    metaPosition.perpage = 10
    metaPosition.search = value

    return actionListPosition(metaPosition)
  }

  onChangePosition = e => {
    const { metaPosition, percent, total, positionFilled } = this.state;
    const { actionListPosition } = this.props;

    metaPosition.page = 1
    metaPosition.perpage = 10
    metaPosition.search = ''

    if(e.length > 0){
      if(positionFilled === false) {
        this.setState({ percent: percent + 20, total: total + 1, positionFilled: true })
      }
    }else{
      this.setState({ percent: percent - 20, total: total - 1, positionFilled: false })
    }

    return actionListPosition(metaPosition)
  }

  onFocusPosition = () => {
    const { metaPosition } = this.state;
    const { actionListPosition } = this.props;
    return actionListPosition(metaPosition)
  }

  onSearchDestination = value => {
    const { metaDestination, placement } = this.state;
    const { actionGetJobDestinationInternational, actionGetJobDestinationDomestic, actionGetJobDestinationAll } = this.props;

    metaDestination.page = 1
    metaDestination.perpage = 10
    metaDestination.search = value

    if(placement === 'INTERNATIONAL'){
      return actionGetJobDestinationInternational(metaDestination)
    }else if(placement === 'DOMESTIC'){
      return actionGetJobDestinationDomestic(metaDestination)
    }else{
      return actionGetJobDestinationAll(metaDestination)
    }
  }

  onFocusDestination = () => {
    const { metaDestination, placement } = this.state;
    const { actionGetJobDestinationInternational, actionGetJobDestinationDomestic, actionGetJobDestinationAll } = this.props;
    
    if(placement === 'INTERNATIONAL'){
      return actionGetJobDestinationInternational(metaDestination)
    }else if(placement === 'DOMESTIC'){
      return actionGetJobDestinationDomestic(metaDestination)
    }else{
      return actionGetJobDestinationAll(metaDestination)
    }
  }

  onChangeDestination = (e, option) => {
    const { metaDestination, placement, percent, total, destinationFilled } = this.state;
    const { actionGetJobDestinationInternational, actionGetJobDestinationDomestic, actionGetJobDestinationAll } = this.props;

    const optionSelected = option.map(item => Number(item.key)) 
  
    this.setState({ listJobDestination: optionSelected })

    metaDestination.page = 1
    metaDestination.perpage = 10
    metaDestination.search = ''

    if(e.length > 0){
      if(destinationFilled === false) {
        this.setState({ percent: percent + 20, total: total + 1, destinationFilled: true })
      }
    }else{
      this.setState({ percent: percent - 20, total: total - 1, destinationFilled: false })
    }

    if(placement === 'INTERNATIONAL'){
      return actionGetJobDestinationInternational(metaDestination)
    }else if(placement === 'DOMESTIC'){
      return actionGetJobDestinationDomestic(metaDestination)
    }else{
      return actionGetJobDestinationAll(metaDestination)
    }
  }

  onChangeReason = e => {
    const { percent, total, reasonFilled } = this.state

    if(e.target.value){
      if(reasonFilled === false) {
        this.setState({ percent: percent + 20, total: total + 1, reasonFilled: true })
      }
    }else{
      this.setState({ percent: percent - 20, total: total - 1, reasonFilled: false })
    }

  }

  onFinish = values => {
    const { actionAddPathway } = this.props
    const { listJobIndustry, listJobDestination } = this.state

    values.listJobIndustry = listJobIndustry
    values.listJobDestination = listJobDestination
    
    return this.setState({ submitLoading: true }, () => {
      return actionAddPathway(values, response => {
        if(response.code === '1000'){
          this.setState({ submitLoading: false })
          this.props.history.push('/candidate/career-pathway')
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const { getJobIndustries, getJobPosition, getJobDestination } = this.props
    const { percent, total, placement } = this.state

    if(getJobIndustries.loading || getJobPosition.loading || getJobDestination.loading){
      <Loading />
    }

    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Career Pathway - Question');
    }

    const initialProps = {
      percent: percent,
      total: total,
      placement: placement,
      onFinish: this.onFinish,
      onChangePlacement: this.onChangePlacement,
      onChangeIndustry: this.onChangeIndustry,
      onFocusPosition: this.onFocusPosition,
      onSearchPosition: this.onSearchPosition,
      onChangePosition: this.onChangePosition,
      onFocusDestination: this.onFocusDestination,
      onSearchDestination: this.onSearchDestination,
      onChangeDestination: this.onChangeDestination,
      onChangeReason: this.onChangeReason,
      formRef: this.formRef
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Career Pathway - Question</title>
        </Helmet>
        <QuestionForm {...initialProps} {...this.props} />
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountlistJobPosition, unmountIndexJobIndustries } = this.props
    return (unmountlistJobPosition(), unmountIndexJobIndustries())
  }
}

const mapStateToProps = (state) => ({
  getJobIndustries: state.jobIndustriesReducer,
  getJobPosition: state.jobPositionReducer,
  getJobDestination: state.jobDestinationReducer
})

const mapDispatchToProps = {
  actionGetJobIndustries: indexJobIndustries,
  unmountIndexJobIndustries: unmountIndexJobIndustries,
  actionListPosition: listJobPosition,
  unmountlistJobPosition: unmountlistJobPosition,
  actionGetJobDestinationInternational: jobDestinationInternational,
  actionGetJobDestinationDomestic: jobDestinationDomestic,
  actionGetJobDestinationAll: jobDestinationAll,
  actionAddPathway: addPathway
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(index))
