import React from 'react'
import { Row, Col, Image, Grid } from 'antd';
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';

import Background from '../../../assets/img/candidate/bg-world.png'
import MBackground from '../../../assets/img/candidate/m-bg-world.png'
import Rocket from '../../../assets/img/candidate/rocket.png';

const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function banner() {
  const { xs, xxl } = GetBreakPoint()

  const parallaxImage = xs ? MBackground : Background
  const parallaxAmount = xs ? 0.5 : 0.4
  const hightBackground = xs ? 360 : xxl ? 550 : 380
  const hightRocket = xs ? 160 : xxl ? 500 : 380
  const marginTopRocket = xs ? 145 : xxl ? 300 : 120
  return (
    <React.Fragment>
      <ParallaxProvider>
        <ParallaxBanner layers={[{ image: parallaxImage, amount: parallaxAmount }]} style={{ width: '100%', height: hightBackground }}>
          <Row justify="space-between" align="bottom" style={{ hight: hightRocket }}>
            <Col md={3}></Col>
            <Col xs={24} md={18}>
                <Image
                  width={'100%'}
                  height={300}
                  src={Rocket}
                  preview={false}
                  style={{ objectFit: 'contain', marginTop: marginTopRocket }}
                />
            </Col>
            <Col md={3}></Col>
          </Row>
        </ParallaxBanner>
      </ParallaxProvider>
    </React.Fragment>
  )
}
