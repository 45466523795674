import React from 'react'
import { Row, Col, Typography, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
const { Text } = Typography

export function expired(props) {
  const { getOrderDetail, t, showModalPayment } = props
  return (
    <React.Fragment>
      <Row className="mt-16 mb-16" style={{ backgroundColor: '#e6e6e6' }} gutter={[0,8]}>
        <Col span={24}>
          <Space className="text-align-center width-100 padding-16" direction="vertical" size={4}>
            <Text className="fs-15 dark-color">{t('myOrders.expired1.text1')}</Text>
            <Text className="fs-15 danger-color font-weight-bold">{t('myOrders.expired1.text2')}</Text>
          </Space>
        </Col>
      </Row>
      <Row className="mt-16 mb-16" style={{ backgroundColor: '#FFFFFF'}}>
        <Col span={20} offset={2}>
          <Row className="mt-16 mb-16" gutter={[0,8]}>
            <Col xs={24} lg={12}>
              <Space direction="vertical" size={0}>
                <Text className="fs-14 light-color">{t('myOrders.expired1.text3')}</Text>
                <Text className="fs-14 dark-color font-weight-bold">
                  {
                    `IDR ${getOrderDetail?.data?.totalTransaction?.toLocaleString()}`
                  }
                </Text>
              </Space>
            </Col>
            <Col xs={24} lg={12}>
              <Space direction="vertical" size={0}>
                <Text className="light-color fs-14">{t('myOrders.expired1.text4')}</Text>
                <Text className="dark-color fs-14 font-weight-bold">{getOrderDetail.data.expiredDate}</Text>
              </Space>
            </Col>
            <Col xs={24} lg={12}>
              <Space direction="vertical" size={0}>
                <Text className="light-color fs-14">{t('myOrders.expired1.text5')}</Text>
                <Text className="dark-color fs-14 font-weight-bold">Bank Transfer</Text>
              </Space>
            </Col>
            <Col xs={24} lg={12}>
              <Space direction="vertical" size={0}>
                <Text className="fs-14 light-color">{t('myOrders.noOrder')}</Text>
                <Text className="fs-14 dark-color font-weight-bold">{getOrderDetail.data.xpertTransactionNumber}</Text>
              </Space>
            </Col>
            {
              getOrderDetail?.data.xpertType === 'SERVICE' ? 
                <Col span={24} lg={12}>
                  <Space direction='vertical' size={0}>
                    <Text className='light-color fs-14'>{t('xpert.paymentSchema')}</Text>
                    <Space direction='horizontal' size={8}>
                      <Text className="dark-color fs-14 font-weight-bold">
                        {
                          getOrderDetail.data.paymentOption === "ONE_TIME_PAYMENT" ? t('xpert.oneTime')
                          : getOrderDetail.data.paymentOption === "INSTALLMENT" ? t('xpert.installment')
                          : getOrderDetail.data.paymentOption === "LOAN" ? t('xpert.loan')
                          : null
                        }
                      </Text>
                      <ExclamationCircleOutlined onClick={showModalPayment} style={{ cursor: 'pointer', color: '#08c' }} theme="outlined" />
                    </Space>
                  </Space>
                </Col> 
              : null
            }
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withTranslation()(expired)