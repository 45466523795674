import AudioNo4 from "../../../assets/audio/Audio-Number-4.mp3";
import AudioNo5 from "../../../assets/audio/Audio-Number-5.mp3";
import AudioNo8 from "../../../assets/audio/Audio-Number-8.mp3";
import AudioNo9 from "../../../assets/audio/Audio-Number-9.mp3";
import AudioNo10 from "../../../assets/audio/Audio-Number-10.mp3";

export const questionListening = [
  {
    question: "Why can't the woman call the bus company?",
    audio: AudioNo4,
    answers: [
      {
        value: 0,
        answerLabel: "Because she doesn't have the number",
      },
      {
        value: 0,
        answerLabel: "Because she doesn't want to do it",
      },
      {
        value: 2,
        answerLabel: "Because the man has more time than her",
      },
    ],
  },
  {
    question: "How does Beth feel about the security cameras?",
    audio: AudioNo5,
    answers: [
      {
        value: 0,
        answerLabel: "She likes it because it's anywhere",
      },
      {
        value: 0,
        answerLabel: "She hates it because it makes her like a criminal",
      },
      {
        value: 2,
        answerLabel: "She likes it because it can help the police",
      },
    ],
  },
  {
    question: "Do you think the brand love to work with the comedian?",
    audio: AudioNo8,
    answers: [
      {
        value: 0,
        answerLabel: "Maybe, they will try it first",
      },
      {
        value: 2,
        answerLabel: "No, they don't",
      },
      {
        value: 0,
        answerLabel: "Yes, they do",
      },
    ],
  },
  {
    question: "What makes the lectures start 30 minutes earlier?",
    audio: AudioNo9,
    answers: [
      {
        value: 0,
        answerLabel: "to finish lectures earlier every day",
      },
      {
        value: 0,
        answerLabel: "to eliminate lunch time for students",
      },
      {
        value: 2,
        answerLabel: "to have longer lunch time",
      },
    ],
  },
  {
    question: "What made her shift her career into teaching?",
    audio: AudioNo10,
    answers: [
      {
        value: 0,
        answerLabel: "It's her dream being a teacher since she was younger",
      },
      {
        value: 2,
        answerLabel: "She needed money",
      },
      {
        value: 0,
        answerLabel: "She didn't like acting anymore",
      },
    ],
  },
];
