import React, { Component } from 'react';
import { Row, Col, Card, Space, Typography, Radio } from 'antd';
import Header from '../Header';
import { withTranslation } from 'react-i18next';
import CButton from '../../../../../../../components/Button';

const { Text } = Typography;

export class Q21_25 extends Component {
  render() {
    const { questionStructure,handleSelect, handleNext, result, t, handleBack } = this.props
    const arrQuestion = questionStructure.filter((res,i) => i > 4 && i < 10)
    return (
      <React.Fragment>
        <Row className='mt-16'>
          <Col lg={{ span: 20, offset: 2 }} xxl={{ span: 18, offset: 3 }}>
            <Header {...this.props} />
            <Card className="card-body-question mt-16 mb-16 border-radius-8">
              <Row gutter={[64,32]}>
                <Col span={24}>
                  <Space className="text-align-center width-100" direction="vertical" size={0}>
                    {/* <Text className='dark-color fs-20' style={{ fontWeight: 'normal', fontStyle: 'italic' }} strong>{t('pathway.structure')}</Text> */}
                    <Text className='dark-color fs-20' style={{ fontWeight: 'normal', fontStyle: 'italic' }} strong>Structure</Text>
                    <Text className='dark-color fs-16' style={{ fontWeight: '600', fontStyle: 'italic' }} strong>Section 2 - Part 1</Text>
                  </Space>
                </Col>
                {
                  arrQuestion.map((item, i) =>
                    <Col key={i} lg={12} xs={24}>
                      <Space align="start">
                        <Text>{i+20+1}.</Text>
                        <Space className='width-100' direction="vertical">
                          <Space className='width-100' direction='vertical' size={0}>
                            <Text className='noselect' style={{ fontSize: 15, fontWeight: 'normal', fontStyle: 'italic', color: '#FF0000'}}>Choose the correct option to complete the sentence.</Text>
                            <Text className='noselect' style={{ fontSize: 15, fontWeight: 'normal', color: '#FF0000' }}>Pilih jawaban yang tepat untuk melengkapi kalimat berikut.</Text>
                            <Text className='noselect dark-color fs-15' strong>Sentence:</Text>
                            <Text className='noselect dark-color' style={{ fontSize: 15, fontWeight: 'normal' }}>{item.question}</Text>
                            <Text className='noselect dark-color fs-15' strong>Answer:</Text>
                          </Space>
                          <Radio.Group className='width-100' value={result?.find(item => item.no === i+20+1)?.selected} onChange={(e) => handleSelect(e,i+20+1)}>
                            <Space className='width-100' direction="vertical">
                              {
                                item.answers.map((res,iAnswer) =>
                                  <Radio className='noselect' key={iAnswer} result={res.value} value={iAnswer} style={{ whiteSpace: 'break-spaces', display: 'flex', alignItems: 'center' }}>{res.answerLabel}</Radio>
                                )
                              }
                            </Space>
                          </Radio.Group>
                        </Space>
                      </Space>
                    </Col>
                  )
                }
                <Col span={24} >
                  <Space className='float-right'>  
                    <CButton type="link" size="large" action={() => handleBack(2,2)} title={t('pathway.back')} icon="LeftOutlined"/>
                    <CButton type="primary-icon" size="large" block action={() => handleNext(2,4,result?.length>=25)} title={t('pathway.next')} icon="RightOutlined"/>
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default withTranslation()(Q21_25)
