import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';
import { Row, Col, Space, Typography, Image, Card } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/id';
import PaymentSuccess from '../../../../../../assets/img/candidate/payment-success.png';
import CButton from '../../../../../../components/Button';

const { Text } = Typography;

export class CandidateXpertPaymentSuccess extends Component {
  componentDidMount(){
    window.scrollTo(0, 0)
  }

  render() {
    const { location: { state: { xpertTransactionNumber, orderDateString } }, t } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>Xpert - {t('xpert.success')}</title>
        </Helmet>
        <Row className="pr-16 pl-16 mt-16">
          <Col lg={{ span: 12, offset: 6 }} xxl={{ span: 8, offset: 8 }}>
            <Card className="border-radius-8 mb-16">
              <Space className="width-100 text-align-center" direction="vertical">
                <Image
                  src={PaymentSuccess}
                  preview={false}
                  height={300}
                  width={'100%'}
                  style={{ objectFit: 'contain' }}
                />
                <Text className="info-color fs-16"><CheckCircleFilled /> {t('xpert.registration')}</Text>
                {/* <Text className="dark-color fs-14">{t('xpert.search')}</Text> */}
              </Space>
              <Row className="mt-16">
                <Col xs={24} lg={12}>
                    <Space direction="vertical" size={0}>
                      <Text className="dark-color fs-14">{t('xpert.text6')}</Text>
                      <Text className="dark-color fs-18 font-weight-bold">{xpertTransactionNumber}</Text>
                    </Space>
                </Col>

                <Col xs={24} lg={12}>
                  <Space direction="vertical" size={0}>
                    <Text className="dark-color fs-14">{t('xpert.date2')}</Text>
                    <Text className="dark-color fs-18 font-weight-bold">{`${moment(orderDateString, 'DDMMYYYY HHmmss').format('LL')} | ${moment(orderDateString, 'DDMMYYYY HHmmss').format('LT')}`}</Text>
                  </Space>
                </Col>
              </Row>
              <Row className="mt-16" gutter={32}>
                <Col span={24}>
                  <Link to={'/candidate/my-orders'}>
                    <CButton className="box-shadow text-align-center width-100" type="primary" size="large" style={{ border: '#4272b9', background: '#4273b9' }} title={t('xpert.text8')}/>
                  </Link>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default withTranslation()(CandidateXpertPaymentSuccess)
