import React from 'react'
import { Tabs, Grid } from 'antd';
import { withTranslation } from 'react-i18next';

const { TabPane } = Tabs;

const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export function menuListOrder(props) {
  const { lg } = GetBreakPoint()
  const { handleMenu, activeKey, t } = props
  
  return (
    <React.Fragment>
      <Tabs onTabClick={(e) => handleMenu(e)} className="dark-color" activeKey={activeKey} tabPosition={lg ? "right" : "top"} size='small'>
        <TabPane tab={t('myOrders.waiting')} key="WAITING_FOR_PAYMENT" />
        <TabPane tab={t('myOrders.confirm')} key="WAITING_FOR_CONFIRM" />
        <TabPane tab={t('myOrders.paymentConfirm')} key="PAYMENT_CONFIRM" />
        <TabPane tab={t('myOrders.expired')} key="EXPIRED" />
        <TabPane tab={t('myOrders.rejected')} key="REJECTED" />
        <TabPane tab={t('myOrders.cancel')} key="CANCELED" />
      </Tabs>
    </React.Fragment>
  )
}

export default withTranslation()(menuListOrder)
