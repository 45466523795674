import { API } from '../../../../config'
import { errorHandler } from '../../auth/errorAction'

export const unmountIndexStudyProgram = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_STUDY_PROGRAM'})
}

export const indexStudyProgram = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_STUDY_PROGRAM' })
  
  API.GET('/study_program/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_STUDY_PROGRAM_SUCCESS', 
      payload: {
        data: response.data,
        meta: response.meta
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_STUDY_PROGRAM_FAILED' }), 
    ))
  })
}