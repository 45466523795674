import React from 'react';
import moment from 'moment';
import { Row, Col, Typography, Form, Select, AutoComplete, DatePicker, Spin } from 'antd';
import CButton from '../../../../../components/Button';
const { Text } = Typography
const { Option } = Select;

export default function FormStudi(props) {
  const { formRef, f5, onFocusInstitution, onSearchInstitution, onFocusFieldOfStudy, getFieldOfStudy, onSearchFieldOfStudy, getInstitution, handlePrev, onFinish } = props
  return (
    <React.Fragment>
      <Row className="text-align-center mb-16 mt-16">
        <Col span={24}>
          <Text className="primary-color fs-17">Melanjutkan Studi</Text>
        </Col>
      </Row>

      <Form 
        ref={formRef}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          sourceCost: f5?.sourceCost ? f5.sourceCost : null,
          nextStudyProgram: f5?.nextStudyProgram ? f5.nextStudyProgram : null,
          nextInstitution: f5?.nextInstitution ? f5.nextInstitution : null,
          nextJoinInstitutionDate: f5?.nextJoinInstitutionDate ? moment(f5.nextJoinInstitutionDat) : null
        }}
      >
        <Row gutter={16} justify="space-between" align="bottom">
          <Col xs={24} lg={12}>
            <Form.Item 
              name="sourceCost"
              label="Sumber biaya yang digunakan dalam studi lanjut:"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <Select 
                className="width-100" 
                placeholder="Pilih Jawaban"
              >
                <Option key="1" value="1">Biaya Sendiri</Option>
                <Option key="2" value="2">Beasiswa</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item 
              name="nextStudyProgram"
              label="Nama Program Studi:"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
                { min: 5, message: 'Fields of Study must be at least 5 characters' },
                { max: 100, message: '100 characters only' },
              ]}
            >
              <AutoComplete
                className="width-100"
                onFocus={onFocusFieldOfStudy}
                onSearch={onSearchFieldOfStudy}
                notFoundContent={getFieldOfStudy.loading ? <Spin size="small" /> : null}
                filterOption={false}
              >
                {
                  getFieldOfStudy?.data?.map((item, i) => (
                    <AutoComplete.Option key={i} value={item.name}>
                      {item.name}
                    </AutoComplete.Option>
                  ))
                }
              </AutoComplete>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} justify="space-between" align="bottom">
          <Col xs={24} lg={12}>
            <Form.Item 
              name="nextInstitution"
              label="Nama Perguruan Tinggi tempat melanjutkan studi:"
              validateFirst
              rules={[
                { required: true, message: 'this field is mandatory!' },
                { min: 3, message: 'At least 3 characters' },
                { max: 100, message: '100 characters only' },
              ]}
            >
              <AutoComplete
                className="width-100"
                onFocus={onFocusInstitution}
                onSearch={onSearchInstitution}
                notFoundContent={getInstitution.loading ? <Spin size="small" /> : null}
                filterOption={false}
              >
                {
                  getInstitution?.data?.map((item, i) => (
                    <AutoComplete.Option key={i} value={item.name}>
                      {item.name}
                    </AutoComplete.Option>
                  ))
                }
              </AutoComplete>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item 
              name="nextJoinInstitutionDate" 
              label="Tanggal masuk studi lanjutan:"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <DatePicker inputReadOnly className="width-100" defaultPickerValue={moment(`${moment().year()}0101`)} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <CButton className="width-100" type="primary" ghost action={handlePrev} title="Sebelumnya"/>
            </Col>
            <Col span={12}>
              <CButton className="width-100" htmlType="submit" type="primary" title="Selanjutnya"/>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </React.Fragment>
  )
}
