import React from 'react'
import { Row, Col, Space, Typography, Image, Card, Breadcrumb, Progress, Form, Input, Radio, Select, Spin } from 'antd';
import Question from '../../../../../../assets/img/candidate/question.png';
import Time from '../../../../../../assets/img/candidate/time.png';
import CButton from '../../../../../../components/Button';

const { Text } = Typography;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

export default function index(props) {
  const { percent, total, placement, getJobIndustries, getJobPosition, getJobDestination, onFinish, onChangePlacement, onChangeIndustry, onFocusPosition, onSearchPosition, onChangePosition, onFocusDestination, onSearchDestination, onChangeDestination, onChangeReason } = props
  
  return (
    <React.Fragment>
      <Row className="mt-8">
          <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
            <Breadcrumb className="mb-16">
              <Breadcrumb.Item>Career Pathway</Breadcrumb.Item>
              <Breadcrumb.Item>Area Karir</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="mb-8">
              <Col className="text-align-center" span={12}>
                <Space direction="vertical">
                  <Image
                    width={'100%'}
                    height={50}
                    src={Question}
                    preview={false}
                    style={{ objectFit: 'contain' }}
                  />
                  <Text className="info-color" style={{ fontSize: 14, fontWeight: 'normal' }}>5 Pertanyaan</Text>
                </Space>
              </Col>
              <Col className="text-align-center" span={12}>
                <Space direction="vertical">
                  <Image
                    width={'100%'}
                    height={50}
                    src={Time}
                    preview={false}
                    style={{ objectFit: 'contain' }}
                  />
                  <Text className="info-color" style={{ fontSize: 14, fontWeight: 'normal' }}>3 Menit Estimasi Waktu</Text>
                </Space>
              </Col>
            </Row>
            <Card className="border-radius-6 mb-16" bordered={false} style={{ backgroundColor: '#f8f8f8' }}>
              <Space className="width-100" direction="vertical" size={16}>
                <Row className="text-align-center">
                  <Col span={24}>
                    <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal' }}>Petunjuk Pengerjaan</Text>
                  </Col>
                </Row>
                <Space className="width-100" direction="vertical" size={-4}>
                  <Text className="light-color" style={{ fontSize: 14, fontWeight: 'normal' }}>- Silahkan jawab pertanyaan yang telah diberikan sesuai dengan kondisimu saat ini.</Text>
                  <Text className="light-color" style={{ fontSize: 14, fontWeight: 'normal' }}>- Tidak ada jawaban benar atau salah</Text>
                </Space>
                <Space className="width-100" direction="vertical" size={-4}>
                  <Text className="dark-color" style={{ fontSize: 14, fontWeight: 'normal' }}>Pertanyaan-pertanyaan dibawah bertujuan untuk media pengenalan dirimu.</Text>
                  <Text className="dark-color" style={{ fontSize: 14, fontWeight: 'normal' }}>Pengolahan data akan dilakukan secara bijak dan bertanggung jawab.</Text>
                </Space>
              </Space>
            </Card>
            <Progress 
              strokeColor={{
                '0%': '#4272b9',
                '100%': '#32ab6d',
              }} 
              percent={percent} 
              showInfo={false} 
            />
            <Text className="dark-color" style={{ fontSize: 14, fontWeight: 'normal' }}>{`${total}/5 Pertanyaan selesai`}</Text>
            
            <Form
              className="mt-16"
              layout="vertical"
              onFinish={onFinish}
            >
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item 
                    name="placementType"
                    label="Tipe Penempatan yang Diminati"
                    rules={[{ required: true, message: 'This field is mandatory!' }]}
                    initialValue="INTERNATIONAL"
                  >
                    <Radio.Group onChange={onChangePlacement}>
                      <Radio value="INTERNATIONAL">Internasional</Radio>
                      <Radio value="DOMESTIC">Domestik</Radio>
                      <Radio value="BOTH">Keduanya</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="listJobIndustry"
                    label="Bidang Karier yang Diminati"
                    validateFirst
                    rules={[
                      { required: true, message: 'This field is mandatory!' },
                      { 
                        validator: (rule, value, callback) => {
                          if (value) {
                            if (value.length > 3) {
                              callback("Maximum 3 options selected");
                            } else if (value.length <= 3) {
                              callback();
                            }
                          }
                          return;
                        }
                      }
                    ]}
                  >
                    <Select 
                      mode="multiple"
                      allowClear 
                      showSearch
                      onChange={onChangeIndustry}
                      placeholder="Please select job industries"
                    >
                      {
                        getJobIndustries?.data?.map((item) => 
                          <Option key={item.id} value={item.title}>{item.title}</Option>
                        )
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item 
                    name="listJobPosition" 
                    label="Posisi Pekerjaan"
                    validateFirst
                    rules={[
                      { required: true, message: 'This field is mandatory!' },
                      { 
                        validator: (rule, value, callback) => {
                          if (value) {
                            if (value.length > 3) {
                              callback("Maximum 3 options selected");
                            } else if (value.length <= 3) {
                              callback();
                            }
                          }
                          return;
                        }
                      }
                    ]}
                  >
                    <Select
                      mode="tags"
                      allowClear 
                      onFocus={onFocusPosition}
                      onSearch={onSearchPosition}
                      onChange={onChangePosition}
                      notFoundContent={getJobPosition?.loading ? <Spin size="small" /> : null}
                      filterOption={false}
                      placeholder="Type your desired job positions"
                    >
                      {
                        getJobPosition?.data?.map((res) => (
                          <Option key={res.id} value={res.title}>
                            {res.title}
                          </Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item 
                    name="listJobDestination" 
                    label="Kota dan Negara Penempatan"
                    validateFirst
                    rules={[
                      { required: true, message: 'This field is mandatory!' },
                      { 
                        validator: (rule, value, callback) => {
                          if (value) {
                            if (value.length > 3) {
                              callback("Maximum 3 options selected");
                            } else if (value.length <= 3) {
                              callback();
                            }
                          }
                          return;
                        }
                      }
                    ]}
                  >
                    <Select 
                      mode="multiple"
                      allowClear 
                      showSearch
                      onFocus={onFocusDestination}
                      onSearch={onSearchDestination}
                      onChange={onChangeDestination}
                      notFoundContent={getJobDestination?.loading ? <Spin size="small" /> : null}
                      placeholder="Type your desired job destinations"
                    >
                      {
                        placement === 'BOTH' ?
                          getJobDestination?.data?.map((item, i) => 
                            <OptGroup key={i} label={item.name}>
                              {
                                item?.listDestination?.map((res) => 
                                  <Option key={res.id} value={res.name}>{res.name}</Option>
                                )
                              }
                            </OptGroup>
                          )
                        :
                          getJobDestination?.data?.map(item => 
                            // <Option key={item.id} value={`${item.name}, ${item.countryName}`}>{`${item.name}, ${item.countryName}`}</Option>
                            <Option key={item.id} value={item.name}>{item.name}</Option>
                            )
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item 
                    name="reason" 
                    label="Alasan Pemilihan Karier"
                    rules={[
                      { max: 500, message: '500 characters only' },
                    ]}
                  >
                    <TextArea rows={3} onChange={onChangeReason} placeholder="Sebagai lompatan karir dan menjadi salah satu mimpi saya untuk bisa go-INTERNATIONAL." />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <CButton className="width-100" type="primary" htmlType="submit" title="Submit"/>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
    </React.Fragment>
  )
}