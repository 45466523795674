const LOAD_DEPARTURE           = 'LOAD_DEPARTURE'
const LOAD_DEPARTURE_SUCCESS   = 'LOAD_DEPARTURE_SUCCESS'
const LOAD_DEPARTURE_FAILED    = 'LOAD_DEPARTURE_FAILED'
const UNMOUNT_DEPARTURE        = 'UNMOUNT_DEPARTURE'
const initialState = {
  loading: true,
  data: null,
  message: null,
}
const departureReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_DEPARTURE:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_DEPARTURE_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_DEPARTURE_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_DEPARTURE:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}
export default departureReducer;