import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Info from './Info';
import Q8_10 from './Q8-10';
// import Q52_53 from './Q52-53';
// import Q54_56 from './Q54-56';

export class Section3 extends Component {
  render() {
    const { isSection, isPart } = this.props

    return (
      <React.Fragment>
        {/* Section 3 INFO */}
        {isSection === 3 && isPart === 0 ? <Info {...this.props} /> : null}
        {/* Section 3 Q 8 - 10 */}
        {isSection === 3 && isPart === 1 ? <Q8_10 {...this.props} /> : null}
      </React.Fragment>
    )
  }
}
export default withTranslation()(Section3)




