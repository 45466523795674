import React from 'react';
import { Form, Space, Select, Typography, Row, Col, Input, DatePicker, Spin, Upload, Button, Checkbox, Divider } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import CButton from '../../../../../../components/Button';

const { Text } = Typography;
const { TextArea } = Input

export default function Document(props) {

  const { 
    t,
    state: {
      disabledDoc,
      documentFile,
      documentCurrent,
      // isDocumentDate,
      allValues
    },
    onChangeDocumentType,
    handleUploadDocument,
    handleRemoveDocument,
    onChangeDocChecked,
    onChangeDocStartDate,
    onChangeDocEndDate,
    getDocumentTypes,
    getProfile
  } = props

  const isDocNeed = getProfile.data.listDocument.filter(item => item.documentName === 'Ijazah' || item.documentName === 'Surat Tanda Registrasi Keperawatan')

  return (
    <React.Fragment>
      <Form.List
        name="listDocument"
        style={{ width: '100%' }}
        initialValue={allValues?.listDocument?.length > 0 ? allValues?.listDocument : [""]}
      > 
        {(fields, {add}) => (
          <>
            {fields.map((field) => (
              <React.Fragment key={field.key}>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Row className="mb-min-8" gutter={16}>
                    <Col className="width-100" span={12}>
                      <Space size={4}>
                        <Text className="fs-12" type="secondary">{t('profile.document.documentType')}</Text>
                      </Space>
                      <Form.Item 
                        {...field}
                        name={[field.name, 'documentTypeId']}
                      >
                        <Select
                          size="small"
                          loading={getDocumentTypes?.loading}
                          notFoundContent={getDocumentTypes.loading ? <Spin size="small" /> : null}
                          onChange={(e, item) => onChangeDocumentType(e, item, field.name)}
                        >
                          {
                            getDocumentTypes?.data && getDocumentTypes?.data?.map(item => (
                              item.name === 'Ijazah' || item.name === 'Surat Tanda Registrasi Keperawatan' ?
                                isDocNeed?.length === 0 || isDocNeed?.find(res => res.documentName !== item.name) ?
                                  <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                  </Select.Option>
                                : null
                              : null
                            ))
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className="width-100" span={12}>
                      <Text className="fs-12" type="secondary">{t('profile.document.documentNumber')}</Text>
                      <Form.Item 
                        {...field}
                        name={[field.name, 'documentNumber']}
                        rules={[
                          { max: 50, message: t('max',{number:50}) },
                        ]}
                      >
                        <Input size="small" disabled={disabledDoc[field.name]} />
                      </Form.Item>
                    </Col>
                    <Col className="width-100 mt-min-16" span={24}>
                      <Form.Item 
                        {...field}
                        name={[field.name, 'documentCurrent']}
                      >
                        <Checkbox className="fs-12" onChange={(e) => onChangeDocChecked(e, field.name)} checked={documentCurrent[field.name]} disabled={disabledDoc[field.name]}>{t('profile.document.checkbox')}</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col className="width-100 mt-min-16" span={12}>
                      <Text className="fs-12" type="secondary">{t('profile.document.issueDate')}</Text>
                      <Form.Item 
                        {...field}
                        name={[field.name, 'documentIssueDate']}
                      >
                        <DatePicker 
                          size="small"
                          picker="month" 
                          style={{ width: '100%' }}
                          onChange={onChangeDocStartDate} 
                          disabled={disabledDoc[field.name]}
                          placeholder={t('profile.education.form.selectYear')}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} className="width-100 mt-min-16 ">
                      <Text className="fs-12" type="secondary">{t('profile.document.expirationDate')}</Text>
                      <Form.Item 
                        {...field}
                        name={[field.name, 'documentExpirationDate']}
                        // validateStatus={isDocumentDate && !documentCurrent ? "error" : null}
                        // help={isDocumentDate && !documentCurrent ? t('validationExpDate') : null}
                      >
                        {
                          documentCurrent[field.name] ?
                            <Text className="fs-12" type="secondary">{t('profile.document.noExp')}</Text>
                          :
                            <DatePicker 
                              size="small"
                              picker="month" 
                              style={{ width: '100%' }}
                              onChange={onChangeDocEndDate} 
                              disabled={disabledDoc[field.name]}
                              placeholder={t('profile.education.form.selectYear')}
                            />
                        }
                      </Form.Item>
                    </Col>
                    <Col className="width-100 mt-min-8" span={24}>
                      <Form.Item 
                        {...field}
                        name={[field.name, 'documentFile']}
                      >
                        <Upload 
                          {...handleUploadDocument(field.name)}
                          onRemove={handleRemoveDocument}
                          listType="picture" 
                          maxCount={1}
                          showUploadList={true}
                          fileList={
                            documentFile[field.name]?.uid ?
                              [
                                {
                                  uid: documentFile[field.name]?.uid,
                                  name: documentFile[field.name]?.name,
                                  status: 'done'
                                }
                              ]
                            :
                              []
                          }
                        >
                          <Button size="small" icon={<UploadOutlined />} disabled={disabledDoc[field.name]}><Text className="fs-12">{t('profile.document.buttonUpload')}</Text></Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="width-100 " span={24}>
                      <Text className="fs-12" type="secondary">{t('profile.document.remarks')}</Text>
                      <Form.Item 
                        {...field}
                        name={[field.name, 'documentRemark']}
                        validateFirst
                        rules={[
                          { max: 200, message: t('max',{number:200}) }
                        ]}
                      >
                        <TextArea rows={3} disabled={disabledDoc[field.name]} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <Divider className='mt-0 mb-16' />
              </React.Fragment>
            ))}
            {
              fields.length === 1 ?
                isDocNeed?.length === 0 ?
                  <Row>
                    <Col className="width-100 " span={24}>
                      <Form.Item >
                        <CButton type="dashed" block icon="PlusOutlined" action={() => add()} title={t('profile.document.addDocument')}/>
                      </Form.Item>
                    </Col>
                  </Row>
                : null
              : null
            }
          </>
        )}
      </Form.List>
    </React.Fragment>
  )
}