import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Grid, Space, Typography, Image } from 'antd';
import Cookie from 'js-cookie';
import EmptyLogo from '../../assets/img/EmptyLogo.png';

const { useBreakpoint } = Grid;
const { Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

function GetTranslation() {
  const t = useTranslation()
  return t;
}

export default function option(props) {
  const { md } = GetBreakPoint()
  const { getDataLanding } = props
  const { t } = GetTranslation()

  return (
    <React.Fragment>
      <Row style={ md ? { backgroundColor: '#fff', paddingBottom: 64 } : { backgroundColor: '#fff', paddingBottom: 8 }}>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          {
            md ?
              <Space className="width-100 mb-64" direction="vertical" size={-8} align="center">
                <Text className="dark-color" style={{ fontSize: 28, fontWeight: 'bold' }}>{t('landingPage.titleCompany')}</Text>
              </Space>
            :
              <Space className="width-100 text-align-center mb-32" direction="vertical" size={-8} align="center">
                <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'bold' }}>{t('landingPage.titleCompany')}</Text>
              </Space>
          }
          <Row gutter={[32, 16]}>
            {
              getDataLanding?.data?.listCompany.map((item, i) =>
                <Col className="text-align-center" key={i} xs={12} sm={8} md={6} lg={4}>
                  <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}>
                    <Image
                      className="cursor-pointer"
                      src={item.companyLogoUrl ? item.companyLogoUrl : EmptyLogo}
                      preview={false}
                      width={'100%'}
                      height={150}
                      style={{ objectFit: 'contain' }}
                    />
                  </Link>
                </Col>
              )
            }
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}
