const LOAD_STEPS           = 'LOAD_STEPS'
const LOAD_STEPS_SUCCESS   = 'LOAD_STEPS_SUCCESS'
const LOAD_STEPS_FAILED    = 'LOAD_STEPS_FAILED'
const UNMOUNT_STEPS        = 'UNMOUNT_STEPS'
const initialState = {
  loading: true,
  data: null,
  message: null,
}
const stepsReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_STEPS:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_STEPS_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_STEPS_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_STEPS:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}
export default stepsReducer;