import React from 'react';
import FeedbackImage from '../../../../assets/img/candidate/feedback.png';
import { Row, Col, Image, Space, Typography, Form, Input } from 'antd';
import CButton from '../../../../components/Button';
const { Text } = Typography
const { TextArea } = Input;

export default function FormFeedback(props) {
  const { formRef, handlePrev, onFinish, submitLoading } = props
  return (
    <React.Fragment>
      <Row className="text-align-center mb-16 mt-16">
        <Col span={24}>
          <Space direction='vertical'>
            <Image
              src={FeedbackImage}
              preview={false}
              width={'30%'}
              style={{ objectFit: 'containt' }}
            />
            <Text className="primary-color fs-17">Terima kasih sudah menyempatkan waktu untuk mengisi!</Text>
            <Text>Kami senang untuk mendengar bagaimana saran dan kritik terahadap Universitas Binawan:</Text>
          </Space>
        </Col>
      </Row>

      <Form 
        ref={formRef}
        layout="vertical"
        onFinish={onFinish}
      >
        <Row className="mb-16">
          <Col span={24}>
            <Form.Item 
              name="suggestion"
              validateFirst
              rules={[
                { max: 250, message: '250 characters only' },
              ]}
            >
              <TextArea rows={3} placeholder="Sampaikan saran dan kritikmu" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <CButton className="width-100" type="primary" ghost action={handlePrev} title="Sebelumnya"/>
            </Col>
            <Col span={12}>
              <CButton className="width-100" htmlType="submit" type="primary" loading={submitLoading} title="Kirim Jawaban"/>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </React.Fragment>
  )
}
