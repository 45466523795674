import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Row, Col, Card, Typography, Select, Checkbox, Tooltip, Space, DatePicker, AutoComplete, Upload, Spin, Image, Grid } from 'antd';
import { InfoCircleTwoTone, UploadOutlined } from '@ant-design/icons';
import './ChannelRegisterAviation.css';
// import ImgLogoBicca from '../../../assets/img/register/logo-bicca.png'
import ImgLogoBicca from '../../../assets/img/register/logo-bicca-v2.png'
import { withTranslation } from 'react-i18next';
import CButton from '../../../components/Button';

const { Option } = Select;
const { Text } = Typography;
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export function ChannelRegisterAviation(props) {
  const { 
    visible,
    agree,
    formRef,
    submitLoading,
    listDocument,
    onFinishAviation,
    onCheckEmail,
    onFocusPassword,  
    onChangeCode,
    onCheckMobile,
    onChangeCheck,
    handleNationality,
    handleCountry,
    handleProvince,
    handleCity,
    onFocusInstitution,
    onSearchInstitution,
    onChangeEducationLevel,
    onFocusFieldOfStudy,
    onSearchFieldOfStudy,
    // handleRadio,
    handleUpload,
    handleRemove,
    getCountry,
    getProvince,
    getCity,
    getInstitution,
    getEducationLevel,
    getFieldOfStudy,
    t
  } = props
  const { lg } = GetBreakPoint()

  const prefixSelector = (
    <Form.Item name="code" noStyle>
      <Select className="prefix-selector" onChange={onChangeCode} showSearch>
        {
          getCountry?.data?.map(item =>
            <Option text={item.callingCode} key={item.id} value={`(+${item.callingCode}) ${item.name}`}>{`(+${item.callingCode}) ${item.name}`}</Option>
            )
          }
      </Select>
    </Form.Item>
  )
  
  return (
    <React.Fragment>
      {
        lg ?
          <Row className='background-container'>
            <Col className='text-align-center mt-64' xs={24} lg={12}>
              <Space direction='vertical' size={18} style={{ position: 'fixed', marginLeft: -200 }}>
                <Image
                  width={'100%'}
                  height={160}
                  src={ImgLogoBicca}
                  alt='BICCA'
                  preview={false}
                  style={{ objectFit: 'contain' }}
                />
                <Space direction='vertical' size={-16} style={{ font: 'Poppins', fontStyle: 'italic', fontSize: 26, fontWeight: 100, letterSpacing: 2 }}>
                  <Text style={{ color: '#FFFFFF' }}>World Class Academy for World Class Airlines</Text>
                </Space>
                <Space direction='vertical' size={-16} style={{ font: 'Poppins', fontStyle: 'italic',  fontSize: 24, fontWeight: 'bold', letterSpacing: 1 }}>
                  <Text style={{ color: '#FFFFFF' }}>Sign Up Now</Text>
                </Space>
              </Space>
            </Col>
            <Col xs={24} lg={12}>
              <Card className="card-aviation-register border-radius-20" style={{ marginLeft: 64 }} bodyStyle={{ padding: 0 }}>
                <Form 
                  ref={formRef}
                  initialValues={{ 
                    code: '(+62) Indonesia'
                  }}
                  onFinish={onFinishAviation}
                  layout='vertical'
                >
                  <Row gutter={[16, 0]}>
                    <Col className="fs-28" xs={24} sm={24} md={24}>
                      <Text type="secondary">Register</Text>
                    </Col>
  
                    <Col className="fs-13 mb-16" xs={24} sm={24} md={24}>
                      <Text type="secondary">Give us some of your information to get free access to jobshub.</Text>
                    </Col>
  
                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="firstName" 
                        validateFirst
                        rules={[
                          { required: true, message: 'First name is required!' },
                          { pattern: /^[a-zA-Z ]*$/, message: 'Can not use special characters and numbers.' },
                          { min: 3, message: 'First Name must be at least 3 characters long.' },
                          { max: 25, message: 'First Name must not be more than 25 characters long.' },
                        ]}
                      >
                        <Input placeholder="First Name" />
                      </Form.Item>
                    </Col>
  
                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="lastName" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Last name is required!' },
                          { pattern: /^[a-zA-Z ]*$/, message: 'Can not use special characters and numbers.' },
                          { min: 3, message: 'Last Name must be at least 3 characters long.' },
                          { max: 25, message: 'Last Name must not be more than 25 characters long.' },
                        ]}
                      >
                        <Input placeholder="Last Name" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                      <Form.Item 
                        className="mb-16"
                        name="mobile"
                        validateFirst
                        rules={[
                          { required: true, message: 'Phone Number is required!' },
                          { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },
                          { min: 7, max: 13, message: 'Your mobile number is invalid' },
                        ]}
                      >
                        <Input onBlur={(e) => onCheckMobile(e.target.value)} addonBefore={prefixSelector} placeholder="Type your primary phone number" />
                      </Form.Item>
                    </Col> 
                    
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item 
                        className="mb-16"
                        name="email"
                        validateFirst
                        rules={[
                          { required: true, message: 'Email is required!' },
                          { type: 'email', message: 'Your email address is invalid!' },
                          // Hanya untuk di production jika di staging akan di matikan
                          // { pattern:  /.+@(gmail|yahoo)\.com$/, message: 'Preferred using Gmail or Yahoo' }
                        ]}
                      >
                        <Input type="email" onBlur={(e) => onCheckEmail(e.target.value)} placeholder="Valid Email Address" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={11}>
                      <Form.Item 
                        className="mb-16"
                        name="password"
                        validateFirst
                        hasFeedback
                        rules={[
                          { required: true, message: 'Password is required!' },
                          { pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, message: 'Password does not meet requirements' },
                          { min: 8, message: 'Password must be at least 8 characters long.' },
                        ]}
                      >
                        <Input.Password onFocus={() => onFocusPassword(true)} onBlur={() => onFocusPassword(false)} placeholder="Password" />
                      </Form.Item>
                    </Col>
                    
                    <Col xs={22} sm={22} md={11}>
                      <Form.Item 
                        className="mb-16"
                        name="confirmPassword" 
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                          { required: true, message: 'Confirm Password is required!' },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Passwords do not match!'));
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder="Confirm Password" />
                      </Form.Item>
                    </Col>

                    <Col className="pt-4 txt-color pl-0" xs={2} sm={2} md={2}>
                      <Tooltip visible={visible} placement="right" title="Password with at least 8 characters, 1 CAPITAL and 1 number">
                        <InfoCircleTwoTone onMouseLeave={() => onFocusPassword(false)} onMouseEnter={() => onFocusPassword(true)} twoToneColor="#0076de" />
                      </Tooltip>
                    </Col>

                    <Col className="fs-13 mb-16" span={24}>
                      <Text type="secondary">Personal Information</Text>
                    </Col>

                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="nationalityId" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Nationality is required!' },
                        ]}
                      >
                        <Select
                          className="width-100"
                          showSearch
                          onChange={handleNationality}
                          placeholder="Nationality"
                        >
                          {
                            getCountry?.data?.map((item) =>
                              <Option key={item.id} value={item.nationality}>{item.nationality}</Option>
                            )
                          }
                        </Select>
                        
                      </Form.Item>
                    </Col>
  
                    <Col xs={24} sm={12} >
                      <Form.Item 
                        className="mb-16"
                        name="identificationId"
                        validateFirst
                        rules={[
                          { required: true, message: 'ID Card Number (KTP) is required!' },
                          { pattern: /^[0-9]*$/, message: 'Numbers Only' },
                          { len: 16, message: t('len',{number:16}) },
                        ]}
                      >
                        <Input placeholder='ID Card Number(KTP)'/>
                      </Form.Item>
                    </Col>
  
                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="placeOfBirth" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Place Of Birth is required!' },
                          { pattern: /^[a-zA-Z ]*$/, message: 'Alphabetical only' },
                          { max: 50, message: '50 characters only' },
                        ]}
                      >
                        <Input placeholder="Place Of Birth" />
                      </Form.Item>
                    </Col>
  
                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="birthdate" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Date of Birth is required!' },
                        ]}
                      >
                        <DatePicker 
                          placeholder='Date of birth'
                          style={{ width: '100%' }} 
                          format="DD/MM/YYYY" 
                          defaultPickerValue={moment('2000-01-01')} 
                        />
                      </Form.Item>
                    </Col>
  
                    {/* <Col xs={24} sm={8}>
                      <Form.Item 
                        className="mb-16"
                        name="age" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Age is mandatory' },
                          { pattern: /^[0-9]*$/, message: 'Numbers Only' },
                          { min: 1, message: 'Minimum 1 digit number' },
                          { max: 3, message: 'Maximum 3 digit number' },
                        ]}
                      >
                        <Input placeholder="Age" />
                      </Form.Item>
                    </Col> */}
  
                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="height" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Height is required!' },
                          { pattern: /^[0-9]*$/, message: 'Numbers Only' },
                          { min: 2, message: 'At least 2 digits' },
                          { max: 3, message: '3 digits only' },
                        ]}
                      >
                        <Input placeholder="Height (cm)" />
                      </Form.Item>
                    </Col>
  
                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="weight" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Weight is required!' },
                          { pattern: /^[0-9]*$/, message: 'Numbers Only' },
                          { min: 2, message: 'At least 2 digits' },
                          { max: 3, message: '3 digits only' },
                        ]}
                      >
                        <Input placeholder="Weight (kg)" />
                      </Form.Item>
                    </Col>
  
                    {/* <Col xs={24} sm={8}>
                      <Form.Item 
                        className="mb-16"
                        name="armReach" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Arm Reach is required!' },
                          { pattern: /^[0-9]*$/, message: 'Numbers Only' },
                          { min: 2, message: 'At least 2 digits' },
                          { max: 3, message: '3 digits only' },
                        ]}
                      >
                        <Input placeholder="Arm reach (cm)" />
                      </Form.Item>
                    </Col> */}

                    <Col className="fs-13 mb-16" span={24}>
                      <Text type="secondary">Address</Text>
                    </Col>
  
                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="countryId" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Country is required!' },
                        ]}
                      >
                        <Select
                          className="width-100"
                          showSearch
                          onChange={handleCountry}
                          placeholder="Country"
                        >
                          {
                            getCountry?.data?.map((item) =>
                              <Option key={item.id} value={item.name}>{item.name}</Option>
                            )
                          }
                        </Select>
                        
                      </Form.Item>
                    </Col>
  
                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="provinceId" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Province is required!' },
                        ]}
                      >
                        <Select
                          className="width-100"
                          showSearch
                          onChange={handleProvince}
                          placeholder="Province"
                        >
                          {
                            getProvince?.data?.map((item) =>
                              <Option key={item.id} value={item.name}>{item.name}</Option>
                            )
                          }
                        </Select>
                      </Form.Item>
                    </Col>
  
                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="cityId" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Cureent location is required!' },
                        ]}
                      >
                        <Select
                          className="width-100"
                          showSearch
                          onChange={handleCity}
                          placeholder="City"
                        >
                          {
                            getCity?.data?.map((item) =>
                              <Option key={item.id} value={item.name}>{item.name}</Option>
                            )
                          }
                        </Select>
                      </Form.Item>
                    </Col>
  
                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="zipCode" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Postal Code is required!' },
                          { pattern: /^[0-9]*$/, message: 'Numbers Only' },
                          { max: 10, message: '10 digits only' },
                        ]}
                      >
                        <Input placeholder="Postal Code" />
                      </Form.Item>
                    </Col>
  
                    <Col xs={24} sm={24}>
                      <Form.Item 
                        className="mb-16"
                        name="address" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Street Address is required!' },
                          { max: 75, message: '75 characters only' },
                        ]}
                      >
                        <Input placeholder="Street Address" />
                      </Form.Item>
                    </Col>

                    <Col className="fs-13 mb-16" span={24}>
                      <Text type="secondary">Education</Text>
                    </Col>
  
                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="educationName" 
                        validateFirst
                        rules={[
                          { required: true, message: 'School/University is required!' },
                          // { pattern: /^[0-9a-zA-Z ]*$/, message: 'Special characters are not allowed' },
                          { min: 5, message: 'School must be at least 5 characters' },
                          { max: 100, message: '100 characters only' },
                        ]}
                      >
                        <AutoComplete
                          className="width-100"
                          onFocus={onFocusInstitution}
                          onSearch={onSearchInstitution}
                          notFoundContent={getInstitution?.loading ? <Spin size="small" /> : null}
                          filterOption={false}
                          placeholder="School/University"
                        >
                          {
                            getInstitution?.data?.map((item, i) => (
                              <AutoComplete.Option key={i} value={item.name}>
                                {item.name}
                              </AutoComplete.Option>
                            ))
                          }
                        </AutoComplete>
                      </Form.Item>
                    </Col>
  
                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="educationLevelId" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Education Level is required!' }
                        ]}
                      >
                        <Select 
                          className="width-100 border-radius-6"
                          onChange={onChangeEducationLevel}
                          placeholder="Education Level"
                        >
                          {
                            getEducationLevel?.data?.map(item => 
                              <Option key={item.id} value={item.id}>{item.name}</Option>
                            )
                          }
                        </Select> 
                      </Form.Item>
                    </Col>
  
                    <Col xs={24} sm={24}>
                      <Form.Item 
                        className="mb-16"
                        name="fieldOfStudy" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Field Of Study is required!' },
                          { min: 5, message: 'Fields of Study must be at least 5 characters' },
                          { max: 100, message: '100 characters only' },
                        ]}
                      >
                        <AutoComplete
                          className="width-100"
                          onFocus={onFocusFieldOfStudy}
                          onSearch={onSearchFieldOfStudy}
                          notFoundContent={getFieldOfStudy?.loading ? <Spin size="small" /> : null}
                          filterOption={false}
                          placeholder="Field of Study , ex: Nursing"
                        >
                          {
                            getFieldOfStudy?.data?.map((item, i) => (
                              <AutoComplete.Option key={i} value={item.name}>
                                {item.name}
                              </AutoComplete.Option>
                            ))
                          }
                        </AutoComplete>
                      </Form.Item>
                    </Col>
                    
                    {/* <Col xs={24} sm={24} md={11}>
                      <Form.Item 
                        className="mb-16"
                        name="password"
                        validateFirst
                        hasFeedback
                        rules={[
                          { required: true, message: 'Password is required!' },
                          { pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, message: 'Password does not meet requirements' },
                          { min: 8, message: 'Password must be at least 8 characters long.' },
                        ]}
                      >
                        <Input.Password onFocus={() => onFocusPassword(true)} onBlur={() => onFocusPassword(false)} placeholder="Password" />
                      </Form.Item>
                    </Col>
                    
                    <Col xs={22} sm={22} md={11}>
                      <Form.Item 
                        className="mb-16"
                        name="confirmPassword" 
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                          { required: true, message: 'Confirm Password is required!' },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Passwords do not match!'));
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder="Confirm Password" />
                      </Form.Item>
                    </Col> */}
  
                    
                    
                    {/* <Col xs={24} sm={24} md={24}>
                      <Form.Item 
                        className="mb-16"
                        name="mobile"
                        validateFirst
                        rules={[
                          { required: true, message: 'Phone Number is required!' },
                          { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },
                          { min: 7, max: 13, message: 'Your mobile number is invalid' },
                        ]}
                      >
                        <Input onBlur={(e) => onCheckMobile(e.target.value)} addonBefore={prefixSelector} placeholder="Type your primary phone number" />
                      </Form.Item>
                    </Col>   */}
  
                    {/* <Col className='mb-12' span={24}>
                      <Text strong>Family History</Text>
                    </Col> */}
                    
                    {/* <Col span={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Heart Trouble</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="heartTrouble"
                        rules={[
                          { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Radio.Group onChange={(e) => handleRadio(e, 0)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col> */}
  
                    {/* <Col span={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Diabetes</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="diabetes"
                        rules={[
                          { required: true, message: 'This field is mandatory!' }
                        ]}
                        
                      >
                        <Radio.Group onChange={(e) => handleRadio(e, 1)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col> */}
                    
                    {/* <Col span={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Convulsion</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="convulsion"
                        rules={[
                          { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Radio.Group onChange={(e) => handleRadio(e, 3)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col> */}
  
                    {/* <Col span={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Cancer</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="cancer"
                        rules={[
                          { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Radio.Group onChange={(e) => handleRadio(e, 4)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col> */}
                    
                    {/* <Col span={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Tubercolosis</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="tubercolosis"
                        rules={[
                          { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Radio.Group onChange={(e) => handleRadio(e, 2)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col> */}
  
                    {/* <Col span={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Allergies</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="allergies"
                        rules={[
                          { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Radio.Group onChange={(e) => handleRadio(e, 5)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col> */}
  
                    {/* <Col span={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Commited Suicide</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="commitedSuicide"
                        rules={[
                          { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Radio.Group onChange={(e) => handleRadio(e, 6)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col> */}
  
                    {/* <Col xs={24} sm={24} md={24}>
                      <Space className='mb-8' direction='vertical' size={0}>
                        <Text>ID Card (KTP)</Text>
                      </Space>
                      <Form.Item 
                        className='mb-16'
                        name="ktp"
                        rules={[
                          { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Upload 
                          {...handleUpload(4)}
                          maxCount={1}
                          showUploadList={true}
                          fileList={
                            listDocument.find(item => item.documentTypeId === 4) ?
                              [{
                                uid: listDocument.find(item => item.documentTypeId === 4).file.uid,
                                name: listDocument.find(item => item.documentTypeId === 4).file.name,
                                status: 'done'
                              }]
                            : []
                          }
                          onRemove={handleRemove}
                        >
                          <Button icon={<UploadOutlined />} block>
                            Upload Document
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col> */}
  
                    {/* <Col xs={24} sm={24} md={24}>
                      <Space className='mb-8' direction='vertical' size={0}>
                        <Text>Ijazah (Last Education)</Text>
                      </Space>
                      <Form.Item 
                        className='mb-16'
                        name="ijazah"
                        rules={[
                          // { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Upload 
                          {...handleUpload(19)}
                          maxCount={1}
                          showUploadList={true}
                          fileList={
                            listDocument.find(item => item.documentTypeId === 19) ?
                              [{
                                uid: listDocument.find(item => item.documentTypeId === 19).file.uid,
                                name: listDocument.find(item => item.documentTypeId === 19).file.name,
                                status: 'done'
                              }]
                            : []
                          }
                          onRemove={handleRemove}
                        >
                          <Button icon={<UploadOutlined />} block>
                            Upload Document
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col> */}
                    
                    {/* <Col xs={24} sm={24} md={24}>
                      <Space className='mb-8' direction='vertical' size={0}>
                        <Text>Pas Photo</Text>
                        <Text type='secondary' style={{ fontSize: 11 }}>• Size : 4x6</Text>
                        <Text type='secondary' style={{ fontSize: 11 }}>• Background Color : White (80%)</Text>
                        <Text type='secondary' style={{ fontSize: 11 }}>• Terlihat gigi</Text>
                      </Space>
                      <Form.Item 
                        className='mb-8'
                        name="pasPhoto"
                        rules={[
                          // { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Upload 
                          {...handleUpload(27)}
                          maxCount={1}
                          showUploadList={true}
                          fileList={
                            listDocument.find(item => item.documentTypeId === 27) ?
                              [{
                                uid: listDocument.find(item => item.documentTypeId === 27).file.uid,
                                name: listDocument.find(item => item.documentTypeId === 27).file.name,
                                status: 'done'
                              }]
                            : []
                          }
                          onRemove={handleRemove}
                        >
                          <Button icon={<UploadOutlined />} block>
                            Upload Document
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col> */}

                    <Col className='mb-12' span={24}>
                      <Text strong>Pas Photo</Text>
                    </Col>
  
                    <Col xs={24} sm={24} md={24}>
                      <Space className='mb-8' direction='vertical' size={0}>
                        <Text type='secondary' style={{ fontSize: 11 }}>• Size : 4x6</Text>
                        <Text type='secondary' style={{ fontSize: 11 }}>• Background Color : White (80%)</Text>
                        <Text type='secondary' style={{ fontSize: 11 }}>• Tidak terlihat gigi</Text>
                      </Space>
                      <Form.Item 
                        className='mb-16'
                        name="pasPhoto2"
                        rules={[
                          { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Upload 
                          {...handleUpload(28)}
                          maxCount={1}
                          showUploadList={true}
                          fileList={
                            listDocument.find(item => item.documentTypeId === 28) ?
                              [{
                                uid: listDocument.find(item => item.documentTypeId === 28).file.uid,
                                name: listDocument.find(item => item.documentTypeId === 28).file.name,
                                status: 'done'
                              }]
                            : []
                          }
                          onRemove={handleRemove}
                        >
                          <Button icon={<UploadOutlined />} block>
                            Upload Document
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
  
                    {/* <Col xs={24} sm={24} md={24}>
                      <Space className='mb-8' direction='vertical' size={0}>
                      <Text>Photo</Text>
                        <Text type='secondary' style={{ fontSize: 11 }}>• Foto seluruh badan</Text>
                        <Text type='secondary' style={{ fontSize: 11 }}>• Menghadap ke depan</Text>
                        <Text type='secondary' style={{ fontSize: 11 }}>• Terlihat dari ujung badan hingga ujung kaki</Text>
                      </Space>
                      <Form.Item 
                        className='mb-16'
                        name="photo"
                        rules={[
                          // { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Upload 
                          {...handleUpload(26)}
                          maxCount={1}
                          showUploadList={true}
                          fileList={
                            listDocument.find(item => item.documentTypeId === 26) ?
                              [{
                                uid: listDocument.find(item => item.documentTypeId === 26).file.uid,
                                name: listDocument.find(item => item.documentTypeId === 26).file.name,
                                status: 'done'
                              }]
                            : []
                          }
                          onRemove={handleRemove}
                        >
                          <Button icon={<UploadOutlined />} block>
                            Upload Document
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col> */}
  
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item 
                        className="mb-16"
                        name="agree"
                        rules={[
                          { 
                            validator(rule, value, callback){
                              if(!agree){
                                callback('Please check this box to proceed your account')
                              }else{
                                callback()
                              }
                            }
                          }
                        ]}
                      >
                        <Checkbox className="fs-11 txt-color" onChange={onChangeCheck} checked={agree}>
                          By creating an account you agree to the 
                          <Link className="info-color" to="/terms-and-conditions" target="_blank"> terms and conditions</Link> and 
                          <Link className="info-color" to="/privacy-policy" target="_blank"> privacy policy </Link>
                          {/* and  */}
                          {/* <Link className="info-color" to="#"> end-user license agreement</Link> */}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item className="mb-8">
                        <CButton type="primary" htmlType="submit" className="login-form-button" loading={submitLoading} block title="Create Account"/>
                      </Form.Item>
                    </Col>
                    
                    <Col xs={24} sm={24} md={24}>
                      <Text className="fs-11" type="secondary">Already have an account? <Link className="info-color" to="/login">Go to login</Link> </Text>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        :
          <Row className='mt-64'>
            <Col className='background-container-mobile text-align-center' xs={24} lg={12} style={{ paddingTop: 20 }}>
              <Space direction='vertical' size={0} style={{ paddingBottom: 16 }}>
                <Space size={32}>
                  <Image
                    width={'100%'}
                    height={80}
                    src={ImgLogoBicca}
                    alt='BICCA'
                    preview={false}
                    style={{ objectFit: 'contain' }}
                  />
                </Space>
                <Space direction='vertical' size={-16} style={{ font: 'Poppins', fontStyle: 'italic', fontSize: 15, fontWeight: 100, letterSpacing: 1 }}>
                  <Text style={{ color: '#FFFFFF' }}>World Class Academy for World Class Airlines</Text>
                </Space>
                <Space direction='vertical' size={-16} style={{ font: 'Poppins', fontStyle: 'italic',  fontSize: 18, fontWeight: 'bold', paddingTop: 75 }}>
                  <Text style={{ color: '#FFFFFF' }}>Sign Up Now</Text>
                </Space>
              </Space>
            </Col>
            <Col xs={24} lg={12}>
              <Card className="card-aviation-register border-radius-20" bodyStyle={{ padding: 0 }}>
                <Form 
                  ref={formRef}
                  initialValues={{ 
                    code: '(+62) Indonesia'
                  }}
                  onFinish={onFinishAviation}
                  layout='vertical'
                >
                  <Row gutter={[16, 0]}>
                    <Col className="fs-28" xs={24} sm={24} md={24}>
                      <Text type="secondary">Register</Text>
                    </Col>

                    <Col className="fs-13 mb-16" xs={24} sm={24} md={24}>
                      <Text type="secondary">Give us some of your information to get free access to jobshub.</Text>
                    </Col>

                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="firstName" 
                        validateFirst
                        rules={[
                          { required: true, message: 'First name is required!' },
                          { pattern: /^[a-zA-Z ]*$/, message: 'Can not use special characters and numbers.' },
                          { min: 3, message: 'First Name must be at least 3 characters long.' },
                          { max: 25, message: 'First Name must not be more than 25 characters long.' },
                        ]}
                      >
                        <Input placeholder="First Name" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="lastName" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Last name is required!' },
                          { pattern: /^[a-zA-Z ]*$/, message: 'Can not use special characters and numbers.' },
                          { min: 3, message: 'Last Name must be at least 3 characters long.' },
                          { max: 25, message: 'Last Name must not be more than 25 characters long.' },
                        ]}
                      >
                        <Input placeholder="Last Name" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                      <Form.Item 
                        className="mb-16"
                        name="mobile"
                        validateFirst
                        rules={[
                          { required: true, message: 'Phone Number is required!' },
                          { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },
                          { min: 7, max: 13, message: 'Your mobile number is invalid' },
                        ]}
                      >
                        <Input onBlur={(e) => onCheckMobile(e.target.value)} addonBefore={prefixSelector} placeholder="Type your primary phone number" />
                      </Form.Item>
                    </Col> 
                    
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item 
                        className="mb-16"
                        name="email"
                        validateFirst
                        rules={[
                          { required: true, message: 'Email is required!' },
                          { type: 'email', message: 'Your email address is invalid!' },
                          // Hanya untuk di production jika di staging akan di matikan
                          // { pattern:  /.+@(gmail|yahoo)\.com$/, message: 'Preferred using Gmail or Yahoo' }
                        ]}
                      >
                        <Input type="email" onBlur={(e) => onCheckEmail(e.target.value)} placeholder="Valid Email Address" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={11}>
                      <Form.Item 
                        className="mb-16"
                        name="password"
                        validateFirst
                        hasFeedback
                        rules={[
                          { required: true, message: 'Password is required!' },
                          { pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, message: 'Password does not meet requirements' },
                          { min: 8, message: 'Password must be at least 8 characters long.' },
                        ]}
                      >
                        <Input.Password onFocus={() => onFocusPassword(true)} onBlur={() => onFocusPassword(false)} placeholder="Password" />
                      </Form.Item>
                    </Col>
                    
                    <Col xs={22} sm={22} md={11}>
                      <Form.Item 
                        className="mb-16"
                        name="confirmPassword" 
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                          { required: true, message: 'Confirm Password is required!' },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Passwords do not match!'));
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder="Confirm Password" />
                      </Form.Item>
                    </Col>

                    <Col className="pt-4 txt-color pl-0" xs={2} sm={2} md={2}>
                      <Tooltip visible={visible} placement="right" title="Password with at least 8 characters, 1 CAPITAL and 1 number">
                        <InfoCircleTwoTone onMouseLeave={() => onFocusPassword(false)} onMouseEnter={() => onFocusPassword(true)} twoToneColor="#0076de" />
                      </Tooltip>
                    </Col>

                    <Col className='mb-12' span={24}>
                      <Text type='secondary'>Personal Information</Text>
                    </Col>
                    
                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="nationalityId" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Nationality is required!' },
                        ]}
                      >
                        <Select
                          className="width-100"
                          showSearch
                          onChange={handleNationality}
                          placeholder="Nationality"
                        >
                          {
                            getCountry?.data?.map((item) =>
                              <Option key={item.id} value={item.nationality}>{item.nationality}</Option>
                            )
                          }
                        </Select>
                        
                      </Form.Item>
                    </Col>
                      

                    <Col xs={24} sm={24} md={24}>
                      <Form.Item 
                        className="mb-16"
                        name="identificationId"
                        validateFirst
                        rules={[
                          { required: true, message: 'ID Card Number (KTP) is required!' },
                          { pattern: /^[0-9]*$/, message: 'Numbers Only' },
                          { len: 16, message: t('len',{number:16}) },
                        ]}
                      >
                        <Input placeholder='ID Card Number(KTP)'/>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={8}>
                      <Form.Item 
                        className="mb-16"
                        name="placeOfBirth" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Place Of Birth is required!' },
                          { pattern: /^[a-zA-Z ]*$/, message: 'Alphabetical only' },
                          { max: 50, message: '50 characters only' },
                        ]}
                      >
                        <Input placeholder="Place Of Birth" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={8}>
                      <Form.Item 
                        className="mb-16"
                        name="birthdate" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Date of Birth is required!' },
                        ]}
                      >
                        <DatePicker 
                          placeholder='Date of birth'
                          style={{ width: '100%' }} 
                          format="DD/MM/YYYY" 
                          defaultPickerValue={moment('2000-01-01')} 
                        />
                      </Form.Item>
                    </Col>
    {/* 
                    <Col xs={24} sm={8}>
                      <Form.Item 
                        className="mb-16"
                        name="age" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Age is mandatory' },
                          { pattern: /^[0-9]*$/, message: 'Numbers Only' },
                          { min: 1, message: 'Minimum 1 digit number' },
                          { max: 3, message: 'Maximum 3 digit number' },
                        ]}
                      >
                        <Input placeholder="Age" />
                      </Form.Item>
                    </Col> */}

                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="height" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Height is required!' },
                          { pattern: /^[0-9]*$/, message: 'Numbers Only' },
                          { min: 2, message: 'At least 2 digits' },
                          { max: 3, message: '3 digits only' },
                        ]}
                      >
                        <Input placeholder="Height (cm)" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="weight" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Weight is required!' },
                          { pattern: /^[0-9]*$/, message: 'Numbers Only' },
                          { min: 2, message: 'At least 2 digits' },
                          { max: 3, message: '3 digits only' },
                        ]}
                      >
                        <Input placeholder="Weight (kg)" />
                      </Form.Item>
                    </Col>

                    {/* <Col xs={24} sm={8}>
                      <Form.Item 
                        className="mb-16"
                        name="armReach" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Arm Reach is required!' },
                          { pattern: /^[0-9]*$/, message: 'Numbers Only' },
                          { min: 2, message: 'At least 2 digits' },
                          { max: 3, message: '3 digits only' },
                        ]}
                      >
                        <Input placeholder="Arm reach (cm)" />
                      </Form.Item>
                    </Col> */}

                    <Col className='mb-12' span={24}>
                      <Text type='secondary'>Address</Text>
                    </Col>

                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="countryId" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Country is required!' },
                        ]}
                      >
                        <Select
                          className="width-100"
                          showSearch
                          onChange={handleCountry}
                          placeholder="Country"
                        >
                          {
                            getCountry?.data?.map((item) =>
                              <Option key={item.id} value={item.name}>{item.name}</Option>
                            )
                          }
                        </Select>
                        
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="provinceId" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Province is required!' },
                        ]}
                      >
                        <Select
                          className="width-100"
                          showSearch
                          onChange={handleProvince}
                          placeholder="Province"
                        >
                          {
                            getProvince?.data?.map((item) =>
                              <Option key={item.id} value={item.name}>{item.name}</Option>
                            )
                          }
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="cityId" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Cureent location is required!' },
                        ]}
                      >
                        <Select
                          className="width-100"
                          showSearch
                          onChange={handleCity}
                          placeholder="City"
                        >
                          {
                            getCity?.data?.map((item) =>
                              <Option key={item.id} value={item.name}>{item.name}</Option>
                            )
                          }
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="zipCode" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Postal Code is required!' },
                          { pattern: /^[0-9]*$/, message: 'Numbers Only' },
                          { max: 10, message: '10 digits only' },
                        ]}
                      >
                        <Input placeholder="Postal Code" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24}>
                      <Form.Item 
                        className="mb-16"
                        name="address" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Street Address is required!' },
                          { max: 75, message: '75 characters only' },
                        ]}
                      >
                        <Input placeholder="Street Address" />
                      </Form.Item>
                    </Col>

                    <Col className='mb-12' span={24}>
                      <Text type='secondary'>Education</Text>
                    </Col>

                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="educationName" 
                        validateFirst
                        rules={[
                          { required: true, message: 'School/University is required!' },
                          // { pattern: /^[0-9a-zA-Z ]*$/, message: 'Special characters are not allowed' },
                          { min: 5, message: 'School must be at least 5 characters' },
                          { max: 100, message: '100 characters only' },
                        ]}
                      >
                        <AutoComplete
                          className="width-100"
                          onFocus={onFocusInstitution}
                          onSearch={onSearchInstitution}
                          notFoundContent={getInstitution?.loading ? <Spin size="small" /> : null}
                          filterOption={false}
                          placeholder="School/University"
                        >
                          {
                            getInstitution?.data?.map((item, i) => (
                              <AutoComplete.Option key={i} value={item.name}>
                                {item.name}
                              </AutoComplete.Option>
                            ))
                          }
                        </AutoComplete>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                      <Form.Item 
                        className="mb-16"
                        name="educationLevelId" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Education Level is required!' }
                        ]}
                      >
                        <Select 
                          className="width-100 border-radius-6"
                          onChange={onChangeEducationLevel}
                          placeholder="Education Level"
                        >
                          {
                            getEducationLevel?.data?.map(item => 
                              <Option key={item.id} value={item.id}>{item.name}</Option>
                            )
                          }
                        </Select> 
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24}>
                      <Form.Item 
                        className="mb-16"
                        name="fieldOfStudy" 
                        validateFirst
                        rules={[
                          { required: true, message: 'Field Of Study is required!' },
                          { min: 5, message: 'Fields of Study must be at least 5 characters' },
                          { max: 100, message: '100 characters only' },
                        ]}
                      >
                        <AutoComplete
                          className="width-100"
                          onFocus={onFocusFieldOfStudy}
                          onSearch={onSearchFieldOfStudy}
                          notFoundContent={getFieldOfStudy?.loading ? <Spin size="small" /> : null}
                          filterOption={false}
                          placeholder="Field of Study , ex: Nursing"
                        >
                          {
                            getFieldOfStudy?.data?.map((item, i) => (
                              <AutoComplete.Option key={i} value={item.name}>
                                {item.name}
                              </AutoComplete.Option>
                            ))
                          }
                        </AutoComplete>
                      </Form.Item>
                    </Col>
                    
                    

                    {/* <Col className='mb-12' span={24}>
                      <Text strong>Family History</Text>
                    </Col> */}
                    
                    {/* <Col span={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Heart Trouble</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="heartTrouble"
                        rules={[
                          { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Radio.Group onChange={(e) => handleRadio(e, 0)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col> */}

                    {/* <Col span={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Diabetes</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="diabetes"
                        rules={[
                          { required: true, message: 'This field is mandatory!' }
                        ]}
                        
                      >
                        <Radio.Group onChange={(e) => handleRadio(e, 1)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col> */}
                    
                    {/* <Col span={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Convulsion</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="convulsion"
                        rules={[
                          { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Radio.Group onChange={(e) => handleRadio(e, 2)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col> */}

                    {/* <Col span={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Cancer</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="cancer"
                        rules={[
                          { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Radio.Group onChange={(e) => handleRadio(e, 3)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col> */}
                    
                    {/* <Col span={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Tubercolosis</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="tubercolosis"
                        rules={[
                          { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Radio.Group onChange={(e) => handleRadio(e, 4)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col> */}

                    {/* <Col span={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Allergies</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="allergies"
                        rules={[
                          { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Radio.Group onChange={(e) => handleRadio(e, 5)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col> */}

                    {/* <Col span={12}>
                      <Space direction='vertical' size={0}>
                        <Text>Commited Suicide</Text>
                      </Space>
                      <Form.Item 
                        className="mb-8"
                        name="commitedSuicide"
                        rules={[
                          { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Radio.Group onChange={(e) => handleRadio(e, 6)}>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col> */}

                    {/* <Col xs={24} sm={24} md={24}>
                      <Space className='mb-8' direction='vertical' size={0}>
                        <Text>ID Card (KTP)</Text>
                      </Space>
                      <Form.Item 
                        className='mb-16'
                        name="ktp"
                        rules={[
                          { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Upload 
                          {...handleUpload(4)}
                          maxCount={1}
                          showUploadList={true}
                          fileList={
                            listDocument.find(item => item.documentTypeId === 4) ?
                              [{
                                uid: listDocument.find(item => item.documentTypeId === 4).file.uid,
                                name: listDocument.find(item => item.documentTypeId === 4).file.name,
                                status: 'done'
                              }]
                            : []
                          }
                          onRemove={handleRemove}
                        >
                          <Button icon={<UploadOutlined />} block>
                            Upload Document
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col> */}

                    {/* <Col xs={24} sm={24} md={24}>
                      <Space className='mb-8' direction='vertical' size={0}>
                        <Text>Ijazah (Last Education)</Text>
                      </Space>
                      <Form.Item 
                        className='mb-16'
                        name="ijazah"
                        rules={[
                          // { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Upload 
                          {...handleUpload(19)}
                          maxCount={1}
                          showUploadList={true}
                          fileList={
                            listDocument.find(item => item.documentTypeId === 19) ?
                              [{
                                uid: listDocument.find(item => item.documentTypeId === 19).file.uid,
                                name: listDocument.find(item => item.documentTypeId === 19).file.name,
                                status: 'done'
                              }]
                            : []
                          }
                          onRemove={handleRemove}
                        >
                          <Button icon={<UploadOutlined />} block>
                            Upload Document
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col> */}
                    
                    {/* <Col xs={24} sm={24} md={24}>
                      <Space className='mb-8' direction='vertical' size={0}>
                        <Text>Pas Photo</Text>
                        <Text type='secondary' style={{ fontSize: 11 }}>• Size : 4x6</Text>
                        <Text type='secondary' style={{ fontSize: 11 }}>• Background Color : White (80%)</Text>
                        <Text type='secondary' style={{ fontSize: 11 }}>• Terlihat gigi</Text>
                      </Space>
                      <Form.Item 
                        className='mb-8'
                        name="pasPhoto"
                        rules={[
                          // { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Upload 
                          {...handleUpload(27)}
                          maxCount={1}
                          showUploadList={true}
                          fileList={
                            listDocument.find(item => item.documentTypeId === 27) ?
                              [{
                                uid: listDocument.find(item => item.documentTypeId === 27).file.uid,
                                name: listDocument.find(item => item.documentTypeId === 27).file.name,
                                status: 'done'
                              }]
                            : []
                          }
                          onRemove={handleRemove}
                        >
                          <Button icon={<UploadOutlined />} block>
                            Upload Document
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col> */}

                    <Col className='mb-12' span={24}>
                      <Text strong>Pas Photo</Text>
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                      <Space className='mb-8' direction='vertical' size={0}>
                        <Text type='secondary' style={{ fontSize: 11 }}>• Size : 4x6</Text>
                        <Text type='secondary' style={{ fontSize: 11 }}>• Background Color : White (80%)</Text>
                        <Text type='secondary' style={{ fontSize: 11 }}>• Tidak terlihat gigi</Text>
                      </Space>
                      <Form.Item 
                        className='mb-16'
                        name="pasPhoto2"
                        rules={[
                          { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Upload 
                          {...handleUpload(28)}
                          maxCount={1}
                          showUploadList={true}
                          fileList={
                            listDocument.find(item => item.documentTypeId === 28) ?
                              [{
                                uid: listDocument.find(item => item.documentTypeId === 28).file.uid,
                                name: listDocument.find(item => item.documentTypeId === 28).file.name,
                                status: 'done'
                              }]
                            : []
                          }
                          onRemove={handleRemove}
                        >
                          <Button icon={<UploadOutlined />} block>
                            Upload Document
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>

                    {/* <Col xs={24} sm={24} md={24}>
                      <Space className='mb-8' direction='vertical' size={0}>
                      <Text>Photo</Text>
                        <Text type='secondary' style={{ fontSize: 11 }}>• Foto seluruh badan</Text>
                        <Text type='secondary' style={{ fontSize: 11 }}>• Menghadap ke depan</Text>
                        <Text type='secondary' style={{ fontSize: 11 }}>• Terlihat dari ujung badan hingga ujung kaki</Text>
                      </Space>
                      <Form.Item 
                        className='mb-16'
                        name="photo"
                        rules={[
                          // { required: true, message: 'This field is mandatory!' }
                        ]}
                      >
                        <Upload 
                          {...handleUpload(26)}
                          maxCount={1}
                          showUploadList={true}
                          fileList={
                            listDocument.find(item => item.documentTypeId === 26) ?
                              [{
                                uid: listDocument.find(item => item.documentTypeId === 26).file.uid,
                                name: listDocument.find(item => item.documentTypeId === 26).file.name,
                                status: 'done'
                              }]
                            : []
                          }
                          onRemove={handleRemove}
                        >
                          <Button icon={<UploadOutlined />} block>
                            Upload Document
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col> */}

                    <Col xs={24} sm={24} md={24}>
                      <Form.Item 
                        className="mb-16"
                        name="agree"
                        rules={[
                          { 
                            validator(rule, value, callback){
                              if(!agree){
                                callback('Please check this box to proceed your account')
                              }else{
                                callback()
                              }
                            }
                          }
                        ]}
                      >
                        <Checkbox className="fs-11 txt-color" onChange={onChangeCheck} checked={agree}>
                          By creating an account you agree to the 
                          <Link className="info-color" to="/terms-and-conditions" target="_blank"> terms and conditions</Link> and 
                          <Link className="info-color" to="/privacy-policy" target="_blank"> privacy policy </Link>
                          {/* and  */}
                          {/* <Link className="info-color" to="#"> end-user license agreement</Link> */}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item className="mb-8">
                        <CButton type="primary" htmlType="submit" className="login-form-button" loading={submitLoading} block title="Create Account"/> 
                      </Form.Item>
                    </Col>
                    
                    <Col xs={24} sm={24} md={24}>
                      <Text className="fs-11" type="secondary">Already have an account? <Link className="info-color" to="/login">Go to login</Link> </Text>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
      }
    </React.Fragment>
  )
}

export default withTranslation()(ChannelRegisterAviation)