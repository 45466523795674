import Question46 from "../../../assets/img/reading/46.png";
import Question48 from "../../../assets/img/reading/48.png";
import Question52 from "../../../assets/img/reading/52.png";
import Question54 from "../../../assets/img/reading/54.png";
import Question57 from "../../../assets/img/reading/57.png";
import Question62 from "../../../assets/img/reading/62.png";

export const questionReadingCompre = [
  {
    problemsImgs: Question46,
    questions: [
      {
        question: "Answer of part (I): _____________",
        answers: [
          {
            value: 0,
            answerLabel: "populations",
          },
          {
            value: 2,
            answerLabel: "inhabitants",
          },
          {
            value: 0,
            answerLabel: "total",
          },
          {
            value: 0,
            answerLabel: "number",
          },
        ],
      },
      {
        question: "Answer of part (II): _____________",
        answers: [
          {
            value: 0,
            answerLabel: "made",
          },
          {
            value: 2,
            answerLabel: "meant",
          },
          {
            value: 0,
            answerLabel: "explained",
          },
          {
            value: 0,
            answerLabel: "showed",
          },
        ],
      },
    ],
  },
  {
    problemsImgs: Question48,
    questions: [
      {
        question: "What's the purpose of celebrating Chinese New Year?",
        answers: [
          {
            value: 0,
            answerLabel: "to have nation holiday",
          },
          {
            value: 2,
            answerLabel: "to worship and honour the spirits of elderly",
          },
          {
            value: 0,
            answerLabel: "to sweep away good luck",
          },
        ],
      },
      {
        question:
          'The word "preceding" in paragraph 2 is closest meaning to _________',
        answers: [
          {
            value: 2,
            answerLabel: "previous",
          },
          {
            value: 0,
            answerLabel: "after",
          },
          {
            value: 0,
            answerLabel: "since",
          },
        ],
      },
      {
        question: "When does Chinese New Year start?",
        answers: [
          {
            value: 0,
            answerLabel: "in February",
          },
          {
            value: 0,
            answerLabel: "in January",
          },
          {
            value: 2,
            answerLabel: "in January or February",
          },
        ],
      },
      {
        question: "Why do Chinese clean their house during Chinese New Year?",
        answers: [
          {
            value: 2,
            answerLabel: "to have good luck",
          },
          {
            value: 0,
            answerLabel: "to decorate it",
          },
          {
            value: 0,
            answerLabel: "to rise up the mood",
          },
        ],
      },
    ],
  },
  {
    problemsImgs: Question52,
    questions: [
      {
        question: "The shop was going to close because _______________",
        answers: [
          {
            value: 0,
            answerLabel: "of decision by the European Union",
          },
          {
            value: 2,
            answerLabel: "the owner thought he was too old to run the shop",
          },
          {
            value: 0,
            answerLabel: "the owner needed the money",
          },
        ],
      },
      {
        question:
          'The word "leisure" in paragraph 4 is closest meaning to _________',
        answers: [
          {
            value: 0,
            answerLabel: "restraint",
          },
          {
            value: 2,
            answerLabel: "recreation",
          },
          {
            value: 0,
            answerLabel: "restriction",
          },
        ],
      },
    ],
  },
  {
    problemsImgs: Question54,
    questions: [
      {
        question: "What's main idea of the article above?",
        answers: [
          {
            value: 0,
            answerLabel: "Moving the house on Sunday",
          },
          {
            value: 0,
            answerLabel: "Finding a house to rent",
          },
          {
            value: 2,
            answerLabel: "Invitation for reunion",
          },
        ],
      },
      {
        question: "What's the closest meaning of 'catch up' in line 6?",
        answers: [
          {
            value: 2,
            answerLabel: "meet",
          },
          {
            value: 0,
            answerLabel: "separate",
          },
          {
            value: 0,
            answerLabel: "leave",
          },
        ],
      },
      {
        question: "What's the closest meaning of 'strange' in line 7?",
        answers: [
          {
            value: 2,
            answerLabel: "bizarre",
          },
          {
            value: 0,
            answerLabel: "easy",
          },
          {
            value: 0,
            answerLabel: "different",
          },
        ],
      },
    ],
  },
  {
    problemsImgs: Question57,
    questions: [
      {
        question: "Sarah used to ________ around the world.",
        answers: [
          {
            value: 2,
            answerLabel: "relax on beaches",
          },
          {
            value: 0,
            answerLabel: "take her bike",
          },
          {
            value: 0,
            answerLabel: "perform in concert halls",
          },
        ],
      },
      {
        question: "Sarah is spending ______ at home than before.",
        answers: [
          {
            value: 0,
            answerLabel: "less time",
          },
          {
            value: 0,
            answerLabel: "about the same amount of time",
          },
          {
            value: 2,
            answerLabel: "more time",
          },
        ],
      },
      {
        question: "Sarah went on bike rides and found some nice _____",
        answers: [
          {
            value: 2,
            answerLabel: "cafes",
          },
          {
            value: 0,
            answerLabel: "parks",
          },
          {
            value: 0,
            answerLabel: "beaches",
          },
        ],
      },
      {
        question:
          "Sarah disliked British beaches because _______ beaches in other countries.",
        answers: [
          {
            value: 2,
            answerLabel: "the weather is worse than on",
          },
          {
            value: 0,
            answerLabel: "there are more people than on",
          },
          {
            value: 0,
            answerLabel: "they are not as beautiful as",
          },
        ],
      },
      {
        question:
          "Talking about relationships, Sarah ______ now than in the past.",
        answers: [
          {
            value: 0,
            answerLabel: "is kinder and more caring",
          },
          {
            value: 2,
            answerLabel: "appreciates normal people more",
          },
          {
            value: 0,
            answerLabel: "has deeper relationships",
          },
        ],
      },
    ],
  },
  {
    problemsImgs: Question62,
    questions: [
      {
        question: "What time does she finish checking her emails?",
        answers: [
          {
            value: 0,
            answerLabel: "06:00 a.m.",
          },
          {
            value: 2,
            answerLabel: "5:45 a.m.",
          },
          {
            value: 0,
            answerLabel: "5:30 a.m.",
          },
        ],
      },
      {
        question:
          "The word 'occasionally' in line 6 is closest meaning to _________",
        answers: [
          {
            value: 0,
            answerLabel: "seldom",
          },
          {
            value: 2,
            answerLabel: "sometimes",
          },
          {
            value: 0,
            answerLabel: "always",
          },
        ],
      },
      {
        question: "How often does Janet meditate?",
        answers: [
          {
            value: 0,
            answerLabel: "Once a day",
          },
          {
            value: 2,
            answerLabel: "Once or twice a day",
          },
          {
            value: 0,
            answerLabel: "Rarely",
          },
        ],
      },
      {
        question: "What is the last thing she does before going to sleep?",
        answers: [
          {
            value: 0,
            answerLabel: "She reads book",
          },
          {
            value: 0,
            answerLabel: "She checks the time",
          },
          {
            value: 2,
            answerLabel: "She sets her alarm",
          },
        ],
      },
    ],
  },
];
