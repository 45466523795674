import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Card, Typography, Modal, Form, Input, Space, Row, Col, Select, Grid, Divider, message } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { indexCountry, unmountIndexCountry } from '../../../../redux/actions/master/country/countryAction';
import { listProvinceByCountry, unmountlistProvinceByCountry } from '../../../../redux/actions/master/province/provinceAction';
import { listCityByProvince, unmountlistCityByProvince } from '../../../../redux/actions/master/city/cityAction';
import { subDistrictByCity, unmountSubDistrictByCity } from '../../../../redux/actions/master/subDistrict/subDistrictAction';
import { villageBySubDistrict, unmountVillageBySubDistrict } from '../../../../redux/actions/master/village/villageAction';
import { addAddress, updateAddress, deleteAddress } from '../../../../redux/actions/profile/addressAction';
import './Address.css';
import Cookie from "js-cookie";
import CButton from '../../../../components/Button';

const { useBreakpoint } = Grid;

const { Text } = Typography;
const { Option } = Select;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export const AddressForm = ({ t, data, visible, showModal, handleCancel, submitLoading, selectedCountry, formRef, onFinish, handleType, type, getCountry, handleCountry, getProvince, handleProvince, getCity, handleCity, getSubDistrict, handleSubDistrict, getVillage, handleVillage, dataEdit, handleDelete, deleteLoading, id, countryId }) => {
  const { md } = GetBreakPoint()

  return (
    <React.Fragment>
      <Card
        className="border-radius-6 box-shadow"
        title={t('profile.address.title')}
        bordered={false}
        headStyle={{ color: '#32ab6d', fontSize: 17 }}
        bodyStyle={data.listAddress.length > 0 ? {} : { textAlign: 'center', padding: 60 }}
        actions={[
          <Text className="primary-color" onClick={() => showModal(null)}>{t('profile.address.buttonAdd')}</Text>
        ]}
      >
        {
          data.listAddress.length > 0 ?
            data.listAddress.map((item, i) =>
              md ?
                <Row key={i} className="mb-16">
                  <Col span={20}>
                    <Text className="primary-color">{item.type === "OTHER" ? item.typeOther : item.type === "HOME" ? "Home" : "Work"}</Text>
                  </Col>
                  <Col span={4}>
                    <Text className="primary-color float-right cursor-pointer" onClick={() => (showModal(item.id))}><EditFilled /> Edit</Text>
                  </Col>
                  <Col span={12}>
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">{t('profile.personalInfo.country')}</Text>
                        <Text className="dark-color" strong>{item.countryName}</Text>
                      </Space>

                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">{t('profile.personalInfo.city')}</Text>
                        <Text className="dark-color" strong>{item.cityName}</Text>
                      </Space>

                      {
                        item.countryId === 99 ?
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12" type="secondary">{t('profile.personalInfo.village')}</Text>
                            <Text className="dark-color" strong>{item.villageName}</Text>
                          </Space>
                          :
                          null
                      }

                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">{t('profile.personalInfo.streetAddress')}</Text>
                        <Text className="dark-color" strong>{item.address}</Text>
                      </Space>
                    </Space>
                  </Col>
                  <Col span={12}>
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">{t('profile.personalInfo.province')}</Text>
                        <Text className="dark-color" strong>{item.provinceName}</Text>
                      </Space>
                      {
                        item.countryId === 99 ?
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12" type="secondary">{t('profile.personalInfo.subDistrict')}</Text>
                            <Text className="dark-color" strong>{item.subDistrictName}</Text>
                          </Space>
                          :
                          null
                      }
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">{t('profile.personalInfo.postalCode')}</Text>
                        <Text className="dark-color" strong>{item.zipCode}</Text>
                      </Space>


                    </Space>
                  </Col>
                  {
                    i + 1 !== data.listAddress.length ?
                      <Divider className='mb-0' />
                      : null
                  }
                </Row>
                :
                <Row key={i} className="mb-16">
                  <Col span={20}>
                    <Text type="secondary">{item.type === "OTHER" ? item.typeOther : item.type === "HOME" ? "Home" : "Work"}</Text>
                  </Col>
                  <Col span={4}>
                    <Text className="primary-color float-right cursor-pointer" onClick={() => (showModal(item.id))}><EditFilled /> Edit</Text>
                  </Col>
                  <Col span={24}>
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">{t('profile.personalInfo.country')}</Text>
                        <Text className="dark-color" strong>{item.countryName}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">{t('profile.personalInfo.province')}</Text>
                        <Text className="dark-color" strong>{item.provinceName}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">{t('profile.personalInfo.city')}</Text>
                        <Text className="dark-color" strong>{item.cityName}</Text>
                      </Space>
                      {
                        countryId === 99 ?
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12" type="secondary">{t('profile.personalInfo.subDistrict')}</Text>
                            <Text className="dark-color" strong>{item.subDistrictName}</Text>
                          </Space>
                          :
                          null
                      }
                      {
                        countryId === 99 ?
                          <Space direction="vertical" size={0}>
                            <Text className="fs-12" type="secondary">{t('profile.personalInfo.village')}</Text>
                            <Text className="dark-color" strong>{item.villageName}</Text>
                          </Space>
                          :
                          null
                      }
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">{t('profile.personalInfo.postalCode')}</Text>
                        <Text className="dark-color" strong>{item.zipCode}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">{t('profile.personalInfo.streetAddress')}</Text>
                        <Text className="dark-color" strong>{item.address}</Text>
                      </Space>
                    </Space>
                  </Col>
                  {
                    i + 1 !== data.listAddress.length ?
                      <Divider className='mb-0' />
                      : null
                  }
                </Row>
            )
            :
            <Text className="dark-color">{t('profile.address.textCard')}</Text>
        }
      </Card>

      <Modal
        title={t('profile.address.title')}
        visible={visible}
        onCancel={handleCancel}
        footer={false}
        bodyStyle={{ paddingBottom: 4 }}
        centered
        width={600}
        destroyOnClose
        maskClosable={false}
      >
        <Form
          ref={formRef}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            type: dataEdit?.type,
            typeOther: dataEdit?.typeOther,
            country: dataEdit?.countryName,
            province: dataEdit?.provinceName,
            city: dataEdit?.cityName,
            subDistrict: selectedCountry === 99 ? dataEdit?.subDistrictName : null,
            village: selectedCountry === 99 ? dataEdit?.villageName : null,
            zipCode: dataEdit?.zipCode,
            address: dataEdit?.address
          }}
        >
          <Row className="mb-min-8" gutter={8}>
            <Col span={12}>
              <Form.Item
                name="type"
                rules={[
                  { required: true, message: 'This is a required field' }
                ]}
              >
                <Select
                  className="width-100 border-radius-6"
                  // size="small"
                  placeholder="Jenis Alamat"
                  onChange={handleType}
                >
                  <Option key="1" value="HOME">Home</Option>
                  <Option key="2" value="WORK">Work</Option>
                  <Option key="3" value="OTHER">Others</Option>
                </Select>
              </Form.Item>
            </Col>
            {
              type === "OTHER" ?
                <Col span={12}>
                  <Form.Item
                    name="typeOther"
                    rules={[
                      { required: true, message: 'This is a required field' }
                    ]}
                  >
                    <Input size="small" placeholder="Contoh: Alamat Kost" />
                  </Form.Item>
                </Col>
                : null
            }
          </Row>
          <Row className="mb-min-8" gutter={8}>
            <Col span={12}>
              <Form.Item name="country">
                <Select
                  className="width-100"
                  // size="small"
                  showSearch
                  onChange={handleCountry}
                  placeholder={t('profile.personalInfo.country')}
                >
                  {
                    getCountry?.data?.map((item) =>
                      <Option key={item.id} value={item.name}>{item.name}</Option>
                    )
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="province">
                <Select
                  className="width-100"
                  // size="small"
                  showSearch
                  onChange={handleProvince}
                  placeholder={t('profile.personalInfo.province')}
                >
                  {
                    getProvince?.data?.map((item) =>
                      <Option key={item.id} value={item.name}>{item.name}</Option>
                    )
                  }
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row className="mb-min-8" gutter={8}>
            <Col span={12}>
              <Form.Item name="city">
                <Select
                  className="width-100"
                  size="small"
                  showSearch
                  onChange={handleCity}
                  placeholder={t('profile.personalInfo.city')}
                >
                  {
                    getCity?.data?.map((item) =>
                      <Option key={item.id} value={item.name}>{item.name}</Option>
                    )
                  }
                </Select>
              </Form.Item>
            </Col>
            {
              selectedCountry === 99 ?
                <Col span={12}>
                  <Form.Item name="subDistrict">
                    <Select
                      className="width-100"
                      // size="small"
                      showSearch
                      onChange={handleSubDistrict}
                      placeholder={t('profile.personalInfo.subDistrict')}
                    >
                      {
                        getSubDistrict?.data?.map((item) =>
                          <Option key={item.id} value={item.name}>{item.name}</Option>
                        )
                      }
                    </Select>
                  </Form.Item>
                </Col>
                :
                <Col span={12}>
                  <Form.Item
                    name="zipCode"
                    validateFirst
                    rules={[
                      { pattern: /^[0-9]*$/, message: t('0-9') },
                      { max: 10, message: t('max', { number: 10 }) },
                    ]}
                  >
                    <Input size="small" placeholder={t('profile.personalInfo.postalCode')} />
                  </Form.Item>
                </Col>
            }
          </Row>
          {
            selectedCountry === 99 ?
              <Row className="mb-min-8" gutter={8}>
                <Col span={12}>
                  <Form.Item name="village">
                    <Select
                      className="width-100"
                      // size="small"
                      showSearch
                      onChange={handleVillage}
                      placeholder={t('profile.personalInfo.village')}
                    >
                      {
                        getVillage?.data?.map((item) =>
                          <Option key={item.id} value={item.name}>{item.name}</Option>
                        )
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="zipCode"
                    validateFirst
                    rules={[
                      { pattern: /^[0-9]*$/, message: t('0-9') },
                      { max: 10, message: t('max', { number: 10 }) },
                    ]}
                  >
                    <Input placeholder={t('profile.personalInfo.postalCode')} />
                  </Form.Item>
                </Col>
              </Row>
              :
              null
          }
          <Row className="mb-min-8">
            <Col span={24}>
              <Form.Item
                name="address"
                validateFirst
                rules={[
                  { max: 75, message: t('max', { number: 75 }) },
                ]}
              >
                <Input placeholder={t('profile.personalInfo.streetAddress')} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            {
              id ?
                <React.Fragment>
                  <CButton className="float-left" action={handleDelete} type='danger-outline' loading={deleteLoading} danger title={t('profile.buttonDelete')}/>
                  <CButton className="float-right" htmlType="submit" type="primary" loading={submitLoading} title={t('profile.buttonSave')}/>
                </React.Fragment>
                :
                <Space className="float-right">
                  <CButton type="default" action={handleCancel} title={t('profile.buttonBack')}/>
                  <CButton htmlType="submit" type="primary" loading={submitLoading} title={t('profile.buttonSave')}/>
                </Space>
            }
            {/* <Space className="float-right">
              <Button onClick={handleCancel}>{t('profile.buttonBack')}</Button>
              <Button htmlType="submit" type="primary" loading={submitLoading}>{t('profile.buttonSave')}</Button>
            </Space> */}
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export class WorkExperience extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
      submitLoading: false,
      countryId: null,
      provinceId: null,
      cityId: null,
      subDistrictId: null,
      villageId: null,
      selectedCountry: null,
      type: null
    }
  }

  showModal = (id) => {
    const { data, actionGetCountry, actionGetProvince, actionGetCity, actionGetSubDistrict, actionGetVillage } = this.props
    const dataEdit = data.listAddress.filter(item => item.id === id).pop()

    this.setState({
      visible: true,
      id: id,
      dataEdit: dataEdit,
      selectedCountry: id ? dataEdit?.countryId : null,
      type: id ? dataEdit?.type : null,
    })

    if (dataEdit?.subDistrictId) {
      return (actionGetCountry(), actionGetProvince(dataEdit?.countryId), actionGetCity(dataEdit?.provinceId), actionGetSubDistrict(dataEdit?.cityId), actionGetVillage(dataEdit?.subDistrictId))
    } else if (dataEdit?.cityId) {
      return (actionGetCountry(), actionGetProvince(dataEdit?.countryId), actionGetCity(dataEdit?.provinceId), actionGetSubDistrict(dataEdit?.cityId))
    } else if (dataEdit?.provinceId) {
      return (actionGetCountry(), actionGetProvince(dataEdit?.countryId), actionGetCity(dataEdit?.provinceId))
    } else if (dataEdit?.countryId) {
      return (actionGetCountry(), actionGetProvince(dataEdit?.countryId))
    }
    return actionGetCountry()
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      current: false,
      isDate: false
    })
  };

  handleType = (value) => {
    this.formRef.current.setFieldsValue({ typeOther: null })
    this.setState({ type: value })
  }

  handleCountry = (value, option) => {
    const { provinceId } = this.state
    const optionSelected = Number(option.key)

    this.setState({
      countryId: optionSelected,
      selectedCountry: optionSelected,
    })

    if (provinceId !== 0) {
      this.setState({
        provinceId: null,
        cityId: null,
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        province: null,
        city: null,
        subDistrict: null,
        village: null,
        zipCode: null,
        address: null
      })
    }

    const { actionGetProvince } = this.props
    return actionGetProvince(option.key)
  }

  handleProvince = (value, option) => {
    const { cityId } = this.state
    const optionSelected = Number(option.key)

    this.setState({
      provinceId: optionSelected
    })

    if (cityId !== 0) {
      this.setState({
        cityId: null,
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        city: null,
        subDistrict: null,
        village: null,
        zipCode: null,
        address: null
      })
    }

    const { actionGetCity } = this.props
    return actionGetCity(option.key)
  }

  handleCity = (value, option) => {
    const { subDistrictId } = this.state
    const optionSelected = Number(option.key)

    this.setState({
      cityId: optionSelected
    })

    if (subDistrictId !== 0) {
      this.setState({
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        subDistrict: null,
        village: null,
        zipCode: null,
        address: null
      })
    }

    const { actionGetSubDistrict } = this.props
    return actionGetSubDistrict(option.key)
  }

  handleSubDistrict = (value, option) => {
    const { villageId } = this.state
    const optionSelected = Number(option.key)

    this.setState({
      subDistrictId: optionSelected
    })

    if (villageId !== 0) {
      this.setState({
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        village: null,
        zipCode: null,
        address: null
      })
    }

    const { actionGetVillage } = this.props
    return actionGetVillage(option.key)
  }

  handleVillage = (value, option) => {
    const optionSelected = Number(option.key)

    this.setState({
      villageId: optionSelected
    })

    this.formRef.current.setFieldsValue({
      zipCode: null,
      address: null
    })
  }

  handleDelete = () => {
    const { actionDeleteAddress, actionGetProfile } = this.props
    const { id } = this.state

    return this.setState({ deleteLoading: true }, () => {
      return actionDeleteAddress(id, response => {
        if (response.code === '1000') {
          return this.setState({ deleteLoading: false, visible: false }, () => {
            message.success(response.message)
            Cookie.set("activeElement", window.pageYOffset)
            return actionGetProfile()
          })
        } else {
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ deleteLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  onFinish = async (values) => {
    const { actionAddAddress, actionUpdateAddress, actionGetProfile } = this.props
    const { countryId, provinceId, cityId, subDistrictId, villageId, id } = this.state

    values.countryId = countryId
    values.provinceId = provinceId
    values.cityId = cityId
    values.subDistrictId = subDistrictId
    values.villageId = villageId
    values.id = id

    if (id) {
      return this.setState({ submitLoading: true }, () => {
        return actionUpdateAddress(values, response => {
          if (response.code === '1000') {
            return this.setState({ submitLoading: false, visible: false }, () => {
              message.success(response.message)
              Cookie.set("activeElement", window.pageYOffset)
              return actionGetProfile()
            })
          } else {
            message.error(response.message)
          }
        }, (err) => {
          return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
        })
      })
    } else {
      return this.setState({ submitLoading: true }, () => {
        return actionAddAddress(values, response => {
          if (response.code === '1000') {
            return this.setState({ submitLoading: false, visible: false }, () => {
              message.success(response.message)
              Cookie.set("activeElement", window.pageYOffset)
              return actionGetProfile()
            })
          } else {
            message.error(response.message)
          }
        }, (err) => {
          return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
        })
      })
    }
  }

  render() {
    const { visible, submitLoading, deleteLoading, id, dataEdit, selectedCountry, type } = this.state
    const { t, data, getCountry, getProvince, getCity, getSubDistrict, getVillage } = this.props

    return (
      <React.Fragment>
        <AddressForm
          t={t}
          data={data}
          visible={visible}
          showModal={(id) => this.showModal(id)}
          handleCancel={this.handleCancel}
          formRef={this.formRef}
          onFinish={this.onFinish}
          submitLoading={submitLoading}
          deleteLoading={deleteLoading}
          id={id}
          dataEdit={dataEdit}
          selectedCountry={selectedCountry}
          handleDelete={this.handleDelete}
          handleType={this.handleType}
          type={type}
          getCountry={getCountry}
          handleCountry={this.handleCountry}
          getProvince={getProvince}
          handleProvince={this.handleProvince}
          getCity={getCity}
          handleCity={this.handleCity}
          getSubDistrict={getSubDistrict}
          handleSubDistrict={this.handleSubDistrict}
          getVillage={getVillage}
          handleVillage={this.handleVillage}
        />
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountIndexCountry, unmountlistProvinceByCountry, unmountlistCityByProvince, unmountSubDistrictByCity, unmountVillageBySubDistrict } = this.props
    return (unmountIndexCountry(), unmountlistProvinceByCountry(), unmountlistCityByProvince(), unmountSubDistrictByCity(), unmountVillageBySubDistrict())
  }
}

const mapStateToProps = (state) => ({
  getCountry: state.countryReducer,
  getProvince: state.provinceReducer,
  getCity: state.cityReducer,
  getSubDistrict: state.subDistrictReducer,
  getVillage: state.villageReducer
})

const mapDispatchToProps = {
  actionGetCountry: indexCountry,
  unmountIndexCountry: unmountIndexCountry,
  actionGetProvince: listProvinceByCountry,
  unmountlistProvinceByCountry: unmountlistProvinceByCountry,
  actionGetCity: listCityByProvince,
  unmountlistCityByProvince: unmountlistCityByProvince,
  actionGetSubDistrict: subDistrictByCity,
  unmountSubDistrictByCity: unmountSubDistrictByCity,
  actionGetVillage: villageBySubDistrict,
  unmountVillageBySubDistrict: unmountVillageBySubDistrict,
  actionAddAddress: addAddress,
  actionUpdateAddress: updateAddress,
  actionDeleteAddress: deleteAddress
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(WorkExperience))