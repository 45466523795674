import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Layout, Row, Col, Space, Typography, Button, Grid, message } from 'antd';
import { setJobPreferences } from '../../../redux/actions/jobPreferences/jobPreferencesAction';
import '../JobPreferences.css';
const { Footer } = Layout;
const { Text } = Typography;
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

function GetHistory() {
  const history = useHistory()
  return history;
}

export const FooterPreference = (props) => {
  const { industriesSelected, positionSelected, destinationSelected } = props
  const { sm } = GetBreakPoint()
  const history = GetHistory()
  const { t } = useTranslation();
  const { pathname } = window.location
  const pathIndustries = '/job-preferences/industries'
  const pathPosition = '/job-preferences/position'
  const pathDestination = '/job-preferences/destination'
  const noStep = pathname === pathIndustries ? '1' : pathname === pathPosition ? '2' : '3'
  
  const onNextStep = () => {
    window.scrollTo(0,0)
    if(pathname === pathIndustries){
      if(industriesSelected?.length > 0){
        localStorage.setItem("industriesSelected", industriesSelected);
        history.push(pathPosition)
      }else{
        message.error(t('jobPreferences.msgMin1',{type:'job industry'}))
      }
    }else if(pathname === pathPosition){
      if(positionSelected?.length > 0){
        localStorage.setItem("positionSelected", positionSelected);
        history.push(pathDestination)
      }else{
        message.error(t('jobPreferences.msgMin1',{type:'job position'}))
      }
    }else if(pathname === pathDestination){
      const { actionSetJobPreferences } = props

      if(destinationSelected?.length > 0){
        const payload = {
          listJobIndustry: localStorage.getItem("industriesSelected").split(',').map(x=>+x),
          listJobPosition: localStorage.getItem("positionSelected").split(',').map(x=>+x),
          listJobDestination: destinationSelected
        }
        localStorage.removeItem('industriesSelected')
        localStorage.removeItem('positionSelected')
        localStorage.removeItem('destinationSelected')
        localStorage.removeItem('email')
        localStorage.removeItem('password')
        
        return actionSetJobPreferences(payload, response => {
          window.location.href = '/candidate/career-pathway'
          return () => message.success(response.message)
        }, (err) => {
          return () => message.error(err.message)
        })
        
      }else{
        message.error(t('jobPreferences.msgMin1',{type:'job destination'}))
      }
    }
  }

  const onBack = () => {
    window.scrollTo(0,0)
    if(pathname === pathDestination){
      localStorage.removeItem('destinationSelected')
      history.push(pathPosition)
    }else if(pathname === pathPosition){
      localStorage.removeItem('positionSelected')
      history.push(pathIndustries)
    }
  }

  const onSkip = () => {
    localStorage.removeItem('industriesSelected')
    localStorage.removeItem('positionSelected')
    localStorage.removeItem('destinationSelected')
    localStorage.removeItem('email')
    localStorage.removeItem('password')
    
    window.location.href = '/candidate/career-pathway'
  }

  const buttonFooter = 
    sm ?
      <Space className="footer-button">
        <Button className="dark-color btn-skip" onClick={onSkip}>{t('jobPreferences.skip')}</Button>
        {
          pathname !== pathIndustries ?
            <Button className="dark-color btn-skip" onClick={onBack}>{t('jobPreferences.back')}</Button>
          :
            null
        }
        <Button className="btn-next" type="primary" onClick={onNextStep}>{pathname === pathDestination ? t('jobPreferences.submit') : t('jobPreferences.next')}</Button>
      </Space>
    :
      <Row>
        <Col span={22} offset={1}>
          <Row gutter={8}>
            <Col span={pathname === pathIndustries? 12 : 8}>
              <Button className="dark-color btn-skip" onClick={onSkip}>{t('jobPreferences.skip')}</Button>
            </Col>
            <Col span={pathname === pathIndustries? 0 : 8}>
              {
                pathname !== pathIndustries ?
                  <Button className="btn-back dark-color" onClick={onBack}>{t('jobPreferences.back')}</Button>
                :
                  null
              }
            </Col>
            <Col span={pathname === pathIndustries? 12 : 8}>
              <Button className="btn-next" type="primary" onClick={onNextStep}>{pathname === pathDestination ? t('jobPreferences.submit') : t('jobPreferences.next')}</Button>
            </Col>
          </Row>
        </Col>
      </Row>

  return (
    <React.Fragment>
      <Footer className="footer-job-preference">
        <Row className="footer-style height-100" justify="space-around" align="middle">
          <Col xs={24} sm={24} md={12}>
            <Text className="dark-color">{t('jobPreferences.step')} { noStep } {t('jobPreferences.of')} 3</Text>
          </Col>
          <Col xs={24} sm={24} md={12}>
            {buttonFooter}
          </Col>
        </Row>
      </Footer>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  actionSetJobPreferences: setJobPreferences
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterPreference)