import React from 'react'
import { Tabs, Grid, Typography } from 'antd';

const { TabPane } = Tabs;
const { useBreakpoint } = Grid;
const { Text } = Typography

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function index(props) {
  const { lg } = GetBreakPoint()
  const { activeKey, getMenuApplications } = props

  const handleMenu = e => {
    const { history } = props

    if(e === "Applied"){
      history.push('applied')
    }
    if(e === "Shortlisted"){
      history.push('shortlisted')
    }
    if(e === "Assessment"){
      history.push('assessment')
    }
    if(e === "Qualified"){
      history.push('qualified')
    }
    if(e === "Interview"){
      history.push('interview')
    }
    if(e === "Offered"){
      history.push('offered')
    }
    if(e === "Departure"){
      history.push('departure')
    }
    if(e === "Hired"){
      history.push('hired')
    }
    if(e === "Rejected"){
      history.push('rejected')
    }
  }
  
  return (
    <React.Fragment>
      <Tabs onTabClick={handleMenu} className={lg ? "dark-color" : "dark-color mb-16"} defaultActiveKey={activeKey} tabPosition={lg ? "right" : "top"}>
        <TabPane tab="Applied" key="Applied" />
        {
          getMenuApplications?.data?.map((item, i) => 
            <TabPane tab={item.name} key={item.name} />
          )
        }
        <TabPane tab={<Text style={{ color: '#ff2424' }}>Rejected</Text>} key="Rejected" />
      </Tabs>
    </React.Fragment>
  )
}
