import React, { Suspense } from 'react';
import './index.less';
import store from './redux';
import AppRoute from './router';
import ReactDOM from 'react-dom';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import Loading from './components/Loading';

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['in', 'en'],
    fallbackLng: 'in',
    debug: false,
    // Options for language detector
    detection: {
      checkWhitelist: true,
      order: ['cookie'],
      caches: ['cookie'],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  })

const loadingMarkup = (
  <Loading />
)

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <Provider store={store}>
      <AppRoute />
    </Provider>
  </Suspense>,
  document.getElementById('root')
);
reportWebVitals();