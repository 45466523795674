import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Typography, Modal, Form, Input, Space, Row, Col, Grid, message } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { updateName } from '../../../../../redux/actions/profile/headerAction';
import { indexHeader } from '../../../../../redux/actions/header/headerAction';
import Cookie from "js-cookie";
import CButton from '../../../../../components/Button';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export const NameComponent = (props) => {
  const { data, showModal } = props

  const { xs } = GetBreakPoint()
  return(
    <Space className={xs ? 'text-align-center' : null}>
      <Title className="info-color" level={3}>{data.fullName}</Title>
      <EditFilled className="fs-12 dark-color cursor-pointer" onClick={showModal} />
    </Space>
  )
}
export class Name extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
       submitLoading: false,
       visible: false
    }
  }
  
  showModal = () => {
    this.setState({
      visible: true,
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      isActivation: false,
      newEmail: null
    })
  };

  onFinish = async (values) => {
    const { actionUpdateName, actionGetProfile, actionGetHeader } = this.props
    
    return this.setState({ submitLoading: true }, () => {
      return actionUpdateName(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            Cookie.remove('activeElement')
            return (actionGetProfile(), actionGetHeader())
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const { submitLoading, visible } = this.state
    const { t, data } = this.props

    return (
      <React.Fragment>
        <NameComponent data={data} showModal={this.showModal} />

        <Modal 
          title={t('profile.header.formName.title')} 
          visible={visible} 
          onCancel={this.handleCancel}
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          width={600}
          destroyOnClose
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
            initialValues={{
              firstName: data.firstName,
              lastName: data.lastName
            }}
          >
            <Row>
              <Col span={24}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">{t('profile.header.formName.firstName')}</Text>
                </Space>
                <Form.Item 
                  name="firstName"
                  validateFirst
                  rules={[
                    { required: true, message: t('required') },
                    { pattern: /^[a-zA-Z ]*$/, message: t('a-zA-Z') },
                    { min: 3, message: t('min',{number:3}) },
                    { max: 25, message: t('max',{number:25}) },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">{t('profile.header.formName.lastName')}</Text>
                </Space>
                <Form.Item 
                  name="lastName"
                  validateFirst
                  rules={[
                    { required: true, message: t('required') },
                    { pattern: /^[a-zA-Z ]*$/, message: t('a-zA-Z') },
                    { min: 3, message: t('min',{number:3}) },
                    { max: 25, message: t('max',{number:25}) },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space className="float-right">
                <CButton type="default" action={this.handleCancel} title={t('profile.buttonBack')}/>
                <CButton htmlType="submit" type="primary" loading={submitLoading} title={t('profile.buttonSave')}/>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
  actionUpdateName: updateName,
  actionGetHeader: indexHeader
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Name))