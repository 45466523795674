import { API } from '../../../config'

export const addSkill = (value, successCB, failedCB) => async dispatch => {
  API.POST('/candidate/skill/add', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const deleteSkill = (value, successCB, failedCB) => async dispatch => {
  const params = { 
    id: value
  }
  API.POST_WITH_PARAMS('/candidate/skill/delete', params).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}