import React, { Component } from 'react'
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import Banner from './banner';
import Content from './content';

export class index extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      isActive: 'biografi',
      isFeedback: false,
      submitLoading: false,
      isActiveProgram: '0'
    }
  }

  render() {

    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Career Pathway');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Career Pathway</title>
        </Helmet>
        <Banner />
        <Content />
    </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(index)
