let path = "https://stg-fo.jobshub.id"

if(process.env.REACT_APP_ENV === "development"){
  path = "https://stg-fo.jobshub.id"
}

if(process.env.REACT_APP_ENV === "production"){
  path = "https://jobshub.id"
}

export default path;