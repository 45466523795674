import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import { withTranslation } from 'react-i18next';
import { listJob, unmountListJob } from '../../../redux/actions/job/jobAction';
import { listJobIndustries, allJobIndustries, unmountIndexJobIndustries, unmountAllJobIndustries } from '../../../redux/actions/master/jobIndustries/jobIndustriesAction';
import { listJobDestination, allJobDestination, unmountIndexJobDestination, unmountAllJobDestination } from '../../../redux/actions/master/jobDestination/jobDestinationAction';
import { addOrDeleteFavorite } from '../../../redux/actions/favorite/favoriteAction';
import JobList from '../../../components/Job';
import { validatePost } from '../../../utils/postValidationFunction';

export class CandidateJob extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visibleFilter: false,
      params: {
        page: 1,
        perpage: 10,
        jobIndustry: null,
        destination: null,
        jobLocation: null,
        search: null,
        field: 'dateOpened',
        sort: 'DESC',
        jobType: null,
        flagRemote: false,
        expLevel: null,
        postDate: null
      },
      paramsIndustry: {
        page: 1,
        perpage: 5,
      },
      paramsDestination: {
        page: 1,
        perpage: 5,
      },
      visibleIndustry: false,
      visibleLocation: false,
      valueIndustry: [],
      valueLocation: [],
      valueDestination: null,
      loadingList: false,
      hasMore: true,
      pageLocation: 20,
      searchLocation: null
    }
  }
  
  componentDidMount() {
    const { params, paramsIndustry, paramsDestination } = this.state
    const { actionGetJob, actionGetJobIndustries, actionGetJobDestination } = this.props
    
    window.scrollTo(0, 0);
    
    actionGetJob(params, (res) => {
      var queryString = Object.keys(validatePost(res)).map(key => key + '=' + params[key]).join('&');
      this.props.history.push({
        search: queryString
      })
      actionGetJobIndustries(paramsIndustry) 
      actionGetJobDestination(paramsDestination)
    })
  }
  
  componentDidUpdate(prevProps, prevState) {
    const { params } = this.state

    if(prevState.params !== params){
      var queryString = Object.keys(validatePost(params)).map(key => key + '=' + params[key]).join('&');
      this.props.history.push({
        search: queryString
      })
    }
  }

  onSearch = (value) => {
    const { params } = this.state
    const { actionGetJob } = this.props

    const paramsSearch = {
      search: value,
      page: 1,
    }

    const newParams = {...params, ...paramsSearch}

   
    return actionGetJob(newParams, (res) => {
      this.setState({ params: res })
      window.scrollTo(0, 0);
    })
  }

  callback = (key) => {
    // console.log(key)
  }

  showFilter = () => {
    this.setState({
      visibleFilter: true
    })
  }

  onCloseFilter = () => {
    this.setState({
      visibleFilter: false
    })
  }

  handleSort = (e) => {
    const { params } = this.state
    const { actionGetJob } = this.props

    const paramsSort = {
      field: e === 'expiryDate' ? 'targetDate' : 'dateOpened',
      sort: e === 'expiryDate' ? 'ASC' : 'DESC',
    }

    const newParams = {...params, ...paramsSort}

    return actionGetJob(newParams, (res) => {
      this.setState({ params: res })
      window.scrollTo(0, 0);
    })
  }

  onChangePagination = (pageNumber) => {
    const { params } = this.state
    const { actionGetJob } = this.props
    
    const newParams = {...params, page: pageNumber}

    return actionGetJob(newParams, (res) => {
      this.setState({ params: res })
      window.scrollTo(0, 0);
    })
  }

  handleIndustry = (checkedValues) => {
    const { params } = this.state
    const { actionGetJob } = this.props
    const strIndustry = checkedValues.join()
    
    const paramsIndustry = {
      jobIndustry: strIndustry,
      page: 1
    }

    const newParams = {...params, ...paramsIndustry}

    return actionGetJob(newParams, (qq) => {
      this.setState({ params: qq, valueIndustry: checkedValues })
      window.scrollTo(0, 0);
      this.formRef.current.setFieldsValue({
        modalIndustry: checkedValues
      })
    })
  }

  handleDestination = (checkedValues) => {
    const { params, paramsDestination } = this.state
    const { actionGetJobDestination, actionGetJob } = this.props

    const strDestination = checkedValues.join()

    const _paramsDestination = {
      destination: strDestination,
      location: null,
      page: 1
    }
    
    const newParams = {...params, ..._paramsDestination}
    const newParamsDestination = {...paramsDestination, flagDomestic: strDestination === 'DOMESTIC' ? true : strDestination === 'INTERNATIONAL' ? false : null}

    return actionGetJob(newParams, (res) => {
      actionGetJobDestination(newParamsDestination)
      this.setState({ 
        params: res, 
        valueDestination: strDestination === 'DOMESTIC' ? true : strDestination === 'INTERNATIONAL' ? false : null,
        valueLocation: []
      })
      window.scrollTo(0, 0)
      this.formRef.current.setFieldsValue({
        modalLocation: [],
        location: []
      })
    })
  }

  handleLocation = (checkedValues) => {
    const { params } = this.state
    const { actionGetJob } = this.props

    const strLocation = checkedValues.join()

    const paramsLocation = {
      location: strLocation,
      page: 1
    }

    const newParams = {...params, ...paramsLocation}
    
    return actionGetJob(newParams, (res) => {
      this.setState({ params: res, valueLocation: checkedValues })
      window.scrollTo(0, 0);
      this.formRef.current.setFieldsValue({
        modalLocation: checkedValues
      })
    })
  }

  handleType = (checkedValues) => {
    const { params } = this.state
    const { actionGetJob } = this.props

    const strType = checkedValues.join()

    const paramsType = {
      jobType: strType,
      page: 1
    }
    
    const newParams = {...params, ...paramsType}

    return actionGetJob(newParams, (res) => {
      this.setState({ params: res })
      window.scrollTo(0, 0);
    })
  }

  handleRemote = (checked) => {
    const { params } = this.state
    const { actionGetJob } = this.props

    const paramsRemote = {
      flagRemote: checked,
      page: 1
    }

    const newParams = {...params, ...paramsRemote}

    return actionGetJob(newParams, (res) => {
      this.setState({ params: res })
      window.scrollTo(0, 0);
    })
  }

  handleLevel = (checkedValues) => {
    const { params } = this.state
    const { actionGetJob } = this.props

    const strLevel = checkedValues.join()
    
    const paramsLevel = {
      expLevel: strLevel,
      page: 1
    }

    const newParams = {...params, ...paramsLevel}

    return actionGetJob(newParams, (res) => {
      this.setState({ params: res })
      window.scrollTo(0, 0);
    })
  }

  handleDate = (e) => {
    const { params } = this.state
    const { actionGetJob } = this.props

    const paramsDate = {
      postDate: e.target.value === 'anytime' ? null : e.target.value,
      page: 1
    }

    const newParams = {...params, ...paramsDate}

    return actionGetJob(newParams, (res) => {
      this.setState({ params: res })
      window.scrollTo(0, 0);
    })
  }

  handleClearFilter = () => {
    const { params: { field, sort } } = this.state
    const { actionGetJob } = this.props

    this.formRef.current.setFieldsValue({
      modalIndustry: [],
      modalLocation: []
    })

    this.setState({ valueSearch: null, valueIndustry: [], valueLocation: [], visibleFilter: false })

    const params = {
      page: 1,
      perpage: 10,
      jobIndustry: null,
      destination: null,
      jobLocation: null,
      search: null,
      field: field,
      sort: sort,
      jobType: null,
      flagRemote: null,
      expLevel: null,
      postDate: null
    }

    return actionGetJob(params, (res) => {
      this.setState({ params: res })
    })
  }

  showModalIndustry = () => {
    const { actionAllJobIndustries } = this.props
    this.setState({
      visibleIndustry: true
    })
    const paramsIndustry = {
      page: 1,
      perpage: 50,
    }
    return actionAllJobIndustries(paramsIndustry)
  }

  onSearchIndustry = (value) => {
    const paramsIndustry = {
      page: 1,
      perpage: 50,
      search: value
    }
    const { actionAllJobIndustries } = this.props
    return actionAllJobIndustries(paramsIndustry)
  }

  handleCheckedIndustry = (checkedValues) => {
    this.setState({ valueIndustry: checkedValues })
  }

  handleOkIndustry = () => {
    const { params, valueIndustry } = this.state
    const { actionGetJob } = this.props

    const paramsIndustry = {
      jobIndustry: valueIndustry.join(),
      page: 1
    }

    const newParams = {...params, ...paramsIndustry}
    
    return actionGetJob(newParams, (res) => {
      this.setState({ params: res, visibleIndustry: false })
      this.formRef.current.setFieldsValue({
        industry: valueIndustry
      })
      window.scrollTo(0, 0);
    })
  }

  handleClearIndustry = () => {
    this.formRef.current.setFieldsValue({
      modalIndustry: []
    });
    this.setState({ valueIndustry: [] })
  }
  
  showModalLocation = () => {
    const { valueDestination } = this.state
    const { actionAllJobDestination } = this.props
    this.setState({
      visibleLocation: true
    })
    const paramsDestination = {
      page: 1,
      perpage: 10,
      flagDomestic: valueDestination
    }
    return actionAllJobDestination(paramsDestination)
  }
  
  onSearchLocation = (value) => {
    const { valueDestination } = this.state
    const paramsLocation = {
      page: 1,
      perpage: 10,
      flagDomestic: valueDestination,
      search: value
    }
    this.setState({ loadingList: false, searchLocation: value })
    const { actionAllJobDestination } = this.props
    return actionAllJobDestination(paramsLocation)
  }
  
  handleCheckedLocation = (checkedValues) => {
    const { valueLocation } = this.state
    let newArr = [...valueLocation, checkedValues]
    if (valueLocation.includes(checkedValues)){
      newArr = newArr.filter(item => item !== checkedValues)
    }
    this.setState({ valueLocation: newArr })
  }
  
  handleOkLocation = () => {
    const { params, valueLocation } = this.state
    const { actionGetJob } = this.props

    const paramsLocation = {
      location: valueLocation.join(),
      page: 1
    }

    const newParams = {...params, ...paramsLocation}
    
    return actionGetJob(newParams, (res) => {
      this.setState({ params: res, visibleLocation: false })
      this.formRef.current.setFieldsValue({
        location: valueLocation
      })
      window.scrollTo(0, 0);
    })
  }

  handleClearLocation = () => {
    this.formRef.current.setFieldsValue({
      modalLocation: []
    })
    this.setState({ valueLocation: [] })
  }

  handleCancel = () => {
    this.setState({ visibleIndustry: false, visibleLocation: false  });
  }

  handleInfiniteOnLoad = () => {
    this.setState({ loadingList: true })
    const { valueDestination, pageLocation, searchLocation } = this.state
    const paramsLocation = {
      page: 1,
      perpage: pageLocation + 10,
      flagDomestic: valueDestination,
      search: searchLocation
    }
    this.setState({ loadingList: false, pageLocation: pageLocation+10 })
    const { actionAllJobDestination } = this.props
    return actionAllJobDestination(paramsLocation)
  }

  handleFavorite = (id, isFavorite) => {
    const { params } = this.state
    const { actionAddOrDeleteFavorite, actionGetJob, t } = this.props
    
    const values = {
      jobId: id
    }

    return this.setState({ submitLoading: true }, () => {
      return actionAddOrDeleteFavorite(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false }, () => {
            message.success(isFavorite ? t('jobList.jobRemove') : t('jobList.jobFavorite'))
            return actionGetJob(params, (res) => {
              this.setState({ params: res })
            })
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const { getJob, getJobIndustry, getJobDestination, allJobIndustry, allJobDestination } = this.props
    const { visibleFilter, visibleIndustry, visibleLocation, params, valueIndustry, valueLocation, loadingList, hasMore } = this.state
    
    const initialProps = {
      onSearch: this.onSearch,
      callback: this.callback,
      getJob: getJob,
      visibleFilter: visibleFilter,
      showFilter: this.showFilter,
      onCloseFilter: this.onCloseFilter,
      handleSort: this.handleSort,
      onChangePagination: this.onChangePagination,
      dataIndustry: getJobIndustry,
      handleIndustry: (checkedValues) => this.handleIndustry(checkedValues),
      dataLocation: getJobDestination,
      handleLocation: (checkedValues) => this.handleLocation(checkedValues),
      handleDestination: (checkedValues) => this.handleDestination(checkedValues),
      handleType: (checkedValues) => this.handleType(checkedValues),
      handleRemote: (checked) => this.handleRemote(checked),
      handleLevel: (checkedValues) => this.handleLevel(checkedValues),
      handleDate: (e) => this.handleDate(e),
      handleClearFilter: this.handleClearFilter,
      formRef: this.formRef,
      flagRemote: params.flagRemote,
      showModalIndustry: this.showModalIndustry,
      visibleIndustry: visibleIndustry,
      allJobIndustry: allJobIndustry,
      onSearchIndustry: this.onSearchIndustry,
      handleCheckedIndustry: (checkedValues) => this.handleCheckedIndustry(checkedValues),
      handleOkIndustry: this.handleOkIndustry,
      valueIndustry: valueIndustry,
      handleClearIndustry: this.handleClearIndustry,
      showModalLocation: this.showModalLocation,
      visibleLocation: visibleLocation,
      allJobDestination: allJobDestination,
      onSearchLocation: this.onSearchLocation,
      handleCheckedLocation: (checkedValues) => this.handleCheckedLocation(checkedValues),
      handleOkLocation: this.handleOkLocation,
      valueLocation: valueLocation,
      handleClearLocation: this.handleClearLocation,
      handleCancel: this.handleCancel,
      loadingList: loadingList,
      hasMore: hasMore,
      handleInfiniteOnLoad: (id, isFavorite) => this.handleInfiniteOnLoad(id, isFavorite),
      handleFavorite: this.handleFavorite
    }
    
    return (
      <JobList {...initialProps} {...this.props} />
    )
  }
  componentWillUnmount(){
    const { unmountListJob, unmountIndexJobIndustries, unmountAllJobIndustries, unmountIndexJobDestination, unmountAllJobDestination } = this.props
    return (unmountListJob(), unmountIndexJobIndustries(), unmountAllJobIndustries(), unmountIndexJobDestination(), unmountAllJobDestination())
  }
}

const mapStateToProps = (state) => ({
  getJob: state.jobReducer,
  getJobIndustry: state.jobIndustriesReducer,
  allJobIndustry: state.allJobIndustriesReducer,
  getJobDestination: state.jobDestinationReducer,
  allJobDestination: state.allJobDestinationReducer,
})

const mapDispatchToProps = {
  actionGetJob: listJob,
  unmountListJob: unmountListJob,
  actionGetJobIndustries: listJobIndustries,
  unmountIndexJobIndustries: unmountIndexJobIndustries,
  actionAllJobIndustries: allJobIndustries,
  unmountAllJobIndustries: unmountAllJobIndustries,
  actionGetJobDestination: listJobDestination,
  unmountIndexJobDestination: unmountIndexJobDestination,
  actionAllJobDestination: allJobDestination,
  unmountAllJobDestination: unmountAllJobDestination,
  actionAddOrDeleteFavorite: addOrDeleteFavorite
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CandidateJob))