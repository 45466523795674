import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Loading } from '../../../../components';
import ServicePlusDetail from '../../../../components/ServicePlus/Detail';
import { detailXpert, unmountDetailXpert } from '../../../../redux/actions/xpert/xpertAction';

export class CandidateXpertDetail extends Component {
  componentDidMount() {
    const { actionGetXpertDetail, match: { params } } = this.props
    
    localStorage.removeItem("end_date")
    window.scrollTo(0, 0)

    return actionGetXpertDetail(params.categorySlug, params.slug)
  }

  render() {
    const { getXpertDetail } = this.props
    
    if(getXpertDetail.loading){
      return <Loading />
    }

    return (
      <ServicePlusDetail {...this.props} />
    )
  }
  // componentWillUnmount(){
  //   const { unmountDetailXpert } = this.props
  //   return unmountDetailXpert()
  // }
}

const mapStateToProps = (state) => ({
  getXpertDetail: state.xpertDetailReducer
})

const mapDispatchToProps = {
  actionGetXpertDetail: detailXpert,
  unmountDetailXpert: unmountDetailXpert,
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateXpertDetail)
