import React from 'react';
import { Link } from 'react-router-dom';
import Cookie from 'js-cookie';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import {  Row, Col, Breadcrumb, Grid } from 'antd';
import DetailJobDesktop from './Desktop';
import DetailJobMobile from './Mobile';
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function JobDetail(initialProps) {
  const { getJobDetail } = initialProps
  const { xs } = GetBreakPoint()

  if(process.env.REACT_APP_ENV === "production"){
    ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], `${getJobDetail?.data?.title} | Jobshub`);
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{getJobDetail.data.title} | Jobshub</title>
      </Helmet>
      <Row>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Breadcrumb className="mb-16 mt-16">
            <Breadcrumb.Item>
              <Link to={Cookie.get('user') ? '/candidate/job' : '/'}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={Cookie.get('user') ? `/candidate/job` : '/job'}>Job Opening</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{getJobDetail.data.title}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
          <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
            {
              xs ?
                <DetailJobMobile {...initialProps} />
              :
                <DetailJobDesktop {...initialProps} />
            }
          </Col>
      </Row>
    </React.Fragment>
  )
}