import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';
import { Row, Col, Space, Typography, Button, Form, Input, Upload, Breadcrumb, InputNumber, Card, Select, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { uploadPayment } from '../../../../../../redux/actions/xpert/xpertAction';
import { indexBank, unmountIndexBank } from '../../../../../../redux/actions/master/bank/bankAction';
import Compressor from 'compressorjs';
import CButton from '../../../../../../components/Button';
import Cookie from 'js-cookie';
const { Option } = Select;
const { Text } = Typography;

export class CandidateXpertPaymentUpload extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      paymentProofFile: null,
      xpertTransactionNumber: null,
      submitLoading: false
    }
  }

  componentDidMount() {
    const { location: { state: { xpertTransactionNumber } }, actionGetBank } = this.props
    window.scrollTo(0, 0);
    this.setState({ xpertTransactionNumber })
    actionGetBank()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.i18n.language !== this.state.lang) {
      if(this.formRef.current.getFieldsError()[0]?.errors.length > 0){
        this.updateValidationMessages();
      }
      this.setState({ lang: Cookie.get('i18next') })
    }
  }
  
  updateValidationMessages = () => {
    const { t } = this.props

    this.formRef.current.setFields([
      {
        name: 'senderBankAccountNumber',
        errors: [t('xpert.required')] || [t('xpert.numberOnly')] || [t('xpert.min')] || [t('xpert.max')]
      },
      {
        name: 'senderBankAccountName',
        errors: [t('xpert.required')] || [t('xpert.alphabetical')] || [t('xpert.min')] || [t('xpert.max')]
      },
      {
        name: 'senderBankId',
        errors: [t('xpert.required')]
      },
      {
        name: 'transferAmount',
        errors: [t('xpert.required')]
      },
      {
        name: 'paymentProofFile',
        errors: [t('xpert.required')]
      },
    ]);
  }

  handleUpload = () => {
    const self = this
    return {
      withCredentials: true,
      accept: ".png,.jpg,.jpeg,.pdf",
      beforeUpload: file => {
        // const validateSize = file.size >= 3000000;
        // if (validateSize) {
        //   message.error('Max file size is 3 MB!');
        //   return false
        // } else {
          new Compressor(file, {
            quality: 0.6,
            success(result) {
              // var reader = new FileReader();
              // reader.readAsDataURL(file);
              // reader.onload = () => {
              // }
              message.success(`${result.name} file uploaded successfully`);
              self.setState({ 
                paymentProofFile: result, 
              })
            },
            error(err) {
              message.success(err.message);
            },
          });
        // }
        return false;
      },
    }
  }

  handleRemove = (file) => {
    this.setState({ 
      paymentProofFile: null, 
    })
    message.success(`${file.name} file removed successfully`);
  }

  onFinish = async (values) => {
    const { actionUploadPayment, location: { state: { xpertTransactionNumber } } } = this.props
    const { paymentProofFile, senderBankId } = this.state

    values.xpertTransactionNumber = xpertTransactionNumber
    values.paymentProofFile = paymentProofFile
    values.senderBankId = senderBankId

    return this.setState({ submitLoading: true }, () => {
      return actionUploadPayment(values, response => {
        if(response.code === '1000'){
          setTimeout(() => {
            this.props.history.push('/candidate/service-plus/payment/waiting-for-confirmation')
            this.setState({ submitLoading: false })
          }, 1500)
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }

  handleBank = (value, option) => {
    const optionSelected = Number(option.key)
    
    this.setState({
      senderBankId: optionSelected
    })
  }
  
  render() {
    const { xpertTransactionNumber, submitLoading, paymentProofFile } = this.state
    const { getBank, t } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>Xpert - {t('xpert.upload')}</title>
        </Helmet>
        <Row className="m-content mt-16">
          <Col md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
            <Breadcrumb className="mb-16 mt-8">
              <Breadcrumb.Item>
                <Link to={'/candidate/my-orders'}>My Orders</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('xpert.waiting')}</Breadcrumb.Item>
              <Breadcrumb.Item>{xpertTransactionNumber}</Breadcrumb.Item>
              <Breadcrumb.Item>{t('xpert.upload2')}</Breadcrumb.Item>
            </Breadcrumb>

            <Card className="border-radius-8 mb-16">
              <Space className="width-100 mb-16" direction="vertical" size={16}>
                <Text className="info-color fs-18">{t('xpert.upload2')}</Text>
              </Space>

              <Form
                ref={this.formRef}
                layout="vertical"
                onFinish={this.onFinish}
              >
                <Row gutter={16}>
                  <Col xs={24} lg={12}>
                    <Form.Item 
                      label={t('xpert.ownerNumber')}
                      name="senderBankAccountNumber"
                      validateFirst
                      rules={[
                        { required: true, message: t('xpert.required') },
                        { pattern: /^[0-9]*$/, message: t('xpert.numberOnly') },
                        { min: 5, message: t('xpert.min') },
                        { max: 20, message: t('xpert.max') }
                      ]}
                    >
                      <Input className="border-radius-6" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item 
                      label={t('xpert.ownerName')}
                      name="senderBankAccountName"
                      validateFirst
                      rules={[
                        { required: true, message: t('xpert.required') },
                        { pattern: /^[a-zA-Z ]*$/, message: t('xpert.alphabetical') },
                        { min: 5, message: t('xpert.min') },
                        { max: 50, message: t('xpert.max2') }
                      ]}
                    >
                      <Input className="border-radius-6" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label={t('xpert.bankName')}
                      name="senderBankId"
                      validateFirst
                      rules={[
                        { required: true, message: t('xpert.required') },
                      ]}
                    >
                      <Select
                        className="width-100"
                        // size="large"
                        showSearch
                        onChange={this.handleBank}
                        placeholder={t('xpert.selectBank')}
                      >
                        {
                          getBank?.data?.map((item) =>
                            <Option key={item.id} value={item.name}>{item.code} - {item.name}</Option>
                          )
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item 
                      label={t('xpert.amount')}
                      name="transferAmount"
                      validateFirst
                      rules={[
                        { required: true, message: t('xpert.required') },
                        { pattern: /^[0-9]*$/, message: t('xpert.numberOnly') },
                      ]}
                    >
                      <InputNumber
                        className="width-100 border-radius-6"
                        maxLength={12}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item 
                      label={t('xpert.proof')}
                      name="paymentProofFile"
                      rules={[
                        { required: true, message: t('xpert.required') }
                      ]}
                    >
                      <Upload {...this.handleUpload()} onRemove={this.handleRemove} listType="picture" maxCount={1} showUploadList={paymentProofFile}>
                        <Button icon={<UploadOutlined />}>{t('xpert.file')}</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col className='mb-min-16' span={24}>
                    <Form.Item>
                      <CButton className="box-shadow text-align-center width-100" htmlType="submit" type="primary" size="large" loading={submitLoading} style={{ border: '#4272b9', background: '#4273b9' }} title={t('xpert.send')}/>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountIndexBank } = this.props
    return (unmountIndexBank())
  }
}

const mapStateToProps = (state) => ({
  getBank: state.bankReducer,
})

const mapDispatchToProps = {
  actionUploadPayment: uploadPayment,
  actionGetBank: indexBank,
  unmountIndexBank: unmountIndexBank,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CandidateXpertPaymentUpload))
