const LOAD_DETAIL_EVENT           = 'LOAD_DETAIL_EVENT'
const LOAD_DETAIL_EVENT_SUCCESS   = 'LOAD_DETAIL_EVENT_SUCCESS'
const LOAD_DETAIL_EVENT_FAILED    = 'LOAD_DETAIL_EVENT_FAILED'
const UNMOUNT_DETAIL_EVENT        = 'UNMOUNT_DETAIL_EVENT'
const initialState = {
  loading: true,
  data: null,
  message: null,
}
const eventDetailReducer = (state = initialState, action) => {

  switch(action.type){
    case LOAD_DETAIL_EVENT:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_DETAIL_EVENT_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_DETAIL_EVENT_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_DETAIL_EVENT:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}
export default eventDetailReducer;