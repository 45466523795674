import React from 'react';
import { Row, Col, Space, Typography, Form, Slider } from 'antd';
import { FrownOutlined, SmileOutlined } from '@ant-design/icons';
import CButton from '../../../../components/Button';
const { Text } = Typography

export default function Form8(props) {
  const { formRef, f8, handlePrev, onFinish } = props
  const formatter = (value) => {
    if(value === 1){
      return 'Sangat Besar'
    }else if(value === 2){
      return 'Besar'
    }else if(value === 3){
      return 'Cukup Besar'
    }else if(value === 4){
      return 'Kurang'
    }else if(value === 5){
      return 'Tidak sama sekali'
    }else{
      return null
    }
  }
  return (
    <React.Fragment>
      <Row className="text-align-center mb-16 mt-16">
        <Col span={24}>
          <Text className="primary-color fs-17">Metode Pembelajaran yang Direkomendasikan</Text>
        </Col>
      </Row>

      <Form 
        ref={formRef}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          f21: f8?.f21 ? f8.f21 : null,
          f22: f8?.f22 ? f8.f22 : null,
          f23: f8?.f23 ? f8.f23 : null,
          f24: f8?.f24 ? f8.f24 : null,
          f25: f8?.f25 ? f8.f25 : null,
          f26: f8?.f26 ? f8.f26 : null,
          f27: f8?.f27 ? f8.f27 : null
        }}
      >
        <Row className="mb-16" gutter={16} justify="space-between" align="bottom">
          {/* A1 */}
          <Col lg={12} xs={24}>
            <Text>Penekanan terhadap metode pembelajaran untuk melakukan Perkuliahan:</Text>
            <Row className="text-align-center" justify="space-around" align="middle" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Besar</Text>
                  </Space>
                  <SmileOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item className="mt-16" name="f21">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <FrownOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Tidak</Text>
                    <Text className="fs-13">Sama</Text>
                    <Text className="fs-13">Sekali</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* A2 */}
          <Col lg={12} xs={24}>
            <Text>Penekanan terhadap metode pembelajaran untuk melakukan Demonstrasi:</Text>
            <Row className="text-align-center" justify="space-around" align="middle" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Besar</Text>
                  </Space>
                  <SmileOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item className="mt-16" name="f22">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <FrownOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Tidak</Text>
                    <Text className="fs-13">Sama</Text>
                    <Text className="fs-13">Sekali</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* B1 */}
          <Col lg={12} xs={24}>
            <Text>Penekanan terhadap metode pembelajaran untuk melakukan Partisipasi dalam proyek riset:</Text>
            <Row className="text-align-center" justify="space-around" align="middle" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Besar</Text>
                  </Space>
                  <SmileOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item className="mt-16" name="f23">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <FrownOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Tidak</Text>
                    <Text className="fs-13">Sama</Text>
                    <Text className="fs-13">Sekali</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* B2 */}
          <Col lg={12} xs={24}>
            <Text>Penekanan terhadap metode pembelajaran untuk melakukan Magang:</Text>
            <Row className="text-align-center" justify="space-around" align="middle" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Besar</Text>
                  </Space>
                  <SmileOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item className="mt-16" name="f24">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <FrownOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Tidak</Text>
                    <Text className="fs-13">Sama</Text>
                    <Text className="fs-13">Sekali</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* C1 */}
          <Col lg={12} xs={24}>
            <Text>Penekanan terhadap metode pembelajaran untuk melakukan Praktikum:</Text>
            <Row className="text-align-center" justify="space-around" align="middle" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Besar</Text>
                  </Space>
                  <SmileOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item className="mt-16" name="f25">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <FrownOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Tidak</Text>
                    <Text className="fs-13">Sama</Text>
                    <Text className="fs-13">Sekali</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* C2 */}
          <Col lg={12} xs={24}>
            <Text>Penekanan terhadap metode pembelajaran untuk melakukan Kerja Lapangan:</Text>
            <Row className="text-align-center" justify="space-around" align="middle" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Besar</Text>
                  </Space>
                  <SmileOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item className="mt-16" name="f26">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <FrownOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Tidak</Text>
                    <Text className="fs-13">Sama</Text>
                    <Text className="fs-13">Sekali</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>

          {/* D1 */}
          <Col lg={12} xs={24}>
            <Text>Penekanan terhadap metode pembelajaran untuk melakukan Diskusi:</Text>
            <Row className="text-align-center" justify="space-around" align="middle" gutter={4}>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Sangat</Text>
                    <Text className="fs-13">Besar</Text>
                  </Space>
                  <SmileOutlined className="fs-18" />
                </Space>
              </Col>
              <Col xs={14} lg={12} xxl={16}>
                <Form.Item className="mt-16" name="f27">
                  <Slider max={5} tipFormatter={formatter} />
                </Form.Item>
              </Col>
              <Col xs={5} lg={6} xxl={4}>
                <Space>
                  <FrownOutlined className="fs-18" />
                  <Space direction='vertical' size={-8}>
                    <Text className="fs-13">Tidak</Text>
                    <Text className="fs-13">Sama</Text>
                    <Text className="fs-13">Sekali</Text>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <CButton className="width-100" type="primary" ghost action={handlePrev} title="Sebelumnya"/>
            </Col>
            <Col span={12}>
              <CButton className="width-100" htmlType="submit" type="primary" title="Selanjutnya"/>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </React.Fragment>
  )
}
