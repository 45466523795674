import React from 'react'
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Row, Col, Grid, Space, Button, Typography, Image } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import Portal from '../../assets/img/international/portal.png';

const { useBreakpoint } = Grid;
const { Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export function cta(props) {
  const { xs, md, sm } = GetBreakPoint()
  const { t } = props
  
  return (
    <React.Fragment>
      <Row style={{ width: '100%', backgroundColor: '#fff', paddingBottom: md ? 32 : 16 }}>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 18, offset: 3 }} xxl={{ span: 14, offset: 5 }}>
          {
            md || sm ?
              <Row justify="space-around" align="middle">
                <Col span={12}>
                  <Space direction="vertical" size={32}>
                    <Text className="dark-color" style={{ fontSize: 28, fontWeight: 'bold', lineHeight: 1.2 }}>{t('jobInternasional.portalTitle')}</Text>
                    <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'normal', lineHeight: 1.2 }}>{t('jobInternasional.portalTitle1')}</Text>
                    <Link className="width-100 text-align-center" to="/domestic">
                      <Button className="box-shadow text-align-center" type="primary" style={{ border: '#4273b9', background: '#4273b9', borderRadius: 32, height: 40, boxShadow: '0px 4px 6px rgb(70 103 152 / 1)', fontSize: 20, fontWeight: 500 }}>{t('jobInternasional.button1')} <ArrowRightOutlined /></Button>
                    </Link>
                  </Space>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Image
                    width={ xs ? '100%' : 400 }
                    src={Portal}
                    preview={false}
                  />
                </Col>
              </Row>
            :
              <Row justify="space-around" align="middle" gutter={ md ? [0, 32] : [0, 8]}>
                <Col span={24}>
                  <Space direction="vertical" size={32}>
                    <Text className="dark-color" style={{ fontSize: 28, fontWeight: 'bold', lineHeight: 1.2 }}>{t('jobInternasional.portalTitle')}</Text>
                    <Text className="dark-color" style={{ fontSize: 18, fontWeight: 'normal', lineHeight: 1.2 }}>{t('jobInternasional.portalTitle1')}</Text>
                  </Space>
                </Col>
                <Col span={24}>
                  <Image
                    width={'100%'}
                    src={Portal}
                    preview={false}
                  />
                </Col>
                <Col span={24}>
                  <Link className="width-100 text-align-center" to="/domestic">
                    <Button className="box-shadow text-align-center" type="primary" style={{ border: '#4273b9', background: '#4273b9', borderRadius: 32, height: 40, width: '100%', boxShadow: '0px 4px 6px rgb(70 103 152 / 1)' }}>{t('jobInternasional.button1')} <ArrowRightOutlined /></Button>
                  </Link></Col>
              </Row>
          }
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withTranslation()(cta)
