import { API } from '../../../config'

export const addTraining = (value, successCB, failedCB) => async dispatch => {
  API.POST('/candidate/training/add', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const updateTraining = (value, successCB, failedCB) => async dispatch => {
  API.POST('/candidate/training/update', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const deleteTraining = (value, successCB, failedCB) => async dispatch => {
  const params = { 
    id: value
  }
  API.POST_WITH_PARAMS('/candidate/training/delete', params).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}