import { API } from '../../../../config'
import { errorHandler } from '../../auth/errorAction'

export const unmountIndexLanguage = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_LANGUAGE'})
}

export const indexLanguage = (value) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_LANGUAGE' })
  
  API.GET('/language_level/index', value).then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_LANGUAGE_SUCCESS', 
      payload: {
        data: response.data,
        meta: response.meta
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_LANGUAGE_FAILED' }), 
    ))
  })
}