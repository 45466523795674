import { API } from '../../../config'

export const addTracerStudy = (value, successCB, failedCB) => async dispatch => {
  API.POST('/candidate/tracer_study/add', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

// export const updateTracerStudy = (value, successCB, failedCB) => async dispatch => {
//   API.POST('/candidate/tracer_study/update', value).then((response) => {
//     return successCB && successCB(response)
//   })
//   .catch((err) => {
//     return failedCB && failedCB(err.error || err.message)
//   })
// }
