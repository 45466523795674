import { API } from '../../../../config'
import { errorHandler } from '../../auth/errorAction'

export const unmountIndexJobIndustries = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_JOB_INDUSTRIES'})
}

export const unmountAllJobIndustries = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_ALL_JOB_INDUSTRIES'})
}

export const indexJobIndustries = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_JOB_INDUSTRIES' })
  return API.GET('/job-industry/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_JOB_INDUSTRIES_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_JOB_INDUSTRIES_FAILED' }), 
    ))
  })
}

export const listJobIndustries = (params) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_JOB_INDUSTRIES' })
  return API.GET('/job-industry/list/sort', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_JOB_INDUSTRIES_SUCCESS', 
      payload: {
        data: response.data,
        meta: response.meta
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_JOB_INDUSTRIES_FAILED' }), 
    ))
  })
}

export const allJobIndustries = (params) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_ALL_JOB_INDUSTRIES' })
  return API.GET('/job-industry/list/sort', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_ALL_JOB_INDUSTRIES_SUCCESS', 
      payload: {
        data: response.data,
        meta: response.meta
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_ALL_JOB_INDUSTRIES_FAILED' }), 
    ))
  })
}