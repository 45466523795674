import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Upload, Button, Typography, Tooltip, Space, message } from 'antd';
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { addDocument, deleteDocument, updateDocument } from '../../../../redux/actions/profile/documentAction';
import { indexDocumentType, unmountIndexDocumentType } from '../../../../redux/actions/master/documentTypes/documentTypesAction';
import DocumentForm from './form.js'
import moment from 'moment'
import Cookie from "js-cookie";

const { Text } = Typography;

export class Document extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      submitLoading: false,
      deleteLoading: false,
      issueDate: null,
      expirationDate: null,
      current: false,
      isDate: false,
      documentFile: null,
      showFileEdit: false,
      id: null,
      dataEdit: null,
      docType: null
    }
  }

  showModal = (id) => {
    const { actionGetDocumentTypes, data } = this.props
    const dataEdit = data.listDocument.filter(item => item.id === id).pop()

    this.setState({
      visible: true,
      documentFile: null,
      showFileEdit: id ? true : false,
      id: id,
      startDate: id ? dataEdit?.startDate : null,
      endDate: id ? dataEdit?.endDate : null,
      current: id ? dataEdit?.current : false,
      docType: id ? dataEdit?.documentTypeId : null,
      dataEdit: dataEdit
    })
    actionGetDocumentTypes()
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      documentFile: null,
      showFileEdit: false,
      current: false,
      isDate: false
    })
  };

  onChangeStartDate = (date, dateString) => {
    const { t } = this.props
    const { endDate } = this.state

    this.setState({
      startDate: dateString
    })
    
    if(endDate){
      if(endDate < dateString){
        message.error(t('validationEndDate'), 5)
        this.formRef.current.setFieldsValue({
          expirationDate: null
        })
        this.setState({
          isDate: true
        })
      }else{
        this.setState({
          isDate: false
        })
      }
    }
  }

  onChangeEndDate = (date, dateString) => {
    const { t } = this.props
    const { startDate } = this.state

    this.setState({
      endDate: dateString
    })

    if(dateString < startDate){
      message.error(t('validationEndDate'), 5)
      this.formRef.current.setFieldsValue({
        expirationDate: null
      })
      this.setState({
        isDate: true
      })
    }else{
      this.setState({
        isDate: false
      })
    }
  }

  handleChecked = (e) => {
    this.setState({
      current: e.target.checked
    })
    
    if(e.target.checked){
      this.setState({
        endDate: null
      })

      this.formRef.current.setFieldsValue({
        endDate: null
      })
    }
  }

  handleUpload(){
    const { docType } = this.state
    return {
      withCredentials: true,
      // accept: docType === 29 ? ".jpg, .jpeg" : (docType === 4 || docType === 8 || docType === 1) ? ".pdf" : ".jpg, .jpeg, .pdf, .png",
      beforeUpload: file => {
        const validateSize = file.size >= 3000000;
        if (validateSize) {
          message.error('Max file size is 3 MB!');
          return false
        }
        if((docType === 1 || docType === 4 || docType === 8) && file.type !== "application/pdf"){
          message.error('Upload Failed, PDF only!');
          return false
        }
        if(docType === 29 && file.type !== "image/jpeg"){
          message.error('Upload Failed, JPG/JPEG Only!')
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            documentFile: file
          })
          message.info('Upload Success!');
        }
        return false;
      },
      onChange: (e) => {
        const validateSize = e.file.size >= 3000000;
        if (validateSize) {
          this.formRef.current.setFieldsValue({ documentFile: null })
        }
        if((docType === 1 || docType === 4 || docType === 8) && e.file.type !== "application/pdf"){
          this.formRef.current.setFieldsValue({ documentFile: null })
        }
        if(docType === 29 && e.file.type !== "image/jpeg"){
          this.formRef.current.setFieldsValue({ documentFile: null })
        }
      },
    }
  }

  handleRemove = (file) => {
    this.setState({ 
      documentFile: null,
      showFileEdit: false
    })
    this.formRef.current.setFieldsValue({
      documentFile: null
    })
    message.success(`${file.name} file removed successfully`);
  }

  handlePreview = (info) => {
    const { documentFile } = this.state
    
    if(documentFile){
      const file = new Blob([documentFile], {
        type: documentFile.type,
      });
  
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank', 'fullscreen=yes');
    }else{
      window.open(info.url, '_blank', 'noreferrer, noopener')
    }
  }

  handleDelete = () => {
    const { actionDeleteDocument, actionGetProfile } = this.props
    const { id } = this.state
    
    return this.setState({ deleteLoading: true }, () => {
      return actionDeleteDocument(id, response => {
        if(response.code === '1000'){
          return this.setState({ deleteLoading: false, visible: false, showFileEdit: false }, () => {
            message.success(response.message)
            Cookie.set("activeElement", window.pageYOffset)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ deleteLoading: false, visible: false, showFileEdit: false }, () => message.error(err))
      })
    })
  }

  handleDocType = (id) => {
    this.setState({ docType: id, documentFile: null })
    this.formRef.current.resetFields(['documentNumber', 'issueDate', 'expirationDate', 'documentFile'])
  }

  onFinish = async (values) => {
    const { actionAddDocument, actionUpdateDocument, actionGetProfile } = this.props
    const { documentFile, current, id } = this.state

    values.documentNumber = values.documentNumber ? values.documentNumber : ""
    values.current = current ? current : false
    values.issueDate = values.issueDate ? moment(values.issueDate).format('MM/YYYY') : ""
    values.expirationDate = values.expirationDate ? moment(values.expirationDate).format('MM/YYYY') : ""
    values.remark = values.remark ? values.remark : ""
    
    if(id){
      values.id = id
      if(documentFile){
        values.documentFile = documentFile
      }else{
        delete values.documentFile
      }
      return this.setState({ submitLoading: true }, () => {
        return actionUpdateDocument(values, response => {
          if(response.code === '1000'){
            return this.setState({ submitLoading: false, visible: false, showFileEdit: false }, () => {
              message.success(response.message)
              Cookie.set("activeElement", window.pageYOffset)
              return actionGetProfile()
            })
          }else{
            message.error(response.message)
          }
        }, (err) => {
          return this.setState({ submitLoading: false, visible: false, showFileEdit: false }, () => message.error(err))
        })
      })
    }else{
      values.documentFile = documentFile
      return this.setState({ submitLoading: true }, () => {
        return actionAddDocument(values, response => {
          if(response.code === '1000'){
            return this.setState({ submitLoading: false, visible: false }, () => {
              message.success(response.message)
              Cookie.set("activeElement", window.pageYOffset)
              return actionGetProfile()
            })
          }else{
            message.error(response.message)
          }
        }, (err) => {
          return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
        })
      })
    }
  }

  render() {
    const { visible, submitLoading, deleteLoading, current, isDate, documentFile, showFileEdit, id, dataEdit, docType, startDate } = this.state
    const { t, data, getDocumentTypes } = this.props

    const addFileList = [
      {
        uid: '-1',
        name: documentFile?.name,
        status: 'done'
      }
    ]

    const editFileList = 
      dataEdit?.documentUrl ?
        [
          {
            uid: '-1',
            name: dataEdit?.documentPic,
            status: 'done',
            url: dataEdit?.documentUrl,
            thumbUrl: dataEdit?.documentUrl,
          }
        ]
      :
        []

    const uploadDocument = (
      <Upload 
        {...this.handleUpload()} 
        onRemove={this.handleRemove}
        onPreview={this.handlePreview}
        listType="picture" 
        maxCount={1}
        showUploadList={documentFile || dataEdit ? true : false}
        fileList={documentFile ? addFileList : showFileEdit ? editFileList : []}
        className="upload-list-document"
      >
        <Space>
          <Button size="small" icon={<UploadOutlined />}>
            <Text className="fs-12">
              <Text className="fs-12" type="danger">* </Text>
              {t('profile.document.buttonUpload')}
            </Text>
          </Button>
          <Tooltip placement="right" title="Max. File 3 MB">
            <InfoCircleOutlined className='primary-color' />
          </Tooltip>
        </Space>
      </Upload>
    )
    
    const initialProps = {
      t: t,
      data: data,
      getDocumentTypes: getDocumentTypes,
      visible: visible,
      current: current,
      formRef: this.formRef,
      isDate: isDate,
      submitLoading: submitLoading,
      deleteLoading: deleteLoading,
      id: id,
      documentFile: documentFile,
      dataEdit: dataEdit,
      docType: docType,
      startDate: startDate,
      showModal: (id) => this.showModal(id),
      handleCancel: this.handleCancel,
      onChangeStartDate: this.onChangeStartDate,
      onChangeEndDate: this.onChangeEndDate,
      handleChecked: this.handleChecked,
      handleDocType: this.handleDocType,
      onFinish: this.onFinish,
      handleDelete: this.handleDelete,
      uploadDocument: uploadDocument
    }

    return (
      <React.Fragment>
        <DocumentForm {...initialProps} />
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountIndexDocumentType } = this.props
    return unmountIndexDocumentType()
  }
}

const mapStateToProps = (state) => ({
  getDocumentTypes: state.documentTypeReducer
})

const mapDispatchToProps = {
  actionGetDocumentTypes: indexDocumentType,
  actionAddDocument: addDocument,
  actionUpdateDocument: updateDocument,
  actionDeleteDocument: deleteDocument,
  unmountIndexDocumentType: unmountIndexDocumentType
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Document))
