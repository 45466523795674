import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { withTranslation } from 'react-i18next';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import Cookie from 'js-cookie';
import { Form, Input, Card, Row, Col, Typography, Checkbox, message } from 'antd';
import { setLogin } from '../../redux/actions/auth/authAction';
import './Login.css';
import CButton from '../../components/Button';

const { Text } = Typography;

class Login extends Component {
  formRef = React.createRef(); 
  constructor(props) {
    super(props)
  
    this.state = {
      username: Cookie.get('remember') ? Cookie.get('email') : null,
      password: Cookie.get('remember') ? Cookie.get('password') : null,
      remember: Cookie.get('remember') ? Cookie.get('remember') : false,
      lang: Cookie.get('i18next')
    };

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.i18n.language !== this.state.lang) {
      if(this.formRef.current.getFieldsError()[0]?.errors.length > 0){
        this.updateValidationMessages();
      }
      this.setState({ lang: Cookie.get('i18next') })
    }
  }
  
  updateValidationMessages = () => {
    const { t } = this.props

    this.formRef.current.setFields([
      {
        name: 'username',
        errors: [t('required')] || [t('typeEmail')]
      },
      {
        name: 'password',
        errors: [ t('required') ]
      }
    ]);
  }
  
  onFinish = async (values) => {
    const { remember } = this.state;
    const { actionSetLogin } = this.props;
    
    if(remember){
      Cookie.set('email', values.username);
      Cookie.set('password', values.password);
      Cookie.set('remember', values.remember);
    } else {
      Cookie.remove('email');
      Cookie.remove('password');
      Cookie.remove('remember');
    }
    return actionSetLogin(values, response => {
      if(response.token){
        if(localStorage.getItem('link')){
          localStorage.removeItem('link');
        }
        if(localStorage.getItem('visible')){
          localStorage.removeItem('visible');
        }
        if(localStorage.getItem('path-interview')){
          localStorage.removeItem('path-interview');
        }
        if(localStorage.getItem('pathway')){
          localStorage.removeItem('pathway');
        }
      }
    }, (err) => {
      message.error(err);
    });
  }

  render() {
    const { remember } = this.state;
    const { t } = this.props;

    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Login');
    }
    
    const responseGoogle = (response) => {
      console.log(response);
    }
    
    const responseFacebook = (response) => {
      console.log(response);
    }

    const onChange = (e) => {
      this.setState({
        remember: e.target.checked
      })
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Login</title>
        </Helmet>
        <Row className="display-flex" align="middle" justify="center">
          <Col>
            <Card className="card-login" bodyStyle={{ padding: 0, display: 'flex' }}>
              <Form 
                ref={this.formRef} 
                name="normal_login" 
                layout="vertical" 
                onFinish={this.onFinish}
              >
                <Row gutter={16}>
                  <Col className="fs-28" xs={24} sm={24} md={24}>
                    <Text type="secondary">{t('login.title')}</Text>
                  </Col>
                  <Col className="fs-13 mb-16" xs={24} sm={24} md={24}>
                    <Text type="secondary">{t('login.subTitle')}</Text>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Text type="secondary">Email</Text>
                    <Form.Item 
                      className="mb-16"
                      name="username"
                      rules={[
                        { required: true, message: t('required') },
                        { type: 'email', message: t('typeEmail') }
                      ]}
                    >
                      <Input 
                        type="email"
                        placeholder={t('login.placeholerEmail')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Text type="secondary">{t('login.password')}</Text>
                    <Form.Item 
                      className="mb-8" 
                      name="password"
                      rules={[
                        { required: true, message: t('required') },
                      ]}
                    >
                      <Input.Password
                        placeholder={t('login.placeholerPassword')}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="mb-8" xs={24} sm={24} md={24}>
                    <Form.Item name="remember" noStyle>
                      <Checkbox className="dark-color fs-11" onChange={onChange} checked={remember}>{t('login.rememberMe')}</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item className="mb-8">
                      <CButton type="primary" className="width-100" htmlType="submit" block title={t('login.button')}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col className="fs-13" xs={12} sm={12} md={12}>
                    <Text type="secondary">
                      {t('login.subButton')} <Link className="info-color" to="/register">{t('login.register')}</Link>
                    </Text>
                  </Col>
                  <Col className="fs-13" xs={12} sm={12} md={12}>
                    <Link className="info-color float-right" to="/forgot-password">{t('login.forgotPassword')}</Link>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
        <Row className="display-none">
          <Col span={12}>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Sign In With Google"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={'single_host_origin'}
              className="google-login social-button"
            />
          </Col>
          <Col span={12}>
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              fields="name,email,picture"
              callback={responseFacebook}
              className="facebook-login social-button"
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({});
  
const mapDispatchToProps = {
  actionSetLogin: setLogin
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Login));
