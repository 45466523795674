import React from 'react';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';
import { Card, Row, Col, Typography, Space, Grid } from 'antd'

const { useBreakpoint } = Grid;
const { Text } = Typography

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export function candidateXpertTermsAndConditions(props) {
  window.scrollTo(0, 0);
  
  if(process.env.REACT_APP_ENV === "production"){
    ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Xpert - Terms and Conditions');
  }

  const { xs } = GetBreakPoint()
  const { t } = props

  return (
    <React.Fragment>
      <Helmet>
        <title>Xpert - {t('xpert.conditions')}</title>
      </Helmet>
      <Row className="mb-16">
        <Col className="mt-32" xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Card style={{ boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.1)', paddingRight: xs ? 0 : 16, paddingLeft: xs ? 0 : 16 }}>
            <Row>
              <Col className={ xs ? "fs-24 text-align-center mb-8" : "fs-28 text-align-center mb-16" } span={24}>
                <Text type="secondary">{t('xpert.label4')}</Text>
              </Col>
              <Space direction="vertical">
                <Space direction="vertical">
                  <Text type="secondary">{t('xpert.label5')}</Text>
                  <Space align="start">
                    <Text type="secondary">1.</Text>
                    <Text type="secondary">{t('xpert.label6')}</Text>
                  </Space>
                  <Space align="start">
                    <Text type="secondary">2.</Text>
                    <Text type="secondary">{t('xpert.label7')}</Text>
                  </Space>
                  <Space align="start">
                    <Text type="secondary">3.</Text>
                    <Text type="secondary">{t('xpert.label8')}</Text>
                  </Space>
                  <Space align="start">
                    <Text type="secondary">4.</Text>
                    <Text type="secondary">{t('xpert.label9')}</Text>
                  </Space>
                  <Space align="start">
                    <Text type="secondary">5.</Text>
                    <Text type="secondary">{t('xpert.label10')}</Text>
                  </Space>
                  <Space align="start">
                    <Text type="secondary">6.</Text>
                    <Text type="secondary">{t('xpert.label11')}</Text>
                  </Space>
                  <Space align="start">
                    <Text type="secondary">7.</Text>
                    <Text type="secondary">{t('xpert.label12')}</Text>
                  </Space>
                  <Space align="start">
                    <Text type="secondary">8.</Text>
                    <Text type="secondary">{t('xpert.label13')}</Text>
                  </Space>
                  <Space align="start">
                    <Text type="secondary">9.</Text>
                    <Text type="secondary">{t('xpert.label14')}</Text>
                  </Space>
                  <Space align="start">
                    <Text type="secondary">10.</Text>
                    <Text type="secondary">{t('xpert.label15')}</Text>
                  </Space>
                </Space>
              </Space>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}
export default withTranslation()(candidateXpertTermsAndConditions)