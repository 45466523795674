import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Typography, Space, Grid, Image } from 'antd'
import ImageSuccess from '../../../../assets/img/employer/success.png';
import CButton from '../../../../components/Button';

const { useBreakpoint } = Grid;
const { Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function employerRegistartionSuccess() {
  const { xs, md, xxl } = GetBreakPoint()
   
  return (
    <React.Fragment>
      <Row style={xxl ? { paddingTop: 64 } : { paddingTop: 32 }}>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Row className="text-align-center" justify="space-around" align="middle">
            <Col span={24}>
              <Space direction="vertical" size={xxl ? 24 : md ? 16 : 4} style={{ width: xxl ? '70%' : '100%' }}>
                <Text style={{ fontSize: xxl ? 28 : md ? 24 : 22, fontWeight: 'normal', color: '#535353' }}>Thank you for submitting your data!</Text>
                <Text style={{ fontSize: xxl ? 20 : 16, fontWeight: 'normal', color: '#535353' }}>Our team will reach you out within the days to inform your credentials.</Text>
                <Image
                  width={xxl ? '50%' : xs ? '100%' : '25%'}
                  src={ImageSuccess}
                  preview={false}
                  style={{ paddingTop: xs ? 16 : 0 }}
                />
                <Link to="/employer">
                  <CButton type="primary" shape="round" size="large" style={{  width: xxl ? '50%' : xs ? '100%' : '25%', marginTop: 32 }} title="Back to homepage"/>
                </Link>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}
