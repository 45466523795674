import React from 'react';
import { Row, Col, Space, Typography, Form, Input, Checkbox } from 'antd';
import CButton from '../../../../components/Button';
const { Text } = Typography

export default function Form10(props) {
  const { formRef, form10, f10, handleCheckboxLainnyaF10, handlePrev, onFinish } = props
  return (
    <React.Fragment>
      <Row className="text-align-center mb-16 mt-16">
        <Col span={24}>
          <Text className="primary-color fs-17">Alasan Pengambilan Pekerjaan Diluar Pendidikan yang Ditempuh</Text>
        </Col>
      </Row>

      <Form 
        ref={formRef}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          f1602: f10?.f1602 ? f10.f1602 : null,
          f1603: f10?.f1603 ? f10.f1603 : null,
          f1604: f10?.f1604 ? f10.f1604 : null,
          f1605: f10?.f1605 ? f10.f1605 : null,
          f1606: f10?.f1606 ? f10.f1606 : null,
          f1607: f10?.f1607 ? f10.f1607 : null,
          f1608: f10?.f1608 ? f10.f1608 : null,
          f1609: f10?.f1609 ? f10.f1609 : null,
          f1610: f10?.f1610 ? f10.f1610 : null,
          f1611: f10?.f1611 ? f10.f1611 : null,
          f1612: f10?.f1612 ? f10.f1612 : null,
          f1613: f10?.f1613 ? f10.f1613 : null,
          f1614: f10?.f1614 ? f10.f1614 : null
        }}
      >
        <Row className="mb-16">
          <Col span={24}>
            <Space className="mb-8">
              <Text>Mengapa kamu mengambil pekerjaan ini?</Text>
            </Space>

            <Form.Item 
              className="margin-0"
              name="f1602"
              valuePropName="checked"
            >
              <Checkbox value={1} checked={f10?.f1602 === "2" ? true : false}>Saya belum mendapatkan pekerjaan yang lebih sesuai.</Checkbox>
            </Form.Item>

            <Form.Item 
              className="margin-0"
              name="f1603"
              valuePropName="checked"
            >
              <Checkbox value={1} checked={f10?.f1603 === "3" ? true : false}>Di pekerjaan ini saya memeroleh prospek karir yang baik.</Checkbox>
            </Form.Item>

            <Form.Item 
              className="margin-0"
              name="f1604"
              valuePropName="checked"
            >
              <Checkbox value={1} checked={f10?.f1604 === "4" ? true : false}>Saya lebih suka bekerja di area pekerjaan yang tidak ada hubungannya dengan pendidikan saya.</Checkbox>
            </Form.Item>

            <Form.Item 
              className="margin-0"
              name="f1605"
              valuePropName="checked"
            >
              <Checkbox value={1} checked={f10?.f1605 === "5" ? true : false}>Saya dipromosikan ke posisi yang kurang berhubungan dengan pendidikan saya dibanding posisi sebelumnya.</Checkbox>
            </Form.Item>

            <Form.Item 
              className="margin-0"
              name="f1606"
              valuePropName="checked"
            >
              <Checkbox value={1} checked={f10?.f1606 === "6" ? true : false}>Saya dapat memeroleh pendapatan yang lebih tinggi di pekerjaan ini.</Checkbox>
            </Form.Item>

            <Form.Item 
              className="margin-0"
              name="f1607"
              valuePropName="checked"
            >
              <Checkbox value={1} checked={f10?.f1607 === "7" ? true : false}>Pekerjaan saya saat ini lebih aman/terjamin/secure</Checkbox>
            </Form.Item>

            <Form.Item 
              className="margin-0"
              name="f1608"
              valuePropName="checked"
            >
              <Checkbox value={1} checked={f10?.f1608 === "8" ? true : false}>Pekerjaan saya saat ini lebih menarik </Checkbox>
            </Form.Item>

            <Form.Item 
              className="margin-0"
              name="f1609"
              valuePropName="checked"
            >
              <Checkbox value={1} checked={f10?.f1609 === "9" ? true : false}>Pekerjaan saya saat ini lebih memungkinkan saya mengambil pekerjaan tambahan/jadwal yang fleksibel, dll.</Checkbox>
            </Form.Item>

            <Form.Item 
              className="margin-0"
              name="f1610"
              valuePropName="checked"
            >
              <Checkbox value={1} checked={f10?.f1610 === "10" ? true : false}>Pekerjaan saya saat ini lokasinya lebih dekat dari rumah saya.</Checkbox>
            </Form.Item>

            <Form.Item 
              className="margin-0"
              name="f1611"
              valuePropName="checked"
            >
              <Checkbox value={1} checked={f10?.f1611 === "11" ? true : false}>Pekerjaan saya saat ini dapat lebih menjamin kebutuhan keluarga saya.</Checkbox>
            </Form.Item>

            <Form.Item 
              className="margin-0"
              name="f1612"
              valuePropName="checked"
            >
              <Checkbox value={1} checked={f10?.f1612 === "12" ? true : false}>Pada awal meniti karir ini, saya harus menerima pekerjaan yang tidak berhubungan dengan pendidikan saya.</Checkbox>
            </Form.Item>

            <Form.Item 
              className="margin-0"
              name="f1613"
              valuePropName="checked"
            >
              <Checkbox onChange={handleCheckboxLainnyaF10} checked={f10?.f1613 === "13" ? true : false}>Lainnya:</Checkbox>
            </Form.Item>
            
            {
              form10.lainnya ?
                <Form.Item 
                  name="f1614"
                  validateFirst
                  rules={[
                    { required: form10.lainnya, message: 'This field is mandatory!' },
                    { min: 3, message: 'At least 3 characters' },
                    { max: 50, message: '50 characters only' },
                  ]}
                >
                  <Input />
                </Form.Item>
              : null
            }
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <CButton className="width-100" type="primary" ghost action={handlePrev} title="Sebelumnya"/>
            </Col>
            <Col span={12}>
              <CButton className="width-100" htmlType="submit" type="primary" title="Selanjutnya"/>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </React.Fragment>
  )
}
