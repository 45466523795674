import React from 'react'
import { Link } from 'react-router-dom';
import { Row, Col, Grid, Image, Divider } from 'antd';
import DomesticB from '../../assets/img/general/domestic-b.png';
import InternationalB from '../../assets/img/general/international-b.png';

const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function option(props) {
  const { md } = GetBreakPoint()

  return (
    <React.Fragment>
      <Row style={ md ? { paddingTop: 32, paddingBottom: 32, backgroundColor: '#fff' } : { backgroundColor: '#fff', paddingTop: 32 } }>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Row className={ md ? "mb-64" : "mb-8"}>
            <Col xs={24} lg={12}>
            <Link to={`/domestic`}>
              <Image
                className="cursor-pointer option-img"
                src={DomesticB}
                preview={false}
                width={'100%'}
                height={ md ? 350 : 220 }
                style={{ objectFit: 'contain', transition: '.5s ease' }}
              />
            </Link>
            </Col>
            <Col xs={24} lg={12}>
              <Link to={`/international`}>
                <Image
                  className="cursor-pointer option-img"
                  src={InternationalB}
                  preview={false}
                  width={'100%'}
                  height={ md ? 350 : 220 }
                  style={{ objectFit: 'contain', transition: '.5s ease'  }}
                />
              </Link>
            </Col>
          </Row>
          <Divider />
        </Col>
      </Row>
    </React.Fragment>
  )
}
