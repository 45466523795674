import React from 'react';
import ImgCrop from 'antd-img-crop';
import { Loading } from '../../../../../components';
import { Row, Col, Space, Form, Typography, Avatar, Button, Upload } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import PersonalInfo from './PersonalInfo';
import Education from './Education';
import WorkExperience from './WorkExperience';
import Document from './Document';
import CButton from '../../../../../components/Button';

const { Text } = Typography;

export default function CareerPathwayFormEditProfile(props) {
  const { 
    t,
    state: {
      onPreviewAvatar
    },
    formRef,
    onFinish,
    handleUploadImage,
    getCountry,
    getEducationLevel,
    getBusinessSector,
    getDocumentTypes,
    getProfile
  } = props

  if(getCountry.loading || getEducationLevel.loading || getBusinessSector.loading || getDocumentTypes.loading){
    return <Loading />
  }

  const isDocNeed = getProfile.data.listDocument.filter(item => item.documentName === 'Ijazah' || item.documentName === 'Surat Tanda Registrasi Keperawatan')

  return (
    <React.Fragment>
      <Form ref={formRef} onFinish={onFinish} layout='vertical' scrollToFirstError>
        <Row>
          <Col span={24}>
            <Row>
              {/* Personal Info */}
              <Col span={24}>
                <Space className='mb-16' direction='vertical' size={10}>
                  <Text className='fs-18 light-color'>{t('profileUpdate.title1')}</Text>
                  <Avatar 
                    size={80} 
                    src={
                      onPreviewAvatar || getProfile?.data?.profilePicUrl ? 
                        onPreviewAvatar || getProfile?.data?.profilePicUrl
                      : 
                        null
                    } 
                    icon={
                      onPreviewAvatar || getProfile?.data?.profilePicUrl ? 
                        null 
                      : 
                        <UserOutlined />
                    }
                  />
                  {
                    !getProfile?.data?.profilePicUrl ?
                      <ImgCrop shape="round" rotate={true}>
                        <Upload {...handleUploadImage()} multiple={false} showUploadList={false}>
                          <Button size="small"><Text className="fs-12" strong>{t('profileUpdate.upload')}</Text></Button>
                        </Upload>
                      </ImgCrop>
                    : null
                  }
                </Space>
                <PersonalInfo {...props} />
              </Col>

              {/* Education */}
              <Col span={24}>
                <Text className='fs-18 light-color'>{t('profileUpdate.title2')}</Text>
                <Education {...props} />
              </Col>

              {/* Work Experience */}
              <Col span={24}>
                <Text className='fs-18 light-color'>{t('profileUpdate.title3')}</Text>
                <WorkExperience {...props} />
              </Col>

              {/* Document */}
              {
                isDocNeed?.length < 2 ?
                  <Col span={24}>
                    <Text className='fs-18 light-color'>{t('profileUpdate.title4')}</Text>
                    <Document {...props} />
                  </Col>
                : null
              }
            </Row>
          </Col>
          <Col span={24}>
            <CButton className="float-right" type="primary" htmlType='submit' style={{ width: '25%' }} title={t('jobDetail.next')}/>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}