import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { dataLandingPage } from '../../redux/actions/landingPage/landingPageAction';
import { jobDestinationAll } from '../../redux/actions/master/jobDestination/jobDestinationAction';
import { listJobPosition, unmountlistJobPosition } from '../../redux/actions/master/jobPosition/jobPositionAction';
import Loading from '../../components/Loading';
import Banner from './newBanner';
// import Banner from './banner';
// import Banner from './bannerExpo';
import Job from './job';
import Pathway from './pathway';
import Option from './option';
import Company from './company';
import Cta from './cta';
import Download from './download';
import { validatePost } from '../../utils/postValidationFunction';
import debounce from 'lodash/debounce';

// const textGray = ['dengan aplikasi smartphone', 'dengan berbagai pekerjaan', 'tanpa harus'];
// const textGreen = ['Jobshub', 'Domestik & Internasional', 'keluar rumah']

// const textGray = ['dengan berbagai pekerjaan', 'tanpa harus'];
// const textGreen = ['Domestik & Internasional', 'keluar rumah']

export class Home extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      destinationSelected: null,
      params: {
        page: 1,
        perpage: 10,
        jobIndustry: null,
        jobLocation: null,
        destination: null,
        search: null,
        field: 'dateOpened',
        sort: 'DESC',
        jobType: null,
        flagRemote: false,
        expLevel: null,
        postDate: null
       },
       textIdx: 0,
       fade: 'fade-in',
       metaJobPosition: {
        page: 1,
        perpage: 10,
        search: '',
       },
       loading: false
    }
    this.onSearchJobPosition = debounce(this.onSearchJobPosition.bind(this), 800)
  }
  
  componentDidMount() {
    const { actionDataLanding, actionGetJobDestination } = this.props

    window.scrollTo(0, 0);

    if(localStorage.getItem('link')){
      localStorage.removeItem('link')
    }
    if(localStorage.getItem('visible')){
      localStorage.removeItem('visible')
    }
    if(localStorage.getItem('emailForgotPass')){
      localStorage.removeItem('emailForgotPass')
    }
    if(localStorage.getItem('industriesSelected')){
      localStorage.removeItem('industriesSelected')
    }
    if(localStorage.getItem('positionSelected')){
      localStorage.removeItem('positionSelected')
    }
    if(localStorage.getItem('destinationSelected')){
      localStorage.removeItem('destinationSelected')
    }
    if(localStorage.getItem('path-interview')){
      localStorage.removeItem('path-interview')
    }
    if(localStorage.getItem('pathway')){
      localStorage.removeItem('pathway')
    }
    
    this.timeout = setInterval(() => {
      let currentIdx = this.state.textIdx;

      if (this.state.fade === 'fade-in') {
        this.setState({ fade: 'fade-out' })
      } else {
        this.setState({ fade: 'fade-in' })
        this.setState({ textIdx: currentIdx + 1 });
      }
    }, 2000);

    return (actionDataLanding(), actionGetJobDestination())
  }

  onSearchJobPosition = value => {
    const { metaJobPosition } = this.state;
    const { actionListJobPosition, unmountlistJobPosition } = this.props;

    metaJobPosition.page = 1
    metaJobPosition.perpage = 10
    metaJobPosition.search = value

    if(value.length >= 3){
      this.setState({ loading: true })
      return actionListJobPosition(metaJobPosition)
    }else{
      this.setState({ loading: false })
      return unmountlistJobPosition()
    }
  }

  handleDestination = (value, option) => {
    const optionSelected = Number(option.key)

    this.setState({
      destinationSelected: optionSelected
    })
  }

  onFinish = async (values) => {
    const { destinationSelected, params } = this.state
    values.location = destinationSelected

    const newParams = {...params, ...values}
    if(values.search || destinationSelected){
      var queryString = Object.keys(validatePost(newParams)).map(key => key + '=' + newParams[key]).join('&');
      
      this.props.history.push(`/job?${queryString}`)
    }else{
      this.props.history.push('/job')
    }
  }

  
  render() {
    const { getDataLanding, getJobDestination } = this.props
    
    if(getDataLanding.loading || getJobDestination.loading){
      return <Loading />
    }
    
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub');
    }
    
    // let changesGray = textGray[this.state.textIdx % textGray.length];
    // let changesGreen = textGreen[this.state.textIdx % textGreen.length];
    
    const initialProps = {
      formRef: this.formRef,
      onFinish: this.onFinish,
      handleDestination: this.handleDestination,
      onSearchJobPosition: this.onSearchJobPosition,
      loading: this.state.loading
    }
    
    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub</title>
        </Helmet>
        {/* <Banner changesGray={changesGray} changesGreen={changesGreen} fade={this.state.fade} /> */}
        <Banner />
        <Job {...this.props} {...initialProps} />
        <Pathway />
        <Option />
        <Company {...this.props} {...initialProps} />
        <Cta />
        <Download />
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountlistJobPosition } = this.props

    clearInterval(this.timeout);
    return unmountlistJobPosition()
  }
}

const mapStateToProps = (state) => ({
  getDataLanding: state.landingPageReducer,
  getJobDestination: state.jobDestinationReducer,
  listJobPosition: state.jobPositionReducer
})

const mapDispatchToProps = {
  actionDataLanding: dataLandingPage,
  actionGetJobDestination: jobDestinationAll,
  actionListJobPosition: listJobPosition,
  unmountlistJobPosition: unmountlistJobPosition
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
