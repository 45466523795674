import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { Row, Col, Breadcrumb, Typography, Space, Card, Divider, Avatar, message, Modal } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { detailOrder, unmountDetailOrder, cancelPayment } from '../../../../redux/actions/xpert/xpertAction'
import EmptyLogo from '../../../../assets/img/EmptyLogo.png';
import WaitingPayment from './waitingPayment';
import WaitingConfirm from './waitingConfirm';
import PaymentConfirm from './paymentConfirm';
import Expired from './expired';
import Rejected from './rejected';
import Canceled from './canceled';
import { xpertStatus } from '../../../../utils/constant/xpertStatus';
import { eventType } from '../../../../utils/constant/eventType';
import { courseLevel } from '../../../../utils/constant/courseLevel';
import { trainingType } from '../../../../utils/constant/trainingType';
import { serviceType } from '../../../../utils/constant/serviceType';

const { Text } = Typography

export class CandidateXpertDetailMyOrders extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      visiblePayment: false,
    }
  }

  showModalPayment = () => {
    this.setState({ visiblePayment: true})
  }

  onCancel = () => {
    this.setState({ visiblePayment : false})
  }

  componentDidMount() {
    const { actionDetailOrder, match: { params } } = this.props

    return actionDetailOrder(params.id)
  }

  handleBack = () => {
    const { getOrderDetail } = this.props
    
    this.props.history.push({ 
      pathname: '/candidate/my-orders',
      status: getOrderDetail.data.status
    })
  }

  handleCopy = () => {
    navigator.clipboard.writeText('1030007950815')
    message.success('copied to clipboard');
  }

  handleUpload = () => {
    const { getOrderDetail: { data } } = this.props
  
    this.props.history.push({
      pathname: `/candidate/${data.xpertType === 'SERVICE' ? 'service-plus' : 'xpert'}/payment/upload`,
      state: data
    })
  }

  handlePayment = (e) => {
    this.setState({
      paymentOption: e.target.value,
    });
  };


  handleCancel = () => {
    const { actionCancelPayment, history, match: { params } } = this.props
    const values = {
      id: params.id,
      notes: 'Canceled by candidate'
    }
    return actionCancelPayment(values, () => {
      return this.setState({ submitLoading: false }, () => {
        history.push({
          pathname: '/candidate/my-orders',
          status: 'CANCELED'
        })
        message.success('Success canceled payment')
      })
    }, (err) => {
      return this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  showModal = (e) => {
    this.setState({ visible: true })
  }

  handleClosed = () => {
    this.setState({ visiblePayment: false })
  }

  render() {
    const { submitLoading, visiblePayment } = this.state
    const { getOrderDetail, t } = this.props

    if(getOrderDetail.loading){
      return <Loading />
    }

    const initialProps = {
      handleBack: this.handleBack,
      handleCopy: this.handleCopy,
      handleUpload: this.handleUpload,
      handleCancel: this.handleCancel,
      submitLoading: submitLoading,
      showModalPayment: this.showModalPayment
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Xpert - Detail</title>
        </Helmet>
        <Row className='mb-8'>
          <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
            <Breadcrumb className="mb-8 mt-8">
              <Breadcrumb.Item>
                <Link to='/candidate/my-orders'>My Orders</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {xpertStatus?.find(res => res.value === getOrderDetail?.data?.status)?.name}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{getOrderDetail?.data?.xpertTransactionNumber}</Breadcrumb.Item>
            </Breadcrumb>

            <Space className="cursor-pointer mb-16" size={16} onClick={this.handleBack}>
              <ArrowLeftOutlined className="info-color fs-14 font-weight-bold"/>
              <Text className="info-color fs-14 font-weight-bold">{t("xpert.back")}</Text>
            </Space>
            
            <Card className="text-align-center mb-2" style={{ backgroundColor: `${xpertStatus?.find(res => res.value === getOrderDetail?.data?.status)?.color}`, border: '1px solid #e0e0e0' }}>
              <Text className="fs-18 white-color">
                {
                  xpertStatus?.find(res => res.value === getOrderDetail?.data?.status)?.name === "Menunggu Pembayaran" ? t('myOrders.waiting')
                  : xpertStatus?.find(res => res.value === getOrderDetail?.data?.status)?.name === "Menunggu Konfirmasi" ? t('myOrders.confirm')
                  : xpertStatus?.find(res => res.value ===  getOrderDetail?.data?.status)?.name === "Pembayaran Terkonfirmasi" ? t('myOrders.paymentConfirm')
                  : xpertStatus?.find(res => res.value === getOrderDetail?.data?.status)?.name === "Kedaluwarsa" ? t('myOrders.expired')
                  : xpertStatus?.find(res => res.value === getOrderDetail?.data?.status)?.name === "Ditolak" ? t('myOrders.rejected')
                  : xpertStatus?.find(res => res.value === getOrderDetail?.data?.status)?.name === "Dibatalkan" ? t('myOrders.cancel')
                  : null
                }
              </Text>
            </Card>

            <Card style={{ backgroundColor: '#FFFFFF', border: '1px solid #e0e0e0', paddingLeft: 32, paddingRight: 32 }} bodyStyle={{ padding: 16 }}>
              <Space className="width-100 text-align-center margin-0" direction="vertical">
                <Text className="info-color fs-18" style={{ fontWeight: 500 }}>{getOrderDetail?.data?.xpertName}</Text>
                <Divider className="mt-8" />
              </Space>
              <Row className="text-align-center" gutter={[8,8]}>
                <Col xs={24} lg={8}>
                  <Space direction="vertical">
                    <Text className="dark-color fs-16">{t('myOrdersTitle.organizedBy')}</Text>
                    <Space>
                      <Avatar size={50} shape="square" src={getOrderDetail?.data?.providerLogoUrl ? getOrderDetail?.data?.providerLogoUrl : EmptyLogo} />
                      <Text className="fs-18 dark-color" style={{ fontSize: 14 }}>{getOrderDetail?.data?.name}</Text>
                    </Space>
                  </Space>
                </Col>
                <Col xs={24} lg={8}>
                  <Space direction="vertical">
                    <Text className="dark-color fs-16">{t('myOrdersTitle.Date')}</Text>
                    <Space direction="vertical" style={{ marginTop: 8}}>
                      <Text className="dark-color fs-14">{getOrderDetail?.data?.xpertDate} </Text>
                      <Text className="dark-color fs-12">
                        {
                          getOrderDetail.data.xpertType === 'SERVICE' ?
                            null :
                          getOrderDetail.data.xpertTime
                        }
                      </Text>
                    </Space>
                  </Space>
                </Col>
                <Col xs={24} lg={8}>
                  <Space direction="vertical">
                    <Text className="dark-color" style={{ fontSize: 16, fontWeight: 'normal' }}>{t('myOrdersTitle.Type')}</Text>
                    <Text className="light-color fs-14">
                      {
                        getOrderDetail?.data?.xpertType === 'EVENT' ?
                          eventType?.find(item => item.value === getOrderDetail?.data?.eventType)?.name
                        : getOrderDetail?.data?.xpertType === 'COURSE' ?
                          courseLevel?.find(item => item.value === getOrderDetail?.data?.courseLevel)?.name
                        : getOrderDetail?.data?.xpertType === 'TRAINING' ?
                          trainingType?.find(item => item.value === getOrderDetail?.data?.trainingType)?.name
                        : getOrderDetail.data.xpertType === 'SERVICE' ?
                          serviceType?.find(item => item.value === getOrderDetail?.data?.serviceType)?.name
                        :
                        null
                      }
                      </Text>
                  </Space>
                </Col>
              </Row>
              </Card>

              {
                getOrderDetail.data.status === 'WAITING_FOR_PAYMENT' ?
                  <WaitingPayment {...this.props} {...initialProps} />
                : getOrderDetail.data.status === 'WAITING_FOR_CONFIRM' ?
                  <WaitingConfirm {...this.props} {...initialProps} />
                : getOrderDetail.data.status === 'PAYMENT_CONFIRM' ?
                  <PaymentConfirm {...this.props} {...initialProps} />
                : getOrderDetail.data.status === 'EXPIRED' ?
                  <Expired {...this.props} {...initialProps} />
                : getOrderDetail.data.status === 'REJECTED' ?
                  <Rejected {...this.props} {...initialProps} />
                : getOrderDetail.data.status === 'CANCELED' ?
                  <Canceled {...this.props} {...initialProps} />
                :
                  null
              }
          </Col>
        </Row>

        <Modal
          centered
          title="Skema Pembayaran"
          width={800}
          visible={visiblePayment}
          footer={false}
          onCancel={this.handleClosed}
          >
          <Row gutter={[0,8]}> 
            <Col span={8} style={{ background: '#F2F2F2', paddingTop: 8, paddingBottom: 8 }}></Col>
            <Col span={8} style={{ background: '#F2F2F2', paddingTop: 8, paddingBottom: 8 }}><Text strong>{t("xpert.fee")}</Text></Col>
            <Col span={8} style={{ background: '#F2F2F2', paddingTop: 8, paddingBottom: 8 }}><Text strong>{t("xpert.dateOfPurchase")}</Text></Col>
            {
              getOrderDetail?.data?.ticketList[0].paymentScheme?.listSchemeDetail?.map((item, i) => 
                <React.Fragment key={i}>
                  <Col span={8} style={{ borderBottom: '1px solid #F2F2F2', paddingBottom: 8 }}>{item.subject}</Col>
                  <Col span={8} style={{ borderBottom: '1px solid #F2F2F2', paddingBottom: 8 }}>Rp {item.amount.toLocaleString()}</Col>
                  <Col span={8} style={{ borderBottom: '1px solid #F2F2F2', paddingBottom: 8 }}>{t("xpert.before")} {item.dueDate}</Col>
                </React.Fragment>
              )
            }
            </Row>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountDetailOrder } = this.props
    return unmountDetailOrder()
  }
}

const mapStateToProps = (state) => ({
  getOrderDetail: state.orderDetailReducer
})

const mapDispatchToProps = {
  actionDetailOrder: detailOrder,
  unmountDetailOrder: unmountDetailOrder,
  actionCancelPayment: cancelPayment
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CandidateXpertDetailMyOrders))
