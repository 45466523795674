import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { Loading } from '../../../components';
import DetailEvent from '../../../components/Event/Detail';
import { detailEvent, unmountDetailEvent } from '../../../redux/actions/event/eventAction';

export class DetailEvent_ extends Component {
  componentDidMount() {
    const { actionGetEventDetail, match: { params } } = this.props
    window.scrollTo(0, 0);
    actionGetEventDetail(params.slug)
  }
  render() {
    const { getEventDetail } = this.props

    if(getEventDetail.loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Event - Detail</title>
        </Helmet>
        <DetailEvent {...this.props}/>
      </React.Fragment>
    )
  }

  componentWillUnmount() {
    const {unmountDetailEvent} = this.props
    unmountDetailEvent()
  }
}

const mapStateToProps = (state) => ({
  getEventDetail : state.eventDetailReducer,
})

const mapDispatchToProps = {
  actionGetEventDetail: detailEvent,
  unmountDetailEvent: unmountDetailEvent,
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailEvent_)