import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CButton from '../../../components/Button';
import { Layout, Menu, Grid, Row, Col, Button, Drawer, Dropdown, Space, Typography, Image } from 'antd';
import JobshubLogo from '../../../assets/img/logo/JobshubLogo.svg';
import EmployerLogo from '../../../assets/img/logo/EmployerLogo.svg';
import Ina from '../../../assets/img/flag/ina.png';
import Eng from '../../../assets/img/flag/eng.png';
import { MenuOutlined, DownOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import cookies from 'js-cookie';

const { useBreakpoint } = Grid;
const { Header } = Layout;
const { Text } = Typography;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export const index = ({ visible, showDrawer, onClose, t }) => {
  const { md } = GetBreakPoint()
  const { pathname } = window.location
  const currentLanguage = cookies.get('i18next')
  
  const menu = (
    <Menu style={{ marginTop: -24 }}>
      <Menu.Item key="1" disabled={currentLanguage === 'in'} onClick={() => {i18next.changeLanguage('in')}} style={{ opacity: currentLanguage === 'in' ? 0.5 : 1, cursor: currentLanguage === 'in' ? 'not-allowed' : 'pointer' }}>
        <Space>
          <Image
            width={20}
            src={Ina}
            preview={false}
            style={{ marginTop: 4, borderRadius: 12, boxShadow: '1px 1px 8px #cccccc' }}
          />
          <Text style={{ color: '#4273b9' }}>
            INA
          </Text>
        </Space>
      </Menu.Item>
      <Menu.Item key="2" disabled={currentLanguage === 'en'} onClick={() => {i18next.changeLanguage('en')}} style={{ opacity: currentLanguage === 'en' ? 0.5 : 1, cursor: currentLanguage === 'en' ? 'not-allowed' : 'pointer' }}>
        <Space>
          <Image
            width={20}
            src={Eng}
            preview={false}
            style={{ marginTop: 4, borderRadius: 12, boxShadow: '1px 1px 8px #cccccc' }}
          />
          <Text style={{ color: '#4273b9' }}>
            ENG
          </Text>
        </Space>
      </Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      {
        pathname === '/register/confirm-email' ?
          null
        :
          md ?
            <React.Fragment>
              {
                pathname === '/employer' || pathname === '/employer/registration' || pathname === '/employer/registration/success' || window.location.pathname.split('/').slice(0,3)[2] === 'register' || pathname === '/bicca/register' || pathname === '/konvensi-nakes/register' ?
                  null
                :  <Row style={{ backgroundColor: '#fff' }}>
                    <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                      <Header>
                        <Space size={24} style={{ marginLeft: 'auto' }}>
                          <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
                            <Space>
                              <Image
                                width={20}
                                src={currentLanguage === 'in' ? Ina : currentLanguage === 'en' ? Eng : null}
                                preview={false}
                                style={{ marginTop: 24, borderRadius: 12, boxShadow: '1px 1px 8px #cccccc' }}
                              />
                              <Text style={{ color: '#4273b9', cursor: 'pointer' }}>
                                {currentLanguage === 'in' ? 'INA' : currentLanguage === 'en' ? 'ENG' : null} <DownOutlined />
                              </Text>
                            </Space>
                          </Dropdown>
                          <Link to="/employer">
                            <CButton type="info-r" title={t('header.companyPage')} icon="ArrowRightOutlined" />
                          </Link>
                        </Space>
                      </Header>
                    </Col>
                  </Row>
              }
              <Row className="header-outside">
                <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }} style={{ backgroundColor: '#f1f1f1' }}>
                  <Header style={{ backgroundColor: '#f1f3f6', height: 70 }}>
                    <Row className="width-100">
                      <Col className="width-100">
                        {
                          pathname === '/bicca/register' || pathname === '/konvensi-nakes/register' ?
                            <Image
                              width={140}
                              src={ JobshubLogo }
                              preview={false}
                              style={{ marginTop: 15 }}
                            />
                          :
                            <Link to={ pathname === '/employer' || pathname === '/employer/registration' || pathname === '/employer/registration/success' ? "/employer" : "/" }>
                              <Image
                                width={140}
                                src={ pathname === '/employer' || pathname === '/employer/registration' || pathname === '/employer/registration/success' ? EmployerLogo : JobshubLogo }
                                preview={false}
                                style={{ marginTop: 15 }}
                              />
                            </Link>
                        }
                        {
                          pathname === '/employer'  ?
                            <Space style={{ float: 'right', paddingTop: 4 }}>
                              <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
                                <Space>
                                  <Image
                                    width={20}
                                    src={currentLanguage === 'in' ? Ina : currentLanguage === 'en' ? Eng : null}
                                    preview={false}
                                    style={{ marginTop: 24, borderRadius: 12, boxShadow: '1px 1px 8px #cccccc' }}
                                  />
                                  <Text style={{ color: '#4273b9', cursor: 'pointer' }}>
                                    {currentLanguage === 'in' ? 'INA' : currentLanguage === 'en' ? 'ENG' : null} <DownOutlined />
                                  </Text>
                                </Space>
                              </Dropdown>
                              <Link to="/">
                                <Button type="primary" style={{ border: 'solid 2px #4273b9', background: 'transparent', color: '#4273b9', borderRadius: 16 }} ghost><ArrowLeftOutlined /> {t('header.candidatePage')}</Button>
                              </Link>
                              <a href={process.env.REACT_APP_ENV === "production" ? "https://dashboard.jobshub.id" : "https://stg-bo.jobshub.id"} target="_blank" rel="noreferrer">
                                <CButton type="primary" title={t('header.login')} />
                              </a>
                            </Space>
                          : pathname === '/employer/registration' ?
                              <Space style={{ float: 'right', paddingTop: 4 }}>
                                <a href={process.env.REACT_APP_ENV === "production" ? "https://dashboard.jobshub.id" : "https://stg-bo.jobshub.id"} target="_blank" rel="noreferrer">
                                  Login as Employer
                                </a>
                              </Space>
                          : pathname === '/employer/registration/success' ?
                              null
                          :
                            pathname === '/bicca/register' || pathname === '/konvensi-nakes/register' ?
                            null
                          :
                            <Space style={{ float: 'right', paddingTop: 4 }}>
                              <Link to="/login">
                                <CButton type="primary-outline" title={t('header.login')} />
                              </Link>
                              <Link to="/register">
                                <CButton type="primary" title={t('header.register')} />
                              </Link>
                            </Space>
                        }
                      </Col>
                    </Row>
                  </Header>
                </Col>
              </Row>
            </React.Fragment>
          :
            <Header breakpoint="md" className={pathname === '/bicca/register' || pathname === '/konvensi-nakes/register' ? "header-mobile-fixed" : "header-mobile"}>
              {
                pathname === '/bicca/register' || pathname === '/konvensi-nakes/register' ?
                  <img className="logo" src={JobshubLogo} alt="..." />
                :
                  <Link to={ pathname === '/employer' || pathname === '/employer/registration' || pathname === '/employer/registration/success' ? "/employer" : "/" || pathname === 'jobfair2510/register' }>
                    <img className="logo" src={pathname === '/employer' || pathname === '/employer/registration' || pathname === '/employer/registration/success' ? EmployerLogo : JobshubLogo} alt="..." />
                  </Link>

              }
              <Space>
                {
                  pathname === '/bicca/register' || pathname === '/konvensi-nakes/register' ?
                    null
                  :  <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
                      <Space>
                        <Image
                          width={20}
                          src={currentLanguage === 'in' ? Ina : currentLanguage === 'en' ? Eng : null}
                          preview={false}
                          style={{ marginTop: 24, borderRadius: 12, boxShadow: '1px 1px 8px #cccccc' }}
                        />
                        <Text style={{ color: '#4273b9', cursor: 'pointer' }}>
                          {currentLanguage === 'in' ? 'INA' : currentLanguage === 'en' ? 'ENG' : null} <DownOutlined />
                        </Text>
                      </Space>
                    </Dropdown>

                }
                {
                  pathname === '/bicca/register' || pathname === '/konvensi-nakes/register' ?
                    null
                  :
                    <Button className="margin-16" icon={<MenuOutlined />} onClick={showDrawer} />
                }
              </Space>
              {
                pathname === '/bicca/register' || pathname === '/konvensi-nakes/register' ?
                  null
                :
                  <Drawer
                    placement="right"
                    // closable={false}
                    onClose={onClose}
                    visible={visible}
                    bodyStyle={{ padding: 0, marginTop: 32 }}
                  >
                    {
                      pathname === '/employer' || pathname === '/employer/registration' || pathname === '/employer/registration/success' || pathname === 'jobfair2510/register' || pathname === '/konvensi-nakes/register' ?
                        <Menu onClick={onClose} selectedKeys={[pathname]} mode="vertical">
                          <Menu.Item key="/dashboard">
                            <a href={process.env.REACT_APP_ENV === "production" ? "https://dashboard.jobshub.id" : "https://stg-bo.jobshub.id"}>
                              {t('header.login')}
                            </a>
                          </Menu.Item>
                          <Menu.Item key="/">
                            <Link to="/">
                              {t('header.candidatePage')}
                            </Link>
                          </Menu.Item>
                        </Menu>
                      :
                        <Menu onClick={onClose} selectedKeys={[pathname]} mode="vertical">
                          <Menu.Item key="/register">
                            <Link to="/register">
                              {t('header.register')}
                            </Link>
                          </Menu.Item>
                          <Menu.Item key="/login">
                            <Link to="/login">
                              {t('header.login')}
                            </Link>
                          </Menu.Item>
                          <Menu.Item key="/employer">
                            <Link to="/employer">
                              {t('header.companyPage')}
                            </Link>
                          </Menu.Item>
                        </Menu>
                    }
                  </Drawer>
              }
            </Header>
      }

    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(index))