import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Info from './Info';
import InfoPart1 from './InfoPart1';
import Q16_20 from './Q16-20';
import Q21_25 from './Q21-25';
import Q26_30 from './Q26-30';
import Q31_35 from './Q31-35';
import InfoPart2 from './InfoPart2';
import Q36_40 from './Q36-40';
import Q41_45 from './Q41-45';

export class Section2 extends Component {
  render() {
    const { isSection, isPart } = this.props

    return (
      <React.Fragment>
        {/* Section 2 INFO */}
        {isSection === 2 && isPart === 0 ? <Info {...this.props} /> : null}
        {/* Section 2 Q INFO Part 1 */}
        {isSection === 2 && isPart === 1 ? <InfoPart1 {...this.props} /> : null}
        {/* Section 2 Q 16 - 20 */}
        {isSection === 2 && isPart === 2 ? <Q16_20 {...this.props} /> : null}
        {/* Section 2 Q 21 - 25 */}
        {isSection === 2 && isPart === 3 ? <Q21_25 {...this.props} /> : null}
        {/* Section 2 Q 26 - 30 */}
        {isSection === 2 && isPart === 4 ? <Q26_30 {...this.props} /> : null}
        {/* Section 2 Q 31 - 35 */}
        {isSection === 2 && isPart === 5 ? <Q31_35 {...this.props} /> : null}

        {/* Section 2 INFO Part 2 */}
        {isSection === 2 && isPart === 6 ? <InfoPart2 {...this.props} /> : null}
        {/* Section 2 Q 36 - 40 */}
        {isSection === 2 && isPart === 7 ? <Q36_40 {...this.props} /> : null}
        {/* Section 2 Q 41 - 45 */}
        {isSection === 2 && isPart === 8 ? <Q41_45 {...this.props} /> : null}
      </React.Fragment>
    )
  }
}


export default withTranslation()(Section2)