import React from 'react';
import { Row, Col, Typography, Form, Input, Select } from 'antd';
import CButton from '../../../../components/Button';
const { Text } = Typography
const { Option } = Select;

export default function Form6(props) {
  const { formRef, form6, f6, handlePembiyayaan, handlePrev, onFinish } = props
  return (
    <React.Fragment>
      <Row className="text-align-center mb-16 mt-16">
        <Col span={24}>
          <Text className="primary-color fs-17">Informasi Pendidikan</Text>
        </Col>
      </Row>

      <Form 
        ref={formRef}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          f1201: f6?.f1201 ? f6.f1201 : null,
          f1202: f6?.f1202 ? f6.f1202 : null,
          f15: f6?.f15 ? f6.f15 : null,
          f14: f6?.f14 ? f6.f14 : null
        }}
      >
        <Row gutter={16} justify="space-between" align="bottom">
          <Col span={form6.pembiyayaan === "7" ? 12 : 24}>
            <Form.Item 
              name="f1201"
              label="Sebutkan sumberdana dalam pembiayaan kuliah?"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <Select 
                className="width-100" 
                placeholder="Pilih Jawaban"
                onChange={handlePembiyayaan}
              >
                <Option key="1" value="1">Biaya Sendiri / Keluarga</Option>
                <Option key="2" value="2">Beasiswa ADIK</Option>
                <Option key="3" value="3">Beasiswa BIDIKMISI</Option>
                <Option key="4" value="4">Beasiswa PPA</Option>
                <Option key="5" value="5">Beasiswa AFIRMASI</Option>
                <Option key="6" value="6">Beasiswa Perusahaan/Swasta</Option>
                <Option key="7" value="7">Lainnya</Option>
              </Select>
            </Form.Item>
          </Col>
          {
            form6.pembiyayaan === "7" ?
              <Col span={form6.pembiyayaan === "7" ? 12 : 24}>
                <Form.Item 
                  name="f1202" 
                  label="Sebutkan Lainnya:"
                  validateFirst
                  rules={[
                    { required: true, message: 'This field is mandatory!' },
                    { min: 3, message: 'At least 3 characters' },
                    { max: 50, message: '50 characters only' },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            : null
          }

          <Col span={24}>
            <Form.Item 
              name="f15" 
              label="Tingkat pendidikan yang paling tepat/sesuai untuk pekerjaan saat ini:"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <Select 
                className="width-100" 
                placeholder="Pilih Jawaban"
              >
                <Option key="1" value="1">Setingkat Lebih Tinggi</Option>
                <Option key="2" value="2">Tingkat yang Sama</Option>
                <Option key="3" value="3">Setingkat Lebih Rendah</Option>
                <Option key="4" value="4">Tidak Perlu Pendidikan Tinggi</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item 
              name="f14" 
              label="Seberapa erat hubungan antara bidang studi dengan pekerjaan kamu?"
              validateFirst
              rules={[
                { required: true, message: 'This field is mandatory!' },
              ]}
            >
              <Select 
                className="width-100" 
                placeholder="Pilih Jawaban"
              >
                <Option key="1" value="1">Sangat Erat</Option>
                <Option key="2" value="2">Erat</Option>
                <Option key="3" value="3">Cukup Erat</Option>
                <Option key="4" value="4">Kurang Erat</Option>
                <Option key="5" value="5">Tidak Sama Sekali</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <CButton className="width-100" type="primary" ghost action={handlePrev} title="Sebelumnya"/>
            </Col>
            <Col span={12}>
              <CButton className="width-100" htmlType="submit" type="primary" title="Selanjutnya"/>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </React.Fragment>
  )
}
