import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Row, Col, Typography, Card, Skeleton, Image, Space, Pagination, Empty, Modal, Form, Input, message } from 'antd'
import { menuApplications, unmountMenuApplications, listApplications, unmountListApplications, detailInterview, unmountInterview, confirmInterview, cancelInterview, rescheduleInterview } from '../../../../redux/actions/applications/applicationsAction'
import { listNotification, countNotification } from '../../../../redux/actions/header/headerAction';
import { SafetyOutlined, EnvironmentOutlined } from '@ant-design/icons';
import CButton from '../../../../components/Button';
import EmptyLogo from '../../../../assets/img/EmptyLogo.png';
import moment from 'moment';
import MenuApplications from '../Menu';
import { withTranslation } from 'react-i18next';

const { Text } = Typography;
const { TextArea } = Input;

export class index extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      params: {
        page: 1,
        perpage: 10,
        // phaseId: 14,
        phaseId: 8,
        jobStatus: 'ACTIVE'
      },
      activeKey: "Interview",
      visible: false,
      visibleDetail: false,
      isReschedule: false,
      isReject: false,
      submitLoading: false,
    }
  }
  
  componentDidMount() {
    const { params } = this.state
    const { actionMenuApplications, actionListApplications } = this.props

    return (actionMenuApplications(), actionListApplications(params))
  }

  handlePagination = (pageNumber) => {
    const { params: { perpage, phaseId, jobStatus } } = this.state
    const params = {
      page: pageNumber,
      perpage: perpage,
      phaseId: phaseId,
      jobStatus: jobStatus
    }
    this.setState({ params })
    window.scrollTo(0, 0);
    const { actionListApplications } = this.props
    return actionListApplications(params)
  }

  showModal = (interviewId) => {
    const { actionDetailInterview } = this.props

    const params = {
      interviewId
    }
    this.setState({
      visible: true
    })

    return actionDetailInterview(params)
  }

  showModalDetail = (interviewId) => {
    const { actionDetailInterview } = this.props

    const params = {
      interviewId
    }
    this.setState({
      visibleDetail: true
    })

    return actionDetailInterview(params)
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      visibleDetail: false
    })
  }

  handleShowReschedule = (value) => {
    this.setState({
      isReschedule: value
    })
  }

  handleShowReject = (value) => {
    this.setState({
      isReject: value
    })
  }

  handleAttend = (interviewId) => {
    const { params } = this.state
    const { actionConfirmInterview, actionListApplications, actionListNotification, actionCountNotification } = this.props
    
    const values = {
      interviewId
    }

    const paramsNotification ={
      page: 1,
      perpage: 100
    }

    return this.setState({ submitLoading: true }, () => {
      return actionConfirmInterview(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            return (actionListApplications(params), actionListNotification(paramsNotification), actionCountNotification())
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  onFinishReschedule = (values, interviewId) => {
    const { params } = this.state
    const { actionRescheduleInterview, actionListApplications, actionListNotification, actionCountNotification } = this.props
    
    values.id = interviewId

    const paramsNotification ={
      page: 1,
      perpage: 100
    }

    return this.setState({ submitLoading: true }, () => {
      return actionRescheduleInterview(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            return (actionListApplications(params), actionListNotification(paramsNotification), actionCountNotification())
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  onFinishCancel = (values, interviewId) => {
    const { params } = this.state
    const { actionCancelInterview, actionListApplications, actionListNotification, actionCountNotification } = this.props
    
    values.id = interviewId

    const paramsNotification ={
      page: 1,
      perpage: 100
    }

    return this.setState({ submitLoading: true }, () => {
      return actionCancelInterview(values, response => {
        if(response.code === '1000'){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            return (actionListApplications(params), actionListNotification(paramsNotification), actionCountNotification())
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const { activeKey, visible, visibleDetail, isReschedule, isReject, submitLoading } = this.state
    const { t, getMenuApplications, getListApplications, getDetailInterview } = this.props

    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Applications - Interview');
    }

    const initialProps = {
      activeKey: activeKey
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Applications - Interview</title>
        </Helmet>
        <Row className="padding-16">
          <Col className="width-100" md={{ span: 20, offset: 2 }}>
            <Row className="mb-32">
              <Col>
                <Text className="dark-color fs-20">{t('application.applications')}</Text>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col xs={24} lg={4}>
                {
                  getMenuApplications.loading ?
                    <Skeleton active />
                  :
                    <MenuApplications {...this.props} {...initialProps} />
                }
              </Col>
              <Col xs={24} lg={20}>
                <Row className="width-100 mb-8" gutter={8}>
                  <Col span={24}>
                    {
                      getListApplications.data ?
                        <Text className="dark-color fs-13">{`${t('application.qualified.showing')} ${getListApplications?.meta?.total === 0 ? getListApplications?.meta?.total : (getListApplications?.meta?.page*getListApplications?.meta?.perpage)-(getListApplications?.meta?.perpage-1)}-${getListApplications?.meta?.page === getListApplications?.meta?.pages || getListApplications?.meta?.total === 0 ? getListApplications?.meta?.total : getListApplications?.meta?.page*getListApplications?.meta?.perpage} of ${getListApplications?.meta?.total} ${t('application.qualified.job')}`}</Text>
                      :
                        <Skeleton.Input active />
                    }
                  </Col>
                </Row>
                <Row className="mb-16">
                  <Col span={24}>
                    {
                      getListApplications.loading ?
                        <Card className="border-radius-3 box-shadow mb-6">
                          <Skeleton active avatar />
                        </Card>
                      :
                        getListApplications?.data?.length > 0 ?
                          getListApplications?.data?.map((item, i) => 
                            <Card key={i} className="border-radius-3 box-shadow mb-6">
                              <Row className="width-100" gutter={16} style={{ marginBottom: -10 }}>
                                <Col className="col-img-job" xs={24} sm={4} md={4}>
                                  {/* <Link to={`/candidate/company/${item.companyId}/${item.companyType}`}> */}
                                  <Link to={`/candidate/job/detail/${item.slug}`}>
                                    <Image
                                      src={item.companyLogoUrl ? item.companyLogoUrl : EmptyLogo}
                                      preview={false}
                                      width={'100%'}
                                      height={80}
                                      style={{ objectFit: 'contain' }}
                                    />
                                  </Link>
                                </Col>
                                <Col xs={24} sm={13} md={12}>
                                  <Space direction="vertical" size={0}>
                                    <Link to={`/candidate/job/detail/${item.slug}`}>
                                      <Text className="info-color fs-17">{item.title}</Text>
                                    </Link>
                                    <Text className="fs-13 dark-color">{item.companyName} {item.isVerified ? <SafetyOutlined className="primary-color" /> : null}</Text>
                                    <Text className="fs-13 dark-color"><EnvironmentOutlined /> {item.jobDestinationParentId ? `${item.jobDestinationName}, ${item.jobDestinationParentName}` : item.jobDestinationName}</Text>
                                  </Space>
                                </Col>
                                <Col xs={24} sm={7} md={8} className="application-date">
                                  <Space direction="vertical" size={8} className="application-date">
                                    <Text className="application-date fs-13 dark-color">{`${t('application.qualified.applied')} ${item.applyDate}`}</Text>
                                    {
                                      item.interviewStatus === 'WAITING_CONFIRM' || item.interviewStatus === 'RESCHEDULE_ADMIN' ?
                                        <CButton className="application-date mt-8 fs-12" type="primary-icon" icon="CalendarOutlined" action={() => this.showModal(item.interviewId)} title={t('application.interview.confirm')}/>
                                      : item.interviewStatus === 'CONFIRM' || item.interviewStatus === 'RESCHEDULE_CANDIDATE' ?
                                        <CButton className="application-date mt-8 fs-12" type="save" action={() => this.showModalDetail(item.interviewId)} title={t('application.assessment.details')}/>
                                      :
                                        null
                                    }
                                    <Modal title="Interview Schedule Confirmation" width={700} visible={visible} onCancel={this.handleCancel} footer={false} destroyOnClose>
                                      {
                                        getDetailInterview.loading ?
                                          <Skeleton active />
                                        :
                                          <React.Fragment>
                                            <Space className="width-100" direction="vertical">
                                              <Text className="dark-color fs-14">{t('application.interview.thankyou')} {getDetailInterview?.data?.jobPositionName} - {getDetailInterview?.data?.companyName}.</Text>
                                              <Text className="dark-color fs-14">{t('application.interview.title1')}</Text>
                                              <Row className="mb-8" gutter={16}>
                                                <Col xs={6} lg={3}>
                                                  <Text> {t('application.interview.date')}</Text>
                                                </Col>
                                                <Col xs={18} lg={21}>
                                                  <Space align="start" style={{ textAlign: 'justify' }}>
                                                    <Text>:</Text>
                                                    <Text className="font-weight-bold">{moment(getDetailInterview?.data?.interviewDate, 'DD/MM/YYYY').format('DD MMMM YYYY')}</Text>
                                                  </Space>
                                                </Col>
                                                <Col xs={6} lg={3}>
                                                  <Text>{t('application.interview.time')}</Text>
                                                </Col>
                                                <Col xs={18} lg={21}>
                                                  <Space align="start" style={{ textAlign: 'justify' }}>
                                                    <Text>:</Text>
                                                    <Text className="font-weight-bold">{moment(getDetailInterview?.data?.interviewTime, 'hh:mm:ss').format('LT')}</Text>
                                                  </Space>
                                                </Col>
                                                {
                                                  getDetailInterview?.data?.isMethod &&
                                                  <React.Fragment>
                                                    <Col xs={6} lg={3}>
                                                      <Text>{t('application.interview.location')}</Text>
                                                    </Col>
                                                    <Col xs={18} lg={21}>
                                                      <Space align="start" style={{ textAlign: 'justify', marginBottom: -12 }}>
                                                        <Text>:</Text>
                                                        <div className="font-weight-bold" dangerouslySetInnerHTML={{ __html: getDetailInterview?.data?.location }}></div>
                                                      </Space>
                                                    </Col>
                                                  </React.Fragment>
                                                }
                                                {
                                                  !getDetailInterview?.data?.isMethod &&
                                                  <React.Fragment>
                                                    <Col xs={6} lg={3}>
                                                      <Text>{t('application.assessment.virtualLink')}</Text>
                                                    </Col>
                                                    <Col xs={18} lg={21}>
                                                      <Space align="start" style={{ textAlign: 'justify', marginBottom: -12 }}>
                                                        <Text>:</Text>
                                                        <div className="font-weight-bold" dangerouslySetInnerHTML={{ __html: getDetailInterview?.data?.virtualLink }}></div>
                                                      </Space>
                                                    </Col>
                                                  </React.Fragment>
                                                }
                                                <Col xs={6} lg={3}>
                                                  <Text>{t('application.interview.notes')}</Text>
                                                </Col>
                                                <Col xs={18} lg={21}>
                                                  <Space align="start" style={{ textAlign: 'justify', marginBottom: -12 }}>
                                                    <Text>:</Text>
                                                    <div className="font-weight-bold" dangerouslySetInnerHTML={{ __html: getDetailInterview.data.notes ? getDetailInterview.data.notes : '-' }}></div>
                                                  </Space>
                                                </Col>
                                              </Row>
                                              {
                                                !isReschedule?
                                                  <Space className="mb-8">
                                                    <CButton className="fs-12" type="primary" disabled={isReject} action={() => this.handleAttend(getDetailInterview?.data?.id)} title={t('application.interview.title2')}/>
                                                    <CButton className="fs-12" type="save" disabled={isReject} action={() => this.handleShowReschedule(getDetailInterview?.data?.id)} style={!isReject ? { background: '#0085ff', color: '#fffff', borderColor: '#0085ff' } : null} title={t('application.assessment.reschedule')}/>
                                                  </Space>
                                                :
                                                  null
                                              }
                                            </Space>
                                            {
                                              !isReschedule && !isReject ?
                                                <Text className="dark-color fs-14">{t('application.interview.title')} <Text className="info-color cursor-pointer" onClick={() => this.handleShowReject(true)}>{t('application.assessment.clickHere')}</Text></Text>
                                              : isReschedule ? 
                                                <Form 
                                                  ref={this.formRef}
                                                  onFinish={(values) => this.onFinishReschedule(values, getDetailInterview?.data?.id)}
                                                >
                                                  <Space size={4}>
                                                    <Text className="fs-12" type="danger">*</Text>
                                                    <Text className="fs-12" type="secondary">{t('application.interview.titleReschedule')}</Text>
                                                  </Space>
                                                  <Form.Item 
                                                    name="rescheduleReason"
                                                    rules={[
                                                      { required: true, message: 'Reschedule reason is mandatory' }
                                                    ]}
                                                  >
                                                    <TextArea rows={3} />
                                                  </Form.Item>
                                                  <Form.Item>
                                                    <Space className="float-right">
                                                      <CButton className="fs-12" type="default" action={() => this.handleShowReschedule(false)} title={t('application.assessment.cancel')}/>
                                                      <CButton className="fs-12" htmlType="submit" type="primary" loading={submitLoading} title={t('application.assessment.reschedule')}/>
                                                    </Space>
                                                  </Form.Item>
                                                </Form>
                                              : isReject ? 
                                                <Form 
                                                  ref={this.formRef}
                                                  onFinish={(values) => this.onFinishCancel(values, getDetailInterview?.data?.id)}
                                                >
                                                  <Space size={4}>
                                                    <Text className="fs-12" type="danger">*</Text>
                                                    <Text className="fs-12" type="secondary">{t('application.interview.title3')}</Text>
                                                  </Space>
                                                  <Form.Item 
                                                    name="candidateNote"
                                                    rules={[
                                                      { required: true, message: 'Decline reason is mandatory' }
                                                    ]}
                                                  >
                                                    <TextArea rows={3} />
                                                  </Form.Item>
                                                  <Form.Item>
                                                    <Space className="float-right">
                                                      <CButton className="fs-12" type="default" action={() => this.handleShowReject(false)} title={t('application.assessment.cancel')}/>
                                                      <CButton className="fs-12" htmlType="submit" type="danger" loading={submitLoading} title={t('application.assessment.declineInvite')}/>
                                                    </Space>
                                                  </Form.Item>
                                                </Form>
                                              :
                                                null
                                            }
                                          </React.Fragment>
                                        }
                                    </Modal>
                                    <Modal 
                                      title={
                                        getDetailInterview.loading ?
                                          <Skeleton paragraph={{ rows: 1 }} active />
                                        :
                                          <Space className="text-align-center width-100" direction="vertical">
                                            <Text className="fs-20 primary-color">{t('application.interview.interDetail')}</Text>
                                            <Text className="fs-15 dark-color">{`${getDetailInterview?.data?.jobPositionName} - ${getDetailInterview?.data?.companyName}`}</Text>
                                          </Space>
                                      } 
                                      width={800}
                                      visible={visibleDetail} 
                                      onCancel={this.handleCancel}
                                      footer={false}
                                    >
                                      {
                                        getDetailInterview.loading ?
                                          <Skeleton active />
                                        :
                                          <React.Fragment>
                                            <Space className="width-100" direction="vertical">
                                              <Row className="mb-32" gutter={16}>
                                              <Col xs={10} lg={5}>
                                                  <Text>{t('application.interview.status')}</Text>
                                                </Col>
                                                <Col xs={14} lg={19}>
                                                  <Space align="start" style={{ textAlign: 'justify' }}>
                                                    <Text>:</Text>
                                                    <Text strong className="font-style-italic">{getDetailInterview?.data?.interviewStatusNote}</Text>
                                                  </Space>
                                                </Col>
                                                {
                                                  getDetailInterview?.data?.rescheduleReason &&
                                                  <React.Fragment>
                                                    <Col xs={10} lg={5}>
                                                      <Text>{t('application.interview.rescheduleReason')}</Text>
                                                    </Col>
                                                    <Col xs={14} lg={19}>
                                                      <Space align="start" style={{ textAlign: 'justify' }}>
                                                        <Text>:</Text>
                                                        <Text strong> {getDetailInterview?.data?.rescheduleReason}</Text>
                                                      </Space>
                                                    </Col>
                                                  </React.Fragment>
                                                }
                                                <Col xs={10} lg={5}>
                                                  <Text>{t('application.interview.date')}</Text>
                                                </Col>
                                                <Col xs={14} lg={19}>
                                                  <Space align="start" style={{ textAlign: 'justify' }}>
                                                    <Text>:</Text>
                                                    <Text strong> {moment(getDetailInterview?.data?.interviewDate, 'DD/MM/YYYY').format('DD MMMM YYYY')}</Text>
                                                  </Space>
                                                </Col>
                                                <Col xs={10} lg={5}>
                                                  <Text>{t('application.interview.time')}</Text>
                                                </Col>
                                                <Col xs={14} lg={19}>
                                                  <Space align="start" style={{ textAlign: 'justify' }}>
                                                    <Text>:</Text>
                                                    <Text strong> {moment(getDetailInterview?.data?.interviewTime, 'hh:mm:ss').format('LT')}</Text>
                                                  </Space>
                                                </Col>
                                                {
                                                  getDetailInterview?.data?.isMethod &&
                                                  <React.Fragment>
                                                    <Col xs={10} lg={5}>
                                                      <Text>{t('application.interview.location')}</Text>
                                                    </Col>
                                                    <Col xs={14} lg={19}>
                                                      <Space align="start" style={{ textAlign: 'justify', marginBottom: -12 }}>
                                                        <Text>:</Text>
                                                        <div className="font-weight-bold" dangerouslySetInnerHTML={{ __html: getDetailInterview?.data?.location }}></div>
                                                      </Space>
                                                    </Col>
                                                  </React.Fragment>
                                                }
                                                {
                                                  !getDetailInterview?.data?.isMethod &&
                                                  <React.Fragment>
                                                    <Col xs={10} lg={5}>
                                                      <Text>{t('application.assessment.virtualLink')}</Text>
                                                    </Col>
                                                    <Col xs={14} lg={19}>
                                                      <Space align="start" style={{ textAlign: 'justify', marginBottom: -12 }}>
                                                        <Text>:</Text>
                                                        <div className="font-weight-bold" dangerouslySetInnerHTML={{ __html: getDetailInterview?.data?.virtualLink }}></div>
                                                      </Space>
                                                    </Col>
                                                  </React.Fragment>
                                                }
                                                <Col xs={10} lg={5}>
                                                  <Text>{t('application.interview.notes')}</Text>
                                                </Col>
                                                <Col xs={14} lg={19}>
                                                  <Space align="start" style={{ textAlign: 'justify', marginBottom: -12 }}>
                                                    <Text>:</Text>
                                                    <div className="font-weight-bold" dangerouslySetInnerHTML={{ __html: getDetailInterview.data.notes ? getDetailInterview.data.notes : '-' }}></div>
                                                  </Space>
                                                </Col>
                                              </Row>
                                              <Space className="float-right">
                                                <CButton className="fs-12" type="primary" action={this.handleCancel} title={t('application.interview.okay')}/>
                                              </Space>
                                            </Space>
                                          </React.Fragment>
                                        }
                                    </Modal>
                                  </Space>
                                </Col>
                              </Row>
                          </Card>
                      )
                        :
                          <Card className="border-radius-3 box-shadow mb-6">
                            <Empty />
                          </Card>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col className="text-align-center" span={24}>
                    <Pagination onChange={this.handlePagination} current={getListApplications?.meta?.page} pageSize={getListApplications?.meta?.perpage || 10} total={getListApplications?.meta?.total} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountMenuApplications, unmountListApplications, unmountInterview } = this.props
    return (unmountMenuApplications(), unmountListApplications(), unmountInterview())
  }
}

const mapStateToProps = (state) => ({
  getMenuApplications: state.menuApplicationsReducer,
  getListApplications: state.listApplicationsReducer,
  getDetailInterview: state.interviewReducer
})

const mapDispatchToProps = {
  actionMenuApplications: menuApplications,
  unmountMenuApplications: unmountMenuApplications,
  actionListApplications: listApplications,
  unmountListApplications: unmountListApplications,
  actionDetailInterview: detailInterview,
  unmountInterview: unmountInterview,
  actionConfirmInterview: confirmInterview,
  actionCancelInterview: cancelInterview,
  actionRescheduleInterview: rescheduleInterview,
  actionListNotification: listNotification,
  actionCountNotification: countNotification
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(index))