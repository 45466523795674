import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Result, Button } from 'antd';

export default class ComingSoon extends Component {
  render() {
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Jobshub - Coming Soon');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Jobshub - Coming Soon</title>
        </Helmet>
        <Result
          status="404"
          title="Coming Soon"
          subTitle="Sorry, you are not authorized to access this page."
          extra={<Button type="primary" href="/">Back Dashboard</Button>}
        />
      </React.Fragment>
    )
  }
}